import {createTheme} from '@material-ui/core/styles';
import conEdLogo from '@generic/assets/images/logo.svg';
import OpenSansRegular from '@generic/assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '@generic/assets/fonts/OpenSans-Bold.ttf';
import OpenSansExtraBold from '@generic/assets/fonts/OpenSans-ExtraBold.ttf';
import OpenSansSemiBold from '@generic/assets/fonts/OpenSans-SemiBold.ttf';
import FuturaDemiWoff2 from '@generic/assets/fonts/Futura-Demi.woff2';
import FuturaDemiWoff from '@generic/assets/fonts/Futura-Demi.woff';
import FuturaBoldWoff2 from '@generic/assets/fonts/Futura-Bold.woff2';
import FuturaBoldWoff from '@generic/assets/fonts/Futura-Bold.woff';
import ConedIcons from '@generic/assets/fonts/coned-icons.ttf';

const CON_ED_BLACK = '#121922';
const CON_ED_BLUE = '#0078cf';
const CON_ED_LIGHT_BLUE = '#069BD7';
const CON_ED_LIGHTER_BLUE = '#E6F2FA';
const CON_ED_DARK_BLUE = '#1E3575';
const CON_ED_ORANGE = '#FDA536';
const CON_ED_YELLOW = '#FDEEBA';
const CON_ED_GREEN = '#00882B';
const CON_ED_LIGHT_GREEN = '#00a334';
const CON_ED_DARK_GREEN = '#007024';
const CON_ED_RED = '#E22918';
const CON_ED_LIGHT_RED = '#f07469';
const WHITE = '#ffffff';
const GRAY_100 = '#f8f8f8';
const GRAY_200 = '#f5f5f5';
const GRAY_300 = '#e6e6e6';
const GRAY_400 = '#979797';
const GRAY_500 = '#636363';
const GRAY_600 = '#2a2a2a';
/*
 * This file is copied over from the Generic folder when a new client is created.
 *
 * Material UI's theming system is _very_ powerful, and therefore has quite
 * a learning curve.
 *
 * Most of the code here should either be removed or replaced on each client project,
 * and this file only exists to demonstrate a few of the theming techniques that can
 * be used within Material UI.
 *
 * Ideally, the entire look and feel of the site can be controlled via this
 * configuration without requiring changes to the underlying React code.
 *
 */

// Follows from Material UI docs:
// https://material-ui.com/customization/typography/#self-hosted-fonts
const openSansRegular = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Open Sans'),
    url(${OpenSansRegular}) format('truetype')
  `,
};

const openSansSemiBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('Open Sans'),
    url(${OpenSansSemiBold}) format('truetype')
  `,
};

const openSansBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Open Sans'),
    url(${OpenSansBold}) format('truetype')
  `,
};

const openSansExtraBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    local('Open Sans'),
    url(${OpenSansExtraBold}) format('truetype')
  `,
};

const futuraDemi = {
  fontFamily: 'Futura',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('Futura'),
    url(${FuturaDemiWoff2}) format('woff2'),
    url(${FuturaDemiWoff}) format('woff'),
  `,
};

const futuraBold = {
  fontFamily: 'Futura',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('Futura'),
    url(${FuturaBoldWoff2}) format('woff2'),
    url(${FuturaBoldWoff}) format('woff'),
  `,
};

const conedIcons = {
  fontFamily: 'coned-icons',
  src: `url(${ConedIcons}) format('truetype')`,
};

/*
 * The application consumes the Material UI theme using a ThemeProvider from
 * the Material UI package. It's essentially just a plain old React Context provider.
 * As such, theming doesn't have to be restricted to out of the box Material UI
 * theme options.
 *
 * The configuration in the customThemeConfig corresponds to src/alt attributes that
 * the Header component expects to get from the `useTheme` hook. None of that has
 * anything to do with Material UI, or even CSS-in-JS. Instead, this just
 * piggy backs the theme's Context. With this technique, the theme that Material UI
 * provides can be used to theme even completely custom elements or attributes!
 *
 */
const customThemeConfig = {
  header: {
    brandLogo: {
      src: conEdLogo,
      alt: 'ConEd',
    },
  },
  slider: {
    track: {
      height: '20px',
      borderRadius: '140px',
      background: GRAY_300,
    },
    thumb: {
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      background: CON_ED_BLUE,
    },
    value: {
      padding: '5px 10px',
      background: GRAY_300,
      fontWeight: 600,
    },
    label: {
      color: GRAY_500,
    },
  },
  vehicleSetDialog: {
    overview: {
      title: {
        fontFamily: 'Futura',
        fontWeight: 'bold',
        fontSize: '1.5rem',
      },
    },
  },
  toggleButtonGroup: {
    toggleButton: {
      backgroundColor: WHITE,
      color: CON_ED_BLACK,
      border: `1px solid ${CON_ED_BLACK}`,

      active: {
        backgroundColor: CON_ED_GREEN,
        color: WHITE,
        border: `1px solid ${CON_ED_GREEN}`,
      },
    },
    toggleLabel: {
      color: GRAY_600,
    },
  },
  ctaButton: {
    backgroundColor: CON_ED_BLUE,
    color: WHITE,
    borderRadius: '10em',
    fontWeight: 800,
    textTransform: 'none',
    padding: '0.75rem 1.5rem',
    '&:hover': {
      backgroundColor: CON_ED_LIGHT_BLUE,
    },
    '&:active, &:focus': {
      backgroundColor: CON_ED_DARK_BLUE,
    },
  },
};

/*
 * https://material-ui.com/customization/theming/#theming
 *
 * Material UI's theming capabilities are really powerful, and it's worth a few
 * hours of study over the documentation if you aren't already very familiar with
 * how it works.
 *
 * As recommended in the docs, a good place to start is to inspect the
 * default theme in the console on the documentation page:
 * https://material-ui.com/customization/default-theme/#explore
 *
 * The default examples given when creating a new project include:
 *
 *   1. Palette: control over the color scheme
 *   2. props: overrides to the props of specific Material UI components.
 *   3. Overrides: conceptually the same as props, except  you specify actual
 *      CSS properties instead of props that a component would receive. These
 *      can be nested, AND can target arbitrary selectors (not just Material
 *      UI selectors), which allows very fine grained control over the app's style.
 *   4. Typography: control over how text is rendered by Material UI
 *      Typography elements.
 *
 */

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
    },
  },
  palette: {
    primary: {
      main: CON_ED_BLUE,
      light: CON_ED_LIGHT_BLUE,
      lighter: CON_ED_LIGHTER_BLUE,
      dark: CON_ED_DARK_BLUE,
    },
    secondary: {
      main: CON_ED_BLACK,
      light: CON_ED_ORANGE,
      lighter: CON_ED_YELLOW,
    },
    gray: {
      100: GRAY_100,
      200: GRAY_200,
      300: GRAY_300,
      400: GRAY_400,
      500: GRAY_500,
      600: GRAY_600,
      black: CON_ED_BLACK,
    },
    success: {
      main: CON_ED_GREEN,
      light: CON_ED_LIGHT_GREEN,
      dark: CON_ED_DARK_GREEN,
    },
    error: {
      light: CON_ED_LIGHT_RED,
      main: CON_ED_RED,
    },
    neutral: {
      main: GRAY_600,
      light: WHITE,
      dark: CON_ED_BLACK,
    },
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
    MuiButton: {
      disableRipple: true,
    },
    MuiTab: {
      disableRipple: true,
    },
    MuiCard: {
      raised: false,
      square: true,
      elevation: 0,
      variant: 'outlined',
    },
    MuiTooltip: {
      enterTouchDelay: 100,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          openSansBold,
          openSansExtraBold,
          openSansRegular,
          openSansSemiBold,
          futuraDemi,
          futuraBold,
          conedIcons,
        ],
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      contained: {
        backgroundColor: GRAY_200,
        boxShadow: 'none',
        borderRadius: '3px',
        border: `1px solid ${GRAY_600}`,
        textTransform: 'initial',
        padding: '8px 24px',

        '&:hover, &:focus': {
          boxShadow: 'none',
          backgroundColor: GRAY_300,
        },
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: '#ffffff',
        boxShadow: 'none',
        borderBottom: `1px solid ${CON_ED_BLACK}`,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'initial',
        },
      },
    },
    MuiToolbar: {
      root: {
        color: CON_ED_BLACK,
        minHeight: 'auto',

        '&.top-toolbar': {
          backgroundColor: CON_ED_BLACK,
          color: WHITE,
          minHeight: 'auto',

          '& .MuiButton-root': {
            textTransform: 'initial',
            '&:hover': {
              textDecoration: 'underline',
              color: GRAY_300,
            },
          },
        },

        '& .MuiButton-root, & .MuiButton-root .MuiTypography-root': {
          fontFamily: "Futura, 'Open Sans'",
          fontWeight: 400,

          '&:hover': {
            backgroundColor: 'inherit',
          },
        },

        '& .link': {
          borderLeft: '1px solid #000',
          padding: '30px 40px',
          width: '160px',

          '& .MuiTypography-root': {
            textTransform: 'initial',
          },

          '&:last-child': {
            borderRight: '1px solid #000',
          },

          '&::before': {
            transition: 'all .3s ease',
            display: 'block',
            position: 'absolute',
            top: '0',
            left: '50%',
            borderTop: 'solid 3px #069bd7',
            width: '0',
            content: '""',
          },

          '&:hover::before': {
            left: '0',
            width: '100%',
          },
        },
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "Futura, 'Open Sans'",
      },
      h2: {
        fontFamily: "Futura, 'Open Sans'",
      },
      h5: {
        fontFamily: "Futura, 'Open Sans'",
      },
      h6: {
        fontWeight: '600',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: CON_ED_BLUE,

        '& .MuiTableCell-head': {
          color: WHITE,
          fontWeight: 700,
        },
      },
    },
    MuiTableRow: {
      root: {
        '& .MuiTableCell-body:first-child': {
          backgroundColor: CON_ED_LIGHTER_BLUE,
        },
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: 8,
        paddingBottom: 6,
        border: `1px solid ${GRAY_300}`,
      },
    },
    MuiSwitch: {
      switchBase: {
        color: GRAY_300,
        boxShadow: 'none',
      },
      colorSecondary: {
        '&$checked': {
          color: CON_ED_BLUE,
        },
        '&$checked + $track': {
          backgroundColor: CON_ED_BLUE,
        },
      },
      thumb: {
        boxShadow: 'none',
      },
      track: {
        backgroundColor: GRAY_400,
        opacity: 1,
      },
      root: {
        '& .MuiIconButton-root:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  typography: {
    fontFamily: "'Open Sans', Arial",
    primary: {
      fontFamily: "Futura, 'Open Sans', Arial",
    },
    secondary: {
      fontFamily: "'Open Sans', Arial",
    },
  },
  ...customThemeConfig,
});

// responsive typography needs to be added after creating the theme,
// so that we can reference the theme's breakpoints
// https://mui.com/customization/typography/#responsive-font-sizes
theme.typography.h1 = {
  fontSize: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
};

theme.typography.h2 = {
  fontSize: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
};

theme.typography.h5 = {
  fontSize: '1.125rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

export default theme;
