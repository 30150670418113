import {Box, Grid, Typography, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import without from 'lodash/without';
import CheckboxButtonInput from '@generic/components/inputs/CheckboxButtonInput';
import {DAYS_ABBREVIATED} from '@generic/data/timePeriods';

const useStyles = makeStyles((theme) => ({
  toggleLabel: {
    color: theme.toggleButtonGroup.toggleLabel.color,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
  },
}));

export default function DaysInOperation({onChange, workdays = []}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const toggleButton = (dayIdx) => {
    if (workdays.includes(dayIdx)) {
      onChange(without(workdays, dayIdx));
    } else {
      onChange([...workdays, dayIdx]);
    }
  };

  return (
    <Box>
      <Typography className={classes.toggleLabel}>
        {formatMessage({id: 'daysInOperation.label'})}
      </Typography>
      <Grid container spacing={2}>
        {DAYS_ABBREVIATED.sort((a, b) => a.position - b.position).map(
          ({dayIdx, translationKey}) => (
            <Grid key={dayIdx} item xs={6} md>
              <CheckboxButtonInput
                checked={workdays.includes(dayIdx)}
                onClick={() => toggleButton(dayIdx)}
              >
                {formatMessage({id: translationKey})}
              </CheckboxButtonInput>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
}
