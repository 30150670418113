import {useState} from 'react';
import {Box} from '@material-ui/core';
import ChargerSetInputCard from '@generic/components/inputs/ChargerSetInputCard';
import ChargerSetDialog from '@generic/components/ChargerSetDialog';

export default function ChargerSetInput({chargerSet, onChange, onRemove}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <ChargerSetInputCard chargerSet={chargerSet} onClick={() => setIsOpen(true)} />
      <ChargerSetDialog
        isOpen={isOpen}
        chargerSet={chargerSet}
        onChange={onChange}
        onClose={() => setIsOpen(false)}
        onRemove={onRemove}
      />
    </Box>
  );
}
