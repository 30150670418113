import VEHICLES from '@generic/data/vehicles';
import CHARGERS from '@generic/data/chargers';
import {getRatesIgnoringDemand} from '@generic/data/rates';
import {DEFAULT_CHARGER_SET} from '../functions/getDefaults';

const computed = {
  vehicleSets: ({vehicleSets}) =>
    vehicleSets.map((vehicleSet) => {
      const vehicle = VEHICLES.find((v) => v.id === vehicleSet.vehicle_id);

      const chargingWindows = vehicleSet.chargingWindows.map((chargingWindow) => ({
        ...chargingWindow,
        charger: CHARGERS.find(({type}) => type === chargingWindow.type),
      }));

      return {
        ...vehicleSet,
        chargingWindows,
        vehicle,
      };
    }),
  chargerSets: ({chargerSets}) =>
    chargerSets.map((chargerSet) => {
      const charger = CHARGERS.find((c) => c.type === chargerSet.type);

      return {
        ...DEFAULT_CHARGER_SET,
        ...chargerSet,
        charger,
      };
    }),
  currentRate: ({currentRate, ...restOfInputs}) => {
    const rates = getRatesIgnoringDemand({...restOfInputs});
    return rates.find(({name}) => name === currentRate) || rates[0];
  },
};

export default computed;
