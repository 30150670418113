// source: https://docs.google.com/spreadsheets/d/1gh6FE8xkA4OZ8WvDUtpuW6WJnwHePU-X2kPa95WwWkE/edit#gid=1674745959

const VEHICLES = [
  {
    id: 'on_road_bev_drive_system_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'BEV Drive System',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_BEV_Drive_System.png',
    rangeInMiles: 150,
    formFactor: 'bev_drive_system',
    liftCapacity: 0,
    gvwr: 40000,
    weightClass: 8,
    batteryCapacityInKwh: 193,
    efficiencyInKwhPerHundredMiles: 128.67,
    msrp: 285000,
    maintenanceCostPerMile: 0.15,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 100000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 6.4,
  },
  {
    id: 'on_road_bev_drive_system_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'BEV Drive System',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_BEV_Drive_System.png',
    rangeInMiles: 200,
    formFactor: 'bev_drive_system',
    liftCapacity: 0,
    gvwr: 19250,
    weightClass: 5,
    batteryCapacityInKwh: 138,
    efficiencyInKwhPerHundredMiles: 69,
    msrp: 159000,
    maintenanceCostPerMile: 0.23,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 75000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
  },
  {
    id: 'on_road_box_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Box_Truck.png',
    rangeInMiles: 172,
    formFactor: 'box_truck',
    liftCapacity: 0,
    gvwr: 44000,
    weightClass: 8,
    batteryCapacityInKwh: 272,
    efficiencyInKwhPerHundredMiles: 158.14,
    msrp: 282000,
    maintenanceCostPerMile: 0.16,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 100000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 6.4,
  },
  {
    id: 'on_road_box_truck_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Box_Truck.png',
    rangeInMiles: 163,
    formFactor: 'box_truck',
    liftCapacity: 0,
    gvwr: 22100,
    weightClass: 5,
    batteryCapacityInKwh: 153,
    efficiencyInKwhPerHundredMiles: 93.87,
    msrp: 185000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 75000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
  },
  {
    id: 'on_road_cargo_van_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cargo Van',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Cargo_Van.png',
    rangeInMiles: 160,
    formFactor: 'cargo_van',
    liftCapacity: 0,
    gvwr: 23500,
    weightClass: 6,
    batteryCapacityInKwh: 128,
    efficiencyInKwhPerHundredMiles: 80,
    msrp: 220000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 125000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 6.7,
  },
  {
    id: 'on_road_cargo_van_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Cargo_Van.png',
    rangeInMiles: 177,
    formFactor: 'cargo_van',
    liftCapacity: 0,
    gvwr: 13293,
    weightClass: 3,
    batteryCapacityInKwh: 101,
    efficiencyInKwhPerHundredMiles: 57.06,
    msrp: 135240,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 70000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
  },
  {
    id: 'on_road_cutaway_chassis_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cutaway Chassis',
    subtype: 'Light Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Cutaway_Chassis.png',
    rangeInMiles: 150,
    formFactor: 'cutaway_chassis',
    liftCapacity: 0,
    gvwr: 6500,
    weightClass: 2,
    batteryCapacityInKwh: 118,
    efficiencyInKwhPerHundredMiles: 78.67,
    msrp: 110000,
    maintenanceCostPerMile: 0.13,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 70000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
  },
  {
    id: 'on_road_cutaway_chassis_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Cutaway_Chassis.png',
    rangeInMiles: 162,
    formFactor: 'cutaway_chassis',
    liftCapacity: 0,
    gvwr: 15530,
    weightClass: 4,
    batteryCapacityInKwh: 125,
    efficiencyInKwhPerHundredMiles: 77.16,
    msrp: 140000,
    maintenanceCostPerMile: 0.16,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 88000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
  },
  {
    id: 'on_road_cutaway_chassis_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cutaway Chassis',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Cutaway_Chassis.png',
    rangeInMiles: 170,
    formFactor: 'cutaway_chassis',
    liftCapacity: 0,
    gvwr: 33000,
    weightClass: 7,
    batteryCapacityInKwh: 138,
    efficiencyInKwhPerHundredMiles: 81.18,
    msrp: 250000,
    maintenanceCostPerMile: 0.23,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 157500,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 7,
  },
  {
    id: 'on_road_motor_coach_double_decker',
    name: 'Generic',
    category: 'On-Road',
    type: 'Motor Coach',
    subtype: 'Double Decker',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Double_Decker_Motor_Coach.png',
    rangeInMiles: 200,
    formFactor: 'motor_coach',
    liftCapacity: 0,
    gvwr: 51375,
    weightClass: 8,
    batteryCapacityInKwh: 380,
    efficiencyInKwhPerHundredMiles: 190,
    msrp: 1050000,
    maintenanceCostPerMile: 0.7,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 600000,
    fossilMaintenanceCostPerMile: 1.05,
    fossilMilesPerGallon: 5,
  },
  {
    id: 'on_road_motor_coach_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Motor Coach',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Motor_Coach.png',
    rangeInMiles: 124,
    formFactor: 'motor_coach',
    liftCapacity: 0,
    gvwr: 17300,
    weightClass: 5,
    batteryCapacityInKwh: 121,
    efficiencyInKwhPerHundredMiles: 97.58,
    msrp: 210000,
    maintenanceCostPerMile: 0.5,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 132000,
    fossilMaintenanceCostPerMile: 0.9,
    fossilMilesPerGallon: 7,
  },
  {
    id: 'on_road_motor_coach_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Motor_Coach.png',
    rangeInMiles: 224,
    formFactor: 'motor_coach',
    liftCapacity: 0,
    gvwr: 51300,
    weightClass: 8,
    batteryCapacityInKwh: 466,
    efficiencyInKwhPerHundredMiles: 208.04,
    msrp: 790000,
    maintenanceCostPerMile: 0.6,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 500000,
    fossilMaintenanceCostPerMile: 1,
    fossilMilesPerGallon: 6,
  },
  {
    id: 'on_road_pick_up_truck_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Pickup_Truck.png',
    rangeInMiles: 315,
    formFactor: 'pickup_truck',
    liftCapacity: 0,
    gvwr: 9000,
    weightClass: 2,
    batteryCapacityInKwh: 158,
    efficiencyInKwhPerHundredMiles: 50.16,
    msrp: 70600,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'gasoline',
    fossilFuelMsrp: 45000,
    fossilMaintenanceCostPerMile: 0.17,
    fossilMilesPerGallon: 19,
  },
  {
    id: 'on_road_pick_up_truck_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Pick-up Truck',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Pickup_Truck.png',
    rangeInMiles: 271,
    formFactor: 'pickup_truck',
    liftCapacity: 0,
    gvwr: 19500,
    weightClass: 5,
    batteryCapacityInKwh: 157,
    efficiencyInKwhPerHundredMiles: 57.93,
    msrp: 116000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 70000,
    fossilMaintenanceCostPerMile: 0.17,
    fossilMilesPerGallon: 9,
  },
  {
    id: 'on_road_pick_up_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Pick-up Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Pickup_Truck.png',
    rangeInMiles: 335,
    formFactor: 'pickup_truck',
    liftCapacity: 0,
    gvwr: 30000,
    weightClass: 8,
    batteryCapacityInKwh: 125,
    efficiencyInKwhPerHundredMiles: 37.31,
    msrp: 140000,
    maintenanceCostPerMile: 0.14,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 85000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 7,
  },
  {
    id: 'on_road_refuse_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Refuse Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Refuse_Truck.png',
    rangeInMiles: 101,
    formFactor: 'refuse_truck',
    liftCapacity: 0,
    gvwr: 66000,
    weightClass: 8,
    batteryCapacityInKwh: 302,
    efficiencyInKwhPerHundredMiles: 299.01,
    msrp: 500000,
    maintenanceCostPerMile: 2.05,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 300000,
    fossilMaintenanceCostPerMile: 3.07,
    fossilMilesPerGallon: 1.7,
  },
  {
    id: 'on_road_refuse_truck_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Refuse Truck',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Refuse_Truck.png',
    rangeInMiles: 125,
    formFactor: 'refuse_truck',
    liftCapacity: 0,
    gvwr: 26000,
    weightClass: 6,
    batteryCapacityInKwh: 221,
    efficiencyInKwhPerHundredMiles: 176.8,
    msrp: 198000,
    maintenanceCostPerMile: 1.5,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 120000,
    fossilMaintenanceCostPerMile: 2.24634146341463,
    fossilMilesPerGallon: 4.31538461538462,
  },
  {
    id: 'on_road_school_bus_type_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'School Bus',
    subtype: 'Type A',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_A_School_Bus.png',
    rangeInMiles: 125,
    formFactor: 'school_bus',
    liftCapacity: 0,
    gvwr: 16462.5,
    weightClass: 5,
    batteryCapacityInKwh: 130,
    efficiencyInKwhPerHundredMiles: 104,
    msrp: 238000,
    maintenanceCostPerMile: 0.2,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 150000,
    fossilMaintenanceCostPerMile: 0.24,
    fossilMilesPerGallon: 6,
  },
  {
    id: 'on_road_school_bus_type_c',
    name: 'Generic',
    category: 'On-Road',
    type: 'School Bus',
    subtype: 'Type C',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_C_School_Bus.png',
    rangeInMiles: 130,
    formFactor: 'school_bus',
    liftCapacity: 0,
    gvwr: 31200,
    weightClass: 7,
    batteryCapacityInKwh: 190,
    efficiencyInKwhPerHundredMiles: 146.15,
    msrp: 350000,
    maintenanceCostPerMile: 0.21,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 200000,
    fossilMaintenanceCostPerMile: 0.25,
    fossilMilesPerGallon: 5,
  },
  {
    id: 'on_road_school_bus_type_d',
    name: 'Generic',
    category: 'On-Road',
    type: 'School Bus',
    subtype: 'Type D',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_D_School_Bus.png',
    rangeInMiles: 130,
    formFactor: 'school_bus',
    liftCapacity: 0,
    gvwr: 34760,
    weightClass: 8,
    batteryCapacityInKwh: 175,
    efficiencyInKwhPerHundredMiles: 134.62,
    msrp: 400000,
    maintenanceCostPerMile: 0.22,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 250000,
    fossilMaintenanceCostPerMile: 0.26,
    fossilMilesPerGallon: 4,
  },
  {
    id: 'on_road_sedan_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Sedan',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Sedan.png',
    rangeInMiles: 250,
    formFactor: 'sedan',
    liftCapacity: 0,
    gvwr: 5000,
    weightClass: 1,
    batteryCapacityInKwh: 80,
    efficiencyInKwhPerHundredMiles: 32,
    msrp: 35000,
    maintenanceCostPerMile: 0.095,
    fossilFuelType: 'gasoline',
    fossilFuelMsrp: 25000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
  },
  {
    id: 'on_road_shuttle_bus_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Shuttle_Bus.png',
    rangeInMiles: 112,
    formFactor: 'shuttle_bus',
    liftCapacity: 0,
    gvwr: 16000,
    weightClass: 4,
    batteryCapacityInKwh: 115,
    efficiencyInKwhPerHundredMiles: 102.68,
    msrp: 216000,
    maintenanceCostPerMile: 0.12,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 136080,
    fossilMaintenanceCostPerMile: 0.35,
    fossilMilesPerGallon: 7,
  },
  {
    id: 'on_road_shuttle_bus_passenger_van',
    name: 'Generic',
    category: 'On-Road',
    type: 'Shuttle Bus',
    subtype: 'Passenger Van',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Passenger_Van_Shuttle_Bus.png',
    rangeInMiles: 200,
    formFactor: 'shuttle_bus',
    liftCapacity: 0,
    gvwr: 12461.2,
    weightClass: 3,
    batteryCapacityInKwh: 104,
    efficiencyInKwhPerHundredMiles: 52,
    msrp: 154000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 97020,
    fossilMaintenanceCostPerMile: 0.32,
    fossilMilesPerGallon: 16,
  },
  {
    id: 'on_road_specialty_bucket_truck',
    name: 'Generic',
    category: 'On-Road',
    type: 'Specialty',
    subtype: 'Bucket Truck',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Bucket_Truck.png',
    rangeInMiles: 130,
    formFactor: 'specialty',
    liftCapacity: 0,
    gvwr: 60000,
    weightClass: 8,
    batteryCapacityInKwh: 336,
    efficiencyInKwhPerHundredMiles: 258.46,
    msrp: 365000,
    maintenanceCostPerMile: 0.131,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 165000,
    fossilMaintenanceCostPerMile: 0.196,
    fossilMilesPerGallon: 4.4,
  },
  {
    id: 'on_road_step_van_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Step Van',
    subtype: 'Light Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Step_Van.png',
    rangeInMiles: 163,
    formFactor: 'step_van',
    liftCapacity: 0,
    gvwr: 9370,
    weightClass: 2,
    batteryCapacityInKwh: 100,
    efficiencyInKwhPerHundredMiles: 61.35,
    msrp: 55000,
    maintenanceCostPerMile: 0.13,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 34650,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
  },
  {
    id: 'on_road_step_van_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Step Van',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Step_Van.png',
    rangeInMiles: 200,
    formFactor: 'step_van',
    liftCapacity: 0,
    gvwr: 15000,
    weightClass: 3,
    batteryCapacityInKwh: 127,
    efficiencyInKwhPerHundredMiles: 63.5,
    msrp: 162000,
    maintenanceCostPerMile: 0.134,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 102060,
    fossilMaintenanceCostPerMile: 0.198,
    fossilMilesPerGallon: 6.3,
  },
  {
    id: 'on_road_suv_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'SUV',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/SUV.png',
    rangeInMiles: 317,
    formFactor: 'suv',
    liftCapacity: 0,
    gvwr: 7650,
    weightClass: 1,
    batteryCapacityInKwh: 140,
    efficiencyInKwhPerHundredMiles: 44.16,
    msrp: 76250,
    maintenanceCostPerMile: 0.095,
    fossilFuelType: 'gasoline',
    fossilFuelMsrp: 48037.5,
    fossilMaintenanceCostPerMile: 0.151,
    fossilMilesPerGallon: 22.7,
  },
  {
    id: 'on_road_trailer_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Trailer_Truck.png',
    rangeInMiles: 250,
    formFactor: 'trailer_truck',
    liftCapacity: 0,
    gvwr: 63842.3529411765,
    weightClass: 8,
    batteryCapacityInKwh: 445,
    efficiencyInKwhPerHundredMiles: 178,
    msrp: 300000,
    maintenanceCostPerMile: 0.15,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 150000,
    fossilMaintenanceCostPerMile: 0.18,
    fossilMilesPerGallon: 5,
  },
  {
    id: 'on_road_transit_bus_double_decker',
    name: 'Generic',
    category: 'On-Road',
    type: 'Transit Bus',
    subtype: 'Double Decker',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Double_Decker_Transit_Bus.png',
    rangeInMiles: 250,
    formFactor: 'transit_bus',
    liftCapacity: 0,
    gvwr: 33000,
    weightClass: 8,
    batteryCapacityInKwh: 600,
    efficiencyInKwhPerHundredMiles: 240,
    msrp: 1050000,
    maintenanceCostPerMile: 0.7,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 700000,
    fossilMaintenanceCostPerMile: 1.05,
    fossilMilesPerGallon: 6,
  },
  {
    id: 'on_road_transit_bus_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Mass_Transit_Bus.png',
    rangeInMiles: 186.56,
    formFactor: 'transit_bus',
    liftCapacity: 0,
    gvwr: 36578,
    weightClass: 8,
    batteryCapacityInKwh: 376,
    efficiencyInKwhPerHundredMiles: 201.54,
    msrp: 720000,
    maintenanceCostPerMile: 0.6,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 500000,
    fossilMaintenanceCostPerMile: 1,
    fossilMilesPerGallon: 6,
  },
  {
    id: 'on_road_transit_bus_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Transit Bus',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Transit_Bus.png',
    rangeInMiles: 150,
    formFactor: 'transit_bus',
    liftCapacity: 0,
    gvwr: 25500,
    weightClass: 6,
    batteryCapacityInKwh: 160,
    efficiencyInKwhPerHundredMiles: 106.67,
    msrp: 210000,
    maintenanceCostPerMile: 0.55,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 140000,
    fossilMaintenanceCostPerMile: 0.9,
    fossilMilesPerGallon: 6,
  },
  {
    id: 'on_road_transit_bus_articulated_bus',
    name: 'Generic',
    category: 'On-Road',
    type: 'Transit Bus',
    subtype: 'Articulated Bus',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Articulated_Bus.png',
    rangeInMiles: 153,
    formFactor: 'transit_bus',
    liftCapacity: 0,
    gvwr: 60000,
    weightClass: 8,
    batteryCapacityInKwh: 508,
    efficiencyInKwhPerHundredMiles: 332.03,
    msrp: 1170000,
    maintenanceCostPerMile: 0.75,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 750000,
    fossilMaintenanceCostPerMile: 1.2,
    fossilMilesPerGallon: 5,
  },
  {
    id: 'on_road_utility_truck_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Utility Truck',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Utility_Truck.png',
    rangeInMiles: 200,
    formFactor: 'utility_truck',
    liftCapacity: 0,
    gvwr: 10000,
    weightClass: 3,
    batteryCapacityInKwh: 142,
    efficiencyInKwhPerHundredMiles: 71,
    msrp: 125000,
    maintenanceCostPerMile: 0.13,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 80000,
    fossilMaintenanceCostPerMile: 0.31,
    fossilMilesPerGallon: 8,
  },

  {
    id: 'on_road_hatchback_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Hatchback',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Hatchback.png',
    rangeInMiles: 250,
    formFactor: 'hatchback',
    liftCapacity: 0,
    gvwr: 5000,
    weightClass: 1,
    batteryCapacityInKwh: 80,
    efficiencyInKwhPerHundredMiles: 32,
    msrp: 35000,
    maintenanceCostPerMile: 0.095,
    fossilFuelType: 'gasoline',
    fossilFuelMsrp: 25000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
  },
  {
    id: 'on_road_coupe_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Coupe',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Coupe.png',
    rangeInMiles: 250,
    formFactor: 'coupe',
    liftCapacity: 0,
    gvwr: 5000,
    weightClass: 1,
    batteryCapacityInKwh: 80,
    efficiencyInKwhPerHundredMiles: 32,
    msrp: 45000,
    maintenanceCostPerMile: 0.095,
    fossilFuelType: 'gasoline',
    fossilFuelMsrp: 35000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
  },
  {
    id: 'on_road_station_wagon_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Station Wagon',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Station_Wagon.png',
    rangeInMiles: 250,
    formFactor: 'station wagon',
    liftCapacity: 0,
    gvwr: 5000,
    weightClass: 1,
    batteryCapacityInKwh: 80,
    efficiencyInKwhPerHundredMiles: 32,
    msrp: 40000,
    maintenanceCostPerMile: 0.095,
    fossilFuelType: 'gasoline',
    fossilFuelMsrp: 30000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
  },
  {
    id: 'on_road_minivan_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Minivan',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Minivan.png',
    rangeInMiles: 250,
    formFactor: 'minivan',
    liftCapacity: 0,
    gvwr: 5000,
    weightClass: 1,
    batteryCapacityInKwh: 80,
    efficiencyInKwhPerHundredMiles: 32,
    msrp: 40000,
    maintenanceCostPerMile: 0.095,
    fossilFuelType: 'gasoline',
    fossilFuelMsrp: 30000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
  },
  {
    id: 'on_road_specialty_mobile_outreach_vehicle',
    name: 'Generic',
    category: 'On-Road',
    type: 'Specialty',
    subtype: 'Mobile Outreach Vehicle',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Mobile_Outreach_Vehicle.png',
    rangeInMiles: 105,
    formFactor: 'specialty',
    liftCapacity: 0,
    gvwr: 26000,
    weightClass: 6,
    batteryCapacityInKwh: 127,
    efficiencyInKwhPerHundredMiles: 120.95,
    msrp: 198000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 125000,
    fossilMaintenanceCostPerMile: 0.24,
    fossilMilesPerGallon: 7,
  },
];

export default VEHICLES;
