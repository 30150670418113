import {HOURS_IN_YEAR} from '@generic/data/assumptions';
import calcSitePlannerLoadProfile from './chargerSet/calcSitePlannerLoadProfile';

const calcChargerSetFacilityLoadProfile = ({chargerSets}) => {
  // hourly charged kWh for every charger set
  const schedules = chargerSets.map((set) =>
    calcSitePlannerLoadProfile(set).map((hour) => hour.chargedKwh)
  );

  return schedules.reduce(
    (sums, current) => sums.map((value, i) => value + current[i]),
    new Array(HOURS_IN_YEAR).fill(0)
  );
};

export default calcChargerSetFacilityLoadProfile;
