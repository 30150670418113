const CHARGER_CHARGING_WINDOWS = {
  low: [
    {
      start: 0,
      finish: 6,
      utilization: 0.0,
    },
    {
      start: 6,
      finish: 12,
      utilization: 0.25,
    },
    {
      start: 12,
      finish: 18,
      utilization: 0.25,
    },
    {
      start: 18,
      finish: 0,
      utilization: 0.0,
    },
  ],
  medium: [
    {
      start: 0,
      finish: 6,
      utilization: 0.1,
    },
    {
      start: 6,
      finish: 12,
      utilization: 0.5,
    },
    {
      start: 12,
      finish: 18,
      utilization: 0.5,
    },
    {
      start: 18,
      finish: 0,
      utilization: 0.1,
    },
  ],

  high: [
    {
      start: 0,
      finish: 6,
      utilization: 0.25,
    },
    {
      start: 6,
      finish: 12,
      utilization: 0.5,
    },
    {
      start: 12,
      finish: 18,
      utilization: 0.75,
    },
    {
      start: 18,
      finish: 0,
      utilization: 0.5,
    },
  ],
  custom: [
    {
      start: 0,
      finish: 6,
      utilization: 0.25,
    },
    {
      start: 6,
      finish: 12,
      utilization: 0.25,
    },
    {
      start: 12,
      finish: 18,
      utilization: 0.25,
    },
    {
      start: 18,
      finish: 0,
      utilization: 0.25,
    },
  ],
};

export default CHARGER_CHARGING_WINDOWS;
