import {AppBar, Toolbar, Typography, Button, Box} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useTheme, makeStyles} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import HeaderDropdownNav from '@generic/components/HeaderDropdownNav';
import printInputs from '../functions/printInputs';

const useStyles = makeStyles((theme) => ({
  logoImgWrapper: {
    marginRight: 'auto',
  },
  logoImg: {
    display: 'inline-block',
    height: '30px',
    [theme.breakpoints.up('md')]: {
      height: '37px',
    },
  },
  largeScreenHeader: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  smallScreenHeader: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Link = ({to, children, ...buttonProps}) => {
  const history = useHistory();

  return (
    <Button {...buttonProps} onClick={() => history.push(to)}>
      {children}
    </Button>
  );
};

export default function Header({className}) {
  const {formatMessage} = useIntl();
  const {
    header: {
      brandLogo: {src, alt},
    },
  } = useTheme();
  const classes = useStyles();
  const inputs = useInputs();

  return (
    <AppBar position="sticky" className={className}>
      <Toolbar>
        <Link color="inherit" to="/" className={classes.logoImgWrapper}>
          {src && <img src={src} alt={alt} className={classes.logoImg} />}
        </Link>
        <Box className={classes.largeScreenHeader}>
          <Link color="inherit" to="/sites" className="link">
            <Typography variant="body2">
              {formatMessage({id: 'header.links.sitePlanner'})}
            </Typography>
          </Link>
          <Link color="inherit" to="/fleets" className="link">
            <Typography variant="body2">{formatMessage({id: 'header.links.charging'})}</Typography>
          </Link>
          <Button
            target="_blank"
            href={formatMessage(
              {id: 'emailAnchorLink'},
              {
                inputs: printInputs(inputs),
              }
            )}
            className="button link MuiButton-colorInherit"
          >
            <Typography variant="body2">{formatMessage({id: 'header.links.emailUs'})}</Typography>
          </Button>
        </Box>
        <Box className={classes.smallScreenHeader}>
          <HeaderDropdownNav />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
