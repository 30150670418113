import {Box, FormControl, Select, MenuItem, makeStyles, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    // hack to make dropdown box take up full width, ignoring the container
    width: 'calc(100% + 40px)',
    marginLeft: '-20px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  formControl: {
    flexGrow: 1,
    marginLeft: '1rem',
  },
  select: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontWeight: 700,

    '& .MuiSelect-icon': {
      color: theme.palette.common.white,
    },

    // select underline
    '&::before': {
      borderBottomColor: theme.palette.common.white,
    },
  },
  menuItem: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
}));

export default function DropdownViews({views}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const [selectedView, setSelectedView] = useState(views[0]);

  const handleViewChange = (e) =>
    setSelectedView(views.find((view) => view.id === e.target.value) || views[0]);

  const {ContentComponent} = selectedView;

  return (
    <>
      <Box className={classes.root}>
        <Typography>{formatMessage({id: 'tabs.intro'})}</Typography>
        <FormControl className={classes.formControl}>
          <Select className={classes.select} value={selectedView.id} onChange={handleViewChange}>
            {views.map((view) => (
              <MenuItem key={view.id} value={view.id} className={classes.menuItem}>
                {view.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box my={4}>
        <ContentComponent />
      </Box>
    </>
  );
}
