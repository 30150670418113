import calcBatterySchedule from '@generic/functions/vehicleSet/calcBatterySchedule';
import VEHICLES from '@generic/data/vehicles';

const calcMinimumStateOfCharge = (vehicleSet) => {
  const {vehicle_id: vehicleId} = vehicleSet;
  const vehicle = VEHICLES.find((v) => v.id === vehicleId);

  const batterySchedule = calcBatterySchedule({...vehicleSet, vehicle});

  const minCharge = Math.min(...batterySchedule.map((x) => x.startKwh));
  const maxCharge = Math.max(...batterySchedule.map((x) => x.startKwh));
  return Math.round((minCharge / maxCharge) * 100);
};

export default calcMinimumStateOfCharge;
