import {Grid} from '@material-ui/core';
import DropdownInput from '@generic/components/inputs/DropdownInput';
import CHARGERS from '@generic/data/chargers';
import TIME_PERIODS from '@generic/config/charger';

const MAX_CHARGER_COUNT = 20;

export default function SelectCharger({chargerSet, onChangeChargerSet, variant = ''}) {
  const {chargerCount, type, chargersPerTimePeriod} = chargerSet;

  const chargerOptions = CHARGERS.map(({type}) => ({
    value: type,
    children: type,
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={2}>
        <DropdownInput
          fullWidth
          title="Count"
          onChange={(e) => {
            const newChargerCount = e.target.value;
            const overcharged = TIME_PERIODS.some(
              (period) => chargersPerTimePeriod[period.key] > newChargerCount
            );
            if (overcharged) {
              const newChargersPerTimePeriod = {};
              TIME_PERIODS.forEach((period) => {
                newChargersPerTimePeriod[period.key] =
                  chargersPerTimePeriod[period.key] > newChargerCount
                    ? newChargerCount
                    : chargersPerTimePeriod[period.key];
              });
              onChangeChargerSet({
                chargersPerTimePeriod: newChargersPerTimePeriod,
                chargerCount: newChargerCount,
              });
            } else {
              onChangeChargerSet({chargerCount: newChargerCount});
            }
          }}
          options={[...Array(MAX_CHARGER_COUNT).keys()].map((idx) => ({
            value: idx + 1,
            children: idx + 1,
          }))}
          value={chargerCount}
          variant={variant}
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <DropdownInput
          fullWidth
          title="Type"
          onChange={(e) => onChangeChargerSet({type: e.target.value})}
          options={chargerOptions}
          value={type}
          variant={variant}
        />
      </Grid>
    </Grid>
  );
}
