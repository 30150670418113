import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.common.black}`,
  },
  topCard: {
    padding: '8px 20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  topCardSubheader: {
    fontWeight: 300,
    fontStyle: 'italic',
    marginLeft: '10px',
  },
  bottomCard: {
    padding: '8px 20px',
  },
  bottomCardText: {
    fontWeight: 700,
  },
}));

export default function ElectricityBillHeader({rateTitle}) {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.topCard}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6">
            {formatMessage({id: 'charging.content.costs.electricity.bill.header'})}
          </Typography>
          <Typography variant="body2" className={classes.topCardSubheader}>
            {rateTitle}
          </Typography>
        </Box>
        <Typography>
          {formatMessage({id: 'charging.content.costs.electricity.bill.subheader'})}
        </Typography>
      </Box>
      <Box className={classes.bottomCard}>
        <Typography variant="body2" className={classes.bottomCardText}>
          {formatMessage({
            id: 'charging.content.costs.electricity.bill.billingPeriod',
          })}
        </Typography>
      </Box>
    </Box>
  );
}
