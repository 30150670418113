import sc9Rate1 from './sc9Rate1';
import sc9Rate2 from './sc9Rate2';
import sc9Rate3 from './sc9Rate3';
import sc9Rate4 from './sc9Rate4';
import sc9Rate5 from './sc9Rate5';

const RATES = [sc9Rate1, sc9Rate2, sc9Rate3, sc9Rate4, sc9Rate5];

const isRateApplicable = ({minKw, maxKw}, demand) => {
  // both are defined, demand has to be in between
  if (minKw !== undefined && maxKw !== undefined) {
    return demand >= minKw && demand <= maxKw;
  }
  // only min is defined, demand has to be greater
  if (minKw !== undefined) {
    return demand >= minKw;
  }
  // only max is defined, demand has to be less
  if (maxKw !== undefined) {
    return demand <= maxKw;
  }
  // neither is defined, always applicable
  return true;
};

export const getRates = ({voltageLevel, zone, isOnMarketBasedRate}, demand) => {
  const isHighTension = voltageLevel === 'high';

  const isLocatedInWestchester = zone === 'I' || zone === 'H';

  // zone input determines hourly price profiles
  // voltage level input determines charges for rate components
  const allRates = RATES.map((rateFn) =>
    rateFn({zone, isHighTension, isOnMarketBasedRate, isLocatedInWestchester})
  );

  // if rate has minKw or maxKw, make sure it's applicable to user's max demand
  const applicableRates = allRates.filter((rate) => isRateApplicable(rate, demand));

  return applicableRates;
};

export const getRatesIgnoringDemand = (inputs) => [
  ...getRates(inputs, Number.NEGATIVE_INFINITY),
  ...getRates(inputs, Number.POSITIVE_INFINITY),
];

export default RATES;
