function hourBuilder(start, end) {
  const hours = new Array(24).fill(0);
  let i = start;
  if (start < end) {
    // eslint-disable-next-line no-plusplus
    for (i; i < end; i++) {
      hours[i] = 1;
    }
    return hours;
  }
  // eslint-disable-next-line no-plusplus
  for (i; i < 24; i++) {
    hours[i] = 1;
  }
  i = 0;
  // eslint-disable-next-line no-plusplus
  for (i; i <= end; i++) {
    hours[i] = 1;
  }
  return hours;
}

const TIME_PERIODS = [
  {
    key: '10pm_8am',
    label: '10pm to 8am (Overnight)',
    maxTimePeriod: 5,
    hours: hourBuilder(22, 8),
  },
  {
    key: '8am_6pm',
    label: '8am to 6pm',
    maxTimePeriod: 12,
    hours: hourBuilder(8, 18),
  },
  {
    key: '6pm_10pm',
    label: '6pm to 10pm',
    maxTimePeriod: 20,
    hours: hourBuilder(18, 22),
  },
];

export default TIME_PERIODS;
