const sharedConEdStaticRateElements = [
  {
    rateElementType: 'MonthlyEnergy',
    name: 'Clean Energy Standard Delivery Surcharge ("CESD")',
    billingCategory: 'delivery',
    rateComponents: [
      {
        charge: 0.000272,
        name: 'Clean Energy Standard Delivery Surcharge ("CESD")',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'Clean Energy Standard Supply Surcharge',
    billingCategory: 'supply',
    rateComponents: [
      {
        charge: 0.006069,
        name: 'Clean Energy Standard Supply Surcharge',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'Delivery Revenue Surcharge',
    billingCategory: 'delivery',
    rateComponents: [
      {
        charge: -0.000234,
        name: 'Delivery Revenue Surcharge',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'MAC Adjustment Factor',
    billingCategory: 'delivery',
    rateComponents: [
      {
        charge: 0.008009,
        name: 'MAC Adjustment Factor',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'Merchant Function Charge',
    billingCategory: 'supply',
    rateComponents: [
      {
        charge: 0.001133,
        name: 'Merchant Function Charge',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'Monthly Adjustment Clause ("MAC")',
    billingCategory: 'delivery',
    rateComponents: [
      {
        charge: 0.00583,
        name: 'Monthly Adjustment Clause ("MAC")',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'MSC Adjustment Factor',
    billingCategory: 'supply',
    rateComponents: [
      {
        charge: -0.015251,
        name: 'MSC Adjustment Factor',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'Revenue Decoupling Mechanism ("RDM")',
    billingCategory: 'delivery',
    rateComponents: [
      {
        charge: -0.0015,
        name: 'Revenue Decoupling Mechanism ("RDM")',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'System Benefit Charge ("SBC")',
    billingCategory: 'delivery',
    rateComponents: [
      {
        charge: 0.0065,
        name: 'System Benefit Charge ("SBC")',
      },
    ],
  },
  {
    rateElementType: 'MonthlyEnergy',
    name: 'Tax Sur-Credit',
    billingCategory: 'delivery',
    rateComponents: [
      {
        charge: 0.0,
        name: 'Tax Sur-Credit',
      },
    ],
  },
];

export default sharedConEdStaticRateElements;
