import React from 'react';
import Alert from '@material-ui/lab/Alert';
import {useIntl} from 'react-intl';
import calcMinimumStateOfCharge from '@generic/functions/vehicleSet/calcMinimumStateOfCharge';
import VEHICLES from '@generic/data/vehicles';
import {makeStyles} from '@material-ui/core';

const findMinStateOfChargeAlert = (minStateOfCharge) => {
  let severity;
  let msgId;
  if (minStateOfCharge > 20) {
    severity = 'info';
    msgId = 'vehicleSetDialog.alerts.stateOfChargeInfo';
  } else if (minStateOfCharge >= 0) {
    severity = 'warning';
    msgId = 'vehicleSetDialog.alerts.stateOfChargeWarning';
  } else {
    severity = 'error';
    msgId = 'vehicleSetDialog.alerts.stateOfChargeError';
  }

  return {
    severity,
    msgId,
  };
};

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
}));

export default function VehicleSetAlerts({vehicleSet}) {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const {vehicle_id: vehicleId, milesPerWorkday} = vehicleSet;
  const vehicle = VEHICLES.find((v) => v.id === vehicleId);

  const alerts = [];

  if (vehicle.rangeInMiles < milesPerWorkday) {
    alerts.push({
      severity: 'warning',
      msg: formatMessage({id: 'vehicleSetDialog.alerts.milesGreaterThanRange'}),
    });
  }

  const minStateOfCharge = calcMinimumStateOfCharge(vehicleSet);

  const {severity, msgId} = findMinStateOfChargeAlert(minStateOfCharge);
  alerts.push({
    severity,
    msg: formatMessage({id: msgId}, {stateOfCharge: minStateOfCharge}),
  });

  return (
    <>
      {alerts.map((alert, idx) => (
        <Alert key={idx} severity={alert.severity} className={classes.alert}>
          {alert.msg}
        </Alert>
      ))}
    </>
  );
}
