const zones = [
  {id: 'H', translationKey: 'inputs.advanced.zone.h'},
  {id: 'I', translationKey: 'inputs.advanced.zone.i'},
  {id: 'J', translationKey: 'inputs.advanced.zone.j'},
];

const unsortedZonesByZip = {
  10001: 'J',
  10002: 'J',
  10003: 'J',
  10004: 'J',
  10005: 'J',
  10006: 'J',
  10007: 'J',
  10008: 'J',
  10009: 'J',
  10010: 'J',
  10011: 'J',
  10012: 'J',
  10013: 'J',
  10014: 'J',
  10016: 'J',
  10017: 'J',
  10018: 'J',
  10019: 'J',
  10020: 'J',
  10021: 'J',
  10022: 'J',
  10023: 'J',
  10024: 'J',
  10025: 'J',
  10026: 'J',
  10027: 'J',
  10028: 'J',
  10029: 'J',
  10030: 'J',
  10031: 'J',
  10032: 'J',
  10033: 'J',
  10034: 'J',
  10035: 'J',
  10036: 'J',
  10037: 'J',
  10038: 'J',
  10039: 'J',
  10040: 'J',
  10041: 'J',
  10044: 'J',
  10045: 'J',
  10065: 'J',
  10069: 'J',
  10075: 'J',
  10080: 'J',
  10103: 'J',
  10104: 'J',
  10105: 'J',
  10106: 'J',
  10110: 'J',
  10111: 'J',
  10112: 'J',
  10115: 'J',
  10118: 'J',
  10119: 'J',
  10120: 'J',
  10121: 'J',
  10122: 'J',
  10123: 'J',
  10128: 'J',
  10151: 'J',
  10152: 'J',
  10153: 'J',
  10154: 'J',
  10155: 'J',
  10158: 'J',
  10162: 'J',
  10165: 'J',
  10166: 'J',
  10167: 'J',
  10168: 'J',
  10170: 'J',
  10171: 'J',
  10172: 'J',
  10173: 'J',
  10174: 'J',
  10175: 'J',
  10176: 'J',
  10177: 'J',
  10178: 'J',
  10199: 'J',
  10270: 'J',
  10271: 'J',
  10278: 'J',
  10279: 'J',
  10280: 'J',
  10281: 'J',
  10282: 'J',
  10285: 'J',
  10451: 'J',
  10452: 'J',
  10453: 'J',
  10454: 'J',
  10455: 'J',
  10456: 'J',
  10457: 'J',
  10458: 'J',
  10459: 'J',
  10460: 'J',
  10461: 'J',
  10462: 'J',
  10463: 'J',
  10464: 'J',
  10465: 'J',
  10466: 'J',
  10467: 'J',
  10468: 'J',
  10469: 'J',
  10470: 'J',
  10471: 'J',
  10472: 'J',
  10473: 'J',
  10474: 'J',
  10475: 'J',
  10502: 'I',
  10503: 'I',
  10504: 'I',
  10506: 'H',
  10507: 'H',
  10510: 'I',
  10511: 'H',
  10514: 'H',
  10517: 'H',
  10520: 'H',
  10522: 'I',
  10523: 'I',
  10528: 'I',
  10530: 'I',
  10532: 'I',
  10533: 'I',
  10538: 'I',
  10543: 'I',
  10545: 'H',
  10546: 'H',
  10547: 'H',
  10548: 'H',
  10549: 'H',
  10550: 'I',
  10551: 'I',
  10552: 'I',
  10553: 'I',
  10562: 'H',
  10566: 'H',
  10567: 'H',
  10570: 'I',
  10571: 'I',
  10572: 'I',
  10573: 'I',
  10577: 'I',
  10580: 'I',
  10583: 'I',
  10588: 'H',
  10591: 'I',
  10594: 'I',
  10595: 'I',
  10596: 'H',
  10598: 'H',
  10601: 'I',
  10602: 'I',
  10603: 'I',
  10604: 'I',
  10605: 'I',
  10606: 'I',
  10607: 'I',
  10610: 'I',
  10701: 'I',
  10702: 'I',
  10703: 'I',
  10704: 'I',
  10705: 'I',
  10706: 'I',
  10707: 'I',
  10708: 'I',
  10709: 'I',
  10710: 'I',
  10801: 'I',
  10802: 'I',
  10803: 'I',
  10804: 'I',
  10805: 'I',
  11040: 'J',
  11101: 'J',
  11102: 'J',
  11103: 'J',
  11104: 'J',
  11105: 'J',
  11106: 'J',
  11109: 'J',
  11201: 'J',
  11202: 'J',
  11203: 'J',
  11204: 'J',
  11205: 'J',
  11206: 'J',
  11207: 'J',
  11208: 'J',
  11209: 'J',
  11210: 'J',
  11211: 'J',
  11212: 'J',
  11213: 'J',
  11214: 'J',
  11215: 'J',
  11216: 'J',
  11217: 'J',
  11218: 'J',
  11219: 'J',
  11220: 'J',
  11221: 'J',
  11222: 'J',
  11223: 'J',
  11224: 'J',
  11225: 'J',
  11226: 'J',
  11228: 'J',
  11229: 'J',
  11230: 'J',
  11231: 'J',
  11232: 'J',
  11233: 'J',
  11234: 'J',
  11235: 'J',
  11236: 'J',
  11237: 'J',
  11238: 'J',
  11239: 'J',
  11241: 'J',
  11242: 'J',
  11249: 'J',
  11252: 'J',
  11279: 'J',
  11354: 'J',
  11355: 'J',
  11356: 'J',
  11357: 'J',
  11358: 'J',
  11359: 'J',
  11360: 'J',
  11361: 'J',
  11362: 'J',
  11363: 'J',
  11364: 'J',
  11365: 'J',
  11366: 'J',
  11367: 'J',
  11368: 'J',
  11369: 'J',
  11370: 'J',
  11372: 'J',
  11373: 'J',
  11374: 'J',
  11375: 'J',
  11377: 'J',
  11378: 'J',
  11379: 'J',
  11385: 'J',
  11411: 'J',
  11412: 'J',
  11413: 'J',
  11414: 'J',
  11415: 'J',
  11416: 'J',
  11417: 'J',
  11418: 'J',
  11419: 'J',
  11420: 'J',
  11421: 'J',
  11422: 'J',
  11423: 'J',
  11424: 'J',
  11426: 'J',
  11427: 'J',
  11428: 'J',
  11429: 'J',
  11430: 'J',
  11431: 'J',
  11432: 'J',
  11433: 'J',
  11434: 'J',
  11435: 'J',
  11436: 'J',
  11439: 'J',
  11580: 'J',
  11693: 'J',
};

const sortedZips = Object.keys(unsortedZonesByZip)
  .sort((a, b) => Number(a) - Number(b))
  .map((zip) => zip.toString());

export const zonesByZipcode = sortedZips.reduce((zips, zip) => {
  const copiedZips = {...zips};
  const zone = unsortedZonesByZip[zip];
  if (zone) {
    copiedZips[zip] = zone;
  }
  return copiedZips;
}, {});

export default zones;
