import React from 'react';
import {Box, useTheme, Typography} from '@material-ui/core';
import {Bar} from 'react-chartjs-2';
import {formatAsThousands, formatVehicleSetTitle} from '@generic/functions/formatters';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import calcAnnualElectricityMaintenanceCosts from '@generic/functions/calcAnnualElectricityMaintenanceCosts';
import calcAnnualFossilFuelMaintenanceCosts from '@generic/functions/calcAnnualFossilFuelMaintenanceCosts';

export default function MaintenanceCostsChart() {
  const theme = useTheme();
  const {formatMessage} = useIntl();

  const {vehicleSets} = useInputs();

  const datasets = vehicleSets.map((vehicleSet, idx) => {
    const data = [
      calcAnnualElectricityMaintenanceCosts({vehicleSets: [vehicleSet]}),
      calcAnnualFossilFuelMaintenanceCosts({vehicleSets: [vehicleSet]}),
    ];
    const label = formatVehicleSetTitle(vehicleSet);
    return {
      idx,
      label,
      data,
      backgroundColor: [
        `rgba(253, 163, 53, ${1 - idx / 5})`,
        `rgba(151, 151, 151, ${1 - idx / 5})`,
      ],
    };
  });

  const data = {
    labels: [
      formatMessage({id: 'charging.content.costs.maintenance.chart.electricLabel'}),
      formatMessage({id: 'charging.content.costs.maintenance.chart.fossilLabel'}),
    ],
    datasets,
  };

  const options = {
    tooltips: {
      callbacks: {
        label: (data, context) =>
          `${context.datasets[data.datasetIndex].label}: $${formatAsThousands(+data.value)}`,
      },
    },
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        color: theme.palette.common.black,
        font: {
          size: 12,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontColor: '#2B2B2B',
            callback: (value) => `$${formatAsThousands(+value)}`,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <Box mt={4}>
      <Typography variant="body2">
        {formatMessage({id: 'charging.content.costs.maintenance.chart.label'})}
      </Typography>
      <Box height={350}>
        <Bar data={data} options={options} datasetKeyProvider={(d) => d.idx} />
      </Box>
    </Box>
  );
}
