const quickServiceRestaurantLoadProfile = [
  6.58, 5.46, 5.39, 5.21, 5.1, 5.86, 5.66, 4.44, 4.09, 4.38, 4.62, 4.95, 5.41, 5.83, 6.04, 6.4,
  7.36, 8.58, 9.24, 9.39, 9.05, 8.16, 7.46, 7.08, 6.58, 5.45, 5.39, 5.24, 5.1, 5.88, 5.7, 4.47,
  4.11, 4.39, 4.62, 4.91, 5.38, 5.79, 5.99, 6.33, 7.31, 8.53, 9.21, 9.4, 9.06, 8.18, 7.49, 7.09,
  6.59, 5.47, 5.4, 5.23, 5.1, 5.88, 5.7, 4.53, 4.1, 4.39, 4.63, 4.9, 5.38, 5.8, 5.98, 6.33, 7.37,
  8.53, 9.2, 9.39, 9.07, 8.18, 7.48, 7.11, 6.58, 5.46, 5.38, 5.19, 5.08, 5.86, 5.66, 4.47, 4.09,
  4.38, 4.64, 4.93, 5.43, 5.84, 6.03, 6.41, 7.43, 8.58, 9.25, 9.4, 9.05, 8.18, 7.48, 7.06, 6.46,
  5.45, 5.36, 5.13, 4.77, 5.51, 5.34, 4.43, 4.3, 4.29, 4.73, 5.14, 5.5, 5.87, 6.18, 6.47, 7.24,
  8.34, 8.76, 8.72, 8.63, 8.12, 7.46, 6.97, 6.51, 5.45, 5.35, 5.09, 4.73, 5.51, 5.32, 4.4, 4.28,
  4.2, 4.49, 5.07, 5.33, 5.76, 6.05, 6.35, 7.13, 8.29, 8.7, 8.69, 8.6, 8.11, 7.5, 7.03, 6.5, 5.41,
  5.34, 5.16, 5.05, 5.8, 5.65, 4.42, 4.09, 4.36, 4.63, 4.94, 5.42, 5.83, 6.04, 6.41, 7.36, 8.58,
  9.25, 9.39, 9.04, 8.17, 7.5, 7.1, 6.58, 5.46, 5.39, 5.21, 5.1, 5.86, 5.66, 4.44, 4.09, 4.38, 4.62,
  4.95, 5.41, 5.83, 6.04, 6.4, 7.36, 8.58, 9.24, 9.39, 9.05, 8.16, 7.46, 7.08, 6.58, 5.45, 5.39,
  5.24, 5.1, 5.88, 5.7, 4.47, 4.11, 4.39, 4.62, 4.91, 5.38, 5.79, 5.99, 6.33, 7.31, 8.53, 9.21, 9.4,
  9.06, 8.18, 7.49, 7.09, 6.59, 5.47, 5.4, 5.23, 5.1, 5.88, 5.7, 4.53, 4.1, 4.39, 4.63, 4.9, 5.38,
  5.8, 5.98, 6.33, 7.37, 8.53, 9.2, 9.39, 9.07, 8.18, 7.48, 7.11, 6.58, 5.46, 5.38, 5.19, 5.08,
  5.86, 5.66, 4.47, 4.09, 4.38, 4.64, 4.93, 5.43, 5.84, 6.03, 6.41, 7.43, 8.58, 9.25, 9.4, 9.05,
  8.18, 7.48, 7.06, 6.46, 5.45, 5.36, 5.13, 4.77, 5.51, 5.34, 4.43, 4.3, 4.29, 4.73, 5.14, 5.5,
  5.87, 6.18, 6.47, 7.24, 8.34, 8.76, 8.72, 8.63, 8.12, 7.46, 6.97, 6.51, 5.45, 5.35, 5.09, 4.73,
  5.51, 5.32, 4.4, 4.28, 4.2, 4.49, 5.07, 5.33, 5.76, 6.05, 6.35, 7.13, 8.29, 8.7, 8.69, 8.6, 8.11,
  7.5, 7.03, 6.5, 5.41, 5.34, 5.16, 5.05, 5.8, 5.65, 4.42, 4.09, 4.36, 4.63, 4.94, 5.42, 5.83, 6.04,
  6.41, 7.36, 8.58, 9.25, 9.39, 9.04, 8.17, 7.5, 7.1, 6.58, 5.46, 5.39, 5.21, 5.1, 5.86, 5.66, 4.44,
  4.09, 4.38, 4.62, 4.95, 5.41, 5.83, 6.04, 6.4, 7.36, 8.58, 9.24, 9.39, 9.05, 8.16, 7.46, 7.08,
  6.58, 5.45, 5.39, 5.24, 5.1, 5.88, 5.7, 4.47, 4.11, 4.39, 4.62, 4.91, 5.38, 5.79, 5.99, 6.33,
  7.31, 8.53, 9.21, 9.4, 9.06, 8.18, 7.49, 7.09, 6.59, 5.47, 5.4, 5.23, 5.1, 5.88, 5.7, 4.53, 4.1,
  4.39, 4.63, 4.9, 5.38, 5.8, 5.98, 6.33, 7.37, 8.53, 9.2, 9.39, 9.07, 8.18, 7.48, 7.11, 6.58, 5.46,
  5.38, 5.19, 5.08, 5.86, 5.66, 4.47, 4.09, 4.38, 4.64, 4.93, 5.43, 5.84, 6.03, 6.41, 7.43, 8.58,
  9.25, 9.4, 9.05, 8.18, 7.48, 7.06, 6.46, 5.45, 5.36, 5.13, 4.77, 5.51, 5.34, 4.43, 4.3, 4.29,
  4.73, 5.14, 5.5, 5.87, 6.18, 6.47, 7.24, 8.34, 8.76, 8.72, 8.63, 8.12, 7.46, 6.97, 6.51, 5.45,
  5.35, 5.09, 4.73, 5.51, 5.32, 4.4, 4.28, 4.2, 4.49, 5.07, 5.33, 5.76, 6.05, 6.35, 7.13, 8.29, 8.7,
  8.69, 8.6, 8.11, 7.5, 7.03, 6.5, 5.41, 5.34, 5.16, 5.05, 5.8, 5.65, 4.42, 4.09, 4.36, 4.63, 4.94,
  5.42, 5.83, 6.04, 6.41, 7.36, 8.58, 9.25, 9.39, 9.04, 8.17, 7.5, 7.1, 6.58, 5.46, 5.39, 5.21, 5.1,
  5.86, 5.66, 4.44, 4.09, 4.38, 4.62, 4.95, 5.41, 5.83, 6.04, 6.4, 7.36, 8.58, 9.24, 9.39, 9.05,
  8.16, 7.46, 7.08, 6.58, 5.45, 5.39, 5.24, 5.1, 5.88, 5.7, 4.47, 4.11, 4.39, 4.62, 4.91, 5.38,
  5.79, 5.99, 6.33, 7.31, 8.53, 9.21, 9.4, 9.06, 8.18, 7.49, 7.09, 6.59, 5.47, 5.4, 5.23, 5.1, 5.88,
  5.7, 4.53, 4.1, 4.39, 4.63, 4.9, 5.38, 5.8, 5.98, 6.33, 7.37, 8.53, 9.2, 9.39, 9.07, 8.18, 7.48,
  7.11, 6.58, 5.46, 5.38, 5.19, 5.08, 5.86, 5.66, 4.47, 4.09, 4.38, 4.64, 4.93, 5.43, 5.84, 6.03,
  6.41, 7.43, 8.58, 9.25, 9.4, 9.05, 8.18, 7.48, 7.06, 6.46, 5.45, 5.36, 5.13, 4.77, 5.51, 5.34,
  4.43, 4.3, 4.29, 4.73, 5.14, 5.5, 5.87, 6.18, 6.47, 7.24, 8.34, 8.76, 8.72, 8.63, 8.12, 7.46,
  6.97, 6.51, 5.45, 5.35, 5.09, 4.73, 5.51, 5.32, 4.4, 4.28, 4.2, 4.49, 5.07, 5.33, 5.76, 6.05,
  6.35, 7.13, 8.29, 8.7, 8.69, 8.6, 8.11, 7.5, 7.03, 6.5, 5.41, 5.34, 5.16, 5.05, 5.8, 5.65, 4.42,
  4.09, 4.36, 4.63, 4.94, 5.42, 5.83, 6.04, 6.41, 7.36, 8.58, 9.25, 9.39, 9.04, 8.17, 7.5, 7.1,
  6.58, 5.46, 5.39, 5.21, 5.1, 5.86, 5.66, 4.44, 4.09, 4.38, 4.62, 4.95, 5.41, 5.83, 6.04, 6.4,
  7.36, 8.58, 9.24, 9.39, 9.05, 8.16, 7.46, 7.08, 6.58, 5.45, 5.39, 5.24, 5.1, 5.88, 5.7, 4.47,
  4.11, 4.39, 4.62, 4.91, 5.38, 5.79, 5.99, 6.33, 7.31, 8.53, 9.21, 9.4, 9.06, 8.18, 7.49, 7.09,
  6.59, 5.47, 5.4, 5.23, 5.1, 5.88, 5.7, 4.53, 4.1, 4.39, 4.63, 4.9, 5.38, 5.8, 5.98, 6.33, 7.37,
  8.53, 9.2, 9.39, 9.07, 8.18, 7.48, 7.11, 6.51, 5.46, 5.38, 5.19, 5.08, 5.81, 5.48, 4.26, 4.08,
  4.39, 4.66, 5.03, 5.55, 5.97, 6.09, 6.43, 7.15, 8.39, 9.27, 9.41, 9.06, 8.19, 7.48, 7.06, 6.46,
  5.44, 5.36, 5.13, 4.79, 5.54, 5.19, 4.23, 4.3, 4.28, 4.74, 5.16, 5.48, 5.88, 6.19, 6.46, 7.0,
  8.16, 8.76, 8.73, 8.64, 8.09, 7.45, 6.97, 6.53, 5.45, 5.36, 5.11, 4.75, 5.52, 5.19, 4.2, 4.29,
  4.21, 4.49, 5.08, 5.37, 5.78, 6.08, 6.36, 6.95, 8.11, 8.73, 8.7, 8.61, 8.13, 7.54, 7.05, 6.56,
  5.45, 5.38, 5.2, 5.1, 5.87, 5.44, 4.27, 4.09, 4.37, 4.64, 4.96, 5.43, 5.85, 6.04, 6.43, 7.12,
  8.39, 9.27, 9.4, 9.04, 8.18, 7.48, 7.09, 6.54, 5.46, 5.39, 5.19, 5.09, 5.86, 5.44, 4.25, 4.09,
  4.37, 4.63, 4.97, 5.43, 5.85, 6.05, 6.42, 7.13, 8.36, 9.27, 9.41, 9.06, 8.2, 7.49, 7.11, 6.6,
  5.46, 5.4, 5.25, 5.12, 5.89, 5.5, 4.32, 4.12, 4.38, 4.6, 4.93, 5.39, 5.79, 6.0, 6.34, 7.08, 8.28,
  9.23, 9.4, 9.05, 8.18, 7.47, 7.09, 6.61, 5.46, 5.39, 5.24, 5.1, 5.85, 5.6, 4.3, 4.1, 4.39, 4.6,
  4.95, 5.44, 5.87, 6.05, 6.39, 7.13, 8.37, 9.27, 9.43, 9.08, 8.24, 7.52, 7.1, 6.51, 5.46, 5.38,
  5.19, 5.08, 5.81, 5.48, 4.26, 4.08, 4.39, 4.66, 5.03, 5.55, 5.97, 6.09, 6.43, 7.15, 8.39, 9.27,
  9.41, 9.06, 8.19, 7.48, 7.06, 6.46, 5.44, 5.36, 5.13, 4.79, 5.54, 5.19, 4.23, 4.3, 4.28, 4.74,
  5.16, 5.48, 5.88, 6.19, 6.46, 7.0, 8.16, 8.76, 8.73, 8.64, 8.09, 7.45, 6.97, 6.53, 5.45, 5.36,
  5.11, 4.75, 5.52, 5.19, 4.2, 4.29, 4.21, 4.49, 5.08, 5.37, 5.78, 6.08, 6.36, 6.95, 8.11, 8.73,
  8.7, 8.61, 8.13, 7.54, 7.05, 6.56, 5.45, 5.38, 5.2, 5.1, 5.87, 5.44, 4.27, 4.09, 4.37, 4.64, 4.96,
  5.43, 5.85, 6.04, 6.43, 7.12, 8.39, 9.27, 9.4, 9.04, 8.18, 7.48, 7.09, 6.54, 5.46, 5.39, 5.19,
  5.09, 5.86, 5.44, 4.25, 4.09, 4.37, 4.63, 4.97, 5.43, 5.85, 6.05, 6.42, 7.13, 8.36, 9.27, 9.41,
  9.06, 8.2, 7.49, 7.11, 6.6, 5.46, 5.4, 5.25, 5.12, 5.89, 5.5, 4.32, 4.12, 4.38, 4.6, 4.93, 5.39,
  5.79, 6.0, 6.34, 7.08, 8.28, 9.23, 9.4, 9.05, 8.18, 7.47, 7.09, 6.61, 5.46, 5.39, 5.24, 5.1, 5.85,
  5.6, 4.3, 4.1, 4.39, 4.6, 4.95, 5.44, 5.87, 6.05, 6.39, 7.13, 8.37, 9.27, 9.43, 9.08, 8.24, 7.52,
  7.1, 6.51, 5.46, 5.38, 5.19, 5.08, 5.81, 5.48, 4.26, 4.08, 4.39, 4.66, 5.03, 5.55, 5.97, 6.09,
  6.43, 7.15, 8.39, 9.27, 9.41, 9.06, 8.19, 7.48, 7.06, 6.46, 5.44, 5.36, 5.13, 4.79, 5.54, 5.19,
  4.23, 4.3, 4.28, 4.74, 5.16, 5.48, 5.88, 6.19, 6.46, 7.0, 8.16, 8.76, 8.73, 8.64, 8.09, 7.45,
  6.97, 6.53, 5.45, 5.36, 5.11, 4.75, 5.52, 5.19, 4.2, 4.29, 4.21, 4.49, 5.08, 5.37, 5.78, 6.08,
  6.36, 6.95, 8.11, 8.73, 8.7, 8.61, 8.13, 7.54, 7.05, 6.56, 5.45, 5.38, 5.2, 5.1, 5.87, 5.44, 4.27,
  4.09, 4.37, 4.64, 4.96, 5.43, 5.85, 6.04, 6.43, 7.12, 8.39, 9.27, 9.4, 9.04, 8.18, 7.48, 7.09,
  6.54, 5.46, 5.39, 5.19, 5.09, 5.86, 5.44, 4.25, 4.09, 4.37, 4.63, 4.97, 5.43, 5.85, 6.05, 6.42,
  7.13, 8.36, 9.27, 9.41, 9.06, 8.2, 7.49, 7.11, 6.6, 5.46, 5.4, 5.25, 5.12, 5.89, 5.5, 4.32, 4.12,
  4.38, 4.6, 4.93, 5.39, 5.79, 6.0, 6.34, 7.08, 8.28, 9.23, 9.4, 9.05, 8.18, 7.47, 7.09, 6.61, 5.46,
  5.39, 5.24, 5.1, 5.85, 5.6, 4.3, 4.1, 4.39, 4.6, 4.95, 5.44, 5.87, 6.05, 6.39, 7.13, 8.37, 9.27,
  9.43, 9.08, 8.24, 7.52, 7.1, 6.51, 5.46, 5.38, 5.19, 5.08, 5.81, 5.48, 4.26, 4.08, 4.39, 4.66,
  5.03, 5.55, 5.97, 6.09, 6.43, 7.15, 8.39, 9.27, 9.41, 9.06, 8.19, 7.48, 7.06, 6.46, 5.44, 5.36,
  5.13, 4.79, 5.54, 5.19, 4.23, 4.3, 4.28, 4.74, 5.16, 5.48, 5.88, 6.19, 6.46, 7.0, 8.16, 8.76,
  8.73, 8.64, 8.09, 7.45, 6.97, 6.53, 5.45, 5.36, 5.11, 4.75, 5.52, 5.19, 4.2, 4.29, 4.21, 4.49,
  5.08, 5.37, 5.78, 6.08, 6.36, 6.95, 8.11, 8.73, 8.7, 8.61, 8.13, 7.54, 7.05, 6.56, 5.45, 5.38,
  5.2, 5.1, 5.87, 5.44, 4.27, 4.09, 4.37, 4.64, 4.96, 5.43, 5.85, 6.04, 6.43, 7.12, 8.39, 9.27, 9.4,
  9.04, 8.18, 7.48, 7.09, 6.54, 5.46, 5.39, 5.19, 5.09, 5.86, 5.44, 4.25, 4.09, 4.37, 4.63, 4.97,
  5.43, 5.85, 6.05, 6.42, 7.13, 8.36, 9.27, 9.41, 9.06, 8.2, 7.49, 7.11, 6.6, 5.46, 5.4, 5.25, 5.12,
  5.89, 5.5, 4.32, 4.12, 4.38, 4.6, 4.93, 5.39, 5.79, 6.0, 6.34, 7.08, 8.28, 9.23, 9.4, 9.05, 8.18,
  7.47, 7.09, 6.61, 5.46, 5.39, 5.24, 5.1, 5.85, 5.6, 4.3, 4.1, 4.39, 4.6, 4.95, 5.44, 5.87, 6.05,
  6.39, 7.13, 8.37, 9.27, 9.43, 9.08, 8.24, 7.52, 7.1, 5.92, 5.42, 5.29, 5.12, 5.51, 5.69, 4.61,
  4.17, 4.28, 4.68, 5.19, 5.72, 6.17, 6.42, 6.72, 7.3, 7.91, 8.63, 9.54, 9.35, 8.66, 7.88, 7.31,
  6.78, 5.86, 5.4, 5.24, 4.93, 5.2, 5.32, 4.49, 4.29, 4.32, 4.59, 5.07, 5.48, 5.9, 6.23, 6.46, 6.89,
  7.45, 8.07, 8.87, 8.8, 8.44, 7.8, 7.22, 6.67, 5.73, 5.39, 5.16, 4.85, 5.29, 5.31, 4.47, 4.29,
  4.23, 4.42, 4.97, 5.32, 5.74, 6.08, 6.41, 6.96, 7.61, 8.17, 8.85, 8.74, 8.36, 7.76, 7.21, 6.64,
  5.73, 5.41, 5.24, 5.12, 5.62, 5.61, 4.48, 4.09, 4.29, 4.57, 4.89, 5.35, 5.76, 6.02, 6.34, 6.98,
  7.67, 8.41, 9.37, 9.17, 8.44, 7.68, 7.17, 6.67, 5.73, 5.39, 5.25, 5.11, 5.63, 5.62, 4.48, 4.11,
  4.29, 4.63, 5.19, 5.78, 6.28, 6.54, 6.83, 7.55, 8.28, 8.9, 9.77, 9.45, 8.59, 7.82, 7.27, 6.68,
  5.78, 5.44, 5.29, 5.14, 5.66, 5.67, 4.57, 4.13, 4.31, 4.57, 4.86, 5.28, 5.71, 5.95, 6.27, 6.91,
  7.6, 8.39, 9.37, 9.17, 8.47, 7.72, 7.21, 6.67, 5.89, 5.43, 5.29, 5.17, 5.55, 5.71, 4.64, 4.16,
  4.26, 4.52, 4.86, 5.32, 5.82, 6.19, 6.5, 7.02, 7.65, 8.46, 9.45, 9.3, 8.66, 7.88, 7.31, 6.77,
  5.92, 5.42, 5.29, 5.12, 5.51, 5.69, 4.61, 4.17, 4.28, 4.68, 5.19, 5.72, 6.17, 6.42, 6.72, 7.3,
  7.91, 8.63, 9.54, 9.35, 8.66, 7.88, 7.31, 6.78, 5.86, 5.4, 5.24, 4.93, 5.2, 5.32, 4.49, 4.29,
  4.32, 4.59, 5.07, 5.48, 5.9, 6.23, 6.46, 6.89, 7.45, 8.07, 8.87, 8.8, 8.44, 7.8, 7.22, 6.67, 5.73,
  5.39, 4.85, 5.29, 5.31, 4.47, 4.29, 4.23, 4.42, 4.97, 5.32, 5.74, 6.08, 6.41, 6.96, 7.61, 8.17,
  8.85, 8.74, 8.36, 7.76, 7.21, 6.64, 5.73, 5.41, 5.24, 5.12, 5.62, 5.61, 4.48, 4.09, 4.29, 4.57,
  4.89, 5.35, 5.76, 6.02, 6.34, 6.98, 7.67, 8.41, 9.37, 9.17, 8.44, 7.68, 7.17, 6.67, 5.73, 5.39,
  5.25, 5.11, 5.63, 5.62, 4.48, 4.11, 4.29, 4.63, 5.19, 5.78, 6.28, 6.54, 6.83, 7.55, 8.28, 8.9,
  9.77, 9.45, 8.59, 7.82, 7.27, 6.68, 5.78, 5.44, 5.29, 5.14, 5.66, 5.67, 4.57, 4.13, 4.31, 4.57,
  4.86, 5.28, 5.71, 5.95, 6.27, 6.91, 7.6, 8.39, 9.37, 9.17, 8.47, 7.72, 7.21, 6.67, 5.89, 5.43,
  5.29, 5.17, 5.55, 5.71, 4.64, 4.16, 4.26, 4.52, 4.86, 5.32, 5.82, 6.19, 6.5, 7.02, 7.65, 8.46,
  9.45, 9.3, 8.66, 7.88, 7.31, 6.77, 5.92, 5.42, 5.29, 5.12, 5.51, 5.69, 4.61, 4.17, 4.28, 4.68,
  5.19, 5.72, 6.17, 6.42, 6.72, 7.3, 7.91, 8.63, 9.54, 9.35, 8.66, 7.88, 7.31, 6.78, 5.86, 5.4,
  5.24, 4.93, 5.2, 5.32, 4.49, 4.29, 4.32, 4.59, 5.07, 5.48, 5.9, 6.23, 6.46, 6.89, 7.45, 8.07,
  8.87, 8.8, 8.44, 7.8, 7.22, 6.67, 5.73, 5.39, 5.16, 4.85, 5.29, 5.31, 4.47, 4.29, 4.23, 4.42,
  4.97, 5.32, 5.74, 6.08, 6.41, 6.96, 7.61, 8.17, 8.85, 8.74, 8.36, 7.76, 7.21, 6.64, 5.73, 5.41,
  5.24, 5.12, 5.62, 5.61, 4.48, 4.09, 4.29, 4.57, 4.89, 5.35, 5.76, 6.02, 6.34, 6.98, 7.67, 8.41,
  9.37, 9.17, 8.44, 7.68, 7.17, 6.67, 5.73, 5.39, 5.25, 5.11, 5.63, 5.62, 4.48, 4.11, 4.29, 4.63,
  5.19, 5.78, 6.28, 6.54, 6.83, 7.55, 8.28, 8.9, 9.77, 9.45, 8.59, 7.82, 7.27, 6.68, 5.78, 5.44,
  5.29, 5.14, 5.66, 5.67, 4.57, 4.13, 4.31, 4.57, 4.86, 5.28, 5.71, 5.95, 6.27, 6.91, 7.6, 8.39,
  9.37, 9.17, 8.47, 7.72, 7.21, 6.67, 5.89, 5.43, 5.29, 5.17, 5.55, 5.71, 4.64, 4.16, 4.26, 4.52,
  4.86, 5.32, 5.82, 6.19, 6.5, 7.02, 7.65, 8.46, 9.45, 9.3, 8.66, 7.88, 7.31, 6.77, 5.92, 5.42,
  5.29, 5.12, 5.51, 5.69, 4.61, 4.17, 4.28, 4.68, 5.19, 5.72, 6.17, 6.42, 6.72, 7.3, 7.91, 8.63,
  9.54, 9.35, 8.66, 7.88, 7.31, 6.78, 5.86, 5.4, 5.24, 4.93, 5.2, 5.32, 4.49, 4.29, 4.32, 4.59,
  5.07, 5.48, 5.9, 6.23, 6.46, 6.89, 7.45, 8.07, 8.87, 8.8, 8.44, 7.8, 7.22, 6.67, 5.73, 5.39, 5.16,
  4.85, 5.29, 5.31, 4.47, 4.29, 4.23, 4.42, 4.97, 5.32, 5.74, 6.08, 6.41, 6.96, 7.61, 8.17, 8.85,
  8.74, 8.36, 7.76, 7.21, 6.64, 5.73, 5.41, 5.24, 5.12, 5.62, 5.61, 4.48, 4.09, 4.29, 4.57, 4.89,
  5.35, 5.76, 6.02, 6.34, 6.98, 7.67, 8.41, 9.37, 9.17, 8.44, 7.68, 7.17, 6.67, 5.73, 5.39, 5.25,
  5.11, 5.63, 5.62, 4.48, 4.11, 4.29, 4.63, 5.19, 5.78, 6.28, 6.54, 6.83, 7.55, 8.28, 8.9, 9.77,
  9.45, 8.59, 7.82, 7.27, 6.68, 5.78, 5.44, 5.29, 5.14, 5.66, 5.67, 4.57, 4.13, 4.31, 4.57, 4.86,
  5.28, 5.71, 5.95, 6.27, 6.91, 7.6, 8.39, 9.37, 9.17, 8.47, 7.72, 7.21, 6.67, 5.89, 5.43, 5.29,
  5.17, 5.55, 5.71, 4.64, 4.16, 4.26, 4.52, 4.86, 5.32, 5.82, 6.19, 6.5, 7.02, 7.65, 8.46, 9.45,
  9.3, 8.66, 7.88, 7.31, 6.77, 5.92, 5.42, 5.29, 5.12, 5.51, 5.69, 4.61, 4.17, 4.28, 4.68, 5.19,
  5.72, 6.17, 6.42, 6.72, 7.3, 7.91, 8.63, 9.54, 9.35, 8.66, 7.88, 7.31, 6.78, 5.86, 5.4, 5.24,
  4.93, 5.2, 5.32, 4.49, 4.29, 4.32, 4.59, 5.07, 5.48, 5.9, 6.23, 6.46, 6.89, 7.45, 8.07, 8.87, 8.8,
  8.44, 7.8, 7.22, 6.67, 5.73, 5.39, 5.16, 4.85, 5.29, 5.31, 4.47, 4.29, 4.23, 4.42, 4.97, 5.32,
  5.74, 6.08, 6.41, 6.96, 7.61, 8.17, 8.85, 8.74, 8.36, 7.76, 7.21, 6.64, 5.49, 5.41, 5.21, 5.08,
  5.78, 5.03, 4.23, 4.09, 4.42, 4.77, 5.21, 5.77, 6.25, 6.47, 6.93, 7.69, 8.41, 9.0, 9.49, 9.39,
  8.49, 7.67, 7.15, 6.53, 5.5, 5.4, 5.2, 5.08, 5.78, 5.08, 4.23, 4.09, 4.39, 4.71, 5.18, 5.79, 6.24,
  6.37, 6.64, 7.31, 7.97, 8.58, 9.18, 9.14, 8.29, 7.58, 7.12, 6.54, 5.5, 5.41, 5.26, 5.1, 5.81,
  5.09, 4.29, 4.1, 4.36, 4.62, 4.93, 5.43, 5.84, 6.03, 6.41, 7.18, 7.88, 8.54, 9.17, 9.1, 8.28,
  7.58, 7.14, 6.56, 5.47, 5.38, 5.24, 5.11, 5.81, 5.04, 4.25, 4.09, 4.36, 4.72, 5.15, 5.77, 6.2,
  6.38, 6.69, 7.41, 8.06, 8.68, 9.26, 9.18, 8.36, 7.63, 7.15, 6.57, 5.48, 5.39, 5.2, 5.07, 5.79,
  5.02, 4.23, 4.07, 4.41, 4.92, 5.52, 6.25, 6.81, 6.97, 7.37, 8.04, 8.63, 9.21, 9.82, 9.73, 8.76,
  7.98, 7.42, 6.76, 5.66, 5.52, 5.25, 4.86, 5.52, 4.78, 4.39, 4.58, 4.72, 5.47, 6.07, 6.48, 6.9,
  7.16, 7.38, 7.92, 8.43, 8.75, 9.08, 9.17, 8.54, 7.79, 7.22, 6.58, 5.53, 5.39, 5.11, 4.74, 5.48,
  4.78, 4.24, 4.33, 4.22, 4.53, 5.16, 5.5, 5.92, 6.23, 6.57, 7.21, 7.9, 8.21, 8.65, 8.75, 8.26,
  7.62, 7.11, 6.53, 5.49, 5.41, 5.21, 5.08, 5.78, 5.03, 4.23, 4.09, 4.42, 4.77, 5.21, 5.77, 6.25,
  6.47, 6.93, 7.69, 8.41, 9.0, 9.49, 9.39, 8.49, 7.67, 7.15, 6.53, 5.5, 5.4, 5.2, 5.08, 5.78, 5.08,
  4.23, 4.09, 4.39, 4.71, 5.18, 5.79, 6.24, 6.37, 6.64, 7.31, 7.97, 8.58, 9.18, 9.14, 8.29, 7.58,
  7.12, 6.54, 5.5, 5.41, 5.26, 5.1, 5.81, 5.09, 4.29, 4.1, 4.36, 4.62, 4.93, 5.43, 5.84, 6.03, 6.41,
  7.18, 7.88, 8.54, 9.17, 9.1, 8.28, 7.58, 7.14, 6.56, 5.47, 5.38, 5.24, 5.11, 5.81, 5.04, 4.25,
  4.09, 4.36, 4.72, 5.15, 5.77, 6.2, 6.38, 6.69, 7.41, 8.06, 8.68, 9.26, 9.18, 8.36, 7.63, 7.15,
  6.57, 5.48, 5.39, 5.2, 5.07, 5.79, 5.02, 4.23, 4.07, 4.41, 4.92, 5.52, 6.25, 6.81, 6.97, 7.37,
  8.04, 8.63, 9.21, 9.82, 9.73, 8.76, 7.98, 7.42, 6.76, 5.66, 5.52, 5.25, 4.86, 5.52, 4.78, 4.39,
  4.58, 4.72, 5.47, 6.07, 6.48, 6.9, 7.16, 7.38, 7.92, 8.43, 8.75, 9.08, 9.17, 8.54, 7.79, 7.22,
  6.58, 5.53, 5.39, 5.11, 4.74, 5.48, 4.78, 4.24, 4.33, 4.22, 4.53, 5.16, 5.5, 5.92, 6.23, 6.57,
  7.21, 7.9, 8.21, 8.65, 8.75, 8.26, 7.62, 7.11, 6.53, 5.49, 5.41, 5.21, 5.08, 5.78, 5.03, 4.23,
  4.09, 4.42, 4.77, 5.21, 5.77, 6.25, 6.47, 6.93, 7.69, 8.41, 9.0, 9.49, 9.39, 8.49, 7.67, 7.15,
  6.53, 5.5, 5.4, 5.2, 5.08, 5.78, 5.08, 4.23, 4.09, 4.39, 4.71, 5.18, 5.79, 6.24, 6.37, 6.64, 7.31,
  7.97, 8.58, 9.18, 9.14, 8.29, 7.58, 7.12, 6.54, 5.5, 5.41, 5.26, 5.1, 5.81, 5.09, 4.29, 4.1, 4.36,
  4.62, 4.93, 5.43, 5.84, 6.03, 6.41, 7.18, 7.88, 8.54, 9.17, 9.1, 8.28, 7.58, 7.14, 6.56, 5.47,
  5.38, 5.24, 5.11, 5.81, 5.04, 4.25, 4.09, 4.36, 4.72, 5.15, 5.77, 6.2, 6.38, 6.69, 7.41, 8.06,
  8.68, 9.26, 9.18, 8.36, 7.63, 7.15, 6.57, 5.48, 5.39, 5.2, 5.07, 5.79, 5.02, 4.23, 4.07, 4.41,
  4.92, 5.52, 6.25, 6.81, 6.97, 7.37, 8.04, 8.63, 9.21, 9.82, 9.73, 8.76, 7.98, 7.42, 6.76, 5.66,
  5.52, 5.25, 4.86, 5.52, 4.78, 4.39, 4.58, 4.72, 5.47, 6.07, 6.48, 6.9, 7.16, 7.38, 7.92, 8.43,
  8.75, 9.08, 9.17, 8.54, 7.79, 7.22, 6.58, 5.53, 5.39, 5.11, 4.74, 5.48, 4.78, 4.24, 4.33, 4.22,
  4.53, 5.16, 5.5, 5.92, 6.23, 6.57, 7.21, 7.9, 8.21, 8.65, 8.75, 8.26, 7.62, 7.11, 6.53, 5.49,
  5.41, 5.21, 5.08, 5.78, 5.03, 4.23, 4.09, 4.42, 4.77, 5.21, 5.77, 6.25, 6.47, 6.93, 7.69, 8.41,
  9.0, 9.49, 9.39, 8.49, 7.67, 7.15, 6.53, 5.5, 5.4, 5.2, 5.08, 5.78, 5.08, 4.23, 4.09, 4.39, 4.71,
  5.18, 5.79, 6.24, 6.37, 6.64, 7.31, 7.97, 8.58, 9.18, 9.14, 8.29, 7.58, 7.12, 6.54, 5.5, 5.41,
  5.26, 5.1, 5.81, 5.09, 4.29, 4.1, 4.36, 4.62, 4.93, 5.43, 5.84, 6.03, 6.41, 7.18, 7.88, 8.54,
  9.17, 9.1, 8.28, 7.58, 7.14, 6.56, 5.47, 5.38, 5.24, 5.11, 5.81, 5.04, 4.25, 4.09, 4.36, 4.72,
  5.15, 5.77, 6.2, 6.38, 6.69, 7.41, 8.06, 8.68, 9.26, 9.18, 8.36, 7.63, 7.15, 6.57, 5.48, 5.39,
  5.2, 5.07, 5.79, 5.02, 4.23, 4.07, 4.41, 4.92, 5.52, 6.25, 6.81, 6.97, 7.37, 8.04, 8.63, 9.21,
  9.82, 9.73, 8.76, 7.98, 7.42, 6.76, 5.66, 5.52, 5.25, 4.86, 5.52, 4.78, 4.39, 4.58, 4.72, 5.47,
  6.07, 6.48, 6.9, 7.16, 7.38, 7.92, 8.43, 8.75, 9.08, 9.17, 8.54, 7.79, 7.22, 6.58, 5.53, 5.39,
  5.11, 4.74, 5.48, 4.78, 4.24, 4.33, 4.22, 4.53, 5.16, 5.5, 5.92, 6.23, 6.57, 7.21, 7.9, 8.21,
  8.65, 8.75, 8.26, 7.62, 7.11, 6.53, 5.49, 5.41, 5.21, 5.08, 5.78, 5.03, 4.23, 4.09, 4.42, 4.77,
  5.21, 5.77, 6.25, 6.47, 6.93, 7.69, 8.41, 9.0, 9.49, 9.39, 8.49, 7.67, 7.15, 6.53, 5.5, 5.4, 5.2,
  5.08, 5.78, 5.08, 4.23, 4.09, 4.39, 4.71, 5.18, 5.79, 6.24, 6.37, 6.64, 7.31, 7.97, 8.58, 9.18,
  9.14, 8.29, 7.58, 7.12, 6.54, 5.77, 5.65, 5.45, 5.26, 5.73, 5.25, 4.85, 4.86, 5.37, 6.02, 6.73,
  7.41, 7.92, 8.15, 8.55, 9.48, 10.18, 10.67, 10.42, 10.4, 9.28, 8.48, 7.89, 7.15, 5.91, 5.79, 5.58,
  5.38, 5.83, 5.3, 4.78, 4.79, 5.38, 6.15, 7.11, 7.98, 8.5, 8.83, 9.12, 9.82, 10.4, 10.94, 10.87,
  10.87, 9.66, 8.64, 7.88, 7.08, 5.73, 5.59, 5.31, 5.14, 5.53, 4.92, 4.42, 4.4, 4.89, 5.32, 5.86,
  6.4, 6.81, 7.07, 7.52, 8.18, 8.68, 9.12, 9.18, 9.41, 8.51, 7.74, 7.21, 6.58, 5.5, 5.4, 5.19, 4.81,
  5.19, 4.59, 4.38, 4.6, 4.7, 5.33, 5.97, 6.4, 6.83, 7.03, 7.18, 7.59, 8.04, 8.4, 8.46, 8.96, 8.38,
  7.68, 7.12, 6.5, 5.5, 5.41, 5.14, 4.76, 5.16, 4.53, 4.28, 4.31, 4.25, 4.53, 5.15, 5.52, 5.94,
  6.21, 6.57, 7.18, 7.88, 8.28, 8.32, 8.82, 8.32, 7.66, 7.14, 6.55, 5.5, 5.41, 5.22, 5.08, 5.48,
  4.83, 4.28, 4.19, 4.6, 5.26, 6.09, 6.86, 7.44, 7.84, 8.47, 9.27, 9.87, 10.28, 10.24, 10.27, 9.21,
  8.35, 7.68, 6.96, 5.67, 5.6, 5.38, 5.2, 5.66, 5.1, 4.62, 4.59, 5.11, 5.75, 6.48, 7.23, 7.75, 8.09,
  8.57, 9.3, 9.85, 10.29, 10.23, 10.28, 9.15, 8.31, 7.73, 6.95, 5.77, 5.65, 5.45, 5.26, 5.73, 5.25,
  4.85, 4.86, 5.37, 6.02, 6.73, 7.41, 7.92, 8.15, 8.55, 9.48, 10.18, 10.67, 10.42, 10.4, 9.28, 8.48,
  7.89, 7.15, 5.91, 5.79, 5.58, 5.38, 5.83, 5.3, 4.78, 4.79, 5.38, 6.15, 7.11, 7.98, 8.5, 8.83,
  9.12, 9.82, 10.4, 10.94, 10.87, 10.87, 9.66, 8.64, 7.88, 7.08, 5.73, 5.59, 5.31, 5.14, 5.53, 4.92,
  4.42, 4.4, 4.89, 5.32, 5.86, 6.4, 6.81, 7.07, 7.52, 8.18, 8.68, 9.12, 9.18, 9.41, 8.51, 7.74,
  7.21, 6.58, 5.5, 5.4, 5.19, 4.81, 5.19, 4.59, 4.38, 4.6, 4.7, 5.33, 5.97, 6.4, 6.83, 7.03, 7.18,
  7.59, 8.04, 8.4, 8.46, 8.96, 8.38, 7.68, 7.12, 6.5, 5.5, 5.41, 5.14, 4.76, 5.16, 4.53, 4.28, 4.31,
  4.25, 4.53, 5.15, 5.52, 5.94, 6.21, 6.57, 7.18, 7.88, 8.28, 8.32, 8.82, 8.32, 7.66, 7.14, 6.55,
  5.5, 5.41, 5.22, 5.08, 5.48, 4.83, 4.28, 4.19, 4.6, 5.26, 6.09, 6.86, 7.44, 7.84, 8.47, 9.27,
  9.87, 10.28, 10.24, 10.27, 9.21, 8.35, 7.68, 6.96, 5.67, 5.6, 5.38, 5.2, 5.66, 5.1, 4.62, 4.59,
  5.11, 5.75, 6.48, 7.23, 7.75, 8.09, 8.57, 9.3, 9.85, 10.29, 10.23, 10.28, 9.15, 8.31, 7.73, 6.95,
  5.77, 5.65, 5.45, 5.26, 5.73, 5.25, 4.85, 4.86, 5.37, 6.02, 6.73, 7.41, 7.92, 8.15, 8.55, 9.48,
  10.18, 10.67, 10.42, 10.4, 9.28, 8.48, 7.89, 7.15, 5.91, 5.79, 5.58, 5.38, 5.83, 5.3, 4.78, 4.79,
  5.38, 6.15, 7.11, 7.98, 8.5, 8.83, 9.12, 9.82, 10.4, 10.94, 10.87, 10.87, 9.66, 8.64, 7.88, 7.08,
  5.73, 5.59, 5.31, 5.14, 5.53, 4.92, 4.42, 4.4, 4.89, 5.32, 5.86, 6.4, 6.81, 7.07, 7.52, 8.18,
  8.68, 9.12, 9.18, 9.41, 8.51, 7.74, 7.21, 6.58, 5.5, 5.4, 5.19, 4.81, 5.19, 4.59, 4.38, 4.6, 4.7,
  5.33, 5.97, 6.4, 6.83, 7.03, 7.18, 7.59, 8.04, 8.4, 8.46, 8.96, 8.38, 7.68, 7.12, 6.5, 5.5, 5.41,
  5.14, 4.76, 5.16, 4.53, 4.28, 4.31, 4.25, 4.53, 5.15, 5.52, 5.94, 6.21, 6.57, 7.18, 7.88, 8.28,
  8.32, 8.82, 8.32, 7.66, 7.14, 6.55, 5.5, 5.41, 5.22, 5.08, 5.48, 4.83, 4.28, 4.19, 4.6, 5.26,
  6.09, 6.86, 7.44, 7.84, 8.47, 9.27, 9.87, 10.28, 10.24, 10.27, 9.21, 8.35, 7.68, 6.96, 5.67, 5.6,
  5.38, 5.2, 5.66, 5.1, 4.62, 4.59, 5.11, 5.75, 6.48, 7.23, 7.75, 8.09, 8.57, 9.3, 9.85, 10.29,
  10.23, 10.28, 9.15, 8.31, 7.73, 6.95, 5.77, 5.65, 5.45, 5.26, 5.73, 5.25, 4.85, 4.86, 5.37, 6.02,
  6.73, 7.41, 7.92, 8.15, 8.55, 9.48, 10.18, 10.67, 10.42, 10.4, 9.28, 8.48, 7.89, 7.15, 5.91, 5.79,
  5.58, 5.38, 5.83, 5.3, 4.78, 4.79, 5.38, 6.15, 7.11, 7.98, 8.5, 8.83, 9.12, 9.82, 10.4, 10.94,
  10.87, 10.87, 9.66, 8.64, 7.88, 7.08, 5.73, 5.59, 5.31, 5.14, 5.53, 4.92, 4.42, 4.4, 4.89, 5.32,
  5.86, 6.4, 6.81, 7.07, 7.52, 8.18, 8.68, 9.12, 9.18, 9.41, 8.51, 7.74, 7.21, 6.58, 5.5, 5.4, 5.19,
  4.81, 5.19, 4.59, 4.38, 4.6, 4.7, 5.33, 5.97, 6.4, 6.83, 7.03, 7.18, 7.59, 8.04, 8.4, 8.46, 8.96,
  8.38, 7.68, 7.12, 6.5, 5.5, 5.41, 5.14, 4.76, 5.16, 4.53, 4.28, 4.31, 4.25, 4.53, 5.15, 5.52,
  5.94, 6.21, 6.57, 7.18, 7.88, 8.28, 8.32, 8.82, 8.32, 7.66, 7.14, 6.55, 5.5, 5.41, 5.22, 5.08,
  5.48, 4.83, 4.28, 4.19, 4.6, 5.26, 6.09, 6.86, 7.44, 7.84, 8.47, 9.27, 9.87, 10.28, 10.24, 10.27,
  9.21, 8.35, 7.68, 6.96, 5.67, 5.6, 5.38, 5.2, 5.66, 5.1, 4.62, 4.59, 5.11, 5.75, 6.48, 7.23, 7.75,
  8.09, 8.57, 9.3, 9.85, 10.29, 10.23, 10.28, 9.15, 8.31, 7.73, 6.95, 5.77, 5.65, 5.45, 5.26, 5.73,
  5.25, 4.85, 4.86, 5.37, 6.02, 6.73, 7.41, 7.92, 8.15, 8.55, 9.48, 10.18, 10.67, 10.42, 10.4, 9.28,
  8.48, 7.89, 7.15, 5.91, 5.79, 5.58, 5.38, 5.83, 5.3, 4.78, 4.79, 5.38, 6.15, 7.11, 7.98, 8.5,
  8.83, 9.12, 9.82, 10.4, 10.94, 10.87, 10.87, 9.66, 8.64, 7.88, 7.08, 5.73, 5.59, 5.31, 5.14, 5.53,
  4.92, 4.42, 4.4, 4.89, 5.32, 5.86, 6.4, 6.81, 7.07, 7.52, 8.18, 8.68, 9.12, 9.18, 9.41, 8.51,
  7.74, 7.21, 6.58, 5.94, 5.79, 5.54, 5.13, 5.33, 5.04, 4.97, 5.34, 5.66, 6.5, 7.53, 8.38, 8.98,
  9.38, 9.7, 10.33, 10.88, 11.17, 10.74, 10.78, 10.07, 9.07, 8.27, 7.46, 6.14, 5.97, 5.58, 5.15,
  5.37, 5.05, 4.93, 5.28, 5.55, 6.24, 7.26, 7.78, 8.12, 8.39, 8.74, 9.45, 10.01, 10.24, 9.99, 10.11,
  9.46, 8.62, 7.85, 7.06, 5.84, 5.68, 5.44, 5.25, 5.5, 5.22, 4.88, 4.91, 5.54, 6.25, 6.83, 7.26,
  7.53, 7.68, 8.01, 8.7, 9.3, 9.78, 9.82, 9.93, 9.11, 8.25, 7.6, 6.88, 5.75, 5.58, 5.36, 5.19, 5.44,
  5.1, 4.69, 4.7, 5.21, 5.9, 6.68, 7.33, 7.89, 8.24, 8.67, 9.35, 9.86, 10.4, 10.27, 10.22, 9.33,
  8.42, 7.71, 6.95, 5.79, 5.68, 5.49, 5.28, 5.53, 5.2, 4.74, 4.78, 5.44, 6.23, 7.03, 7.75, 8.21,
  8.42, 8.82, 9.45, 10.07, 10.64, 10.56, 10.62, 9.74, 8.82, 8.13, 7.34, 6.04, 5.9, 5.67, 5.44, 5.66,
  5.43, 4.98, 4.95, 5.58, 6.28, 6.96, 7.6, 8.0, 8.27, 8.64, 9.39, 10.14, 10.82, 10.81, 10.76, 9.77,
  8.87, 8.19, 7.32, 6.01, 5.79, 5.51, 5.29, 5.53, 5.19, 4.87, 4.91, 5.5, 6.28, 7.12, 7.72, 8.08,
  8.45, 9.0, 9.75, 10.35, 10.87, 10.85, 10.86, 9.84, 8.8, 8.02, 7.21, 5.94, 5.79, 5.54, 5.13, 5.33,
  5.04, 4.97, 5.34, 5.66, 6.5, 7.53, 8.38, 8.98, 9.38, 9.7, 10.33, 10.88, 11.17, 10.74, 10.78,
  10.07, 9.07, 8.27, 7.46, 6.14, 5.97, 5.58, 5.15, 5.37, 5.05, 4.93, 5.28, 5.55, 6.24, 7.26, 7.78,
  8.12, 8.39, 8.74, 9.45, 10.01, 10.24, 9.99, 10.11, 9.46, 8.62, 7.85, 7.06, 5.84, 5.68, 5.44, 5.25,
  5.5, 5.22, 4.88, 4.91, 5.54, 6.25, 6.83, 7.26, 7.53, 7.68, 8.01, 8.7, 9.3, 9.78, 9.82, 9.93, 9.11,
  8.25, 7.6, 6.88, 5.75, 5.58, 5.36, 5.19, 5.44, 5.1, 4.69, 4.7, 5.21, 5.9, 6.68, 7.33, 7.89, 8.24,
  8.67, 9.35, 9.86, 10.4, 10.27, 10.22, 9.33, 8.42, 7.71, 6.95, 5.79, 5.68, 5.49, 5.28, 5.53, 5.2,
  4.74, 4.78, 5.44, 6.23, 7.03, 7.75, 8.21, 8.42, 8.82, 9.45, 10.07, 10.64, 10.56, 10.62, 9.74,
  8.82, 8.13, 7.34, 6.04, 5.9, 5.67, 5.44, 5.66, 5.43, 4.98, 4.95, 5.58, 6.28, 6.96, 7.6, 8.0, 8.27,
  8.64, 9.39, 10.14, 10.82, 10.81, 10.76, 9.77, 8.87, 8.19, 7.32, 6.01, 5.79, 5.51, 5.29, 5.53,
  5.19, 4.87, 4.91, 5.5, 6.28, 7.12, 7.72, 8.08, 8.45, 9.0, 9.75, 10.35, 10.87, 10.85, 10.86, 9.84,
  8.8, 8.02, 7.21, 5.94, 5.79, 5.54, 5.13, 5.33, 5.04, 4.97, 5.34, 5.66, 6.5, 7.53, 8.38, 8.98,
  9.38, 9.7, 10.33, 10.88, 11.17, 10.74, 10.78, 10.07, 9.07, 8.27, 7.46, 6.14, 5.97, 5.58, 5.15,
  5.37, 5.05, 4.93, 5.28, 5.55, 6.24, 7.26, 7.78, 8.12, 8.39, 8.74, 9.45, 10.01, 10.24, 9.99, 10.11,
  9.46, 8.62, 7.85, 7.06, 5.84, 5.68, 5.44, 5.25, 5.5, 5.22, 4.88, 4.91, 5.54, 6.25, 6.83, 7.26,
  7.53, 7.68, 8.01, 8.7, 9.3, 9.78, 9.82, 9.93, 9.11, 8.25, 7.6, 6.88, 5.75, 5.58, 5.36, 5.19, 5.44,
  5.1, 4.69, 4.7, 5.21, 5.9, 6.68, 7.33, 7.89, 8.24, 8.67, 9.35, 9.86, 10.4, 10.27, 10.22, 9.33,
  8.42, 7.71, 6.95, 5.79, 5.68, 5.49, 5.28, 5.53, 5.2, 4.74, 4.78, 5.44, 6.23, 7.03, 7.75, 8.21,
  8.42, 8.82, 9.45, 10.07, 10.64, 10.56, 10.62, 9.74, 8.82, 8.13, 7.34, 6.04, 5.9, 5.67, 5.44, 5.66,
  5.43, 4.98, 4.95, 5.58, 6.28, 6.96, 7.6, 8.0, 8.27, 8.64, 9.39, 10.14, 10.82, 10.81, 10.76, 9.77,
  8.87, 8.19, 7.32, 6.01, 5.79, 5.51, 5.29, 5.53, 5.19, 4.87, 4.91, 5.5, 6.28, 7.12, 7.72, 8.08,
  8.45, 9.0, 9.75, 10.35, 10.87, 10.85, 10.86, 9.84, 8.8, 8.02, 7.21, 5.94, 5.79, 5.54, 5.13, 5.33,
  5.04, 4.97, 5.34, 5.66, 6.5, 7.53, 8.38, 8.98, 9.38, 9.7, 10.33, 10.88, 11.17, 10.74, 10.78,
  10.07, 9.07, 8.27, 7.46, 6.14, 5.97, 5.58, 5.15, 5.37, 5.05, 4.93, 5.28, 5.55, 6.24, 7.26, 7.78,
  8.12, 8.39, 8.74, 9.45, 10.01, 10.24, 9.99, 10.11, 9.46, 8.62, 7.85, 7.06, 5.84, 5.68, 5.44, 5.25,
  5.5, 5.22, 4.88, 4.91, 5.54, 6.25, 6.83, 7.26, 7.53, 7.68, 8.01, 8.7, 9.3, 9.78, 9.82, 9.93, 9.11,
  8.25, 7.6, 6.88, 5.75, 5.58, 5.36, 5.19, 5.44, 5.1, 4.69, 4.7, 5.21, 5.9, 6.68, 7.33, 7.89, 8.24,
  8.67, 9.35, 9.86, 10.4, 10.27, 10.22, 9.33, 8.42, 7.71, 6.95, 5.79, 5.68, 5.49, 5.28, 5.53, 5.2,
  4.74, 4.78, 5.44, 6.23, 7.03, 7.75, 8.21, 8.42, 8.82, 9.45, 10.07, 10.64, 10.56, 10.62, 9.74,
  8.82, 8.13, 7.34, 6.04, 5.9, 5.67, 5.44, 5.66, 5.43, 4.98, 4.95, 5.58, 6.28, 6.96, 7.6, 8.0, 8.27,
  8.64, 9.39, 10.14, 10.82, 10.81, 10.76, 9.77, 8.87, 8.19, 7.32, 6.01, 5.79, 5.51, 5.29, 5.53,
  5.19, 4.87, 4.91, 5.5, 6.28, 7.12, 7.72, 8.08, 8.45, 9.0, 9.75, 10.35, 10.87, 10.85, 10.86, 9.84,
  8.8, 8.02, 7.21, 5.94, 5.79, 5.54, 5.13, 5.33, 5.04, 4.97, 5.34, 5.66, 6.5, 7.53, 8.38, 8.98,
  9.38, 9.7, 10.33, 10.88, 11.17, 10.74, 10.78, 10.07, 9.07, 8.27, 7.46, 6.14, 5.97, 5.58, 5.15,
  5.37, 5.05, 4.93, 5.28, 5.55, 6.24, 7.26, 7.78, 8.12, 8.39, 8.74, 9.45, 10.01, 10.24, 9.99, 10.11,
  9.46, 8.62, 7.85, 7.06, 6.09, 5.91, 5.68, 5.46, 5.99, 5.56, 5.22, 5.29, 6.0, 6.97, 7.85, 8.53,
  8.94, 9.0, 9.14, 9.87, 10.82, 11.5, 11.45, 11.34, 10.17, 9.2, 8.42, 7.55, 6.22, 6.05, 5.8, 5.58,
  6.16, 5.78, 5.48, 5.67, 6.45, 7.28, 8.18, 8.98, 9.5, 9.83, 10.35, 11.11, 11.83, 12.28, 12.08,
  11.98, 10.74, 9.65, 8.79, 7.9, 6.61, 6.42, 6.17, 5.9, 6.6, 6.35, 6.08, 6.25, 7.23, 8.34, 9.35,
  10.01, 10.36, 10.53, 10.91, 11.7, 12.42, 12.93, 12.74, 12.6, 11.39, 10.35, 9.34, 8.41, 6.85, 6.58,
  6.26, 5.97, 6.62, 6.31, 6.08, 6.35, 7.34, 8.26, 8.94, 9.17, 9.49, 9.87, 10.19, 10.9, 11.56, 12.06,
  11.96, 11.75, 10.46, 9.41, 8.6, 7.72, 6.32, 6.14, 5.86, 5.59, 6.16, 5.75, 5.45, 5.58, 6.4, 7.29,
  8.06, 8.64, 9.06, 9.45, 10.02, 10.78, 11.46, 11.97, 11.81, 11.69, 10.54, 9.43, 8.63, 7.78, 6.39,
  6.22, 5.87, 5.41, 5.84, 5.41, 5.52, 6.1, 6.43, 7.33, 8.2, 8.87, 9.37, 9.64, 9.75, 10.08, 10.54,
  10.97, 10.84, 11.0, 10.15, 9.15, 8.28, 7.41, 6.21, 6.01, 5.65, 5.18, 5.59, 5.11, 5.04, 5.47, 5.7,
  6.54, 7.59, 8.13, 8.67, 9.04, 9.44, 10.07, 10.61, 10.92, 10.53, 10.69, 10.06, 9.16, 8.32, 7.45,
  6.09, 5.91, 5.68, 5.46, 5.99, 5.56, 5.22, 5.29, 6.0, 6.97, 7.85, 8.53, 8.94, 9.0, 9.14, 9.87,
  10.82, 11.5, 11.45, 11.34, 10.17, 9.2, 8.42, 7.55, 6.22, 6.05, 5.8, 5.58, 6.16, 5.78, 5.48, 5.67,
  6.45, 7.28, 8.18, 8.98, 9.5, 9.83, 10.35, 11.11, 11.83, 12.28, 12.08, 11.98, 10.74, 9.65, 8.79,
  7.9, 6.61, 6.42, 6.17, 5.9, 6.6, 6.35, 6.08, 6.25, 7.23, 8.34, 9.35, 10.01, 10.36, 10.53, 10.91,
  11.7, 12.42, 12.93, 12.74, 12.6, 11.39, 10.35, 9.34, 8.41, 6.85, 6.58, 6.26, 5.97, 6.62, 6.31,
  6.08, 6.35, 7.34, 8.26, 8.94, 9.17, 9.49, 9.87, 10.19, 10.9, 11.56, 12.06, 11.96, 11.75, 10.46,
  9.41, 8.6, 7.72, 6.32, 6.14, 5.86, 5.59, 6.16, 5.75, 5.45, 5.58, 6.4, 7.29, 8.06, 8.64, 9.06,
  9.45, 10.02, 10.78, 11.46, 11.97, 11.81, 11.69, 10.54, 9.43, 8.63, 7.78, 6.39, 6.22, 5.87, 5.41,
  5.84, 5.41, 5.52, 6.1, 6.43, 7.33, 8.2, 8.87, 9.37, 9.64, 9.75, 10.08, 10.54, 10.97, 10.84, 11.0,
  10.15, 9.15, 8.28, 7.41, 6.21, 6.01, 5.65, 5.18, 5.59, 5.11, 5.04, 5.47, 5.7, 6.54, 7.59, 8.13,
  8.67, 9.04, 9.44, 10.07, 10.61, 10.92, 10.53, 10.69, 10.06, 9.16, 8.32, 7.45, 6.09, 5.91, 5.68,
  5.46, 5.99, 5.56, 5.22, 5.29, 6.0, 6.97, 7.85, 8.53, 8.94, 9.0, 9.14, 9.87, 10.82, 11.5, 11.45,
  11.34, 10.17, 9.2, 8.42, 7.55, 6.22, 6.05, 5.8, 5.58, 6.16, 5.78, 5.48, 5.67, 6.45, 7.28, 8.18,
  8.98, 9.5, 9.83, 10.35, 11.11, 11.83, 12.28, 12.08, 11.98, 10.74, 9.65, 8.79, 7.9, 6.61, 6.42,
  6.17, 5.9, 6.6, 6.35, 6.08, 6.25, 7.23, 8.34, 9.35, 10.01, 10.36, 10.53, 10.91, 11.7, 12.42,
  12.93, 12.74, 12.6, 11.39, 10.35, 9.34, 8.41, 6.85, 6.58, 6.26, 5.97, 6.62, 6.31, 6.08, 6.35,
  7.34, 8.26, 8.94, 9.17, 9.49, 9.87, 10.19, 10.9, 11.56, 12.06, 11.96, 11.75, 10.46, 9.41, 8.6,
  7.72, 6.32, 6.14, 5.86, 5.59, 6.16, 5.75, 5.45, 5.58, 6.4, 7.29, 8.06, 8.64, 9.06, 9.45, 10.02,
  10.78, 11.46, 11.97, 11.81, 11.69, 10.54, 9.43, 8.63, 7.78, 6.39, 6.22, 5.87, 5.41, 5.84, 5.41,
  5.52, 6.1, 6.43, 7.33, 8.2, 8.87, 9.37, 9.64, 9.75, 10.08, 10.54, 10.97, 10.84, 11.0, 10.15, 9.15,
  8.28, 7.41, 6.21, 6.01, 5.65, 5.18, 5.59, 5.11, 5.04, 5.47, 5.7, 6.54, 7.59, 8.13, 8.67, 9.04,
  9.44, 10.07, 10.61, 10.92, 10.53, 10.69, 10.06, 9.16, 8.32, 7.45, 6.09, 5.91, 5.68, 5.46, 5.99,
  5.56, 5.22, 5.29, 6.0, 6.97, 7.85, 8.53, 8.94, 9.0, 9.14, 9.87, 10.82, 11.5, 11.45, 11.34, 10.17,
  9.2, 8.42, 7.55, 6.22, 6.05, 5.8, 5.58, 6.16, 5.78, 5.48, 5.67, 6.45, 7.28, 8.18, 8.98, 9.5, 9.83,
  10.35, 11.11, 11.83, 12.28, 12.08, 11.98, 10.74, 9.65, 8.79, 7.9, 6.61, 6.42, 6.17, 5.9, 6.6,
  6.35, 6.08, 6.25, 7.23, 8.34, 9.35, 10.01, 10.36, 10.53, 10.91, 11.7, 12.42, 12.93, 12.74, 12.6,
  11.39, 10.35, 9.34, 8.41, 6.85, 6.58, 6.26, 5.97, 6.62, 6.31, 6.08, 6.35, 7.34, 8.26, 8.94, 9.17,
  9.49, 9.87, 10.19, 10.9, 11.56, 12.06, 11.96, 11.75, 10.46, 9.41, 8.6, 7.72, 6.32, 6.14, 5.86,
  5.59, 6.16, 5.75, 5.45, 5.58, 6.4, 7.29, 8.06, 8.64, 9.06, 9.45, 10.02, 10.78, 11.46, 11.97,
  11.81, 11.69, 10.54, 9.43, 8.63, 7.78, 6.39, 6.22, 5.87, 5.41, 5.84, 5.41, 5.52, 6.1, 6.43, 7.33,
  8.2, 8.87, 9.37, 9.64, 9.75, 10.08, 10.54, 10.97, 10.84, 11.0, 10.15, 9.15, 8.28, 7.41, 6.21,
  6.01, 5.65, 5.18, 5.59, 5.11, 5.04, 5.47, 5.7, 6.54, 7.59, 8.13, 8.67, 9.04, 9.44, 10.07, 10.61,
  10.92, 10.53, 10.69, 10.06, 9.16, 8.32, 7.45, 6.09, 5.91, 5.68, 5.46, 5.99, 5.56, 5.22, 5.29, 6.0,
  6.97, 7.85, 8.53, 8.94, 9.0, 9.14, 9.87, 10.82, 11.5, 11.45, 11.34, 10.17, 9.2, 8.42, 7.55, 6.22,
  6.05, 5.8, 5.58, 6.16, 5.78, 5.48, 5.67, 6.45, 7.28, 8.18, 8.98, 9.5, 9.83, 10.35, 11.11, 11.83,
  12.28, 12.08, 11.98, 10.74, 9.65, 8.79, 7.9, 6.61, 6.42, 6.17, 5.9, 6.6, 6.35, 6.08, 6.25, 7.23,
  8.34, 9.35, 10.01, 10.36, 10.53, 10.91, 11.7, 12.42, 12.93, 12.74, 12.6, 11.39, 10.35, 9.34, 8.41,
  6.32, 6.08, 5.8, 5.53, 6.31, 5.66, 5.17, 5.21, 5.83, 6.63, 7.44, 8.08, 8.48, 8.59, 8.98, 9.75,
  10.45, 11.04, 11.28, 11.18, 10.05, 9.1, 8.37, 7.54, 6.18, 6.02, 5.78, 5.59, 6.43, 5.8, 5.36, 5.4,
  6.15, 7.05, 7.87, 8.54, 9.02, 9.37, 9.76, 10.4, 11.1, 11.64, 11.77, 11.58, 10.35, 9.47, 8.74,
  7.83, 6.48, 6.32, 6.02, 5.56, 6.31, 5.5, 5.41, 5.97, 6.33, 7.28, 8.14, 8.73, 9.31, 9.61, 9.88,
  10.49, 11.02, 11.29, 11.1, 11.24, 10.4, 9.41, 8.48, 7.58, 6.23, 6.03, 5.69, 5.25, 5.95, 5.19, 5.1,
  5.53, 5.83, 6.61, 7.65, 8.26, 8.71, 8.89, 9.13, 9.75, 10.3, 10.48, 10.42, 10.66, 9.98, 9.1, 8.26,
  7.31, 5.98, 5.84, 5.56, 5.33, 6.08, 5.29, 4.85, 4.87, 5.48, 6.12, 6.83, 7.48, 7.96, 8.18, 8.53,
  9.25, 9.88, 10.46, 10.65, 10.57, 9.39, 8.45, 7.76, 7.01, 5.82, 5.65, 5.42, 5.24, 5.95, 5.19, 4.68,
  4.75, 5.45, 6.2, 6.91, 7.51, 7.89, 8.11, 8.6, 9.46, 10.22, 10.77, 10.88, 10.79, 9.68, 8.8, 8.08,
  7.3, 6.07, 5.94, 5.68, 5.47, 6.26, 5.63, 5.23, 5.29, 6.06, 6.95, 7.77, 8.47, 8.97, 9.36, 9.84,
  10.62, 11.36, 11.78, 11.86, 11.82, 10.49, 9.41, 8.59, 7.73, 6.32, 6.08, 5.8, 5.53, 6.31, 5.66,
  5.17, 5.21, 5.83, 6.63, 7.44, 8.08, 8.48, 8.59, 8.98, 9.75, 10.45, 11.04, 11.28, 11.18, 10.05,
  9.1, 8.37, 7.54, 6.18, 6.02, 5.78, 5.59, 6.43, 5.8, 5.36, 5.4, 6.15, 7.05, 7.87, 8.54, 9.02, 9.37,
  9.76, 10.4, 11.1, 11.64, 11.77, 11.58, 10.35, 9.47, 8.74, 7.83, 6.48, 6.32, 6.02, 5.56, 6.31, 5.5,
  5.41, 5.97, 6.33, 7.28, 8.14, 8.73, 9.31, 9.61, 9.88, 10.49, 11.02, 11.29, 11.1, 11.24, 10.4,
  9.41, 8.48, 7.58, 6.23, 6.03, 5.69, 5.25, 5.95, 5.19, 5.1, 5.53, 5.83, 6.61, 7.65, 8.26, 8.71,
  8.89, 9.13, 9.75, 10.3, 10.48, 10.42, 10.66, 9.98, 9.1, 8.26, 7.31, 5.98, 5.84, 5.56, 5.33, 6.08,
  5.29, 4.85, 4.87, 5.48, 6.12, 6.83, 7.48, 7.96, 8.18, 8.53, 9.25, 9.88, 10.46, 10.65, 10.57, 9.39,
  8.45, 7.76, 7.01, 5.82, 5.65, 5.42, 5.24, 5.95, 5.19, 4.68, 4.75, 5.45, 6.2, 6.91, 7.51, 7.89,
  8.11, 8.6, 9.46, 10.22, 10.77, 10.88, 10.79, 9.68, 8.8, 8.08, 7.3, 6.07, 5.94, 5.68, 5.47, 6.26,
  5.63, 5.23, 5.29, 6.06, 6.95, 7.77, 8.47, 8.97, 9.36, 9.84, 10.62, 11.36, 11.78, 11.86, 11.82,
  10.49, 9.41, 8.59, 7.73, 6.32, 6.08, 5.8, 5.53, 6.31, 5.66, 5.17, 5.21, 5.83, 6.63, 7.44, 8.08,
  8.48, 8.59, 8.98, 9.75, 10.45, 11.04, 11.28, 11.18, 10.05, 9.1, 8.37, 7.54, 6.18, 6.02, 5.78,
  5.59, 6.43, 5.8, 5.36, 5.4, 6.15, 7.05, 7.87, 8.54, 9.02, 9.37, 9.76, 10.4, 11.1, 11.64, 11.77,
  11.58, 10.35, 9.47, 8.74, 7.83, 6.48, 6.32, 6.02, 5.56, 6.31, 5.5, 5.41, 5.97, 6.33, 7.28, 8.14,
  8.73, 9.31, 9.61, 9.88, 10.49, 11.02, 11.29, 11.1, 11.24, 10.4, 9.41, 8.48, 7.58, 6.23, 6.03,
  5.69, 5.25, 5.95, 5.19, 5.1, 5.53, 5.83, 6.61, 7.65, 8.26, 8.71, 8.89, 9.13, 9.75, 10.3, 10.48,
  10.42, 10.66, 9.98, 9.1, 8.26, 7.31, 5.98, 5.84, 5.56, 5.33, 6.08, 5.29, 4.85, 4.87, 5.48, 6.12,
  6.83, 7.48, 7.96, 8.18, 8.53, 9.25, 9.88, 10.46, 10.65, 10.57, 9.39, 8.45, 7.76, 7.01, 5.82, 5.65,
  5.42, 5.24, 5.95, 5.19, 4.68, 4.75, 5.45, 6.2, 6.91, 7.51, 7.89, 8.11, 8.6, 9.46, 10.22, 10.77,
  10.88, 10.79, 9.68, 8.8, 8.08, 7.3, 6.07, 5.94, 5.68, 5.47, 6.26, 5.63, 5.23, 5.29, 6.06, 6.95,
  7.77, 8.47, 8.97, 9.36, 9.84, 10.62, 11.36, 11.78, 11.86, 11.82, 10.49, 9.41, 8.59, 7.73, 6.32,
  6.08, 5.8, 5.53, 6.31, 5.66, 5.17, 5.21, 5.83, 6.63, 7.44, 8.08, 8.48, 8.59, 8.98, 9.75, 10.45,
  11.04, 11.28, 11.18, 10.05, 9.1, 8.37, 7.54, 6.18, 6.02, 5.78, 5.59, 6.43, 5.8, 5.36, 5.4, 6.15,
  7.05, 7.87, 8.54, 9.02, 9.37, 9.76, 10.4, 11.1, 11.64, 11.77, 11.58, 10.35, 9.47, 8.74, 7.83,
  6.48, 6.32, 6.02, 5.56, 6.31, 5.5, 5.41, 5.97, 6.33, 7.28, 8.14, 8.73, 9.31, 9.61, 9.88, 10.49,
  11.02, 11.29, 11.1, 11.24, 10.4, 9.41, 8.48, 7.58, 6.23, 6.03, 5.69, 5.25, 5.95, 5.19, 5.1, 5.53,
  5.83, 6.61, 7.65, 8.26, 8.71, 8.89, 9.13, 9.75, 10.3, 10.48, 10.42, 10.66, 9.98, 9.1, 8.26, 7.31,
  5.98, 5.84, 5.56, 5.33, 6.08, 5.29, 4.85, 4.87, 5.48, 6.12, 6.83, 7.48, 7.96, 8.18, 8.53, 9.25,
  9.88, 10.46, 10.65, 10.57, 9.39, 8.45, 7.76, 7.01, 5.82, 5.65, 5.42, 5.24, 5.95, 5.19, 4.68, 4.75,
  5.45, 6.2, 6.91, 7.51, 7.89, 8.11, 8.6, 9.46, 10.22, 10.77, 10.88, 10.79, 9.68, 8.8, 8.08, 7.3,
  6.07, 5.94, 5.68, 5.47, 6.26, 5.63, 5.23, 5.29, 6.06, 6.95, 7.77, 8.47, 8.97, 9.36, 9.84, 10.62,
  11.36, 11.78, 11.86, 11.82, 10.49, 9.41, 8.59, 7.73, 6.32, 6.08, 5.8, 5.53, 6.31, 5.66, 5.17,
  5.21, 5.83, 6.63, 7.44, 8.08, 8.48, 8.59, 8.98, 9.75, 10.45, 11.04, 11.28, 11.18, 10.05, 9.1,
  8.37, 7.54, 6.18, 6.02, 5.78, 5.59, 6.43, 5.8, 5.36, 5.4, 6.15, 7.05, 7.87, 8.54, 9.02, 9.37,
  9.76, 10.4, 11.1, 11.64, 11.77, 11.58, 10.35, 9.47, 8.74, 7.83, 6.48, 6.32, 6.02, 5.56, 6.31, 5.5,
  5.41, 5.97, 6.33, 7.28, 8.14, 8.73, 9.31, 9.61, 9.88, 10.49, 11.02, 11.29, 11.1, 11.24, 10.4,
  9.41, 8.48, 7.58, 5.69, 5.57, 5.25, 4.85, 5.57, 5.1, 4.43, 4.53, 4.55, 5.07, 5.91, 6.44, 6.96,
  7.26, 7.6, 8.26, 8.87, 9.36, 9.87, 9.64, 9.04, 8.29, 7.61, 6.92, 5.82, 5.7, 5.48, 5.3, 6.12, 5.66,
  4.74, 4.67, 5.2, 5.95, 6.7, 7.32, 7.79, 7.98, 8.33, 9.06, 9.72, 10.31, 10.89, 10.43, 9.35, 8.5,
  7.85, 7.09, 5.88, 5.74, 5.48, 5.29, 6.06, 5.61, 4.73, 4.66, 5.25, 5.99, 6.68, 7.34, 7.81, 8.05,
  8.42, 9.14, 9.8, 10.38, 10.95, 10.41, 9.34, 8.4, 7.74, 6.99, 5.81, 5.72, 5.52, 5.32, 6.1, 5.72,
  4.77, 4.67, 5.23, 5.99, 6.77, 7.33, 7.79, 7.98, 8.26, 8.97, 9.62, 10.3, 10.89, 10.43, 9.38, 8.51,
  7.88, 7.16, 5.96, 5.85, 5.65, 5.44, 6.27, 5.86, 4.9, 4.76, 5.19, 5.77, 6.32, 6.88, 7.28, 7.53,
  7.98, 8.79, 9.47, 10.14, 10.73, 10.3, 9.31, 8.47, 7.86, 7.17, 5.96, 5.84, 5.58, 5.39, 6.21, 5.92,
  4.96, 4.89, 5.42, 6.16, 6.91, 7.51, 7.9, 8.14, 8.54, 9.32, 10.04, 10.77, 11.36, 10.82, 9.61, 8.62,
  7.88, 7.09, 5.87, 5.7, 5.44, 5.04, 5.71, 5.33, 4.73, 4.98, 5.08, 5.74, 6.5, 7.04, 7.53, 7.78,
  8.03, 8.62, 9.13, 9.52, 9.97, 9.69, 9.0, 8.18, 7.49, 6.79, 5.69, 5.57, 5.25, 4.85, 5.57, 5.1,
  4.43, 4.53, 4.55, 5.07, 5.91, 6.44, 6.96, 7.26, 7.6, 8.26, 8.87, 9.36, 9.87, 9.64, 9.04, 8.29,
  7.61, 6.92, 5.82, 5.7, 5.48, 5.3, 6.12, 5.66, 4.74, 4.67, 5.2, 5.95, 6.7, 7.32, 7.79, 7.98, 8.33,
  9.06, 9.72, 10.31, 10.89, 10.43, 9.35, 8.5, 7.85, 7.09, 5.88, 5.74, 5.48, 5.29, 6.06, 5.61, 4.73,
  4.66, 5.25, 5.99, 6.68, 7.34, 7.81, 8.05, 8.42, 9.14, 9.8, 10.38, 10.95, 10.41, 9.34, 8.4, 7.74,
  6.99, 5.81, 5.72, 5.52, 5.32, 6.1, 5.72, 4.77, 4.67, 5.23, 5.99, 6.77, 7.33, 7.79, 7.98, 8.26,
  8.97, 9.62, 10.3, 10.89, 10.43, 9.38, 8.51, 7.88, 7.16, 5.96, 5.85, 5.65, 5.44, 6.27, 5.86, 4.9,
  4.76, 5.19, 5.77, 6.32, 6.88, 7.28, 7.53, 7.98, 8.79, 9.47, 10.14, 10.73, 10.3, 9.31, 8.47, 7.86,
  7.17, 5.96, 5.84, 5.58, 5.39, 6.21, 5.92, 4.96, 4.89, 5.42, 6.16, 6.91, 7.51, 7.9, 8.14, 8.54,
  9.32, 10.04, 10.77, 11.36, 10.82, 9.61, 8.62, 7.88, 7.09, 5.87, 5.7, 5.44, 5.04, 5.71, 5.33, 4.73,
  4.98, 5.08, 5.74, 6.5, 7.04, 7.53, 7.78, 8.03, 8.62, 9.13, 9.52, 9.97, 9.69, 9.0, 8.18, 7.49,
  6.79, 5.69, 5.57, 5.25, 4.85, 5.57, 5.1, 4.43, 4.53, 4.55, 5.07, 5.91, 6.44, 6.96, 7.26, 7.6,
  8.26, 8.87, 9.36, 9.87, 9.64, 9.04, 8.29, 7.61, 6.92, 5.82, 5.7, 5.48, 5.3, 6.12, 5.66, 4.74,
  4.67, 5.2, 5.95, 6.7, 7.32, 7.79, 7.98, 8.33, 9.06, 9.72, 10.31, 10.89, 10.43, 9.35, 8.5, 7.85,
  7.09, 5.88, 5.74, 5.48, 5.29, 6.06, 5.61, 4.73, 4.66, 5.25, 5.99, 6.68, 7.34, 7.81, 8.05, 8.42,
  9.14, 9.8, 10.38, 10.95, 10.41, 9.34, 8.4, 7.74, 6.99, 5.81, 5.72, 5.52, 5.32, 6.1, 5.72, 4.77,
  4.67, 5.23, 5.99, 6.77, 7.33, 7.79, 7.98, 8.26, 8.97, 9.62, 10.3, 10.89, 10.43, 9.38, 8.51, 7.88,
  7.16, 5.96, 5.85, 5.65, 5.44, 6.27, 5.86, 4.9, 4.76, 5.19, 5.77, 6.32, 6.88, 7.28, 7.53, 7.98,
  8.79, 9.47, 10.14, 10.73, 10.3, 9.31, 8.47, 7.86, 7.17, 5.96, 5.84, 5.58, 5.39, 6.21, 5.92, 4.96,
  4.89, 5.42, 6.16, 6.91, 7.51, 7.9, 8.14, 8.54, 9.32, 10.04, 10.77, 11.36, 10.82, 9.61, 8.62, 7.88,
  7.09, 5.87, 5.7, 5.44, 5.04, 5.71, 5.33, 4.73, 4.98, 5.08, 5.74, 6.5, 7.04, 7.53, 7.78, 8.03,
  8.62, 9.13, 9.52, 9.97, 9.69, 9.0, 8.18, 7.49, 6.79, 5.69, 5.57, 5.25, 4.85, 5.57, 5.1, 4.43,
  4.53, 4.55, 5.07, 5.91, 6.44, 6.96, 7.26, 7.6, 8.26, 8.87, 9.36, 9.87, 9.64, 9.04, 8.29, 7.61,
  6.92, 5.82, 5.7, 5.48, 5.3, 6.12, 5.66, 4.74, 4.67, 5.2, 5.95, 6.7, 7.32, 7.79, 7.98, 8.33, 9.06,
  9.72, 10.31, 10.89, 10.43, 9.35, 8.5, 7.85, 7.09, 5.88, 5.74, 5.48, 5.29, 6.06, 5.61, 4.73, 4.66,
  5.25, 5.99, 6.68, 7.34, 7.81, 8.05, 8.42, 9.14, 9.8, 10.38, 10.95, 10.41, 9.34, 8.4, 7.74, 6.99,
  5.81, 5.72, 5.52, 5.32, 6.1, 5.72, 4.77, 4.67, 5.23, 5.99, 6.77, 7.33, 7.79, 7.98, 8.26, 8.97,
  9.62, 10.3, 10.89, 10.43, 9.38, 8.51, 7.88, 7.16, 5.96, 5.85, 5.65, 5.44, 6.27, 5.86, 4.9, 4.76,
  5.19, 5.77, 6.32, 6.88, 7.28, 7.53, 7.98, 8.79, 9.47, 10.14, 10.73, 10.3, 9.31, 8.47, 7.86, 7.17,
  5.96, 5.84, 5.58, 5.39, 6.21, 5.92, 4.96, 4.89, 5.42, 6.16, 6.91, 7.51, 7.9, 8.14, 8.54, 9.32,
  10.04, 10.77, 11.36, 10.82, 9.61, 8.62, 7.88, 7.09, 5.87, 5.7, 5.44, 5.04, 5.71, 5.33, 4.73, 4.98,
  5.08, 5.74, 6.5, 7.04, 7.53, 7.78, 8.03, 8.62, 9.13, 9.52, 9.97, 9.69, 9.0, 8.18, 7.49, 6.79,
  5.69, 5.57, 5.25, 4.85, 5.57, 5.1, 4.43, 4.53, 4.55, 5.07, 5.91, 6.44, 6.96, 7.26, 7.6, 8.26,
  8.87, 9.36, 9.87, 9.64, 9.04, 8.29, 7.61, 6.92, 5.82, 5.7, 5.48, 5.3, 6.12, 5.66, 4.74, 4.67, 5.2,
  5.95, 6.7, 7.32, 7.79, 7.98, 8.33, 9.06, 9.72, 10.31, 10.89, 10.43, 9.35, 8.5, 7.85, 7.09, 5.53,
  5.43, 5.23, 5.09, 5.78, 5.58, 4.34, 4.12, 4.4, 4.77, 5.23, 5.82, 6.29, 6.56, 6.96, 7.72, 8.43,
  9.62, 9.79, 9.35, 8.46, 7.71, 7.2, 6.58, 5.52, 5.44, 5.26, 5.1, 5.81, 5.62, 4.36, 4.09, 4.4, 4.78,
  5.24, 5.81, 6.27, 6.48, 6.76, 7.48, 8.2, 9.43, 9.65, 9.29, 8.48, 7.74, 7.24, 6.63, 5.56, 5.47,
  5.29, 5.13, 5.83, 5.69, 4.47, 4.26, 4.59, 5.01, 5.54, 6.07, 6.51, 6.71, 7.01, 7.73, 8.42, 9.56,
  9.73, 9.33, 8.48, 7.71, 7.23, 6.6, 5.52, 5.42, 5.22, 5.07, 5.77, 5.57, 4.33, 4.07, 4.35, 4.65,
  5.09, 5.61, 6.03, 6.27, 6.64, 7.38, 8.1, 9.34, 9.51, 9.16, 8.34, 7.62, 7.15, 6.54, 5.48, 5.38,
  5.16, 4.79, 5.42, 5.3, 4.31, 4.3, 4.28, 4.66, 5.09, 5.49, 5.91, 6.19, 6.5, 7.08, 7.72, 8.78, 8.83,
  8.69, 8.16, 7.49, 7.0, 6.46, 5.48, 5.37, 5.1, 4.75, 5.46, 5.3, 4.31, 4.28, 4.19, 4.46, 5.02, 5.38,
  5.81, 6.11, 6.45, 7.06, 7.74, 8.86, 8.88, 8.74, 8.28, 7.62, 7.11, 6.52, 5.51, 5.41, 5.22, 5.07,
  5.77, 5.57, 4.31, 4.07, 4.4, 4.77, 5.26, 5.83, 6.27, 6.51, 6.91, 7.65, 8.36, 9.57, 9.72, 9.34,
  8.48, 7.74, 7.22, 6.6, 5.53, 5.43, 5.23, 5.09, 5.78, 5.58, 4.34, 4.12, 4.4, 4.77, 5.23, 5.82,
  6.29, 6.56, 6.96, 7.72, 8.43, 9.62, 9.79, 9.35, 8.46, 7.71, 7.2, 6.58, 5.52, 5.44, 5.26, 5.1,
  5.81, 5.62, 4.36, 4.09, 4.4, 4.78, 5.24, 5.81, 6.27, 6.48, 6.76, 7.48, 8.2, 9.43, 9.65, 9.29,
  8.48, 7.74, 7.24, 6.63, 5.56, 5.47, 5.29, 5.13, 5.83, 5.69, 4.47, 4.26, 4.59, 5.01, 5.54, 6.07,
  6.51, 6.71, 7.01, 7.73, 8.42, 9.56, 9.73, 9.33, 8.48, 7.71, 7.23, 6.6, 5.52, 5.42, 5.22, 5.07,
  5.77, 5.57, 4.33, 4.07, 4.35, 4.65, 5.09, 5.61, 6.03, 6.27, 6.64, 7.38, 8.1, 9.34, 9.51, 9.16,
  8.34, 7.62, 7.15, 6.54, 5.48, 5.38, 5.16, 4.79, 5.42, 5.3, 4.31, 4.3, 4.28, 4.66, 5.09, 5.49,
  5.91, 6.19, 6.5, 7.08, 7.72, 8.78, 8.83, 8.69, 8.16, 7.49, 7.0, 6.46, 5.48, 5.37, 5.1, 4.75, 5.46,
  5.3, 4.31, 4.28, 4.19, 4.46, 5.02, 5.38, 5.81, 6.11, 6.45, 7.06, 7.74, 8.86, 8.88, 8.74, 8.28,
  7.62, 7.11, 6.52, 5.51, 5.41, 5.22, 5.07, 5.77, 5.57, 4.31, 4.07, 4.4, 4.77, 5.26, 5.83, 6.27,
  6.51, 6.91, 7.65, 8.36, 9.57, 9.72, 9.34, 8.48, 7.74, 7.22, 6.6, 5.53, 5.43, 5.23, 5.09, 5.78,
  5.58, 4.34, 4.12, 4.4, 4.77, 5.23, 5.82, 6.29, 6.56, 6.96, 7.72, 8.43, 9.62, 9.79, 9.35, 8.46,
  7.71, 7.2, 6.58, 5.52, 5.44, 5.26, 5.1, 5.81, 5.62, 4.36, 4.09, 4.4, 4.78, 5.24, 5.81, 6.27, 6.48,
  6.76, 7.48, 8.2, 9.43, 9.65, 9.29, 8.48, 7.74, 7.24, 6.63, 5.56, 5.47, 5.29, 5.13, 5.83, 5.69,
  4.47, 4.26, 4.59, 5.01, 5.54, 6.07, 6.51, 6.71, 7.01, 7.73, 8.42, 9.56, 9.73, 9.33, 8.48, 7.71,
  7.23, 6.6, 5.52, 5.42, 5.22, 5.07, 5.77, 5.57, 4.33, 4.07, 4.35, 4.65, 5.09, 5.61, 6.03, 6.27,
  6.64, 7.38, 8.1, 9.34, 9.51, 9.16, 8.34, 7.62, 7.15, 6.54, 5.48, 5.38, 5.16, 4.79, 5.42, 5.3,
  4.31, 4.3, 4.28, 4.66, 5.09, 5.49, 5.91, 6.19, 6.5, 7.08, 7.72, 8.78, 8.83, 8.69, 8.16, 7.49, 7.0,
  6.46, 5.48, 5.37, 5.1, 4.75, 5.46, 5.3, 4.31, 4.28, 4.19, 4.46, 5.02, 5.38, 5.81, 6.11, 6.45,
  7.06, 7.74, 8.86, 8.88, 8.74, 8.28, 7.62, 7.11, 6.52, 5.51, 5.41, 5.22, 5.07, 5.77, 5.57, 4.31,
  4.07, 4.4, 4.77, 5.26, 5.83, 6.27, 6.51, 6.91, 7.65, 8.36, 9.57, 9.72, 9.34, 8.48, 7.74, 7.22,
  6.6, 5.53, 5.43, 5.23, 5.09, 5.78, 5.58, 4.34, 4.12, 4.4, 4.77, 5.23, 5.82, 6.29, 6.56, 6.96,
  7.72, 8.43, 9.62, 9.79, 9.35, 8.46, 7.71, 7.2, 6.58, 5.52, 5.44, 5.26, 5.1, 5.81, 5.62, 4.36,
  4.09, 4.4, 4.78, 5.24, 5.81, 6.27, 6.48, 6.76, 7.48, 8.2, 9.43, 9.65, 9.29, 8.48, 7.74, 7.24,
  6.63, 5.56, 5.47, 5.29, 5.13, 5.83, 5.69, 4.47, 4.26, 4.59, 5.01, 5.54, 6.07, 6.51, 6.71, 7.01,
  7.73, 8.42, 9.56, 9.73, 9.33, 8.48, 7.71, 7.23, 6.6, 5.52, 5.42, 5.22, 5.07, 5.77, 5.57, 4.33,
  4.07, 4.35, 4.65, 5.09, 5.61, 6.03, 6.27, 6.64, 7.38, 8.1, 9.34, 9.51, 9.16, 8.34, 7.62, 7.15,
  6.54, 5.48, 5.38, 5.16, 4.79, 5.42, 5.3, 4.31, 4.3, 4.28, 4.66, 5.09, 5.49, 5.91, 6.19, 6.5, 7.08,
  7.72, 8.78, 8.83, 8.69, 8.16, 7.49, 7.0, 6.46, 5.48, 5.37, 5.1, 4.75, 5.46, 5.3, 4.31, 4.28, 4.19,
  4.46, 5.02, 5.38, 5.81, 6.11, 6.45, 7.06, 7.74, 8.86, 8.88, 8.74, 8.28, 7.62, 7.11, 6.52, 5.51,
  5.41, 5.22, 5.07, 5.77, 5.57, 4.31, 4.07, 4.4, 4.77, 5.26, 5.83, 6.27, 6.51, 6.91, 7.65, 8.36,
  9.57, 9.72, 9.34, 8.48, 7.74, 7.22, 6.6, 5.53, 5.43, 5.23, 5.09, 5.78, 5.58, 4.34, 4.12, 4.4,
  4.77, 5.23, 5.82, 6.29, 6.56, 6.96, 7.72, 8.43, 9.62, 9.79, 9.35, 8.46, 7.71, 7.2, 6.58, 5.52,
  5.44, 5.26, 5.1, 5.81, 5.62, 4.36, 4.09, 4.4, 4.78, 5.24, 5.81, 6.27, 6.48, 6.76, 7.48, 8.2, 9.43,
  9.65, 9.29, 8.48, 7.74, 7.24, 6.63, 5.56, 5.47, 5.29, 5.13, 5.83, 5.69, 4.47, 4.26, 4.59, 5.01,
  5.54, 6.07, 6.51, 6.71, 7.01, 7.73, 8.42, 9.56, 9.73, 9.33, 8.48, 7.71, 7.23, 6.6, 6.31, 5.45,
  5.34, 5.17, 5.19, 5.75, 5.24, 4.18, 4.09, 4.39, 4.68, 5.06, 5.55, 5.94, 6.18, 6.63, 7.74, 8.75,
  9.29, 9.33, 8.89, 8.1, 7.45, 6.98, 6.19, 5.45, 5.3, 5.3, 5.06, 4.95, 5.39, 4.93, 4.25, 4.31, 4.4,
  4.87, 5.3, 5.7, 6.08, 6.34, 6.67, 7.61, 8.55, 8.89, 8.85, 8.65, 8.08, 7.45, 6.91, 6.27, 5.47,
  5.33, 5.02, 4.93, 5.44, 4.99, 4.27, 4.31, 4.31, 4.7, 5.25, 5.59, 5.94, 6.23, 6.57, 7.52, 8.48,
  8.79, 8.74, 8.55, 8.04, 7.45, 6.94, 6.54, 5.48, 5.38, 5.2, 5.06, 5.78, 5.3, 4.2, 4.05, 4.34, 4.61,
  4.97, 5.47, 5.88, 6.08, 6.45, 7.57, 8.59, 9.25, 9.39, 9.06, 8.22, 7.55, 7.09, 6.53, 5.47, 5.38,
  5.19, 5.06, 5.78, 5.37, 4.22, 4.04, 4.36, 4.63, 5.02, 5.55, 5.95, 6.12, 6.48, 7.59, 8.61, 9.26,
  9.4, 9.07, 8.23, 7.54, 7.1, 6.55, 5.48, 5.4, 5.24, 5.08, 5.8, 5.47, 4.27, 4.08, 4.37, 4.64, 5.01,
  5.52, 5.93, 6.11, 6.46, 7.65, 8.62, 9.3, 9.49, 9.14, 8.3, 7.58, 7.15, 6.36, 5.48, 5.38, 5.22,
  5.22, 5.77, 5.26, 4.22, 4.11, 4.39, 4.67, 5.02, 5.5, 5.85, 6.07, 6.52, 7.65, 8.64, 9.23, 9.3,
  8.89, 8.08, 7.45, 7.0, 6.31, 5.45, 5.34, 5.17, 5.19, 5.75, 5.24, 4.18, 4.09, 4.39, 4.68, 5.06,
  5.55, 5.94, 6.18, 6.63, 7.74, 8.75, 9.29, 9.33, 8.89, 8.1, 7.45, 6.98, 6.19, 5.45, 5.3, 5.06,
  4.95, 5.39, 4.93, 4.25, 4.31, 4.4, 4.87, 5.3, 5.7, 6.08, 6.34, 6.67, 7.61, 8.55, 8.89, 8.85, 8.65,
  8.08, 7.45, 6.91, 6.27, 5.47, 5.33, 5.02, 4.93, 5.44, 4.99, 4.27, 4.31, 4.31, 4.7, 5.25, 5.59,
  5.94, 6.23, 6.57, 7.52, 8.48, 8.79, 8.74, 8.55, 8.04, 7.45, 6.94, 6.54, 5.48, 5.38, 5.2, 5.06,
  5.78, 5.3, 4.2, 4.05, 4.34, 4.61, 4.97, 5.47, 5.88, 6.08, 6.45, 7.57, 8.59, 9.25, 9.39, 9.06,
  8.22, 7.55, 7.09, 6.53, 5.47, 5.38, 5.19, 5.06, 5.78, 5.37, 4.22, 4.04, 4.36, 4.63, 5.02, 5.55,
  5.95, 6.12, 6.48, 7.59, 8.61, 9.26, 9.4, 9.07, 8.23, 7.54, 7.1, 6.55, 5.48, 5.4, 5.24, 5.08, 5.8,
  5.47, 4.27, 4.08, 4.37, 4.64, 5.01, 5.52, 5.93, 6.11, 6.46, 7.65, 8.62, 9.3, 9.49, 9.14, 8.3,
  7.58, 7.15, 6.36, 5.48, 5.38, 5.22, 5.22, 5.77, 5.26, 4.22, 4.11, 4.39, 4.67, 5.02, 5.5, 5.85,
  6.07, 6.52, 7.65, 8.64, 9.23, 9.3, 8.89, 8.08, 7.45, 7.0, 6.31, 5.45, 5.34, 5.17, 5.19, 5.75,
  5.24, 4.18, 4.09, 4.39, 4.68, 5.06, 5.55, 5.94, 6.18, 6.63, 7.74, 8.75, 9.29, 9.33, 8.89, 8.1,
  7.45, 6.98, 6.19, 5.45, 5.3, 5.06, 4.95, 5.39, 4.93, 4.25, 4.31, 4.4, 4.87, 5.3, 5.7, 6.08, 6.34,
  6.67, 7.61, 8.55, 8.89, 8.85, 8.65, 8.08, 7.45, 6.91, 6.27, 5.47, 5.33, 5.02, 4.93, 5.44, 4.99,
  4.27, 4.31, 4.31, 4.7, 5.25, 5.59, 5.94, 6.23, 6.57, 7.52, 8.48, 8.79, 8.74, 8.55, 8.04, 7.45,
  6.94, 6.54, 5.48, 5.38, 5.2, 5.06, 5.78, 5.3, 4.2, 4.05, 4.34, 4.61, 4.97, 5.47, 5.88, 6.08, 6.45,
  7.57, 8.59, 9.25, 9.39, 9.06, 8.22, 7.55, 7.09, 6.53, 5.47, 5.38, 5.19, 5.06, 5.78, 5.37, 4.22,
  4.04, 4.36, 4.63, 5.02, 5.55, 5.95, 6.12, 6.48, 7.59, 8.61, 9.26, 9.4, 9.07, 8.23, 7.54, 7.1,
  6.55, 5.48, 5.4, 5.24, 5.08, 5.8, 5.47, 4.27, 4.08, 4.37, 4.64, 5.01, 5.52, 5.93, 6.11, 6.46,
  7.65, 8.62, 9.3, 9.49, 9.14, 8.3, 7.58, 7.15, 6.36, 5.48, 5.38, 5.22, 5.22, 5.77, 5.26, 4.22,
  4.11, 4.39, 4.67, 5.02, 5.5, 5.85, 6.07, 6.52, 7.65, 8.64, 9.23, 9.3, 8.89, 8.08, 7.45, 7.0, 6.31,
  5.45, 5.34, 5.17, 5.19, 5.75, 5.24, 4.18, 4.09, 4.39, 4.68, 5.06, 5.55, 5.94, 6.18, 6.63, 7.74,
  8.75, 9.29, 9.33, 8.89, 8.1, 7.45, 6.98, 6.19, 5.45, 5.3, 5.06, 4.95, 5.39, 4.93, 4.25, 4.31, 4.4,
  4.87, 5.3, 5.7, 6.08, 6.34, 6.67, 7.61, 8.55, 8.89, 8.85, 8.65, 8.08, 7.45, 6.91, 6.27, 5.47,
  5.33, 5.02, 4.93, 5.44, 4.99, 4.27, 4.31, 4.31, 4.7, 5.25, 5.59, 5.94, 6.23, 6.57, 7.52, 8.48,
  8.79, 8.74, 8.55, 8.04, 7.45, 6.94, 6.54, 5.48, 5.38, 5.2, 5.06, 5.78, 5.3, 4.2, 4.05, 4.34, 4.61,
  4.97, 5.47, 5.88, 6.08, 6.45, 7.57, 8.59, 9.25, 9.39, 9.06, 8.22, 7.55, 7.09, 6.53, 5.47, 5.38,
  5.19, 5.06, 5.78, 5.37, 4.22, 4.04, 4.36, 4.63, 5.02, 5.55, 5.95, 6.12, 6.48, 7.59, 8.61, 9.26,
  9.4, 9.07, 8.23, 7.54, 7.1, 6.55, 5.48, 5.4, 5.24, 5.08, 5.8, 5.47, 4.27, 4.08, 4.37, 4.64, 5.01,
  5.52, 5.93, 6.11, 6.46, 7.65, 8.62, 9.3, 9.49, 9.14, 8.3, 7.58, 7.15, 6.36, 5.48, 5.38, 5.22,
  5.22, 5.77, 5.26, 4.22, 4.11, 4.39, 4.67, 5.02, 5.5, 5.85, 6.07, 6.52, 7.65, 8.64, 9.23, 9.3,
  8.89, 8.08, 7.45, 7.0, 6.31, 5.45, 5.34, 5.17, 5.19, 5.75, 5.24, 4.18, 4.09, 4.39, 4.68, 5.06,
  5.55, 5.94, 6.18, 6.63, 7.74, 8.75, 9.29, 9.33, 8.89, 8.1, 7.45, 6.98, 6.19, 5.45, 5.3, 5.06,
  4.95, 5.39, 4.93, 4.25, 4.31, 4.4, 4.87, 5.3, 5.7, 6.08, 6.34, 6.67, 7.61, 8.55, 8.89, 8.85, 8.65,
  8.08, 7.45, 6.91, 6.51, 5.45, 5.37, 5.1, 4.74, 5.51, 5.33, 4.31, 4.27, 4.2, 4.46, 5.05, 5.32,
  5.72, 6.03, 6.34, 7.46, 8.28, 8.72, 8.69, 8.58, 8.13, 7.52, 7.04, 6.52, 5.47, 5.37, 5.19, 5.08,
  5.83, 5.66, 4.36, 4.05, 4.36, 4.62, 4.93, 5.41, 5.82, 6.01, 6.39, 7.66, 8.57, 9.22, 9.36, 9.03,
  8.18, 7.48, 7.08, 6.53, 5.46, 5.37, 5.2, 5.08, 5.83, 5.64, 4.34, 4.07, 4.35, 4.6, 4.92, 5.39,
  5.82, 5.99, 6.38, 7.7, 8.57, 9.2, 9.35, 9.01, 8.15, 7.47, 7.09, 6.59, 5.47, 5.39, 5.24, 5.1, 5.87,
  5.68, 4.47, 4.1, 4.37, 4.59, 4.88, 5.35, 5.77, 5.94, 6.31, 7.63, 8.5, 9.18, 9.35, 9.02, 8.17,
  7.48, 7.11, 6.59, 5.46, 5.39, 5.23, 5.1, 5.86, 5.69, 4.44, 4.08, 4.35, 4.61, 4.88, 5.35, 5.76,
  5.96, 6.31, 7.62, 8.51, 9.18, 9.36, 9.03, 8.16, 7.47, 7.08, 6.53, 5.47, 5.38, 5.19, 5.08, 5.83,
  5.66, 4.39, 4.06, 4.37, 4.6, 4.91, 5.4, 5.81, 6.0, 6.39, 7.71, 8.58, 9.23, 9.37, 9.04, 8.18, 7.49,
  7.1, 6.48, 5.46, 5.36, 5.15, 4.79, 5.51, 5.33, 4.34, 4.3, 4.27, 4.69, 5.1, 5.44, 5.83, 6.14, 6.43,
  7.57, 8.31, 8.73, 8.72, 8.6, 8.09, 7.44, 6.96, 6.51, 5.45, 5.37, 5.1, 4.74, 5.51, 5.33, 4.31,
  4.27, 4.2, 4.46, 5.05, 5.32, 5.72, 6.03, 6.34, 7.46, 8.28, 8.72, 8.69, 8.58, 8.13, 7.52, 7.04,
  6.52, 5.47, 5.37, 5.19, 5.08, 5.83, 5.66, 4.36, 4.05, 4.36, 4.62, 4.93, 5.41, 5.82, 6.01, 6.39,
  7.66, 8.57, 9.22, 9.36, 9.03, 8.18, 7.48, 7.08, 6.53, 5.46, 5.37, 5.2, 5.08, 5.83, 5.64, 4.34,
  4.07, 4.35, 4.6, 4.92, 5.39, 5.82, 5.99, 6.38, 7.7, 8.57, 9.2, 9.35, 9.01, 8.15, 7.47, 7.09, 6.59,
  5.47, 5.39, 5.24, 5.1, 5.87, 5.68, 4.47, 4.1, 4.37, 4.59, 4.88, 5.35, 5.77, 5.94, 6.31, 7.63, 8.5,
  9.18, 9.35, 9.02, 8.17, 7.48, 7.11, 6.59, 5.46, 5.39, 5.23, 5.1, 5.86, 5.69, 4.44, 4.08, 4.35,
  4.61, 4.88, 5.35, 5.76, 5.96, 6.31, 7.62, 8.51, 9.18, 9.36, 9.03, 8.16, 7.47, 7.08, 6.53, 5.47,
  5.38, 5.19, 5.08, 5.83, 5.66, 4.39, 4.06, 4.37, 4.6, 4.91, 5.4, 5.81, 6.0, 6.39, 7.71, 8.58, 9.23,
  9.37, 9.04, 8.18, 7.49, 7.1, 6.48, 5.46, 5.36, 5.15, 4.79, 5.51, 5.33, 4.34, 4.3, 4.27, 4.69, 5.1,
  5.44, 5.83, 6.14, 6.43, 7.57, 8.31, 8.73, 8.72, 8.6, 8.09, 7.44, 6.96, 6.51, 5.45, 5.37, 5.1,
  4.74, 5.51, 5.33, 4.31, 4.27, 4.2, 4.46, 5.05, 5.32, 5.72, 6.03, 6.34, 7.46, 8.28, 8.72, 8.69,
  8.58, 8.13, 7.52, 7.04, 6.52, 5.47, 5.37, 5.19, 5.08, 5.83, 5.66, 4.36, 4.05, 4.36, 4.62, 4.93,
  5.41, 5.82, 6.01, 6.39, 7.66, 8.57, 9.22, 9.36, 9.03, 8.18, 7.48, 7.08, 6.53, 5.46, 5.37, 5.2,
  5.08, 5.83, 5.64, 4.34, 4.07, 4.35, 4.6, 4.92, 5.39, 5.82, 5.99, 6.38, 7.7, 8.57, 9.2, 9.35, 9.01,
  8.15, 7.47, 7.09, 6.59, 5.47, 5.39, 5.24, 5.1, 5.87, 5.68, 4.47, 4.1, 4.37, 4.59, 4.88, 5.35,
  5.77, 5.94, 6.31, 7.63, 8.5, 9.18, 9.35, 9.02, 8.17, 7.48, 7.11, 6.59, 5.46, 5.39, 5.23, 5.1,
  5.86, 5.69, 4.44, 4.08, 4.35, 4.61, 4.88, 5.35, 5.76, 5.96, 6.31, 7.62, 8.51, 9.18, 9.36, 9.03,
  8.16, 7.47, 7.08, 6.53, 5.47, 5.38, 5.19, 5.08, 5.83, 5.66, 4.39, 4.06, 4.37, 4.6, 4.91, 5.4,
  5.81, 6.0, 6.39, 7.71, 8.58, 9.23, 9.37, 9.04, 8.18, 7.49, 7.1, 6.48, 5.46, 5.36, 5.15, 4.79,
  5.51, 5.33, 4.34, 4.3, 4.27, 4.69, 5.1, 5.44, 5.83, 6.14, 6.43, 7.57, 8.31, 8.73, 8.72, 8.6, 8.09,
  7.44, 6.96, 6.51, 5.45, 5.37, 5.1, 4.74, 5.51, 5.33, 4.31, 4.27, 4.2, 4.46, 5.05, 5.32, 5.72,
  6.03, 6.34, 7.46, 8.28, 8.72, 8.69, 8.58, 8.13, 7.52, 7.04, 6.52, 5.47, 5.37, 5.19, 5.08, 5.83,
  5.66, 4.36, 4.05, 4.36, 4.62, 4.93, 5.41, 5.82, 6.01, 6.39, 7.66, 8.57, 9.22, 9.36, 9.03, 8.18,
  7.48, 7.08, 6.53, 5.46, 5.37, 5.2, 5.08, 5.83, 5.64, 4.34, 4.07, 4.35, 4.6, 4.92, 5.39, 5.82,
  5.99, 6.38, 7.7, 8.57, 9.2, 9.35, 9.01, 8.15, 7.47, 7.09, 6.59, 5.47, 5.39, 5.24, 5.1, 5.87, 5.68,
  4.47, 4.1, 4.37, 4.59, 4.88, 5.35, 5.77, 5.94, 6.31, 7.63, 8.5, 9.18, 9.35, 9.02, 8.17, 7.48,
  7.11, 6.59, 5.46, 5.39, 5.23, 5.1, 5.86, 5.69, 4.44, 4.08, 4.35, 4.61, 4.88, 5.35, 5.76, 5.96,
  6.31, 7.62, 8.51, 9.18, 9.36, 9.03, 8.16, 7.47, 7.08, 6.53, 5.47, 5.38, 5.19, 5.08, 5.83, 5.66,
  4.39, 4.06, 4.37, 4.6, 4.91, 5.4, 5.81, 6.0, 6.39, 7.71, 8.58, 9.23, 9.37, 9.04, 8.18, 7.49, 7.1,
  6.48, 5.46, 5.36, 5.15, 4.79, 5.51, 5.33, 4.34, 4.3, 4.27, 4.69, 5.1, 5.44, 5.83, 6.14, 6.43,
  7.57, 8.31, 8.73, 8.72, 8.6, 8.09, 7.44, 6.96, 6.51, 5.45, 5.37, 5.1, 4.74, 5.51, 5.33, 4.31,
  4.27, 4.2, 4.46, 5.05, 5.32, 5.72, 6.03, 6.34, 7.46, 8.28, 8.72, 8.69, 8.58, 8.13, 7.52, 7.04,
  6.52, 5.47, 5.37, 5.19, 5.08, 5.83, 5.66, 4.36, 4.05, 4.36, 4.62, 4.93, 5.41, 5.82, 6.01, 6.39,
  7.66, 8.57, 9.22, 9.36, 9.03, 8.18, 7.48, 7.08, 6.53, 5.46, 5.37, 5.2, 5.08, 5.83, 5.64, 4.34,
  4.07, 4.35, 4.6, 4.92, 5.39, 5.82, 5.99, 6.38, 7.7, 8.57, 9.2, 9.35, 9.01, 8.15, 7.47, 7.09,
];

export default quickServiceRestaurantLoadProfile;
