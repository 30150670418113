import {Box, Tooltip, Typography, useMediaQuery, makeStyles, useTheme} from '@material-ui/core';
import {useIntl} from 'react-intl';
import DaysInOperation from '@generic/components/DaysInOperation';
import MonthsInOperation from '@generic/components/MonthsInOperation';
import SliderInput from '@generic/components/inputs/SliderInput';
import DropdownInput from '@generic/components/inputs/DropdownInput';
import IconCircleQuestion from '@generic/assets/images/IconCircleQuestion';
import TIME_PERIODS from '@generic/config/charger';

const useStyles = makeStyles((theme) => ({
  title: (props) => ({
    fontWeight: props.variant === 'gray' ? 600 : 700,
    textTransform: props.variant === 'gray' ? 'none' : 'uppercase',
    color: props.variant === 'gray' ? theme.palette.gray[600] : theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  }),
  tooltipTrigger: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function Utilization({
  chargerSet: {workdays, workmonths, chargersPerTimePeriod = {}, chargerCount, maxTimePeriod},
  onChangeChargerSet,
  variant = '',
}) {
  const classes = useStyles({variant});
  const {formatMessage} = useIntl();

  const handleChangeDays = (days) => {
    onChangeChargerSet({workdays: days});
  };

  const handleChangeMonths = (months) => {
    onChangeChargerSet({workmonths: months});
  };

  const handleChangeMaxTimePeriod = (periodKey) => {
    onChangeChargerSet({maxTimePeriod: periodKey});
  };

  const handleChangeChargerPerTimePeriod = (periodKey, chargerCount) => {
    // chargingWindows
    onChangeChargerSet({
      chargersPerTimePeriod: {...chargersPerTimePeriod, [periodKey]: chargerCount},
    });
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Box display="flex" flexDirection="column" sx={{gap: '1.75rem'}}>
      <Box>
        <DaysInOperation workdays={workdays} onChange={handleChangeDays} />
      </Box>
      <Box>
        <MonthsInOperation workmonths={workmonths} onChange={handleChangeMonths} />
      </Box>
      <Box>
        <DropdownInput
          fullWidth
          title={formatMessage({id: 'utilizationLevel.maxTimePeriodLabel'})}
          tooltip={formatMessage({id: 'utilizationLevel.maxTimePeriodTooltip'})}
          onChange={(e) => handleChangeMaxTimePeriod(e.target.value)}
          options={TIME_PERIODS.map((period) => ({
            value: period.key,
            children: period.label,
          }))}
          value={maxTimePeriod}
        />
      </Box>
      <Box className={classes.title}>
        <Typography variant="title">
          {formatMessage({id: 'utilizationLevel.avgTimePeriodLabel'})}
        </Typography>
        <Tooltip
          title={formatMessage({id: 'utilizationLevel.avgTimePeriodTooltip'})}
          arrow
          placement="right"
        >
          <span className={classes.tooltipTrigger}>
            <IconCircleQuestion />
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          sx={{gridColumnGap: isSmallScreen ? '1rem' : '1.75rem'}}
        >
          {TIME_PERIODS.map((period) => (
            <SliderInput
              key={period.key}
              min={0}
              max={chargerCount}
              step={1}
              value={chargersPerTimePeriod?.[period.key]}
              onChange={(value) => handleChangeChargerPerTimePeriod(period.key, value)}
              label={period.label}
              variant="gray"
              minWidth="40%"
              flex="1"
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
