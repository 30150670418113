import {getRates} from '@generic/data/rates';
import calcVehicleSetFacilityLoadProfile from '@generic/functions/calcVehicleSetFacilityLoadProfile';
import {LoadProfile, RateCalculator} from '@bellawatt/electric-rate-engine';
import {YEAR} from '@generic/data/assumptions';
import calcScaledFacilityLoadProfile from '@generic/functions/calcScaledFacilityLoadProfile';
import {calcSiteSccRebate, calcFleetSccRebate} from '@generic/functions/calcSccRebate';

const calcAnnualVehicleElectricityCostsAllRates = (inputs) => {
  const {separatelyMetered} = inputs;
  RateCalculator.shouldValidate = false;

  // get EV load profile
  const evLoadProfileData = calcVehicleSetFacilityLoadProfile(inputs);
  const evLoadProfile = new LoadProfile(evLoadProfileData, {year: YEAR});

  // if separately metered, run EV load profile through all rates
  if (separatelyMetered) {
    const maxDemand = evLoadProfile.max();

    return getRates(inputs, maxDemand).map((rate) => {
      const rateCalculator = new RateCalculator({...rate, loadProfile: evLoadProfile});
      const annualCost = rateCalculator.annualCost();

      return {
        title: rate.title,
        name: rate.name,
        facility: 0,
        ev: annualCost,
        total: annualCost,
        siteSccRebate: calcSiteSccRebate(inputs, rateCalculator),
        fleetSccRebate: calcFleetSccRebate(inputs, rateCalculator),
      };
    });
  }

  // if not separately metered...

  // get scaled facility load profile based on building type, current rate, monthly electric bill
  const scaledFacilityLoadProfile = calcScaledFacilityLoadProfile(inputs);

  // aggregate ev and scaled facility load profiles
  const aggregatedFacilityAndEv = evLoadProfile.aggregate(scaledFacilityLoadProfile);

  const maxDemand = aggregatedFacilityAndEv.max();

  // run aggregate load profile through all rates
  return getRates(inputs, maxDemand).map((rate) => {
    const fleetRateCalculator = new RateCalculator({...rate, loadProfile: evLoadProfile});
    const annualTotalCost = new RateCalculator({
      ...rate,
      loadProfile: aggregatedFacilityAndEv,
    }).annualCost();

    const annualFacilityCost = new RateCalculator({
      ...rate,
      loadProfile: scaledFacilityLoadProfile,
    }).annualCost();

    const annualEvCost = annualTotalCost - annualFacilityCost;

    return {
      title: rate.title,
      name: rate.name,
      facility: annualFacilityCost,
      ev: annualEvCost,
      total: annualTotalCost,
      siteSccRebate: calcSiteSccRebate(inputs, fleetRateCalculator),
      fleetSccRebate: calcFleetSccRebate(inputs, fleetRateCalculator),
    };
  });
};

export default calcAnnualVehicleElectricityCostsAllRates;
