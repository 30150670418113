import React, {useState} from 'react';
import {Box, AppBar, Tab, Tabs as MuiTabs} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const TabPanel = ({children, value, index, ...other}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

const StyledAppBar = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '1px solid #ddd',
  },
}))(AppBar);

const StyledTabs = withStyles({
  root: {
    backgroundColor: 'transparent',
    minHeight: 'auto',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  flexContainer: {
    justifyContent: 'space-between',
  },
})(MuiTabs);

const StyledTab = withStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'none',
    color: theme.palette.common.black,
    padding: '6px 12px',
    minHeight: 'auto',
    fontWeight: 'bold',
    '&:focus': {
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}))(Tab);

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

export default function Tabs({tabs}) {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <StyledAppBar position="static">
        <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
          {tabs.map(({label}, idx) => (
            <StyledTab key={label} label={label} {...a11yProps(idx)} />
          ))}
        </StyledTabs>
      </StyledAppBar>
      {tabs.map(({ContentComponent, label}, idx) => (
        <TabPanel key={label} value={value} index={idx}>
          <ContentComponent />
        </TabPanel>
      ))}
    </>
  );
}
