const currentRates = [
  {
    id: 'sc9Rate1',
    translationKey: 'charging.inputs.advanced.currentRate.sc9_1',
  },
  {
    id: 'sc9Rate2',
    translationKey: 'charging.inputs.advanced.currentRate.sc9_2',
  },
  {
    id: 'sc9Rate3',
    translationKey: 'charging.inputs.advanced.currentRate.sc9_3',
  },
  {
    id: 'sc9Rate4',
    translationKey: 'charging.inputs.advanced.currentRate.sc9_4',
  },
  {
    id: 'sc9Rate5',
    translationKey: 'charging.inputs.advanced.currentRate.sc9_5',
  },
];

export default currentRates;
