const outpatientLoadProfile = [
  3.89, 3.73, 3.84, 4.24, 4.88, 5.69, 8.09, 8.7, 9.43, 9.91, 10.41, 10.7, 10.74, 10.5, 9.82, 9.15,
  8.55, 8.02, 5.88, 5.36, 4.98, 4.48, 4.2, 3.88, 3.74, 3.57, 3.68, 4.09, 4.72, 5.53, 7.97, 8.58,
  9.34, 9.84, 10.34, 10.63, 10.68, 10.43, 9.8, 9.13, 8.53, 8.01, 5.87, 5.35, 4.97, 4.47, 4.19, 3.86,
  3.78, 3.61, 3.67, 3.99, 4.57, 5.42, 7.84, 8.46, 9.18, 9.65, 10.14, 10.42, 10.51, 10.27, 9.7, 9.06,
  8.51, 8.0, 5.88, 5.39, 5.04, 4.57, 4.3, 3.99, 3.86, 3.69, 3.84, 4.23, 4.86, 5.66, 8.08, 8.7, 9.43,
  9.85, 10.34, 10.64, 10.72, 10.51, 9.89, 9.21, 8.62, 8.02, 5.86, 5.33, 4.95, 4.42, 4.13, 3.79,
  3.44, 3.43, 3.45, 3.48, 3.64, 3.88, 5.85, 5.75, 5.83, 6.21, 6.82, 7.4, 7.79, 7.89, 7.84, 7.49,
  7.06, 6.89, 4.77, 4.35, 4.06, 3.82, 3.62, 3.44, 3.19, 3.2, 3.22, 3.22, 3.29, 3.36, 3.58, 3.22,
  3.1, 3.16, 3.37, 3.48, 3.63, 3.64, 3.64, 3.6, 3.66, 3.88, 3.8, 3.66, 3.47, 3.39, 3.35, 3.27, 3.67,
  3.47, 3.56, 3.93, 4.56, 5.38, 7.84, 8.46, 9.26, 9.78, 10.31, 10.62, 10.69, 10.46, 9.83, 9.16,
  8.54, 8.0, 5.88, 5.37, 5.0, 4.55, 4.32, 4.02, 3.89, 3.73, 3.84, 4.24, 4.88, 5.69, 8.09, 8.7, 9.43,
  9.91, 10.41, 10.7, 10.74, 10.5, 9.82, 9.15, 8.55, 8.02, 5.88, 5.36, 4.98, 4.48, 4.2, 3.88, 3.74,
  3.57, 3.68, 4.09, 4.72, 5.53, 7.97, 8.58, 9.34, 9.84, 10.34, 10.63, 10.68, 10.43, 9.8, 9.13, 8.53,
  8.01, 5.87, 5.35, 4.97, 4.47, 4.19, 3.86, 3.78, 3.61, 3.67, 3.99, 4.57, 5.42, 7.84, 8.46, 9.18,
  9.65, 10.14, 10.42, 10.51, 10.27, 9.7, 9.06, 8.51, 8.0, 5.88, 5.39, 5.04, 4.57, 4.3, 3.99, 3.86,
  3.69, 3.84, 4.23, 4.86, 5.66, 8.08, 8.7, 9.43, 9.85, 10.34, 10.64, 10.72, 10.51, 9.89, 9.21, 8.62,
  8.02, 5.86, 5.33, 4.95, 4.42, 4.13, 3.79, 3.44, 3.43, 3.45, 3.48, 3.64, 3.88, 5.85, 5.75, 5.83,
  6.21, 6.82, 7.4, 7.79, 7.89, 7.84, 7.49, 7.06, 6.89, 4.77, 4.35, 4.06, 3.82, 3.62, 3.44, 3.19,
  3.2, 3.22, 3.22, 3.29, 3.36, 3.58, 3.22, 3.1, 3.16, 3.37, 3.48, 3.63, 3.64, 3.64, 3.6, 3.66, 3.88,
  3.8, 3.66, 3.47, 3.39, 3.35, 3.27, 3.67, 3.47, 3.56, 3.93, 4.56, 5.38, 7.84, 8.46, 9.26, 9.78,
  10.31, 10.62, 10.69, 10.46, 9.83, 9.16, 8.54, 8.0, 5.88, 5.37, 5.0, 4.55, 4.32, 4.02, 3.89, 3.73,
  3.84, 4.24, 4.88, 5.69, 8.09, 8.7, 9.43, 9.91, 10.41, 10.7, 10.74, 10.5, 9.82, 9.15, 8.55, 8.02,
  5.88, 5.36, 4.98, 4.48, 4.2, 3.88, 3.74, 3.57, 3.68, 4.09, 4.72, 5.53, 7.97, 8.58, 9.34, 9.84,
  10.34, 10.63, 10.68, 10.43, 9.8, 9.13, 8.53, 8.01, 5.87, 5.35, 4.97, 4.47, 4.19, 3.86, 3.78, 3.61,
  3.67, 3.99, 4.57, 5.42, 7.84, 8.46, 9.18, 9.65, 10.14, 10.42, 10.51, 10.27, 9.7, 9.06, 8.51, 8.0,
  5.88, 5.39, 5.04, 4.57, 4.3, 3.99, 3.86, 3.69, 3.84, 4.23, 4.86, 5.66, 8.08, 8.7, 9.43, 9.85,
  10.34, 10.64, 10.72, 10.51, 9.89, 9.21, 8.62, 8.02, 5.86, 5.33, 4.95, 4.42, 4.13, 3.79, 3.44,
  3.43, 3.45, 3.48, 3.64, 3.88, 5.85, 5.75, 5.83, 6.21, 6.82, 7.4, 7.79, 7.89, 7.84, 7.49, 7.06,
  6.89, 4.77, 4.35, 4.06, 3.82, 3.62, 3.44, 3.19, 3.2, 3.22, 3.22, 3.29, 3.36, 3.58, 3.22, 3.1,
  3.16, 3.37, 3.48, 3.63, 3.64, 3.64, 3.6, 3.66, 3.88, 3.8, 3.66, 3.47, 3.39, 3.35, 3.27, 3.67,
  3.47, 3.56, 3.93, 4.56, 5.38, 7.84, 8.46, 9.26, 9.78, 10.31, 10.62, 10.69, 10.46, 9.83, 9.16,
  8.54, 8.0, 5.88, 5.37, 5.0, 4.55, 4.32, 4.02, 3.89, 3.73, 3.84, 4.24, 4.88, 5.69, 8.09, 8.7, 9.43,
  9.91, 10.41, 10.7, 10.74, 10.5, 9.82, 9.15, 8.55, 8.02, 5.88, 5.36, 4.98, 4.48, 4.2, 3.88, 3.74,
  3.57, 3.68, 4.09, 4.72, 5.53, 7.97, 8.58, 9.34, 9.84, 10.34, 10.63, 10.68, 10.43, 9.8, 9.13, 8.53,
  8.01, 5.87, 5.35, 4.97, 4.47, 4.19, 3.86, 3.78, 3.61, 3.67, 3.99, 4.57, 5.42, 7.84, 8.46, 9.18,
  9.65, 10.14, 10.42, 10.51, 10.27, 9.7, 9.06, 8.51, 8.0, 5.88, 5.39, 5.04, 4.57, 4.3, 3.99, 3.86,
  3.69, 3.84, 4.23, 4.86, 5.66, 8.08, 8.7, 9.43, 9.85, 10.34, 10.64, 10.72, 10.51, 9.89, 9.21, 8.62,
  8.02, 5.86, 5.33, 4.95, 4.42, 4.13, 3.79, 3.44, 3.43, 3.45, 3.48, 3.64, 3.88, 5.85, 5.75, 5.83,
  6.21, 6.82, 7.4, 7.79, 7.89, 7.84, 7.49, 7.06, 6.89, 4.77, 4.35, 4.06, 3.82, 3.62, 3.44, 3.19,
  3.2, 3.22, 3.22, 3.29, 3.36, 3.58, 3.22, 3.1, 3.16, 3.37, 3.48, 3.63, 3.64, 3.64, 3.6, 3.66, 3.88,
  3.8, 3.66, 3.47, 3.39, 3.35, 3.27, 3.67, 3.47, 3.56, 3.93, 4.56, 5.38, 7.84, 8.46, 9.26, 9.78,
  10.31, 10.62, 10.69, 10.46, 9.83, 9.16, 8.54, 8.0, 5.88, 5.37, 5.0, 4.55, 4.32, 4.02, 3.89, 3.73,
  3.84, 4.24, 4.88, 5.69, 8.09, 8.7, 9.43, 9.91, 10.41, 10.7, 10.74, 10.5, 9.82, 9.15, 8.55, 8.02,
  5.88, 5.36, 4.98, 4.48, 4.2, 3.88, 3.74, 3.57, 3.68, 4.09, 4.72, 5.53, 7.97, 8.58, 9.34, 9.84,
  10.34, 10.63, 10.68, 10.43, 9.8, 9.13, 8.53, 8.01, 5.87, 5.35, 4.97, 4.47, 4.19, 3.86, 3.78, 3.61,
  3.67, 3.99, 4.57, 5.42, 7.84, 8.46, 9.18, 9.65, 10.14, 10.42, 10.51, 10.27, 9.7, 9.06, 8.51, 8.0,
  5.88, 5.39, 5.04, 4.57, 4.3, 3.99, 3.49, 3.29, 3.37, 3.74, 4.38, 5.26, 7.82, 8.49, 9.44, 10.03,
  10.62, 11.07, 11.25, 10.99, 10.2, 9.38, 8.51, 7.87, 5.77, 5.22, 4.8, 4.26, 3.97, 3.63, 3.27, 3.27,
  3.28, 3.31, 3.49, 3.74, 5.65, 5.52, 5.73, 6.12, 6.73, 7.33, 7.75, 7.86, 7.82, 7.47, 6.87, 6.71,
  4.73, 4.3, 4.0, 3.76, 3.57, 3.41, 3.16, 3.18, 3.18, 3.2, 3.27, 3.34, 3.48, 3.09, 3.07, 3.13, 3.34,
  3.46, 3.62, 3.64, 3.64, 3.59, 3.53, 3.71, 3.77, 3.64, 3.45, 3.38, 3.36, 3.28, 3.73, 3.54, 3.66,
  4.05, 4.68, 5.5, 7.8, 8.41, 9.28, 9.77, 10.29, 10.62, 10.7, 10.46, 9.85, 9.19, 8.39, 7.83, 5.79,
  5.26, 4.87, 4.36, 4.1, 3.79, 3.65, 3.47, 3.59, 3.98, 4.64, 5.46, 7.82, 8.46, 9.34, 9.86, 10.37,
  10.69, 10.74, 10.5, 9.87, 9.19, 8.42, 7.87, 5.89, 5.38, 4.98, 4.47, 4.2, 3.87, 3.72, 3.55, 3.66,
  4.07, 4.71, 5.51, 7.84, 8.45, 9.3, 9.78, 10.3, 10.61, 10.69, 10.45, 9.83, 9.17, 8.39, 7.8, 5.81,
  5.28, 4.9, 4.4, 4.11, 3.79, 3.63, 3.44, 3.5, 3.81, 4.36, 5.2, 7.64, 8.22, 9.12, 9.64, 10.21,
  10.55, 10.69, 10.47, 9.9, 9.23, 8.43, 7.83, 5.75, 5.21, 4.79, 4.29, 3.99, 3.63, 3.49, 3.29, 3.37,
  3.74, 4.38, 5.26, 7.82, 8.49, 9.44, 10.03, 10.62, 11.07, 11.25, 10.99, 10.2, 9.38, 8.51, 7.87,
  5.77, 5.22, 4.8, 4.26, 3.97, 3.63, 3.27, 3.27, 3.28, 3.31, 3.49, 3.74, 5.65, 5.52, 5.73, 6.12,
  6.73, 7.33, 7.75, 7.86, 7.82, 7.47, 6.87, 6.71, 4.73, 4.3, 4.0, 3.76, 3.57, 3.41, 3.16, 3.18,
  3.18, 3.2, 3.27, 3.34, 3.48, 3.09, 3.07, 3.13, 3.34, 3.46, 3.62, 3.64, 3.64, 3.59, 3.53, 3.71,
  3.77, 3.64, 3.45, 3.38, 3.36, 3.28, 3.73, 3.54, 3.66, 4.05, 4.68, 5.5, 7.8, 8.41, 9.28, 9.77,
  10.29, 10.62, 10.7, 10.46, 9.85, 9.19, 8.39, 7.83, 5.79, 5.26, 4.87, 4.36, 4.1, 3.79, 3.65, 3.47,
  3.59, 3.98, 4.64, 5.46, 7.82, 8.46, 9.34, 9.86, 10.37, 10.69, 10.74, 10.5, 9.87, 9.19, 8.42, 7.87,
  5.89, 5.38, 4.98, 4.47, 4.2, 3.87, 3.72, 3.55, 3.66, 4.07, 4.71, 5.51, 7.84, 8.45, 9.3, 9.78,
  10.3, 10.61, 10.69, 10.45, 9.83, 9.17, 8.39, 7.8, 5.81, 5.28, 4.9, 4.4, 4.11, 3.79, 3.63, 3.44,
  3.5, 3.81, 4.36, 5.2, 7.64, 8.22, 9.12, 9.64, 10.21, 10.55, 10.69, 10.47, 9.9, 9.23, 8.43, 7.83,
  5.75, 5.21, 4.79, 4.29, 3.99, 3.63, 3.49, 3.29, 3.37, 3.74, 4.38, 5.26, 7.82, 8.49, 9.44, 10.03,
  10.62, 11.07, 11.25, 10.99, 10.2, 9.38, 8.51, 7.87, 5.77, 5.22, 4.8, 4.26, 3.97, 3.63, 3.27, 3.27,
  3.28, 3.31, 3.49, 3.74, 5.65, 5.52, 5.73, 6.12, 6.73, 7.33, 7.75, 7.86, 7.82, 7.47, 6.87, 6.71,
  4.73, 4.3, 4.0, 3.76, 3.57, 3.41, 3.16, 3.18, 3.18, 3.2, 3.27, 3.34, 3.48, 3.09, 3.07, 3.13, 3.34,
  3.46, 3.62, 3.64, 3.64, 3.59, 3.53, 3.71, 3.77, 3.64, 3.45, 3.38, 3.36, 3.28, 3.73, 3.54, 3.66,
  4.05, 4.68, 5.5, 7.8, 8.41, 9.28, 9.77, 10.29, 10.62, 10.7, 10.46, 9.85, 9.19, 8.39, 7.83, 5.79,
  5.26, 4.87, 4.36, 4.1, 3.79, 3.65, 3.47, 3.59, 3.98, 4.64, 5.46, 7.82, 8.46, 9.34, 9.86, 10.37,
  10.69, 10.74, 10.5, 9.87, 9.19, 8.42, 7.87, 5.89, 5.38, 4.98, 4.47, 4.2, 3.87, 3.72, 3.55, 3.66,
  4.07, 4.71, 5.51, 7.84, 8.45, 9.3, 9.78, 10.3, 10.61, 10.69, 10.45, 9.83, 9.17, 8.39, 7.8, 5.81,
  5.28, 4.9, 4.4, 4.11, 3.79, 3.63, 3.44, 3.5, 3.81, 4.36, 5.2, 7.64, 8.22, 9.12, 9.64, 10.21,
  10.55, 10.69, 10.47, 9.9, 9.23, 8.43, 7.83, 5.75, 5.21, 4.79, 4.29, 3.99, 3.63, 3.49, 3.29, 3.37,
  3.74, 4.38, 5.26, 7.82, 8.49, 9.44, 10.03, 10.62, 11.07, 11.25, 10.99, 10.2, 9.38, 8.51, 7.87,
  5.77, 5.22, 4.8, 4.26, 3.97, 3.63, 3.27, 3.27, 3.28, 3.31, 3.49, 3.74, 5.65, 5.52, 5.73, 6.12,
  6.73, 7.33, 7.75, 7.86, 7.82, 7.47, 6.87, 6.71, 4.73, 4.3, 4.0, 3.76, 3.57, 3.41, 3.16, 3.18,
  3.18, 3.2, 3.27, 3.34, 3.48, 3.09, 3.07, 3.13, 3.34, 3.46, 3.62, 3.64, 3.64, 3.59, 3.53, 3.71,
  3.77, 3.64, 3.45, 3.38, 3.36, 3.28, 3.73, 3.54, 3.66, 4.05, 4.68, 5.5, 7.8, 8.41, 9.28, 9.77,
  10.29, 10.62, 10.7, 10.46, 9.85, 9.19, 8.39, 7.83, 5.79, 5.26, 4.87, 4.36, 4.1, 3.79, 3.65, 3.47,
  3.59, 3.98, 4.64, 5.46, 7.82, 8.46, 9.34, 9.86, 10.37, 10.69, 10.74, 10.5, 9.87, 9.19, 8.42, 7.87,
  5.89, 5.38, 4.98, 4.47, 4.2, 3.87, 3.72, 3.55, 3.66, 4.07, 4.71, 5.51, 7.84, 8.45, 9.3, 9.78,
  10.3, 10.61, 10.69, 10.45, 9.83, 9.17, 8.39, 7.8, 5.81, 5.28, 4.9, 4.4, 4.11, 3.79, 3.63, 3.44,
  3.5, 3.81, 4.36, 5.2, 7.64, 8.22, 9.12, 9.64, 10.21, 10.55, 10.69, 10.47, 9.9, 9.23, 8.43, 7.83,
  5.75, 5.21, 4.79, 4.29, 3.99, 3.63, 3.37, 3.34, 3.57, 4.1, 4.89, 6.85, 8.15, 9.13, 9.99, 10.75,
  11.46, 11.78, 11.65, 11.03, 10.28, 9.42, 8.31, 6.54, 5.6, 5.09, 4.53, 4.1, 3.76, 3.52, 3.23, 3.21,
  3.23, 3.34, 3.54, 4.83, 5.46, 5.65, 6.0, 6.55, 7.19, 7.72, 7.98, 8.03, 7.78, 7.26, 6.65, 5.2,
  4.45, 4.08, 3.8, 3.57, 3.36, 3.25, 3.03, 3.05, 3.07, 3.13, 3.22, 3.4, 3.08, 3.02, 3.07, 3.27,
  3.41, 3.58, 3.65, 3.68, 3.67, 3.61, 3.4, 3.36, 3.6, 3.39, 3.25, 3.19, 3.11, 3.05, 3.36, 3.34,
  3.63, 4.2, 5.01, 7.17, 8.18, 9.09, 9.77, 10.37, 10.83, 11.04, 10.88, 10.35, 9.65, 8.86, 7.86,
  5.95, 5.39, 4.92, 4.37, 4.01, 3.68, 3.49, 3.32, 3.35, 3.65, 4.23, 5.03, 7.17, 8.19, 9.11, 9.81,
  10.5, 11.17, 11.5, 11.35, 10.75, 10.02, 9.25, 8.2, 6.2, 5.55, 5.07, 4.5, 4.14, 3.8, 3.59, 3.42,
  3.48, 3.77, 4.34, 5.13, 7.26, 8.23, 9.11, 9.76, 10.35, 10.82, 11.03, 10.86, 10.35, 9.69, 8.92,
  7.91, 5.99, 5.41, 4.93, 4.38, 4.01, 3.67, 3.47, 3.33, 3.3, 3.51, 4.01, 4.76, 6.63, 7.84, 8.74,
  9.44, 10.02, 10.48, 10.75, 10.73, 10.35, 9.76, 8.95, 7.97, 6.3, 5.46, 4.98, 4.49, 4.12, 3.78,
  3.55, 3.37, 3.34, 3.57, 4.1, 4.89, 6.85, 8.15, 9.13, 9.99, 10.75, 11.46, 11.78, 11.65, 11.03,
  10.28, 9.42, 8.31, 6.54, 5.6, 5.09, 4.53, 4.1, 3.76, 3.52, 3.23, 3.21, 3.23, 3.34, 3.54, 4.83,
  5.46, 5.65, 6.0, 6.55, 7.19, 7.72, 7.98, 8.03, 7.78, 7.26, 6.65, 5.2, 4.45, 4.08, 3.8, 3.57, 3.36,
  3.25, 3.03, 3.05, 3.13, 3.22, 3.4, 3.08, 3.02, 3.07, 3.27, 3.41, 3.58, 3.65, 3.68, 3.67, 3.61,
  3.4, 3.36, 3.6, 3.39, 3.25, 3.19, 3.11, 3.05, 3.36, 3.34, 3.63, 4.2, 5.01, 7.17, 8.18, 9.09, 9.77,
  10.37, 10.83, 11.04, 10.88, 10.35, 9.65, 8.86, 7.86, 5.95, 5.39, 4.92, 4.37, 4.01, 3.68, 3.49,
  3.32, 3.35, 3.65, 4.23, 5.03, 7.17, 8.19, 9.11, 9.81, 10.5, 11.17, 11.5, 11.35, 10.75, 10.02,
  9.25, 8.2, 6.2, 5.55, 5.07, 4.5, 4.14, 3.8, 3.59, 3.42, 3.48, 3.77, 4.34, 5.13, 7.26, 8.23, 9.11,
  9.76, 10.35, 10.82, 11.03, 10.86, 10.35, 9.69, 8.92, 7.91, 5.99, 5.41, 4.93, 4.38, 4.01, 3.67,
  3.47, 3.33, 3.3, 3.51, 4.01, 4.76, 6.63, 7.84, 8.74, 9.44, 10.02, 10.48, 10.75, 10.73, 10.35,
  9.76, 8.95, 7.97, 6.3, 5.46, 4.98, 4.49, 4.12, 3.78, 3.55, 3.37, 3.34, 3.57, 4.1, 4.89, 6.85,
  8.15, 9.13, 9.99, 10.75, 11.46, 11.78, 11.65, 11.03, 10.28, 9.42, 8.31, 6.54, 5.6, 5.09, 4.53,
  4.1, 3.76, 3.52, 3.23, 3.21, 3.23, 3.34, 3.54, 4.83, 5.46, 5.65, 6.0, 6.55, 7.19, 7.72, 7.98,
  8.03, 7.78, 7.26, 6.65, 5.2, 4.45, 4.08, 3.8, 3.57, 3.36, 3.25, 3.03, 3.05, 3.07, 3.13, 3.22, 3.4,
  3.08, 3.02, 3.07, 3.27, 3.41, 3.58, 3.65, 3.68, 3.67, 3.61, 3.4, 3.36, 3.6, 3.39, 3.25, 3.19,
  3.11, 3.05, 3.36, 3.34, 3.63, 4.2, 5.01, 7.17, 8.18, 9.09, 9.77, 10.37, 10.83, 11.04, 10.88,
  10.35, 9.65, 8.86, 7.86, 5.95, 5.39, 4.92, 4.37, 4.01, 3.68, 3.49, 3.32, 3.35, 3.65, 4.23, 5.03,
  7.17, 8.19, 9.11, 9.81, 10.5, 11.17, 11.5, 11.35, 10.75, 10.02, 9.25, 8.2, 6.2, 5.55, 5.07, 4.5,
  4.14, 3.8, 3.59, 3.42, 3.48, 3.77, 4.34, 5.13, 7.26, 8.23, 9.11, 9.76, 10.35, 10.82, 11.03, 10.86,
  10.35, 9.69, 8.92, 7.91, 5.99, 5.41, 4.93, 4.38, 4.01, 3.67, 3.47, 3.33, 3.3, 3.51, 4.01, 4.76,
  6.63, 7.84, 8.74, 9.44, 10.02, 10.48, 10.75, 10.73, 10.35, 9.76, 8.95, 7.97, 6.3, 5.46, 4.98,
  4.49, 4.12, 3.78, 3.55, 3.37, 3.34, 3.57, 4.1, 4.89, 6.85, 8.15, 9.13, 9.99, 10.75, 11.46, 11.78,
  11.65, 11.03, 10.28, 9.42, 8.31, 6.54, 5.6, 5.09, 4.53, 4.1, 3.76, 3.52, 3.23, 3.21, 3.23, 3.34,
  3.54, 4.83, 5.46, 5.65, 6.0, 6.55, 7.19, 7.72, 7.98, 8.03, 7.78, 7.26, 6.65, 5.2, 4.45, 4.08, 3.8,
  3.57, 3.36, 3.25, 3.03, 3.05, 3.07, 3.13, 3.22, 3.4, 3.08, 3.02, 3.07, 3.27, 3.41, 3.58, 3.65,
  3.68, 3.67, 3.61, 3.4, 3.36, 3.6, 3.39, 3.25, 3.19, 3.11, 3.05, 3.36, 3.34, 3.63, 4.2, 5.01, 7.17,
  8.18, 9.09, 9.77, 10.37, 10.83, 11.04, 10.88, 10.35, 9.65, 8.86, 7.86, 5.95, 5.39, 4.92, 4.37,
  4.01, 3.68, 3.49, 3.32, 3.35, 3.65, 4.23, 5.03, 7.17, 8.19, 9.11, 9.81, 10.5, 11.17, 11.5, 11.35,
  10.75, 10.02, 9.25, 8.2, 6.2, 5.55, 5.07, 4.5, 4.14, 3.8, 3.59, 3.42, 3.48, 3.77, 4.34, 5.13,
  7.26, 8.23, 9.11, 9.76, 10.35, 10.82, 11.03, 10.86, 10.35, 9.69, 8.92, 7.91, 5.99, 5.41, 4.93,
  4.38, 4.01, 3.67, 3.47, 3.33, 3.3, 3.51, 4.01, 4.76, 6.63, 7.84, 8.74, 9.44, 10.02, 10.48, 10.75,
  10.73, 10.35, 9.76, 8.95, 7.97, 6.3, 5.46, 4.98, 4.49, 4.12, 3.78, 3.55, 3.37, 3.34, 3.57, 4.1,
  4.89, 6.85, 8.15, 9.13, 9.99, 10.75, 11.46, 11.78, 11.65, 11.03, 10.28, 9.42, 8.31, 6.54, 5.6,
  5.09, 4.53, 4.1, 3.76, 3.52, 3.23, 3.21, 3.23, 3.34, 3.54, 4.83, 5.46, 5.65, 6.0, 6.55, 7.19,
  7.72, 7.98, 8.03, 7.78, 7.26, 6.65, 5.2, 4.45, 4.08, 3.8, 3.57, 3.36, 3.25, 3.03, 3.05, 3.07,
  3.13, 3.22, 3.4, 3.08, 3.02, 3.07, 3.27, 3.41, 3.58, 3.65, 3.68, 3.67, 3.61, 3.4, 3.36, 3.6, 3.39,
  3.25, 3.19, 3.11, 3.05, 3.29, 3.33, 3.66, 4.3, 5.21, 7.54, 8.57, 9.64, 10.36, 11.11, 11.67, 11.9,
  11.66, 10.98, 10.3, 9.47, 8.29, 5.87, 5.4, 5.07, 4.38, 3.99, 3.61, 3.44, 3.23, 3.28, 3.61, 4.25,
  5.14, 7.54, 8.53, 9.58, 10.32, 11.06, 11.69, 11.96, 11.67, 10.85, 9.95, 9.01, 7.86, 5.53, 5.2,
  4.93, 4.29, 3.95, 3.59, 3.43, 3.25, 3.32, 3.66, 4.31, 5.2, 7.56, 8.55, 9.51, 10.11, 10.73, 11.14,
  11.28, 11.06, 10.45, 9.77, 8.96, 7.86, 5.54, 5.17, 4.87, 4.25, 3.92, 3.56, 3.4, 3.19, 3.25, 3.56,
  4.15, 5.02, 7.28, 8.24, 9.21, 9.82, 10.48, 10.91, 11.16, 10.99, 10.42, 9.74, 8.93, 7.86, 5.53,
  5.18, 4.89, 4.32, 3.98, 3.61, 3.45, 3.26, 3.32, 3.65, 4.29, 5.19, 7.52, 8.55, 9.64, 10.41, 11.29,
  12.06, 12.49, 12.36, 11.62, 10.86, 9.83, 8.46, 5.99, 5.54, 5.21, 4.49, 4.12, 3.73, 3.55, 3.29,
  3.29, 3.31, 3.47, 3.7, 5.45, 5.72, 6.04, 6.6, 7.51, 8.29, 8.79, 8.96, 8.94, 8.5, 7.81, 7.08, 4.71,
  4.41, 4.17, 3.86, 3.6, 3.4, 3.33, 3.07, 3.05, 3.03, 3.09, 3.17, 3.09, 2.94, 2.99, 3.08, 3.34,
  3.48, 3.66, 3.7, 3.72, 3.71, 3.66, 3.44, 3.29, 3.4, 3.3, 3.19, 3.13, 3.04, 3.01, 3.29, 3.33, 3.66,
  4.3, 5.21, 7.54, 8.57, 9.64, 10.36, 11.11, 11.67, 11.9, 11.66, 10.98, 10.3, 9.47, 8.29, 5.87, 5.4,
  5.07, 4.38, 3.99, 3.61, 3.44, 3.23, 3.28, 3.61, 4.25, 5.14, 7.54, 8.53, 9.58, 10.32, 11.06, 11.69,
  11.96, 11.67, 10.85, 9.95, 9.01, 7.86, 5.53, 5.2, 4.93, 4.29, 3.95, 3.59, 3.43, 3.25, 3.32, 3.66,
  4.31, 5.2, 7.56, 8.55, 9.51, 10.11, 10.73, 11.14, 11.28, 11.06, 10.45, 9.77, 8.96, 7.86, 5.54,
  5.17, 4.87, 4.25, 3.92, 3.56, 3.4, 3.19, 3.25, 3.56, 4.15, 5.02, 7.28, 8.24, 9.21, 9.82, 10.48,
  10.91, 11.16, 10.99, 10.42, 9.74, 8.93, 7.86, 5.53, 5.18, 4.89, 4.32, 3.98, 3.61, 3.45, 3.26,
  3.32, 3.65, 4.29, 5.19, 7.52, 8.55, 9.64, 10.41, 11.29, 12.06, 12.49, 12.36, 11.62, 10.86, 9.83,
  8.46, 5.99, 5.54, 5.21, 4.49, 4.12, 3.73, 3.55, 3.29, 3.29, 3.31, 3.47, 3.7, 5.45, 5.72, 6.04,
  6.6, 7.51, 8.29, 8.79, 8.96, 8.94, 8.5, 7.81, 7.08, 4.71, 4.41, 4.17, 3.86, 3.6, 3.4, 3.33, 3.07,
  3.05, 3.03, 3.09, 3.17, 3.09, 2.94, 2.99, 3.08, 3.34, 3.48, 3.66, 3.7, 3.72, 3.71, 3.66, 3.44,
  3.29, 3.4, 3.3, 3.19, 3.13, 3.04, 3.01, 3.29, 3.33, 3.66, 4.3, 5.21, 7.54, 8.57, 9.64, 10.36,
  11.11, 11.67, 11.9, 11.66, 10.98, 10.3, 9.47, 8.29, 5.87, 5.4, 5.07, 4.38, 3.99, 3.61, 3.44, 3.23,
  3.28, 3.61, 4.25, 5.14, 7.54, 8.53, 9.58, 10.32, 11.06, 11.69, 11.96, 11.67, 10.85, 9.95, 9.01,
  7.86, 5.53, 5.2, 4.93, 4.29, 3.95, 3.59, 3.43, 3.25, 3.32, 3.66, 4.31, 5.2, 7.56, 8.55, 9.51,
  10.11, 10.73, 11.14, 11.28, 11.06, 10.45, 9.77, 8.96, 7.86, 5.54, 5.17, 4.87, 4.25, 3.92, 3.56,
  3.4, 3.19, 3.25, 3.56, 4.15, 5.02, 7.28, 8.24, 9.21, 9.82, 10.48, 10.91, 11.16, 10.99, 10.42,
  9.74, 8.93, 7.86, 5.53, 5.18, 4.89, 4.32, 3.98, 3.61, 3.45, 3.26, 3.32, 3.65, 4.29, 5.19, 7.52,
  8.55, 9.64, 10.41, 11.29, 12.06, 12.49, 12.36, 11.62, 10.86, 9.83, 8.46, 5.99, 5.54, 5.21, 4.49,
  4.12, 3.73, 3.55, 3.29, 3.29, 3.31, 3.47, 3.7, 5.45, 5.72, 6.04, 6.6, 7.51, 8.29, 8.79, 8.96,
  8.94, 8.5, 7.81, 7.08, 4.71, 4.41, 4.17, 3.86, 3.6, 3.4, 3.33, 3.07, 3.05, 3.03, 3.09, 3.17, 3.09,
  2.94, 2.99, 3.08, 3.34, 3.48, 3.66, 3.7, 3.72, 3.71, 3.66, 3.44, 3.29, 3.4, 3.3, 3.19, 3.13, 3.04,
  3.01, 3.29, 3.33, 3.66, 4.3, 5.21, 7.54, 8.57, 9.64, 10.36, 11.11, 11.67, 11.9, 11.66, 10.98,
  10.3, 9.47, 8.29, 5.87, 5.4, 5.07, 4.38, 3.99, 3.61, 3.44, 3.23, 3.28, 3.61, 4.25, 5.14, 7.54,
  8.53, 9.58, 10.32, 11.06, 11.69, 11.96, 11.67, 10.85, 9.95, 9.01, 7.86, 5.53, 5.2, 4.93, 4.29,
  3.95, 3.59, 3.43, 3.25, 3.32, 3.66, 4.31, 5.2, 7.56, 8.55, 9.51, 10.11, 10.73, 11.14, 11.28,
  11.06, 10.45, 9.77, 8.96, 7.86, 5.54, 5.17, 4.87, 4.25, 3.92, 3.56, 3.4, 3.19, 3.25, 3.56, 4.15,
  5.02, 7.28, 8.24, 9.21, 9.82, 10.48, 10.91, 11.16, 10.99, 10.42, 9.74, 8.93, 7.86, 5.53, 5.18,
  4.89, 4.32, 3.98, 3.61, 3.45, 3.26, 3.32, 3.65, 4.29, 5.19, 7.52, 8.55, 9.64, 10.41, 11.29, 12.06,
  12.49, 12.36, 11.62, 10.86, 9.83, 8.46, 5.99, 5.54, 5.21, 4.49, 4.12, 3.73, 3.55, 3.29, 3.29,
  3.31, 3.47, 3.7, 5.45, 5.72, 6.04, 6.6, 7.51, 8.29, 8.79, 8.96, 8.94, 8.5, 7.81, 7.08, 4.71, 4.41,
  4.17, 3.86, 3.6, 3.4, 3.33, 3.07, 3.05, 3.03, 3.09, 3.17, 3.09, 2.94, 2.99, 3.08, 3.34, 3.48,
  3.66, 3.7, 3.72, 3.71, 3.66, 3.44, 3.29, 3.4, 3.3, 3.19, 3.13, 3.04, 3.01, 3.29, 3.33, 3.66, 4.3,
  5.21, 7.54, 8.57, 9.64, 10.36, 11.11, 11.67, 11.9, 11.66, 10.98, 10.3, 9.47, 8.29, 5.87, 5.4,
  5.07, 4.38, 3.99, 3.61, 3.44, 3.23, 3.28, 3.61, 4.25, 5.14, 7.54, 8.53, 9.58, 10.32, 11.06, 11.69,
  11.96, 11.67, 10.85, 9.95, 9.01, 7.86, 5.53, 5.2, 4.93, 4.29, 3.95, 3.59, 3.43, 3.45, 3.5, 3.83,
  4.55, 5.45, 8.24, 9.76, 11.15, 12.18, 13.21, 14.14, 14.41, 14.08, 13.15, 12.32, 11.4, 9.85, 7.17,
  6.06, 5.74, 4.83, 4.46, 4.08, 3.86, 3.58, 3.6, 3.91, 4.54, 5.27, 7.85, 9.05, 10.3, 11.2, 12.22,
  13.11, 13.67, 13.69, 13.19, 12.37, 11.3, 9.87, 7.23, 6.31, 5.98, 5.18, 4.68, 4.15, 3.86, 3.5,
  3.53, 3.84, 4.53, 5.35, 8.02, 9.45, 10.74, 11.64, 12.41, 13.1, 13.19, 12.77, 11.91, 11.14, 10.03,
  8.51, 6.02, 5.28, 5.18, 4.44, 4.07, 3.68, 3.51, 3.25, 3.24, 3.26, 3.44, 3.48, 5.38, 5.73, 6.04,
  6.6, 7.42, 8.2, 8.72, 8.9, 8.85, 8.34, 7.54, 6.76, 4.48, 4.04, 4.1, 3.81, 3.57, 3.36, 3.3, 3.06,
  3.05, 3.04, 3.1, 2.99, 2.96, 2.96, 3.02, 3.14, 3.41, 3.54, 3.73, 3.78, 3.79, 3.78, 3.73, 3.47,
  3.34, 3.18, 3.35, 3.23, 3.15, 3.06, 3.02, 3.29, 3.33, 3.67, 4.31, 5.07, 7.56, 8.85, 10.09, 11.05,
  12.07, 13.09, 13.53, 13.34, 12.61, 11.98, 10.96, 9.37, 6.75, 5.84, 5.63, 4.77, 4.37, 3.94, 3.73,
  3.42, 3.48, 3.82, 4.52, 5.4, 8.1, 9.53, 10.87, 11.83, 12.85, 13.78, 14.17, 13.9, 13.07, 12.28,
  11.22, 9.54, 6.86, 5.9, 5.64, 4.74, 4.33, 3.94, 3.7, 3.45, 3.5, 3.83, 4.55, 5.45, 8.24, 9.76,
  11.15, 12.18, 13.21, 14.14, 14.41, 14.08, 13.15, 12.32, 11.4, 9.85, 7.17, 6.06, 5.74, 4.83, 4.46,
  4.08, 3.86, 3.58, 3.6, 3.91, 4.54, 5.27, 7.85, 9.05, 10.3, 11.2, 12.22, 13.11, 13.67, 13.69,
  13.19, 12.37, 11.3, 9.87, 7.23, 6.31, 5.98, 5.18, 4.68, 4.15, 3.86, 3.5, 3.53, 3.84, 4.53, 5.35,
  8.02, 9.45, 10.74, 11.64, 12.41, 13.1, 13.19, 12.77, 11.91, 11.14, 10.03, 8.51, 6.02, 5.28, 5.18,
  4.44, 4.07, 3.68, 3.51, 3.25, 3.24, 3.26, 3.44, 3.48, 5.38, 5.73, 6.04, 6.6, 7.42, 8.2, 8.72, 8.9,
  8.85, 8.34, 7.54, 6.76, 4.48, 4.04, 4.1, 3.81, 3.57, 3.36, 3.3, 3.06, 3.05, 3.04, 3.1, 2.99, 2.96,
  2.96, 3.02, 3.14, 3.41, 3.54, 3.73, 3.78, 3.79, 3.78, 3.73, 3.47, 3.34, 3.18, 3.35, 3.23, 3.15,
  3.06, 3.02, 3.29, 3.33, 3.67, 4.31, 5.07, 7.56, 8.85, 10.09, 11.05, 12.07, 13.09, 13.53, 13.34,
  12.61, 11.98, 10.96, 9.37, 6.75, 5.84, 5.63, 4.77, 4.37, 3.94, 3.73, 3.42, 3.48, 3.82, 4.52, 5.4,
  8.1, 9.53, 10.87, 11.83, 12.85, 13.78, 14.17, 13.9, 13.07, 12.28, 11.22, 9.54, 6.86, 5.9, 5.64,
  4.74, 4.33, 3.94, 3.7, 3.45, 3.5, 3.83, 4.55, 5.45, 8.24, 9.76, 11.15, 12.18, 13.21, 14.14, 14.41,
  14.08, 13.15, 12.32, 11.4, 9.85, 7.17, 6.06, 5.74, 4.83, 4.46, 4.08, 3.86, 3.58, 3.6, 3.91, 4.54,
  5.27, 7.85, 9.05, 10.3, 11.2, 12.22, 13.11, 13.67, 13.69, 13.19, 12.37, 11.3, 9.87, 7.23, 6.31,
  5.98, 5.18, 4.68, 4.15, 3.86, 3.5, 3.53, 3.84, 4.53, 5.35, 8.02, 9.45, 10.74, 11.64, 12.41, 13.1,
  13.19, 12.77, 11.91, 11.14, 10.03, 8.51, 6.02, 5.28, 5.18, 4.44, 4.07, 3.68, 3.51, 3.25, 3.24,
  3.26, 3.44, 3.48, 5.38, 5.73, 6.04, 6.6, 7.42, 8.2, 8.72, 8.9, 8.85, 8.34, 7.54, 6.76, 4.48, 4.04,
  4.1, 3.81, 3.57, 3.36, 3.3, 3.06, 3.05, 3.04, 3.1, 2.99, 2.96, 2.96, 3.02, 3.14, 3.41, 3.54, 3.73,
  3.78, 3.79, 3.78, 3.73, 3.47, 3.34, 3.18, 3.35, 3.23, 3.15, 3.06, 3.02, 3.29, 3.33, 3.67, 4.31,
  5.07, 7.56, 8.85, 10.09, 11.05, 12.07, 13.09, 13.53, 13.34, 12.61, 11.98, 10.96, 9.37, 6.75, 5.84,
  5.63, 4.77, 4.37, 3.94, 3.73, 3.42, 3.48, 3.82, 4.52, 5.4, 8.1, 9.53, 10.87, 11.83, 12.85, 13.78,
  14.17, 13.9, 13.07, 12.28, 11.22, 9.54, 6.86, 5.9, 5.64, 4.74, 4.33, 3.94, 3.7, 3.45, 3.5, 3.83,
  4.55, 5.45, 8.24, 9.76, 11.15, 12.18, 13.21, 14.14, 14.41, 14.08, 13.15, 12.32, 11.4, 9.85, 7.17,
  6.06, 5.74, 4.83, 4.46, 4.08, 3.86, 3.58, 3.6, 3.91, 4.54, 5.27, 7.85, 9.05, 10.3, 11.2, 12.22,
  13.11, 13.67, 13.69, 13.19, 12.37, 11.3, 9.87, 7.23, 6.31, 5.98, 5.18, 4.68, 4.15, 3.86, 3.5,
  3.53, 3.84, 4.53, 5.35, 8.02, 9.45, 10.74, 11.64, 12.41, 13.1, 13.19, 12.77, 11.91, 11.14, 10.03,
  8.51, 6.02, 5.28, 5.18, 4.44, 4.07, 3.68, 3.51, 3.25, 3.24, 3.26, 3.44, 3.48, 5.38, 5.73, 6.04,
  6.6, 7.42, 8.2, 8.72, 8.9, 8.85, 8.34, 7.54, 6.76, 4.48, 4.04, 4.1, 3.81, 3.57, 3.36, 3.3, 3.06,
  3.05, 3.04, 3.1, 2.99, 2.96, 2.96, 3.02, 3.14, 3.41, 3.54, 3.73, 3.78, 3.79, 3.78, 3.73, 3.47,
  3.34, 3.18, 3.35, 3.23, 3.15, 3.06, 3.02, 3.29, 3.33, 3.67, 4.31, 5.07, 7.56, 8.85, 10.09, 11.05,
  12.07, 13.09, 13.53, 13.34, 12.61, 11.98, 10.96, 9.37, 6.75, 5.84, 5.63, 4.77, 4.37, 3.94, 3.73,
  3.42, 3.48, 3.82, 4.52, 5.4, 8.1, 9.53, 10.87, 11.83, 12.85, 13.78, 14.17, 13.9, 13.07, 12.28,
  11.22, 9.54, 6.86, 5.9, 5.64, 4.74, 4.33, 3.94, 3.7, 3.45, 3.5, 3.83, 4.55, 5.45, 8.24, 9.76,
  11.15, 12.18, 13.21, 14.14, 14.41, 14.08, 13.15, 12.32, 11.4, 9.85, 7.17, 6.06, 5.74, 4.83, 4.46,
  4.08, 3.86, 3.58, 3.6, 3.91, 4.54, 5.27, 7.85, 9.05, 10.3, 11.2, 12.22, 13.11, 13.67, 13.69,
  13.19, 12.37, 11.3, 9.87, 7.23, 6.31, 5.98, 5.18, 4.68, 4.15, 3.86, 3.5, 3.53, 3.84, 4.53, 5.35,
  8.02, 9.45, 10.74, 11.64, 12.41, 13.1, 13.19, 12.77, 11.91, 11.14, 10.03, 8.51, 6.02, 5.28, 5.18,
  4.44, 4.07, 3.68, 3.51, 3.6, 3.58, 3.6, 3.76, 3.68, 5.9, 6.33, 6.76, 7.45, 8.44, 9.49, 10.41,
  10.83, 11.04, 10.7, 9.96, 9.08, 6.47, 5.51, 5.1, 4.7, 4.31, 4.0, 3.89, 3.53, 3.48, 3.44, 3.48,
  3.23, 3.44, 3.52, 3.71, 3.99, 4.45, 4.75, 5.04, 5.05, 5.14, 5.21, 5.14, 4.69, 4.38, 3.97, 3.87,
  3.74, 3.6, 3.44, 3.36, 3.64, 3.66, 4.0, 4.78, 5.66, 8.75, 10.43, 11.82, 12.84, 13.77, 14.52,
  14.45, 13.86, 12.77, 11.82, 10.67, 9.04, 6.43, 5.59, 5.41, 4.69, 4.29, 3.87, 3.68, 3.46, 3.51,
  3.84, 4.58, 5.37, 8.29, 9.87, 11.28, 12.29, 13.27, 14.21, 14.45, 14.16, 13.29, 12.42, 11.2, 9.5,
  6.89, 5.91, 5.61, 4.84, 4.4, 3.95, 3.73, 3.5, 3.55, 3.88, 4.64, 5.46, 8.41, 9.99, 11.49, 12.68,
  13.7, 14.65, 14.96, 14.55, 13.51, 12.61, 11.32, 9.64, 7.01, 6.01, 5.76, 5.0, 4.56, 4.12, 3.91,
  3.65, 3.69, 3.99, 4.64, 5.25, 8.09, 9.39, 10.67, 11.59, 12.48, 13.08, 13.46, 13.32, 12.75, 12.0,
  11.03, 9.75, 7.22, 6.32, 5.94, 5.29, 4.84, 4.36, 4.09, 3.81, 3.79, 4.1, 4.85, 5.7, 8.74, 10.45,
  11.9, 12.98, 13.98, 14.97, 15.09, 14.54, 13.64, 12.9, 11.75, 10.01, 7.31, 6.3, 6.01, 5.11, 4.6,
  4.12, 3.9, 3.6, 3.58, 3.6, 3.76, 3.68, 5.9, 6.33, 6.76, 7.45, 8.44, 9.49, 10.41, 10.83, 11.04,
  10.7, 9.96, 9.08, 6.47, 5.51, 5.1, 4.7, 4.31, 4.0, 3.89, 3.53, 3.48, 3.44, 3.48, 3.23, 3.44, 3.52,
  3.71, 3.99, 4.45, 4.75, 5.04, 5.05, 5.14, 5.21, 5.14, 4.69, 4.38, 3.97, 3.87, 3.74, 3.6, 3.44,
  3.36, 3.64, 3.66, 4.0, 4.78, 5.66, 8.75, 10.43, 11.82, 12.84, 13.77, 14.52, 14.45, 13.86, 12.77,
  11.82, 10.67, 9.04, 6.43, 5.59, 5.41, 4.69, 4.29, 3.87, 3.68, 3.46, 3.51, 3.84, 4.58, 5.37, 8.29,
  9.87, 11.28, 12.29, 13.27, 14.21, 14.45, 14.16, 13.29, 12.42, 11.2, 9.5, 6.89, 5.91, 5.61, 4.84,
  4.4, 3.95, 3.73, 3.5, 3.55, 3.88, 4.64, 5.46, 8.41, 9.99, 11.49, 12.68, 13.7, 14.65, 14.96, 14.55,
  13.51, 12.61, 11.32, 9.64, 7.01, 6.01, 5.76, 5.0, 4.56, 4.12, 3.91, 3.65, 3.69, 3.99, 4.64, 5.25,
  8.09, 9.39, 10.67, 11.59, 12.48, 13.08, 13.46, 13.32, 12.75, 12.0, 11.03, 9.75, 7.22, 6.32, 5.94,
  5.29, 4.84, 4.36, 4.09, 3.81, 3.79, 4.1, 4.85, 5.7, 8.74, 10.45, 11.9, 12.98, 13.98, 14.97, 15.09,
  14.54, 13.64, 12.9, 11.75, 10.01, 7.31, 6.3, 6.01, 5.11, 4.6, 4.12, 3.9, 3.6, 3.58, 3.6, 3.76,
  3.68, 5.9, 6.33, 6.76, 7.45, 8.44, 9.49, 10.41, 10.83, 11.04, 10.7, 9.96, 9.08, 6.47, 5.51, 5.1,
  4.7, 4.31, 4.0, 3.89, 3.53, 3.48, 3.44, 3.48, 3.23, 3.44, 3.52, 3.71, 3.99, 4.45, 4.75, 5.04,
  5.05, 5.14, 5.21, 5.14, 4.69, 4.38, 3.97, 3.87, 3.74, 3.6, 3.44, 3.36, 3.64, 3.66, 4.0, 4.78,
  5.66, 8.75, 10.43, 11.82, 12.84, 13.77, 14.52, 14.45, 13.86, 12.77, 11.82, 10.67, 9.04, 6.43,
  5.59, 5.41, 4.69, 4.29, 3.87, 3.68, 3.46, 3.51, 3.84, 4.58, 5.37, 8.29, 9.87, 11.28, 12.29, 13.27,
  14.21, 14.45, 14.16, 13.29, 12.42, 11.2, 9.5, 6.89, 5.91, 5.61, 4.84, 4.4, 3.95, 3.73, 3.5, 3.55,
  3.88, 4.64, 5.46, 8.41, 9.99, 11.49, 12.68, 13.7, 14.65, 14.96, 14.55, 13.51, 12.61, 11.32, 9.64,
  7.01, 6.01, 5.76, 5.0, 4.56, 4.12, 3.91, 3.65, 3.69, 3.99, 4.64, 5.25, 8.09, 9.39, 10.67, 11.59,
  12.48, 13.08, 13.46, 13.32, 12.75, 12.0, 11.03, 9.75, 7.22, 6.32, 5.94, 5.29, 4.84, 4.36, 4.09,
  3.81, 3.79, 4.1, 4.85, 5.7, 8.74, 10.45, 11.9, 12.98, 13.98, 14.97, 15.09, 14.54, 13.64, 12.9,
  11.75, 10.01, 7.31, 6.3, 6.01, 5.11, 4.6, 4.12, 3.9, 3.6, 3.58, 3.6, 3.76, 3.68, 5.9, 6.33, 6.76,
  7.45, 8.44, 9.49, 10.41, 10.83, 11.04, 10.7, 9.96, 9.08, 6.47, 5.51, 5.1, 4.7, 4.31, 4.0, 3.89,
  3.53, 3.48, 3.44, 3.48, 3.23, 3.44, 3.52, 3.71, 3.99, 4.45, 4.75, 5.04, 5.05, 5.14, 5.21, 5.14,
  4.69, 4.38, 3.97, 3.87, 3.74, 3.6, 3.44, 3.36, 3.64, 3.66, 4.0, 4.78, 5.66, 8.75, 10.43, 11.82,
  12.84, 13.77, 14.52, 14.45, 13.86, 12.77, 11.82, 10.67, 9.04, 6.43, 5.59, 5.41, 4.69, 4.29, 3.87,
  3.68, 3.46, 3.51, 3.84, 4.58, 5.37, 8.29, 9.87, 11.28, 12.29, 13.27, 14.21, 14.45, 14.16, 13.29,
  12.42, 11.2, 9.5, 6.89, 5.91, 5.61, 4.84, 4.4, 3.95, 3.73, 3.5, 3.55, 3.88, 4.64, 5.46, 8.41,
  9.99, 11.49, 12.68, 13.7, 14.65, 14.96, 14.55, 13.51, 12.61, 11.32, 9.64, 7.01, 6.01, 5.76, 5.0,
  4.56, 4.12, 3.91, 3.65, 3.69, 3.99, 4.64, 5.25, 8.09, 9.39, 10.67, 11.59, 12.48, 13.08, 13.46,
  13.32, 12.75, 12.0, 11.03, 9.75, 7.22, 6.32, 5.94, 5.29, 4.84, 4.36, 4.09, 3.81, 3.79, 4.1, 4.85,
  5.7, 8.74, 10.45, 11.9, 12.98, 13.98, 14.97, 15.09, 14.54, 13.64, 12.9, 11.75, 10.01, 7.31, 6.3,
  6.01, 5.11, 4.6, 4.12, 3.9, 3.6, 3.58, 3.6, 3.76, 3.68, 5.9, 6.33, 6.76, 7.45, 8.44, 9.49, 10.41,
  10.83, 11.04, 10.7, 9.96, 9.08, 6.47, 5.51, 5.1, 4.7, 4.31, 4.0, 3.89, 3.53, 3.48, 3.44, 3.48,
  3.23, 3.44, 3.52, 3.71, 3.99, 4.45, 4.75, 5.04, 5.05, 5.14, 5.21, 5.14, 4.69, 4.38, 3.97, 3.87,
  3.74, 3.6, 3.44, 3.36, 3.81, 3.81, 4.18, 5.01, 6.11, 9.12, 10.84, 12.31, 13.45, 14.62, 15.73,
  16.0, 15.48, 14.18, 12.93, 11.72, 10.26, 7.72, 6.65, 6.25, 5.27, 4.81, 4.34, 4.08, 3.79, 3.83,
  4.22, 5.07, 6.21, 9.34, 11.16, 12.76, 13.98, 15.05, 16.13, 16.48, 16.12, 15.18, 14.36, 13.15,
  11.32, 8.41, 7.16, 6.7, 5.62, 5.07, 4.55, 4.27, 4.01, 4.05, 4.45, 5.35, 6.58, 9.83, 11.71, 13.4,
  14.89, 16.29, 17.56, 17.8, 17.21, 16.02, 15.09, 13.88, 11.91, 8.97, 7.62, 7.08, 6.03, 5.5, 4.9,
  4.59, 4.21, 4.13, 4.43, 5.13, 5.97, 8.89, 10.46, 12.17, 13.45, 14.59, 15.16, 15.17, 15.02, 14.59,
  13.67, 12.49, 10.93, 8.11, 7.07, 6.56, 5.69, 5.17, 4.61, 4.32, 4.02, 4.03, 4.4, 5.24, 6.37, 9.49,
  11.28, 12.86, 14.12, 15.2, 16.14, 16.17, 15.63, 14.73, 13.98, 12.83, 11.0, 8.18, 6.98, 6.54, 5.5,
  4.95, 4.45, 4.21, 3.85, 3.81, 3.78, 3.92, 4.0, 6.2, 6.74, 7.27, 8.08, 9.2, 10.23, 11.08, 11.42,
  11.49, 10.85, 9.79, 8.8, 6.24, 5.5, 5.21, 4.77, 4.36, 4.02, 3.87, 3.59, 3.53, 3.48, 3.52, 3.38,
  3.47, 3.55, 3.76, 4.08, 4.6, 4.92, 5.25, 5.43, 5.59, 5.67, 5.6, 5.13, 4.8, 4.29, 4.18, 4.01, 3.85,
  3.68, 3.57, 3.81, 3.81, 4.18, 5.01, 6.11, 9.12, 10.84, 12.31, 13.45, 14.62, 15.73, 16.0, 15.48,
  14.18, 12.93, 11.72, 10.26, 7.72, 6.65, 6.25, 5.27, 4.81, 4.34, 4.08, 3.79, 3.83, 4.22, 5.07,
  6.21, 9.34, 11.16, 12.76, 13.98, 15.05, 16.13, 16.48, 16.12, 15.18, 14.36, 13.15, 11.32, 8.41,
  7.16, 6.7, 5.62, 5.07, 4.55, 4.27, 4.01, 4.05, 4.45, 5.35, 6.58, 9.83, 11.71, 13.4, 14.89, 16.29,
  17.56, 17.8, 17.21, 16.02, 15.09, 13.88, 11.91, 8.97, 7.62, 7.08, 6.03, 5.5, 4.9, 4.59, 4.21,
  4.13, 4.43, 5.13, 5.97, 8.89, 10.46, 12.17, 13.45, 14.59, 15.16, 15.17, 15.02, 14.59, 13.67,
  12.49, 10.93, 8.11, 7.07, 6.56, 5.69, 5.17, 4.61, 4.32, 4.02, 4.03, 4.4, 5.24, 6.37, 9.49, 11.28,
  12.86, 14.12, 15.2, 16.14, 16.17, 15.63, 14.73, 13.98, 12.83, 11.0, 8.18, 6.98, 6.54, 5.5, 4.95,
  4.45, 4.21, 3.85, 3.81, 3.78, 3.92, 4.0, 6.2, 6.74, 7.27, 8.08, 9.2, 10.23, 11.08, 11.42, 11.49,
  10.85, 9.79, 8.8, 6.24, 5.5, 5.21, 4.77, 4.36, 4.02, 3.87, 3.59, 3.53, 3.48, 3.52, 3.38, 3.47,
  3.55, 3.76, 4.08, 4.6, 4.92, 5.25, 5.43, 5.59, 5.67, 5.6, 5.13, 4.8, 4.29, 4.18, 4.01, 3.85, 3.68,
  3.57, 3.81, 3.81, 4.18, 5.01, 6.11, 9.12, 10.84, 12.31, 13.45, 14.62, 15.73, 16.0, 15.48, 14.18,
  12.93, 11.72, 10.26, 7.72, 6.65, 6.25, 5.27, 4.81, 4.34, 4.08, 3.79, 3.83, 4.22, 5.07, 6.21, 9.34,
  11.16, 12.76, 13.98, 15.05, 16.13, 16.48, 16.12, 15.18, 14.36, 13.15, 11.32, 8.41, 7.16, 6.7,
  5.62, 5.07, 4.55, 4.27, 4.01, 4.05, 4.45, 5.35, 6.58, 9.83, 11.71, 13.4, 14.89, 16.29, 17.56,
  17.8, 17.21, 16.02, 15.09, 13.88, 11.91, 8.97, 7.62, 7.08, 6.03, 5.5, 4.9, 4.59, 4.21, 4.13, 4.43,
  5.13, 5.97, 8.89, 10.46, 12.17, 13.45, 14.59, 15.16, 15.17, 15.02, 14.59, 13.67, 12.49, 10.93,
  8.11, 7.07, 6.56, 5.69, 5.17, 4.61, 4.32, 4.02, 4.03, 4.4, 5.24, 6.37, 9.49, 11.28, 12.86, 14.12,
  15.2, 16.14, 16.17, 15.63, 14.73, 13.98, 12.83, 11.0, 8.18, 6.98, 6.54, 5.5, 4.95, 4.45, 4.21,
  3.85, 3.81, 3.78, 3.92, 4.0, 6.2, 6.74, 7.27, 8.08, 9.2, 10.23, 11.08, 11.42, 11.49, 10.85, 9.79,
  8.8, 6.24, 5.5, 5.21, 4.77, 4.36, 4.02, 3.87, 3.59, 3.53, 3.48, 3.52, 3.38, 3.47, 3.55, 3.76,
  4.08, 4.6, 4.92, 5.25, 5.43, 5.59, 5.67, 5.6, 5.13, 4.8, 4.29, 4.18, 4.01, 3.85, 3.68, 3.57, 3.81,
  3.81, 4.18, 5.01, 6.11, 9.12, 10.84, 12.31, 13.45, 14.62, 15.73, 16.0, 15.48, 14.18, 12.93, 11.72,
  10.26, 7.72, 6.65, 6.25, 5.27, 4.81, 4.34, 4.08, 3.79, 3.83, 4.22, 5.07, 6.21, 9.34, 11.16, 12.76,
  13.98, 15.05, 16.13, 16.48, 16.12, 15.18, 14.36, 13.15, 11.32, 8.41, 7.16, 6.7, 5.62, 5.07, 4.55,
  4.27, 4.01, 4.05, 4.45, 5.35, 6.58, 9.83, 11.71, 13.4, 14.89, 16.29, 17.56, 17.8, 17.21, 16.02,
  15.09, 13.88, 11.91, 8.97, 7.62, 7.08, 6.03, 5.5, 4.9, 4.59, 4.21, 4.13, 4.43, 5.13, 5.97, 8.89,
  10.46, 12.17, 13.45, 14.59, 15.16, 15.17, 15.02, 14.59, 13.67, 12.49, 10.93, 8.11, 7.07, 6.56,
  5.69, 5.17, 4.61, 4.32, 4.02, 4.03, 4.4, 5.24, 6.37, 9.49, 11.28, 12.86, 14.12, 15.2, 16.14,
  16.17, 15.63, 14.73, 13.98, 12.83, 11.0, 8.18, 6.98, 6.54, 5.5, 4.95, 4.45, 4.21, 3.85, 3.81,
  3.78, 3.92, 4.0, 6.2, 6.74, 7.27, 8.08, 9.2, 10.23, 11.08, 11.42, 11.49, 10.85, 9.79, 8.8, 6.24,
  5.5, 5.21, 4.77, 4.36, 4.02, 3.87, 3.59, 3.53, 3.48, 3.52, 3.38, 3.47, 3.55, 3.76, 4.08, 4.6,
  4.92, 5.25, 5.43, 5.59, 5.67, 5.6, 5.13, 4.8, 4.29, 4.18, 4.01, 3.85, 3.68, 3.57, 3.81, 3.81,
  4.18, 5.01, 6.11, 9.12, 10.84, 12.31, 13.45, 14.62, 15.73, 16.0, 15.48, 14.18, 12.93, 11.72,
  10.26, 7.72, 6.65, 6.25, 5.27, 4.81, 4.34, 4.08, 3.79, 3.83, 4.22, 5.07, 6.21, 9.34, 11.16, 12.76,
  13.98, 15.05, 16.13, 16.48, 16.12, 15.18, 14.36, 13.15, 11.32, 8.41, 7.16, 6.7, 5.62, 5.07, 4.55,
  4.27, 4.01, 4.05, 4.45, 5.35, 6.58, 9.83, 11.71, 13.4, 14.89, 16.29, 17.56, 17.8, 17.21, 16.02,
  15.09, 13.88, 11.91, 8.97, 7.62, 7.08, 6.03, 5.5, 4.9, 4.59, 3.82, 3.79, 4.09, 4.74, 5.68, 8.26,
  9.52, 10.9, 11.83, 12.84, 13.53, 13.92, 13.79, 13.1, 12.3, 11.3, 9.9, 7.21, 6.5, 6.15, 5.4, 4.97,
  4.47, 4.21, 3.92, 3.95, 4.32, 5.2, 6.49, 9.4, 11.07, 12.54, 13.69, 14.78, 15.81, 16.06, 15.62,
  14.68, 13.72, 12.46, 10.71, 7.96, 6.97, 6.5, 5.43, 4.99, 4.5, 4.23, 3.94, 3.91, 3.91, 4.05, 4.25,
  6.26, 6.72, 7.28, 8.14, 9.3, 10.33, 11.07, 11.43, 11.51, 11.09, 10.31, 9.39, 6.64, 5.8, 5.44,
  4.95, 4.53, 4.14, 3.96, 3.61, 3.54, 3.5, 3.54, 3.58, 3.49, 3.58, 3.79, 4.09, 4.57, 4.94, 5.31,
  5.46, 5.52, 5.42, 5.25, 4.75, 4.43, 4.13, 4.1, 3.9, 3.79, 3.63, 3.49, 3.73, 3.74, 4.08, 4.9, 6.13,
  8.87, 10.44, 11.86, 12.98, 13.83, 14.66, 14.83, 14.41, 13.38, 12.38, 11.24, 9.59, 6.94, 6.13,
  5.79, 4.85, 4.41, 3.97, 3.77, 3.54, 3.59, 3.93, 4.71, 5.87, 8.59, 10.11, 11.56, 12.73, 13.75,
  14.64, 14.8, 14.32, 13.29, 12.42, 11.46, 9.87, 7.2, 6.29, 5.92, 4.98, 4.56, 4.12, 3.91, 3.67,
  3.71, 4.08, 4.92, 6.17, 9.03, 10.68, 12.19, 13.46, 14.54, 15.63, 15.88, 15.49, 14.63, 13.78,
  12.55, 10.75, 7.87, 6.86, 6.48, 5.39, 4.9, 4.39, 4.14, 3.82, 3.79, 4.09, 4.74, 5.68, 8.26, 9.52,
  10.9, 11.83, 12.84, 13.53, 13.92, 13.79, 13.1, 12.3, 11.3, 9.9, 7.21, 6.5, 6.15, 5.4, 4.97, 4.47,
  4.21, 3.92, 3.95, 4.32, 5.2, 6.49, 9.4, 11.07, 12.54, 13.69, 14.78, 15.81, 16.06, 15.62, 14.68,
  13.72, 12.46, 10.71, 7.96, 6.97, 6.5, 5.43, 4.99, 4.5, 4.23, 3.94, 3.91, 3.91, 4.05, 4.25, 6.26,
  6.72, 7.28, 8.14, 9.3, 10.33, 11.07, 11.43, 11.51, 11.09, 10.31, 9.39, 6.64, 5.8, 5.44, 4.95,
  4.53, 4.14, 3.96, 3.61, 3.54, 3.5, 3.54, 3.58, 3.49, 3.58, 3.79, 4.09, 4.57, 4.94, 5.31, 5.46,
  5.52, 5.42, 5.25, 4.75, 4.43, 4.13, 4.1, 3.9, 3.79, 3.63, 3.49, 3.73, 3.74, 4.08, 4.9, 6.13, 8.87,
  10.44, 11.86, 12.98, 13.83, 14.66, 14.83, 14.41, 13.38, 12.38, 11.24, 9.59, 6.94, 6.13, 5.79,
  4.85, 4.41, 3.97, 3.77, 3.54, 3.59, 3.93, 4.71, 5.87, 8.59, 10.11, 11.56, 12.73, 13.75, 14.64,
  14.8, 14.32, 13.29, 12.42, 11.46, 9.87, 7.2, 6.29, 5.92, 4.98, 4.56, 4.12, 3.91, 3.67, 3.71, 4.08,
  4.92, 6.17, 9.03, 10.68, 12.19, 13.46, 14.54, 15.63, 15.88, 15.49, 14.63, 13.78, 12.55, 10.75,
  7.87, 6.86, 6.48, 5.39, 4.9, 4.39, 4.14, 3.82, 3.79, 4.09, 4.74, 5.68, 8.26, 9.52, 10.9, 11.83,
  12.84, 13.53, 13.92, 13.79, 13.1, 12.3, 11.3, 9.9, 7.21, 6.5, 6.15, 5.4, 4.97, 4.47, 4.21, 3.92,
  3.95, 4.32, 5.2, 6.49, 9.4, 11.07, 12.54, 13.69, 14.78, 15.81, 16.06, 15.62, 14.68, 13.72, 12.46,
  10.71, 7.96, 6.97, 6.5, 5.43, 4.99, 4.5, 4.23, 3.94, 3.91, 3.91, 4.05, 4.25, 6.26, 6.72, 7.28,
  8.14, 9.3, 10.33, 11.07, 11.43, 11.51, 11.09, 10.31, 9.39, 6.64, 5.8, 5.44, 4.95, 4.53, 4.14,
  3.96, 3.61, 3.54, 3.5, 3.54, 3.58, 3.49, 3.58, 3.79, 4.09, 4.57, 4.94, 5.31, 5.46, 5.52, 5.42,
  5.25, 4.75, 4.43, 4.13, 4.1, 3.9, 3.79, 3.63, 3.49, 3.73, 3.74, 4.08, 4.9, 6.13, 8.87, 10.44,
  11.86, 12.98, 13.83, 14.66, 14.83, 14.41, 13.38, 12.38, 11.24, 9.59, 6.94, 6.13, 5.79, 4.85, 4.41,
  3.97, 3.77, 3.54, 3.59, 3.93, 4.71, 5.87, 8.59, 10.11, 11.56, 12.73, 13.75, 14.64, 14.8, 14.32,
  13.29, 12.42, 11.46, 9.87, 7.2, 6.29, 5.92, 4.98, 4.56, 4.12, 3.91, 3.67, 3.71, 4.08, 4.92, 6.17,
  9.03, 10.68, 12.19, 13.46, 14.54, 15.63, 15.88, 15.49, 14.63, 13.78, 12.55, 10.75, 7.87, 6.86,
  6.48, 5.39, 4.9, 4.39, 4.14, 3.82, 3.79, 4.09, 4.74, 5.68, 8.26, 9.52, 10.9, 11.83, 12.84, 13.53,
  13.92, 13.79, 13.1, 12.3, 11.3, 9.9, 7.21, 6.5, 6.15, 5.4, 4.97, 4.47, 4.21, 3.92, 3.95, 4.32,
  5.2, 6.49, 9.4, 11.07, 12.54, 13.69, 14.78, 15.81, 16.06, 15.62, 14.68, 13.72, 12.46, 10.71, 7.96,
  6.97, 6.5, 5.43, 4.99, 4.5, 4.23, 3.94, 3.91, 3.91, 4.05, 4.25, 6.26, 6.72, 7.28, 8.14, 9.3,
  10.33, 11.07, 11.43, 11.51, 11.09, 10.31, 9.39, 6.64, 5.8, 5.44, 4.95, 4.53, 4.14, 3.96, 3.61,
  3.54, 3.5, 3.54, 3.58, 3.49, 3.58, 3.79, 4.09, 4.57, 4.94, 5.31, 5.46, 5.52, 5.42, 5.25, 4.75,
  4.43, 4.13, 4.1, 3.9, 3.79, 3.63, 3.49, 3.73, 3.74, 4.08, 4.9, 6.13, 8.87, 10.44, 11.86, 12.98,
  13.83, 14.66, 14.83, 14.41, 13.38, 12.38, 11.24, 9.59, 6.94, 6.13, 5.79, 4.85, 4.41, 3.97, 3.77,
  3.54, 3.59, 3.93, 4.71, 5.87, 8.59, 10.11, 11.56, 12.73, 13.75, 14.64, 14.8, 14.32, 13.29, 12.42,
  11.46, 9.87, 7.2, 6.29, 5.92, 4.98, 4.56, 4.12, 3.91, 3.67, 3.71, 4.08, 4.92, 6.17, 9.03, 10.68,
  12.19, 13.46, 14.54, 15.63, 15.88, 15.49, 14.63, 13.78, 12.55, 10.75, 7.87, 6.86, 6.48, 5.39, 4.9,
  4.39, 4.14, 3.82, 3.79, 4.09, 4.74, 5.68, 8.26, 9.52, 10.9, 11.83, 12.84, 13.53, 13.92, 13.79,
  13.1, 12.3, 11.3, 9.9, 7.21, 6.5, 6.15, 5.4, 4.97, 4.47, 4.21, 3.92, 3.95, 4.32, 5.2, 6.49, 9.4,
  11.07, 12.54, 13.69, 14.78, 15.81, 16.06, 15.62, 14.68, 13.72, 12.46, 10.71, 7.96, 6.97, 6.5,
  5.43, 4.99, 4.5, 4.23, 3.94, 3.91, 3.91, 4.05, 4.25, 6.26, 6.72, 7.28, 8.14, 9.3, 10.33, 11.07,
  11.43, 11.51, 11.09, 10.31, 9.39, 6.64, 5.8, 5.44, 4.95, 4.53, 4.14, 3.96, 3.24, 3.21, 3.19, 3.24,
  3.32, 3.4, 3.11, 3.2, 3.38, 3.76, 4.0, 4.29, 4.43, 4.52, 4.49, 4.37, 4.0, 3.85, 3.96, 3.68, 3.53,
  3.44, 3.32, 3.27, 3.56, 3.58, 3.93, 4.69, 5.85, 8.67, 9.88, 11.22, 12.26, 13.3, 14.24, 14.45,
  14.02, 13.0, 12.1, 11.02, 9.42, 6.84, 6.29, 5.71, 4.85, 4.45, 4.02, 3.82, 3.58, 3.62, 3.95, 4.71,
  5.86, 8.72, 9.92, 11.24, 12.31, 13.34, 14.25, 14.5, 14.14, 13.16, 12.2, 11.09, 9.46, 6.83, 6.27,
  5.68, 4.81, 4.39, 3.95, 3.75, 3.51, 3.56, 3.91, 4.69, 5.86, 8.74, 9.88, 11.22, 12.33, 13.42,
  14.37, 14.54, 14.09, 13.05, 12.05, 10.89, 9.3, 6.77, 6.23, 5.68, 4.83, 4.43, 4.01, 3.82, 3.58,
  3.62, 3.94, 4.6, 5.56, 8.22, 9.13, 10.27, 11.04, 11.86, 12.4, 12.68, 12.51, 11.96, 11.28, 10.39,
  9.08, 6.59, 6.16, 5.62, 4.96, 4.56, 4.13, 3.93, 3.7, 3.75, 4.1, 4.9, 6.14, 9.18, 10.4, 11.72,
  12.74, 13.7, 14.63, 14.79, 14.27, 13.21, 12.26, 11.12, 9.5, 6.98, 6.43, 5.84, 4.89, 4.44, 4.01,
  3.8, 3.53, 3.51, 3.53, 3.7, 3.94, 6.07, 6.13, 6.45, 7.03, 7.88, 8.71, 9.31, 9.55, 9.57, 9.16,
  8.42, 7.55, 5.16, 4.9, 4.45, 4.12, 3.83, 3.59, 3.51, 3.24, 3.21, 3.19, 3.24, 3.32, 3.4, 3.11, 3.2,
  3.38, 3.76, 4.0, 4.29, 4.43, 4.52, 4.49, 4.37, 4.0, 3.85, 3.96, 3.68, 3.53, 3.44, 3.32, 3.27,
  3.56, 3.58, 3.93, 4.69, 5.85, 8.67, 9.88, 11.22, 12.26, 13.3, 14.24, 14.45, 14.02, 13.0, 12.1,
  11.02, 9.42, 6.84, 6.29, 5.71, 4.85, 4.45, 4.02, 3.82, 3.58, 3.62, 3.95, 4.71, 5.86, 8.72, 9.92,
  11.24, 12.31, 13.34, 14.25, 14.5, 14.14, 13.16, 12.2, 11.09, 9.46, 6.83, 6.27, 5.68, 4.81, 4.39,
  3.95, 3.75, 3.51, 3.56, 3.91, 4.69, 5.86, 8.74, 9.88, 11.22, 12.33, 13.42, 14.37, 14.54, 14.09,
  13.05, 12.05, 10.89, 9.3, 6.77, 6.23, 5.68, 4.83, 4.43, 4.01, 3.82, 3.58, 3.62, 3.94, 4.6, 5.56,
  8.22, 9.13, 10.27, 11.04, 11.86, 12.4, 12.68, 12.51, 11.96, 11.28, 10.39, 9.08, 6.59, 6.16, 5.62,
  4.96, 4.56, 4.13, 3.93, 3.7, 3.75, 4.1, 4.9, 6.14, 9.18, 10.4, 11.72, 12.74, 13.7, 14.63, 14.79,
  14.27, 13.21, 12.26, 11.12, 9.5, 6.98, 6.43, 5.84, 4.89, 4.44, 4.01, 3.8, 3.53, 3.51, 3.53, 3.7,
  3.94, 6.07, 6.13, 6.45, 7.03, 7.88, 8.71, 9.31, 9.55, 9.57, 9.16, 8.42, 7.55, 5.16, 4.9, 4.45,
  4.12, 3.83, 3.59, 3.51, 3.24, 3.21, 3.19, 3.24, 3.32, 3.4, 3.11, 3.2, 3.38, 3.76, 4.0, 4.29, 4.43,
  4.52, 4.49, 4.37, 4.0, 3.85, 3.96, 3.68, 3.53, 3.44, 3.32, 3.27, 3.56, 3.58, 3.93, 4.69, 5.85,
  8.67, 9.88, 11.22, 12.26, 13.3, 14.24, 14.45, 14.02, 13.0, 12.1, 11.02, 9.42, 6.84, 6.29, 5.71,
  4.85, 4.45, 4.02, 3.82, 3.58, 3.62, 3.95, 4.71, 5.86, 8.72, 9.92, 11.24, 12.31, 13.34, 14.25,
  14.5, 14.14, 13.16, 12.2, 11.09, 9.46, 6.83, 6.27, 5.68, 4.81, 4.39, 3.95, 3.75, 3.51, 3.56, 3.91,
  4.69, 5.86, 8.74, 9.88, 11.22, 12.33, 13.42, 14.37, 14.54, 14.09, 13.05, 12.05, 10.89, 9.3, 6.77,
  6.23, 5.68, 4.83, 4.43, 4.01, 3.82, 3.58, 3.62, 3.94, 4.6, 5.56, 8.22, 9.13, 10.27, 11.04, 11.86,
  12.4, 12.68, 12.51, 11.96, 11.28, 10.39, 9.08, 6.59, 6.16, 5.62, 4.96, 4.56, 4.13, 3.93, 3.7,
  3.75, 4.1, 4.9, 6.14, 9.18, 10.4, 11.72, 12.74, 13.7, 14.63, 14.79, 14.27, 13.21, 12.26, 11.12,
  9.5, 6.98, 6.43, 5.84, 4.89, 4.44, 4.01, 3.8, 3.53, 3.51, 3.53, 3.7, 3.94, 6.07, 6.13, 6.45, 7.03,
  7.88, 8.71, 9.31, 9.55, 9.57, 9.16, 8.42, 7.55, 5.16, 4.9, 4.45, 4.12, 3.83, 3.59, 3.51, 3.24,
  3.21, 3.19, 3.24, 3.32, 3.4, 3.11, 3.2, 3.38, 3.76, 4.0, 4.29, 4.43, 4.52, 4.49, 4.37, 4.0, 3.85,
  3.96, 3.68, 3.53, 3.44, 3.32, 3.27, 3.56, 3.58, 3.93, 4.69, 5.85, 8.67, 9.88, 11.22, 12.26, 13.3,
  14.24, 14.45, 14.02, 13.0, 12.1, 11.02, 9.42, 6.84, 6.29, 5.71, 4.85, 4.45, 4.02, 3.82, 3.58,
  3.62, 3.95, 4.71, 5.86, 8.72, 9.92, 11.24, 12.31, 13.34, 14.25, 14.5, 14.14, 13.16, 12.2, 11.09,
  9.46, 6.83, 6.27, 5.68, 4.81, 4.39, 3.95, 3.75, 3.51, 3.56, 3.91, 4.69, 5.86, 8.74, 9.88, 11.22,
  12.33, 13.42, 14.37, 14.54, 14.09, 13.05, 12.05, 10.89, 9.3, 6.77, 6.23, 5.68, 4.83, 4.43, 4.01,
  3.82, 3.58, 3.62, 3.94, 4.6, 5.56, 8.22, 9.13, 10.27, 11.04, 11.86, 12.4, 12.68, 12.51, 11.96,
  11.28, 10.39, 9.08, 6.59, 6.16, 5.62, 4.96, 4.56, 4.13, 3.93, 3.7, 3.75, 4.1, 4.9, 6.14, 9.18,
  10.4, 11.72, 12.74, 13.7, 14.63, 14.79, 14.27, 13.21, 12.26, 11.12, 9.5, 6.98, 6.43, 5.84, 4.89,
  4.44, 4.01, 3.8, 3.53, 3.51, 3.53, 3.7, 3.94, 6.07, 6.13, 6.45, 7.03, 7.88, 8.71, 9.31, 9.55,
  9.57, 9.16, 8.42, 7.55, 5.16, 4.9, 4.45, 4.12, 3.83, 3.59, 3.51, 3.24, 3.21, 3.19, 3.24, 3.32,
  3.4, 3.11, 3.2, 3.38, 3.76, 4.0, 4.29, 4.43, 4.52, 4.49, 4.37, 4.0, 3.85, 3.96, 3.68, 3.53, 3.44,
  3.32, 3.27, 3.56, 3.58, 3.93, 4.69, 5.85, 8.67, 9.88, 11.22, 12.26, 13.3, 14.24, 14.45, 14.02,
  13.0, 12.1, 11.02, 9.42, 6.84, 6.29, 5.71, 4.85, 4.45, 4.02, 3.82, 3.3, 3.36, 3.69, 4.35, 5.31,
  8.11, 8.88, 9.91, 10.64, 11.41, 12.04, 12.29, 12.04, 11.26, 10.47, 9.55, 8.27, 6.24, 5.59, 5.09,
  4.39, 4.03, 3.66, 3.49, 3.28, 3.35, 3.68, 4.34, 5.27, 8.02, 8.74, 9.75, 10.5, 11.3, 11.95, 12.22,
  11.97, 11.19, 10.37, 9.39, 8.18, 6.18, 5.56, 5.07, 4.39, 4.05, 3.68, 3.52, 3.34, 3.4, 3.72, 4.35,
  5.25, 8.02, 8.7, 9.69, 10.35, 11.05, 11.49, 11.7, 11.53, 10.92, 10.19, 9.27, 8.14, 6.13, 5.57,
  5.07, 4.46, 4.1, 3.71, 3.54, 3.34, 3.4, 3.71, 4.37, 5.34, 8.12, 8.88, 9.87, 10.58, 11.29, 11.9,
  12.09, 11.76, 10.98, 10.21, 9.3, 8.13, 6.16, 5.52, 5.03, 4.36, 4.02, 3.64, 3.47, 3.22, 3.22, 3.23,
  3.38, 3.59, 5.72, 5.58, 5.77, 6.23, 6.89, 7.55, 8.01, 8.17, 8.17, 7.81, 7.19, 6.57, 4.73, 4.32,
  3.96, 3.7, 3.47, 3.28, 3.22, 3.0, 3.0, 3.0, 3.07, 3.15, 3.41, 2.97, 2.95, 3.06, 3.33, 3.49, 3.68,
  3.74, 3.77, 3.74, 3.65, 3.41, 3.72, 3.57, 3.33, 3.22, 3.15, 3.06, 3.03, 3.33, 3.36, 3.69, 4.34,
  5.28, 8.0, 8.73, 9.74, 10.47, 11.24, 11.88, 12.11, 11.85, 11.1, 10.34, 9.42, 8.2, 6.19, 5.56,
  5.07, 4.39, 4.05, 3.67, 3.5, 3.3, 3.36, 3.69, 4.35, 5.31, 8.11, 8.88, 9.91, 10.64, 11.41, 12.04,
  12.29, 12.04, 11.26, 10.47, 9.55, 8.27, 6.24, 5.59, 5.09, 4.39, 4.03, 3.66, 3.49, 3.28, 3.35,
  3.68, 4.34, 5.27, 8.02, 8.74, 9.75, 10.5, 11.3, 11.95, 12.22, 11.97, 11.19, 10.37, 9.39, 8.18,
  6.18, 5.56, 5.07, 4.39, 4.05, 3.68, 3.52, 3.34, 3.4, 3.72, 4.35, 5.25, 8.02, 8.7, 9.69, 10.35,
  11.05, 11.49, 11.7, 11.53, 10.92, 10.19, 9.27, 8.14, 6.13, 5.57, 5.07, 4.46, 4.1, 3.71, 3.54,
  3.34, 3.4, 3.71, 4.37, 5.34, 8.12, 8.88, 9.87, 10.58, 11.29, 11.9, 12.09, 11.76, 10.98, 10.21,
  9.3, 8.13, 6.16, 5.52, 5.03, 4.36, 4.02, 3.64, 3.47, 3.22, 3.22, 3.23, 3.38, 3.59, 5.72, 5.58,
  5.77, 6.23, 6.89, 7.55, 8.01, 8.17, 8.17, 7.81, 7.19, 6.57, 4.73, 4.32, 3.96, 3.7, 3.47, 3.28,
  3.22, 3.0, 3.0, 3.0, 3.07, 3.15, 3.41, 2.97, 2.95, 3.06, 3.33, 3.49, 3.68, 3.74, 3.77, 3.74, 3.65,
  3.41, 3.72, 3.57, 3.33, 3.22, 3.15, 3.06, 3.03, 3.33, 3.36, 3.69, 4.34, 5.28, 8.0, 8.73, 9.74,
  10.47, 11.24, 11.88, 12.11, 11.85, 11.1, 10.34, 9.42, 8.2, 6.19, 5.56, 5.07, 4.39, 4.05, 3.67,
  3.5, 3.3, 3.36, 3.69, 4.35, 5.31, 8.11, 8.88, 9.91, 10.64, 11.41, 12.04, 12.29, 12.04, 11.26,
  10.47, 9.55, 8.27, 6.24, 5.59, 5.09, 4.39, 4.03, 3.66, 3.49, 3.28, 3.35, 3.68, 4.34, 5.27, 8.02,
  8.74, 9.75, 10.5, 11.3, 11.95, 12.22, 11.97, 11.19, 10.37, 9.39, 8.18, 6.18, 5.56, 5.07, 4.39,
  4.05, 3.68, 3.52, 3.34, 3.4, 3.72, 4.35, 5.25, 8.02, 8.7, 9.69, 10.35, 11.05, 11.49, 11.7, 11.53,
  10.92, 10.19, 9.27, 8.14, 6.13, 5.57, 5.07, 4.46, 4.1, 3.71, 3.54, 3.34, 3.4, 3.71, 4.37, 5.34,
  8.12, 8.88, 9.87, 10.58, 11.29, 11.9, 12.09, 11.76, 10.98, 10.21, 9.3, 8.13, 6.16, 5.52, 5.03,
  4.36, 4.02, 3.64, 3.47, 3.22, 3.22, 3.23, 3.38, 3.59, 5.72, 5.58, 5.77, 6.23, 6.89, 7.55, 8.01,
  8.17, 8.17, 7.81, 7.19, 6.57, 4.73, 4.32, 3.96, 3.7, 3.47, 3.28, 3.22, 3.0, 3.0, 3.0, 3.07, 3.15,
  3.41, 2.97, 2.95, 3.06, 3.33, 3.49, 3.68, 3.74, 3.77, 3.74, 3.65, 3.41, 3.72, 3.57, 3.33, 3.22,
  3.15, 3.06, 3.03, 3.33, 3.36, 3.69, 4.34, 5.28, 8.0, 8.73, 9.74, 10.47, 11.24, 11.88, 12.11,
  11.85, 11.1, 10.34, 9.42, 8.2, 6.19, 5.56, 5.07, 4.39, 4.05, 3.67, 3.5, 3.3, 3.36, 3.69, 4.35,
  5.31, 8.11, 8.88, 9.91, 10.64, 11.41, 12.04, 12.29, 12.04, 11.26, 10.47, 9.55, 8.27, 6.24, 5.59,
  5.09, 4.39, 4.03, 3.66, 3.49, 3.28, 3.35, 3.68, 4.34, 5.27, 8.02, 8.74, 9.75, 10.5, 11.3, 11.95,
  12.22, 11.97, 11.19, 10.37, 9.39, 8.18, 6.18, 5.56, 5.07, 4.39, 4.05, 3.68, 3.52, 3.34, 3.4, 3.72,
  4.35, 5.25, 8.02, 8.7, 9.69, 10.35, 11.05, 11.49, 11.7, 11.53, 10.92, 10.19, 9.27, 8.14, 6.13,
  5.57, 5.07, 4.46, 4.1, 3.71, 3.54, 3.34, 3.4, 3.71, 4.37, 5.34, 8.12, 8.88, 9.87, 10.58, 11.29,
  11.9, 12.09, 11.76, 10.98, 10.21, 9.3, 8.13, 6.16, 5.52, 5.03, 4.36, 4.02, 3.64, 3.47, 3.22, 3.22,
  3.23, 3.38, 3.59, 5.72, 5.58, 5.77, 6.23, 6.89, 7.55, 8.01, 8.17, 8.17, 7.81, 7.19, 6.57, 4.73,
  4.32, 3.96, 3.7, 3.47, 3.28, 3.22, 3.0, 3.0, 3.0, 3.07, 3.15, 3.41, 2.97, 2.95, 3.06, 3.33, 3.49,
  3.68, 3.74, 3.77, 3.74, 3.65, 3.41, 3.72, 3.57, 3.33, 3.22, 3.15, 3.06, 3.03, 3.33, 3.36, 3.69,
  4.34, 5.28, 8.0, 8.73, 9.74, 10.47, 11.24, 11.88, 12.11, 11.85, 11.1, 10.34, 9.42, 8.2, 6.19,
  5.56, 5.07, 4.39, 4.05, 3.67, 3.5, 3.3, 3.36, 3.69, 4.35, 5.31, 8.11, 8.88, 9.91, 10.64, 11.41,
  12.04, 12.29, 12.04, 11.26, 10.47, 9.55, 8.27, 6.24, 5.59, 5.09, 4.39, 4.03, 3.66, 3.49, 3.28,
  3.35, 3.68, 4.34, 5.27, 8.02, 8.74, 9.75, 10.5, 11.3, 11.95, 12.22, 11.97, 11.19, 10.37, 9.39,
  8.18, 6.18, 5.56, 5.07, 4.39, 4.05, 3.68, 3.52, 3.34, 3.4, 3.72, 4.35, 5.25, 8.02, 8.7, 9.69,
  10.35, 11.05, 11.49, 11.7, 11.53, 10.92, 10.19, 9.27, 8.14, 6.13, 5.57, 5.07, 4.46, 4.1, 3.71,
  3.54, 3.41, 3.27, 3.39, 3.8, 4.47, 5.69, 7.88, 8.6, 9.54, 10.19, 10.82, 11.23, 11.33, 11.0, 10.33,
  9.55, 8.86, 7.78, 5.83, 5.25, 4.76, 4.21, 3.88, 3.57, 3.22, 3.19, 3.19, 3.19, 3.25, 3.42, 4.12,
  5.59, 5.59, 5.92, 6.45, 7.15, 7.76, 8.13, 8.22, 8.1, 7.62, 7.19, 6.42, 4.67, 4.25, 3.93, 3.68,
  3.46, 3.3, 3.04, 3.04, 3.04, 3.06, 3.14, 3.27, 3.3, 2.99, 3.04, 3.17, 3.4, 3.55, 3.7, 3.72, 3.71,
  3.65, 3.74, 3.79, 3.67, 3.49, 3.29, 3.22, 3.15, 3.08, 3.51, 3.29, 3.37, 3.72, 4.36, 5.24, 7.68,
  8.46, 9.43, 10.04, 10.65, 11.11, 11.27, 11.01, 10.34, 9.6, 8.99, 8.16, 5.92, 5.34, 4.9, 4.31,
  3.98, 3.63, 3.47, 3.26, 3.35, 3.7, 4.33, 5.2, 7.67, 8.42, 9.43, 10.09, 10.76, 11.24, 11.41, 11.14,
  10.41, 9.64, 9.02, 8.17, 5.92, 5.33, 4.89, 4.29, 3.98, 3.62, 3.47, 3.28, 3.36, 3.68, 4.31, 5.18,
  7.74, 8.49, 9.49, 10.12, 10.79, 11.27, 11.42, 11.1, 10.4, 9.66, 9.1, 8.23, 5.97, 5.36, 4.92, 4.33,
  4.0, 3.66, 3.45, 3.3, 3.4, 3.77, 4.42, 5.64, 7.74, 8.43, 9.28, 9.84, 10.38, 10.71, 10.79, 10.48,
  9.87, 9.17, 8.57, 7.55, 5.66, 5.14, 4.68, 4.2, 3.89, 3.58, 3.41, 3.27, 3.39, 3.8, 4.47, 5.69,
  7.88, 8.6, 9.54, 10.19, 10.82, 11.23, 11.33, 11.0, 10.33, 9.55, 8.86, 7.78, 5.83, 5.25, 4.76,
  4.21, 3.88, 3.57, 3.22, 3.19, 3.19, 3.25, 3.42, 4.12, 5.59, 5.59, 5.92, 6.45, 7.15, 7.76, 8.13,
  8.22, 8.1, 7.62, 7.19, 6.42, 4.67, 4.25, 3.93, 3.68, 3.46, 3.3, 3.04, 3.04, 3.04, 3.06, 3.14,
  3.27, 3.3, 2.99, 3.04, 3.17, 3.4, 3.55, 3.7, 3.72, 3.71, 3.65, 3.74, 3.79, 3.67, 3.49, 3.29, 3.22,
  3.15, 3.08, 3.51, 3.29, 3.37, 3.72, 4.36, 5.24, 7.68, 8.46, 9.43, 10.04, 10.65, 11.11, 11.27,
  11.01, 10.34, 9.6, 8.99, 8.16, 5.92, 5.34, 4.9, 4.31, 3.98, 3.63, 3.47, 3.26, 3.35, 3.7, 4.33,
  5.2, 7.67, 8.42, 9.43, 10.09, 10.76, 11.24, 11.41, 11.14, 10.41, 9.64, 9.02, 8.17, 5.92, 5.33,
  4.89, 4.29, 3.98, 3.62, 3.47, 3.28, 3.36, 3.68, 4.31, 5.18, 7.74, 8.49, 9.49, 10.12, 10.79, 11.27,
  11.42, 11.1, 10.4, 9.66, 9.1, 8.23, 5.97, 5.36, 4.92, 4.33, 4.0, 3.66, 3.45, 3.3, 3.4, 3.77, 4.42,
  5.64, 7.74, 8.43, 9.28, 9.84, 10.38, 10.71, 10.79, 10.48, 9.87, 9.17, 8.57, 7.55, 5.66, 5.14,
  4.68, 4.2, 3.89, 3.58, 3.41, 3.27, 3.39, 3.8, 4.47, 5.69, 7.88, 8.6, 9.54, 10.19, 10.82, 11.23,
  11.33, 11.0, 10.33, 9.55, 8.86, 7.78, 5.83, 5.25, 4.76, 4.21, 3.88, 3.57, 3.22, 3.19, 3.19, 3.25,
  3.42, 4.12, 5.59, 5.59, 5.92, 6.45, 7.15, 7.76, 8.13, 8.22, 8.1, 7.62, 7.19, 6.42, 4.67, 4.25,
  3.93, 3.68, 3.46, 3.3, 3.04, 3.04, 3.04, 3.06, 3.14, 3.27, 3.3, 2.99, 3.04, 3.17, 3.4, 3.55, 3.7,
  3.72, 3.71, 3.65, 3.74, 3.79, 3.67, 3.49, 3.29, 3.22, 3.15, 3.08, 3.51, 3.29, 3.37, 3.72, 4.36,
  5.24, 7.68, 8.46, 9.43, 10.04, 10.65, 11.11, 11.27, 11.01, 10.34, 9.6, 8.99, 8.16, 5.92, 5.34,
  4.9, 4.31, 3.98, 3.63, 3.47, 3.26, 3.35, 3.7, 4.33, 5.2, 7.67, 8.42, 9.43, 10.09, 10.76, 11.24,
  11.41, 11.14, 10.41, 9.64, 9.02, 8.17, 5.92, 5.33, 4.89, 4.29, 3.98, 3.62, 3.47, 3.28, 3.36, 3.68,
  4.31, 5.18, 7.74, 8.49, 9.49, 10.12, 10.79, 11.27, 11.42, 11.1, 10.4, 9.66, 9.1, 8.23, 5.97, 5.36,
  4.92, 4.33, 4.0, 3.66, 3.45, 3.3, 3.4, 3.77, 4.42, 5.64, 7.74, 8.43, 9.28, 9.84, 10.38, 10.71,
  10.79, 10.48, 9.87, 9.17, 8.57, 7.55, 5.66, 5.14, 4.68, 4.2, 3.89, 3.58, 3.41, 3.27, 3.39, 3.8,
  4.47, 5.69, 7.88, 8.6, 9.54, 10.19, 10.82, 11.23, 11.33, 11.0, 10.33, 9.55, 8.86, 7.78, 5.83,
  5.25, 4.76, 4.21, 3.88, 3.57, 3.22, 3.19, 3.19, 3.25, 3.42, 4.12, 5.59, 5.59, 5.92, 6.45, 7.15,
  7.76, 8.13, 8.22, 8.1, 7.62, 7.19, 6.42, 4.67, 4.25, 3.93, 3.68, 3.46, 3.3, 3.04, 3.04, 3.04,
  3.06, 3.14, 3.27, 3.3, 2.99, 3.04, 3.17, 3.4, 3.55, 3.7, 3.72, 3.71, 3.65, 3.74, 3.79, 3.67, 3.49,
  3.29, 3.22, 3.15, 3.08, 3.51, 3.29, 3.37, 3.72, 4.36, 5.24, 7.68, 8.46, 9.43, 10.04, 10.65, 11.11,
  11.27, 11.01, 10.34, 9.6, 8.99, 8.16, 5.92, 5.34, 4.9, 4.31, 3.98, 3.63, 3.47, 3.26, 3.35, 3.7,
  4.33, 5.2, 7.67, 8.42, 9.43, 10.09, 10.76, 11.24, 11.41, 11.14, 10.41, 9.64, 9.02, 8.17, 5.92,
  5.33, 4.89, 4.29, 3.98, 3.62, 3.47, 3.28, 3.36, 3.68, 4.31, 5.18, 7.74, 8.49, 9.49, 10.12, 10.79,
  11.27, 11.42, 11.1, 10.4, 9.66, 9.1, 8.23, 5.97, 5.36, 4.92, 4.33, 4.0, 3.66, 3.45, 3.3, 3.4,
  3.77, 4.42, 5.64, 7.74, 8.43, 9.28, 9.84, 10.38, 10.71, 10.79, 10.48, 9.87, 9.17, 8.57, 7.55,
  5.66, 5.14, 4.68, 4.2, 3.89, 3.58, 3.41, 3.27, 3.39, 3.8, 4.47, 5.69, 7.88, 8.6, 9.54, 10.19,
  10.82, 11.23, 11.33, 11.0, 10.33, 9.55, 8.86, 7.78, 5.83, 5.25, 4.76, 4.21, 3.88, 3.57, 3.22,
  3.19, 3.19, 3.25, 3.42, 4.12, 5.59, 5.59, 5.92, 6.45, 7.15, 7.76, 8.13, 8.22, 8.1, 7.62, 7.19,
  6.42, 4.67, 4.25, 3.93, 3.68, 3.46, 3.3, 3.14, 3.14, 3.14, 3.15, 3.22, 3.29, 3.54, 3.12, 3.03,
  3.09, 3.31, 3.42, 3.58, 3.6, 3.6, 3.56, 3.84, 3.79, 3.7, 3.57, 3.37, 3.29, 3.25, 3.18, 3.6, 3.39,
  3.49, 3.86, 4.48, 5.31, 7.82, 8.44, 9.26, 9.79, 10.36, 10.74, 10.87, 10.62, 9.98, 9.28, 8.81, 8.0,
  5.8, 5.26, 4.86, 4.33, 4.03, 3.7, 3.55, 3.37, 3.49, 3.86, 4.5, 5.34, 7.88, 8.49, 9.33, 9.84,
  10.37, 10.7, 10.79, 10.54, 9.89, 9.2, 8.81, 8.01, 5.82, 5.28, 4.88, 4.37, 4.09, 3.75, 3.62, 3.43,
  3.53, 3.92, 4.57, 5.39, 7.88, 8.51, 9.3, 9.8, 10.32, 10.62, 10.68, 10.44, 9.81, 9.14, 8.76, 7.99,
  5.84, 5.3, 4.89, 4.38, 4.07, 3.75, 3.61, 3.42, 3.47, 3.77, 4.34, 5.17, 7.66, 8.27, 9.06, 9.56,
  10.09, 10.39, 10.51, 10.27, 9.69, 9.05, 8.68, 7.93, 5.78, 5.27, 4.89, 4.41, 4.11, 3.77, 3.64,
  3.46, 3.55, 3.93, 4.59, 5.41, 7.9, 8.53, 9.34, 9.86, 10.37, 10.67, 10.77, 10.55, 9.92, 9.26, 8.87,
  8.05, 5.86, 5.31, 4.91, 4.39, 4.1, 3.77, 3.38, 3.37, 3.38, 3.41, 3.57, 3.8, 5.81, 5.68, 5.81,
  6.18, 6.78, 7.37, 7.78, 7.87, 7.83, 7.48, 7.27, 6.88, 4.76, 4.33, 4.02, 3.79, 3.57, 3.4, 3.14,
  3.14, 3.14, 3.15, 3.22, 3.29, 3.54, 3.12, 3.03, 3.09, 3.31, 3.42, 3.58, 3.6, 3.6, 3.56, 3.84,
  3.79, 3.7, 3.57, 3.37, 3.29, 3.25, 3.18, 3.6, 3.39, 3.49, 3.86, 4.48, 5.31, 7.82, 8.44, 9.26,
  9.79, 10.36, 10.74, 10.87, 10.62, 9.98, 9.28, 8.81, 8.0, 5.8, 5.26, 4.86, 4.33, 4.03, 3.7, 3.55,
  3.37, 3.49, 3.86, 4.5, 5.34, 7.88, 8.49, 9.33, 9.84, 10.37, 10.7, 10.79, 10.54, 9.89, 9.2, 8.81,
  8.01, 5.82, 5.28, 4.88, 4.37, 4.09, 3.75, 3.62, 3.43, 3.53, 3.92, 4.57, 5.39, 7.88, 8.51, 9.3,
  9.8, 10.32, 10.62, 10.68, 10.44, 9.81, 9.14, 8.76, 7.99, 5.84, 5.3, 4.89, 4.38, 4.07, 3.75, 3.61,
  3.42, 3.47, 3.77, 4.34, 5.17, 7.66, 8.27, 9.06, 9.56, 10.09, 10.39, 10.51, 10.27, 9.69, 9.05,
  8.68, 7.93, 5.78, 5.27, 4.89, 4.41, 4.11, 3.77, 3.64, 3.46, 3.55, 3.93, 4.59, 5.41, 7.9, 8.53,
  9.34, 9.86, 10.37, 10.67, 10.77, 10.55, 9.92, 9.26, 8.87, 8.05, 5.86, 5.31, 4.91, 4.39, 4.1, 3.77,
  3.38, 3.37, 3.38, 3.41, 3.57, 3.8, 5.81, 5.68, 5.81, 6.18, 6.78, 7.37, 7.78, 7.87, 7.83, 7.48,
  7.27, 6.88, 4.76, 4.33, 4.02, 3.79, 3.57, 3.4, 3.14, 3.14, 3.14, 3.15, 3.22, 3.29, 3.54, 3.12,
  3.03, 3.09, 3.31, 3.42, 3.58, 3.6, 3.6, 3.56, 3.84, 3.79, 3.7, 3.57, 3.37, 3.29, 3.25, 3.18, 3.6,
  3.39, 3.49, 3.86, 4.48, 5.31, 7.82, 8.44, 9.26, 9.79, 10.36, 10.74, 10.87, 10.62, 9.98, 9.28,
  8.81, 8.0, 5.8, 5.26, 4.86, 4.33, 4.03, 3.7, 3.55, 3.37, 3.49, 3.86, 4.5, 5.34, 7.88, 8.49, 9.33,
  9.84, 10.37, 10.7, 10.79, 10.54, 9.89, 9.2, 8.81, 8.01, 5.82, 5.28, 4.88, 4.37, 4.09, 3.75, 3.62,
  3.43, 3.53, 3.92, 4.57, 5.39, 7.88, 8.51, 9.3, 9.8, 10.32, 10.62, 10.68, 10.44, 9.81, 9.14, 8.76,
  7.99, 5.84, 5.3, 4.89, 4.38, 4.07, 3.75, 3.61, 3.42, 3.47, 3.77, 4.34, 5.17, 7.66, 8.27, 9.06,
  9.56, 10.09, 10.39, 10.51, 10.27, 9.69, 9.05, 8.68, 7.93, 5.78, 5.27, 4.89, 4.41, 4.11, 3.77,
  3.64, 3.46, 3.55, 3.93, 4.59, 5.41, 7.9, 8.53, 9.34, 9.86, 10.37, 10.67, 10.77, 10.55, 9.92, 9.26,
  8.87, 8.05, 5.86, 5.31, 4.91, 4.39, 4.1, 3.77, 3.38, 3.37, 3.38, 3.41, 3.57, 3.8, 5.81, 5.68,
  5.81, 6.18, 6.78, 7.37, 7.78, 7.87, 7.83, 7.48, 7.27, 6.88, 4.76, 4.33, 4.02, 3.79, 3.57, 3.4,
  3.14, 3.14, 3.14, 3.15, 3.22, 3.29, 3.54, 3.12, 3.03, 3.09, 3.31, 3.42, 3.58, 3.6, 3.6, 3.56,
  3.84, 3.79, 3.7, 3.57, 3.37, 3.29, 3.25, 3.18, 3.6, 3.39, 3.49, 3.86, 4.48, 5.31, 7.82, 8.44,
  9.26, 9.79, 10.36, 10.74, 10.87, 10.62, 9.98, 9.28, 8.81, 8.0, 5.8, 5.26, 4.86, 4.33, 4.03, 3.7,
  3.55, 3.37, 3.49, 3.86, 4.5, 5.34, 7.88, 8.49, 9.33, 9.84, 10.37, 10.7, 10.79, 10.54, 9.89, 9.2,
  8.81, 8.01, 5.82, 5.28, 4.88, 4.37, 4.09, 3.75, 3.62, 3.43, 3.53, 3.92, 4.57, 5.39, 7.88, 8.51,
  9.3, 9.8, 10.32, 10.62, 10.68, 10.44, 9.81, 9.14, 8.76, 7.99, 5.84, 5.3, 4.89, 4.38, 4.07, 3.75,
  3.61, 3.42, 3.47, 3.77, 4.34, 5.17, 7.66, 8.27, 9.06, 9.56, 10.09, 10.39, 10.51, 10.27, 9.69,
  9.05, 8.68, 7.93, 5.78, 5.27, 4.89, 4.41, 4.11, 3.77, 3.64, 3.46, 3.55, 3.93, 4.59, 5.41, 7.9,
  8.53, 9.34, 9.86, 10.37, 10.67, 10.77, 10.55, 9.92, 9.26, 8.87, 8.05, 5.86, 5.31, 4.91, 4.39, 4.1,
  3.77, 3.38, 3.37, 3.38, 3.41, 3.57, 3.8, 5.81, 5.68, 5.81, 6.18, 6.78, 7.37, 7.78, 7.87, 7.83,
  7.48, 7.27, 6.88, 4.76, 4.33, 4.02, 3.79, 3.57, 3.4, 3.14, 3.14, 3.14, 3.15, 3.22, 3.29, 3.54,
  3.12, 3.03, 3.09, 3.31, 3.42, 3.58, 3.6, 3.6, 3.56, 3.84, 3.79, 3.7, 3.57, 3.37, 3.29, 3.25, 3.18,
  3.6, 3.39, 3.49, 3.86, 4.48, 5.31, 7.82, 8.44, 9.26, 9.79, 10.36, 10.74, 10.87, 10.62, 9.98, 9.28,
  8.81, 8.0, 5.8, 5.26, 4.86, 4.33, 4.03, 3.7, 3.55, 3.37, 3.49, 3.86, 4.5, 5.34, 7.88, 8.49, 9.33,
  9.84, 10.37, 10.7, 10.79, 10.54, 9.89, 9.2, 8.81, 8.01, 5.82, 5.28, 4.88, 4.37, 4.09, 3.75,
];

export default outpatientLoadProfile;
