const calcMilesPerYear = (vehicleSet) => {
  const {workdays, workmonths, milesPerWorkday, vehicleCount} = vehicleSet;

  const milesPerVehiclePerYear =
    milesPerWorkday * 365.25 * (workdays.length / 7) * (workmonths.length / 12);

  return vehicleCount * milesPerVehiclePerYear;
};

export default calcMilesPerYear;
