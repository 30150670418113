import React from 'react';
import {Helmet} from 'react-helmet-async';
import {useIntl} from 'react-intl';
import heroImage from '@generic/assets/images/ev-plug.jpeg';

export default function Seo() {
  const {formatMessage} = useIntl();

  return (
    <Helmet>
      <title>{formatMessage({id: 'seo.title'})}</title>
      <meta name="author" content={formatMessage({id: 'seo.author'})} />
      <meta name="description" content={formatMessage({id: 'seo.description'})} />
      <meta property="og:type" content="website" />
      <meta name="title" property="og:title" content={formatMessage({id: 'seo.title'})} />
      <meta
        name="description"
        property="og:description"
        content={formatMessage({id: 'seo.description'})}
      />
      <meta name="image" property="og:image" content={heroImage} />
      <meta property="article:published_time" content="2021-12-28" />
    </Helmet>
  );
}
