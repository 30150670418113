import React, {useState} from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import {Menu, MenuItem, IconButton, makeStyles} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import printInputs from '../../ConEd/functions/printInputs';

const useStyles = makeStyles(() => ({
  menuItem: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  menuItemLink: {
    textDecoration: 'none',
  },
}));

export default function HeaderDropdownNav() {
  const history = useHistory();
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleMenuIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (to) => {
    handleClose();
    history.push(to);
  };
  const inputs = useInputs();

  return (
    <>
      <IconButton
        id="menu-button"
        aria-controls="nav-menu"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        aria-label={formatMessage({id: 'header.menuLabel'})}
        onClick={handleMenuIconClick}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="nav-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
      >
        <MenuItem className={classes.menuItem} onClick={() => handleMenuItemClick('/fleets')}>
          {formatMessage({id: 'header.links.charging'})}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleMenuItemClick('/sites')}>
          {formatMessage({id: 'header.links.sitePlanner'})}
        </MenuItem>
        <a
          target="_blank"
          rel="noreferrer"
          className={classes.menuItemLink}
          href={formatMessage(
            {id: 'emailAnchorLink'},
            {
              inputs: printInputs(inputs),
            }
          )}
        >
          <MenuItem className={classes.menuItem}>
            {formatMessage({id: 'header.links.emailUs'})}
          </MenuItem>
        </a>
      </Menu>
    </>
  );
}
