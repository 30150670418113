import React from 'react';
import {Typography, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import ChargePanel from '@generic/components/ChargePanel';
import mean from 'lodash/mean';
import {formatAsThousands, formatAsDollars} from '@generic/functions/formatters';

const useStyles = makeStyles(({palette}) => ({
  discountText: {
    color: palette.success.main,
    fontWeight: 'bold',
  },
}));

export default function RateChargePanel({
  rateCalculator,
  loadProfile,
  rateTitle,
  rateComponent,
  sccRebate,
}) {
  const {formatMessage} = useIntl();
  const {demand: sccRebateDemand, energy: sccRebateEnergy} = sccRebate;
  const classes = useStyles();

  const averageMonthlyEnergy = Math.round(mean(loadProfile.sumByMonth()));
  const annualMaxDemand = Math.round(loadProfile.max());
  const averageMonthlyDemand = Math.round(mean(loadProfile.maxByMonth()));

  const annualFixedCost = rateCalculator.annualCost({classifications: ['fixed']});
  const annualDemandCost = rateCalculator.annualCost({classifications: ['demand']});
  const annualEnergyCost = rateCalculator.annualCost({classifications: ['energy']});

  const monthlyFixedCost = annualFixedCost / 12;
  const monthlyDemandCost = (annualDemandCost - sccRebateDemand) / 12;
  const monthlyEnergyCost = (annualEnergyCost - sccRebateEnergy) / 12;

  const panels = [
    {
      id: 'fixed',
      description: formatMessage(
        {id: 'charging.content.costs.electricity.rateComponents.fixed.description'},
        {rateTitle: <b>{rateTitle}</b>}
      ),
      totalLabel: formatMessage({
        id: 'charging.content.costs.electricity.rateComponents.fixed.totalLabel',
      }),
      total: formatAsDollars(monthlyFixedCost),
    },
    {
      id: 'demand',
      description: formatMessage(
        {id: 'charging.content.costs.electricity.rateComponents.demand.description'},
        // eslint-disable-next-line @calm/react-intl/missing-formatted-message
        {annualDemand: <b>{annualMaxDemand} kW</b>, monthlyDemand: <b>{averageMonthlyDemand} kW</b>}
      ),
      totalLabel: formatMessage({
        id: 'charging.content.costs.electricity.rateComponents.demand.totalLabel',
      }),
      total: formatAsDollars(monthlyDemandCost),
      discountDescription:
        sccRebateDemand > 0 &&
        formatMessage(
          {id: 'charging.content.costs.electricity.rateComponents.sccRebateDiscount.description'},
          {
            sccRebate: (
              <Typography component="span" className={classes.discountText}>
                {formatAsDollars(sccRebateDemand / 12)}
              </Typography>
            ),
          }
        ),
    },
    {
      id: 'energy',
      description: formatMessage(
        {id: 'charging.content.costs.electricity.rateComponents.energy.description'},
        // eslint-disable-next-line @calm/react-intl/missing-formatted-message
        {totalUsage: <b>{formatAsThousands(averageMonthlyEnergy)} kWh</b>}
      ),
      totalLabel: formatMessage({
        id: 'charging.content.costs.electricity.rateComponents.energy.totalLabel',
      }),
      total: formatAsDollars(monthlyEnergyCost),
      discountDescription:
        sccRebateEnergy > 0 &&
        formatMessage(
          {id: 'charging.content.costs.electricity.rateComponents.sccRebateDiscount.description'},
          {
            sccRebate: (
              <Typography component="span" className={classes.discountText}>
                {formatAsDollars(sccRebateEnergy / 12)}
              </Typography>
            ),
          }
        ),
    },
  ];

  const {description, totalLabel, total, discountDescription} =
    panels.find((panel) => panel.id === rateComponent) || panels[0];

  return (
    <ChargePanel
      description={description}
      total={total}
      totalLabel={totalLabel}
      discountDescription={discountDescription}
    />
  );
}
