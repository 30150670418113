import {makeStyles, Typography, Box} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import FooterLinks from '@generic/components/FooterLinks';
import SecondaryFooter from '@generic/components/SecondaryFooter';
import printInputs from '../functions/printInputs';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gray[300],
  },
  contactContainer: {
    padding: '0 10px 40px 10px',
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  contactContainerTop: {
    padding: '40px 10px 0 10px',
  },
  contactLink: {
    backgroundColor: theme.palette.primary.main,
    padding: '20px 80px',
    textDecoration: 'none',
    textAlign: 'center',
    borderRadius: '86px',
    transition: 'all 200ms ease',

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  contactLinkText: {
    color: theme.palette.common.white,
    fontSize: '1.125rem',
    fontFamily: theme.typography.primary.fontFamily,
    fontWeight: 600,
  },
  helpText: {
    fontWeight: 600,
    fontSize: '1.25rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
}));

export default function Footer() {
  const {formatMessage} = useIntl();
  const inputs = useInputs();
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Box display="flex" justifyContent="center" className={classes.contactContainerTop}>
        <Typography className={classes.helpText}>
          {formatMessage({id: 'footer.helpText'})}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" className={classes.contactContainer}>
        <a
          href={formatMessage(
            {id: 'footer.emailAnchorLink'},
            {
              inputs: printInputs(inputs),
            }
          )}
          className={classes.contactLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography className={classes.contactLinkText}>
            {formatMessage({id: 'footer.contactLink'})}
          </Typography>
        </a>
      </Box>
      <FooterLinks />
      <SecondaryFooter />
    </footer>
  );
}
