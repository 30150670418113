const DCFC_DEMAND_THRESHOLD = 50;

const isEligibleForDemandChargeRebate = ({
  dcfcLoadProfile,
  siteAdditionalQualifications,
  maxDemand,
}) => {
  const dcfcMaxDemand = dcfcLoadProfile.max();
  const percentDcfcLoad = (dcfcMaxDemand / maxDemand) * 100;

  const hasEnoughDcfcLoad = percentDcfcLoad >= DCFC_DEMAND_THRESHOLD;
  const publiclyAvailable = siteAdditionalQualifications === 'publiclyAvailable';

  return publiclyAvailable && hasEnoughDcfcLoad;
};

export default isEligibleForDemandChargeRebate;
