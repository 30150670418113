import times from 'lodash/times';
import moment from 'moment-timezone';

const translations = {
  en: {
    translation: {
      en: 'English',
      header: {
        name: 'Commercial Fleets',
        links: {
          home: 'Home',
          charging: 'Fleet Electrification',
          sitePlanner: 'Site Electrification',
          emailUs: 'Email Us!',
        },
        language: 'Language:',
        menuLabel: 'Menu',
      },
      footer: {
        contactLink: 'Contact a Representative',
        expand: 'Expand',
        collapse: 'Collapse',
        links: {
          chargingCalculator: {
            header: 'Charging Calculator',
            home: 'Charging Calculators',
            fleetElectrification: 'Fleet Electrification Calculator',
            sitePlanner: 'Site Electrification Calculator',
          },
          site: {
            header: 'ZappyRide',
            mainSite: 'Main Site',
            electricVehicles: 'Electric Vehicle Incentives',
          },
          social: {
            header: 'Social',
            facebook: 'Facebook',
            facebookLink: 'https://www.facebook.com',
            twitter: 'Twitter',
            twitterLink: 'https://www.twitter.com',
            youtube: 'YouTube',
            youtubeLink: 'https://www.youtube.com',
            linkedIn: 'LinkedIn',
            linkedInLink: 'https://www.linkedin.com',
          },
        },
        secondary: {
          poweredByLink: 'Powered by {linkTag}',
          zappyRide: 'ZappyRide',
          links: {
            privacyPolicy: 'Privacy Policy',
            privacyPolicyLink: '/',
            accessibilityPolicy: 'Accessibility Policy',
            accessibilityPolicyLink: '/',
            termsOfService: 'Terms of Service',
            termsOfServiceLink: '/',
            homeNet: 'Home Net',
            homeNetLink: '/',
          },
          copyright: '© 2021 ZappyRide All Rights Reserved.',
        },
      },
      seo: {
        title: 'ZappyRide Fleets',
        author: 'ZappyRide',
        description: 'Plan your commercial EV project.',
      },
      home: {
        header: 'Commercial Fleets Template',
        genericExplainer: {
          first:
            'This is the "base" version of a Fleets tool. It demonstrates all features and pages available within a Fleets tool created in this system',
          second:
            'When a new client project is created via npm run create:client, it will look identical to this. However, components, translations, the Material UI theme, and any file can be overridden just by creating a file in the client folder with the same path as the file in the Generic folder.',
          third:
            'This is referred to as "ejecting" a file. There are helper scripts available to eject components. The script simply copies an existing file from the Generic folder into a client folder, but it is convenient when needed!',
          fourth:
            'The create:client script will eject a few components and files by default. These are things like the Header, Footer, Homepage, and translations which are certain to vary from client to client.',
          fifth:
            'This flexibility provided by ejecting components is accomplished using custom resolvers in Webpack. When importing a file from `@generic`, Webpack will look first in the current directory, before falling back to searching the Generic directory',
          sixth:
            'Besides the import trickery, the rest of the system follows existing React, Material UI, and React-intl patterns.',
        },
        nav: {
          header: 'What type of commercial EV charging project are you planning?',
          charging: {
            button: 'Start With Your Fleet',
            subtitle: 'Transition your light, medium, or heavy-duty vehicle fleet to electric.',
          },
          planning: {
            button: 'Start With Your Site',
            subtitle: 'Add EV chargers to your commercial or multifamily property, or parking lot.',
          },
        },
      },
      charging: {
        header: 'Fleet Electrification',
        insights: {
          savings: {
            annualFuel: 'Annual Fuel Savings',
            perMile: 'Fuel Savings Per Mile',
          },
          costs: {
            evFuel: 'Annual EV Fuel Costs',
            fossilFuel: 'Fossil Fuel Costs',
          },
          count: {
            chargers: 'Total Chargers',
            ports: 'Ports Available',
          },
        },
        inputs: {
          vehicleSets: {
            title: 'Vehicle Type',
            addNewButton: '+ Add new vehicle type',
            editButton: 'Edit vehicle set',
          },
          fuel: {
            title: 'Fuel',
            gasSliderLabel: 'Gasoline Price:',
            dieselSliderLabel: 'Diesel Price:',
          },
          advanced: {
            title: 'Additional Information',
            monthlyBillSliderLabel: 'Monthly Electric Bill:',
            monthlyBillSliderTooltip:
              "Enter the typical electric bill you pay in an average month. We use this in combination with your building type and current rate to estimate your building's typical energy consumption.",
            zipCode: {
              title: 'Zip Code',
            },
            isOnMarketBasedRate: {
              title: 'Day-Ahead Hourly Rate',
              tooltip:
                'Day-Ahead Hourly Rates are applied on a mandatory basis when the maximum monthly demand is >500 kW and on a voluntary basis otherwise.',
              sourceLink: 'Learn more',
            },
            separatelyMetered: {
              title: 'Separately Metered Chargers',
              tooltip:
                'Selecting Yes will estimate the electric bill for the chargers alone. Selecting No will estimate the electric bill for the chargers and the building combined.',
            },
            voltageLevel: {
              title: 'Voltage Level',
              tooltip:
                'Most customers receive service on Low Tension, which is for service at 460V or below.',
              high: 'High',
              low: 'Low',
            },
            buildingType: {
              title: 'Building Type',
              tooltip: "We use this to estimate your building's energy usage pattern.",
              outpatient: 'Outpatient',
              smallHotel: 'Small Hotel',
              secondarySchool: 'Secondary School',
              warehouse: 'Warehouse',
              stripMall: 'Strip Mall',
              primarySchool: 'Primary School',
              standaloneRetail: 'Standalone Retail',
              smallOffice: 'Small Office',
              quickServiceRestaurant: 'Quick Service Restaurant',
              largeHotel: 'Large Hotel',
              largeOffice: 'Large Office',
              mediumOffice: 'Medium Office',
              fullServiceRestaurant: 'Full Service Restaurant',
            },
            currentRate: {
              title: 'Current Rate',
              tooltip: 'Your current rate is used to estimate your monthly electricity costs.',
              sc9_1: 'SC9 Rate I',
              sc9_2: 'SC9 Rate II',
              sc9_3: 'SC9 Rate III',
              sc9_4: 'SC9 Rate IV',
              sc9_5: 'SC9 Rate V',
            },
          },
          adjustFilters: 'Adjust Filters',
        },
        errors: {
          conflictingChargingWindows:
            'Warning! Multiple chargers are in use at once for {vehicles}.',
          depletedBatteries:
            'Warning! The following vehicles deplete their batteries at some point during the week: {vehicles}.',
          depletedBatteriesSubheader:
            'Please add more charging times, increase the charging level(s), and/or decrease the daily miles.',
        },
        content: {
          tabs: {
            overviewLabel: 'Overview',
            vehiclesLabel: 'Vehicles',
            chargersLabel: 'Chargers',
            costsLabel: 'Costs',
            electricityCostsLabel: 'Costs - Electricity',
            fuelCostsLabel: 'Costs - Fuel',
            maintenanceCostsLabel: 'Costs - Maintenance',
          },
          overview: {
            subtitle:
              'Compare the cost of charging your electric fleet with the cost of fueling equivalent gas vehicles.',
            yearlyCostsHeader: 'Yearly Fuel Costs',
            yearlyCosts:
              'Based on your selection, your electric fleet will cost {costDifference} {moreOrLess} per year to fuel than its fossil fuel equivalent.',
            chart: {
              electricFleet: 'Electric Vehicle Fleet',
              yourSelection: '(Your selection)',
              fossilFuelFleet: 'Gasoline Fleet',
              similarVehicle: '(Similar gas vehicle)',
              facility: 'Facility',
              vehicles: 'Vehicles',
              total: 'Total',
            },
            fleetSummary: {
              electricHeader: 'Electric Fleet',
              fossilFuelHeader: 'Equivalent Fossil Fuel Fleet',
              fuelCost: 'Fuel Cost',
              maintenanceCost: 'Maintenance Cost',
              annualElectricCost: 'Annual Electricity Cost',
              electricPerMile: 'Electricity Cost Per Mile',
              annualFossilFuelCost: 'Annual Fossil Fuel Cost',
              fossilFuelPerMile: 'Fossil Fuel Cost Per Mile',
              annualMaintenanceCost: 'Total Annual Maintenance Cost',
              maintenancePerMile: 'Maintenance Cost Per Mile',
            },
          },
          vehicles: {
            vehicleSpecsTitle: 'Vehicle Specs',
            specs: {
              range: {
                label: 'Range',
                value: '{rangeInMiles} miles',
              },
              batteryCapacity: {
                label: 'Battery Capacity',
                value: '{batteryCapacityInKwh} kWh',
              },
              efficiency: {
                label: 'Efficiency',
                value: '{efficiencyInKwhPerHundredMiles} kWh per 100 miles',
              },
              weight: {
                label: 'Gross Vehicle Weight',
                value: '{gvwr} lbs',
              },
              equivGasMpg: {
                label: 'Equivalent Fossil Fuel MPG',
                value: '{fossilMilesPerGallon} miles per gallon ({fossilFuelType})',
              },
              workdays: {
                label: 'Workdays',
                value: '{workdays}',
              },
              typicalWorkdays: {
                label: 'Miles Per Workday',
                value: '{milesPerWorkday} miles',
              },
              chargingWindows: {
                time: '{start} - {finish}',
                label: 'Charging window(s)',
                approach: 'Approach: {chargingApproach}',
              },
            },
          },
          chargers: {
            header: 'Charging and Facility Needs',
            subheader: `Chargers are most profitable if they can be shared by vehicles. It looks like you'll need at least the following equipment at your facility to charge your fleet`,
            labels: {
              chargerType: 'Charger Type',
              vehicle: 'Vehicle',
              timeRange: 'Time Range',
            },
            chart: {
              title: 'Minimum Charging Equipment',
              selectLabel: 'Select Charger',
              label: 'Hourly Charger Use',
            },
          },
          costs: {
            tabs: {
              electricityLabel: 'Electricity',
              fuelLabel: 'Fuel',
              maintenanceLabel: 'Maintenance',
            },
            fuel: {
              header: 'Fossil Fuel Costs',
              subheader:
                'Your cost is determined by applying the cost of gasoline and/or diesel to driving an equivalent internal combustion engine vehicle.',
              table: {
                ariaLabel: 'Fuel costs table',
                headers: {
                  vehicle: 'Vehicle',
                  count: 'Count',
                  efficiency: 'Efficiency (MPG)',
                  miles: 'Miles',
                  fuelCost: 'Fuel Cost ($/Gal)',
                  cost: 'Cost',
                },
                total: 'Total',
              },
            },
            maintenance: {
              header: 'Maintenance',
              subheader:
                'The cost is calculated as a function of the years of ownership, miles driven annually, and whether it is an electric or internal combustion engine vehicle. Note that each vehicle has its own maintenance cost per mile, and it is assumed that the vehicle lifetime is 12 years.',
              chart: {
                label: 'Total Maintenance Costs',
                electricLabel: 'Electric Vehicles',
                fossilLabel: 'Fossil Vehicles',
              },
            },
            electricity: {
              rateBreakdown: {
                title: 'Rate breakdown',
                cheapestRateTitle: '{rateTitle} is the best rate option for you.',
                ev: 'Vehicles',
                fossilFuelVehicles: 'Fossil Fuel Vehicles',
                facility: 'Facility',
              },
              rateComponents: {
                fixed: {
                  title: 'Fixed',
                  description:
                    'Your monthly fixed costs for {rateTitle} include the minimum bill and taxes and surcharges.',
                  totalLabel: 'Typical Monthly Fixed Cost',
                },
                demand: {
                  title: 'Demand',
                  description:
                    'Your annual max demand is {annualDemand}, and you average {monthlyDemand} max demand per month.',
                  totalLabel: 'Typical Monthly Demand Cost',
                },
                energy: {
                  title: 'Energy',
                  description:
                    'You use a total of {totalUsage} in a typical month. Note that prices vary by the time of the day and month.',
                  totalLabel: 'Typical Monthly Energy Cost',
                },
              },
              demandCharge:
                "Your demand is {demandAmount} and {rateTitle}'s demand charges total {demandRate} which results in a demand charge of",
              monthlyDemand: {
                header: 'You can reduce demand costs by having a lower monthly peak.',
                subheader: 'Your maximum demand looks like this:',
                selectLabel: 'Weekday selected',
              },
              loadProfile: {
                header: 'You can reduce energy costs by consuming less and charging over night.',
                subheader: 'Your load profile (the energy you consume) looks like this:',
                selectLabel: 'Time Range:',
              },
              hourlyRate: {
                header:
                  'You can reduce energy costs by consuming less energy, especially during high-priced periods.',
                subheader:
                  'A portion of your bill is based on market prices, which vary hour-by-hour.',
                chartTitle: 'Hourly Energy Rate ($/kWh)',
              },
              hideRateDetails: 'Hide Rate Details',
              showRateDetails: 'Show Rate Details',
              bill: {
                header: 'Your Electricity Breakdown',
                billingPeriod: 'Your estimated average monthly bill',
                supplyCharges: {
                  title: 'Your Supply Charges',
                  total: 'Total supply charges',
                  fixedLabel: 'Fixed Monthly Minimum Charge',
                },
                deliveryCharges: {
                  title: 'Your Delivery Charges',
                  total: 'Total delivery charges',
                  fixedLabel: 'Fixed Monthly Minimum Charge',
                },
                taxesLabel: 'Sales tax of 8.75%',
                taxesNotIncluded: 'Not Included Here',
                totalDemand: 'Total Estimated Demand Use',
                totalTaxes: 'Total sales tax',
                total: 'Total electricity charges',
                supplyChargesRateDisclaimer:
                  'Your total electricity supply cost for this bill is {cost}¢ per kWh. You can compare this price with those offered by energy services companies.',
                disclaimer:
                  'This information is an estimate of your annual electricity costs based on the information provided and general assumptions. The purpose of the information is to help guide you in preliminary planning for your commercial EV charging station. Actual totals may vary.',
              },
            },
          },
        },
      },
      planning: {
        header: 'Charging Site Electrification',
        inputs: {
          chargers: {
            title: 'Chargers',
            addNewButton: '+ Add another charger',
            editButton: 'Edit charger set',
          },
          adjustFilters: 'Adjust Filters',
        },
        content: {
          tabs: {
            overviewLabel: 'Overview',
            chargersLabel: 'Chargers',
            electricityLabel: 'Electricity',
          },
          overview: {
            header: 'Public Charging',
            subtitle:
              'Determine the electric bill impacts of providing charging stations at your property.',
            yearlyCostsHeader: 'Yearly Electricity Costs',
            yearlyCosts:
              'Based on your selection, your electric fleet will cost {costDifference} {moreOrLess} per year to fuel than its fossil fuel equivalent.',
            chart: {
              electricity: 'Electricity',
              yourSelection: '(Your selection)',
            },
            electricitySummary: {
              title: 'Electricity',
              fuelHeader: 'Fuel Cost',
              maintenanceHeader: 'Maintenance Cost',
              annualElectricCost: 'Annual Electric Cost',
              perMileElectricCost: 'Electricity Cost Per Mile',
              annualMaintenanceCost: 'Total Annual Maintenance Cost',
              perMileMaintenanceCost: 'Maintenance Cost Per Mile',
            },
          },
          chargers: {
            header: 'Charger Specs',
            data: {
              type: 'Type',
              powerLevel: 'Power Level',
              portCount: 'Port Count',
              count: 'Count',
              estimatedCost: 'Estimated Unit Cost',
            },
          },
          electricity: {
            rateBreakdown: {
              cheapestRateTitle: '{rateTitle} is the best rate option for you.',
              chargers: 'Chargers',
              facility: 'Facility',
            },
            chargePanels: {
              fixedCosts: "Fixed costs are constant, and {rateTitle}'s fixed costs total",
              demandCosts:
                "Your demand is {demandAmount} and {rateTitle}'s demand charges total {demandRate} which results in a demand charge of",
              totalCosts:
                'Your total energy is {totalEnergy} and {rateTitle} has various energy charges that include some variability. In total, the energy portion of your bill would total',
            },
            loadProfile: {
              header:
                'You can reduce energy costs by consuming less and charging during overnight time periods.',
              subheader:
                'Your current EV charger load profile (the energy you consume) looks like this:',
              selectLabel: 'Time Range:',
            },
            monthlyBill: {
              header: 'Your itemized typical monthly bill would be:',
            },
            hideRateDetails: 'Hide Rate Details',
            showRateDetails: 'Show Rate Details',
          },
        },
      },
      tabs: {
        intro: 'View:',
      },
      loremIpsum: {
        exampleSidebarContent: 'Example sidebar content',
        demandChargeTotalCost: '$XXX',
      },
      electricRateExplainer: {
        summary: 'How did you come up with my estimate?',
        details:
          'We use the information you enter about your charger type(s) and location to estimate your annual electric charges. The calculation includes supply charges and certain fees, assuming your utility supplies electricity to you and assuming prices from the 2019 calendar year. If you get your electricity from a different supplier, these total bill estimates may not apply. You may choose to separately meter your EV charging load or combine it with your building load. If your EV charging load is combined with the building load, we estimate your building load shape from the 2021 NREL Load Shape Study to estimate your pre-existing typical monthly bill. Additional taxes such as sales tax are not included here, and would be added to your actual bill.',
      },
      chargingBreakdown: {
        specs: '{type} - {speed}',
        dcfc: 'DCFC',
        level2: 'Level 2',
        speed: '{amount}kW',
        singlePort: 'Single Port',
        dualPort: 'Dual Port',
        minimumRequired: 'Minimum Req: {quantity}',
        dividerImgAlt: 'Arrow pointing to the right',
      },
      vehicleQuantity: 'x{quantity} {vehicleType}',
      sedans: 'Sedans',
      flatbedTrucks: 'Flatbed Trucks',
      inputs: {
        booleanOptions: {
          yes: 'Yes',
          no: 'No',
        },
        reset: 'Reset',
        resetConfirmation: 'Are you sure you want to reset all your inputs?',
      },
      vehicleSetDialog: {
        selectVehicleType: 'Select a Vehicle Type',
        enterVehicleDetails: 'Enter Vehicle Details',
        selectChargingBehaviors: 'Select Charging Behaviors',
        overview: {
          range: {
            label: 'Range',
            value: '{range} miles',
          },
          batteryCapacity: {
            label: 'Battery Capacity',
            value: '{batteryCapacity} kWh',
          },
          equivGasMpg: {
            label: 'Equivalent Fossil Fuel MPG',
            value: '{equivGasMpg} miles per gallon ({equivGasType})',
          },
          workdays: {
            label: 'Workdays',
            value: '{workdays}',
          },
          efficiency: {
            label: 'kWh per 100 Miles',
            value: '{efficiency}',
          },
          gvwr: {
            label: 'Gross Vehicle Weight',
            value: '{weight} lbs',
          },
        },
        selectVehicle: {
          vehicleType: 'Vehicle Type',
          vehicleSubtype: 'Subtype',
        },
        vehicleDetails: {
          numberOfVehiclesAdopted: '# of Vehicles Adopted',
          averageMilesPerDay: 'Avg Daily Miles',
          tooltip:
            'Include both commercial and personal miles. If some vehicles have above-average use, place them in a different vehicle set with a higher average mileage.',
        },
        chargingWindows: {
          start: {
            label: 'Start',
          },
          finish: {
            label: 'Finish',
          },
          charger: {
            label: 'Charger',
            tooltip:
              'A Level 2 charger, which runs on a 240V source like a clothes dryer, is adequate for most EV charging.  A DCFC charger runs on a 400V or greater source.',
          },
          approach: {
            label: 'Approach',
            tooltip:
              'Select "Even" if you plan on using managed charging, and this tool will spread the necessary kWh across the time period. Select "Max" if you plan to plug in and use the full power of the charger.',
          },
          addChargingTime: '+ Add charging time',
        },
        alerts: {
          milesGreaterThanRange:
            'Warning! The miles per day are greater than the range for this vehicle. This is sustainable with multiple charging windows.',
          stateOfChargeInfo:
            'With this configuration, the minimum state of charge across the week will be {stateOfCharge}%.',
          stateOfChargeWarning:
            'With this configuration, the minimum state of charge across the week will be {stateOfCharge}%. You may want to adjust your charging times, charging level(s), or daily miles inputs.',
          stateOfChargeError:
            'Warning! With this configuration, the batteries will be depleted at some point during the week. Please add more charging times, increase the charging level(s), and/or decrease the daily miles.',
          conflictingChargingWindows:
            'Warning! Multiple chargers are in use at overlapping times. Please identify a single charger per hour of the day.',
        },
        saveChanges: 'Save changes',
        cancelChanges: 'Cancel',
        deleteVehicleSet: 'Delete vehicle set',
      },
      wizard: {
        backButton: 'Back',
        nextButton: 'Next: {label}',
        submitButton: 'Submit',
        closeButtonLabel: 'Close dialog',
        charging: {
          dialogLabel: 'Fleets introductory dialog',
          intro: {
            label: 'Get Started',
            header: 'General Information',
            subheader:
              "We'll get started evaluating fuel savings for your fleet.  Begin by entering your baseline information.  You'll be able to edit these fields again later.",
          },
          vehicleTypes: {
            label: 'Vehicle',
            header: 'Select a Vehicle',
            subheader:
              "Incentive eligibility depends on the vehicle class selected; please speak with a representative from your utility to confirm eligibility. You'll be able to add more vehicle types after completing these four steps.",
          },
          vehicleDetails: {
            label: 'Driving',
            header: 'Enter Vehicle Details',
            subheader:
              'Tell us how you use this fleet vehicle so we can compare them to electric equivalents.',
          },
          charging: {
            label: 'Charging',
            header: 'Select Charging Behaviors',
            subheader:
              'Think ahead to when you expect to charge your new electrified fleet at your facility. Enter each daily window of time you expect to park and charge your vehicles.',
          },
        },
        planning: {
          dialogLabel: 'Site Planner introductory dialog',
          intro: {
            label: 'Get Started',
            header: 'General Information',
            subheader:
              "We'll get started estimating your electric charging costs. Begin by entering your baseline information. You'll be able to edit these fields again later.",
          },
          chargers: {
            label: 'Chargers',
            header: 'Select Chargers',
            subheader: 'Select the chargers you plan to install at your location.',
          },
          utilization: {
            label: 'Utilization',
            header: 'Availability and Utilization',
            subheader:
              'Select the days and months you expect the chargers to be available, followed by how much you expect them to be utilized.',
          },
        },
      },
      daysInOperation: {
        label: 'Days in Operation',
      },
      monthsInOperation: {
        label: 'Months in Operation',
        description: 'Summer Rates are June through September, inclusive.',
      },
      chargingWindows: {
        chargingTimes: {
          ...times(24, (i) => moment(i, 'H').format('h a')),
        },
      },
      chargers: {
        types: {
          'Level 2 - 7.7kW': 'Level 2 - 7.7kW',
          'Level 2 - 9.6kW': 'Level 2 - 9.6kW',
          'Level 2 - 7.7kW [Dual Port]': 'Level 2 - 7.7kW [Dual Port]',
          'Level 2 - 9.6kW [2 Port]': 'Level 2 - 9.6kW [2 Port]',
          'Level 2 - 11.5kW': 'Level 2 - 11.5kW',
          'Level 2 - 19.2kW': 'Level 2 - 19.2kW',
          'Level 2 - 24.0kW': 'Level 2 - 24.0kW',
          'DCFC - 50kW': 'DCFC - 50kW',
          'DCFC - 100kW': 'DCFC - 100kW',
          'DCFC - 120kW': 'DCFC - 120kW',
          'DCFC - 160kW': 'DCFC - 160kW',
          'DCFC - 175kW': 'DCFC - 175kW',
          'DCFC - 500kW': 'DCFC - 500kW',
        },
        chargingApproaches: {
          even: 'Even',
          max: 'Max',
        },
        categories: {
          level_2: 'Level 2',
          dc_fast_charging: 'DCFC',
        },
        ports: {
          1: 'Single Port',
          2: 'Dual Port',
          3: '3 Port',
          4: '4 Port',
          5: '5 Port',
          6: '6 Port',
        },
      },
      timePeriods: {
        days: {
          weekdays: {
            long: 'Weekdays',
            abbreviated: 'Weekdays',
          },
          weekends: {
            long: 'Weekends',
            abbreviated: 'Weekends',
          },
          0: {
            long: 'Sunday',
            abbreviated: 'Sun',
          },
          1: {
            long: 'Monday',
            abbreviated: 'Mon',
          },
          2: {
            long: 'Tuesday',
            abbreviated: 'Tue',
          },
          3: {
            long: 'Wednesday',
            abbreviated: 'Wed',
          },
          4: {
            long: 'Thursday',
            abbreviated: 'Thu',
          },
          5: {
            long: 'Friday',
            abbreviated: 'Fri',
          },
          6: {
            long: 'Saturday',
            abbreviated: 'Sat',
          },
        },
        months: {
          0: {
            long: 'January',
            abbreviated: 'Jan',
          },
          1: {
            long: 'February',
            abbreviated: 'Feb',
          },
          2: {
            long: 'March',
            abbreviated: 'Mar',
          },
          3: {
            long: 'April',
            abbreviated: 'Apr',
          },
          4: {
            long: 'May',
            abbreviated: 'May',
          },
          5: {
            long: 'June',
            abbreviated: 'Jun',
          },
          6: {
            long: 'July',
            abbreviated: 'Jul',
          },
          7: {
            long: 'August',
            abbreviated: 'Aug',
          },
          8: {
            long: 'September',
            abbreviated: 'Sep',
          },
          9: {
            long: 'October',
            abbreviated: 'Oct',
          },
          10: {
            long: 'November',
            abbreviated: 'Nov',
          },
          11: {
            long: 'December',
            abbreviated: 'Dec',
          },
        },
        workdays: {
          weekdays: 'Weekdays',
          weekends: 'Week',
        },
        labels: {
          month: 'Month',
          day: 'Day',
          hour: 'Hour',
        },
      },
      chargerSetDialog: {
        chargers: 'Chargers',
        utilization: 'utilization',
        deleteChargerSet: 'Delete charger set',
        saveChanges: 'Save changes',
        cancelChanges: 'Cancel',
        overview: {
          type: {
            label: 'Type',
            value: '{type}',
          },
          count: {
            label: 'Count',
          },
        },
      },
      utilizationLevel: {
        label: 'Utilization',
        tooltip:
          'Select the utilization of your set of chargers - start with Low if you expect infrequent usage. For more granular utilization by time of day, select "Custom".',
        preset: {
          low: 'Low',
          medium: 'Medium',
          high: 'High',
          custom: 'Custom',
        },
        maxTimePeriodLabel: 'Time period when the most chargers are in use',
        maxTimePeriodTooltip:
          'Select the time period when it is most likely that all the chargers at the site will be in use.',
        avgTimePeriodLabel: 'Average # chargers in use by time period',
        avgTimePeriodTooltip:
          'Select how many chargers you expect to be in use on average over all hours of the relevant time period.',
      },
    },
  },
  fr: {
    translation: {
      fr: 'Français',
      'Welcome to React': 'Bienvenue à React et react-i18next',
    },
  },
};

export default translations;
