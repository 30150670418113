import sum from 'lodash/sum';
import {FOSSIL_FUEL_MAINTENANCE_COST_FACTOR} from '@generic/data/assumptions';

const calcPerMileFossilFuelMaintenanceCosts = ({vehicleSets}) =>
  sum(
    vehicleSets.map(
      (set) => set.vehicle.fossilMaintenanceCostPerMile * FOSSIL_FUEL_MAINTENANCE_COST_FACTOR
    )
  );

export default calcPerMileFossilFuelMaintenanceCosts;
