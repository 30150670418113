import React from 'react';
import {Box, Grid, makeStyles, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import CHARGERS from '@generic/data/chargers';
import ChargerInfoBreakdown from '@generic/pages/SitePlanner/ChargerInfoBreakdown';

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: theme.typography.primary.fontFamily,
    textTransform: 'uppercase',
  },
  image: {
    height: 100,
    width: 'auto',
    maxWidth: '100%',
    margin: 20,
  },
  row: {
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  rowHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
  rowHeaderValue: {
    color: theme.palette.common.white,
    fontSize: '1.25rem',
    fontWeight: 700,
    padding: '0 17px',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,

    '&:last-child': {
      borderRight: `1px solid ${theme.palette.primary.main}`,
    },
  },
  cellValue: {
    color: theme.palette.gray[500],
    fontSize: '1.125rem',
    padding: 20,
  },
}));

export default function PlanningChargers() {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const {chargerSets} = useInputs();

  const findCharger = (type) => CHARGERS.find((c) => c.type === type) || CHARGERS[0];

  return (
    <Box>
      <Typography variant="h6" className={classes.header}>
        {formatMessage({id: 'planning.content.chargers.header'})}
      </Typography>

      <Box mt={5}>
        {isSmallScreen ? (
          chargerSets.map((chargerSet, idx) => (
            <ChargerInfoBreakdown key={idx} chargerSet={chargerSet} />
          ))
        ) : (
          <>
            <Grid container wrap="nowrap">
              <Grid item xs={2} />
              {chargerSets.map(({type}, idx) => (
                <Grid key={idx} item xs className={classes.cell}>
                  <img src={findCharger(type).imageUrl} alt={type} className={classes.image} />
                </Grid>
              ))}
            </Grid>

            <Grid container className={classes.row}>
              <Grid item xs={2} className={classes.rowHeader}>
                <Typography className={classes.rowHeaderValue}>
                  {formatMessage({id: 'planning.content.chargers.data.type'})}
                </Typography>
              </Grid>
              {chargerSets.map((chargerSet, idx) => (
                <Grid key={idx} item xs className={classes.cell}>
                  <Typography variant="body2" className={classes.cellValue}>
                    {chargerSet.type}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            <Grid container className={classes.row}>
              <Grid item xs={2} className={classes.rowHeader}>
                <Typography className={classes.rowHeaderValue}>
                  {formatMessage({id: 'planning.content.chargers.data.powerLevel'})}
                </Typography>
              </Grid>
              {chargerSets.map((chargerSet, idx) => (
                <Grid key={idx} item xs className={classes.cell}>
                  <Typography variant="body2" className={classes.cellValue}>
                    {`${findCharger(chargerSet.type).portKw}kW`}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            <Grid container className={classes.row}>
              <Grid item xs={2} className={classes.rowHeader}>
                <Typography className={classes.rowHeaderValue}>
                  {formatMessage({id: 'planning.content.chargers.data.portCount'})}
                </Typography>
              </Grid>
              {chargerSets.map((chargerSet, idx) => (
                <Grid key={idx} item xs className={classes.cell}>
                  <Typography variant="body2" className={classes.cellValue}>
                    {findCharger(chargerSet.type).ports}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            <Grid container className={classes.row}>
              <Grid item xs={2} className={classes.rowHeader}>
                <Typography className={classes.rowHeaderValue}>
                  {formatMessage({id: 'planning.content.chargers.data.count'})}
                </Typography>
              </Grid>
              {chargerSets.map((chargerSet, idx) => (
                <Grid key={idx} item xs className={classes.cell}>
                  <Typography variant="body2" className={classes.cellValue}>
                    {chargerSet.chargerCount}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
}
