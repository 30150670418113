import React, {useState} from 'react';
import {Box, Grid, makeStyles} from '@material-ui/core';
import RateBreakdownSelector from '@generic/pages/ChargingCalculator/RateBreakdownSelector';
import RateComponentCategoryChart from '@generic/pages/ChargingCalculator/RateComponentCategoryChart';
import RateChargePanel from '@generic/pages/ChargingCalculator/RateChargePanel';
import SupportingCopyPrintout from '@generic/pages/ChargingCalculator/SupportingCopyPrintout';
import ChargingSiteMonthlyDemand from '@generic/pages/ChargingCalculator/ChargingSiteMonthlyDemand';
import ChargingSiteLoadProfile from '@generic/pages/ChargingCalculator/ChargingSiteLoadProfile';
import ElectricityBill from '@generic/pages/ChargingCalculator/ElectricityBill';
import calcVehicleSetFacilityLoadProfile from '@generic/functions/calcVehicleSetFacilityLoadProfile';
import calcScaledFacilityLoadProfile from '@generic/functions/calcScaledFacilityLoadProfile';
import {getRates} from '@generic/data/rates';
import {YEAR, HOURS_IN_YEAR} from '@generic/data/assumptions';
import {useInputs} from '@bellawatt/use-inputs';
import {LoadProfile, RateCalculator} from '@bellawatt/electric-rate-engine';
import groupVehicleSetsByChargers from '@generic/functions/groupVehicleSetsByChargers';
import SmartChargeCommercialBreakdown from '../../components/SmartChargeCommercialBreakdown';
import DemandChargeRebateDisclaimer from '../../components/DemandChargeRebateDisclaimer';
import isEligibleForDemandChargeRebate from '../../functions/isEligibleForDemandChargeRebate';

const useStyles = makeStyles(() => ({
  flexedGridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function ChargingRateBreakdown({annualCosts}) {
  const inputs = useInputs();
  const {separatelyMetered, vehicleSets} = inputs;
  RateCalculator.shouldValidate = false;

  const classes = useStyles();

  const evLoadProfileData = calcVehicleSetFacilityLoadProfile(inputs);
  const evLoadProfile = new LoadProfile(evLoadProfileData, {year: YEAR});
  const totalLoadProfile = separatelyMetered
    ? evLoadProfile
    : evLoadProfile.aggregate(calcScaledFacilityLoadProfile(inputs));

  const maxDemand = totalLoadProfile.max();

  const rates = getRates(inputs, maxDemand);

  const [rate, setRate] = useState(rates[0]);
  const [selectedRateComponent, setSelectedRateComponent] = useState('energy');

  const handleChange = (rateName) => {
    setRate(rates.find((r) => r.name === rateName) || rates[0]);
  };

  const selectedAnnualCosts = annualCosts.find((c) => c.name === rate.name) || annualCosts[0];
  const {fleetSccRebate} = selectedAnnualCosts;

  const rateCalculator = new RateCalculator({
    ...rate,
    loadProfile: totalLoadProfile,
  });

  const chargerSets = groupVehicleSetsByChargers(vehicleSets);
  const dcfcChargerSetLoadProfile = chargerSets
    .filter(({chargerType}) => chargerType === 'dc_fast_charging')
    .reduce((accLoadProfile, {vehicles}) => {
      // A top level vehicle set has charging windows as an array. Vehicles within a group from groupVehicleSetsByChargers
      // have a single charging window. We need to convert the vehicle sets to have charging windows as an array.
      const chargerSetVehicleSets = vehicles.map((almostVehicleSet) => ({
        chargingWindows: [almostVehicleSet.chargingWindow],
        ...almostVehicleSet,
      }));
      const loadProfileData = calcVehicleSetFacilityLoadProfile({
        vehicleSets: chargerSetVehicleSets,
      });
      const loadProfile = new LoadProfile(loadProfileData, {year: YEAR});

      return accLoadProfile.aggregate(loadProfile);
    }, new LoadProfile(new Array(HOURS_IN_YEAR).fill(0), {year: YEAR}));

  const isEligibleForDcr = isEligibleForDemandChargeRebate({
    ...inputs,
    maxDemand,
    dcfcLoadProfile: dcfcChargerSetLoadProfile,
  });

  return (
    <Box mt={5}>
      <Box mb={5}>
        <Grid container spacing={2} mb={5}>
          <Grid item xs={12} lg={6}>
            <RateBreakdownSelector
              rateName={rate.name}
              handleChange={handleChange}
              maxDemand={maxDemand}
            />

            <SupportingCopyPrintout rateName={rate.name} />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.flexedGridItem}>
            <SmartChargeCommercialBreakdown />
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <RateComponentCategoryChart
            rateCalculator={rateCalculator}
            onHover={setSelectedRateComponent}
            sccRebate={fleetSccRebate}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box px={10} display="flex" justifyContent="center">
            <RateChargePanel
              rateCalculator={rateCalculator}
              loadProfile={totalLoadProfile}
              rateTitle={rate.title}
              rateComponent={selectedRateComponent}
              sccRebate={fleetSccRebate}
            />
          </Box>
        </Grid>
      </Grid>

      <ChargingSiteMonthlyDemand />
      <ChargingSiteLoadProfile />
      <ElectricityBill
        rateCalculator={rateCalculator}
        loadProfile={totalLoadProfile}
        rateTitle={rate.title}
      />

      {isEligibleForDcr && (
        <Box mb={5}>
          <DemandChargeRebateDisclaimer rateCalculator={rateCalculator} />
        </Box>
      )}
    </Box>
  );
}
