// Source: https://docs.google.com/spreadsheets/d/1o8ydfXcQ6B6Mtk_EagQECyZ2LXQICQFbT2WAtkXboXI/edit#gid=1078164887

const CHARGERS = [
  {
    type: 'Level 2 - 7.7kW',
    ports: 1,
    portKw: 7.7,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png',
  },
  {
    type: 'Level 2 - 9.6kW',
    ports: 1,
    portKw: 9.6,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 9.6KW-01.png',
  },
  {
    type: 'Level 2 - 11.5kW',
    ports: 1,
    portKw: 11.5,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 11.5KW-01.png',
  },
  {
    type: 'Level 2 - 19.2kW',
    ports: 1,
    portKw: 19.2,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 19.2KW-01.png',
  },
  {
    type: 'Level 2 - 24.0kW',
    ports: 1,
    portKw: 24,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 19.2KW-01.png',
  },

  {
    type: 'DCFC - 50kW',
    ports: 1,
    portKw: 50,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 50 KW Single port charger-01.png',
  },
  {
    type: 'DCFC - 100kW',
    ports: 1,
    portKw: 100,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 100 KW dual port charger-01.png',
  },
  {
    type: 'DCFC - 120kW',
    ports: 1,
    portKw: 120,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 120 KW dual port charger-01.png',
  },
  {
    type: 'DCFC - 160kW',
    ports: 1,
    portKw: 160,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 120 KW dual port charger-01.png',
  },
  {
    type: 'DCFC - 175kW',
    ports: 1,
    portKw: 175,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DC 175kW Singal Port--01.png',
  },
  {
    type: 'DCFC - 500kW',
    ports: 1,
    portKw: 500,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DCFC 6 port 500kW-01.png',
  },
];

export default CHARGERS;
