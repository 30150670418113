import {LoadProfile} from '@bellawatt/electric-rate-engine';
import {YEAR} from '@generic/data/assumptions';
import getRepresentativeLoadProfile from '@generic/data/loadProfiles';

const calcScaledFacilityLoadProfile = (inputs) => {
  const {monthlyElectricBill, buildingType, currentRate} = inputs;

  const representativeFacilityLoadProfileData = getRepresentativeLoadProfile(buildingType);

  const representativeFacilityLoadProfile = new LoadProfile(representativeFacilityLoadProfileData, {
    year: YEAR,
  });

  try {
    const scaled = representativeFacilityLoadProfile
      .scale()
      .toAverageMonthlyBill(monthlyElectricBill, currentRate);

    return scaled;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return representativeFacilityLoadProfile.scale().toTotalKwh(monthlyElectricBill * 5.6);
  }
};

export default calcScaledFacilityLoadProfile;
