import hourlyMarketPricesZoneH from './hourlyMarketPricesZoneH';
import hourlyMarketPricesZoneI from './hourlyMarketPricesZoneI';
import hourlyMarketPricesZoneJ from './hourlyMarketPricesZoneJ';

const ZONE_COST_PROFILES = {
  H: hourlyMarketPricesZoneH,
  I: hourlyMarketPricesZoneI,
  J: hourlyMarketPricesZoneJ,
};

const CONED_LOSS_ADJUSTMENT_FACTOR = 1.063;

export default function getMarketRateElements(zone, isOnMarketBasedRate) {
  return isOnMarketBasedRate
    ? [
        {
          rateElementType: 'HourlyEnergy',
          billingCategory: 'supply',
          name: 'Mandatory Day Ahead Hourly Pricing (MDAH)',
          priceProfile: ZONE_COST_PROFILES[zone].map(
            (price) => price * CONED_LOSS_ADJUSTMENT_FACTOR
          ),
        },
        {
          rateElementType: 'MonthlyEnergy',
          name: 'MHP - Ancillary Services',
          billingCategory: 'supply',
          rateComponents: [
            {
              charge: 0.00249,
              name: 'MHP - Ancillary Services',
            },
          ],
        },
        {
          rateElementType: 'MonthlyEnergy',
          name: 'MHP - NYPA Transmission Adjustment',
          billingCategory: 'supply',
          rateComponents: [
            {
              charge: 0.00225,
              name: 'MHP - NYPA Transmission Adjustment',
            },
          ],
        },
        {
          rateElementType: 'MonthlyEnergy',
          name: 'MHP - Transco Facilities Charge',
          billingCategory: 'supply',
          rateComponents: [
            {
              charge: 0.0006,
              name: 'MHP - Transco Facilities Charge',
            },
          ],
        },
      ]
    : [];
}
