import React from 'react';
import {Box, makeStyles, Typography, Divider} from '@material-ui/core';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    border: (props) => `1px solid ${props.color}`,
  },
  infoBox: {
    borderTop: (props) => `4px solid ${props.color}`,
  },
  infoHeader: {
    fontWeight: 'bold',
    marginTop: 30,
  },
  costLabel: {
    fontWeight: 'bold',
  },
  divider: {
    width: 48,
    marginTop: 10,
    background: theme.palette.common.black,
  },
}));

export default function FleetSummary({
  title,
  color,
  icon,
  annualCostLabel,
  annualCostValue,
  perMileLabel,
  perMileValue,
  annualMaintenanceCostValue,
  perMileMaintenanceCostValue,
}) {
  const classes = useStyles({color});
  const {formatMessage} = useIntl();

  return (
    <Box className={classes.root}>
      <Box display="flex" flexDirection="column" alignItems="center" py={4}>
        <Box height="90px">{icon && <img src={icon} alt="" />}</Box>
        <Typography className={classes.infoHeader} align="center" variant="h5">
          {title}
        </Typography>
      </Box>
      <Box p={4} className={classes.infoBox}>
        <Typography className={classes.infoHeader} variant="h6">
          {formatMessage({id: 'charging.content.overview.fleetSummary.fuelCost'})}
        </Typography>
        <Box py={2}>
          <Typography variant="body2" className={classes.costLabel}>
            {annualCostLabel}
          </Typography>
          <Typography>{annualCostValue}</Typography>
          <Divider className={classes.divider} />
        </Box>
        <Box py={2}>
          <Typography variant="body2" className={classes.costLabel}>
            {perMileLabel}
          </Typography>
          <Typography>{perMileValue}</Typography>
          <Divider className={classes.divider} />
        </Box>
      </Box>
      <Box p={4} className={classes.infoBox}>
        <Typography className={classes.infoHeader} variant="h6">
          {formatMessage({id: 'charging.content.overview.fleetSummary.maintenanceCost'})}
        </Typography>
        <Box py={2}>
          <Typography variant="body2" className={classes.costLabel}>
            {formatMessage({id: 'charging.content.overview.fleetSummary.annualMaintenanceCost'})}
          </Typography>
          <Typography>{annualMaintenanceCostValue}</Typography>
          <Divider className={classes.divider} />
        </Box>
        <Box py={2}>
          <Typography variant="body2" className={classes.costLabel}>
            {formatMessage({id: 'charging.content.overview.fleetSummary.maintenancePerMile'})}
          </Typography>
          <Typography>{perMileMaintenanceCostValue}</Typography>
          <Divider className={classes.divider} />
        </Box>
      </Box>
    </Box>
  );
}
