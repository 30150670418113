const stripMallLoadProfile = [
  4.81, 4.46, 4.44, 4.53, 4.77, 5.63, 6.08, 6.58, 7.65, 8.39, 8.72, 8.97, 9.07, 9.16, 9.13, 9.02,
  9.08, 9.28, 8.91, 8.43, 7.88, 7.03, 5.77, 4.99, 4.5, 4.16, 4.17, 4.29, 4.51, 5.31, 5.77, 6.18,
  7.28, 8.2, 8.59, 8.83, 8.97, 9.02, 8.99, 8.93, 9.02, 9.27, 8.93, 8.45, 7.9, 7.1, 5.87, 5.05, 4.57,
  4.18, 4.17, 4.28, 4.55, 5.43, 5.94, 6.46, 7.51, 8.33, 8.71, 8.93, 8.98, 8.98, 8.98, 8.93, 9.07,
  9.32, 8.99, 8.55, 8.06, 7.23, 5.94, 5.17, 4.67, 4.23, 4.27, 4.36, 4.53, 5.38, 5.86, 6.39, 7.45,
  8.21, 8.53, 8.74, 8.79, 8.8, 8.77, 8.68, 8.83, 9.08, 8.74, 8.29, 7.78, 6.88, 5.61, 4.8, 4.18,
  3.86, 3.86, 3.93, 4.07, 4.58, 4.99, 5.52, 6.55, 7.36, 7.8, 8.09, 8.28, 8.42, 8.39, 8.33, 8.41,
  8.68, 8.39, 7.94, 7.39, 6.51, 5.23, 4.34, 4.27, 4.03, 4.02, 4.01, 4.07, 4.64, 4.93, 4.9, 5.32,
  6.07, 6.77, 7.4, 7.87, 8.1, 8.15, 7.99, 7.85, 7.94, 7.42, 6.83, 6.24, 5.75, 5.3, 4.92, 4.27, 4.0,
  3.94, 3.99, 4.18, 4.96, 5.38, 5.83, 7.0, 7.91, 8.39, 8.7, 8.85, 8.91, 8.89, 8.82, 8.9, 9.21, 8.91,
  8.46, 7.96, 7.21, 6.06, 5.31, 4.81, 4.46, 4.44, 4.53, 4.77, 5.63, 6.08, 6.58, 7.65, 8.39, 8.72,
  8.97, 9.07, 9.16, 9.13, 9.02, 9.08, 9.28, 8.91, 8.43, 7.88, 7.03, 5.77, 4.99, 4.5, 4.16, 4.17,
  4.29, 4.51, 5.31, 5.77, 6.18, 7.28, 8.2, 8.59, 8.83, 8.97, 9.02, 8.99, 8.93, 9.02, 9.27, 8.93,
  8.45, 7.9, 7.1, 5.87, 5.05, 4.57, 4.18, 4.17, 4.28, 4.55, 5.43, 5.94, 6.46, 7.51, 8.33, 8.71,
  8.93, 8.98, 8.98, 8.98, 8.93, 9.07, 9.32, 8.99, 8.55, 8.06, 7.23, 5.94, 5.17, 4.67, 4.23, 4.27,
  4.36, 4.53, 5.38, 5.86, 6.39, 7.45, 8.21, 8.53, 8.74, 8.79, 8.8, 8.77, 8.68, 8.83, 9.08, 8.74,
  8.29, 7.78, 6.88, 5.61, 4.8, 4.18, 3.86, 3.86, 3.93, 4.07, 4.58, 4.99, 5.52, 6.55, 7.36, 7.8,
  8.09, 8.28, 8.42, 8.39, 8.33, 8.41, 8.68, 8.39, 7.94, 7.39, 6.51, 5.23, 4.34, 4.27, 4.03, 4.02,
  4.01, 4.07, 4.64, 4.93, 4.9, 5.32, 6.07, 6.77, 7.4, 7.87, 8.1, 8.15, 7.99, 7.85, 7.94, 7.42, 6.83,
  6.24, 5.75, 5.3, 4.92, 4.27, 4.0, 3.94, 3.99, 4.18, 4.96, 5.38, 5.83, 7.0, 7.91, 8.39, 8.7, 8.85,
  8.91, 8.89, 8.82, 8.9, 9.21, 8.91, 8.46, 7.96, 7.21, 6.06, 5.31, 4.81, 4.46, 4.44, 4.53, 4.77,
  5.63, 6.08, 6.58, 7.65, 8.39, 8.72, 8.97, 9.07, 9.16, 9.13, 9.02, 9.08, 9.28, 8.91, 8.43, 7.88,
  7.03, 5.77, 4.99, 4.5, 4.16, 4.17, 4.29, 4.51, 5.31, 5.77, 6.18, 7.28, 8.2, 8.59, 8.83, 8.97,
  9.02, 8.99, 8.93, 9.02, 9.27, 8.93, 8.45, 7.9, 7.1, 5.87, 5.05, 4.57, 4.18, 4.17, 4.28, 4.55,
  5.43, 5.94, 6.46, 7.51, 8.33, 8.71, 8.93, 8.98, 8.98, 8.98, 8.93, 9.07, 9.32, 8.99, 8.55, 8.06,
  7.23, 5.94, 5.17, 4.67, 4.23, 4.27, 4.36, 4.53, 5.38, 5.86, 6.39, 7.45, 8.21, 8.53, 8.74, 8.79,
  8.8, 8.77, 8.68, 8.83, 9.08, 8.74, 8.29, 7.78, 6.88, 5.61, 4.8, 4.18, 3.86, 3.86, 3.93, 4.07,
  4.58, 4.99, 5.52, 6.55, 7.36, 7.8, 8.09, 8.28, 8.42, 8.39, 8.33, 8.41, 8.68, 8.39, 7.94, 7.39,
  6.51, 5.23, 4.34, 4.27, 4.03, 4.02, 4.01, 4.07, 4.64, 4.93, 4.9, 5.32, 6.07, 6.77, 7.4, 7.87, 8.1,
  8.15, 7.99, 7.85, 7.94, 7.42, 6.83, 6.24, 5.75, 5.3, 4.92, 4.27, 4.0, 3.94, 3.99, 4.18, 4.96,
  5.38, 5.83, 7.0, 7.91, 8.39, 8.7, 8.85, 8.91, 8.89, 8.82, 8.9, 9.21, 8.91, 8.46, 7.96, 7.21, 6.06,
  5.31, 4.81, 4.46, 4.44, 4.53, 4.77, 5.63, 6.08, 6.58, 7.65, 8.39, 8.72, 8.97, 9.07, 9.16, 9.13,
  9.02, 9.08, 9.28, 8.91, 8.43, 7.88, 7.03, 5.77, 4.99, 4.5, 4.16, 4.17, 4.29, 4.51, 5.31, 5.77,
  6.18, 7.28, 8.2, 8.59, 8.83, 8.97, 9.02, 8.99, 8.93, 9.02, 9.27, 8.93, 8.45, 7.9, 7.1, 5.87, 5.05,
  4.57, 4.18, 4.17, 4.28, 4.55, 5.43, 5.94, 6.46, 7.51, 8.33, 8.71, 8.93, 8.98, 8.98, 8.98, 8.93,
  9.07, 9.32, 8.99, 8.55, 8.06, 7.23, 5.94, 5.17, 4.67, 4.23, 4.27, 4.36, 4.53, 5.38, 5.86, 6.39,
  7.45, 8.21, 8.53, 8.74, 8.79, 8.8, 8.77, 8.68, 8.83, 9.08, 8.74, 8.29, 7.78, 6.88, 5.61, 4.8,
  4.18, 3.86, 3.86, 3.93, 4.07, 4.58, 4.99, 5.52, 6.55, 7.36, 7.8, 8.09, 8.28, 8.42, 8.39, 8.33,
  8.41, 8.68, 8.39, 7.94, 7.39, 6.51, 5.23, 4.34, 4.27, 4.03, 4.02, 4.01, 4.07, 4.64, 4.93, 4.9,
  5.32, 6.07, 6.77, 7.4, 7.87, 8.1, 8.15, 7.99, 7.85, 7.94, 7.42, 6.83, 6.24, 5.75, 5.3, 4.92, 4.27,
  4.0, 3.94, 3.99, 4.18, 4.96, 5.38, 5.83, 7.0, 7.91, 8.39, 8.7, 8.85, 8.91, 8.89, 8.82, 8.9, 9.21,
  8.91, 8.46, 7.96, 7.21, 6.06, 5.31, 4.81, 4.46, 4.44, 4.53, 4.77, 5.63, 6.08, 6.58, 7.65, 8.39,
  8.72, 8.97, 9.07, 9.16, 9.13, 9.02, 9.08, 9.28, 8.91, 8.43, 7.88, 7.03, 5.77, 4.99, 4.5, 4.16,
  4.17, 4.29, 4.51, 5.31, 5.77, 6.18, 7.28, 8.2, 8.59, 8.83, 8.97, 9.02, 8.99, 8.93, 9.02, 9.27,
  8.93, 8.45, 7.9, 7.1, 5.87, 5.05, 4.57, 4.18, 4.17, 4.28, 4.55, 5.43, 5.94, 6.46, 7.51, 8.33,
  8.71, 8.93, 8.98, 8.98, 8.98, 8.93, 9.07, 9.32, 8.99, 8.55, 8.06, 7.23, 5.94, 5.17, 3.93, 3.63,
  3.56, 3.6, 3.74, 4.32, 4.67, 5.1, 6.38, 7.24, 7.68, 7.98, 8.19, 8.34, 8.34, 8.32, 8.26, 8.62,
  8.49, 8.04, 7.46, 6.6, 5.35, 4.51, 3.94, 3.7, 3.67, 3.71, 3.85, 4.32, 4.66, 5.11, 6.31, 7.12,
  7.55, 7.84, 8.08, 8.27, 8.24, 8.21, 8.14, 8.46, 8.32, 7.84, 7.31, 6.43, 5.2, 4.36, 4.3, 4.06,
  4.04, 4.06, 4.11, 4.64, 4.78, 4.71, 5.29, 6.0, 6.66, 7.26, 7.72, 8.02, 8.08, 7.9, 7.62, 7.7, 7.4,
  6.84, 6.25, 5.78, 5.35, 4.97, 4.35, 4.04, 4.06, 4.13, 4.32, 5.14, 5.47, 5.92, 7.17, 7.95, 8.34,
  8.58, 8.68, 8.71, 8.67, 8.59, 8.49, 8.79, 8.65, 8.18, 7.64, 6.84, 5.66, 4.94, 4.37, 4.01, 4.02,
  4.11, 4.35, 5.12, 5.42, 5.84, 7.14, 8.01, 8.4, 8.63, 8.75, 8.86, 8.87, 8.76, 8.66, 8.97, 8.91,
  8.5, 7.93, 7.07, 5.88, 5.07, 4.53, 4.17, 4.18, 4.32, 4.5, 5.26, 5.52, 5.97, 7.18, 7.97, 8.39,
  8.65, 8.82, 8.84, 8.77, 8.67, 8.54, 8.81, 8.76, 8.31, 7.77, 6.96, 5.68, 4.92, 4.33, 3.95, 3.9,
  3.95, 4.08, 4.76, 5.09, 5.49, 6.69, 7.44, 7.81, 8.03, 8.16, 8.19, 8.22, 8.22, 8.16, 8.53, 8.42,
  7.99, 7.44, 6.56, 5.27, 4.41, 3.93, 3.63, 3.56, 3.6, 3.74, 4.32, 4.67, 5.1, 6.38, 7.24, 7.68,
  7.98, 8.19, 8.34, 8.34, 8.32, 8.26, 8.62, 8.49, 8.04, 7.46, 6.6, 5.35, 4.51, 3.94, 3.7, 3.67,
  3.71, 3.85, 4.32, 4.66, 5.11, 6.31, 7.12, 7.55, 7.84, 8.08, 8.27, 8.24, 8.21, 8.14, 8.46, 8.32,
  7.84, 7.31, 6.43, 5.2, 4.36, 4.3, 4.06, 4.04, 4.06, 4.11, 4.64, 4.78, 4.71, 5.29, 6.0, 6.66, 7.26,
  7.72, 8.02, 8.08, 7.9, 7.62, 7.7, 7.4, 6.84, 6.25, 5.78, 5.35, 4.97, 4.35, 4.04, 4.06, 4.13, 4.32,
  5.14, 5.47, 5.92, 7.17, 7.95, 8.34, 8.58, 8.68, 8.71, 8.67, 8.59, 8.49, 8.79, 8.65, 8.18, 7.64,
  6.84, 5.66, 4.94, 4.37, 4.01, 4.02, 4.11, 4.35, 5.12, 5.42, 5.84, 7.14, 8.01, 8.4, 8.63, 8.75,
  8.86, 8.87, 8.76, 8.66, 8.97, 8.91, 8.5, 7.93, 7.07, 5.88, 5.07, 4.53, 4.17, 4.18, 4.32, 4.5,
  5.26, 5.52, 5.97, 7.18, 7.97, 8.39, 8.65, 8.82, 8.84, 8.77, 8.67, 8.54, 8.81, 8.76, 8.31, 7.77,
  6.96, 5.68, 4.92, 4.33, 3.95, 3.9, 3.95, 4.08, 4.76, 5.09, 5.49, 6.69, 7.44, 7.81, 8.03, 8.16,
  8.19, 8.22, 8.22, 8.16, 8.53, 8.42, 7.99, 7.44, 6.56, 5.27, 4.41, 3.93, 3.63, 3.56, 3.6, 3.74,
  4.32, 4.67, 5.1, 6.38, 7.24, 7.68, 7.98, 8.19, 8.34, 8.34, 8.32, 8.26, 8.62, 8.49, 8.04, 7.46,
  6.6, 5.35, 4.51, 3.94, 3.7, 3.67, 3.71, 3.85, 4.32, 4.66, 5.11, 6.31, 7.12, 7.55, 7.84, 8.08,
  8.27, 8.24, 8.21, 8.14, 8.46, 8.32, 7.84, 7.31, 6.43, 5.2, 4.36, 4.3, 4.06, 4.04, 4.06, 4.11,
  4.64, 4.78, 4.71, 5.29, 6.0, 6.66, 7.26, 7.72, 8.02, 8.08, 7.9, 7.62, 7.7, 7.4, 6.84, 6.25, 5.78,
  5.35, 4.97, 4.35, 4.04, 4.06, 4.13, 4.32, 5.14, 5.47, 5.92, 7.17, 7.95, 8.34, 8.58, 8.68, 8.71,
  8.67, 8.59, 8.49, 8.79, 8.65, 8.18, 7.64, 6.84, 5.66, 4.94, 4.37, 4.01, 4.02, 4.11, 4.35, 5.12,
  5.42, 5.84, 7.14, 8.01, 8.4, 8.63, 8.75, 8.86, 8.87, 8.76, 8.66, 8.97, 8.91, 8.5, 7.93, 7.07,
  5.88, 5.07, 4.53, 4.17, 4.18, 4.32, 4.5, 5.26, 5.52, 5.97, 7.18, 7.97, 8.39, 8.65, 8.82, 8.84,
  8.77, 8.67, 8.54, 8.81, 8.76, 8.31, 7.77, 6.96, 5.68, 4.92, 4.33, 3.95, 3.9, 3.95, 4.08, 4.76,
  5.09, 5.49, 6.69, 7.44, 7.81, 8.03, 8.16, 8.19, 8.22, 8.22, 8.16, 8.53, 8.42, 7.99, 7.44, 6.56,
  5.27, 4.41, 3.93, 3.63, 3.56, 3.6, 3.74, 4.32, 4.67, 5.1, 6.38, 7.24, 7.68, 7.98, 8.19, 8.34,
  8.34, 8.32, 8.26, 8.62, 8.49, 8.04, 7.46, 6.6, 5.35, 4.51, 3.94, 3.7, 3.67, 3.71, 3.85, 4.32,
  4.66, 5.11, 6.31, 7.12, 7.55, 7.84, 8.08, 8.27, 8.24, 8.21, 8.14, 8.46, 8.32, 7.84, 7.31, 6.43,
  5.2, 4.36, 4.3, 4.06, 4.04, 4.06, 4.11, 4.64, 4.78, 4.71, 5.29, 6.0, 6.66, 7.26, 7.72, 8.02, 8.08,
  7.9, 7.62, 7.7, 7.4, 6.84, 6.25, 5.78, 5.35, 4.97, 4.35, 4.04, 4.06, 4.13, 4.32, 5.14, 5.47, 5.92,
  7.17, 7.95, 8.34, 8.58, 8.68, 8.71, 8.67, 8.59, 8.49, 8.79, 8.65, 8.18, 7.64, 6.84, 5.66, 4.94,
  4.37, 4.01, 4.02, 4.11, 4.35, 5.12, 5.42, 5.84, 7.14, 8.01, 8.4, 8.63, 8.75, 8.86, 8.87, 8.76,
  8.66, 8.97, 8.91, 8.5, 7.93, 7.07, 5.88, 5.07, 4.53, 4.17, 4.18, 4.32, 4.5, 5.26, 5.52, 5.97,
  7.18, 7.97, 8.39, 8.65, 8.82, 8.84, 8.77, 8.67, 8.54, 8.81, 8.76, 8.31, 7.77, 6.96, 5.68, 4.92,
  4.33, 3.95, 3.9, 3.95, 4.08, 4.76, 5.09, 5.49, 6.69, 7.44, 7.81, 8.03, 8.16, 8.19, 8.22, 8.22,
  8.16, 8.53, 8.42, 7.99, 7.44, 6.56, 5.27, 4.41, 3.67, 3.51, 3.51, 3.61, 4.01, 4.51, 4.73, 5.76,
  6.82, 7.6, 8.25, 8.71, 8.94, 8.99, 8.89, 8.7, 8.4, 8.13, 8.18, 7.61, 6.81, 5.67, 4.65, 4.01, 3.59,
  3.47, 3.47, 3.54, 3.79, 4.13, 4.36, 5.38, 6.41, 7.07, 7.54, 7.93, 8.19, 8.24, 8.09, 7.95, 7.77,
  7.64, 7.78, 7.3, 6.57, 5.43, 4.39, 3.82, 3.82, 3.77, 3.79, 3.83, 4.18, 4.47, 4.37, 4.81, 5.5,
  6.18, 6.78, 7.28, 7.64, 7.81, 7.76, 7.52, 7.14, 6.72, 6.64, 6.04, 5.49, 5.0, 4.57, 4.15, 3.64,
  3.5, 3.53, 3.66, 4.09, 4.59, 4.93, 6.08, 7.05, 7.63, 7.96, 8.15, 8.26, 8.32, 8.26, 8.16, 7.97,
  7.79, 7.96, 7.44, 6.65, 5.51, 4.59, 4.06, 3.76, 3.63, 3.66, 3.79, 4.27, 4.74, 5.04, 6.15, 7.07,
  7.68, 8.28, 8.81, 9.15, 9.29, 9.19, 9.11, 8.86, 8.38, 8.36, 7.76, 6.89, 5.69, 4.77, 4.17, 3.9,
  3.83, 3.86, 3.97, 4.47, 5.0, 5.26, 6.32, 7.24, 7.79, 8.12, 8.26, 8.34, 8.35, 8.27, 8.15, 7.96,
  7.74, 7.92, 7.42, 6.65, 5.5, 4.54, 3.94, 3.7, 3.55, 3.57, 3.71, 4.16, 4.75, 4.95, 5.97, 6.92, 7.5,
  7.86, 8.13, 8.36, 8.51, 8.5, 8.33, 8.1, 7.96, 8.09, 7.58, 6.82, 5.69, 4.65, 4.0, 3.67, 3.51, 3.51,
  3.61, 4.01, 4.51, 4.73, 5.76, 6.82, 7.6, 8.25, 8.71, 8.94, 8.99, 8.89, 8.7, 8.4, 8.13, 8.18, 7.61,
  6.81, 5.67, 4.65, 4.01, 3.59, 3.47, 3.47, 3.54, 3.79, 4.13, 4.36, 5.38, 6.41, 7.07, 7.54, 7.93,
  8.19, 8.24, 8.09, 7.95, 7.77, 7.64, 7.78, 7.3, 6.57, 5.43, 4.39, 3.82, 3.82, 3.77, 3.83, 4.18,
  4.47, 4.37, 4.81, 5.5, 6.18, 6.78, 7.28, 7.64, 7.81, 7.76, 7.52, 7.14, 6.72, 6.64, 6.04, 5.49,
  5.0, 4.57, 4.15, 3.64, 3.5, 3.53, 3.66, 4.09, 4.59, 4.93, 6.08, 7.05, 7.63, 7.96, 8.15, 8.26,
  8.32, 8.26, 8.16, 7.97, 7.79, 7.96, 7.44, 6.65, 5.51, 4.59, 4.06, 3.76, 3.63, 3.66, 3.79, 4.27,
  4.74, 5.04, 6.15, 7.07, 7.68, 8.28, 8.81, 9.15, 9.29, 9.19, 9.11, 8.86, 8.38, 8.36, 7.76, 6.89,
  5.69, 4.77, 4.17, 3.9, 3.83, 3.86, 3.97, 4.47, 5.0, 5.26, 6.32, 7.24, 7.79, 8.12, 8.26, 8.34,
  8.35, 8.27, 8.15, 7.96, 7.74, 7.92, 7.42, 6.65, 5.5, 4.54, 3.94, 3.7, 3.55, 3.57, 3.71, 4.16,
  4.75, 4.95, 5.97, 6.92, 7.5, 7.86, 8.13, 8.36, 8.51, 8.5, 8.33, 8.1, 7.96, 8.09, 7.58, 6.82, 5.69,
  4.65, 4.0, 3.67, 3.51, 3.51, 3.61, 4.01, 4.51, 4.73, 5.76, 6.82, 7.6, 8.25, 8.71, 8.94, 8.99,
  8.89, 8.7, 8.4, 8.13, 8.18, 7.61, 6.81, 5.67, 4.65, 4.01, 3.59, 3.47, 3.47, 3.54, 3.79, 4.13,
  4.36, 5.38, 6.41, 7.07, 7.54, 7.93, 8.19, 8.24, 8.09, 7.95, 7.77, 7.64, 7.78, 7.3, 6.57, 5.43,
  4.39, 3.82, 3.82, 3.77, 3.79, 3.83, 4.18, 4.47, 4.37, 4.81, 5.5, 6.18, 6.78, 7.28, 7.64, 7.81,
  7.76, 7.52, 7.14, 6.72, 6.64, 6.04, 5.49, 5.0, 4.57, 4.15, 3.64, 3.5, 3.53, 3.66, 4.09, 4.59,
  4.93, 6.08, 7.05, 7.63, 7.96, 8.15, 8.26, 8.32, 8.26, 8.16, 7.97, 7.79, 7.96, 7.44, 6.65, 5.51,
  4.59, 4.06, 3.76, 3.63, 3.66, 3.79, 4.27, 4.74, 5.04, 6.15, 7.07, 7.68, 8.28, 8.81, 9.15, 9.29,
  9.19, 9.11, 8.86, 8.38, 8.36, 7.76, 6.89, 5.69, 4.77, 4.17, 3.9, 3.83, 3.86, 3.97, 4.47, 5.0,
  5.26, 6.32, 7.24, 7.79, 8.12, 8.26, 8.34, 8.35, 8.27, 8.15, 7.96, 7.74, 7.92, 7.42, 6.65, 5.5,
  4.54, 3.94, 3.7, 3.55, 3.57, 3.71, 4.16, 4.75, 4.95, 5.97, 6.92, 7.5, 7.86, 8.13, 8.36, 8.51, 8.5,
  8.33, 8.1, 7.96, 8.09, 7.58, 6.82, 5.69, 4.65, 4.0, 3.67, 3.51, 3.51, 3.61, 4.01, 4.51, 4.73,
  5.76, 6.82, 7.6, 8.25, 8.71, 8.94, 8.99, 8.89, 8.7, 8.4, 8.13, 8.18, 7.61, 6.81, 5.67, 4.65, 4.01,
  3.59, 3.47, 3.47, 3.54, 3.79, 4.13, 4.36, 5.38, 6.41, 7.07, 7.54, 7.93, 8.19, 8.24, 8.09, 7.95,
  7.77, 7.64, 7.78, 7.3, 6.57, 5.43, 4.39, 3.82, 3.82, 3.77, 3.79, 3.83, 4.18, 4.47, 4.37, 4.81,
  5.5, 6.18, 6.78, 7.28, 7.64, 7.81, 7.76, 7.52, 7.14, 6.72, 6.64, 6.04, 5.49, 5.0, 4.57, 4.15,
  3.64, 3.5, 3.53, 3.66, 4.09, 4.59, 4.93, 6.08, 7.05, 7.63, 7.96, 8.15, 8.26, 8.32, 8.26, 8.16,
  7.97, 7.79, 7.96, 7.44, 6.65, 5.51, 4.59, 4.06, 3.76, 3.63, 3.66, 3.79, 4.27, 4.74, 5.04, 6.15,
  7.07, 7.68, 8.28, 8.81, 9.15, 9.29, 9.19, 9.11, 8.86, 8.38, 8.36, 7.76, 6.89, 5.69, 4.77, 4.17,
  3.9, 3.83, 3.86, 3.97, 4.47, 5.0, 5.26, 6.32, 7.24, 7.79, 8.12, 8.26, 8.34, 8.35, 8.27, 8.15,
  7.96, 7.74, 7.92, 7.42, 6.65, 5.5, 4.54, 3.94, 3.7, 3.55, 3.57, 3.71, 4.16, 4.75, 4.95, 5.97,
  6.92, 7.5, 7.86, 8.13, 8.36, 8.51, 8.5, 8.33, 8.1, 7.96, 8.09, 7.58, 6.82, 5.69, 4.65, 4.0, 3.67,
  3.51, 3.51, 3.61, 4.01, 4.51, 4.73, 5.76, 6.82, 7.6, 8.25, 8.71, 8.94, 8.99, 8.89, 8.7, 8.4, 8.13,
  8.18, 7.61, 6.81, 5.67, 4.65, 4.01, 3.59, 3.47, 3.47, 3.54, 3.79, 4.13, 4.36, 5.38, 6.41, 7.07,
  7.54, 7.93, 8.19, 8.24, 8.09, 7.95, 7.77, 7.64, 7.78, 7.3, 6.57, 5.43, 4.39, 3.82, 3.82, 3.77,
  3.79, 3.83, 4.18, 4.47, 4.37, 4.81, 5.5, 6.18, 6.78, 7.28, 7.64, 7.81, 7.76, 7.52, 7.14, 6.72,
  6.64, 6.04, 5.49, 5.0, 4.57, 4.15, 3.41, 3.33, 3.34, 3.46, 3.97, 3.89, 4.66, 5.96, 6.89, 7.52,
  8.02, 8.38, 8.65, 8.79, 8.84, 8.76, 8.5, 8.0, 7.73, 7.31, 6.34, 5.0, 4.12, 3.62, 3.37, 3.3, 3.32,
  3.43, 3.95, 3.95, 4.72, 6.01, 6.88, 7.46, 7.95, 8.38, 8.63, 8.63, 8.42, 8.24, 7.97, 7.58, 7.51,
  7.2, 6.31, 5.04, 4.19, 3.7, 3.45, 3.39, 3.4, 3.53, 4.04, 3.96, 4.71, 5.97, 6.9, 7.44, 7.78, 8.0,
  8.11, 8.15, 8.12, 8.1, 7.92, 7.59, 7.53, 7.23, 6.37, 5.13, 4.25, 3.71, 3.43, 3.36, 3.39, 3.5,
  4.03, 3.98, 4.78, 6.05, 6.94, 7.53, 8.02, 8.41, 8.6, 8.62, 8.53, 8.42, 8.16, 7.75, 7.6, 7.26,
  6.34, 5.04, 4.16, 3.64, 3.41, 3.34, 3.35, 3.48, 4.01, 3.94, 4.73, 6.02, 6.99, 7.79, 8.54, 9.15,
  9.57, 9.63, 9.56, 9.26, 8.79, 8.25, 8.05, 7.62, 6.62, 5.23, 4.29, 3.77, 3.42, 3.38, 3.4, 3.49,
  3.89, 3.77, 4.71, 6.16, 7.31, 8.3, 9.01, 9.4, 9.65, 9.62, 9.42, 9.17, 8.78, 8.14, 7.84, 7.35,
  6.32, 4.96, 4.03, 3.64, 3.7, 3.67, 3.66, 3.68, 4.09, 3.84, 4.04, 4.6, 5.32, 6.04, 6.7, 7.23, 7.63,
  7.79, 7.7, 7.47, 7.1, 6.45, 6.18, 5.75, 5.21, 4.72, 4.32, 3.88, 3.41, 3.33, 3.34, 3.46, 3.97,
  3.89, 4.66, 5.96, 6.89, 7.52, 8.02, 8.38, 8.65, 8.79, 8.84, 8.76, 8.5, 8.0, 7.73, 7.31, 6.34, 5.0,
  4.12, 3.62, 3.37, 3.3, 3.32, 3.43, 3.95, 3.95, 4.72, 6.01, 6.88, 7.46, 7.95, 8.38, 8.63, 8.63,
  8.42, 8.24, 7.97, 7.58, 7.51, 7.2, 6.31, 5.04, 4.19, 3.7, 3.45, 3.39, 3.4, 3.53, 4.04, 3.96, 4.71,
  5.97, 6.9, 7.44, 7.78, 8.0, 8.11, 8.15, 8.12, 8.1, 7.92, 7.59, 7.53, 7.23, 6.37, 5.13, 4.25, 3.71,
  3.43, 3.36, 3.39, 3.5, 4.03, 3.98, 4.78, 6.05, 6.94, 7.53, 8.02, 8.41, 8.6, 8.62, 8.53, 8.42,
  8.16, 7.75, 7.6, 7.26, 6.34, 5.04, 4.16, 3.64, 3.41, 3.34, 3.35, 3.48, 4.01, 3.94, 4.73, 6.02,
  6.99, 7.79, 8.54, 9.15, 9.57, 9.63, 9.56, 9.26, 8.79, 8.25, 8.05, 7.62, 6.62, 5.23, 4.29, 3.77,
  3.42, 3.38, 3.4, 3.49, 3.89, 3.77, 4.71, 6.16, 7.31, 8.3, 9.01, 9.4, 9.65, 9.62, 9.42, 9.17, 8.78,
  8.14, 7.84, 7.35, 6.32, 4.96, 4.03, 3.64, 3.7, 3.67, 3.66, 3.68, 4.09, 3.84, 4.04, 4.6, 5.32,
  6.04, 6.7, 7.23, 7.63, 7.79, 7.7, 7.47, 7.1, 6.45, 6.18, 5.75, 5.21, 4.72, 4.32, 3.88, 3.41, 3.33,
  3.34, 3.46, 3.97, 3.89, 4.66, 5.96, 6.89, 7.52, 8.02, 8.38, 8.65, 8.79, 8.84, 8.76, 8.5, 8.0,
  7.73, 7.31, 6.34, 5.0, 4.12, 3.62, 3.37, 3.3, 3.32, 3.43, 3.95, 3.95, 4.72, 6.01, 6.88, 7.46,
  7.95, 8.38, 8.63, 8.63, 8.42, 8.24, 7.97, 7.58, 7.51, 7.2, 6.31, 5.04, 4.19, 3.7, 3.45, 3.39, 3.4,
  3.53, 4.04, 3.96, 4.71, 5.97, 6.9, 7.44, 7.78, 8.0, 8.11, 8.15, 8.12, 8.1, 7.92, 7.59, 7.53, 7.23,
  6.37, 5.13, 4.25, 3.71, 3.43, 3.36, 3.39, 3.5, 4.03, 3.98, 4.78, 6.05, 6.94, 7.53, 8.02, 8.41,
  8.6, 8.62, 8.53, 8.42, 8.16, 7.75, 7.6, 7.26, 6.34, 5.04, 4.16, 3.64, 3.41, 3.34, 3.35, 3.48,
  4.01, 3.94, 4.73, 6.02, 6.99, 7.79, 8.54, 9.15, 9.57, 9.63, 9.56, 9.26, 8.79, 8.25, 8.05, 7.62,
  6.62, 5.23, 4.29, 3.77, 3.42, 3.38, 3.4, 3.49, 3.89, 3.77, 4.71, 6.16, 7.31, 8.3, 9.01, 9.4, 9.65,
  9.62, 9.42, 9.17, 8.78, 8.14, 7.84, 7.35, 6.32, 4.96, 4.03, 3.64, 3.7, 3.67, 3.66, 3.68, 4.09,
  3.84, 4.04, 4.6, 5.32, 6.04, 6.7, 7.23, 7.63, 7.79, 7.7, 7.47, 7.1, 6.45, 6.18, 5.75, 5.21, 4.72,
  4.32, 3.88, 3.41, 3.33, 3.34, 3.46, 3.97, 3.89, 4.66, 5.96, 6.89, 7.52, 8.02, 8.38, 8.65, 8.79,
  8.84, 8.76, 8.5, 8.0, 7.73, 7.31, 6.34, 5.0, 4.12, 3.62, 3.37, 3.3, 3.32, 3.43, 3.95, 3.95, 4.72,
  6.01, 6.88, 7.46, 7.95, 8.38, 8.63, 8.63, 8.42, 8.24, 7.97, 7.58, 7.51, 7.2, 6.31, 5.04, 4.19,
  3.7, 3.45, 3.39, 3.4, 3.53, 4.04, 3.96, 4.71, 5.97, 6.9, 7.44, 7.78, 8.0, 8.11, 8.15, 8.12, 8.1,
  7.92, 7.59, 7.53, 7.23, 6.37, 5.13, 4.25, 3.71, 3.43, 3.36, 3.39, 3.5, 4.03, 3.98, 4.78, 6.05,
  6.94, 7.53, 8.02, 8.41, 8.6, 8.62, 8.53, 8.42, 8.16, 7.75, 7.6, 7.26, 6.34, 5.04, 4.16, 3.64,
  3.41, 3.34, 3.35, 3.48, 4.01, 3.94, 4.73, 6.02, 6.99, 7.79, 8.54, 9.15, 9.57, 9.63, 9.56, 9.26,
  8.79, 8.25, 8.05, 7.62, 6.62, 5.23, 4.29, 3.77, 3.42, 3.38, 3.4, 3.49, 3.89, 3.77, 4.71, 6.16,
  7.31, 8.3, 9.01, 9.4, 9.65, 9.62, 9.42, 9.17, 8.78, 8.14, 7.84, 7.35, 6.32, 4.96, 4.03, 3.64, 3.7,
  3.67, 3.66, 3.68, 4.09, 3.84, 4.04, 4.6, 5.32, 6.04, 6.7, 7.23, 7.63, 7.79, 7.7, 7.47, 7.1, 6.45,
  6.18, 5.75, 5.21, 4.72, 4.32, 3.88, 3.41, 3.33, 3.34, 3.46, 3.97, 3.89, 4.66, 5.96, 6.89, 7.52,
  8.02, 8.38, 8.65, 8.79, 8.84, 8.76, 8.5, 8.0, 7.73, 7.31, 6.34, 5.0, 4.12, 3.62, 3.37, 3.3, 3.32,
  3.43, 3.95, 3.95, 4.72, 6.01, 6.88, 7.46, 7.95, 8.38, 8.63, 8.63, 8.42, 8.24, 7.97, 7.58, 7.51,
  7.2, 6.31, 5.04, 4.19, 3.7, 3.39, 3.3, 3.29, 3.39, 3.71, 3.83, 5.07, 6.88, 8.37, 9.55, 10.54,
  11.19, 11.66, 11.75, 11.59, 11.44, 10.86, 9.97, 8.74, 8.19, 7.0, 5.56, 4.59, 3.96, 3.48, 3.38,
  3.37, 3.45, 3.8, 3.91, 5.06, 6.85, 8.39, 9.73, 11.06, 12.01, 12.55, 12.77, 12.46, 11.93, 11.21,
  10.35, 9.26, 8.69, 7.34, 5.65, 4.5, 3.81, 3.4, 3.31, 3.3, 3.4, 3.65, 3.68, 4.79, 6.44, 7.78, 8.64,
  9.3, 9.68, 9.94, 10.0, 9.97, 9.55, 8.85, 8.14, 7.51, 7.38, 6.38, 5.04, 4.16, 3.65, 3.34, 3.29,
  3.29, 3.35, 3.4, 3.38, 4.39, 5.81, 7.0, 7.93, 8.66, 9.15, 9.47, 9.39, 9.09, 8.64, 8.13, 7.61,
  7.14, 7.1, 6.16, 4.83, 3.92, 3.54, 3.63, 3.57, 3.56, 3.56, 3.67, 3.44, 3.82, 4.39, 5.09, 5.81,
  6.49, 7.07, 7.49, 7.66, 7.58, 7.33, 6.92, 6.34, 5.73, 5.69, 5.14, 4.64, 4.24, 3.81, 3.34, 3.25,
  3.25, 3.35, 3.57, 3.55, 4.48, 5.81, 6.91, 7.95, 9.03, 9.89, 10.49, 10.88, 11.11, 10.84, 10.18,
  9.3, 8.35, 7.95, 6.78, 5.32, 4.34, 3.75, 3.37, 3.27, 3.28, 3.36, 3.66, 3.74, 4.91, 6.6, 7.93,
  9.05, 9.99, 10.76, 11.27, 11.54, 11.51, 11.18, 10.4, 9.46, 8.49, 8.04, 6.8, 5.32, 4.37, 3.75,
  3.39, 3.3, 3.29, 3.39, 3.71, 3.83, 5.07, 6.88, 8.37, 9.55, 10.54, 11.19, 11.66, 11.75, 11.59,
  11.44, 10.86, 9.97, 8.74, 8.19, 7.0, 5.56, 4.59, 3.96, 3.48, 3.38, 3.37, 3.45, 3.8, 3.91, 5.06,
  6.85, 8.39, 9.73, 11.06, 12.01, 12.55, 12.77, 12.46, 11.93, 11.21, 10.35, 9.26, 8.69, 7.34, 5.65,
  4.5, 3.81, 3.4, 3.31, 3.3, 3.4, 3.65, 3.68, 4.79, 6.44, 7.78, 8.64, 9.3, 9.68, 9.94, 10.0, 9.97,
  9.55, 8.85, 8.14, 7.51, 7.38, 6.38, 5.04, 4.16, 3.65, 3.34, 3.29, 3.29, 3.35, 3.4, 3.38, 4.39,
  5.81, 7.0, 7.93, 8.66, 9.15, 9.47, 9.39, 9.09, 8.64, 8.13, 7.61, 7.14, 7.1, 6.16, 4.83, 3.92,
  3.54, 3.63, 3.57, 3.56, 3.56, 3.67, 3.44, 3.82, 4.39, 5.09, 5.81, 6.49, 7.07, 7.49, 7.66, 7.58,
  7.33, 6.92, 6.34, 5.73, 5.69, 5.14, 4.64, 4.24, 3.81, 3.34, 3.25, 3.25, 3.35, 3.57, 3.55, 4.48,
  5.81, 6.91, 7.95, 9.03, 9.89, 10.49, 10.88, 11.11, 10.84, 10.18, 9.3, 8.35, 7.95, 6.78, 5.32,
  4.34, 3.75, 3.37, 3.27, 3.28, 3.36, 3.66, 3.74, 4.91, 6.6, 7.93, 9.05, 9.99, 10.76, 11.27, 11.54,
  11.51, 11.18, 10.4, 9.46, 8.49, 8.04, 6.8, 5.32, 4.37, 3.75, 3.39, 3.3, 3.29, 3.39, 3.71, 3.83,
  5.07, 6.88, 8.37, 9.55, 10.54, 11.19, 11.66, 11.75, 11.59, 11.44, 10.86, 9.97, 8.74, 8.19, 7.0,
  5.56, 4.59, 3.96, 3.48, 3.38, 3.37, 3.45, 3.8, 3.91, 5.06, 6.85, 8.39, 9.73, 11.06, 12.01, 12.55,
  12.77, 12.46, 11.93, 11.21, 10.35, 9.26, 8.69, 7.34, 5.65, 4.5, 3.81, 3.4, 3.31, 3.3, 3.4, 3.65,
  3.68, 4.79, 6.44, 7.78, 8.64, 9.3, 9.68, 9.94, 10.0, 9.97, 9.55, 8.85, 8.14, 7.51, 7.38, 6.38,
  5.04, 4.16, 3.65, 3.34, 3.29, 3.29, 3.35, 3.4, 3.38, 4.39, 5.81, 7.0, 7.93, 8.66, 9.15, 9.47,
  9.39, 9.09, 8.64, 8.13, 7.61, 7.14, 7.1, 6.16, 4.83, 3.92, 3.54, 3.63, 3.57, 3.56, 3.56, 3.67,
  3.44, 3.82, 4.39, 5.09, 5.81, 6.49, 7.07, 7.49, 7.66, 7.58, 7.33, 6.92, 6.34, 5.73, 5.69, 5.14,
  4.64, 4.24, 3.81, 3.34, 3.25, 3.25, 3.35, 3.57, 3.55, 4.48, 5.81, 6.91, 7.95, 9.03, 9.89, 10.49,
  10.88, 11.11, 10.84, 10.18, 9.3, 8.35, 7.95, 6.78, 5.32, 4.34, 3.75, 3.37, 3.27, 3.28, 3.36, 3.66,
  3.74, 4.91, 6.6, 7.93, 9.05, 9.99, 10.76, 11.27, 11.54, 11.51, 11.18, 10.4, 9.46, 8.49, 8.04, 6.8,
  5.32, 4.37, 3.75, 3.39, 3.3, 3.29, 3.39, 3.71, 3.83, 5.07, 6.88, 8.37, 9.55, 10.54, 11.19, 11.66,
  11.75, 11.59, 11.44, 10.86, 9.97, 8.74, 8.19, 7.0, 5.56, 4.59, 3.96, 3.48, 3.38, 3.37, 3.45, 3.8,
  3.91, 5.06, 6.85, 8.39, 9.73, 11.06, 12.01, 12.55, 12.77, 12.46, 11.93, 11.21, 10.35, 9.26, 8.69,
  7.34, 5.65, 4.5, 3.81, 3.4, 3.31, 3.3, 3.4, 3.65, 3.68, 4.79, 6.44, 7.78, 8.64, 9.3, 9.68, 9.94,
  10.0, 9.97, 9.55, 8.85, 8.14, 7.51, 7.38, 6.38, 5.04, 4.16, 3.65, 3.34, 3.29, 3.29, 3.35, 3.4,
  3.38, 4.39, 5.81, 7.0, 7.93, 8.66, 9.15, 9.47, 9.39, 9.09, 8.64, 8.13, 7.61, 7.14, 7.1, 6.16,
  4.83, 3.92, 3.54, 3.63, 3.57, 3.56, 3.56, 3.67, 3.44, 3.82, 4.39, 5.09, 5.81, 6.49, 7.07, 7.49,
  7.66, 7.58, 7.33, 6.92, 6.34, 5.73, 5.69, 5.14, 4.64, 4.24, 3.81, 3.34, 3.25, 3.25, 3.35, 3.57,
  3.55, 4.48, 5.81, 6.91, 7.95, 9.03, 9.89, 10.49, 10.88, 11.11, 10.84, 10.18, 9.3, 8.35, 7.95,
  6.78, 5.32, 4.34, 3.75, 3.37, 3.27, 3.28, 3.36, 3.66, 3.74, 4.91, 6.6, 7.93, 9.05, 9.99, 10.76,
  11.27, 11.54, 11.51, 11.18, 10.4, 9.46, 8.49, 8.04, 6.8, 5.32, 4.37, 3.75, 3.39, 3.3, 3.29, 3.39,
  3.71, 3.83, 5.07, 6.88, 8.37, 9.55, 10.54, 11.19, 11.66, 11.75, 11.59, 11.44, 10.86, 9.97, 8.74,
  8.19, 7.0, 5.56, 4.59, 3.96, 3.48, 3.38, 3.37, 3.45, 3.8, 3.91, 5.06, 6.85, 8.39, 9.73, 11.06,
  12.01, 12.55, 12.77, 12.46, 11.93, 11.21, 10.35, 9.26, 8.69, 7.34, 5.65, 4.5, 3.81, 3.4, 3.31,
  3.3, 3.4, 3.65, 3.68, 4.79, 6.44, 7.78, 8.64, 9.3, 9.68, 9.94, 10.0, 9.97, 9.55, 8.85, 8.14, 7.51,
  7.38, 6.38, 5.04, 4.16, 3.65, 3.41, 3.35, 3.35, 3.42, 3.48, 3.72, 5.04, 6.96, 8.69, 10.1, 11.38,
  12.51, 13.11, 13.33, 13.19, 12.83, 12.21, 11.2, 9.77, 8.86, 7.45, 5.67, 4.49, 3.97, 3.84, 3.76,
  3.71, 3.69, 3.8, 3.77, 4.4, 5.47, 6.96, 8.46, 9.85, 10.85, 11.17, 11.25, 11.08, 10.59, 9.71, 8.5,
  7.22, 6.51, 5.74, 5.06, 4.52, 3.99, 3.37, 3.29, 3.28, 3.38, 3.51, 3.81, 5.17, 7.19, 8.87, 10.17,
  10.94, 11.15, 11.27, 11.13, 10.79, 10.33, 9.57, 8.69, 7.89, 7.59, 6.64, 5.19, 4.24, 3.68, 3.35,
  3.27, 3.26, 3.35, 3.46, 3.7, 4.95, 6.77, 8.28, 9.52, 10.56, 11.2, 11.71, 11.94, 11.76, 11.19,
  10.35, 9.53, 8.52, 7.96, 6.89, 5.35, 4.32, 3.73, 3.38, 3.29, 3.29, 3.37, 3.5, 3.72, 4.94, 6.91,
  8.76, 10.14, 11.21, 11.94, 12.33, 12.33, 12.09, 11.39, 10.62, 9.77, 8.72, 8.25, 7.24, 5.67, 4.57,
  3.91, 3.44, 3.34, 3.33, 3.43, 3.63, 3.98, 5.31, 7.27, 8.95, 10.16, 11.03, 11.66, 11.92, 12.0,
  11.78, 11.36, 10.87, 10.22, 9.19, 8.55, 7.36, 5.71, 4.6, 3.9, 3.47, 3.35, 3.33, 3.43, 3.58, 3.84,
  5.23, 7.27, 9.0, 10.4, 11.47, 12.0, 12.21, 12.39, 12.38, 11.94, 11.12, 10.19, 9.14, 8.57, 7.37,
  5.64, 4.52, 3.86, 3.41, 3.35, 3.35, 3.42, 3.48, 3.72, 5.04, 6.96, 8.69, 10.1, 11.38, 12.51, 13.11,
  13.33, 13.19, 12.83, 12.21, 11.2, 9.77, 8.86, 7.45, 5.67, 4.49, 3.97, 3.84, 3.76, 3.71, 3.69, 3.8,
  3.77, 4.4, 5.47, 6.96, 8.46, 9.85, 10.85, 11.17, 11.25, 11.08, 10.59, 9.71, 8.5, 7.22, 6.51, 5.74,
  5.06, 4.52, 3.99, 3.37, 3.29, 3.28, 3.38, 3.51, 3.81, 5.17, 7.19, 8.87, 10.17, 10.94, 11.15,
  11.27, 11.13, 10.79, 10.33, 9.57, 8.69, 7.89, 7.59, 6.64, 5.19, 4.24, 3.68, 3.35, 3.27, 3.26,
  3.35, 3.46, 3.7, 4.95, 6.77, 8.28, 9.52, 10.56, 11.2, 11.71, 11.94, 11.76, 11.19, 10.35, 9.53,
  8.52, 7.96, 6.89, 5.35, 4.32, 3.73, 3.38, 3.29, 3.29, 3.37, 3.5, 3.72, 4.94, 6.91, 8.76, 10.14,
  11.21, 11.94, 12.33, 12.33, 12.09, 11.39, 10.62, 9.77, 8.72, 8.25, 7.24, 5.67, 4.57, 3.91, 3.44,
  3.34, 3.33, 3.43, 3.63, 3.98, 5.31, 7.27, 8.95, 10.16, 11.03, 11.66, 11.92, 12.0, 11.78, 11.36,
  10.87, 10.22, 9.19, 8.55, 7.36, 5.71, 4.6, 3.9, 3.47, 3.35, 3.33, 3.43, 3.58, 3.84, 5.23, 7.27,
  9.0, 10.4, 11.47, 12.0, 12.21, 12.39, 12.38, 11.94, 11.12, 10.19, 9.14, 8.57, 7.37, 5.64, 4.52,
  3.86, 3.41, 3.35, 3.35, 3.42, 3.48, 3.72, 5.04, 6.96, 8.69, 10.1, 11.38, 12.51, 13.11, 13.33,
  13.19, 12.83, 12.21, 11.2, 9.77, 8.86, 7.45, 5.67, 4.49, 3.97, 3.84, 3.76, 3.71, 3.69, 3.8, 3.77,
  4.4, 5.47, 6.96, 8.46, 9.85, 10.85, 11.17, 11.25, 11.08, 10.59, 9.71, 8.5, 7.22, 6.51, 5.74, 5.06,
  4.52, 3.99, 3.37, 3.29, 3.28, 3.38, 3.51, 3.81, 5.17, 7.19, 8.87, 10.17, 10.94, 11.15, 11.27,
  11.13, 10.79, 10.33, 9.57, 8.69, 7.89, 7.59, 6.64, 5.19, 4.24, 3.68, 3.35, 3.27, 3.26, 3.35, 3.46,
  3.7, 4.95, 6.77, 8.28, 9.52, 10.56, 11.2, 11.71, 11.94, 11.76, 11.19, 10.35, 9.53, 8.52, 7.96,
  6.89, 5.35, 4.32, 3.73, 3.38, 3.29, 3.29, 3.37, 3.5, 3.72, 4.94, 6.91, 8.76, 10.14, 11.21, 11.94,
  12.33, 12.33, 12.09, 11.39, 10.62, 9.77, 8.72, 8.25, 7.24, 5.67, 4.57, 3.91, 3.44, 3.34, 3.33,
  3.43, 3.63, 3.98, 5.31, 7.27, 8.95, 10.16, 11.03, 11.66, 11.92, 12.0, 11.78, 11.36, 10.87, 10.22,
  9.19, 8.55, 7.36, 5.71, 4.6, 3.9, 3.47, 3.35, 3.33, 3.43, 3.58, 3.84, 5.23, 7.27, 9.0, 10.4,
  11.47, 12.0, 12.21, 12.39, 12.38, 11.94, 11.12, 10.19, 9.14, 8.57, 7.37, 5.64, 4.52, 3.86, 3.41,
  3.35, 3.35, 3.42, 3.48, 3.72, 5.04, 6.96, 8.69, 10.1, 11.38, 12.51, 13.11, 13.33, 13.19, 12.83,
  12.21, 11.2, 9.77, 8.86, 7.45, 5.67, 4.49, 3.97, 3.84, 3.76, 3.71, 3.69, 3.8, 3.77, 4.4, 5.47,
  6.96, 8.46, 9.85, 10.85, 11.17, 11.25, 11.08, 10.59, 9.71, 8.5, 7.22, 6.51, 5.74, 5.06, 4.52,
  3.99, 3.37, 3.29, 3.28, 3.38, 3.51, 3.81, 5.17, 7.19, 8.87, 10.17, 10.94, 11.15, 11.27, 11.13,
  10.79, 10.33, 9.57, 8.69, 7.89, 7.59, 6.64, 5.19, 4.24, 3.68, 3.35, 3.27, 3.26, 3.35, 3.46, 3.7,
  4.95, 6.77, 8.28, 9.52, 10.56, 11.2, 11.71, 11.94, 11.76, 11.19, 10.35, 9.53, 8.52, 7.96, 6.89,
  5.35, 4.32, 3.73, 3.38, 3.29, 3.29, 3.37, 3.5, 3.72, 4.94, 6.91, 8.76, 10.14, 11.21, 11.94, 12.33,
  12.33, 12.09, 11.39, 10.62, 9.77, 8.72, 8.25, 7.24, 5.67, 4.57, 3.91, 3.44, 3.34, 3.33, 3.43,
  3.63, 3.98, 5.31, 7.27, 8.95, 10.16, 11.03, 11.66, 11.92, 12.0, 11.78, 11.36, 10.87, 10.22, 9.19,
  8.55, 7.36, 5.71, 4.6, 3.9, 3.47, 3.35, 3.33, 3.43, 3.58, 3.84, 5.23, 7.27, 9.0, 10.4, 11.47,
  12.0, 12.21, 12.39, 12.38, 11.94, 11.12, 10.19, 9.14, 8.57, 7.37, 5.64, 4.52, 3.86, 3.41, 3.35,
  3.35, 3.42, 3.48, 3.72, 5.04, 6.96, 8.69, 10.1, 11.38, 12.51, 13.11, 13.33, 13.19, 12.83, 12.21,
  11.2, 9.77, 8.86, 7.45, 5.67, 4.49, 3.97, 3.84, 3.76, 3.71, 3.69, 3.8, 3.77, 4.4, 5.47, 6.96,
  8.46, 9.85, 10.85, 11.17, 11.25, 11.08, 10.59, 9.71, 8.5, 7.22, 6.51, 5.74, 5.06, 4.52, 3.99,
  3.47, 3.37, 3.37, 3.49, 3.94, 4.11, 5.59, 7.75, 9.59, 11.24, 12.45, 13.18, 13.52, 13.19, 12.44,
  11.87, 11.46, 10.77, 9.63, 8.89, 7.57, 5.87, 4.74, 4.04, 3.52, 3.42, 3.42, 3.56, 4.09, 4.33, 5.96,
  8.37, 10.4, 11.94, 13.12, 14.01, 14.49, 14.65, 14.49, 13.93, 13.11, 11.89, 10.48, 9.64, 8.18, 6.3,
  5.07, 4.31, 3.73, 3.62, 3.63, 3.79, 4.5, 4.81, 6.53, 9.13, 11.6, 13.72, 15.14, 15.85, 16.06,
  15.89, 15.57, 15.01, 14.04, 12.77, 11.2, 10.2, 8.89, 7.01, 5.58, 4.73, 3.88, 3.69, 3.67, 3.81,
  4.57, 4.88, 6.69, 9.49, 11.88, 13.5, 14.39, 14.34, 14.43, 14.65, 14.16, 13.47, 12.6, 11.56, 10.28,
  9.37, 7.87, 6.04, 4.82, 4.06, 3.53, 3.43, 3.44, 3.57, 4.11, 4.32, 5.91, 8.3, 10.36, 11.96, 12.96,
  13.4, 13.76, 14.01, 13.94, 13.44, 12.6, 11.5, 10.17, 9.33, 7.92, 6.07, 4.88, 4.17, 3.58, 3.52,
  3.5, 3.57, 3.98, 4.16, 5.77, 8.02, 10.0, 11.5, 12.65, 13.51, 13.93, 13.92, 13.33, 12.47, 11.64,
  10.74, 9.66, 8.92, 7.48, 5.7, 4.5, 3.95, 3.86, 3.78, 3.72, 3.7, 3.98, 3.78, 4.42, 5.56, 7.1, 8.77,
  10.26, 11.34, 12.01, 12.26, 12.11, 11.53, 10.58, 9.33, 7.85, 7.08, 6.23, 5.47, 4.88, 4.26, 3.47,
  3.37, 3.37, 3.49, 3.94, 4.11, 5.59, 7.75, 9.59, 11.24, 12.45, 13.18, 13.52, 13.19, 12.44, 11.87,
  11.46, 10.77, 9.63, 8.89, 7.57, 5.87, 4.74, 4.04, 3.52, 3.42, 3.42, 3.56, 4.09, 4.33, 5.96, 8.37,
  10.4, 11.94, 13.12, 14.01, 14.49, 14.65, 14.49, 13.93, 13.11, 11.89, 10.48, 9.64, 8.18, 6.3, 5.07,
  4.31, 3.73, 3.62, 3.63, 3.79, 4.5, 4.81, 6.53, 9.13, 11.6, 13.72, 15.14, 15.85, 16.06, 15.89,
  15.57, 15.01, 14.04, 12.77, 11.2, 10.2, 8.89, 7.01, 5.58, 4.73, 3.88, 3.69, 3.67, 3.81, 4.57,
  4.88, 6.69, 9.49, 11.88, 13.5, 14.39, 14.34, 14.43, 14.65, 14.16, 13.47, 12.6, 11.56, 10.28, 9.37,
  7.87, 6.04, 4.82, 4.06, 3.53, 3.43, 3.44, 3.57, 4.11, 4.32, 5.91, 8.3, 10.36, 11.96, 12.96, 13.4,
  13.76, 14.01, 13.94, 13.44, 12.6, 11.5, 10.17, 9.33, 7.92, 6.07, 4.88, 4.17, 3.58, 3.52, 3.5,
  3.57, 3.98, 4.16, 5.77, 8.02, 10.0, 11.5, 12.65, 13.51, 13.93, 13.92, 13.33, 12.47, 11.64, 10.74,
  9.66, 8.92, 7.48, 5.7, 4.5, 3.95, 3.86, 3.78, 3.72, 3.7, 3.98, 3.78, 4.42, 5.56, 7.1, 8.77, 10.26,
  11.34, 12.01, 12.26, 12.11, 11.53, 10.58, 9.33, 7.85, 7.08, 6.23, 5.47, 4.88, 4.26, 3.47, 3.37,
  3.37, 3.49, 3.94, 4.11, 5.59, 7.75, 9.59, 11.24, 12.45, 13.18, 13.52, 13.19, 12.44, 11.87, 11.46,
  10.77, 9.63, 8.89, 7.57, 5.87, 4.74, 4.04, 3.52, 3.42, 3.42, 3.56, 4.09, 4.33, 5.96, 8.37, 10.4,
  11.94, 13.12, 14.01, 14.49, 14.65, 14.49, 13.93, 13.11, 11.89, 10.48, 9.64, 8.18, 6.3, 5.07, 4.31,
  3.73, 3.62, 3.63, 3.79, 4.5, 4.81, 6.53, 9.13, 11.6, 13.72, 15.14, 15.85, 16.06, 15.89, 15.57,
  15.01, 14.04, 12.77, 11.2, 10.2, 8.89, 7.01, 5.58, 4.73, 3.88, 3.69, 3.67, 3.81, 4.57, 4.88, 6.69,
  9.49, 11.88, 13.5, 14.39, 14.34, 14.43, 14.65, 14.16, 13.47, 12.6, 11.56, 10.28, 9.37, 7.87, 6.04,
  4.82, 4.06, 3.53, 3.43, 3.44, 3.57, 4.11, 4.32, 5.91, 8.3, 10.36, 11.96, 12.96, 13.4, 13.76,
  14.01, 13.94, 13.44, 12.6, 11.5, 10.17, 9.33, 7.92, 6.07, 4.88, 4.17, 3.58, 3.52, 3.5, 3.57, 3.98,
  4.16, 5.77, 8.02, 10.0, 11.5, 12.65, 13.51, 13.93, 13.92, 13.33, 12.47, 11.64, 10.74, 9.66, 8.92,
  7.48, 5.7, 4.5, 3.95, 3.86, 3.78, 3.72, 3.7, 3.98, 3.78, 4.42, 5.56, 7.1, 8.77, 10.26, 11.34,
  12.01, 12.26, 12.11, 11.53, 10.58, 9.33, 7.85, 7.08, 6.23, 5.47, 4.88, 4.26, 3.47, 3.37, 3.37,
  3.49, 3.94, 4.11, 5.59, 7.75, 9.59, 11.24, 12.45, 13.18, 13.52, 13.19, 12.44, 11.87, 11.46, 10.77,
  9.63, 8.89, 7.57, 5.87, 4.74, 4.04, 3.52, 3.42, 3.42, 3.56, 4.09, 4.33, 5.96, 8.37, 10.4, 11.94,
  13.12, 14.01, 14.49, 14.65, 14.49, 13.93, 13.11, 11.89, 10.48, 9.64, 8.18, 6.3, 5.07, 4.31, 3.73,
  3.62, 3.63, 3.79, 4.5, 4.81, 6.53, 9.13, 11.6, 13.72, 15.14, 15.85, 16.06, 15.89, 15.57, 15.01,
  14.04, 12.77, 11.2, 10.2, 8.89, 7.01, 5.58, 4.73, 3.88, 3.69, 3.67, 3.81, 4.57, 4.88, 6.69, 9.49,
  11.88, 13.5, 14.39, 14.34, 14.43, 14.65, 14.16, 13.47, 12.6, 11.56, 10.28, 9.37, 7.87, 6.04, 4.82,
  4.06, 3.53, 3.43, 3.44, 3.57, 4.11, 4.32, 5.91, 8.3, 10.36, 11.96, 12.96, 13.4, 13.76, 14.01,
  13.94, 13.44, 12.6, 11.5, 10.17, 9.33, 7.92, 6.07, 4.88, 4.17, 3.58, 3.52, 3.5, 3.57, 3.98, 4.16,
  5.77, 8.02, 10.0, 11.5, 12.65, 13.51, 13.93, 13.92, 13.33, 12.47, 11.64, 10.74, 9.66, 8.92, 7.48,
  5.7, 4.5, 3.95, 3.86, 3.78, 3.72, 3.7, 3.98, 3.78, 4.42, 5.56, 7.1, 8.77, 10.26, 11.34, 12.01,
  12.26, 12.11, 11.53, 10.58, 9.33, 7.85, 7.08, 6.23, 5.47, 4.88, 4.26, 3.47, 3.37, 3.37, 3.49,
  3.94, 4.11, 5.59, 7.75, 9.59, 11.24, 12.45, 13.18, 13.52, 13.19, 12.44, 11.87, 11.46, 10.77, 9.63,
  8.89, 7.57, 5.87, 4.74, 4.04, 3.52, 3.42, 3.42, 3.56, 4.09, 4.33, 5.96, 8.37, 10.4, 11.94, 13.12,
  14.01, 14.49, 14.65, 14.49, 13.93, 13.11, 11.89, 10.48, 9.64, 8.18, 6.3, 5.07, 4.31, 3.73, 3.62,
  3.63, 3.79, 4.5, 4.81, 6.53, 9.13, 11.6, 13.72, 15.14, 15.85, 16.06, 15.89, 15.57, 15.01, 14.04,
  12.77, 11.2, 10.2, 8.89, 7.01, 5.58, 4.73, 3.55, 3.42, 3.39, 3.5, 4.19, 4.14, 5.43, 7.57, 9.33,
  10.74, 11.79, 12.43, 12.74, 12.6, 12.31, 11.87, 11.17, 10.24, 9.39, 8.73, 7.42, 5.79, 4.67, 3.98,
  3.5, 3.42, 3.43, 3.57, 4.32, 4.33, 5.73, 7.85, 9.72, 11.31, 12.48, 13.24, 13.7, 13.89, 13.57,
  12.9, 12.11, 11.1, 10.04, 9.18, 7.77, 6.09, 4.95, 4.22, 3.67, 3.62, 3.63, 3.7, 4.33, 4.25, 5.67,
  7.9, 9.95, 11.55, 12.66, 13.35, 13.87, 13.95, 13.64, 13.25, 12.57, 11.4, 10.15, 9.31, 7.78, 5.93,
  4.65, 4.05, 3.89, 3.8, 3.75, 3.74, 4.29, 3.88, 4.53, 5.64, 7.19, 8.81, 10.36, 11.57, 12.14, 12.1,
  11.63, 10.91, 9.91, 8.62, 7.47, 6.86, 6.04, 5.36, 4.79, 4.15, 3.42, 3.32, 3.31, 3.42, 4.03, 3.87,
  5.12, 7.1, 8.81, 10.01, 10.91, 11.51, 11.91, 11.91, 11.56, 11.09, 10.38, 9.54, 8.76, 8.18, 6.89,
  5.33, 4.31, 3.73, 3.37, 3.28, 3.26, 3.36, 3.89, 3.76, 4.9, 6.84, 8.63, 10.02, 10.98, 11.52, 11.82,
  11.8, 11.62, 11.4, 10.84, 9.98, 9.06, 8.4, 7.11, 5.55, 4.5, 3.87, 3.44, 3.35, 3.35, 3.47, 4.13,
  4.09, 5.42, 7.54, 9.55, 11.09, 12.33, 13.09, 13.63, 13.95, 13.74, 13.17, 12.35, 11.13, 10.0, 9.28,
  7.87, 6.12, 4.9, 4.16, 3.55, 3.42, 3.39, 3.5, 4.19, 4.14, 5.43, 7.57, 9.33, 10.74, 11.79, 12.43,
  12.74, 12.6, 12.31, 11.87, 11.17, 10.24, 9.39, 8.73, 7.42, 5.79, 4.67, 3.98, 3.5, 3.42, 3.43,
  3.57, 4.32, 4.33, 5.73, 7.85, 9.72, 11.31, 12.48, 13.24, 13.7, 13.89, 13.57, 12.9, 12.11, 11.1,
  10.04, 9.18, 7.77, 6.09, 4.95, 4.22, 3.67, 3.62, 3.63, 3.7, 4.33, 4.25, 5.67, 7.9, 9.95, 11.55,
  12.66, 13.35, 13.87, 13.95, 13.64, 13.25, 12.57, 11.4, 10.15, 9.31, 7.78, 5.93, 4.65, 4.05, 3.89,
  3.8, 3.75, 3.74, 4.29, 3.88, 4.53, 5.64, 7.19, 8.81, 10.36, 11.57, 12.14, 12.1, 11.63, 10.91,
  9.91, 8.62, 7.47, 6.86, 6.04, 5.36, 4.79, 4.15, 3.42, 3.32, 3.31, 3.42, 4.03, 3.87, 5.12, 7.1,
  8.81, 10.01, 10.91, 11.51, 11.91, 11.91, 11.56, 11.09, 10.38, 9.54, 8.76, 8.18, 6.89, 5.33, 4.31,
  3.73, 3.37, 3.28, 3.26, 3.36, 3.89, 3.76, 4.9, 6.84, 8.63, 10.02, 10.98, 11.52, 11.82, 11.8,
  11.62, 11.4, 10.84, 9.98, 9.06, 8.4, 7.11, 5.55, 4.5, 3.87, 3.44, 3.35, 3.35, 3.47, 4.13, 4.09,
  5.42, 7.54, 9.55, 11.09, 12.33, 13.09, 13.63, 13.95, 13.74, 13.17, 12.35, 11.13, 10.0, 9.28, 7.87,
  6.12, 4.9, 4.16, 3.55, 3.42, 3.39, 3.5, 4.19, 4.14, 5.43, 7.57, 9.33, 10.74, 11.79, 12.43, 12.74,
  12.6, 12.31, 11.87, 11.17, 10.24, 9.39, 8.73, 7.42, 5.79, 4.67, 3.98, 3.5, 3.42, 3.43, 3.57, 4.32,
  4.33, 5.73, 7.85, 9.72, 11.31, 12.48, 13.24, 13.7, 13.89, 13.57, 12.9, 12.11, 11.1, 10.04, 9.18,
  7.77, 6.09, 4.95, 4.22, 3.67, 3.62, 3.63, 3.7, 4.33, 4.25, 5.67, 7.9, 9.95, 11.55, 12.66, 13.35,
  13.87, 13.95, 13.64, 13.25, 12.57, 11.4, 10.15, 9.31, 7.78, 5.93, 4.65, 4.05, 3.89, 3.8, 3.75,
  3.74, 4.29, 3.88, 4.53, 5.64, 7.19, 8.81, 10.36, 11.57, 12.14, 12.1, 11.63, 10.91, 9.91, 8.62,
  7.47, 6.86, 6.04, 5.36, 4.79, 4.15, 3.42, 3.32, 3.31, 3.42, 4.03, 3.87, 5.12, 7.1, 8.81, 10.01,
  10.91, 11.51, 11.91, 11.91, 11.56, 11.09, 10.38, 9.54, 8.76, 8.18, 6.89, 5.33, 4.31, 3.73, 3.37,
  3.28, 3.26, 3.36, 3.89, 3.76, 4.9, 6.84, 8.63, 10.02, 10.98, 11.52, 11.82, 11.8, 11.62, 11.4,
  10.84, 9.98, 9.06, 8.4, 7.11, 5.55, 4.5, 3.87, 3.44, 3.35, 3.35, 3.47, 4.13, 4.09, 5.42, 7.54,
  9.55, 11.09, 12.33, 13.09, 13.63, 13.95, 13.74, 13.17, 12.35, 11.13, 10.0, 9.28, 7.87, 6.12, 4.9,
  4.16, 3.55, 3.42, 3.39, 3.5, 4.19, 4.14, 5.43, 7.57, 9.33, 10.74, 11.79, 12.43, 12.74, 12.6,
  12.31, 11.87, 11.17, 10.24, 9.39, 8.73, 7.42, 5.79, 4.67, 3.98, 3.5, 3.42, 3.43, 3.57, 4.32, 4.33,
  5.73, 7.85, 9.72, 11.31, 12.48, 13.24, 13.7, 13.89, 13.57, 12.9, 12.11, 11.1, 10.04, 9.18, 7.77,
  6.09, 4.95, 4.22, 3.67, 3.62, 3.63, 3.7, 4.33, 4.25, 5.67, 7.9, 9.95, 11.55, 12.66, 13.35, 13.87,
  13.95, 13.64, 13.25, 12.57, 11.4, 10.15, 9.31, 7.78, 5.93, 4.65, 4.05, 3.89, 3.8, 3.75, 3.74,
  4.29, 3.88, 4.53, 5.64, 7.19, 8.81, 10.36, 11.57, 12.14, 12.1, 11.63, 10.91, 9.91, 8.62, 7.47,
  6.86, 6.04, 5.36, 4.79, 4.15, 3.42, 3.32, 3.31, 3.42, 4.03, 3.87, 5.12, 7.1, 8.81, 10.01, 10.91,
  11.51, 11.91, 11.91, 11.56, 11.09, 10.38, 9.54, 8.76, 8.18, 6.89, 5.33, 4.31, 3.73, 3.37, 3.28,
  3.26, 3.36, 3.89, 3.76, 4.9, 6.84, 8.63, 10.02, 10.98, 11.52, 11.82, 11.8, 11.62, 11.4, 10.84,
  9.98, 9.06, 8.4, 7.11, 5.55, 4.5, 3.87, 3.44, 3.35, 3.35, 3.47, 4.13, 4.09, 5.42, 7.54, 9.55,
  11.09, 12.33, 13.09, 13.63, 13.95, 13.74, 13.17, 12.35, 11.13, 10.0, 9.28, 7.87, 6.12, 4.9, 4.16,
  3.55, 3.42, 3.39, 3.5, 4.19, 4.14, 5.43, 7.57, 9.33, 10.74, 11.79, 12.43, 12.74, 12.6, 12.31,
  11.87, 11.17, 10.24, 9.39, 8.73, 7.42, 5.79, 4.67, 3.98, 3.5, 3.42, 3.43, 3.57, 4.32, 4.33, 5.73,
  7.85, 9.72, 11.31, 12.48, 13.24, 13.7, 13.89, 13.57, 12.9, 12.11, 11.1, 10.04, 9.18, 7.77, 6.09,
  4.95, 4.22, 3.67, 3.62, 3.63, 3.7, 4.33, 4.25, 5.67, 7.9, 9.95, 11.55, 12.66, 13.35, 13.87, 13.95,
  13.64, 13.25, 12.57, 11.4, 10.15, 9.31, 7.78, 5.93, 4.65, 4.05, 3.63, 3.59, 3.55, 3.56, 3.94,
  3.79, 3.81, 4.46, 5.37, 6.45, 7.54, 8.47, 9.15, 9.42, 9.26, 8.79, 8.1, 7.31, 6.89, 6.1, 5.44,
  4.88, 4.43, 3.95, 3.38, 3.3, 3.29, 3.4, 4.01, 4.13, 4.95, 6.67, 8.16, 9.44, 10.48, 11.14, 11.54,
  11.52, 11.28, 10.88, 10.19, 9.39, 8.94, 8.05, 6.89, 5.4, 4.4, 3.81, 3.42, 3.32, 3.3, 3.4, 3.98,
  4.11, 4.94, 6.71, 8.29, 9.62, 10.59, 11.25, 11.69, 11.75, 11.46, 11.04, 10.33, 9.46, 8.96, 8.04,
  6.83, 5.31, 4.32, 3.74, 3.39, 3.3, 3.3, 3.4, 3.99, 4.14, 4.84, 6.57, 8.18, 9.61, 10.67, 11.31,
  11.67, 11.63, 11.26, 10.75, 10.04, 9.31, 8.87, 8.03, 6.94, 5.47, 4.45, 3.85, 3.43, 3.35, 3.34,
  3.47, 4.18, 4.37, 5.17, 6.84, 8.16, 9.17, 9.92, 10.43, 10.7, 10.82, 10.74, 10.49, 9.91, 9.21,
  8.82, 8.01, 6.89, 5.39, 4.39, 3.78, 3.42, 3.33, 3.32, 3.42, 4.08, 4.34, 5.13, 6.94, 8.48, 9.74,
  10.74, 11.37, 11.69, 11.7, 11.42, 10.96, 10.3, 9.61, 9.17, 8.22, 6.95, 5.38, 4.35, 3.76, 3.36,
  3.3, 3.3, 3.36, 3.78, 3.88, 4.63, 6.27, 7.69, 8.82, 9.74, 10.39, 10.8, 10.8, 10.57, 10.23, 9.58,
  8.83, 8.43, 7.6, 6.48, 5.01, 4.03, 3.61, 3.63, 3.59, 3.55, 3.56, 3.94, 3.79, 3.81, 4.46, 5.37,
  6.45, 7.54, 8.47, 9.15, 9.42, 9.26, 8.79, 8.1, 7.31, 6.89, 6.1, 5.44, 4.88, 4.43, 3.95, 3.38, 3.3,
  3.29, 3.4, 4.01, 4.13, 4.95, 6.67, 8.16, 9.44, 10.48, 11.14, 11.54, 11.52, 11.28, 10.88, 10.19,
  9.39, 8.94, 8.05, 6.89, 5.4, 4.4, 3.81, 3.42, 3.32, 3.3, 3.4, 3.98, 4.11, 4.94, 6.71, 8.29, 9.62,
  10.59, 11.25, 11.69, 11.75, 11.46, 11.04, 10.33, 9.46, 8.96, 8.04, 6.83, 5.31, 4.32, 3.74, 3.39,
  3.3, 3.3, 3.4, 3.99, 4.14, 4.84, 6.57, 8.18, 9.61, 10.67, 11.31, 11.67, 11.63, 11.26, 10.75,
  10.04, 9.31, 8.87, 8.03, 6.94, 5.47, 4.45, 3.85, 3.43, 3.35, 3.34, 3.47, 4.18, 4.37, 5.17, 6.84,
  8.16, 9.17, 9.92, 10.43, 10.7, 10.82, 10.74, 10.49, 9.91, 9.21, 8.82, 8.01, 6.89, 5.39, 4.39,
  3.78, 3.42, 3.33, 3.32, 3.42, 4.08, 4.34, 5.13, 6.94, 8.48, 9.74, 10.74, 11.37, 11.69, 11.7,
  11.42, 10.96, 10.3, 9.61, 9.17, 8.22, 6.95, 5.38, 4.35, 3.76, 3.36, 3.3, 3.3, 3.36, 3.78, 3.88,
  4.63, 6.27, 7.69, 8.82, 9.74, 10.39, 10.8, 10.8, 10.57, 10.23, 9.58, 8.83, 8.43, 7.6, 6.48, 5.01,
  4.03, 3.61, 3.63, 3.59, 3.55, 3.56, 3.94, 3.79, 3.81, 4.46, 5.37, 6.45, 7.54, 8.47, 9.15, 9.42,
  9.26, 8.79, 8.1, 7.31, 6.89, 6.1, 5.44, 4.88, 4.43, 3.95, 3.38, 3.3, 3.29, 3.4, 4.01, 4.13, 4.95,
  6.67, 8.16, 9.44, 10.48, 11.14, 11.54, 11.52, 11.28, 10.88, 10.19, 9.39, 8.94, 8.05, 6.89, 5.4,
  4.4, 3.81, 3.42, 3.32, 3.3, 3.4, 3.98, 4.11, 4.94, 6.71, 8.29, 9.62, 10.59, 11.25, 11.69, 11.75,
  11.46, 11.04, 10.33, 9.46, 8.96, 8.04, 6.83, 5.31, 4.32, 3.74, 3.39, 3.3, 3.3, 3.4, 3.99, 4.14,
  4.84, 6.57, 8.18, 9.61, 10.67, 11.31, 11.67, 11.63, 11.26, 10.75, 10.04, 9.31, 8.87, 8.03, 6.94,
  5.47, 4.45, 3.85, 3.43, 3.35, 3.34, 3.47, 4.18, 4.37, 5.17, 6.84, 8.16, 9.17, 9.92, 10.43, 10.7,
  10.82, 10.74, 10.49, 9.91, 9.21, 8.82, 8.01, 6.89, 5.39, 4.39, 3.78, 3.42, 3.33, 3.32, 3.42, 4.08,
  4.34, 5.13, 6.94, 8.48, 9.74, 10.74, 11.37, 11.69, 11.7, 11.42, 10.96, 10.3, 9.61, 9.17, 8.22,
  6.95, 5.38, 4.35, 3.76, 3.36, 3.3, 3.3, 3.36, 3.78, 3.88, 4.63, 6.27, 7.69, 8.82, 9.74, 10.39,
  10.8, 10.8, 10.57, 10.23, 9.58, 8.83, 8.43, 7.6, 6.48, 5.01, 4.03, 3.61, 3.63, 3.59, 3.55, 3.56,
  3.94, 3.79, 3.81, 4.46, 5.37, 6.45, 7.54, 8.47, 9.15, 9.42, 9.26, 8.79, 8.1, 7.31, 6.89, 6.1,
  5.44, 4.88, 4.43, 3.95, 3.38, 3.3, 3.29, 3.4, 4.01, 4.13, 4.95, 6.67, 8.16, 9.44, 10.48, 11.14,
  11.54, 11.52, 11.28, 10.88, 10.19, 9.39, 8.94, 8.05, 6.89, 5.4, 4.4, 3.81, 3.42, 3.32, 3.3, 3.4,
  3.98, 4.11, 4.94, 6.71, 8.29, 9.62, 10.59, 11.25, 11.69, 11.75, 11.46, 11.04, 10.33, 9.46, 8.96,
  8.04, 6.83, 5.31, 4.32, 3.74, 3.39, 3.3, 3.3, 3.4, 3.99, 4.14, 4.84, 6.57, 8.18, 9.61, 10.67,
  11.31, 11.67, 11.63, 11.26, 10.75, 10.04, 9.31, 8.87, 8.03, 6.94, 5.47, 4.45, 3.85, 3.43, 3.35,
  3.34, 3.47, 4.18, 4.37, 5.17, 6.84, 8.16, 9.17, 9.92, 10.43, 10.7, 10.82, 10.74, 10.49, 9.91,
  9.21, 8.82, 8.01, 6.89, 5.39, 4.39, 3.78, 3.42, 3.33, 3.32, 3.42, 4.08, 4.34, 5.13, 6.94, 8.48,
  9.74, 10.74, 11.37, 11.69, 11.7, 11.42, 10.96, 10.3, 9.61, 9.17, 8.22, 6.95, 5.38, 4.35, 3.76,
  3.36, 3.3, 3.3, 3.36, 3.78, 3.88, 4.63, 6.27, 7.69, 8.82, 9.74, 10.39, 10.8, 10.8, 10.57, 10.23,
  9.58, 8.83, 8.43, 7.6, 6.48, 5.01, 4.03, 3.61, 3.63, 3.59, 3.55, 3.56, 3.94, 3.79, 3.81, 4.46,
  5.37, 6.45, 7.54, 8.47, 9.15, 9.42, 9.26, 8.79, 8.1, 7.31, 6.89, 6.1, 5.44, 4.88, 4.43, 3.95,
  3.38, 3.3, 3.29, 3.4, 4.01, 4.13, 4.95, 6.67, 8.16, 9.44, 10.48, 11.14, 11.54, 11.52, 11.28,
  10.88, 10.19, 9.39, 8.94, 8.05, 6.89, 5.4, 4.4, 3.81, 3.37, 3.3, 3.31, 3.41, 3.91, 4.29, 4.69,
  5.96, 6.94, 7.65, 8.21, 8.65, 8.98, 9.1, 9.0, 8.81, 8.44, 8.43, 7.9, 7.24, 6.29, 4.99, 4.12, 3.63,
  3.38, 3.31, 3.33, 3.43, 3.94, 4.31, 4.68, 5.9, 6.9, 7.63, 8.21, 8.65, 8.95, 8.98, 8.78, 8.49,
  8.17, 8.23, 7.79, 7.2, 6.33, 5.05, 4.15, 3.62, 3.33, 3.24, 3.23, 3.31, 3.8, 4.18, 4.58, 5.94, 7.0,
  7.79, 8.35, 8.73, 8.98, 9.01, 8.88, 8.65, 8.31, 8.31, 7.85, 7.25, 6.32, 5.0, 4.11, 3.6, 3.37,
  3.29, 3.29, 3.39, 3.88, 4.26, 4.67, 5.87, 6.81, 7.44, 7.97, 8.36, 8.6, 8.65, 8.57, 8.4, 8.12,
  8.22, 7.77, 7.19, 6.29, 5.0, 4.15, 3.66, 3.37, 3.32, 3.33, 3.38, 3.68, 4.08, 4.43, 5.54, 6.38,
  6.89, 7.33, 7.7, 7.97, 8.01, 7.98, 7.89, 7.69, 7.87, 7.49, 6.96, 6.08, 4.81, 3.94, 3.58, 3.66,
  3.62, 3.6, 3.62, 4.0, 4.2, 4.02, 4.48, 5.19, 5.88, 6.53, 7.04, 7.41, 7.57, 7.46, 7.17, 6.79, 6.84,
  6.26, 5.66, 5.13, 4.65, 4.27, 3.85, 3.38, 3.3, 3.3, 3.4, 3.88, 4.28, 4.68, 5.92, 6.88, 7.57, 8.15,
  8.58, 8.86, 8.93, 8.84, 8.65, 8.31, 8.37, 7.88, 7.26, 6.32, 5.01, 4.14, 3.64, 3.37, 3.3, 3.31,
  3.41, 3.91, 4.29, 4.69, 5.96, 6.94, 7.65, 8.21, 8.65, 8.98, 9.1, 9.0, 8.81, 8.44, 8.43, 7.9, 7.24,
  6.29, 4.99, 4.12, 3.63, 3.38, 3.31, 3.33, 3.43, 3.94, 4.31, 4.68, 5.9, 6.9, 7.63, 8.21, 8.65,
  8.95, 8.98, 8.78, 8.49, 8.17, 8.23, 7.79, 7.2, 6.33, 5.05, 4.15, 3.62, 3.33, 3.24, 3.23, 3.31,
  3.8, 4.18, 4.58, 5.94, 7.0, 7.79, 8.35, 8.73, 8.98, 9.01, 8.88, 8.65, 8.31, 8.31, 7.85, 7.25,
  6.32, 5.0, 4.11, 3.6, 3.37, 3.29, 3.29, 3.39, 3.88, 4.26, 4.67, 5.87, 6.81, 7.44, 7.97, 8.36, 8.6,
  8.65, 8.57, 8.4, 8.12, 8.22, 7.77, 7.19, 6.29, 5.0, 4.15, 3.66, 3.37, 3.32, 3.33, 3.38, 3.68,
  4.08, 4.43, 5.54, 6.38, 6.89, 7.33, 7.7, 7.97, 8.01, 7.98, 7.89, 7.69, 7.87, 7.49, 6.96, 6.08,
  4.81, 3.94, 3.58, 3.66, 3.62, 3.6, 3.62, 4.0, 4.2, 4.02, 4.48, 5.19, 5.88, 6.53, 7.04, 7.41, 7.57,
  7.46, 7.17, 6.79, 6.84, 6.26, 5.66, 5.13, 4.65, 4.27, 3.85, 3.38, 3.3, 3.3, 3.4, 3.88, 4.28, 4.68,
  5.92, 6.88, 7.57, 8.15, 8.58, 8.86, 8.93, 8.84, 8.65, 8.31, 8.37, 7.88, 7.26, 6.32, 5.01, 4.14,
  3.64, 3.37, 3.3, 3.31, 3.41, 3.91, 4.29, 4.69, 5.96, 6.94, 7.65, 8.21, 8.65, 8.98, 9.1, 9.0, 8.81,
  8.44, 8.43, 7.9, 7.24, 6.29, 4.99, 4.12, 3.63, 3.38, 3.31, 3.33, 3.43, 3.94, 4.31, 4.68, 5.9, 6.9,
  7.63, 8.21, 8.65, 8.95, 8.98, 8.78, 8.49, 8.17, 8.23, 7.79, 7.2, 6.33, 5.05, 4.15, 3.62, 3.33,
  3.24, 3.23, 3.31, 3.8, 4.18, 4.58, 5.94, 7.0, 7.79, 8.35, 8.73, 8.98, 9.01, 8.88, 8.65, 8.31,
  8.31, 7.85, 7.25, 6.32, 5.0, 4.11, 3.6, 3.37, 3.29, 3.29, 3.39, 3.88, 4.26, 4.67, 5.87, 6.81,
  7.44, 7.97, 8.36, 8.6, 8.65, 8.57, 8.4, 8.12, 8.22, 7.77, 7.19, 6.29, 5.0, 4.15, 3.66, 3.37, 3.32,
  3.33, 3.38, 3.68, 4.08, 4.43, 5.54, 6.38, 6.89, 7.33, 7.7, 7.97, 8.01, 7.98, 7.89, 7.69, 7.87,
  7.49, 6.96, 6.08, 4.81, 3.94, 3.58, 3.66, 3.62, 3.6, 3.62, 4.0, 4.2, 4.02, 4.48, 5.19, 5.88, 6.53,
  7.04, 7.41, 7.57, 7.46, 7.17, 6.79, 6.84, 6.26, 5.66, 5.13, 4.65, 4.27, 3.85, 3.38, 3.3, 3.3, 3.4,
  3.88, 4.28, 4.68, 5.92, 6.88, 7.57, 8.15, 8.58, 8.86, 8.93, 8.84, 8.65, 8.31, 8.37, 7.88, 7.26,
  6.32, 5.01, 4.14, 3.64, 3.37, 3.3, 3.31, 3.41, 3.91, 4.29, 4.69, 5.96, 6.94, 7.65, 8.21, 8.65,
  8.98, 9.1, 9.0, 8.81, 8.44, 8.43, 7.9, 7.24, 6.29, 4.99, 4.12, 3.63, 3.38, 3.31, 3.33, 3.43, 3.94,
  4.31, 4.68, 5.9, 6.9, 7.63, 8.21, 8.65, 8.95, 8.98, 8.78, 8.49, 8.17, 8.23, 7.79, 7.2, 6.33, 5.05,
  4.15, 3.62, 3.33, 3.24, 3.23, 3.31, 3.8, 4.18, 4.58, 5.94, 7.0, 7.79, 8.35, 8.73, 8.98, 9.01,
  8.88, 8.65, 8.31, 8.31, 7.85, 7.25, 6.32, 5.0, 4.11, 3.6, 3.37, 3.29, 3.29, 3.39, 3.88, 4.26,
  4.67, 5.87, 6.81, 7.44, 7.97, 8.36, 8.6, 8.65, 8.57, 8.4, 8.12, 8.22, 7.77, 7.19, 6.29, 5.0, 4.15,
  3.66, 3.37, 3.32, 3.33, 3.38, 3.68, 4.08, 4.43, 5.54, 6.38, 6.89, 7.33, 7.7, 7.97, 8.01, 7.98,
  7.89, 7.69, 7.87, 7.49, 6.96, 6.08, 4.81, 3.94, 3.58, 3.66, 3.62, 3.6, 3.62, 4.0, 4.2, 4.02, 4.48,
  5.19, 5.88, 6.53, 7.04, 7.41, 7.57, 7.46, 7.17, 6.79, 6.84, 6.26, 5.66, 5.13, 4.65, 4.27, 3.85,
  3.38, 3.3, 3.3, 3.4, 3.88, 4.28, 4.68, 5.92, 6.88, 7.57, 8.15, 8.58, 8.86, 8.93, 8.84, 8.65, 8.31,
  8.37, 7.88, 7.26, 6.32, 5.01, 4.14, 3.64, 3.37, 3.3, 3.31, 3.41, 3.91, 4.29, 4.69, 5.96, 6.94,
  7.65, 8.21, 8.65, 8.98, 9.1, 9.0, 8.81, 8.44, 8.43, 7.9, 7.24, 6.29, 4.99, 4.12, 3.63, 3.38, 3.31,
  3.33, 3.43, 3.94, 4.31, 4.68, 5.9, 6.9, 7.63, 8.21, 8.65, 8.95, 8.98, 8.78, 8.49, 8.17, 8.23,
  7.79, 7.2, 6.33, 5.05, 4.15, 3.62, 3.33, 3.24, 3.23, 3.31, 3.8, 4.18, 4.58, 5.94, 7.0, 7.79, 8.35,
  8.73, 8.98, 9.01, 8.88, 8.65, 8.31, 8.31, 7.85, 7.25, 6.32, 5.0, 4.11, 3.6, 3.72, 3.49, 3.43,
  3.48, 3.7, 4.26, 4.6, 5.15, 6.32, 7.12, 7.62, 7.97, 8.21, 8.35, 8.37, 8.26, 8.43, 8.46, 8.11,
  7.64, 7.02, 6.07, 4.87, 4.09, 3.53, 3.35, 3.32, 3.32, 3.34, 3.47, 3.82, 4.1, 4.68, 5.78, 6.56,
  7.12, 7.59, 7.95, 8.16, 8.16, 8.02, 8.12, 8.18, 7.83, 7.36, 6.76, 5.78, 4.6, 3.85, 3.82, 3.65,
  3.61, 3.61, 3.72, 4.06, 4.06, 4.09, 4.7, 5.41, 6.15, 6.81, 7.32, 7.62, 7.69, 7.5, 7.46, 7.38,
  6.86, 6.27, 5.69, 5.19, 4.73, 4.33, 3.77, 3.51, 3.44, 3.48, 3.63, 4.19, 4.41, 4.95, 6.18, 7.03,
  7.51, 7.86, 8.13, 8.3, 8.32, 8.23, 8.47, 8.54, 8.24, 7.8, 7.26, 6.39, 5.13, 4.32, 3.83, 3.56, 3.5,
  3.53, 3.66, 4.26, 4.52, 5.04, 6.32, 7.22, 7.77, 8.2, 8.48, 8.63, 8.57, 8.41, 8.57, 8.59, 8.27,
  7.8, 7.26, 6.4, 5.12, 4.27, 3.79, 3.52, 3.45, 3.44, 3.55, 4.07, 4.33, 4.77, 6.08, 7.0, 7.56, 7.96,
  8.26, 8.39, 8.44, 8.33, 8.59, 8.6, 8.3, 7.84, 7.3, 6.46, 5.2, 4.34, 3.7, 3.48, 3.42, 3.46, 3.66,
  4.21, 4.53, 5.11, 6.29, 7.08, 7.54, 7.87, 8.1, 8.21, 8.22, 8.15, 8.38, 8.45, 8.14, 7.69, 7.09,
  6.13, 4.94, 4.15, 3.72, 3.49, 3.43, 3.48, 3.7, 4.26, 4.6, 5.15, 6.32, 7.12, 7.62, 7.97, 8.21,
  8.35, 8.37, 8.26, 8.43, 8.46, 8.11, 7.64, 7.02, 6.07, 4.87, 4.09, 3.53, 3.35, 3.32, 3.34, 3.47,
  3.82, 4.1, 4.68, 5.78, 6.56, 7.12, 7.59, 7.95, 8.16, 8.16, 8.02, 8.12, 8.18, 7.83, 7.36, 6.76,
  5.78, 4.6, 3.85, 3.82, 3.65, 3.61, 3.61, 3.72, 4.06, 4.06, 4.09, 4.7, 5.41, 6.15, 6.81, 7.32,
  7.62, 7.69, 7.5, 7.46, 7.38, 6.86, 6.27, 5.69, 5.19, 4.73, 4.33, 3.77, 3.51, 3.44, 3.48, 3.63,
  4.19, 4.41, 4.95, 6.18, 7.03, 7.51, 7.86, 8.13, 8.3, 8.32, 8.23, 8.47, 8.54, 8.24, 7.8, 7.26,
  6.39, 5.13, 4.32, 3.83, 3.56, 3.5, 3.53, 3.66, 4.26, 4.52, 5.04, 6.32, 7.22, 7.77, 8.2, 8.48,
  8.63, 8.57, 8.41, 8.57, 8.59, 8.27, 7.8, 7.26, 6.4, 5.12, 4.27, 3.79, 3.52, 3.45, 3.44, 3.55,
  4.07, 4.33, 4.77, 6.08, 7.0, 7.56, 7.96, 8.26, 8.39, 8.44, 8.33, 8.59, 8.6, 8.3, 7.84, 7.3, 6.46,
  5.2, 4.34, 3.7, 3.48, 3.42, 3.46, 3.66, 4.21, 4.53, 5.11, 6.29, 7.08, 7.54, 7.87, 8.1, 8.21, 8.22,
  8.15, 8.38, 8.45, 8.14, 7.69, 7.09, 6.13, 4.94, 4.15, 3.72, 3.49, 3.43, 3.48, 3.7, 4.26, 4.6,
  5.15, 6.32, 7.12, 7.62, 7.97, 8.21, 8.35, 8.37, 8.26, 8.43, 8.46, 8.11, 7.64, 7.02, 6.07, 4.87,
  4.09, 3.53, 3.35, 3.32, 3.34, 3.47, 3.82, 4.1, 4.68, 5.78, 6.56, 7.12, 7.59, 7.95, 8.16, 8.16,
  8.02, 8.12, 8.18, 7.83, 7.36, 6.76, 5.78, 4.6, 3.85, 3.82, 3.65, 3.61, 3.61, 3.72, 4.06, 4.06,
  4.09, 4.7, 5.41, 6.15, 6.81, 7.32, 7.62, 7.69, 7.5, 7.46, 7.38, 6.86, 6.27, 5.69, 5.19, 4.73,
  4.33, 3.77, 3.51, 3.44, 3.48, 3.63, 4.19, 4.41, 4.95, 6.18, 7.03, 7.51, 7.86, 8.13, 8.3, 8.32,
  8.23, 8.47, 8.54, 8.24, 7.8, 7.26, 6.39, 5.13, 4.32, 3.83, 3.56, 3.5, 3.53, 3.66, 4.26, 4.52,
  5.04, 6.32, 7.22, 7.77, 8.2, 8.48, 8.63, 8.57, 8.41, 8.57, 8.59, 8.27, 7.8, 7.26, 6.4, 5.12, 4.27,
  3.79, 3.52, 3.45, 3.44, 3.55, 4.07, 4.33, 4.77, 6.08, 7.0, 7.56, 7.96, 8.26, 8.39, 8.44, 8.33,
  8.59, 8.6, 8.3, 7.84, 7.3, 6.46, 5.2, 4.34, 3.7, 3.48, 3.42, 3.46, 3.66, 4.21, 4.53, 5.11, 6.29,
  7.08, 7.54, 7.87, 8.1, 8.21, 8.22, 8.15, 8.38, 8.45, 8.14, 7.69, 7.09, 6.13, 4.94, 4.15, 3.72,
  3.49, 3.43, 3.48, 3.7, 4.26, 4.6, 5.15, 6.32, 7.12, 7.62, 7.97, 8.21, 8.35, 8.37, 8.26, 8.43,
  8.46, 8.11, 7.64, 7.02, 6.07, 4.87, 4.09, 3.53, 3.35, 3.32, 3.34, 3.47, 3.82, 4.1, 4.68, 5.78,
  6.56, 7.12, 7.59, 7.95, 8.16, 8.16, 8.02, 8.12, 8.18, 7.83, 7.36, 6.76, 5.78, 4.6, 3.85, 3.82,
  3.65, 3.61, 3.61, 3.72, 4.06, 4.06, 4.09, 4.7, 5.41, 6.15, 6.81, 7.32, 7.62, 7.69, 7.5, 7.46,
  7.38, 6.86, 6.27, 5.69, 5.19, 4.73, 4.33, 3.77, 3.51, 3.44, 3.48, 3.63, 4.19, 4.41, 4.95, 6.18,
  7.03, 7.51, 7.86, 8.13, 8.3, 8.32, 8.23, 8.47, 8.54, 8.24, 7.8, 7.26, 6.39, 5.13, 4.32, 3.83,
  3.56, 3.5, 3.53, 3.66, 4.26, 4.52, 5.04, 6.32, 7.22, 7.77, 8.2, 8.48, 8.63, 8.57, 8.41, 8.57,
  8.59, 8.27, 7.8, 7.26, 6.4, 5.12, 4.27, 3.79, 3.52, 3.45, 3.44, 3.55, 4.07, 4.33, 4.77, 6.08, 7.0,
  7.56, 7.96, 8.26, 8.39, 8.44, 8.33, 8.59, 8.6, 8.3, 7.84, 7.3, 6.46, 5.2, 4.34, 3.7, 3.48, 3.42,
  3.46, 3.66, 4.21, 4.53, 5.11, 6.29, 7.08, 7.54, 7.87, 8.1, 8.21, 8.22, 8.15, 8.38, 8.45, 8.14,
  7.69, 7.09, 6.13, 4.94, 4.15, 3.72, 3.49, 3.43, 3.48, 3.7, 4.26, 4.6, 5.15, 6.32, 7.12, 7.62,
  7.97, 8.21, 8.35, 8.37, 8.26, 8.43, 8.46, 8.11, 7.64, 7.02, 6.07, 4.87, 4.09, 3.53, 3.35, 3.32,
  3.34, 3.47, 3.82, 4.1, 4.68, 5.78, 6.56, 7.12, 7.59, 7.95, 8.16, 8.16, 8.02, 8.12, 8.18, 7.83,
  7.36, 6.76, 5.78, 4.6, 3.85, 4.22, 3.98, 3.95, 3.96, 3.99, 4.5, 4.78, 4.65, 5.1, 5.8, 6.47, 7.1,
  7.54, 7.81, 7.85, 7.65, 7.85, 7.69, 7.19, 6.62, 6.04, 5.54, 5.09, 4.72, 4.08, 3.81, 3.78, 3.85,
  4.01, 4.66, 5.12, 5.54, 6.68, 7.5, 7.93, 8.18, 8.33, 8.41, 8.41, 8.36, 8.76, 8.79, 8.49, 8.06,
  7.55, 6.7, 5.44, 4.66, 4.14, 3.85, 3.84, 3.9, 4.08, 4.8, 5.24, 5.61, 6.84, 7.69, 8.11, 8.42, 8.56,
  8.63, 8.67, 8.64, 9.03, 8.99, 8.66, 8.21, 7.68, 6.81, 5.59, 4.8, 4.31, 3.95, 3.94, 4.05, 4.24,
  5.01, 5.46, 5.88, 7.01, 7.87, 8.32, 8.64, 8.8, 8.87, 8.86, 8.77, 9.13, 9.06, 8.76, 8.29, 7.74,
  6.9, 5.63, 4.85, 4.28, 3.91, 3.85, 3.88, 4.05, 4.77, 5.22, 5.67, 6.82, 7.68, 8.1, 8.38, 8.55,
  8.62, 8.67, 8.65, 9.09, 9.03, 8.71, 8.27, 7.74, 6.89, 5.59, 4.74, 4.29, 3.96, 3.91, 4.02, 4.21,
  4.94, 5.36, 5.76, 6.91, 7.81, 8.24, 8.52, 8.64, 8.66, 8.64, 8.53, 8.94, 8.91, 8.6, 8.16, 7.61,
  6.79, 5.57, 4.76, 4.11, 3.84, 3.81, 3.86, 3.99, 4.45, 4.89, 5.35, 6.44, 7.24, 7.63, 7.94, 8.18,
  8.3, 8.26, 8.23, 8.65, 8.63, 8.32, 7.84, 7.31, 6.43, 5.17, 4.31, 4.22, 3.98, 3.95, 3.96, 3.99,
  4.5, 4.78, 4.65, 5.1, 5.8, 6.47, 7.1, 7.54, 7.81, 7.85, 7.65, 7.85, 7.69, 7.19, 6.62, 6.04, 5.54,
  5.09, 4.72, 4.08, 3.81, 3.78, 3.85, 4.01, 4.66, 5.12, 5.54, 6.68, 7.5, 7.93, 8.18, 8.33, 8.41,
  8.41, 8.36, 8.76, 8.79, 8.49, 8.06, 7.55, 6.7, 5.44, 4.66, 4.14, 3.85, 3.84, 3.9, 4.08, 4.8, 5.24,
  5.61, 6.84, 7.69, 8.11, 8.42, 8.56, 8.63, 8.67, 8.64, 9.03, 8.99, 8.66, 8.21, 7.68, 6.81, 5.59,
  4.8, 4.31, 3.95, 3.94, 4.05, 4.24, 5.01, 5.46, 5.88, 7.01, 7.87, 8.32, 8.64, 8.8, 8.87, 8.86,
  8.77, 9.13, 9.06, 8.76, 8.29, 7.74, 6.9, 5.63, 4.85, 4.28, 3.91, 3.85, 3.88, 4.05, 4.77, 5.22,
  5.67, 6.82, 7.68, 8.1, 8.38, 8.55, 8.62, 8.67, 8.65, 9.09, 9.03, 8.71, 8.27, 7.74, 6.89, 5.59,
  4.74, 4.29, 3.96, 3.91, 4.02, 4.21, 4.94, 5.36, 5.76, 6.91, 7.81, 8.24, 8.52, 8.64, 8.66, 8.64,
  8.53, 8.94, 8.91, 8.6, 8.16, 7.61, 6.79, 5.57, 4.76, 4.11, 3.84, 3.81, 3.86, 3.99, 4.45, 4.89,
  5.35, 6.44, 7.24, 7.63, 7.94, 8.18, 8.3, 8.26, 8.23, 8.65, 8.63, 8.32, 7.84, 7.31, 6.43, 5.17,
  4.31, 4.22, 3.98, 3.95, 3.96, 3.99, 4.5, 4.78, 4.65, 5.1, 5.8, 6.47, 7.1, 7.54, 7.81, 7.85, 7.65,
  7.85, 7.69, 7.19, 6.62, 6.04, 5.54, 5.09, 4.72, 4.08, 3.81, 3.78, 3.85, 4.01, 4.66, 5.12, 5.54,
  6.68, 7.5, 7.93, 8.18, 8.33, 8.41, 8.41, 8.36, 8.76, 8.79, 8.49, 8.06, 7.55, 6.7, 5.44, 4.66,
  4.14, 3.85, 3.84, 3.9, 4.08, 4.8, 5.24, 5.61, 6.84, 7.69, 8.11, 8.42, 8.56, 8.63, 8.67, 8.64,
  9.03, 8.99, 8.66, 8.21, 7.68, 6.81, 5.59, 4.8, 4.31, 3.95, 3.94, 4.05, 4.24, 5.01, 5.46, 5.88,
  7.01, 7.87, 8.32, 8.64, 8.8, 8.87, 8.86, 8.77, 9.13, 9.06, 8.76, 8.29, 7.74, 6.9, 5.63, 4.85,
  4.28, 3.91, 3.85, 3.88, 4.05, 4.77, 5.22, 5.67, 6.82, 7.68, 8.1, 8.38, 8.55, 8.62, 8.67, 8.65,
  9.09, 9.03, 8.71, 8.27, 7.74, 6.89, 5.59, 4.74, 4.29, 3.96, 3.91, 4.02, 4.21, 4.94, 5.36, 5.76,
  6.91, 7.81, 8.24, 8.52, 8.64, 8.66, 8.64, 8.53, 8.94, 8.91, 8.6, 8.16, 7.61, 6.79, 5.57, 4.76,
  4.11, 3.84, 3.81, 3.86, 3.99, 4.45, 4.89, 5.35, 6.44, 7.24, 7.63, 7.94, 8.18, 8.3, 8.26, 8.23,
  8.65, 8.63, 8.32, 7.84, 7.31, 6.43, 5.17, 4.31, 4.22, 3.98, 3.95, 3.96, 3.99, 4.5, 4.78, 4.65,
  5.1, 5.8, 6.47, 7.1, 7.54, 7.81, 7.85, 7.65, 7.85, 7.69, 7.19, 6.62, 6.04, 5.54, 5.09, 4.72, 4.08,
  3.81, 3.78, 3.85, 4.01, 4.66, 5.12, 5.54, 6.68, 7.5, 7.93, 8.18, 8.33, 8.41, 8.41, 8.36, 8.76,
  8.79, 8.49, 8.06, 7.55, 6.7, 5.44, 4.66, 4.14, 3.85, 3.84, 3.9, 4.08, 4.8, 5.24, 5.61, 6.84, 7.69,
  8.11, 8.42, 8.56, 8.63, 8.67, 8.64, 9.03, 8.99, 8.66, 8.21, 7.68, 6.81, 5.59, 4.8, 4.31, 3.95,
  3.94, 4.05, 4.24, 5.01, 5.46, 5.88, 7.01, 7.87, 8.32, 8.64, 8.8, 8.87, 8.86, 8.77, 9.13, 9.06,
  8.76, 8.29, 7.74, 6.9, 5.63, 4.85, 4.28, 3.91, 3.85, 3.88, 4.05, 4.77, 5.22, 5.67, 6.82, 7.68,
  8.1, 8.38, 8.55, 8.62, 8.67, 8.65, 9.09, 9.03, 8.71, 8.27, 7.74, 6.89, 5.59, 4.74, 4.29, 3.96,
  3.91, 4.02, 4.21, 4.94, 5.36, 5.76, 6.91, 7.81, 8.24, 8.52, 8.64, 8.66, 8.64, 8.53, 8.94, 8.91,
  8.6, 8.16, 7.61, 6.79, 5.57, 4.76, 4.11, 3.84, 3.81, 3.86, 3.99, 4.45, 4.89, 5.35, 6.44, 7.24,
  7.63, 7.94, 8.18, 8.3, 8.26, 8.23, 8.65, 8.63, 8.32, 7.84, 7.31, 6.43, 5.17, 4.31, 4.22, 3.98,
  3.95, 3.96, 3.99, 4.5, 4.78, 4.65, 5.1, 5.8, 6.47, 7.1, 7.54, 7.81, 7.85, 7.65, 7.85, 7.69, 7.19,
  6.62, 6.04, 5.54, 5.09, 4.72, 4.08, 3.81, 3.78, 3.85, 4.01, 4.66, 5.12, 5.54, 6.68, 7.5, 7.93,
  8.18, 8.33, 8.41, 8.41, 8.36, 8.76, 8.79, 8.49, 8.06, 7.55, 6.7, 5.44, 4.66, 4.14, 3.85, 3.84,
  3.9, 4.08, 4.8, 5.24, 5.61, 6.84, 7.69, 8.11, 8.42, 8.56, 8.63, 8.67, 8.64, 9.03, 8.99, 8.66,
  8.21, 7.68, 6.81, 5.59, 4.8,
];

export default stripMallLoadProfile;
