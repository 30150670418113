import React from 'react';
import {Box, useTheme} from '@material-ui/core';
import {LoadProfile} from '@bellawatt/electric-rate-engine';
import {useInputs} from '@bellawatt/use-inputs';
import {Bar} from 'react-chartjs-2';
import {formatAsThousands, formatTime} from '@generic/functions/formatters';
import calcBatterySchedule from '@generic/functions/vehicleSet/calcBatterySchedule';
import {YEAR} from '@generic/data/assumptions';
import {useIntl} from 'react-intl';
import times from 'lodash/times';

export default function ChargingSiteMonthlyDemandChart({timeRange}) {
  const theme = useTheme();
  const {formatMessage} = useIntl();

  const {vehicleSets} = useInputs();

  const getTooltipTitle = (tick) => {
    if (timeRange.value === 'month') {
      return formatMessage({id: `timePeriods.months.${tick}.abbreviated`});
    }
    if (timeRange.value === 'day') {
      return formatMessage({
        id: `timePeriods.days.${tick}.abbreviated`,
      });
    }
    return formatTime(tick);
  };

  const getXAxisLabel = (tick) => {
    if (timeRange.value === 'month') {
      return formatMessage({id: `timePeriods.months.${tick}.abbreviated`});
    }
    if (timeRange.value === 'day') {
      return formatMessage({
        id: `timePeriods.days.${tick}.abbreviated`,
      });
    }
    return formatTime(+tick % 24);
  };

  let labels;
  if (timeRange.value === 'month') {
    labels = times(12, (i) => i);
  } else if (timeRange.value === 'day') {
    labels = times(7, (i) => i);
  } else {
    labels = times(24, (i) => i);
  }

  const loadProfileOfZeros = new LoadProfile(
    times(8760, () => 0),
    {year: YEAR}
  );

  const aggregateLoadProfile = vehicleSets.reduce((agg, vehicleSet) => {
    const loadProfileData = calcBatterySchedule(vehicleSet).map((hour) => hour.chargedKwh);
    const loadProfile = new LoadProfile(loadProfileData, {year: YEAR});
    return loadProfile.aggregate(agg);
  }, loadProfileOfZeros);

  let chartData;
  switch (timeRange.value) {
    case 'month':
      chartData = aggregateLoadProfile.maxByMonth();
      break;
    case 'day':
      chartData = times(7, (day) => aggregateLoadProfile.filterBy({daysOfWeek: [day]}).max());
      break;
    default:
      chartData = times(24, (hour) => aggregateLoadProfile.filterBy({hourStarts: [hour]}).max());
      break;
  }

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: theme.palette.primary.main,
        categoryPercentage: 1,
        barPercentage: 0.8,
      },
    ],
  };

  const options = {
    tooltips: {
      callbacks: {
        title: (data) => getTooltipTitle(data[0].xLabel),
        label: (data) => `${formatAsThousands(+data.value)} kW`,
      },
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.common.black,
            fontSize: 14,
            callback: (value) => `${formatAsThousands(value)} kW`,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: getXAxisLabel,
            fontColor: theme.palette.common.black,
            fontSize: 14,
            maxRotation: 45,
            minRotation: 45,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <Box height={300}>
      <Bar data={data} options={options} datasetKeyProvider={(d) => d.idx} />
    </Box>
  );
}
