import {getHoursOfYear} from '@generic/functions/time';
import calcPlugSchedule from '@generic/functions/vehicleSet/calcPlugSchedule';
import calcKwhPerDay from '@generic/functions/vehicleSet/calcKwhPerDay';
import {YEAR} from '@generic/data/assumptions';

const calcChargingHour = ({
  vehicleSet,
  startKwh,
  hourStart,
  plugSchedule,
  evenPowerDrawInKwhPerHour,
}) => {
  const {
    vehicle: {batteryCapacityInKwh},
    vehicleCount,
  } = vehicleSet;
  const {chargingApproach, portKw} = plugSchedule[hourStart];

  const totalPortKw = portKw * vehicleCount;

  const availablePowerInKw =
    chargingApproach === 'Max' ? totalPortKw : Math.min(totalPortKw, evenPowerDrawInKwhPerHour); // even if approach is "even", the available power shouldn't surpass the charger's kW availability

  const unchargedBatteryCapacity = vehicleCount * batteryCapacityInKwh - startKwh;
  const actualPowerDrawInKwh = chargingApproach
    ? Math.min(availablePowerInKw, unchargedBatteryCapacity)
    : 0;

  return {
    startKwh,
    finishKwh: startKwh + actualPowerDrawInKwh,
    dischargedKwh: 0,
    chargedKwh: actualPowerDrawInKwh,
  };
};

const isWorkday = ({vehicleSet: {workdays, workmonths}, date}) =>
  workdays.includes(date.dayOfWeek) && workmonths.includes(date.month);

const isDriving = ({plugSchedule, date, vehicleSet}) =>
  plugSchedule[date.hourStart].portKw === null && isWorkday({vehicleSet, date});

const calcBatterySchedule = (vehicleSet) => {
  const {
    vehicle: {batteryCapacityInKwh, idlingFactor = 1},
    vehicleCount,
  } = vehicleSet;
  const hoursOfYear = getHoursOfYear(YEAR);
  const plugSchedule = calcPlugSchedule(vehicleSet);
  const kWhPerDay = calcKwhPerDay(vehicleSet);

  const hoursOfCharging = plugSchedule.filter((plug) => plug.portKw).length;
  const hoursAwayFromChargers = 24 - hoursOfCharging;

  const dischargedKwhPerHour = (kWhPerDay * idlingFactor) / hoursAwayFromChargers;

  const evenPowerDrawInKwhPerHour = kWhPerDay / hoursOfCharging;

  const batterySchedule = [];

  hoursOfYear.forEach((date, currIdx) => {
    const {finishKwh} = batterySchedule[currIdx - 1] || {
      finishKwh: batteryCapacityInKwh * vehicleCount,
    };
    const {hourStart} = date;

    let hourEntry;
    if (isDriving({plugSchedule, date, vehicleSet})) {
      hourEntry = {
        startKwh: finishKwh,
        finishKwh: finishKwh - dischargedKwhPerHour,
        dischargedKwh: dischargedKwhPerHour,
        chargedKwh: 0,
      };
    } else {
      // charging
      hourEntry = calcChargingHour({
        vehicleSet,
        evenPowerDrawInKwhPerHour,
        startKwh: finishKwh,
        hourStart,
        plugSchedule,
      });
    }
    batterySchedule[currIdx] = hourEntry;
  });
  return batterySchedule;
};

export default calcBatterySchedule;
