import React from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import LineItemBill from '@generic/pages/ChargingCalculator/LineItemBill';

const useStyles = makeStyles(() => ({
  title: {fontWeight: 700},
}));

export default function DeliveryCharges({charges}) {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const total = {
    label: formatMessage({id: 'charging.content.costs.electricity.bill.deliveryCharges.total'}),
    charge: charges.reduce((acc, curr) => acc + curr.charge, 0),
  };

  return (
    <Box>
      <Typography variant="body2" className={classes.title}>
        {formatMessage({id: 'charging.content.costs.electricity.bill.deliveryCharges.title'})}
      </Typography>
      <LineItemBill charges={charges} total={total} />
    </Box>
  );
}
