import React, {useState} from 'react';
import {Box, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  inputHeader: {
    borderBottom: `7px solid ${theme.palette.primary.main}`,
  },
  inputHeaderButton: {
    width: '100%',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    '&:hover': {
      background: 'initial',
    },
  },
  chevron: {
    transition: 'transform 200ms ease',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
}));

export default function SidebarInput({children, title, defaultOpen = true}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const classes = useStyles();

  return (
    <Box mb={3}>
      <Box className={classes.inputHeader}>
        <Button
          variant="text"
          className={classes.inputHeaderButton}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {title}
          <KeyboardArrowDownIcon
            className={`${classes.chevron} ${isOpen ? classes.rotated : ''}`}
          />
        </Button>
      </Box>
      {isOpen && children}
    </Box>
  );
}
