import calcMilesPerYear from '@generic/functions/calcMilesPerYear';
import calcAnnualFossilFuelCosts from '@generic/functions/calcAnnualFossilFuelCosts';

const calcPerMileFossilFuelCosts = (inputs) => {
  const {vehicleSets} = inputs;

  const totalCost = calcAnnualFossilFuelCosts(inputs);
  const totalMiles = vehicleSets.reduce((sum, set) => sum + calcMilesPerYear(set), 0);

  return totalCost / totalMiles;
};

export default calcPerMileFossilFuelCosts;
