import {getRates} from '@generic/data/rates';
import calcChargerSetFacilityLoadProfile from '@generic/functions/calcChargerSetFacilityLoadProfile';
import {LoadProfile, RateCalculator} from '@bellawatt/electric-rate-engine';
import {YEAR} from '@generic/data/assumptions';
import calcScaledFacilityLoadProfile from '@generic/functions/calcScaledFacilityLoadProfile';
import {calcSiteSccRebate, calcFleetSccRebate} from '@generic/functions/calcSccRebate';

const calcAnnualChargerElectricityCostsAllRates = (inputs) => {
  const {separatelyMetered} = inputs;
  RateCalculator.shouldValidate = false;

  // get charger load profile
  const chargerLoadProfileData = calcChargerSetFacilityLoadProfile(inputs);
  const chargerLoadProfile = new LoadProfile(chargerLoadProfileData, {year: YEAR});
  const chargerMaxDemand = chargerLoadProfile.max();
  // if separately metered, run charger load profile through all rates
  if (separatelyMetered) {
    return getRates(inputs, chargerMaxDemand).map((rate) => {
      const rateCalculator = new RateCalculator({...rate, loadProfile: chargerLoadProfile});
      const annualCost = rateCalculator.annualCost();

      return {
        title: rate.title,
        name: rate.name,
        facility: 0,
        chargers: annualCost,
        total: annualCost,
        siteSccRebate: calcSiteSccRebate(inputs, rateCalculator),
        fleetSccRebate: calcFleetSccRebate(inputs, rateCalculator),
      };
    });
  }

  // if not separately metered...

  // get scaled facility load profile based on building type, current rate, monthly electric bill
  const scaledFacilityLoadProfile = calcScaledFacilityLoadProfile(inputs);

  // aggregate ev and scaled facility load profiles
  const aggregatedFacilityAndChargers = chargerLoadProfile.aggregate(scaledFacilityLoadProfile);

  const maxDemand = aggregatedFacilityAndChargers.max();

  // run aggregate load profile through all rates
  return getRates(inputs, maxDemand).map((rate) => {
    const chargerRateCalculator = new RateCalculator({...rate, loadProfile: chargerLoadProfile});
    const annualTotalCost = new RateCalculator({
      ...rate,
      loadProfile: aggregatedFacilityAndChargers,
    }).annualCost();

    const annualFacilityCost = new RateCalculator({
      ...rate,
      loadProfile: scaledFacilityLoadProfile,
    }).annualCost();

    const annualChargerCost = annualTotalCost - annualFacilityCost;

    return {
      title: rate.title,
      name: rate.name,
      facility: annualFacilityCost,
      chargers: annualChargerCost,
      total: annualTotalCost,
      siteSccRebate: calcSiteSccRebate(inputs, chargerRateCalculator),
      fleetSccRebate: calcFleetSccRebate(inputs, chargerRateCalculator),
    };
  });
};

export default calcAnnualChargerElectricityCostsAllRates;
