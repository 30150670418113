export const YEAR = 2019;
export const HOURS_IN_YEAR = 8760;

export const SEASONS = [
  'WINTER',
  'WINTER',
  'WINTER',
  'WINTER',
  'WINTER',
  'SUMMER',
  'SUMMER',
  'SUMMER',
  'SUMMER',
  'WINTER',
  'WINTER',
  'WINTER',
];

export const CON_ED_DEMAND_CUTOFF = 500;

export const YEARS_OF_OWNERSHIP = 12;

export const ELECTRIC_MAINTENANCE_COST_FACTOR =
  0.0004 * YEARS_OF_OWNERSHIP ** 4 -
  0.0128 * YEARS_OF_OWNERSHIP ** 3 +
  0.126 * YEARS_OF_OWNERSHIP ** 2 -
  0.1762 * YEARS_OF_OWNERSHIP +
  1.0563;

export const FOSSIL_FUEL_MAINTENANCE_COST_FACTOR =
  0.0002 * YEARS_OF_OWNERSHIP ** 4 -
  0.0065 * YEARS_OF_OWNERSHIP ** 3 +
  0.0547 * YEARS_OF_OWNERSHIP ** 2 +
  0.1258 * YEARS_OF_OWNERSHIP +
  0.7821;
