import {Button, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    backgroundColor: theme.toggleButtonGroup.toggleButton.backgroundColor,
    color: theme.toggleButtonGroup.toggleButton.color,
    border: theme.toggleButtonGroup.toggleButton.border,
    flex: 1,
    fontWeight: 600,
    paddingTop: () => theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 'auto',
    width: '100%',
    '&:last-child': {
      marginRight: theme.spacing(0),
    },
    '&.compact': {
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      paddingLeft: '0.25rem',
      paddingRight: '0.25rem',
      marginRight: theme.spacing(1),
      '&:last-child': {
        marginRight: theme.spacing(0),
      },
    },
  },
  active: {
    backgroundColor: theme.toggleButtonGroup.toggleButton.active.backgroundColor,
    color: theme.toggleButtonGroup.toggleButton.active.color,
    border: theme.toggleButtonGroup.toggleButton.active.border,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    '&:active, &:focus': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  toggleLabel: {
    color: theme.toggleButtonGroup.toggleLabel.color,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
  },
}));

export default function CheckboxButtonInput({checked, children, onClick, variant, ...rest}) {
  const classes = useStyles();

  return (
    <Button
      aria-checked={checked}
      aria-label={children}
      type="button"
      role="checkbox"
      onClick={onClick}
      className={`${classes.toggleButton} ${checked ? classes.active : ''} ${variant}`}
      {...rest}
    >
      {children}
    </Button>
  );
}
