import {Inputs} from '@bellawatt/use-inputs';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Switch, Route} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import Honeybadger from '@honeybadger-io/js';
import I18NextProvider from '@generic/utils/I18NextProvider';
import getConEdDefaults from '@generic/functions/getConEdDefaults';
import Home from '@generic/pages/Home';
import ChargingCalculator from '@generic/pages/ChargingCalculator/ChargingCalculator';
import SitePlanner from '@generic/pages/SitePlanner/SitePlanner';
import ReactGA from 'react-ga4';
import ReactIntlProvider from '@generic/utils/ReactIntlProvider';
import computed from '@generic/use-inputs/computed';
import theme from '@generic/utils/themeWithDefaults';
import {zonesByZipcode} from './data/zones';
import ErrorBoundary from './components/ErrorBoundary';

import './assets/stylesheets/icons.css';

const DEFAULT_LOCALE = 'en';

Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

export default function ConEd() {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  }
  const defaults = getConEdDefaults();
  const {zone: defaultZone} = defaults;

  const customComputed = {
    ...computed,
    zone: ({zipcode}) => zonesByZipcode[zipcode] || defaultZone,
  };

  return (
    <I18NextProvider>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Inputs
            computed={customComputed}
            defaults={defaults}
            ignored={['incentives']}
            options={{localStorageName: 'conEdInputs'}}
          >
            <ReactIntlProvider defaultLocale={DEFAULT_LOCALE}>
              <ErrorBoundary>
                <Switch>
                  <Route path="/fleets">
                    <ChargingCalculator />
                  </Route>
                  <Route path="/sites">
                    <SitePlanner />
                  </Route>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                </Switch>
              </ErrorBoundary>
            </ReactIntlProvider>
          </Inputs>
        </ThemeProvider>
      </HelmetProvider>
    </I18NextProvider>
  );
}
