import React from 'react';
import {Grid, Typography, Box, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import ChargingTimeRangeBreakdown from '@generic/pages/ChargingCalculator/ChargingTimeRangeBreakdown';
import ChargingBreakdownLabels from '@generic/pages/ChargingCalculator/ChargingBreakdownLabels';
import ChargingBreakdownDivider from '@generic/pages/ChargingCalculator/ChargingBreakdownDivider';
import {
  formatVehicleSetTitle,
  formatChargerTitle,
  formatTimeRange,
} from '@generic/functions/formatters';

const useStyles = makeStyles((theme) => ({
  labelsWrapper: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  row: {
    marginBottom: '3rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '2rem',
    },
  },
  indexBox: {
    border: `1px solid ${theme.palette.primary.light}`,
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  chargerInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  chargerInfoValue: {
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  chargerLabel: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1rem',
    color: theme.palette.gray[500],
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  vehicleImg: {
    marginRight: 16,
  },
}));

export default function VehicleIndexedChargingBreakdown() {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const {vehicleSets} = useInputs();

  return (
    <Grid container spacing={1}>
      <Box className={classes.labelsWrapper}>
        <ChargingBreakdownLabels
          labels={[
            formatMessage({id: 'charging.content.chargers.labels.vehicle'}),
            formatMessage({id: 'charging.content.chargers.labels.chargerType'}),
            formatMessage({id: 'charging.content.chargers.labels.timeRange'}),
          ]}
        />
      </Box>

      {vehicleSets.map((vehicleSet, idx) => (
        <Grid key={idx} item container xs={12} className={classes.row}>
          {/* Left Box */}
          <Grid item xs={12} md={4}>
            <Box
              p={3}
              height="100%"
              display="flex"
              alignItems="center"
              className={classes.indexBox}
            >
              <img
                src={vehicleSet.vehicle.imageUrl}
                alt={formatVehicleSetTitle(vehicleSet)}
                height="auto"
                width={71}
                className={classes.vehicleImg}
              />
              <Typography variant="body1">
                {formatVehicleSetTitle({
                  vehicleCount: vehicleSet.vehicleCount,
                  vehicle: vehicleSet.vehicle,
                })}
              </Typography>
            </Box>
          </Grid>

          {/* Divider */}
          <Grid item xs={12} md={1}>
            <ChargingBreakdownDivider />
          </Grid>

          {/* Right Box */}
          <Grid item xs={12} md={7}>
            {vehicleSet.chargingWindows.map(
              ({start, finish, charger: {chargerType, portKw, ports}}, idx) => (
                <ChargingTimeRangeBreakdown key={idx} timeRange={formatTimeRange(start, finish)}>
                  <Box className={classes.chargerInfo} display="flex">
                    <Box className={classes.chargerLabel}>
                      {formatMessage({id: 'charging.content.chargers.labels.chargerType'})}
                    </Box>
                    <Box>
                      <Typography variant="body1" className={classes.chargerInfoValue}>
                        {formatChargerTitle({
                          category: formatMessage({
                            id: `chargers.categories.${chargerType}`,
                          }),
                          power: portKw,
                        })}
                      </Typography>
                      <Typography variant="body2" className={classes.chargerInfoValue}>
                        {formatMessage({
                          id: `chargers.ports.${ports}`,
                        })}
                      </Typography>
                    </Box>
                  </Box>
                </ChargingTimeRangeBreakdown>
              )
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
