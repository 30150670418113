import {Box, makeStyles, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import ChevronIcon from '@generic/assets/images/icon-chevron.svg';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.common.black}`,
    borderBottom: `1px solid ${theme.palette.common.black}`,
    padding: '20px',

    [theme.breakpoints.up('md')]: {
      border: 'none',
    },
  },
  header: {
    fontFamily: theme.typography.primary.fontFamily,
    fontWeight: 600,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginBottom: '1rem',
    },
  },
  button: {
    outline: 'none',
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  childrenExpanded: {
    display: 'block',
    marginTop: '1rem',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
  },
  childrenCollapsed: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  chevronIcon: {
    transition: 'transform 100ms ease',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  chevronExpanded: {
    transform: 'rotate(180deg)',
  },
}));

export default function FooterLinkSection({header, children}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <button
        type="button"
        className={classes.button}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <Typography className={classes.header}>{header}</Typography>
        <img
          src={ChevronIcon}
          alt={formatMessage({id: isExpanded ? 'footer.collapse' : 'footer.expand'})}
          className={`${classes.chevronIcon} ${isExpanded ? classes.chevronExpanded : ''}`}
        />
      </button>
      <Box className={isExpanded ? classes.childrenExpanded : classes.childrenCollapsed}>
        {children}
      </Box>
    </Box>
  );
}
