import {Box, Button, makeStyles} from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import SidebarInput from '@generic/components/inputs/SidebarInput';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import SliderInput from '@generic/components/inputs/SliderInput';
import DropdownInput from '@generic/components/inputs/DropdownInput';
import VehicleSetInput from '@generic/components/inputs/VehicleSetInput';
import {formatAsDollarsPerGal, formatAsDollars} from '@generic/functions/formatters';
import buildingTypes from '@generic/data/buildingTypes';
import currentRates from '@generic/data/currentRates';
import booleanOptions from '@generic/data/booleanOptions';
import voltageOptions from '@generic/data/voltageOptions';
import {zonesByZipcode} from '@generic/data/zones';
import getConEdDefaults from '@generic/functions/getConEdDefaults';
import {DEFAULT_VEHICLE_SET} from '@generic/functions/getDefaults';
import SmallSourceLink from '@generic/components/SmallSourceLink';
import AutocompleteInput from '@generic/components/inputs/AutocompleteInput';
import siteAdditionalQualificationOptions from '../../data/siteAdditionalQualificationOptions';

const VEHICLE_SET_LIMIT = 5;

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: '800',
    outline: 'none',
    textDecoration: 'underline',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.light,
      textDecoration: 'underline',
    },
    '&:active, &:focus': {
      color: theme.palette.primary.dark,
    },
  },
  resetButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: '10em',
    fontWeight: 800,
    textTransform: 'none',
    padding: '0.75rem 1.5rem',
    marginTop: theme.spacing(5),
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&:active, &:focus': {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

export default function ChargingInputs() {
  const {formatMessage} = useIntl();
  const {
    gasolinePrice,
    dieselPrice,
    monthlyElectricBill,
    isOnMarketBasedRate,
    voltageLevel,
    separatelyMetered,
    buildingType,
    currentRate,
    vehicleSets,
    setInput,
    siteAdditionalQualifications,
    zipcode,
  } = useInputs();

  const classes = useStyles();

  const handleChangeVehicleSet = (idx, data) => {
    const newVehicleSets = cloneDeep(vehicleSets);
    newVehicleSets[idx] = data;
    setInput({vehicleSets: newVehicleSets});
  };

  const handleAddVehicleSet = () =>
    setInput({vehicleSets: [...vehicleSets, {...DEFAULT_VEHICLE_SET}]});

  const handleRemoveVehicleSet = (idxToRemove) =>
    setInput({
      vehicleSets: vehicleSets.filter((_, idx) => idx !== idxToRemove),
    });

  const handleResetInputs = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(formatMessage({id: 'inputs.resetConfirmation'}))) {
      setInput({...getConEdDefaults()});
    }
  };

  const isGasolineSliderVisible = vehicleSets.some(
    ({vehicle}) => vehicle.fossilFuelType === 'gasoline'
  );
  const isDieselSliderVisible = vehicleSets.some(
    ({vehicle}) => vehicle.fossilFuelType === 'diesel'
  );

  return (
    <Box width="100%">
      <SidebarInput title={formatMessage({id: 'charging.inputs.vehicleSets.title'})}>
        <Box mb={3} p={2}>
          {vehicleSets.map((vehicleSet, idx) => (
            <Box mb={2} key={idx}>
              <VehicleSetInput
                onRemove={() => handleRemoveVehicleSet(idx)}
                onChange={(data) => handleChangeVehicleSet(idx, data)}
                vehicleSet={vehicleSet}
              />
            </Box>
          ))}
          {vehicleSets.length < VEHICLE_SET_LIMIT && (
            <Button className={classes.button} onClick={handleAddVehicleSet} type="button">
              {formatMessage({
                id: 'charging.inputs.vehicleSets.addNewButton',
              })}
            </Button>
          )}
        </Box>
      </SidebarInput>

      <SidebarInput title={formatMessage({id: 'charging.inputs.fuel.title'})}>
        <Box mb={4} p={2}>
          {isGasolineSliderVisible && (
            <SliderInput
              label={formatMessage({
                id: 'charging.inputs.fuel.gasSliderLabel',
              })}
              value={gasolinePrice}
              formatFn={formatAsDollarsPerGal}
              onChange={(value) => setInput({gasolinePrice: value})}
              min={0}
              max={5}
              step={0.1}
            />
          )}
          {isDieselSliderVisible && (
            <SliderInput
              label={formatMessage({
                id: 'charging.inputs.fuel.dieselSliderLabel',
              })}
              value={dieselPrice}
              formatFn={formatAsDollarsPerGal}
              onChange={(value) => setInput({dieselPrice: value})}
              min={0}
              max={5}
              step={0.1}
            />
          )}
        </Box>
      </SidebarInput>

      <SidebarInput title={formatMessage({id: 'charging.inputs.advanced.title'})}>
        <Box mb={4} p={2}>
          <Box mb={5}>
            <DropdownInput
              title={formatMessage({
                id: 'charging.inputs.advanced.isOnMarketBasedRate.title',
              })}
              tooltip={formatMessage({
                id: 'charging.inputs.advanced.isOnMarketBasedRate.tooltip',
              })}
              options={booleanOptions.map(({id, translationKey}) => ({
                value: id,
                children: formatMessage({id: translationKey}),
              }))}
              value={isOnMarketBasedRate ? 'yes' : 'no'}
              onChange={(e) => setInput({isOnMarketBasedRate: e.target.value === 'yes'})}
              mb={0}
            />
            <SmallSourceLink
              text={formatMessage({id: 'charging.inputs.advanced.isOnMarketBasedRate.sourceLink'})}
              link="https://lite.coned.com/_external/cerates/supply_charges.asp"
            />
          </Box>
          <Box mb={5}>
            <AutocompleteInput
              title={formatMessage({
                id: 'inputs.advanced.zipcode.title',
              })}
              tooltip={formatMessage({
                id: 'inputs.advanced.zipcode.tooltip',
              })}
              options={Object.keys(zonesByZipcode)}
              onChange={(e, value) => {
                setInput({zipcode: value});
              }}
              value={zipcode}
            />
          </Box>
          <DropdownInput
            title={formatMessage({
              id: 'charging.inputs.advanced.voltageLevel.title',
            })}
            tooltip={formatMessage({
              id: 'charging.inputs.advanced.voltageLevel.tooltip',
            })}
            options={voltageOptions.map(({id, translationKey}) => ({
              value: id,
              children: formatMessage({id: translationKey}),
            }))}
            value={voltageLevel}
            onChange={(e) => setInput({voltageLevel: e.target.value})}
            mb={5}
          />
          <DropdownInput
            title={formatMessage({
              id: 'charging.inputs.advanced.separatelyMetered.title',
            })}
            tooltip={formatMessage({
              id: 'charging.inputs.advanced.separatelyMetered.tooltip',
            })}
            options={booleanOptions.map(({id, translationKey}) => ({
              value: id,
              children: formatMessage({id: translationKey}),
            }))}
            value={separatelyMetered ? 'yes' : 'no'}
            onChange={(e) => setInput({separatelyMetered: e.target.value === 'yes'})}
            mb={5}
          />
          {!separatelyMetered && (
            <>
              <DropdownInput
                title={formatMessage({
                  id: 'charging.inputs.advanced.buildingType.title',
                })}
                tooltip={formatMessage({
                  id: 'charging.inputs.advanced.buildingType.tooltip',
                })}
                options={buildingTypes.map(({id, translationKey}) => ({
                  value: id,
                  children: formatMessage({id: translationKey}),
                }))}
                value={buildingType}
                onChange={(e) => setInput({buildingType: e.target.value})}
                mb={5}
              />
              <DropdownInput
                title={formatMessage({
                  id: 'charging.inputs.advanced.currentRate.title',
                })}
                tooltip={formatMessage({
                  id: 'charging.inputs.advanced.currentRate.tooltip',
                })}
                options={currentRates.map(({id, translationKey}) => ({
                  value: id,
                  children: formatMessage({id: translationKey}),
                }))}
                value={currentRate.name}
                onChange={(e) => setInput({currentRate: e.target.value})}
                mb={5}
              />
              <SliderInput
                label={formatMessage({
                  id: 'charging.inputs.advanced.monthlyBillSliderLabel',
                })}
                tooltip={formatMessage({
                  id: 'charging.inputs.advanced.monthlyBillSliderTooltip',
                })}
                value={monthlyElectricBill}
                formatFn={formatAsDollars}
                onChange={(value) => setInput({monthlyElectricBill: value})}
                min={1000}
                max={20000}
                step={1000}
              />
            </>
          )}

          <DropdownInput
            title={formatMessage({
              id: 'inputs.advanced.siteAdditionalQualifications.title',
            })}
            tooltip={formatMessage({
              id: 'inputs.advanced.siteAdditionalQualifications.tooltip',
            })}
            options={siteAdditionalQualificationOptions.map(({id, translationKey}) => ({
              value: id,
              children: formatMessage({id: translationKey}),
            }))}
            value={siteAdditionalQualifications}
            onChange={(e) => setInput({siteAdditionalQualifications: e.target.value})}
          />

          <Button className={classes.resetButton} onClick={handleResetInputs}>
            {formatMessage({id: 'inputs.reset'})}
          </Button>
        </Box>
      </SidebarInput>
    </Box>
  );
}
