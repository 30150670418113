import {Button, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(({ctaButton}) => ({
  ctaButton: {
    ...ctaButton,
    '&:hover': {
      ...ctaButton['&:hover'],
      textDecoration: 'none',
    },
  },
}));

export default function CtaButton({className, ...rest}) {
  const classes = useStyles();
  return <Button className={`${className || ''} ${classes.ctaButton}`} {...rest} />;
}
