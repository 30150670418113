import {useState, useEffect} from 'react';
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import {Box} from '@material-ui/core';
import Honeybadger from '@honeybadger-io/js';
import Header from '@generic/components/Header';
import Footer from '@generic/components/Footer';
import CtaButton from '@generic/components/CtaButton';
import getConEdDefaults from '../functions/getConEdDefaults';

function ErrorFallback() {
  const {formatMessage} = useIntl();
  const {setInput} = useInputs();

  const resetInputs = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(formatMessage({id: 'inputs.resetConfirmation'}))) {
      setInput({...getConEdDefaults()});
    }
  };

  return (
    <Box width="100%" minHeight="100vh" display="flex" flexDirection="column">
      <Header />
      <Box flex="1 1" textAlign="center" maxWidth="800px" mx="auto" p="40px">
        <div role="alert">
          <h1>{formatMessage({id: 'errorBoundary.header'})}</h1>
          <p>{formatMessage({id: 'errorBoundary.body'})}</p>
          <Box mt="40px">
            <CtaButton
              onClick={() => {
                resetInputs();
                // useInputs debounces localStorage updates by a hardcoded 500ms. This is a hack to
                // ensure that the inputs are reset before the page reloads.
                // A better solution would be to change setInput within use-inputs to either accept
                // a callback or return a promise.
                setTimeout(() => {
                  window.location = window.location.pathname;
                }, 750);
              }}
            >
              {formatMessage({id: 'errorBoundary.resetButton'})}
            </CtaButton>
          </Box>
        </div>
      </Box>
      <Footer />
    </Box>
  );
}

function ErrorBoundaryTester() {
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    throw new Error('ErrorBoundary test');
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('triggerError')) {
      setHasError(true);
    }
  }, []);

  return null;
}

const report = (error) => {
  Honeybadger.notify(error);
};

export default function ErrorBoundary({children}) {
  const isNotProduction = ['development', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT);

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={report}>
      {isNotProduction && <ErrorBoundaryTester />}
      {children}
    </ReactErrorBoundary>
  );
}
