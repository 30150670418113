import React from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';
import {formatAsDollars} from '@generic/functions/formatters';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  imageWrapper: {
    borderBottom: `4px solid ${theme.palette.primary.light}`,
  },
  image: {
    height: 100,
    width: 'auto',
  },
  infoWrapper: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -6,
      width: 48,
      height: 1,
      backgroundColor: theme.palette.gray[500],
    },
  },
  infoHeader: {
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.palette.gray[500],
    marginBottom: '0.5rem',
  },
  info: {
    fontSize: '1.125rem',
    marginBottom: '1.5rem',
  },
}));

export default function ChargerInfoBreakdown({chargerSet}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  return (
    <Box className={classes.root} mb={5}>
      <Box p={3} display="flex" justifyContent="center" className={classes.imageWrapper}>
        <img src={chargerSet.charger.imageUrl} alt="" className={classes.image} />
      </Box>
      <Box p={3}>
        <Box className={classes.infoWrapper}>
          <Typography className={classes.infoHeader}>
            {formatMessage({id: 'planning.content.chargers.data.type'})}
          </Typography>
          <Typography className={classes.info}>{chargerSet.type}</Typography>
        </Box>

        <Box className={classes.infoWrapper}>
          <Typography className={classes.infoHeader}>
            {formatMessage({id: 'planning.content.chargers.data.powerLevel'})}
          </Typography>
          <Typography className={classes.info}>{`${chargerSet.charger.portKw}kW`}</Typography>
        </Box>

        <Box className={classes.infoWrapper}>
          <Typography className={classes.infoHeader}>
            {formatMessage({id: 'planning.content.chargers.data.portCount'})}
          </Typography>
          <Typography className={classes.info}>{chargerSet.charger.ports}</Typography>
        </Box>

        <Box className={classes.infoWrapper}>
          <Typography className={classes.infoHeader}>
            {formatMessage({id: 'planning.content.chargers.data.count'})}
          </Typography>
          <Typography className={classes.info}>{chargerSet.chargerCount}</Typography>
        </Box>

        <Box className={classes.infoWrapper}>
          <Typography className={classes.infoHeader}>
            {formatMessage({id: 'planning.content.chargers.data.estimatedCost'})}
          </Typography>
          <Typography className={classes.info}>
            {formatAsDollars(chargerSet.charger.estimatedCost)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
