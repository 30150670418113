import React from 'react';
import {Box} from '@material-ui/core';
import VehicleSetCard from '@generic/components/VehicleSetCard';
import {useInputs} from '@bellawatt/use-inputs';

export default function ChargingVehicles() {
  const {vehicleSets} = useInputs();
  return (
    <Box>
      {vehicleSets.map((vehicleSet, idx) => (
        <VehicleSetCard key={idx} vehicleSet={vehicleSet} />
      ))}
    </Box>
  );
}
