const buildingTypes = [
  {id: 'smallOffice', translationKey: 'charging.inputs.advanced.buildingType.smallOffice'},
  {id: 'mediumOffice', translationKey: 'charging.inputs.advanced.buildingType.mediumOffice'},
  {id: 'largeOffice', translationKey: 'charging.inputs.advanced.buildingType.largeOffice'},
  {id: 'smallHotel', translationKey: 'charging.inputs.advanced.buildingType.smallHotel'},
  {id: 'largeHotel', translationKey: 'charging.inputs.advanced.buildingType.largeHotel'},
  {id: 'primarySchool', translationKey: 'charging.inputs.advanced.buildingType.primarySchool'},
  {id: 'secondarySchool', translationKey: 'charging.inputs.advanced.buildingType.secondarySchool'},
  {
    id: 'standaloneRetail',
    translationKey: 'charging.inputs.advanced.buildingType.standaloneRetail',
  },
  {id: 'stripMall', translationKey: 'charging.inputs.advanced.buildingType.stripMall'},
  {id: 'warehouse', translationKey: 'charging.inputs.advanced.buildingType.warehouse'},
  {
    id: 'fullServiceRestaurant',
    translationKey: 'charging.inputs.advanced.buildingType.fullServiceRestaurant',
  },
  {
    id: 'quickServiceRestaurant',
    translationKey: 'charging.inputs.advanced.buildingType.quickServiceRestaurant',
  },
  {id: 'outpatient', translationKey: 'charging.inputs.advanced.buildingType.outpatient'},
];

export default buildingTypes;
