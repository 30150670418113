const secondarySchoolLoadProfile = [
  4.84, 4.98, 5.09, 5.29, 5.78, 6.8, 10.53, 10.59, 11.73, 12.5, 12.96, 12.81, 12.34, 11.32, 9.79,
  8.66, 7.88, 7.82, 7.33, 6.88, 6.56, 4.77, 4.77, 4.75, 4.77, 4.84, 4.86, 5.11, 5.58, 6.61, 10.18,
  10.27, 11.46, 12.4, 12.88, 12.74, 12.27, 11.23, 9.65, 8.58, 7.8, 7.76, 7.3, 6.84, 6.5, 4.69, 4.72,
  4.67, 4.81, 4.89, 4.91, 5.11, 5.6, 6.62, 10.37, 10.57, 11.67, 12.56, 13.08, 12.9, 12.38, 11.29,
  9.72, 8.65, 7.93, 7.83, 7.31, 6.91, 6.63, 4.85, 4.85, 4.79, 4.84, 4.88, 4.94, 5.21, 5.68, 6.72,
  10.32, 10.46, 11.55, 12.37, 12.84, 12.65, 12.12, 11.1, 9.56, 8.49, 7.76, 7.65, 7.11, 6.64, 6.31,
  4.56, 4.53, 4.47, 4.08, 4.09, 3.94, 3.9, 3.86, 3.83, 6.14, 4.47, 3.83, 3.66, 3.62, 3.45, 3.3,
  3.26, 3.2, 3.27, 3.77, 4.77, 5.03, 5.21, 5.35, 3.98, 4.08, 3.97, 3.43, 3.51, 3.48, 3.4, 3.4, 3.36,
  5.6, 3.87, 3.49, 3.21, 3.08, 2.96, 2.91, 2.9, 2.92, 3.02, 3.47, 4.49, 4.62, 4.74, 4.84, 3.28,
  3.49, 3.54, 4.26, 4.35, 4.4, 4.56, 5.02, 6.09, 9.6, 9.82, 11.07, 12.04, 12.62, 12.51, 12.11, 11.1,
  9.57, 8.48, 7.7, 7.71, 7.23, 6.8, 6.51, 4.78, 4.83, 4.82, 4.84, 4.98, 5.09, 5.29, 5.78, 6.8,
  10.53, 10.59, 11.73, 12.5, 12.96, 12.81, 12.34, 11.32, 9.79, 8.66, 7.88, 7.82, 7.33, 6.88, 6.56,
  4.77, 4.77, 4.75, 4.77, 4.84, 4.86, 5.11, 5.58, 6.61, 10.18, 10.27, 11.46, 12.4, 12.88, 12.74,
  12.27, 11.23, 9.65, 8.58, 7.8, 7.76, 7.3, 6.84, 6.5, 4.69, 4.72, 4.67, 4.81, 4.89, 4.91, 5.11,
  5.6, 6.62, 10.37, 10.57, 11.67, 12.56, 13.08, 12.9, 12.38, 11.29, 9.72, 8.65, 7.93, 7.83, 7.31,
  6.91, 6.63, 4.85, 4.85, 4.79, 4.84, 4.88, 4.94, 5.21, 5.68, 6.72, 10.32, 10.46, 11.55, 12.37,
  12.84, 12.65, 12.12, 11.1, 9.56, 8.49, 7.76, 7.65, 7.11, 6.64, 6.31, 4.56, 4.53, 4.47, 4.08, 4.09,
  3.94, 3.9, 3.86, 3.83, 6.14, 4.47, 3.83, 3.66, 3.62, 3.45, 3.3, 3.26, 3.2, 3.27, 3.77, 4.77, 5.03,
  5.21, 5.35, 3.98, 4.08, 3.97, 3.43, 3.51, 3.48, 3.4, 3.4, 3.36, 5.6, 3.87, 3.49, 3.21, 3.08, 2.96,
  2.91, 2.9, 2.92, 3.02, 3.47, 4.49, 4.62, 4.74, 4.84, 3.28, 3.49, 3.54, 4.26, 4.35, 4.4, 4.56,
  5.02, 6.09, 9.6, 9.82, 11.07, 12.04, 12.62, 12.51, 12.11, 11.1, 9.57, 8.48, 7.7, 7.71, 7.23, 6.8,
  6.51, 4.78, 4.83, 4.82, 4.84, 4.98, 5.09, 5.29, 5.78, 6.8, 10.53, 10.59, 11.73, 12.5, 12.96,
  12.81, 12.34, 11.32, 9.79, 8.66, 7.88, 7.82, 7.33, 6.88, 6.56, 4.77, 4.77, 4.75, 4.77, 4.84, 4.86,
  5.11, 5.58, 6.61, 10.18, 10.27, 11.46, 12.4, 12.88, 12.74, 12.27, 11.23, 9.65, 8.58, 7.8, 7.76,
  7.3, 6.84, 6.5, 4.69, 4.72, 4.67, 4.81, 4.89, 4.91, 5.11, 5.6, 6.62, 10.37, 10.57, 11.67, 12.56,
  13.08, 12.9, 12.38, 11.29, 9.72, 8.65, 7.93, 7.83, 7.31, 6.91, 6.63, 4.85, 4.85, 4.79, 4.84, 4.88,
  4.94, 5.21, 5.68, 6.72, 10.32, 10.46, 11.55, 12.37, 12.84, 12.65, 12.12, 11.1, 9.56, 8.49, 7.76,
  7.65, 7.11, 6.64, 6.31, 4.56, 4.53, 4.47, 4.08, 4.09, 3.94, 3.9, 3.86, 3.83, 6.14, 4.47, 3.83,
  3.66, 3.62, 3.45, 3.3, 3.26, 3.2, 3.27, 3.77, 4.77, 5.03, 5.21, 5.35, 3.98, 4.08, 3.97, 3.43,
  3.51, 3.48, 3.4, 3.4, 3.36, 5.6, 3.87, 3.49, 3.21, 3.08, 2.96, 2.91, 2.9, 2.92, 3.02, 3.47, 4.49,
  4.62, 4.74, 4.84, 3.28, 3.49, 3.54, 4.26, 4.35, 4.4, 4.56, 5.02, 6.09, 9.6, 9.82, 11.07, 12.04,
  12.62, 12.51, 12.11, 11.1, 9.57, 8.48, 7.7, 7.71, 7.23, 6.8, 6.51, 4.78, 4.83, 4.82, 4.84, 4.98,
  5.09, 5.29, 5.78, 6.8, 10.53, 10.59, 11.73, 12.5, 12.96, 12.81, 12.34, 11.32, 9.79, 8.66, 7.88,
  7.82, 7.33, 6.88, 6.56, 4.77, 4.77, 4.75, 4.77, 4.84, 4.86, 5.11, 5.58, 6.61, 10.18, 10.27, 11.46,
  12.4, 12.88, 12.74, 12.27, 11.23, 9.65, 8.58, 7.8, 7.76, 7.3, 6.84, 6.5, 4.69, 4.72, 4.67, 4.81,
  4.89, 4.91, 5.11, 5.6, 6.62, 10.37, 10.57, 11.67, 12.56, 13.08, 12.9, 12.38, 11.29, 9.72, 8.65,
  7.93, 7.83, 7.31, 6.91, 6.63, 4.85, 4.85, 4.79, 4.84, 4.88, 4.94, 5.21, 5.68, 6.72, 10.32, 10.46,
  11.55, 12.37, 12.84, 12.65, 12.12, 11.1, 9.56, 8.49, 7.76, 7.65, 7.11, 6.64, 6.31, 4.56, 4.53,
  4.47, 4.08, 4.09, 3.94, 3.9, 3.86, 3.83, 6.14, 4.47, 3.83, 3.66, 3.62, 3.45, 3.3, 3.26, 3.2, 3.27,
  3.77, 4.77, 5.03, 5.21, 5.35, 3.98, 4.08, 3.97, 3.43, 3.51, 3.48, 3.4, 3.4, 3.36, 5.6, 3.87, 3.49,
  3.21, 3.08, 2.96, 2.91, 2.9, 2.92, 3.02, 3.47, 4.49, 4.62, 4.74, 4.84, 3.28, 3.49, 3.54, 4.26,
  4.35, 4.4, 4.56, 5.02, 6.09, 9.6, 9.82, 11.07, 12.04, 12.62, 12.51, 12.11, 11.1, 9.57, 8.48, 7.7,
  7.71, 7.23, 6.8, 6.51, 4.78, 4.83, 4.82, 4.84, 4.98, 5.09, 5.29, 5.78, 6.8, 10.53, 10.59, 11.73,
  12.5, 12.96, 12.81, 12.34, 11.32, 9.79, 8.66, 7.88, 7.82, 7.33, 6.88, 6.56, 4.77, 4.77, 4.75,
  4.77, 4.84, 4.86, 5.11, 5.58, 6.61, 10.18, 10.27, 11.46, 12.4, 12.88, 12.74, 12.27, 11.23, 9.65,
  8.58, 7.8, 7.76, 7.3, 6.84, 6.5, 4.69, 4.72, 4.67, 4.81, 4.89, 4.91, 5.11, 5.6, 6.62, 10.37,
  10.57, 11.67, 12.56, 13.08, 12.9, 12.38, 11.29, 9.72, 8.65, 7.93, 7.83, 7.31, 6.91, 6.63, 4.85,
  4.85, 4.79, 3.97, 4.02, 4.04, 4.22, 4.72, 5.82, 8.7, 9.21, 10.8, 11.91, 12.59, 12.69, 12.35,
  11.34, 9.56, 8.22, 6.98, 6.93, 6.54, 6.0, 5.67, 4.27, 4.18, 4.1, 3.63, 3.62, 3.45, 3.38, 3.44,
  3.45, 5.65, 3.84, 3.38, 3.13, 2.99, 2.83, 2.78, 2.82, 2.79, 2.87, 3.11, 4.31, 4.88, 5.13, 5.3,
  3.88, 3.98, 3.96, 3.36, 3.45, 3.43, 3.36, 3.38, 3.37, 5.51, 3.65, 3.44, 3.13, 2.95, 2.87, 2.85,
  2.84, 2.79, 2.81, 2.96, 4.01, 4.52, 4.68, 4.78, 3.31, 3.49, 3.6, 4.37, 4.5, 4.56, 4.75, 5.22,
  6.27, 9.78, 9.94, 11.34, 12.25, 12.67, 12.5, 12.02, 10.92, 9.36, 8.24, 7.07, 7.06, 6.82, 6.34,
  6.03, 4.48, 4.46, 4.44, 4.44, 4.53, 4.58, 4.76, 5.25, 6.3, 9.55, 9.85, 11.34, 12.26, 12.75, 12.68,
  12.23, 11.25, 9.66, 8.53, 7.38, 7.33, 7.19, 6.78, 6.42, 4.67, 4.67, 4.65, 4.64, 4.71, 4.74, 4.94,
  5.41, 6.44, 9.83, 10.03, 11.42, 12.3, 12.75, 12.59, 12.14, 11.02, 9.39, 8.28, 7.1, 7.08, 7.05,
  6.63, 6.33, 4.59, 4.55, 4.54, 4.54, 4.57, 4.55, 4.7, 5.12, 6.15, 9.34, 9.46, 10.82, 11.72, 12.3,
  12.32, 11.96, 11.03, 9.48, 8.34, 7.09, 6.99, 6.52, 5.96, 5.59, 4.25, 4.11, 3.99, 3.97, 4.02, 4.04,
  4.22, 4.72, 5.82, 8.7, 9.21, 10.8, 11.91, 12.59, 12.69, 12.35, 11.34, 9.56, 8.22, 6.98, 6.93,
  6.54, 6.0, 5.67, 4.27, 4.18, 4.1, 3.63, 3.62, 3.45, 3.38, 3.44, 3.45, 5.65, 3.84, 3.38, 3.13,
  2.99, 2.83, 2.78, 2.82, 2.79, 2.87, 3.11, 4.31, 4.88, 5.13, 5.3, 3.88, 3.98, 3.96, 3.36, 3.45,
  3.43, 3.36, 3.38, 3.37, 5.51, 3.65, 3.44, 3.13, 2.95, 2.87, 2.85, 2.84, 2.79, 2.81, 2.96, 4.01,
  4.52, 4.68, 4.78, 3.31, 3.49, 3.6, 4.37, 4.5, 4.56, 4.75, 5.22, 6.27, 9.78, 9.94, 11.34, 12.25,
  12.67, 12.5, 12.02, 10.92, 9.36, 8.24, 7.07, 7.06, 6.82, 6.34, 6.03, 4.48, 4.46, 4.44, 4.44, 4.53,
  4.58, 4.76, 5.25, 6.3, 9.55, 9.85, 11.34, 12.26, 12.75, 12.68, 12.23, 11.25, 9.66, 8.53, 7.38,
  7.33, 7.19, 6.78, 6.42, 4.67, 4.67, 4.65, 4.64, 4.71, 4.74, 4.94, 5.41, 6.44, 9.83, 10.03, 11.42,
  12.3, 12.75, 12.59, 12.14, 11.02, 9.39, 8.28, 7.1, 7.08, 7.05, 6.63, 6.33, 4.59, 4.55, 4.54, 4.54,
  4.57, 4.55, 4.7, 5.12, 6.15, 9.34, 9.46, 10.82, 11.72, 12.3, 12.32, 11.96, 11.03, 9.48, 8.34,
  7.09, 6.99, 6.52, 5.96, 5.59, 4.25, 4.11, 3.99, 3.97, 4.02, 4.04, 4.22, 4.72, 5.82, 8.7, 9.21,
  10.8, 11.91, 12.59, 12.69, 12.35, 11.34, 9.56, 8.22, 6.98, 6.93, 6.54, 6.0, 5.67, 4.27, 4.18, 4.1,
  3.63, 3.62, 3.45, 3.38, 3.44, 3.45, 5.65, 3.84, 3.38, 3.13, 2.99, 2.83, 2.78, 2.82, 2.79, 2.87,
  3.11, 4.31, 4.88, 5.13, 5.3, 3.88, 3.98, 3.96, 3.36, 3.45, 3.43, 3.36, 3.38, 3.37, 5.51, 3.65,
  3.44, 3.13, 2.95, 2.87, 2.85, 2.84, 2.79, 2.81, 2.96, 4.01, 4.52, 4.68, 4.78, 3.31, 3.49, 3.6,
  4.37, 4.5, 4.56, 4.75, 5.22, 6.27, 9.78, 9.94, 11.34, 12.25, 12.67, 12.5, 12.02, 10.92, 9.36,
  8.24, 7.07, 7.06, 6.82, 6.34, 6.03, 4.48, 4.46, 4.44, 4.44, 4.53, 4.58, 4.76, 5.25, 6.3, 9.55,
  9.85, 11.34, 12.26, 12.75, 12.68, 12.23, 11.25, 9.66, 8.53, 7.38, 7.33, 7.19, 6.78, 6.42, 4.67,
  4.67, 4.65, 4.64, 4.71, 4.74, 4.94, 5.41, 6.44, 9.83, 10.03, 11.42, 12.3, 12.75, 12.59, 12.14,
  11.02, 9.39, 8.28, 7.1, 7.08, 7.05, 6.63, 6.33, 4.59, 4.55, 4.54, 4.54, 4.57, 4.55, 4.7, 5.12,
  6.15, 9.34, 9.46, 10.82, 11.72, 12.3, 12.32, 11.96, 11.03, 9.48, 8.34, 7.09, 6.99, 6.52, 5.96,
  5.59, 4.25, 4.11, 3.99, 3.97, 4.02, 4.04, 4.22, 4.72, 5.82, 8.7, 9.21, 10.8, 11.91, 12.59, 12.69,
  12.35, 11.34, 9.56, 8.22, 6.98, 6.93, 6.54, 6.0, 5.67, 4.27, 4.18, 4.1, 3.63, 3.62, 3.45, 3.38,
  3.44, 3.45, 5.65, 3.84, 3.38, 3.13, 2.99, 2.83, 2.78, 2.82, 2.79, 2.87, 3.11, 4.31, 4.88, 5.13,
  5.3, 3.88, 3.98, 3.96, 3.36, 3.45, 3.43, 3.36, 3.38, 3.37, 5.51, 3.65, 3.44, 3.13, 2.95, 2.87,
  2.85, 2.84, 2.79, 2.81, 2.96, 4.01, 4.52, 4.68, 4.78, 3.31, 3.49, 3.6, 4.37, 4.5, 4.56, 4.75,
  5.22, 6.27, 9.78, 9.94, 11.34, 12.25, 12.67, 12.5, 12.02, 10.92, 9.36, 8.24, 7.07, 7.06, 6.82,
  6.34, 6.03, 4.48, 4.46, 4.44, 4.44, 4.53, 4.58, 4.76, 5.25, 6.3, 9.55, 9.85, 11.34, 12.26, 12.75,
  12.68, 12.23, 11.25, 9.66, 8.53, 7.38, 7.33, 7.19, 6.78, 6.42, 4.67, 4.67, 4.65, 4.64, 4.71, 4.74,
  4.94, 5.41, 6.44, 9.83, 10.03, 11.42, 12.3, 12.75, 12.59, 12.14, 11.02, 9.39, 8.28, 7.1, 7.08,
  7.05, 6.63, 6.33, 4.59, 4.55, 4.54, 4.54, 4.57, 4.55, 4.7, 5.12, 6.15, 9.34, 9.46, 10.82, 11.72,
  12.3, 12.32, 11.96, 11.03, 9.48, 8.34, 7.09, 6.99, 6.52, 5.96, 5.59, 4.25, 4.11, 3.99, 3.96, 4.0,
  4.08, 4.41, 5.3, 7.39, 8.83, 10.13, 11.46, 12.57, 13.4, 13.64, 13.04, 11.61, 10.01, 8.54, 6.98,
  5.95, 6.1, 5.6, 4.75, 4.12, 3.98, 3.92, 3.42, 3.3, 3.2, 3.15, 3.11, 4.06, 3.54, 3.01, 2.83, 2.73,
  2.66, 2.6, 2.56, 2.55, 2.5, 2.52, 2.73, 3.24, 4.43, 4.66, 4.24, 3.8, 3.71, 3.5, 3.06, 3.05, 3.01,
  3.04, 3.07, 4.31, 3.47, 3.16, 2.9, 2.72, 2.55, 2.46, 2.44, 2.44, 2.51, 2.6, 2.69, 3.05, 4.11,
  4.19, 3.59, 3.13, 3.22, 3.14, 3.91, 3.99, 4.13, 4.49, 5.46, 7.86, 8.98, 10.35, 11.54, 12.34,
  12.52, 12.21, 11.34, 9.89, 8.56, 7.27, 6.17, 5.48, 5.97, 5.58, 4.62, 4.07, 4.05, 4.01, 4.04, 4.12,
  4.23, 4.6, 5.59, 8.15, 9.11, 10.44, 11.66, 12.51, 13.24, 13.53, 12.95, 11.51, 10.02, 8.77, 7.4,
  6.22, 6.44, 5.99, 4.87, 4.28, 4.22, 4.18, 4.25, 4.34, 4.46, 4.81, 5.75, 8.21, 9.33, 10.5, 11.55,
  12.31, 12.55, 12.19, 11.32, 9.89, 8.59, 7.26, 6.2, 5.54, 5.94, 5.55, 4.61, 4.05, 3.96, 4.0, 4.04,
  4.08, 4.22, 4.6, 5.51, 7.69, 9.04, 10.19, 11.39, 12.23, 12.54, 12.46, 11.96, 10.8, 9.39, 7.85,
  6.48, 5.71, 5.98, 5.56, 4.77, 4.1, 4.01, 3.96, 3.96, 4.0, 4.08, 4.41, 5.3, 7.39, 8.83, 10.13,
  11.46, 12.57, 13.4, 13.64, 13.04, 11.61, 10.01, 8.54, 6.98, 5.95, 6.1, 5.6, 4.75, 4.12, 3.98,
  3.92, 3.42, 3.3, 3.2, 3.15, 3.11, 4.06, 3.54, 3.01, 2.83, 2.73, 2.66, 2.6, 2.56, 2.55, 2.5, 2.52,
  2.73, 3.24, 4.43, 4.66, 4.24, 3.8, 3.71, 3.5, 3.06, 3.05, 3.04, 3.07, 4.31, 3.47, 3.16, 2.9, 2.72,
  2.55, 2.46, 2.44, 2.44, 2.51, 2.6, 2.69, 3.05, 4.11, 4.19, 3.59, 3.13, 3.22, 3.14, 3.91, 3.99,
  4.13, 4.49, 5.46, 7.86, 8.98, 10.35, 11.54, 12.34, 12.52, 12.21, 11.34, 9.89, 8.56, 7.27, 6.17,
  5.48, 5.97, 5.58, 4.62, 4.07, 4.05, 4.01, 4.04, 4.12, 4.23, 4.6, 5.59, 8.15, 9.11, 10.44, 11.66,
  12.51, 13.24, 13.53, 12.95, 11.51, 10.02, 8.77, 7.4, 6.22, 6.44, 5.99, 4.87, 4.28, 4.22, 4.18,
  4.25, 4.34, 4.46, 4.81, 5.75, 8.21, 9.33, 10.5, 11.55, 12.31, 12.55, 12.19, 11.32, 9.89, 8.59,
  7.26, 6.2, 5.54, 5.94, 5.55, 4.61, 4.05, 3.96, 4.0, 4.04, 4.08, 4.22, 4.6, 5.51, 7.69, 9.04,
  10.19, 11.39, 12.23, 12.54, 12.46, 11.96, 10.8, 9.39, 7.85, 6.48, 5.71, 5.98, 5.56, 4.77, 4.1,
  4.01, 3.96, 3.96, 4.0, 4.08, 4.41, 5.3, 7.39, 8.83, 10.13, 11.46, 12.57, 13.4, 13.64, 13.04,
  11.61, 10.01, 8.54, 6.98, 5.95, 6.1, 5.6, 4.75, 4.12, 3.98, 3.92, 3.42, 3.3, 3.2, 3.15, 3.11,
  4.06, 3.54, 3.01, 2.83, 2.73, 2.66, 2.6, 2.56, 2.55, 2.5, 2.52, 2.73, 3.24, 4.43, 4.66, 4.24, 3.8,
  3.71, 3.5, 3.06, 3.05, 3.01, 3.04, 3.07, 4.31, 3.47, 3.16, 2.9, 2.72, 2.55, 2.46, 2.44, 2.44,
  2.51, 2.6, 2.69, 3.05, 4.11, 4.19, 3.59, 3.13, 3.22, 3.14, 3.91, 3.99, 4.13, 4.49, 5.46, 7.86,
  8.98, 10.35, 11.54, 12.34, 12.52, 12.21, 11.34, 9.89, 8.56, 7.27, 6.17, 5.48, 5.97, 5.58, 4.62,
  4.07, 4.05, 4.01, 4.04, 4.12, 4.23, 4.6, 5.59, 8.15, 9.11, 10.44, 11.66, 12.51, 13.24, 13.53,
  12.95, 11.51, 10.02, 8.77, 7.4, 6.22, 6.44, 5.99, 4.87, 4.28, 4.22, 4.18, 4.25, 4.34, 4.46, 4.81,
  5.75, 8.21, 9.33, 10.5, 11.55, 12.31, 12.55, 12.19, 11.32, 9.89, 8.59, 7.26, 6.2, 5.54, 5.94,
  5.55, 4.61, 4.05, 3.96, 4.0, 4.04, 4.08, 4.22, 4.6, 5.51, 7.69, 9.04, 10.19, 11.39, 12.23, 12.54,
  12.46, 11.96, 10.8, 9.39, 7.85, 6.48, 5.71, 5.98, 5.56, 4.77, 4.1, 4.01, 3.96, 3.96, 4.0, 4.08,
  4.41, 5.3, 7.39, 8.83, 10.13, 11.46, 12.57, 13.4, 13.64, 13.04, 11.61, 10.01, 8.54, 6.98, 5.95,
  6.1, 5.6, 4.75, 4.12, 3.98, 3.92, 3.42, 3.3, 3.2, 3.15, 3.11, 4.06, 3.54, 3.01, 2.83, 2.73, 2.66,
  2.6, 2.56, 2.55, 2.5, 2.52, 2.73, 3.24, 4.43, 4.66, 4.24, 3.8, 3.71, 3.5, 3.06, 3.05, 3.01, 3.04,
  3.07, 4.31, 3.47, 3.16, 2.9, 2.72, 2.55, 2.46, 2.44, 2.44, 2.51, 2.6, 2.69, 3.05, 4.11, 4.19,
  3.59, 3.13, 3.22, 3.14, 3.91, 3.99, 4.13, 4.49, 5.46, 7.86, 8.98, 10.35, 11.54, 12.34, 12.52,
  12.21, 11.34, 9.89, 8.56, 7.27, 6.17, 5.48, 5.97, 5.58, 4.62, 4.07, 4.05, 4.01, 4.04, 4.12, 4.23,
  4.6, 5.59, 8.15, 9.11, 10.44, 11.66, 12.51, 13.24, 13.53, 12.95, 11.51, 10.02, 8.77, 7.4, 6.22,
  6.44, 5.99, 4.87, 4.28, 4.22, 4.18, 4.25, 4.34, 4.46, 4.81, 5.75, 8.21, 9.33, 10.5, 11.55, 12.31,
  12.55, 12.19, 11.32, 9.89, 8.59, 7.26, 6.2, 5.54, 5.94, 5.55, 4.61, 4.05, 3.96, 4.0, 4.04, 4.08,
  4.22, 4.6, 5.51, 7.69, 9.04, 10.19, 11.39, 12.23, 12.54, 12.46, 11.96, 10.8, 9.39, 7.85, 6.48,
  5.71, 5.98, 5.56, 4.77, 4.1, 4.01, 3.96, 3.96, 4.0, 4.08, 4.41, 5.3, 7.39, 8.83, 10.13, 11.46,
  12.57, 13.4, 13.64, 13.04, 11.61, 10.01, 8.54, 6.98, 5.95, 6.1, 5.6, 4.75, 4.12, 3.98, 3.92, 3.42,
  3.3, 3.2, 3.15, 3.11, 4.06, 3.54, 3.01, 2.83, 2.73, 2.66, 2.6, 2.56, 2.55, 2.5, 2.52, 2.73, 3.24,
  4.43, 4.66, 4.24, 3.8, 3.71, 3.5, 3.06, 3.05, 3.01, 3.04, 3.07, 4.31, 3.47, 3.16, 2.9, 2.72, 2.55,
  2.46, 2.44, 2.44, 2.51, 2.6, 2.69, 3.05, 4.11, 4.19, 3.59, 3.13, 3.22, 3.14, 3.83, 3.87, 3.99,
  4.42, 5.55, 7.44, 8.75, 10.53, 11.81, 12.8, 13.14, 13.09, 12.4, 10.99, 9.81, 8.44, 7.03, 5.73,
  5.51, 5.45, 4.28, 3.97, 3.8, 3.72, 3.77, 3.76, 3.91, 4.34, 5.43, 7.36, 8.69, 10.55, 11.79, 12.68,
  13.02, 12.95, 12.16, 10.48, 8.82, 7.34, 6.09, 5.1, 5.21, 5.27, 4.18, 3.99, 3.88, 3.82, 3.86, 3.9,
  4.02, 4.45, 5.6, 7.49, 8.83, 10.5, 11.6, 12.33, 12.41, 12.04, 11.12, 9.61, 8.41, 7.16, 6.04, 5.13,
  5.22, 5.31, 4.18, 3.97, 3.87, 3.82, 3.81, 3.83, 3.99, 4.43, 5.54, 7.55, 8.74, 10.44, 11.66, 12.62,
  13.13, 13.24, 12.5, 10.87, 9.36, 7.85, 6.45, 5.34, 5.29, 5.25, 4.19, 3.94, 3.83, 3.79, 3.8, 3.83,
  3.99, 4.41, 5.52, 7.5, 8.75, 10.5, 11.83, 13.23, 14.15, 14.55, 14.07, 12.45, 10.96, 9.24, 7.41,
  6.06, 5.83, 5.81, 4.4, 4.16, 3.99, 3.92, 3.42, 3.28, 3.18, 3.15, 3.1, 3.71, 3.2, 3.04, 2.96, 3.21,
  3.46, 3.44, 3.49, 3.48, 3.27, 3.3, 3.41, 3.4, 4.22, 4.76, 3.92, 3.8, 3.71, 3.43, 3.06, 3.01, 2.9,
  2.87, 2.85, 3.66, 2.49, 2.66, 2.51, 2.39, 2.26, 2.29, 2.25, 2.23, 2.37, 2.5, 2.64, 2.73, 3.52,
  3.93, 3.11, 3.08, 3.14, 3.0, 3.83, 3.87, 3.99, 4.42, 5.55, 7.44, 8.75, 10.53, 11.81, 12.8, 13.14,
  13.09, 12.4, 10.99, 9.81, 8.44, 7.03, 5.73, 5.51, 5.45, 4.28, 3.97, 3.8, 3.72, 3.77, 3.76, 3.91,
  4.34, 5.43, 7.36, 8.69, 10.55, 11.79, 12.68, 13.02, 12.95, 12.16, 10.48, 8.82, 7.34, 6.09, 5.1,
  5.21, 5.27, 4.18, 3.99, 3.88, 3.82, 3.86, 3.9, 4.02, 4.45, 5.6, 7.49, 8.83, 10.5, 11.6, 12.33,
  12.41, 12.04, 11.12, 9.61, 8.41, 7.16, 6.04, 5.13, 5.22, 5.31, 4.18, 3.97, 3.87, 3.82, 3.81, 3.83,
  3.99, 4.43, 5.54, 7.55, 8.74, 10.44, 11.66, 12.62, 13.13, 13.24, 12.5, 10.87, 9.36, 7.85, 6.45,
  5.34, 5.29, 5.25, 4.19, 3.94, 3.83, 3.79, 3.8, 3.83, 3.99, 4.41, 5.52, 7.5, 8.75, 10.5, 11.83,
  13.23, 14.15, 14.55, 14.07, 12.45, 10.96, 9.24, 7.41, 6.06, 5.83, 5.81, 4.4, 4.16, 3.99, 3.92,
  3.42, 3.28, 3.18, 3.15, 3.1, 3.71, 3.2, 3.04, 2.96, 3.21, 3.46, 3.44, 3.49, 3.48, 3.27, 3.3, 3.41,
  3.4, 4.22, 4.76, 3.92, 3.8, 3.71, 3.43, 3.06, 3.01, 2.9, 2.87, 2.85, 3.66, 2.49, 2.66, 2.51, 2.39,
  2.26, 2.29, 2.25, 2.23, 2.37, 2.5, 2.64, 2.73, 3.52, 3.93, 3.11, 3.08, 3.14, 3.0, 3.83, 3.87,
  3.99, 4.42, 5.55, 7.44, 8.75, 10.53, 11.81, 12.8, 13.14, 13.09, 12.4, 10.99, 9.81, 8.44, 7.03,
  5.73, 5.51, 5.45, 4.28, 3.97, 3.8, 3.72, 3.77, 3.76, 3.91, 4.34, 5.43, 7.36, 8.69, 10.55, 11.79,
  12.68, 13.02, 12.95, 12.16, 10.48, 8.82, 7.34, 6.09, 5.1, 5.21, 5.27, 4.18, 3.99, 3.88, 3.82,
  3.86, 3.9, 4.02, 4.45, 5.6, 7.49, 8.83, 10.5, 11.6, 12.33, 12.41, 12.04, 11.12, 9.61, 8.41, 7.16,
  6.04, 5.13, 5.22, 5.31, 4.18, 3.97, 3.87, 3.82, 3.81, 3.83, 3.99, 4.43, 5.54, 7.55, 8.74, 10.44,
  11.66, 12.62, 13.13, 13.24, 12.5, 10.87, 9.36, 7.85, 6.45, 5.34, 5.29, 5.25, 4.19, 3.94, 3.83,
  3.79, 3.8, 3.83, 3.99, 4.41, 5.52, 7.5, 8.75, 10.5, 11.83, 13.23, 14.15, 14.55, 14.07, 12.45,
  10.96, 9.24, 7.41, 6.06, 5.83, 5.81, 4.4, 4.16, 3.99, 3.92, 3.42, 3.28, 3.18, 3.15, 3.1, 3.71,
  3.2, 3.04, 2.96, 3.21, 3.46, 3.44, 3.49, 3.48, 3.27, 3.3, 3.41, 3.4, 4.22, 4.76, 3.92, 3.8, 3.71,
  3.43, 3.06, 3.01, 2.9, 2.87, 2.85, 3.66, 2.49, 2.66, 2.51, 2.39, 2.26, 2.29, 2.25, 2.23, 2.37,
  2.5, 2.64, 2.73, 3.52, 3.93, 3.11, 3.08, 3.14, 3.0, 3.83, 3.87, 3.99, 4.42, 5.55, 7.44, 8.75,
  10.53, 11.81, 12.8, 13.14, 13.09, 12.4, 10.99, 9.81, 8.44, 7.03, 5.73, 5.51, 5.45, 4.28, 3.97,
  3.8, 3.72, 3.77, 3.76, 3.91, 4.34, 5.43, 7.36, 8.69, 10.55, 11.79, 12.68, 13.02, 12.95, 12.16,
  10.48, 8.82, 7.34, 6.09, 5.1, 5.21, 5.27, 4.18, 3.99, 3.88, 3.82, 3.86, 3.9, 4.02, 4.45, 5.6,
  7.49, 8.83, 10.5, 11.6, 12.33, 12.41, 12.04, 11.12, 9.61, 8.41, 7.16, 6.04, 5.13, 5.22, 5.31,
  4.18, 3.97, 3.87, 3.82, 3.81, 3.83, 3.99, 4.43, 5.54, 7.55, 8.74, 10.44, 11.66, 12.62, 13.13,
  13.24, 12.5, 10.87, 9.36, 7.85, 6.45, 5.34, 5.29, 5.25, 4.19, 3.94, 3.83, 3.79, 3.8, 3.83, 3.99,
  4.41, 5.52, 7.5, 8.75, 10.5, 11.83, 13.23, 14.15, 14.55, 14.07, 12.45, 10.96, 9.24, 7.41, 6.06,
  5.83, 5.81, 4.4, 4.16, 3.99, 3.92, 3.42, 3.28, 3.18, 3.15, 3.1, 3.71, 3.2, 3.04, 2.96, 3.21, 3.46,
  3.44, 3.49, 3.48, 3.27, 3.3, 3.41, 3.4, 4.22, 4.76, 3.92, 3.8, 3.71, 3.43, 3.06, 3.01, 2.9, 2.87,
  2.85, 3.66, 2.49, 2.66, 2.51, 2.39, 2.26, 2.29, 2.25, 2.23, 2.37, 2.5, 2.64, 2.73, 3.52, 3.93,
  3.11, 3.08, 3.14, 3.0, 3.83, 3.87, 3.99, 4.42, 5.55, 7.44, 8.75, 10.53, 11.81, 12.8, 13.14, 13.09,
  12.4, 10.99, 9.81, 8.44, 7.03, 5.73, 5.51, 5.45, 4.28, 3.97, 3.8, 3.72, 3.77, 3.76, 3.91, 4.34,
  5.43, 7.36, 8.69, 10.55, 11.79, 12.68, 13.02, 12.95, 12.16, 10.48, 8.82, 7.34, 6.09, 5.1, 5.21,
  5.27, 4.18, 3.99, 3.88, 3.82, 3.98, 3.98, 4.04, 4.49, 5.16, 7.63, 10.29, 13.01, 15.15, 16.98,
  18.18, 18.66, 18.31, 16.7, 15.02, 13.44, 11.09, 8.81, 6.82, 6.53, 4.63, 4.39, 4.22, 4.09, 4.08,
  4.07, 4.13, 4.54, 5.2, 7.78, 10.19, 12.89, 15.27, 17.55, 19.52, 20.56, 20.22, 18.73, 16.45, 14.1,
  11.63, 9.37, 7.45, 7.12, 4.71, 4.4, 4.18, 4.06, 4.03, 4.03, 4.08, 4.5, 5.18, 7.02, 9.36, 11.66,
  13.57, 14.77, 15.36, 15.31, 14.59, 13.12, 11.8, 9.75, 7.39, 5.74, 4.81, 5.29, 4.31, 4.06, 3.93,
  3.86, 3.31, 3.13, 3.06, 3.01, 2.55, 3.03, 2.86, 2.7, 2.62, 2.83, 2.99, 3.03, 3.17, 3.17, 2.97,
  2.81, 2.81, 2.99, 3.26, 4.47, 3.94, 3.8, 3.7, 3.35, 3.0, 2.94, 2.83, 2.78, 2.31, 3.05, 2.31, 2.44,
  2.42, 2.36, 2.19, 2.21, 2.19, 2.17, 2.34, 2.44, 2.49, 2.61, 2.77, 3.85, 3.2, 3.11, 3.08, 2.93,
  3.74, 3.83, 3.97, 4.41, 4.98, 7.08, 8.87, 10.85, 12.56, 14.3, 15.77, 16.52, 16.37, 15.29, 14.28,
  12.3, 9.81, 7.73, 6.26, 6.22, 4.61, 4.35, 4.15, 4.02, 3.95, 3.98, 4.08, 4.52, 5.25, 7.47, 9.91,
  12.46, 14.45, 16.23, 17.35, 17.98, 17.66, 16.5, 14.99, 12.92, 10.18, 8.03, 6.48, 6.38, 4.55, 4.28,
  4.14, 3.99, 3.98, 3.98, 4.04, 4.49, 5.16, 7.63, 10.29, 13.01, 15.15, 16.98, 18.18, 18.66, 18.31,
  16.7, 15.02, 13.44, 11.09, 8.81, 6.82, 6.53, 4.63, 4.39, 4.22, 4.09, 4.08, 4.07, 4.13, 4.54, 5.2,
  7.78, 10.19, 12.89, 15.27, 17.55, 19.52, 20.56, 20.22, 18.73, 16.45, 14.1, 11.63, 9.37, 7.45,
  7.12, 4.71, 4.4, 4.18, 4.06, 4.03, 4.03, 4.08, 4.5, 5.18, 7.02, 9.36, 11.66, 13.57, 14.77, 15.36,
  15.31, 14.59, 13.12, 11.8, 9.75, 7.39, 5.74, 4.81, 5.29, 4.31, 4.06, 3.93, 3.86, 3.31, 3.13, 3.06,
  3.01, 2.55, 3.03, 2.86, 2.7, 2.62, 2.83, 2.99, 3.03, 3.17, 3.17, 2.97, 2.81, 2.81, 2.99, 3.26,
  4.47, 3.94, 3.8, 3.7, 3.35, 3.0, 2.94, 2.83, 2.78, 2.31, 3.05, 2.31, 2.44, 2.42, 2.36, 2.19, 2.21,
  2.19, 2.17, 2.34, 2.44, 2.49, 2.61, 2.77, 3.85, 3.2, 3.11, 3.08, 2.93, 3.74, 3.83, 3.97, 4.41,
  4.98, 7.08, 8.87, 10.85, 12.56, 14.3, 15.77, 16.52, 16.37, 15.29, 14.28, 12.3, 9.81, 7.73, 6.26,
  6.22, 4.61, 4.35, 4.15, 4.02, 3.95, 3.98, 4.08, 4.52, 5.25, 7.47, 9.91, 12.46, 14.45, 16.23,
  17.35, 17.98, 17.66, 16.5, 14.99, 12.92, 10.18, 8.03, 6.48, 6.38, 4.55, 4.28, 4.14, 3.99, 3.98,
  3.98, 4.04, 4.49, 5.16, 7.63, 10.29, 13.01, 15.15, 16.98, 18.18, 18.66, 18.31, 16.7, 15.02, 13.44,
  11.09, 8.81, 6.82, 6.53, 4.63, 4.39, 4.22, 4.09, 4.08, 4.07, 4.13, 4.54, 5.2, 7.78, 10.19, 12.89,
  15.27, 17.55, 19.52, 20.56, 20.22, 18.73, 16.45, 14.1, 11.63, 9.37, 7.45, 7.12, 4.71, 4.4, 4.18,
  4.06, 4.03, 4.03, 4.08, 4.5, 5.18, 7.02, 9.36, 11.66, 13.57, 14.77, 15.36, 15.31, 14.59, 13.12,
  11.8, 9.75, 7.39, 5.74, 4.81, 5.29, 4.31, 4.06, 3.93, 3.86, 3.31, 3.13, 3.06, 3.01, 2.55, 3.03,
  2.86, 2.7, 2.62, 2.83, 2.99, 3.03, 3.17, 3.17, 2.97, 2.81, 2.81, 2.99, 3.26, 4.47, 3.94, 3.8, 3.7,
  3.35, 3.0, 2.94, 2.83, 2.78, 2.31, 3.05, 2.31, 2.44, 2.42, 2.36, 2.19, 2.21, 2.19, 2.17, 2.34,
  2.44, 2.49, 2.61, 2.77, 3.85, 3.2, 3.11, 3.08, 2.93, 3.74, 3.83, 3.97, 4.41, 4.98, 7.08, 8.87,
  10.85, 12.56, 14.3, 15.77, 16.52, 16.37, 15.29, 14.28, 12.3, 9.81, 7.73, 6.26, 6.22, 4.61, 4.35,
  4.15, 4.02, 3.95, 3.98, 4.08, 4.52, 5.25, 7.47, 9.91, 12.46, 14.45, 16.23, 17.35, 17.98, 17.66,
  16.5, 14.99, 12.92, 10.18, 8.03, 6.48, 6.38, 4.55, 4.28, 4.14, 3.99, 3.98, 3.98, 4.04, 4.49, 5.16,
  7.63, 10.29, 13.01, 15.15, 16.98, 18.18, 18.66, 18.31, 16.7, 15.02, 13.44, 11.09, 8.81, 6.82,
  6.53, 4.63, 4.39, 4.22, 4.09, 4.08, 4.07, 4.13, 4.54, 5.2, 7.78, 10.19, 12.89, 15.27, 17.55,
  19.52, 20.56, 20.22, 18.73, 16.45, 14.1, 11.63, 9.37, 7.45, 7.12, 4.71, 4.4, 4.18, 4.06, 4.03,
  4.03, 4.08, 4.5, 5.18, 7.02, 9.36, 11.66, 13.57, 14.77, 15.36, 15.31, 14.59, 13.12, 11.8, 9.75,
  7.39, 5.74, 4.81, 5.29, 4.31, 4.06, 3.93, 3.86, 3.31, 3.13, 3.06, 3.01, 2.55, 3.03, 2.86, 2.7,
  2.62, 2.83, 2.99, 3.03, 3.17, 3.17, 2.97, 2.81, 2.81, 2.99, 3.26, 4.47, 3.94, 3.8, 3.7, 3.35, 3.0,
  2.94, 2.83, 2.78, 2.31, 3.05, 2.31, 2.44, 2.42, 2.36, 2.19, 2.21, 2.19, 2.17, 2.34, 2.44, 2.49,
  2.61, 2.77, 3.85, 3.2, 3.11, 3.08, 2.93, 3.74, 3.83, 3.97, 4.41, 4.98, 7.08, 8.87, 10.85, 12.56,
  14.3, 15.77, 16.52, 16.37, 15.29, 14.28, 12.3, 9.81, 7.73, 6.26, 6.22, 4.61, 4.35, 4.15, 4.02,
  3.95, 3.98, 4.08, 4.52, 5.25, 7.47, 9.91, 12.46, 14.45, 16.23, 17.35, 17.98, 17.66, 16.5, 14.99,
  12.92, 10.18, 8.03, 6.48, 6.38, 4.55, 4.28, 4.14, 3.99, 3.98, 3.98, 4.04, 4.49, 5.16, 7.63, 10.29,
  13.01, 15.15, 16.98, 18.18, 18.66, 18.31, 16.7, 15.02, 13.44, 11.09, 8.81, 6.82, 6.53, 4.63, 4.39,
  4.22, 4.09, 4.08, 4.07, 4.13, 4.54, 5.2, 7.78, 10.19, 12.89, 15.27, 17.55, 19.52, 20.56, 20.22,
  18.73, 16.45, 14.1, 11.63, 9.37, 7.45, 7.12, 4.71, 4.4, 4.18, 4.06, 4.03, 4.03, 4.08, 4.5, 5.18,
  7.02, 9.36, 11.66, 13.57, 14.77, 15.36, 15.31, 14.59, 13.12, 11.8, 9.75, 7.39, 5.74, 4.81, 5.29,
  4.31, 4.06, 3.93, 3.86, 3.69, 3.54, 3.44, 3.37, 2.51, 2.9, 3.05, 3.08, 3.29, 3.72, 4.1, 4.83,
  5.35, 5.88, 6.2, 6.33, 6.2, 5.76, 5.18, 5.52, 4.33, 4.3, 4.19, 3.84, 3.46, 3.41, 3.29, 3.26, 2.47,
  2.97, 2.52, 3.22, 3.5, 3.63, 3.82, 4.43, 4.34, 4.34, 4.81, 4.84, 4.4, 4.15, 3.75, 4.15, 3.55,
  3.52, 3.53, 3.37, 3.75, 3.76, 3.87, 4.19, 4.37, 6.43, 8.97, 11.22, 13.4, 15.23, 16.01, 15.88,
  15.19, 13.66, 12.12, 10.38, 7.99, 6.06, 4.88, 5.12, 4.17, 3.9, 3.74, 3.63, 3.64, 3.63, 3.73, 4.06,
  4.22, 6.21, 8.44, 10.59, 12.54, 14.34, 15.66, 16.37, 16.6, 15.72, 14.19, 12.14, 9.54, 7.46, 5.82,
  5.67, 4.29, 4.0, 3.81, 3.7, 3.71, 3.71, 3.78, 4.09, 4.19, 6.07, 8.2, 10.64, 13.15, 15.35, 16.88,
  17.88, 17.84, 16.6, 15.12, 12.58, 10.05, 7.78, 6.1, 6.18, 4.48, 4.12, 3.93, 3.82, 3.82, 3.83, 3.9,
  4.22, 4.27, 6.6, 9.08, 11.46, 13.73, 15.58, 16.58, 17.32, 17.06, 16.03, 14.53, 12.69, 10.7, 8.57,
  6.66, 6.41, 4.54, 4.15, 3.94, 3.84, 3.9, 3.88, 3.92, 4.25, 4.38, 6.45, 9.1, 11.65, 13.99, 16.23,
  17.76, 18.15, 17.71, 16.79, 15.56, 13.58, 10.94, 8.5, 6.75, 6.65, 4.6, 4.22, 4.02, 3.91, 3.69,
  3.54, 3.44, 3.37, 2.51, 2.9, 3.05, 3.08, 3.29, 3.72, 4.1, 4.83, 5.35, 5.88, 6.2, 6.33, 6.2, 5.76,
  5.18, 5.52, 4.33, 4.3, 4.19, 3.84, 3.46, 3.41, 3.29, 3.26, 2.47, 2.97, 2.52, 3.22, 3.5, 3.63,
  3.82, 4.43, 4.34, 4.34, 4.81, 4.84, 4.4, 4.15, 3.75, 4.15, 3.55, 3.52, 3.53, 3.37, 3.75, 3.76,
  3.87, 4.19, 4.37, 6.43, 8.97, 11.22, 13.4, 15.23, 16.01, 15.88, 15.19, 13.66, 12.12, 10.38, 7.99,
  6.06, 4.88, 5.12, 4.17, 3.9, 3.74, 3.63, 3.64, 3.63, 3.73, 4.06, 4.22, 6.21, 8.44, 10.59, 12.54,
  14.34, 15.66, 16.37, 16.6, 15.72, 14.19, 12.14, 9.54, 7.46, 5.82, 5.67, 4.29, 4.0, 3.81, 3.7,
  3.71, 3.71, 3.78, 4.09, 4.19, 6.07, 8.2, 10.64, 13.15, 15.35, 16.88, 17.88, 17.84, 16.6, 15.12,
  12.58, 10.05, 7.78, 6.1, 6.18, 4.48, 4.12, 3.93, 3.82, 3.82, 3.83, 3.9, 4.22, 4.27, 6.6, 9.08,
  11.46, 13.73, 15.58, 16.58, 17.32, 17.06, 16.03, 14.53, 12.69, 10.7, 8.57, 6.66, 6.41, 4.54, 4.15,
  3.94, 3.84, 3.9, 3.88, 3.92, 4.25, 4.38, 6.45, 9.1, 11.65, 13.99, 16.23, 17.76, 18.15, 17.71,
  16.79, 15.56, 13.58, 10.94, 8.5, 6.75, 6.65, 4.6, 4.22, 4.02, 3.91, 3.69, 3.54, 3.44, 3.37, 2.51,
  2.9, 3.05, 3.08, 3.29, 3.72, 4.1, 4.83, 5.35, 5.88, 6.2, 6.33, 6.2, 5.76, 5.18, 5.52, 4.33, 4.3,
  4.19, 3.84, 3.46, 3.41, 3.29, 3.26, 2.47, 2.97, 2.52, 3.22, 3.5, 3.63, 3.82, 4.43, 4.34, 4.34,
  4.81, 4.84, 4.4, 4.15, 3.75, 4.15, 3.55, 3.52, 3.53, 3.37, 3.75, 3.76, 3.87, 4.19, 4.37, 6.43,
  8.97, 11.22, 13.4, 15.23, 16.01, 15.88, 15.19, 13.66, 12.12, 10.38, 7.99, 6.06, 4.88, 5.12, 4.17,
  3.9, 3.74, 3.63, 3.64, 3.63, 3.73, 4.06, 4.22, 6.21, 8.44, 10.59, 12.54, 14.34, 15.66, 16.37,
  16.6, 15.72, 14.19, 12.14, 9.54, 7.46, 5.82, 5.67, 4.29, 4.0, 3.81, 3.7, 3.71, 3.71, 3.78, 4.09,
  4.19, 6.07, 8.2, 10.64, 13.15, 15.35, 16.88, 17.88, 17.84, 16.6, 15.12, 12.58, 10.05, 7.78, 6.1,
  6.18, 4.48, 4.12, 3.93, 3.82, 3.82, 3.83, 3.9, 4.22, 4.27, 6.6, 9.08, 11.46, 13.73, 15.58, 16.58,
  17.32, 17.06, 16.03, 14.53, 12.69, 10.7, 8.57, 6.66, 6.41, 4.54, 4.15, 3.94, 3.84, 3.9, 3.88,
  3.92, 4.25, 4.38, 6.45, 9.1, 11.65, 13.99, 16.23, 17.76, 18.15, 17.71, 16.79, 15.56, 13.58, 10.94,
  8.5, 6.75, 6.65, 4.6, 4.22, 4.02, 3.91, 3.69, 3.54, 3.44, 3.37, 2.51, 2.9, 3.05, 3.08, 3.29, 3.72,
  4.1, 4.83, 5.35, 5.88, 6.2, 6.33, 6.2, 5.76, 5.18, 5.52, 4.33, 4.3, 4.19, 3.84, 3.46, 3.41, 3.29,
  3.26, 2.47, 2.97, 2.52, 3.22, 3.5, 3.63, 3.82, 4.43, 4.34, 4.34, 4.81, 4.84, 4.4, 4.15, 3.75,
  4.15, 3.55, 3.52, 3.53, 3.37, 3.75, 3.76, 3.87, 4.19, 4.37, 6.43, 8.97, 11.22, 13.4, 15.23, 16.01,
  15.88, 15.19, 13.66, 12.12, 10.38, 7.99, 6.06, 4.88, 5.12, 4.17, 3.9, 3.74, 3.63, 3.64, 3.63,
  3.73, 4.06, 4.22, 6.21, 8.44, 10.59, 12.54, 14.34, 15.66, 16.37, 16.6, 15.72, 14.19, 12.14, 9.54,
  7.46, 5.82, 5.67, 4.29, 4.0, 3.81, 3.7, 3.71, 3.71, 3.78, 4.09, 4.19, 6.07, 8.2, 10.64, 13.15,
  15.35, 16.88, 17.88, 17.84, 16.6, 15.12, 12.58, 10.05, 7.78, 6.1, 6.18, 4.48, 4.12, 3.93, 3.82,
  3.82, 3.83, 3.9, 4.22, 4.27, 6.6, 9.08, 11.46, 13.73, 15.58, 16.58, 17.32, 17.06, 16.03, 14.53,
  12.69, 10.7, 8.57, 6.66, 6.41, 4.54, 4.15, 3.94, 3.84, 3.9, 3.88, 3.92, 4.25, 4.38, 6.45, 9.1,
  11.65, 13.99, 16.23, 17.76, 18.15, 17.71, 16.79, 15.56, 13.58, 10.94, 8.5, 6.75, 6.65, 4.6, 4.22,
  4.02, 3.91, 3.69, 3.54, 3.44, 3.37, 2.51, 2.9, 3.05, 3.08, 3.29, 3.72, 4.1, 4.83, 5.35, 5.88, 6.2,
  6.33, 6.2, 5.76, 5.18, 5.52, 4.33, 4.3, 4.19, 3.84, 3.46, 3.41, 3.29, 3.26, 2.47, 2.97, 2.52,
  3.22, 3.5, 3.63, 3.82, 4.43, 4.34, 4.34, 4.81, 4.84, 4.4, 4.15, 3.75, 4.15, 3.55, 3.52, 3.53,
  3.37, 3.48, 3.47, 3.59, 3.75, 3.64, 3.8, 6.62, 8.66, 10.82, 13.35, 15.0, 16.07, 16.26, 15.08,
  13.26, 11.45, 9.88, 4.74, 4.12, 4.67, 4.44, 3.99, 3.66, 3.56, 3.54, 3.55, 3.65, 3.81, 3.71, 3.9,
  7.4, 9.68, 12.05, 14.48, 16.15, 17.67, 18.46, 18.33, 17.53, 15.52, 12.97, 5.33, 4.58, 5.04, 4.75,
  4.16, 3.78, 3.7, 3.7, 3.68, 3.75, 3.91, 3.79, 4.09, 8.52, 11.19, 14.17, 17.79, 19.84, 21.07, 21.1,
  20.41, 19.34, 17.33, 14.61, 5.8, 4.96, 5.35, 5.11, 4.49, 4.02, 3.9, 3.85, 3.8, 3.8, 3.96, 3.8,
  4.1, 8.72, 11.69, 14.61, 17.43, 18.38, 18.19, 18.37, 18.51, 16.99, 14.79, 12.07, 4.97, 4.27, 4.8,
  4.53, 4.0, 3.66, 3.6, 3.58, 3.57, 3.67, 3.81, 3.74, 3.88, 7.21, 9.47, 11.88, 14.54, 15.9, 16.43,
  16.75, 16.7, 16.15, 14.34, 11.89, 5.02, 4.36, 4.86, 4.62, 4.06, 3.72, 3.65, 3.94, 3.78, 3.62,
  3.54, 3.01, 2.41, 4.01, 3.79, 4.11, 4.8, 5.45, 6.13, 6.93, 7.26, 6.7, 6.3, 5.9, 3.01, 3.23, 4.15,
  4.36, 4.34, 4.26, 3.91, 3.59, 3.54, 3.39, 3.38, 2.85, 2.21, 2.53, 3.48, 3.8, 4.11, 4.3, 4.86,
  5.33, 5.52, 6.0, 5.99, 5.47, 2.5, 2.62, 3.46, 3.73, 3.73, 3.75, 3.56, 3.48, 3.47, 3.59, 3.75,
  3.64, 3.8, 6.62, 8.66, 10.82, 13.35, 15.0, 16.07, 16.26, 15.08, 13.26, 11.45, 9.88, 4.74, 4.12,
  4.67, 4.44, 3.99, 3.66, 3.56, 3.54, 3.55, 3.65, 3.81, 3.71, 3.9, 7.4, 9.68, 12.05, 14.48, 16.15,
  17.67, 18.46, 18.33, 17.53, 15.52, 12.97, 5.33, 4.58, 5.04, 4.75, 4.16, 3.78, 3.7, 3.7, 3.68,
  3.75, 3.91, 3.79, 4.09, 8.52, 11.19, 14.17, 17.79, 19.84, 21.07, 21.1, 20.41, 19.34, 17.33, 14.61,
  5.8, 4.96, 5.35, 5.11, 4.49, 4.02, 3.9, 3.85, 3.8, 3.8, 3.96, 3.8, 4.1, 8.72, 11.69, 14.61, 17.43,
  18.38, 18.19, 18.37, 18.51, 16.99, 14.79, 12.07, 4.97, 4.27, 4.8, 4.53, 4.0, 3.66, 3.6, 3.58,
  3.57, 3.67, 3.81, 3.74, 3.88, 7.21, 9.47, 11.88, 14.54, 15.9, 16.43, 16.75, 16.7, 16.15, 14.34,
  11.89, 5.02, 4.36, 4.86, 4.62, 4.06, 3.72, 3.65, 3.94, 3.78, 3.62, 3.54, 3.01, 2.41, 4.01, 3.79,
  4.11, 4.8, 5.45, 6.13, 6.93, 7.26, 6.7, 6.3, 5.9, 3.01, 3.23, 4.15, 4.36, 4.34, 4.26, 3.91, 3.59,
  3.54, 3.39, 3.38, 2.85, 2.21, 2.53, 3.48, 3.8, 4.11, 4.3, 4.86, 5.33, 5.52, 6.0, 5.99, 5.47, 2.5,
  2.62, 3.46, 3.73, 3.73, 3.75, 3.56, 3.48, 3.47, 3.59, 3.75, 3.64, 3.8, 6.62, 8.66, 10.82, 13.35,
  15.0, 16.07, 16.26, 15.08, 13.26, 11.45, 9.88, 4.74, 4.12, 4.67, 4.44, 3.99, 3.66, 3.56, 3.54,
  3.55, 3.65, 3.81, 3.71, 3.9, 7.4, 9.68, 12.05, 14.48, 16.15, 17.67, 18.46, 18.33, 17.53, 15.52,
  12.97, 5.33, 4.58, 5.04, 4.75, 4.16, 3.78, 3.7, 3.7, 3.68, 3.75, 3.91, 3.79, 4.09, 8.52, 11.19,
  14.17, 17.79, 19.84, 21.07, 21.1, 20.41, 19.34, 17.33, 14.61, 5.8, 4.96, 5.35, 5.11, 4.49, 4.02,
  3.9, 3.85, 3.8, 3.8, 3.96, 3.8, 4.1, 8.72, 11.69, 14.61, 17.43, 18.38, 18.19, 18.37, 18.51, 16.99,
  14.79, 12.07, 4.97, 4.27, 4.8, 4.53, 4.0, 3.66, 3.6, 3.58, 3.57, 3.67, 3.81, 3.74, 3.88, 7.21,
  9.47, 11.88, 14.54, 15.9, 16.43, 16.75, 16.7, 16.15, 14.34, 11.89, 5.02, 4.36, 4.86, 4.62, 4.06,
  3.72, 3.65, 3.94, 3.78, 3.62, 3.54, 3.01, 2.41, 4.01, 3.79, 4.11, 4.8, 5.45, 6.13, 6.93, 7.26,
  6.7, 6.3, 5.9, 3.01, 3.23, 4.15, 4.36, 4.34, 4.26, 3.91, 3.59, 3.54, 3.39, 3.38, 2.85, 2.21, 2.53,
  3.48, 3.8, 4.11, 4.3, 4.86, 5.33, 5.52, 6.0, 5.99, 5.47, 2.5, 2.62, 3.46, 3.73, 3.73, 3.75, 3.56,
  3.48, 3.47, 3.59, 3.75, 3.64, 3.8, 6.62, 8.66, 10.82, 13.35, 15.0, 16.07, 16.26, 15.08, 13.26,
  11.45, 9.88, 4.74, 4.12, 4.67, 4.44, 3.99, 3.66, 3.56, 3.54, 3.55, 3.65, 3.81, 3.71, 3.9, 7.4,
  9.68, 12.05, 14.48, 16.15, 17.67, 18.46, 18.33, 17.53, 15.52, 12.97, 5.33, 4.58, 5.04, 4.75, 4.16,
  3.78, 3.7, 3.7, 3.68, 3.75, 3.91, 3.79, 4.09, 8.52, 11.19, 14.17, 17.79, 19.84, 21.07, 21.1,
  20.41, 19.34, 17.33, 14.61, 5.8, 4.96, 5.35, 5.11, 4.49, 4.02, 3.9, 3.85, 3.8, 3.8, 3.96, 3.8,
  4.1, 8.72, 11.69, 14.61, 17.43, 18.38, 18.19, 18.37, 18.51, 16.99, 14.79, 12.07, 4.97, 4.27, 4.8,
  4.53, 4.0, 3.66, 3.6, 3.58, 3.57, 3.67, 3.81, 3.74, 3.88, 7.21, 9.47, 11.88, 14.54, 15.9, 16.43,
  16.75, 16.7, 16.15, 14.34, 11.89, 5.02, 4.36, 4.86, 4.62, 4.06, 3.72, 3.65, 3.94, 3.78, 3.62,
  3.54, 3.01, 2.41, 4.01, 3.79, 4.11, 4.8, 5.45, 6.13, 6.93, 7.26, 6.7, 6.3, 5.9, 3.01, 3.23, 4.15,
  4.36, 4.34, 4.26, 3.91, 3.59, 3.54, 3.39, 3.38, 2.85, 2.21, 2.53, 3.48, 3.8, 4.11, 4.3, 4.86,
  5.33, 5.52, 6.0, 5.99, 5.47, 2.5, 2.62, 3.46, 3.73, 3.73, 3.75, 3.56, 3.48, 3.47, 3.59, 3.75,
  3.64, 3.8, 6.62, 8.66, 10.82, 13.35, 15.0, 16.07, 16.26, 15.08, 13.26, 11.45, 9.88, 4.74, 4.12,
  4.67, 4.44, 3.99, 3.66, 3.56, 3.54, 3.55, 3.65, 3.81, 3.71, 3.9, 7.4, 9.68, 12.05, 14.48, 16.15,
  17.67, 18.46, 18.33, 17.53, 15.52, 12.97, 5.33, 4.58, 5.04, 4.75, 4.16, 3.78, 3.7, 3.7, 3.68,
  3.75, 3.91, 3.79, 4.09, 8.52, 11.19, 14.17, 17.79, 19.84, 21.07, 21.1, 20.41, 19.34, 17.33, 14.61,
  5.8, 4.96, 5.35, 5.11, 4.49, 4.02, 3.9, 3.73, 3.65, 3.73, 3.93, 4.35, 4.37, 7.21, 9.11, 10.94,
  13.05, 14.42, 15.36, 15.53, 14.61, 13.43, 11.62, 9.42, 4.66, 4.4, 4.79, 4.43, 4.06, 3.79, 3.73,
  3.71, 3.7, 3.79, 4.0, 4.42, 4.45, 7.81, 9.69, 11.74, 14.09, 15.57, 16.67, 17.16, 16.91, 15.65,
  13.44, 11.23, 5.18, 4.83, 5.11, 4.67, 4.22, 3.92, 3.84, 3.96, 3.82, 3.68, 3.59, 3.42, 2.48, 4.27,
  4.02, 4.39, 5.2, 5.97, 6.58, 7.29, 7.45, 7.24, 7.3, 7.04, 3.11, 3.52, 4.4, 4.45, 4.39, 4.29, 3.88,
  3.58, 3.52, 3.36, 3.36, 3.31, 2.26, 2.98, 3.51, 3.73, 3.97, 4.26, 4.83, 5.26, 5.33, 5.32, 5.17,
  4.62, 2.36, 2.69, 3.6, 3.68, 3.7, 3.72, 3.52, 3.64, 3.65, 3.76, 3.98, 4.4, 4.24, 6.59, 8.34,
  10.24, 11.74, 12.81, 13.71, 14.11, 13.56, 12.28, 10.58, 8.33, 4.46, 4.19, 4.67, 4.27, 3.87, 3.61,
  3.59, 3.59, 3.58, 3.69, 3.9, 4.31, 4.23, 6.48, 8.08, 9.99, 12.03, 13.2, 13.97, 14.14, 13.57,
  12.72, 11.4, 9.23, 4.66, 4.34, 4.78, 4.41, 4.0, 3.73, 3.69, 3.65, 3.65, 3.74, 3.96, 4.36, 4.37,
  7.28, 9.3, 11.57, 13.87, 15.33, 16.42, 17.02, 16.93, 16.04, 14.05, 11.53, 5.05, 4.62, 5.13, 4.71,
  4.2, 3.86, 3.78, 3.73, 3.65, 3.73, 3.93, 4.35, 4.37, 7.21, 9.11, 10.94, 13.05, 14.42, 15.36,
  15.53, 14.61, 13.43, 11.62, 9.42, 4.66, 4.4, 4.79, 4.43, 4.06, 3.79, 3.73, 3.71, 3.7, 3.79, 4.0,
  4.42, 4.45, 7.81, 9.69, 11.74, 14.09, 15.57, 16.67, 17.16, 16.91, 15.65, 13.44, 11.23, 5.18, 4.83,
  5.11, 4.67, 4.22, 3.92, 3.84, 3.96, 3.82, 3.68, 3.59, 3.42, 2.48, 4.27, 4.02, 4.39, 5.2, 5.97,
  6.58, 7.29, 7.45, 7.24, 7.3, 7.04, 3.11, 3.52, 4.4, 4.45, 4.39, 4.29, 3.88, 3.58, 3.52, 3.36,
  3.36, 3.31, 2.26, 2.98, 3.51, 3.73, 3.97, 4.26, 4.83, 5.26, 5.33, 5.32, 5.17, 4.62, 2.36, 2.69,
  3.6, 3.68, 3.7, 3.72, 3.52, 3.64, 3.65, 3.76, 3.98, 4.4, 4.24, 6.59, 8.34, 10.24, 11.74, 12.81,
  13.71, 14.11, 13.56, 12.28, 10.58, 8.33, 4.46, 4.19, 4.67, 4.27, 3.87, 3.61, 3.59, 3.59, 3.58,
  3.69, 3.9, 4.31, 4.23, 6.48, 8.08, 9.99, 12.03, 13.2, 13.97, 14.14, 13.57, 12.72, 11.4, 9.23,
  4.66, 4.34, 4.78, 4.41, 4.0, 3.73, 3.69, 3.65, 3.65, 3.74, 3.96, 4.36, 4.37, 7.28, 9.3, 11.57,
  13.87, 15.33, 16.42, 17.02, 16.93, 16.04, 14.05, 11.53, 5.05, 4.62, 5.13, 4.71, 4.2, 3.86, 3.78,
  3.73, 3.65, 3.73, 3.93, 4.35, 4.37, 7.21, 9.11, 10.94, 13.05, 14.42, 15.36, 15.53, 14.61, 13.43,
  11.62, 9.42, 4.66, 4.4, 4.79, 4.43, 4.06, 3.79, 3.73, 3.71, 3.7, 3.79, 4.0, 4.42, 4.45, 7.81,
  9.69, 11.74, 14.09, 15.57, 16.67, 17.16, 16.91, 15.65, 13.44, 11.23, 5.18, 4.83, 5.11, 4.67, 4.22,
  3.92, 3.84, 3.96, 3.82, 3.68, 3.59, 3.42, 2.48, 4.27, 4.02, 4.39, 5.2, 5.97, 6.58, 7.29, 7.45,
  7.24, 7.3, 7.04, 3.11, 3.52, 4.4, 4.45, 4.39, 4.29, 3.88, 3.58, 3.52, 3.36, 3.36, 3.31, 2.26,
  2.98, 3.51, 3.73, 3.97, 4.26, 4.83, 5.26, 5.33, 5.32, 5.17, 4.62, 2.36, 2.69, 3.6, 3.68, 3.7,
  3.72, 3.52, 3.64, 3.65, 3.76, 3.98, 4.4, 4.24, 6.59, 8.34, 10.24, 11.74, 12.81, 13.71, 14.11,
  13.56, 12.28, 10.58, 8.33, 4.46, 4.19, 4.67, 4.27, 3.87, 3.61, 3.59, 3.59, 3.58, 3.69, 3.9, 4.31,
  4.23, 6.48, 8.08, 9.99, 12.03, 13.2, 13.97, 14.14, 13.57, 12.72, 11.4, 9.23, 4.66, 4.34, 4.78,
  4.41, 4.0, 3.73, 3.69, 3.65, 3.65, 3.74, 3.96, 4.36, 4.37, 7.28, 9.3, 11.57, 13.87, 15.33, 16.42,
  17.02, 16.93, 16.04, 14.05, 11.53, 5.05, 4.62, 5.13, 4.71, 4.2, 3.86, 3.78, 3.73, 3.65, 3.73,
  3.93, 4.35, 4.37, 7.21, 9.11, 10.94, 13.05, 14.42, 15.36, 15.53, 14.61, 13.43, 11.62, 9.42, 4.66,
  4.4, 4.79, 4.43, 4.06, 3.79, 3.73, 3.71, 3.7, 3.79, 4.0, 4.42, 4.45, 7.81, 9.69, 11.74, 14.09,
  15.57, 16.67, 17.16, 16.91, 15.65, 13.44, 11.23, 5.18, 4.83, 5.11, 4.67, 4.22, 3.92, 3.84, 3.96,
  3.82, 3.68, 3.59, 3.42, 2.48, 4.27, 4.02, 4.39, 5.2, 5.97, 6.58, 7.29, 7.45, 7.24, 7.3, 7.04,
  3.11, 3.52, 4.4, 4.45, 4.39, 4.29, 3.88, 3.58, 3.52, 3.36, 3.36, 3.31, 2.26, 2.98, 3.51, 3.73,
  3.97, 4.26, 4.83, 5.26, 5.33, 5.32, 5.17, 4.62, 2.36, 2.69, 3.6, 3.68, 3.7, 3.72, 3.52, 3.64,
  3.65, 3.76, 3.98, 4.4, 4.24, 6.59, 8.34, 10.24, 11.74, 12.81, 13.71, 14.11, 13.56, 12.28, 10.58,
  8.33, 4.46, 4.19, 4.67, 4.27, 3.87, 3.61, 3.59, 3.59, 3.58, 3.69, 3.9, 4.31, 4.23, 6.48, 8.08,
  9.99, 12.03, 13.2, 13.97, 14.14, 13.57, 12.72, 11.4, 9.23, 4.66, 4.34, 4.78, 4.41, 4.0, 3.73,
  3.69, 3.65, 3.65, 3.74, 3.96, 4.36, 4.37, 7.28, 9.3, 11.57, 13.87, 15.33, 16.42, 17.02, 16.93,
  16.04, 14.05, 11.53, 5.05, 4.62, 5.13, 4.71, 4.2, 3.86, 3.78, 3.73, 3.65, 3.73, 3.93, 4.35, 4.37,
  7.21, 9.11, 10.94, 13.05, 14.42, 15.36, 15.53, 14.61, 13.43, 11.62, 9.42, 4.66, 4.4, 4.79, 4.43,
  4.06, 3.79, 3.73, 3.71, 3.7, 3.79, 4.0, 4.42, 4.45, 7.81, 9.69, 11.74, 14.09, 15.57, 16.67, 17.16,
  16.91, 15.65, 13.44, 11.23, 5.18, 4.83, 5.11, 4.67, 4.22, 3.92, 3.84, 3.96, 3.82, 3.68, 3.59,
  3.42, 2.48, 4.27, 4.02, 4.39, 5.2, 5.97, 6.58, 7.29, 7.45, 7.24, 7.3, 7.04, 3.11, 3.52, 4.4, 4.45,
  4.39, 4.29, 3.88, 3.19, 3.11, 3.0, 2.96, 2.92, 3.51, 2.31, 2.52, 2.56, 2.62, 2.58, 2.96, 3.16,
  3.19, 3.36, 3.36, 3.24, 3.4, 4.16, 4.18, 3.48, 3.42, 3.39, 3.22, 4.01, 4.01, 4.13, 4.54, 5.56,
  8.09, 9.57, 11.72, 13.6, 15.48, 16.74, 17.35, 17.17, 15.7, 13.89, 11.88, 9.58, 7.68, 7.19, 6.4,
  4.68, 4.41, 4.22, 4.16, 4.14, 4.14, 4.2, 4.62, 5.67, 8.02, 9.74, 11.91, 14.0, 15.93, 17.09, 17.67,
  17.55, 16.23, 14.34, 12.25, 9.9, 7.79, 7.18, 6.28, 4.64, 4.35, 4.2, 4.1, 4.09, 4.1, 4.18, 4.6,
  5.66, 8.09, 9.67, 11.85, 13.9, 15.91, 17.21, 17.79, 17.52, 15.98, 13.92, 11.7, 9.41, 7.67, 7.18,
  6.35, 4.66, 4.4, 4.23, 4.11, 4.12, 4.14, 4.24, 4.64, 5.68, 8.56, 9.96, 11.99, 13.65, 15.08, 15.89,
  16.16, 15.78, 14.54, 13.04, 11.31, 9.19, 7.44, 6.98, 6.21, 4.65, 4.4, 4.24, 4.14, 4.16, 4.17,
  4.25, 4.68, 5.78, 8.64, 10.34, 12.67, 14.6, 16.33, 17.47, 17.96, 17.6, 16.15, 14.45, 12.47, 10.06,
  8.1, 7.44, 6.48, 4.73, 4.44, 4.33, 4.23, 3.65, 3.46, 3.38, 3.29, 3.21, 3.51, 2.83, 2.73, 2.82,
  3.0, 3.34, 3.5, 3.75, 3.9, 3.95, 3.76, 3.67, 3.8, 4.59, 4.8, 4.22, 4.04, 3.88, 3.54, 3.19, 3.11,
  3.0, 2.96, 2.92, 3.51, 2.31, 2.52, 2.56, 2.62, 2.58, 2.96, 3.16, 3.19, 3.36, 3.36, 3.24, 3.4,
  4.16, 4.18, 3.48, 3.42, 3.39, 3.22, 4.01, 4.01, 4.13, 4.54, 5.56, 8.09, 9.57, 11.72, 13.6, 15.48,
  16.74, 17.35, 17.17, 15.7, 13.89, 11.88, 9.58, 7.68, 7.19, 6.4, 4.68, 4.41, 4.22, 4.16, 4.14,
  4.14, 4.2, 4.62, 5.67, 8.02, 9.74, 11.91, 14.0, 15.93, 17.09, 17.67, 17.55, 16.23, 14.34, 12.25,
  9.9, 7.79, 7.18, 6.28, 4.64, 4.35, 4.2, 4.1, 4.09, 4.1, 4.18, 4.6, 5.66, 8.09, 9.67, 11.85, 13.9,
  15.91, 17.21, 17.79, 17.52, 15.98, 13.92, 11.7, 9.41, 7.67, 7.18, 6.35, 4.66, 4.4, 4.23, 4.11,
  4.12, 4.14, 4.24, 4.64, 5.68, 8.56, 9.96, 11.99, 13.65, 15.08, 15.89, 16.16, 15.78, 14.54, 13.04,
  11.31, 9.19, 7.44, 6.98, 6.21, 4.65, 4.4, 4.24, 4.14, 4.16, 4.17, 4.25, 4.68, 5.78, 8.64, 10.34,
  12.67, 14.6, 16.33, 17.47, 17.96, 17.6, 16.15, 14.45, 12.47, 10.06, 8.1, 7.44, 6.48, 4.73, 4.44,
  4.33, 4.23, 3.65, 3.46, 3.38, 3.29, 3.21, 3.51, 2.83, 2.73, 2.82, 3.0, 3.34, 3.5, 3.75, 3.9, 3.95,
  3.76, 3.67, 3.8, 4.59, 4.8, 4.22, 4.04, 3.88, 3.54, 3.19, 3.11, 3.0, 2.96, 2.92, 3.51, 2.31, 2.52,
  2.56, 2.62, 2.58, 2.96, 3.16, 3.19, 3.36, 3.36, 3.24, 3.4, 4.16, 4.18, 3.48, 3.42, 3.39, 3.22,
  4.01, 4.01, 4.13, 4.54, 5.56, 8.09, 9.57, 11.72, 13.6, 15.48, 16.74, 17.35, 17.17, 15.7, 13.89,
  11.88, 9.58, 7.68, 7.19, 6.4, 4.68, 4.41, 4.22, 4.16, 4.14, 4.14, 4.2, 4.62, 5.67, 8.02, 9.74,
  11.91, 14.0, 15.93, 17.09, 17.67, 17.55, 16.23, 14.34, 12.25, 9.9, 7.79, 7.18, 6.28, 4.64, 4.35,
  4.2, 4.1, 4.09, 4.1, 4.18, 4.6, 5.66, 8.09, 9.67, 11.85, 13.9, 15.91, 17.21, 17.79, 17.52, 15.98,
  13.92, 11.7, 9.41, 7.67, 7.18, 6.35, 4.66, 4.4, 4.23, 4.11, 4.12, 4.14, 4.24, 4.64, 5.68, 8.56,
  9.96, 11.99, 13.65, 15.08, 15.89, 16.16, 15.78, 14.54, 13.04, 11.31, 9.19, 7.44, 6.98, 6.21, 4.65,
  4.4, 4.24, 4.14, 4.16, 4.17, 4.25, 4.68, 5.78, 8.64, 10.34, 12.67, 14.6, 16.33, 17.47, 17.96,
  17.6, 16.15, 14.45, 12.47, 10.06, 8.1, 7.44, 6.48, 4.73, 4.44, 4.33, 4.23, 3.65, 3.46, 3.38, 3.29,
  3.21, 3.51, 2.83, 2.73, 2.82, 3.0, 3.34, 3.5, 3.75, 3.9, 3.95, 3.76, 3.67, 3.8, 4.59, 4.8, 4.22,
  4.04, 3.88, 3.54, 3.19, 3.11, 3.0, 2.96, 2.92, 3.51, 2.31, 2.52, 2.56, 2.62, 2.58, 2.96, 3.16,
  3.19, 3.36, 3.36, 3.24, 3.4, 4.16, 4.18, 3.48, 3.42, 3.39, 3.22, 4.01, 4.01, 4.13, 4.54, 5.56,
  8.09, 9.57, 11.72, 13.6, 15.48, 16.74, 17.35, 17.17, 15.7, 13.89, 11.88, 9.58, 7.68, 7.19, 6.4,
  4.68, 4.41, 4.22, 4.16, 4.14, 4.14, 4.2, 4.62, 5.67, 8.02, 9.74, 11.91, 14.0, 15.93, 17.09, 17.67,
  17.55, 16.23, 14.34, 12.25, 9.9, 7.79, 7.18, 6.28, 4.64, 4.35, 4.2, 4.1, 4.09, 4.1, 4.18, 4.6,
  5.66, 8.09, 9.67, 11.85, 13.9, 15.91, 17.21, 17.79, 17.52, 15.98, 13.92, 11.7, 9.41, 7.67, 7.18,
  6.35, 4.66, 4.4, 4.23, 4.11, 4.12, 4.14, 4.24, 4.64, 5.68, 8.56, 9.96, 11.99, 13.65, 15.08, 15.89,
  16.16, 15.78, 14.54, 13.04, 11.31, 9.19, 7.44, 6.98, 6.21, 4.65, 4.4, 4.24, 4.14, 4.16, 4.17,
  4.25, 4.68, 5.78, 8.64, 10.34, 12.67, 14.6, 16.33, 17.47, 17.96, 17.6, 16.15, 14.45, 12.47, 10.06,
  8.1, 7.44, 6.48, 4.73, 4.44, 4.33, 4.23, 3.65, 3.46, 3.38, 3.29, 3.21, 3.51, 2.83, 2.73, 2.82,
  3.0, 3.34, 3.5, 3.75, 3.9, 3.95, 3.76, 3.67, 3.8, 4.59, 4.8, 4.22, 4.04, 3.88, 3.54, 3.19, 3.11,
  3.0, 2.96, 2.92, 3.51, 2.31, 2.52, 2.56, 2.62, 2.58, 2.96, 3.16, 3.19, 3.36, 3.36, 3.24, 3.4,
  4.16, 4.18, 3.48, 3.42, 3.39, 3.22, 4.01, 4.01, 4.13, 4.54, 5.56, 8.09, 9.57, 11.72, 13.6, 15.48,
  16.74, 17.35, 17.17, 15.7, 13.89, 11.88, 9.58, 7.68, 7.19, 6.4, 4.68, 4.41, 4.22, 4.16, 3.91,
  3.93, 4.01, 4.44, 5.55, 8.19, 8.98, 10.63, 11.9, 12.9, 13.33, 13.39, 12.94, 11.51, 10.11, 8.53,
  6.93, 6.58, 5.88, 5.38, 4.34, 4.05, 3.92, 3.83, 3.88, 3.9, 3.99, 4.46, 5.53, 8.15, 8.86, 10.46,
  11.74, 12.84, 13.39, 13.47, 12.9, 11.34, 9.79, 8.1, 6.7, 6.4, 5.79, 5.39, 4.32, 4.05, 3.91, 3.88,
  3.94, 3.95, 4.06, 4.5, 5.63, 8.28, 9.33, 11.27, 12.69, 13.86, 14.31, 14.27, 13.68, 12.12, 10.45,
  8.64, 6.92, 6.39, 5.67, 5.35, 4.35, 4.04, 3.95, 3.88, 3.91, 3.92, 3.98, 4.38, 5.47, 8.04, 8.84,
  10.43, 11.6, 12.44, 12.76, 12.64, 11.88, 10.39, 9.07, 7.65, 6.37, 6.27, 5.7, 5.25, 4.3, 4.05,
  3.91, 3.82, 3.32, 3.16, 3.06, 2.99, 2.97, 4.35, 3.02, 2.62, 2.49, 2.47, 2.46, 2.49, 2.59, 2.62,
  2.6, 2.72, 2.88, 4.03, 4.39, 4.6, 3.84, 3.76, 3.65, 3.37, 3.01, 2.97, 2.88, 2.85, 2.82, 4.49,
  2.65, 2.63, 2.5, 2.38, 2.26, 2.39, 2.45, 2.38, 2.5, 2.6, 2.65, 3.83, 3.98, 3.95, 3.15, 3.14, 3.14,
  2.99, 3.87, 3.88, 3.98, 4.41, 5.39, 8.16, 8.8, 10.39, 11.67, 12.71, 13.23, 13.27, 12.7, 11.21,
  9.82, 8.27, 6.81, 6.56, 5.9, 5.42, 4.29, 4.07, 3.95, 3.86, 3.91, 3.93, 4.01, 4.44, 5.55, 8.19,
  8.98, 10.63, 11.9, 12.9, 13.33, 13.39, 12.94, 11.51, 10.11, 8.53, 6.93, 6.58, 5.88, 5.38, 4.34,
  4.05, 3.92, 3.83, 3.88, 3.9, 3.99, 4.46, 5.53, 8.15, 8.86, 10.46, 11.74, 12.84, 13.39, 13.47,
  12.9, 11.34, 9.79, 8.1, 6.7, 6.4, 5.79, 5.39, 4.32, 4.05, 3.91, 3.88, 3.94, 3.95, 4.06, 4.5, 5.63,
  8.28, 9.33, 11.27, 12.69, 13.86, 14.31, 14.27, 13.68, 12.12, 10.45, 8.64, 6.92, 6.39, 5.67, 5.35,
  4.35, 4.04, 3.95, 3.88, 3.91, 3.92, 3.98, 4.38, 5.47, 8.04, 8.84, 10.43, 11.6, 12.44, 12.76,
  12.64, 11.88, 10.39, 9.07, 7.65, 6.37, 6.27, 5.7, 5.25, 4.3, 4.05, 3.91, 3.82, 3.32, 3.16, 3.06,
  2.99, 2.97, 4.35, 3.02, 2.62, 2.49, 2.47, 2.46, 2.49, 2.59, 2.62, 2.6, 2.72, 2.88, 4.03, 4.39,
  4.6, 3.84, 3.76, 3.65, 3.37, 3.01, 2.97, 2.88, 2.85, 2.82, 4.49, 2.65, 2.63, 2.5, 2.38, 2.26,
  2.39, 2.45, 2.38, 2.5, 2.6, 2.65, 3.83, 3.98, 3.95, 3.15, 3.14, 3.14, 2.99, 3.87, 3.88, 3.98,
  4.41, 5.39, 8.16, 8.8, 10.39, 11.67, 12.71, 13.23, 13.27, 12.7, 11.21, 9.82, 8.27, 6.81, 6.56,
  5.9, 5.42, 4.29, 4.07, 3.95, 3.86, 3.91, 3.93, 4.01, 4.44, 5.55, 8.19, 8.98, 10.63, 11.9, 12.9,
  13.33, 13.39, 12.94, 11.51, 10.11, 8.53, 6.93, 6.58, 5.88, 5.38, 4.34, 4.05, 3.92, 3.83, 3.88,
  3.9, 3.99, 4.46, 5.53, 8.15, 8.86, 10.46, 11.74, 12.84, 13.39, 13.47, 12.9, 11.34, 9.79, 8.1, 6.7,
  6.4, 5.79, 5.39, 4.32, 4.05, 3.91, 3.88, 3.94, 3.95, 4.06, 4.5, 5.63, 8.28, 9.33, 11.27, 12.69,
  13.86, 14.31, 14.27, 13.68, 12.12, 10.45, 8.64, 6.92, 6.39, 5.67, 5.35, 4.35, 4.04, 3.95, 3.88,
  3.91, 3.92, 3.98, 4.38, 5.47, 8.04, 8.84, 10.43, 11.6, 12.44, 12.76, 12.64, 11.88, 10.39, 9.07,
  7.65, 6.37, 6.27, 5.7, 5.25, 4.3, 4.05, 3.91, 3.82, 3.32, 3.16, 3.06, 2.99, 2.97, 4.35, 3.02,
  2.62, 2.49, 2.47, 2.46, 2.49, 2.59, 2.62, 2.6, 2.72, 2.88, 4.03, 4.39, 4.6, 3.84, 3.76, 3.65,
  3.37, 3.01, 2.97, 2.88, 2.85, 2.82, 4.49, 2.65, 2.63, 2.5, 2.38, 2.26, 2.39, 2.45, 2.38, 2.5, 2.6,
  2.65, 3.83, 3.98, 3.95, 3.15, 3.14, 3.14, 2.99, 3.87, 3.88, 3.98, 4.41, 5.39, 8.16, 8.8, 10.39,
  11.67, 12.71, 13.23, 13.27, 12.7, 11.21, 9.82, 8.27, 6.81, 6.56, 5.9, 5.42, 4.29, 4.07, 3.95,
  3.86, 3.91, 3.93, 4.01, 4.44, 5.55, 8.19, 8.98, 10.63, 11.9, 12.9, 13.33, 13.39, 12.94, 11.51,
  10.11, 8.53, 6.93, 6.58, 5.88, 5.38, 4.34, 4.05, 3.92, 3.83, 3.88, 3.9, 3.99, 4.46, 5.53, 8.15,
  8.86, 10.46, 11.74, 12.84, 13.39, 13.47, 12.9, 11.34, 9.79, 8.1, 6.7, 6.4, 5.79, 5.39, 4.32, 4.05,
  3.91, 3.88, 3.94, 3.95, 4.06, 4.5, 5.63, 8.28, 9.33, 11.27, 12.69, 13.86, 14.31, 14.27, 13.68,
  12.12, 10.45, 8.64, 6.92, 6.39, 5.67, 5.35, 4.35, 4.04, 3.95, 3.88, 3.91, 3.92, 3.98, 4.38, 5.47,
  8.04, 8.84, 10.43, 11.6, 12.44, 12.76, 12.64, 11.88, 10.39, 9.07, 7.65, 6.37, 6.27, 5.7, 5.25,
  4.3, 4.05, 3.91, 3.82, 3.32, 3.16, 3.06, 2.99, 2.97, 4.35, 3.02, 2.62, 2.49, 2.47, 2.46, 2.49,
  2.59, 2.62, 2.6, 2.72, 2.88, 4.03, 4.39, 4.6, 3.84, 3.76, 3.65, 3.37, 3.01, 2.97, 2.88, 2.85,
  2.82, 4.49, 2.65, 2.63, 2.5, 2.38, 2.26, 2.39, 2.45, 2.38, 2.5, 2.6, 2.65, 3.83, 3.98, 3.95, 3.15,
  3.14, 3.14, 2.99, 3.87, 3.88, 3.98, 4.41, 5.39, 8.16, 8.8, 10.39, 11.67, 12.71, 13.23, 13.27,
  12.7, 11.21, 9.82, 8.27, 6.81, 6.56, 5.9, 5.42, 4.29, 4.07, 3.95, 3.86, 3.91, 3.93, 4.01, 4.44,
  5.55, 8.19, 8.98, 10.63, 11.9, 12.9, 13.33, 13.39, 12.94, 11.51, 10.11, 8.53, 6.93, 6.58, 5.88,
  5.38, 4.34, 4.05, 3.92, 3.83, 3.88, 3.9, 3.99, 4.46, 5.53, 8.15, 8.86, 10.46, 11.74, 12.84, 13.39,
  13.47, 12.9, 11.34, 9.79, 8.1, 6.7, 6.4, 5.79, 5.39, 4.32, 4.05, 3.91, 3.88, 3.94, 3.95, 4.06,
  4.5, 5.63, 8.28, 9.33, 11.27, 12.69, 13.86, 14.31, 14.27, 13.68, 12.12, 10.45, 8.64, 6.92, 6.39,
  5.67, 5.35, 4.35, 4.04, 3.95, 3.88, 3.87, 3.88, 3.96, 4.16, 4.68, 6.19, 8.47, 9.03, 10.51, 11.57,
  12.27, 12.4, 12.06, 11.1, 9.6, 8.3, 7.6, 7.11, 6.27, 5.7, 5.18, 4.14, 3.98, 3.86, 3.29, 3.21,
  3.09, 3.09, 3.04, 2.95, 3.35, 3.98, 2.86, 2.63, 2.65, 2.79, 2.77, 2.82, 2.85, 2.79, 2.83, 3.48,
  4.14, 4.39, 4.62, 4.53, 3.84, 3.76, 3.58, 3.0, 3.01, 2.96, 2.92, 2.92, 3.29, 3.95, 2.62, 2.69,
  2.6, 2.57, 2.51, 2.52, 2.47, 2.46, 2.59, 3.22, 3.9, 4.1, 4.15, 3.93, 3.14, 3.18, 3.19, 3.85, 3.89,
  3.98, 4.11, 4.54, 5.57, 8.22, 8.91, 10.51, 11.63, 12.31, 12.44, 12.14, 11.2, 9.7, 8.45, 7.8, 7.27,
  6.5, 5.95, 5.58, 4.26, 4.08, 4.01, 3.94, 3.98, 4.02, 4.17, 4.59, 5.64, 8.35, 8.95, 10.57, 11.71,
  12.51, 12.71, 12.48, 11.63, 9.98, 8.57, 7.83, 7.21, 6.42, 5.86, 5.47, 4.28, 4.09, 3.98, 3.92,
  3.94, 3.96, 4.03, 4.45, 5.52, 8.16, 8.78, 10.45, 11.55, 12.35, 12.55, 12.27, 11.32, 9.85, 8.53,
  7.95, 7.28, 6.46, 5.86, 5.5, 4.3, 4.1, 4.02, 3.91, 3.91, 3.99, 4.18, 4.72, 6.12, 8.48, 9.18,
  10.63, 11.58, 12.21, 12.33, 11.95, 10.91, 9.38, 8.14, 7.49, 7.02, 6.27, 5.72, 5.2, 4.11, 3.95,
  3.89, 3.87, 3.88, 3.96, 4.16, 4.68, 6.19, 8.47, 9.03, 10.51, 11.57, 12.27, 12.4, 12.06, 11.1, 9.6,
  8.3, 7.6, 7.11, 6.27, 5.7, 5.18, 4.14, 3.98, 3.86, 3.29, 3.21, 3.09, 3.04, 2.95, 3.35, 3.98, 2.86,
  2.63, 2.65, 2.79, 2.77, 2.82, 2.85, 2.79, 2.83, 3.48, 4.14, 4.39, 4.62, 4.53, 3.84, 3.76, 3.58,
  3.0, 3.01, 2.96, 2.92, 2.92, 3.29, 3.95, 2.62, 2.69, 2.6, 2.57, 2.51, 2.52, 2.47, 2.46, 2.59,
  3.22, 3.9, 4.1, 4.15, 3.93, 3.14, 3.18, 3.19, 3.85, 3.89, 3.98, 4.11, 4.54, 5.57, 8.22, 8.91,
  10.51, 11.63, 12.31, 12.44, 12.14, 11.2, 9.7, 8.45, 7.8, 7.27, 6.5, 5.95, 5.58, 4.26, 4.08, 4.01,
  3.94, 3.98, 4.02, 4.17, 4.59, 5.64, 8.35, 8.95, 10.57, 11.71, 12.51, 12.71, 12.48, 11.63, 9.98,
  8.57, 7.83, 7.21, 6.42, 5.86, 5.47, 4.28, 4.09, 3.98, 3.92, 3.94, 3.96, 4.03, 4.45, 5.52, 8.16,
  8.78, 10.45, 11.55, 12.35, 12.55, 12.27, 11.32, 9.85, 8.53, 7.95, 7.28, 6.46, 5.86, 5.5, 4.3, 4.1,
  4.02, 3.91, 3.91, 3.99, 4.18, 4.72, 6.12, 8.48, 9.18, 10.63, 11.58, 12.21, 12.33, 11.95, 10.91,
  9.38, 8.14, 7.49, 7.02, 6.27, 5.72, 5.2, 4.11, 3.95, 3.89, 3.87, 3.88, 3.96, 4.16, 4.68, 6.19,
  8.47, 9.03, 10.51, 11.57, 12.27, 12.4, 12.06, 11.1, 9.6, 8.3, 7.6, 7.11, 6.27, 5.7, 5.18, 4.14,
  3.98, 3.86, 3.29, 3.21, 3.09, 3.04, 2.95, 3.35, 3.98, 2.86, 2.63, 2.65, 2.79, 2.77, 2.82, 2.85,
  2.79, 2.83, 3.48, 4.14, 4.39, 4.62, 4.53, 3.84, 3.76, 3.58, 3.0, 3.01, 2.96, 2.92, 2.92, 3.29,
  3.95, 2.62, 2.69, 2.6, 2.57, 2.51, 2.52, 2.47, 2.46, 2.59, 3.22, 3.9, 4.1, 4.15, 3.93, 3.14, 3.18,
  3.19, 3.85, 3.89, 3.98, 4.11, 4.54, 5.57, 8.22, 8.91, 10.51, 11.63, 12.31, 12.44, 12.14, 11.2,
  9.7, 8.45, 7.8, 7.27, 6.5, 5.95, 5.58, 4.26, 4.08, 4.01, 3.94, 3.98, 4.02, 4.17, 4.59, 5.64, 8.35,
  8.95, 10.57, 11.71, 12.51, 12.71, 12.48, 11.63, 9.98, 8.57, 7.83, 7.21, 6.42, 5.86, 5.47, 4.28,
  4.09, 3.98, 3.92, 3.94, 3.96, 4.03, 4.45, 5.52, 8.16, 8.78, 10.45, 11.55, 12.35, 12.55, 12.27,
  11.32, 9.85, 8.53, 7.95, 7.28, 6.46, 5.86, 5.5, 4.3, 4.1, 4.02, 3.91, 3.91, 3.99, 4.18, 4.72,
  6.12, 8.48, 9.18, 10.63, 11.58, 12.21, 12.33, 11.95, 10.91, 9.38, 8.14, 7.49, 7.02, 6.27, 5.72,
  5.2, 4.11, 3.95, 3.89, 3.87, 3.88, 3.96, 4.16, 4.68, 6.19, 8.47, 9.03, 10.51, 11.57, 12.27, 12.4,
  12.06, 11.1, 9.6, 8.3, 7.6, 7.11, 6.27, 5.7, 5.18, 4.14, 3.98, 3.86, 3.29, 3.21, 3.09, 3.04, 2.95,
  3.35, 3.98, 2.86, 2.63, 2.65, 2.79, 2.77, 2.82, 2.85, 2.79, 2.83, 3.48, 4.14, 4.39, 4.62, 4.53,
  3.84, 3.76, 3.58, 3.0, 3.01, 2.96, 2.92, 2.92, 3.29, 3.95, 2.62, 2.69, 2.6, 2.57, 2.51, 2.52,
  2.47, 2.46, 2.59, 3.22, 3.9, 4.1, 4.15, 3.93, 3.14, 3.18, 3.19, 3.85, 3.89, 3.98, 4.11, 4.54,
  5.57, 8.22, 8.91, 10.51, 11.63, 12.31, 12.44, 12.14, 11.2, 9.7, 8.45, 7.8, 7.27, 6.5, 5.95, 5.58,
  4.26, 4.08, 4.01, 3.94, 3.98, 4.02, 4.17, 4.59, 5.64, 8.35, 8.95, 10.57, 11.71, 12.51, 12.71,
  12.48, 11.63, 9.98, 8.57, 7.83, 7.21, 6.42, 5.86, 5.47, 4.28, 4.09, 3.98, 3.92, 3.94, 3.96, 4.03,
  4.45, 5.52, 8.16, 8.78, 10.45, 11.55, 12.35, 12.55, 12.27, 11.32, 9.85, 8.53, 7.95, 7.28, 6.46,
  5.86, 5.5, 4.3, 4.1, 4.02, 3.91, 3.91, 3.99, 4.18, 4.72, 6.12, 8.48, 9.18, 10.63, 11.58, 12.21,
  12.33, 11.95, 10.91, 9.38, 8.14, 7.49, 7.02, 6.27, 5.72, 5.2, 4.11, 3.95, 3.89, 3.87, 3.88, 3.96,
  4.16, 4.68, 6.19, 8.47, 9.03, 10.51, 11.57, 12.27, 12.4, 12.06, 11.1, 9.6, 8.3, 7.6, 7.11, 6.27,
  5.7, 5.18, 4.14, 3.98, 3.86, 3.29, 3.21, 3.09, 3.04, 2.95, 3.35, 3.98, 2.86, 2.63, 2.65, 2.79,
  2.77, 2.82, 2.85, 2.79, 2.83, 3.48, 4.14, 4.39, 4.62, 4.53, 3.84, 3.76, 3.58, 3.36, 3.38, 3.34,
  3.28, 3.25, 3.24, 5.43, 3.51, 3.24, 2.92, 2.7, 2.61, 2.58, 2.55, 2.52, 2.56, 3.5, 3.97, 4.13,
  4.29, 4.4, 3.2, 3.33, 3.43, 4.11, 4.18, 4.23, 4.37, 4.8, 5.9, 9.15, 9.39, 10.67, 11.66, 12.28,
  12.31, 11.98, 11.02, 9.45, 8.34, 7.95, 7.37, 6.71, 6.23, 5.93, 4.38, 4.3, 4.29, 4.25, 4.3, 4.38,
  4.49, 4.93, 6.01, 9.41, 9.58, 10.93, 11.93, 12.48, 12.45, 12.03, 11.03, 9.57, 8.5, 8.23, 7.61,
  6.97, 6.4, 6.07, 4.48, 4.45, 4.42, 4.41, 4.43, 4.47, 4.64, 5.11, 6.14, 9.69, 9.88, 11.12, 12.06,
  12.64, 12.53, 12.1, 11.08, 9.57, 8.43, 8.15, 7.56, 7.04, 6.53, 6.13, 4.56, 4.48, 4.44, 4.43, 4.45,
  4.45, 4.57, 5.02, 6.06, 9.38, 9.59, 10.82, 11.82, 12.36, 12.26, 11.86, 10.89, 9.42, 8.37, 8.12,
  7.5, 6.96, 6.47, 6.13, 4.5, 4.46, 4.37, 4.39, 4.47, 4.5, 4.65, 5.13, 6.14, 9.43, 9.63, 10.91,
  11.9, 12.46, 12.38, 12.05, 11.12, 9.57, 8.46, 8.21, 7.55, 6.94, 6.43, 6.07, 4.52, 4.43, 4.38,
  3.85, 3.83, 3.66, 3.62, 3.59, 3.55, 5.76, 4.1, 3.62, 3.47, 3.34, 3.21, 3.14, 3.07, 3.03, 3.14,
  4.21, 4.71, 4.92, 5.15, 5.3, 3.94, 4.01, 3.96, 3.36, 3.38, 3.34, 3.28, 3.25, 3.24, 5.43, 3.51,
  3.24, 2.92, 2.7, 2.61, 2.58, 2.55, 2.52, 2.56, 3.5, 3.97, 4.13, 4.29, 4.4, 3.2, 3.33, 3.43, 4.11,
  4.18, 4.23, 4.37, 4.8, 5.9, 9.15, 9.39, 10.67, 11.66, 12.28, 12.31, 11.98, 11.02, 9.45, 8.34,
  7.95, 7.37, 6.71, 6.23, 5.93, 4.38, 4.3, 4.29, 4.25, 4.3, 4.38, 4.49, 4.93, 6.01, 9.41, 9.58,
  10.93, 11.93, 12.48, 12.45, 12.03, 11.03, 9.57, 8.5, 8.23, 7.61, 6.97, 6.4, 6.07, 4.48, 4.45,
  4.42, 4.41, 4.43, 4.47, 4.64, 5.11, 6.14, 9.69, 9.88, 11.12, 12.06, 12.64, 12.53, 12.1, 11.08,
  9.57, 8.43, 8.15, 7.56, 7.04, 6.53, 6.13, 4.56, 4.48, 4.44, 4.43, 4.45, 4.45, 4.57, 5.02, 6.06,
  9.38, 9.59, 10.82, 11.82, 12.36, 12.26, 11.86, 10.89, 9.42, 8.37, 8.12, 7.5, 6.96, 6.47, 6.13,
  4.5, 4.46, 4.37, 4.39, 4.47, 4.5, 4.65, 5.13, 6.14, 9.43, 9.63, 10.91, 11.9, 12.46, 12.38, 12.05,
  11.12, 9.57, 8.46, 8.21, 7.55, 6.94, 6.43, 6.07, 4.52, 4.43, 4.38, 3.85, 3.83, 3.66, 3.62, 3.59,
  3.55, 5.76, 4.1, 3.62, 3.47, 3.34, 3.21, 3.14, 3.07, 3.03, 3.14, 4.21, 4.71, 4.92, 5.15, 5.3,
  3.94, 4.01, 3.96, 3.36, 3.38, 3.34, 3.28, 3.25, 3.24, 5.43, 3.51, 3.24, 2.92, 2.7, 2.61, 2.58,
  2.55, 2.52, 2.56, 3.5, 3.97, 4.13, 4.29, 4.4, 3.2, 3.33, 3.43, 4.11, 4.18, 4.23, 4.37, 4.8, 5.9,
  9.15, 9.39, 10.67, 11.66, 12.28, 12.31, 11.98, 11.02, 9.45, 8.34, 7.95, 7.37, 6.71, 6.23, 5.93,
  4.38, 4.3, 4.29, 4.25, 4.3, 4.38, 4.49, 4.93, 6.01, 9.41, 9.58, 10.93, 11.93, 12.48, 12.45, 12.03,
  11.03, 9.57, 8.5, 8.23, 7.61, 6.97, 6.4, 6.07, 4.48, 4.45, 4.42, 4.41, 4.43, 4.47, 4.64, 5.11,
  6.14, 9.69, 9.88, 11.12, 12.06, 12.64, 12.53, 12.1, 11.08, 9.57, 8.43, 8.15, 7.56, 7.04, 6.53,
  6.13, 4.56, 4.48, 4.44, 4.43, 4.45, 4.45, 4.57, 5.02, 6.06, 9.38, 9.59, 10.82, 11.82, 12.36,
  12.26, 11.86, 10.89, 9.42, 8.37, 8.12, 7.5, 6.96, 6.47, 6.13, 4.5, 4.46, 4.37, 4.39, 4.47, 4.5,
  4.65, 5.13, 6.14, 9.43, 9.63, 10.91, 11.9, 12.46, 12.38, 12.05, 11.12, 9.57, 8.46, 8.21, 7.55,
  6.94, 6.43, 6.07, 4.52, 4.43, 4.38, 3.85, 3.83, 3.66, 3.62, 3.59, 3.55, 5.76, 4.1, 3.62, 3.47,
  3.34, 3.21, 3.14, 3.07, 3.03, 3.14, 4.21, 4.71, 4.92, 5.15, 5.3, 3.94, 4.01, 3.96, 3.36, 3.38,
  3.34, 3.28, 3.25, 3.24, 5.43, 3.51, 3.24, 2.92, 2.7, 2.61, 2.58, 2.55, 2.52, 2.56, 3.5, 3.97,
  4.13, 4.29, 4.4, 3.2, 3.33, 3.43, 4.11, 4.18, 4.23, 4.37, 4.8, 5.9, 9.15, 9.39, 10.67, 11.66,
  12.28, 12.31, 11.98, 11.02, 9.45, 8.34, 7.95, 7.37, 6.71, 6.23, 5.93, 4.38, 4.3, 4.29, 4.25, 4.3,
  4.38, 4.49, 4.93, 6.01, 9.41, 9.58, 10.93, 11.93, 12.48, 12.45, 12.03, 11.03, 9.57, 8.5, 8.23,
  7.61, 6.97, 6.4, 6.07, 4.48, 4.45, 4.42, 4.41, 4.43, 4.47, 4.64, 5.11, 6.14, 9.69, 9.88, 11.12,
  12.06, 12.64, 12.53, 12.1, 11.08, 9.57, 8.43, 8.15, 7.56, 7.04, 6.53, 6.13, 4.56, 4.48, 4.44,
  4.43, 4.45, 4.45, 4.57, 5.02, 6.06, 9.38, 9.59, 10.82, 11.82, 12.36, 12.26, 11.86, 10.89, 9.42,
  8.37, 8.12, 7.5, 6.96, 6.47, 6.13, 4.5, 4.46, 4.37, 4.39, 4.47, 4.5, 4.65, 5.13, 6.14, 9.43, 9.63,
  10.91, 11.9, 12.46, 12.38, 12.05, 11.12, 9.57, 8.46, 8.21, 7.55, 6.94, 6.43, 6.07, 4.52, 4.43,
  4.38, 3.85, 3.83, 3.66, 3.62, 3.59, 3.55, 5.76, 4.1, 3.62, 3.47, 3.34, 3.21, 3.14, 3.07, 3.03,
  3.14, 4.21, 4.71, 4.92, 5.15, 5.3, 3.94, 4.01, 3.96, 3.36, 3.38, 3.34, 3.28, 3.25, 3.24, 5.43,
  3.51, 3.24, 2.92, 2.7, 2.61, 2.58, 2.55, 2.52, 2.56, 3.5, 3.97, 4.13, 4.29, 4.4, 3.2, 3.33, 3.43,
  4.11, 4.18, 4.23, 4.37, 4.8, 5.9, 9.15, 9.39, 10.67, 11.66, 12.28, 12.31, 11.98, 11.02, 9.45,
  8.34, 7.95, 7.37, 6.71, 6.23, 5.93, 4.38, 4.3, 4.29, 4.25, 4.3, 4.38, 4.49, 4.93, 6.01, 9.41,
  9.58, 10.93, 11.93, 12.48, 12.45, 12.03, 11.03, 9.57, 8.5, 8.23, 7.61, 6.97, 6.4, 6.07, 4.48,
  4.45, 4.42,
];

export default secondarySchoolLoadProfile;
