import {Box, Grid} from '@material-ui/core';
import {useIntl} from 'react-intl';
import SliderInput from '@generic/components/inputs/SliderInput';
import DaysInOperation from '@generic/components/DaysInOperation';
import MonthsInOperation from '@generic/components/MonthsInOperation';

// TODO: Consider renaming this to Driving Details (and all respective translations)
export default function VehicleDetails({vehicleSet, onChangeVehicleSet, variant = ''}) {
  const {formatMessage} = useIntl();
  const {milesPerWorkday, vehicleCount, workdays, workmonths} = vehicleSet;

  const handleChangeDays = (workdays) => onChangeVehicleSet({workdays});

  const handleChangeMonths = (workmonths) => onChangeVehicleSet({workmonths});

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SliderInput
            label={formatMessage({id: 'vehicleSetDialog.vehicleDetails.numberOfVehiclesAdopted'})}
            min={1}
            max={100}
            step={1}
            value={vehicleCount}
            onChange={(value) => onChangeVehicleSet({vehicleCount: value})}
            variant={variant}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SliderInput
            label={formatMessage({id: 'vehicleSetDialog.vehicleDetails.averageMilesPerDay'})}
            tooltip={formatMessage({id: 'vehicleSetDialog.vehicleDetails.tooltip'})}
            min={5}
            max={300}
            step={5}
            value={milesPerWorkday}
            onChange={(value) => onChangeVehicleSet({milesPerWorkday: value})}
            variant={variant}
          />
        </Grid>
      </Grid>
      <Box mb={3}>
        <DaysInOperation workdays={workdays} onChange={handleChangeDays} />
      </Box>
      <MonthsInOperation workmonths={workmonths} onChange={handleChangeMonths} />
    </>
  );
}
