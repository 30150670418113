import CHARGER_CHARGING_WINDOWS from '@generic/data/chargerChargingWindows';
import TIME_PERIODS from '@generic/config/charger';

export const DEFAULT_CHARGING_WINDOW = {
  start: 21,
  finish: 5,
  chargingApproach: 'Even',
  type: 'Level 2 - 7.7kW',
};

// used after DEFAULT_CHARGING_WINDOW has already been added
export const SECONDARY_DEFAULT_CHARGING_WINDOW = {
  start: 12,
  finish: 14,
  chargingApproach: 'Max',
  type: 'DCFC - 50kW',
};

export const DEFAULT_CHARGER_SET = {
  chargerCount: 3,
  type: 'Level 2 - 7.7kW',
  workdays: [1, 2, 3, 4, 5],
  workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  chargingWindowPreset: 'medium',
  chargingWindows: CHARGER_CHARGING_WINDOWS.medium,
  maxTimePeriod: TIME_PERIODS[0].key,
  chargersPerTimePeriod: TIME_PERIODS.reduce(
    (acc, period) => ({
      ...acc,
      [period.key]: 2,
    }),
    {}
  ),
};

export const DEFAULT_VEHICLE_SET = {
  idx: -1,
  vehicle_id: 'on_road_pick_up_truck_medium_duty',
  vehicleCount: 3,
  milesPerWorkday: 100,
  hoursPerWorkday: 8,
  workdays: [1, 2, 3, 4, 5],
  workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  chargingWindows: [DEFAULT_CHARGING_WINDOW],
};

export default function getDefaults() {
  return {
    buildingType: 'smallOffice',
    currentRate: 'sc9Rate1HighTension',
    chargerSets: [DEFAULT_CHARGER_SET],
    dieselPrice: 3.1,
    gasolinePrice: 3.3,
    lng: 'en',
    monthlyElectricBill: 1000,
    isOnMarketBasedRate: true,
    separatelyMetered: true,
    publiclyAvailable: false,
    vehicleSets: [DEFAULT_VEHICLE_SET],
    zone: 'J',
    voltageLevel: 'low',
    chargingWizardShown: false,
    planningWizardShown: false,
  };
}
