import {createTheme} from '@material-ui/core/styles';
import zappyLogo from '@generic/assets/images/zappyride_logo.png';
import RobotoCondensed from '@generic/assets/fonts/RobotoCondensed-Regular.ttf';

/*
 * This file is copied over from the Generic folder when a new client is created.
 *
 * Material UI's theming system is _very_ powerful, and therefore has quite
 * a learning curve.
 *
 * Most of the code here should either be removed or replaced on each client project,
 * and this file only exists to demonstrate a few of the theming techniques that can
 * be used within Material UI.
 *
 * Ideally, the entire look and feel of the site can be controlled via this
 * configuration without requiring changes to the underlying React code.
 *
 */

// Follows from Material UI docs:
// https://material-ui.com/customization/typography/#self-hosted-fonts
const robotoCondensed = {
  fontFamily: 'Roboto Condensed',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Roboto Condensed'),
    url(${RobotoCondensed}) format('truetype')
  `,
};

/*
 * The application consumes the Material UI theme using a ThemeProvider from
 * the Material UI package. It's essentially just a plain old React Context provider.
 * As such, theming doesn't have to be restricted to out of the box Material UI
 * theme options.
 *
 * The configuration in the customThemeConfig corresponds to src/alt attributes that
 * the Header component expects to get from the `useTheme` hook. None of that has
 * anything to do with Material UI, or even CSS-in-JS. Instead, this just
 * piggy backs the theme's Context. With this technique, the theme that Material UI
 * provides can be used to theme even completely custom elements or attributes!
 *
 */
const customThemeConfig = {
  header: {
    brandLogo: {
      src: zappyLogo,
      alt: 'Zappy Ride',
    },
  },
  slider: {
    track: {
      height: '20px',
      borderRadius: '140px',
      background: '#ddd',
    },
    thumb: {
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      background: '#000',
    },
    value: {
      padding: '5px 10px',
      background: '#bbb',
      fontWeight: 600,
    },
    label: {
      color: '#888',
    },
  },
  vehicleSetDialog: {
    overview: {
      title: {},
    },
  },
  toggleButtonGroup: {
    toggleButton: {
      active: {},
    },
    toggleLabel: {},
  },
};

/*
 * https://material-ui.com/customization/theming/#theming
 *
 * Material UI's theming capabilities are really powerful, and it's worth a few
 * hours of study over the documentation if you aren't already very familiar with
 * how it works.
 *
 * As recommended in the docs, a good place to start is to inspect the
 * default theme in the console on the documentation page:
 * https://material-ui.com/customization/default-theme/#explore
 *
 * The default examples given when creating a new project include:
 *
 *   1. Palette: control over the color scheme
 *   2. props: overrides to the props of specific Material UI components.
 *   3. Overrides: conceptually the same as props, except  you specify actual
 *      CSS properties instead of props that a component would receive. These
 *      can be nested, AND can target arbitrary selectors (not just Material
 *      UI selectors), which allows very fine grained control over the app's style.
 *   4. Typography: control over how text is rendered by Material UI
 *      Typography elements.
 *
 */

const ZR_ORANGE = '#f98925';
const ZR_BLACK = '#121922';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
    },
  },
  palette: {
    primary: {
      main: ZR_ORANGE,
      light: ZR_ORANGE,
      lighter: ZR_ORANGE,
      dark: ZR_ORANGE,
    },
    secondary: {
      main: ZR_BLACK,
      light: '#ddd',
      lighter: '#ddd',
    },
    gray: {
      200: '#ddd',
      300: '#bbb',
      400: '#aaa',
    },
  },
  props: {
    MuiAppBar: {
      // color: 'secondary',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [robotoCondensed],
      },
    },
    MuiToolbar: {
      root: {
        color: '#ffffff',
      },
    },
  },
  typography: {
    fontFamily: {
      primary: "'Roboto Condensed', Arial",
      secondary: "'Roboto Condensed', Arial",
    },
    primary: {
      fontFamily: "'Roboto Condensed', Arial",
    },
    secondary: {
      fontFamily: "'Roboto Condensed', Arial",
    },
  },
  ...customThemeConfig,
});

export default theme;
