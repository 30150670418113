import {Box, makeStyles, Typography} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.gray[300]}`,
    display: 'flex',
  },
  tab: {
    flexGrow: 1,
    padding: '6px 10px',
    color: theme.palette.gray[500],
    [theme.breakpoints.up('sm')]: {
      padding: '6px 20px',
    },
  },
  tabLabel: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  activeTab: {
    backgroundColor: theme.palette.gray[600],
    color: theme.palette.common.white,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  previousTab: {
    backgroundColor: theme.palette.gray[600],
    color: theme.palette.common.white,
    '& .MuiTypography-root': {
      textDecoration: 'underline',
    },
  },
  icon: {
    width: 8,
    marginLeft: 20,
    [theme.breakpoints.up('md')]: {
      marginLeft: 4,
    },
  },
  bold: {
    fontWeight: 700,
  },
}));

export default function WizardHeader({tabs, activeTab}) {
  const classes = useStyles();

  const getTabClassName = (tab) => {
    if (activeTab === tab) {
      return `${classes.tab} ${classes.activeTab}`;
    }
    if (activeTab > tab) {
      return `${classes.tab} ${classes.previousTab}`;
    }
    return classes.tab;
  };

  return (
    <Box className={classes.root}>
      {tabs.map((tab, i) => (
        <Box key={tab.label} className={getTabClassName(i)} display="flex" alignItems="center">
          <Typography variant="body2">
            <span className={classes.bold}>{`${i + 1}. `}</span>
            <span className={classes.tabLabel}>{tab.label}</span>
          </Typography>
          <span className={classes.icon}>{activeTab > i ? '\u2713' : '\u2022'}</span>
        </Box>
      ))}
    </Box>
  );
}
