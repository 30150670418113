import {LoadProfile, RateCalculator} from '@bellawatt/electric-rate-engine';
import calcChargerSetFacilityLoadProfile from '@generic/functions/calcChargerSetFacilityLoadProfile';
import {YEAR, SEASONS, HOURS_IN_YEAR} from '../../Generic/data/assumptions';
import groupVehicleSetsByChargers from '../../Generic/functions/groupVehicleSetsByChargers';
import calcVehicleSetFacilityLoadProfile from '../../Generic/functions/calcVehicleSetFacilityLoadProfile';
import isEligibleForDemandChargeRebate from './isEligibleForDemandChargeRebate';
import peakWindowsByZipcode, {DEFAULT_PEAK_DEMAND_WINDOWS} from '../data/peakWindowsByZipcode';

const sccRate = ({
  summerPeakAvoidanceCharge,
  winterPeakAvoidanceCharge,
  offPeakUsageCharge,
  transitAdderCharge,
  publicL2AdderCharge,
}) => ({
  name: 'SCC Rebate',
  title: 'SCC Rebate',
  rateElements: [
    {
      id: 'demand',
      rateElementType: 'FixedPerMonth',
      name: 'SCC Demand Rebate',
      rateComponents: [
        {
          charge: summerPeakAvoidanceCharge,
          name: 'Summer Peak Avoidance',
        },
        {
          charge: winterPeakAvoidanceCharge,
          name: 'Winter Peak Avoidance',
        },
      ],
    },
    {
      id: 'energy',
      rateElementType: 'EnergyTimeOfUse',
      name: 'SCC Energy Rebate',
      rateComponents: [
        {
          charge: offPeakUsageCharge,
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7], // 12am - 8am,
          name: 'Off Peak Energy Usage',
        },
        {
          charge: 0,
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], // 8am - 12am
          name: 'Peak Energy Usage',
        },
      ],
    },
    {
      id: 'demand',
      rateElementType: 'FixedPerMonth',
      name: 'Transit Adder',
      rateComponents: [
        {
          charge: transitAdderCharge,
          name: 'Transit Adder',
        },
      ],
    },
    {
      id: 'demand',
      rateElementType: 'FixedPerMonth',
      name: 'Public L2 Adder',
      rateComponents: [
        {
          charge: publicL2AdderCharge,
          name: 'Public L2 Adder',
        },
      ],
    },
  ],
});

const calcSccRebate = (inputs, chargerSetsData, rateCalculator) => {
  const {includeSCCRebate, siteAdditionalQualifications, zipcode} = inputs;
  if (!includeSCCRebate)
    return {
      energy: 0,
      demand: 0,
      total: 0,
    };

  const publiclyAvailable = siteAdditionalQualifications === 'publiclyAvailable';

  const dcfcLoadProfile = chargerSetsData
    .filter(({chargerType}) => chargerType === 'dc_fast_charging')
    .reduce(
      (accLoadProfile, {loadProfile}) => loadProfile.aggregate(loadProfile),
      new LoadProfile(new Array(HOURS_IN_YEAR).fill(0), {year: YEAR})
    );

  const maxDemand = 0;
  const areWeIgnoringDcrInThisCalculation = true;
  let isEligibleForDcr = false;
  if (!areWeIgnoringDcrInThisCalculation) {
    isEligibleForDcr = isEligibleForDemandChargeRebate({
      ...inputs,
      maxDemand,
      dcfcLoadProfile,
    });
  }
  let totalRebateAmount = 0;
  let energyRebateAmount = 0;
  let demandRebateAmount = 0;

  chargerSetsData.forEach(({chargerType, loadProfile: chargerSetLoadProfile, nameplateKw = 0}) => {
    const chargerSetLoadFactor = chargerSetLoadProfile.loadFactor();
    const chargerSetMonthlyDemand = chargerSetLoadProfile
      .filterBy({
        hourStarts: peakWindowsByZipcode[zipcode] || DEFAULT_PEAK_DEMAND_WINDOWS,
      })
      .maxByMonth();
    const chargerSetNameplateMonthlyDemand = new Array(12).fill(0).map(() => nameplateKw);
    const avoidedDemand = chargerSetNameplateMonthlyDemand.map((nameplateDemand, month) => {
      const actualDemand = chargerSetMonthlyDemand[month];
      return nameplateDemand - actualDemand;
    });

    let summerPeakAvoidanceCharge = 0;
    let winterPeakAvoidanceCharge = 0;
    let offPeakUsageCharge = 0;
    if (chargerType === 'dc_fast_charging' && publiclyAvailable && !isEligibleForDcr) {
      summerPeakAvoidanceCharge = chargerSetLoadFactor >= 0.15 ? 26 : 20;
      winterPeakAvoidanceCharge = 8;
      offPeakUsageCharge = 0.03;
    } else if (chargerType === 'dc_fast_charging' && publiclyAvailable) {
      summerPeakAvoidanceCharge = 3;
      winterPeakAvoidanceCharge = 0.5;
      offPeakUsageCharge = 0.03;
    } else if (chargerType === 'level_2' && publiclyAvailable) {
      summerPeakAvoidanceCharge = 17;
      winterPeakAvoidanceCharge = 6;
      offPeakUsageCharge = 0.03;
    } else if (chargerType === 'level_2' || chargerType === 'dc_fast_charging') {
      summerPeakAvoidanceCharge = 10;
      winterPeakAvoidanceCharge = 2;
      offPeakUsageCharge = 0.03;
    }
    summerPeakAvoidanceCharge = avoidedDemand.map((demand, monthIdx) =>
      SEASONS[monthIdx] === 'SUMMER' ? demand * summerPeakAvoidanceCharge : 0
    );
    winterPeakAvoidanceCharge = avoidedDemand.map((demand, monthIdx) =>
      SEASONS[monthIdx] === 'WINTER' ? demand * winterPeakAvoidanceCharge : 0
    );

    const isTransitAgency = false;
    let transitAdderPerKw = 0;
    if (isTransitAgency) {
      if (chargerSetLoadFactor < 0.05) {
        transitAdderPerKw = 6;
      } else if (chargerSetLoadFactor < 0.1) {
        transitAdderPerKw = 5;
      } else if (chargerSetLoadFactor < 0.15) {
        transitAdderPerKw = 4;
      }
    }
    const transitAdderCharge = transitAdderPerKw * nameplateKw;

    const isPublicL2 = chargerType === 'level_2' && publiclyAvailable;
    let publicL2AdderPerKw = 0;
    if (isPublicL2) {
      if (chargerSetLoadFactor < 0.05) {
        publicL2AdderPerKw = 3;
      } else if (chargerSetLoadFactor < 0.1) {
        publicL2AdderPerKw = 2;
      } else if (chargerSetLoadFactor < 0.15) {
        publicL2AdderPerKw = 1;
      }
    }
    const publicL2AdderCharge = publicL2AdderPerKw * nameplateKw;

    const rate = sccRate({
      summerPeakAvoidanceCharge,
      winterPeakAvoidanceCharge,
      offPeakUsageCharge,
      transitAdderCharge,
      publicL2AdderCharge,
    });
    const calculator = new RateCalculator({
      ...rate,
      loadProfile: chargerSetLoadProfile,
    });

    totalRebateAmount += calculator.annualCost();
    energyRebateAmount += calculator.annualCost({ids: ['energy']});
    demandRebateAmount += calculator.annualCost({ids: ['demand']});
  });

  return {
    energy: Math.min(energyRebateAmount, rateCalculator.annualCost({classifications: ['energy']})),
    demand: Math.min(demandRebateAmount, rateCalculator.annualCost({classifications: ['demand']})),
    total: Math.min(totalRebateAmount, rateCalculator.annualCost()),
  };
};

export const calcSiteSccRebate = (inputs, rateCalculator) => {
  const {chargerSets} = inputs;
  const chargerSetsData = chargerSets.map(
    ({charger: {chargerType, portKw, ports}, chargerCount}) => {
      const loadProfileData = calcChargerSetFacilityLoadProfile(inputs);

      return {
        chargerType,
        loadProfile: new LoadProfile(loadProfileData, {year: YEAR}),
        nameplateKw: portKw * ports * chargerCount,
      };
    }
  );
  return calcSccRebate(inputs, chargerSetsData, rateCalculator);
};

export const calcFleetSccRebate = (inputs, rateCalculator) => {
  const {vehicleSets} = inputs;

  const chargerSets = groupVehicleSetsByChargers(vehicleSets);
  const chargerSetsData = chargerSets.map(
    ({chargerType, vehicles, portKw, ports, minimumRequired}) => {
      // A top level vehicle set has charging windows as an array. Vehicles within a group from groupVehicleSetsByChargers
      // have a single charging window. We need to convert the vehicle sets to have charging windows as an array.
      const chargerSetVehicleSets = vehicles.map((almostVehicleSet) => ({
        chargingWindows: [almostVehicleSet.chargingWindow],
        ...almostVehicleSet,
      }));
      const loadProfileData = calcVehicleSetFacilityLoadProfile({
        vehicleSets: chargerSetVehicleSets,
      });

      return {
        chargerType,
        loadProfile: new LoadProfile(loadProfileData, {year: YEAR}),
        nameplateKw: portKw * ports * minimumRequired,
      };
    }
  );

  return calcSccRebate(inputs, chargerSetsData, rateCalculator);
};
