import {useState} from 'react';
import {Box} from '@material-ui/core';
import VehicleSetInputCard from '@generic/components/inputs/VehicleSetInputCard';
import VehicleSetDialog from '@generic/components/VehicleSetDialog';

export default function VehicleSetInput({onChange, onRemove, vehicleSet}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box>
      <VehicleSetInputCard onClick={() => setIsOpen(true)} vehicleSet={vehicleSet} />
      <VehicleSetDialog
        isOpen={isOpen}
        onChange={onChange}
        onClose={() => setIsOpen(false)}
        onRemove={onRemove}
        vehicleSet={vehicleSet}
      />
    </Box>
  );
}
