import {Box, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {formatAsDollars} from '@generic/functions/formatters';
import sum from 'lodash/sum';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gray[300],
    padding: '0.875rem 2rem',
  },
  topText: {
    marginBottom: '1rem',
  },
}));

const DISCOUNT_AS_DECIMAL = 0.5;

export default function DemandChargeRebateDisclaimer({rateCalculator}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const demandChargeRebateElements = rateCalculator.rateElements({
    ids: ['demandChargeRebate'],
  });
  const discountSubtotal = sum(demandChargeRebateElements.map((el) => el.annualCost()));

  const savingsAmount = Math.round(discountSubtotal * DISCOUNT_AS_DECIMAL);
  const monthlySavingsAmount = Math.round(savingsAmount / 12);

  return (
    <Box mt={6} className={classes.root}>
      <Typography className={classes.topText} align="center">
        {formatMessage(
          {id: 'demandChargeRebate.disclaimer'},
          {
            percent: DISCOUNT_AS_DECIMAL * 100,
            amount: formatAsDollars(monthlySavingsAmount),
            bold: (str) => <b>{str}</b>,
          }
        )}
      </Typography>
      <Typography align="center">
        {formatMessage(
          {id: 'demandChargeRebate.seeMore'},
          {
            link: (
              <a
                href={formatMessage({id: 'demandChargeRebate.link'})}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({id: 'demandChargeRebate.linkTrigger'})}
              </a>
            ),
          }
        )}
      </Typography>
    </Box>
  );
}
