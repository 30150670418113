const BASE_DAYS = [
  {
    dayIdx: 0,
    position: 6,
  },
  {
    dayIdx: 1,
    position: 0,
  },
  {
    dayIdx: 2,
    position: 1,
  },
  {
    dayIdx: 3,
    position: 2,
  },
  {
    dayIdx: 4,
    position: 3,
  },
  {
    dayIdx: 5,
    position: 4,
  },
  {
    dayIdx: 6,
    position: 5,
  },
];

export const DAYS = BASE_DAYS.map((day) => ({
  ...day,
  translationKey: `timePeriods.days.${day.dayIdx}.long`,
}));

export const DAYS_ABBREVIATED = DAYS.map((day) => ({
  ...day,
  translationKey: `timePeriods.days.${day.dayIdx}.abbreviated`,
}));

export const MONTHS = [...Array(12).keys()].map((monthIdx) => ({
  monthIdx,
  translationKey: `timePeriods.months.${monthIdx}.long`,
}));

export const MONTHS_ABBREVIATED = [...Array(12).keys()].map((monthIdx) => ({
  monthIdx,
  translationKey: `timePeriods.months.${monthIdx}.abbreviated`,
}));
