import {useTheme} from '@material-ui/core/styles';

export default function IconCircleQuestion({color}) {
  const theme = useTheme();
  const stroke = color || theme.palette.gray[400];
  const fill = color || theme.palette.gray[400];

  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle stroke={stroke} cx="7.5" cy="7.5" r="6.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.93756 8.42684V8.70213H7.00156V8.38096C7.00156 8.00472 7.09332 7.76613 7.31356 7.47249L8.02932 6.50896C8.1945 6.28872 8.31379 6.06849 8.31379 5.81154C8.31379 5.29766 7.94673 4.8939 7.4145 4.8939C6.84556 4.8939 6.52438 5.32519 6.52438 5.82072H5.58838C5.58838 4.78378 6.37756 4.05884 7.4145 4.05884C8.41473 4.05884 9.24979 4.72872 9.24979 5.81154C9.24979 6.25201 9.05708 6.60072 8.80932 6.94025L8.10273 7.93131C8.00179 8.06896 7.93756 8.28001 7.93756 8.42684ZM7.98323 9.64728V10.6475H6.94629V9.64728H7.98323Z"
        fill={fill}
      />
    </svg>
  );
}
