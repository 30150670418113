import React, {useState} from 'react';
import {Box, Typography, makeStyles, FormControl, Select, MenuItem} from '@material-ui/core';
import PlanningSiteMonthlyDemandChart from '@generic/pages/SitePlanner/PlanningSiteMonthlyDemandChart';
import {useIntl} from 'react-intl';

const useStyles = makeStyles(() => ({
  selectLabel: {
    marginRight: '1rem',
    lineHeight: '1.5rem',
  },
  select: {
    fontSize: '1rem',
    marginRight: '8px',
    fontWeight: 600,
    '& .MuiSelect-root': {
      paddingBottom: '4px',
    },
  },
}));

export default function PlanningSiteMonthlyDemand() {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const timeRanges = [
    {value: 'month', display: formatMessage({id: 'timePeriods.labels.month'})},
    {value: 'day', display: formatMessage({id: 'timePeriods.labels.day'})},
    {value: 'hour', display: formatMessage({id: 'timePeriods.labels.hour'})},
  ];

  // Default to monthly view
  const [selectedTimeRange, setSelectedTimeRange] = useState(timeRanges[0]);

  const handleSelectChange = (e) => {
    const timeRange =
      timeRanges.find((range) => e.target.value === range.value) || timeRanges[7].value;
    setSelectedTimeRange(timeRange);
  };

  return (
    <Box mt={12}>
      <Typography variant="h6" align="center">
        {formatMessage({id: 'charging.content.costs.electricity.monthlyDemand.header'})}
      </Typography>
      <Typography variant="body2" align="center">
        {formatMessage({id: 'charging.content.costs.electricity.monthlyDemand.subheader'})}
      </Typography>

      <Box mt={3} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" className={classes.selectLabel}>
          <label htmlFor="monthly-demand-time-range">
            {formatMessage({id: 'charging.content.costs.electricity.loadProfile.selectLabel'})}
          </label>
        </Typography>
        <FormControl>
          <Select
            className={classes.select}
            value={selectedTimeRange.value}
            onChange={handleSelectChange}
            inputProps={{id: 'monthly-demand-time-range'}}
          >
            {timeRanges.map((timeRange) => (
              <MenuItem key={timeRange.value} value={timeRange.value}>
                {timeRange.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <PlanningSiteMonthlyDemandChart timeRange={selectedTimeRange} />
    </Box>
  );
}
