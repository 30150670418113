import {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  makeStyles,
} from '@material-ui/core';
import {useIntl} from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import useDeepCompareEffect from 'use-deep-compare-effect';
import SidebarInput from '@generic/components/inputs/SidebarInput';
import CtaButton from '@generic/components/CtaButton';
import Overview from '@generic/components/ChargerSetDialog/Overview';
import SelectCharger from '@generic/components/ChargerSetDialog/SelectCharger';
import Utilization from '@generic/components/ChargerSetDialog/Utilization';
import {useInputs} from '@bellawatt/use-inputs';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: '755px',
  },
  dialogTitle: {
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      padding: '16px 24px',
    },
  },
  dialogContent: {
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      padding: '16px 24px',
    },
  },
  dialogActions: {
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },

    '& > button': {
      width: '100%',
      marginTop: 8,
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        marginTop: 0,
      },
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
    fontWeight: 600,
    textTransform: 'none',
  },
  cancelButton: {
    color: theme.palette.gray[500],
    fontWeight: 600,
    padding: '0.75rem 1.5rem',
    borderRadius: '10em',
    textTransform: 'none',
    border: `1px solid ${theme.palette.gray[500]}`,
  },
}));

export default function ChargerSetDialog({
  chargerSet: {charger, ...restOfChargerSet},
  isOpen,
  onChange,
  onClose,
  onRemove,
}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const [workingChargerSet, setWorkingChargerSet] = useState(cloneDeep(restOfChargerSet));

  // needed to make sure we aren't deleting the one and only charger set
  const {chargerSets} = useInputs();

  // if the charger set changes via the wizard dialog, the working state here needs to reflect that
  useDeepCompareEffect(() => {
    setWorkingChargerSet(restOfChargerSet);
  }, [restOfChargerSet]);

  const handleChangeChargerSet = (newData) =>
    setWorkingChargerSet((current) => ({...current, ...newData}));

  const handleSaveAndClose = () => {
    onChange(workingChargerSet);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="body"
      PaperProps={{className: classes.dialogPaper}}
      fullWidth
    >
      <Box p={{xs: 1, md: 3}}>
        <DialogTitle className={classes.dialogTitle}>
          <Overview chargerSet={workingChargerSet} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <SidebarInput title={formatMessage({id: 'chargerSetDialog.chargers'})}>
            <Box py={{xs: 1, sm: 2, md: 3}} px={{xs: 2, md: 5}}>
              <SelectCharger
                chargerSet={workingChargerSet}
                onChangeChargerSet={handleChangeChargerSet}
              />
            </Box>
          </SidebarInput>

          <SidebarInput title={formatMessage({id: 'chargerSetDialog.utilization'})}>
            <Box py={{xs: 1, sm: 2, md: 3}} px={{xs: 2, md: 5}}>
              <Utilization
                chargerSet={workingChargerSet}
                onChangeChargerSet={handleChangeChargerSet}
              />
            </Box>
          </SidebarInput>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {chargerSets.length > 1 && (
            <Button className={classes.deleteButton} onClick={onRemove}>
              <Icon className="icon-close" />
              {formatMessage({id: 'chargerSetDialog.deleteChargerSet'})}
            </Button>
          )}
          <Button className={classes.cancelButton} onClick={onClose}>
            {formatMessage({id: 'chargerSetDialog.cancelChanges'})}
          </Button>
          <CtaButton onClick={handleSaveAndClose}>
            {formatMessage({id: 'chargerSetDialog.saveChanges'})}
          </CtaButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
