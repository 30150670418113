import Layout from '@generic/components/Layout';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import heroImage from '@generic/assets/images/ev-plug.jpeg';
import evIllustration from '@generic/assets/images/ev-illustration.svg';

const useStyles = makeStyles((theme) => ({
  heroWrapper: {
    backgroundColor: theme.palette.gray[200],
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 1,
    },
    [theme.breakpoints.up('lg')]: {
      width: '58%',
      order: 1,
      padding: theme.spacing(7),
    },
  },
  header: {
    padding: 20,
    fontWeight: 800,
    textAlign: 'center',
  },
  subheader: {
    padding: '10px 20px',
    fontWeight: 500,
    fontSize: '1.125rem',
    color: theme.palette.gray[500],
    textAlign: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
    [theme.breakpoints.up('lg')]: {
      width: '42%',
      order: 2,
    },
  },
  heroImage: {
    width: 'auto',
    maxWidth: '100%',
    marginTop: '-6px',
    display: 'none',
    objectFit: 'cover',

    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      display: 'block',
    },
  },
  featuresHeader: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: '2.125rem',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  featuresContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(7),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  featureBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      maxWidth: 275,
    },
  },
  featureTitle: {
    fontSize: '1.125rem',
    fontWeight: 700,
    fontFamily: theme.typography.primary.fontFamily,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  featureText: {
    fontSize: '1rem',
    textAlign: 'center',
    color: theme.palette.gray[500],
    fontWeight: 500,
  },
  evIllustration: {
    width: 484,
    maxWidth: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctaHeader: {
    fontWeight: 700,
    fontFamily: theme.typography.primary.fontFamily,
    fontSize: '2.25rem',
    textAlign: 'center',
  },
  ctaLinks: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  navSubtitle: {
    fontSize: '0.875rem',
    color: theme.palette.gray[500],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textOverflow: 'wrap',

    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
      width: 300,
    },
  },
  navLink: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 86,
    fontSize: '1rem',
    width: 300,
    maxWidth: '95vw',
    fontWeight: 700,
    textDecoration: 'none',
    padding: '12px 50px',
    margin: theme.spacing(1),
    textAlign: 'center',
    transition: 'all 200ms ease',
  },
  navLinkPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export default function Home() {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  return (
    <Layout noPadding>
      <Box display="flex" flexWrap="wrap" className={classes.heroWrapper}>
        <Box className={classes.imageContainer}>
          <img src={heroImage} alt="" className={classes.heroImage} />
        </Box>
        <Box className={classes.headerContainer}>
          <Typography variant="h1" className={classes.header}>
            {formatMessage({id: 'home.header'})}
          </Typography>
          <Typography variant="body2" className={classes.subheader}>
            {formatMessage({id: 'home.subheader'})}
          </Typography>
          <Box className={classes.navContainer}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <a href="/sites" className={`${classes.navLink} ${classes.navLinkPrimary}`}>
                {formatMessage({id: 'home.nav.planning.button'})}
              </a>
              <Typography align="center" className={classes.navSubtitle}>
                {formatMessage({id: 'home.nav.planning.subtitle'})}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <a href="/fleets" className={classes.navLink}>
                {formatMessage({id: 'home.nav.charging.button'})}
              </a>
              <Typography align="center" className={classes.navSubtitle}>
                {formatMessage({id: 'home.nav.charging.subtitle'})}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box pt={6}>
        <Typography variant="h2" className={classes.featuresHeader}>
          {formatMessage({id: 'home.features.header'})}
        </Typography>
        <Box className={classes.featuresContent}>
          <Box>
            <Box mb={10} className={classes.featureBlock}>
              <Typography className={classes.featureTitle}>
                {formatMessage({id: 'home.features.analysis.title'})}
              </Typography>
              <Typography variant="body2" className={classes.featureText}>
                {formatMessage({id: 'home.features.analysis.text'})}
              </Typography>
            </Box>
            <Box className={classes.featureBlock}>
              <Typography className={classes.featureTitle}>
                {formatMessage({id: 'home.features.rates.title'})}
              </Typography>
              <Typography variant="body2" className={classes.featureText}>
                {formatMessage({id: 'home.features.rates.text'})}
              </Typography>
            </Box>
          </Box>

          <img className={classes.evIllustration} src={evIllustration} alt="" />

          <Box>
            <Box mb={10} className={classes.featureBlock}>
              <Typography className={classes.featureTitle}>
                {formatMessage({id: 'home.features.savings.title'})}
              </Typography>
              <Typography variant="body2" className={classes.featureText}>
                {formatMessage({id: 'home.features.savings.text'})}
              </Typography>
            </Box>
            <Box className={classes.featureBlock}>
              <Typography className={classes.featureTitle}>
                {formatMessage({id: 'home.features.incentives.title'})}
              </Typography>
              <Typography variant="body2" className={classes.featureText}>
                {formatMessage({id: 'home.features.incentives.text'})}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box p={7} className={classes.cta}>
        <Typography className={classes.ctaHeader} variant="h3">
          {formatMessage({id: 'home.cta'})}
        </Typography>
        <Box mt={6} className={classes.ctaLinks}>
          <a href="/sites" className={`${classes.navLink} ${classes.navLinkPrimary}`}>
            {formatMessage({id: 'home.nav.planning.button'})}
          </a>
          <a href="/fleets" className={classes.navLink}>
            {formatMessage({id: 'home.nav.charging.button'})}
          </a>
        </Box>
      </Box>
    </Layout>
  );
}
