import CHARGERS from '@generic/data/chargers';
import isHourInUse from '@generic/functions/isHourInUse';

// TODO: move this into a dedicated function that throws an error when a charger can't be found
const getCharger = (type) => CHARGERS.find((c) => c.type === type);

const calcPlugSchedule = ({chargingWindows}) =>
  [...Array(24).keys()].map((hour) => {
    const firstInUseChargingWindow = chargingWindows.filter((cw) => isHourInUse(cw, hour))[0];

    return {
      chargingApproach: firstInUseChargingWindow?.chargingApproach || null,
      portKw: getCharger(firstInUseChargingWindow?.type)?.portKw || null,
    };
  });

export default calcPlugSchedule;
