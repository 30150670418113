import {Helmet} from 'react-helmet-async';
import ConEdFavicon from '@generic/assets/images/favicon.ico';

export default function FaviconMetaTag() {
  return (
    <Helmet>
      <link rel="icon" type="image/ico" href={ConEdFavicon} />
    </Helmet>
  );
}
