import {Card, CardHeader, IconButton, makeStyles} from '@material-ui/core';
import {ReactComponent as PencilIcon} from '@generic/assets/images/icon-pencil.svg';
import {formatVehicleSetTitle} from '@generic/functions/formatters';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.gray[200],
    },
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.gray[200],
    },
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    '& .MuiCardHeader-action': {
      alignSelf: 'auto',
      marginRight: 0,
      marginTop: 0,
    },
  },
  vehicleImage: {
    height: 'auto',
    width: '2.5rem',
  },
}));

export default function VehicleSetInputCard({onClick, vehicleSet}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const {
    vehicle: {imageUrl},
  } = vehicleSet;

  return (
    <Card className={classes.card} onClick={onClick}>
      <CardHeader
        avatar={<img className={classes.vehicleImage} src={imageUrl} alt="" />}
        action={
          <IconButton
            className={classes.button}
            size="small"
            onClick={onClick}
            aria-label={formatMessage({id: 'charging.inputs.vehicleSets.editButton'})}
          >
            <PencilIcon />
          </IconButton>
        }
        className={classes.header}
        title={formatVehicleSetTitle(vehicleSet)}
      />
    </Card>
  );
}
