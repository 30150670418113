import {Box, Grid, Paper, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import uniq from 'lodash/uniq';
import DropdownInput from '@generic/components/inputs/DropdownInput';
import VEHICLES from '@generic/data/vehicles';

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '130px',
  },
  label: {
    fontSize: '0.875rem',
    color: theme.palette.gray[400],
    flexGrow: 1,
  },
  value: {
    fontSize: '0.875rem',
    textAlign: 'right',
  },
}));

function Spec({label, value}) {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  );
}

export default function SelectVehicle({vehicleSet, onChangeVehicleSet, variant = ''}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const {vehicle_id: vehicleId} = vehicleSet;
  // Can't use the 'computed' vehicle because this vehicle set is a working copy
  // stored in regular state
  const vehicle = VEHICLES.find((v) => v.id === vehicleId);
  const {
    batteryCapacityInKwh,
    imageUrl,
    rangeInMiles,
    efficiencyInKwhPerHundredMiles,
    type,
    subtype,
    gvwr,
  } = vehicle;

  const typeOptions = uniq(VEHICLES.map(({type}) => type)).map((type) => ({
    value: type,
    children: type,
  }));

  const handleChangeType = (e) => {
    const newVehicle = VEHICLES.find((v) => v.type === e.target.value);
    onChangeVehicleSet({vehicle_id: newVehicle.id});
  };

  const subtypeOptions = uniq(VEHICLES.filter((v) => v.type === type).map((v) => v.subtype)).map(
    (subtype) => ({value: subtype, children: subtype})
  );

  const handleChangeSubtype = (e) => {
    const newVehicle = VEHICLES.find((v) => v.type === type && v.subtype === e.target.value);
    onChangeVehicleSet({vehicle_id: newVehicle.id});
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
      <Grid item xs={12} md={6}>
        <Box mb={5}>
          <DropdownInput
            fullWidth
            title={formatMessage({id: 'vehicleSetDialog.selectVehicle.vehicleType'})}
            options={typeOptions}
            value={type}
            onChange={handleChangeType}
            variant={variant}
          />
        </Box>
        <Box mb={5}>
          <DropdownInput
            fullWidth
            title={formatMessage({id: 'vehicleSetDialog.selectVehicle.vehicleSubtype'})}
            onChange={handleChangeSubtype}
            options={subtypeOptions}
            value={subtype}
            variant={variant}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper variant="outlined" square>
          <Box p={2}>
            <img className={classes.img} src={imageUrl} alt="" />
            <Spec
              label={formatMessage({id: 'vehicleSetDialog.overview.range.label'})}
              value={formatMessage(
                {id: 'vehicleSetDialog.overview.range.value'},
                {range: rangeInMiles}
              )}
            />
            <Spec
              label={formatMessage({id: 'vehicleSetDialog.overview.batteryCapacity.label'})}
              value={formatMessage(
                {id: 'vehicleSetDialog.overview.batteryCapacity.value'},
                {batteryCapacity: batteryCapacityInKwh}
              )}
            />
            <Spec
              label={formatMessage({id: 'vehicleSetDialog.overview.efficiency.label'})}
              value={formatMessage(
                {id: 'vehicleSetDialog.overview.efficiency.value'},
                {efficiency: efficiencyInKwhPerHundredMiles}
              )}
            />
            <Spec
              label={formatMessage({id: 'vehicleSetDialog.overview.gvwr.label'})}
              value={formatMessage({id: 'vehicleSetDialog.overview.gvwr.value'}, {weight: gvwr})}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
