import React, {useState} from 'react';
import {Box, AppBar, Tab, Tabs as MuiTabs} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';
import ElectricityCosts from '@generic/pages/ChargingCalculator/ElectricityCosts';
import FuelCosts from '@generic/pages/ChargingCalculator/FuelCosts';
import MaintenanceCosts from '@generic/pages/ChargingCalculator/MaintenanceCosts';

const TabPanel = ({children, value, index, ...other}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

const StyledAppBar = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: 'none',
  },
}))(AppBar);

const StyledTabs = withStyles({
  root: {
    backgroundColor: 'transparent',
    minHeight: 'auto',
  },
  scroller: {display: 'flex', justifyContent: 'center'},
  indicator: {
    backgroundColor: 'transparent',
  },
})(MuiTabs);

const StyledTab = withStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.gray[500],
    padding: '6px 12px',
    minHeight: 'auto',
    fontWeight: 400,
    borderBottom: `3px solid transparent`,
    '&:focus': {
      opacity: 1,
    },
    '&.Mui-selected': {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      fontWeight: 600,
    },
  },
}))(Tab);

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

export default function ChargingCosts() {
  const {formatMessage} = useIntl();
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      label: formatMessage({id: 'charging.content.costs.tabs.electricityLabel'}),
      ContentComponent: ElectricityCosts,
    },
    {
      label: formatMessage({id: 'charging.content.costs.tabs.fuelLabel'}),
      ContentComponent: FuelCosts,
    },
    {
      label: formatMessage({id: 'charging.content.costs.tabs.maintenanceLabel'}),
      ContentComponent: MaintenanceCosts,
    },
  ];

  return (
    <>
      <StyledAppBar position="static">
        <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
          {tabs.map(({label}, idx) => (
            <StyledTab key={label} label={label} {...a11yProps(idx)} />
          ))}
        </StyledTabs>
      </StyledAppBar>
      {tabs.map(({ContentComponent, label}, idx) => (
        <TabPanel key={label} value={value} index={idx}>
          <ContentComponent />
        </TabPanel>
      ))}
    </>
  );
}
