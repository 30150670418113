import React from 'react';
import {useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  formatAsThousands,
  formatAsDollars,
  formatAsCents,
  formatVehicleName,
} from '@generic/functions/formatters';
import calcMilesPerYear from '@generic/functions/calcMilesPerYear';
import calcFossilFuelCostForVehicleSet from '@generic/functions/calcFossilFuelCostForVehicleSet';
import {useInputs} from '@bellawatt/use-inputs';
import calcAnnualFossilFuelCost from '@generic/functions/calcAnnualFossilFuelCosts';

const useStyles = makeStyles((theme) => ({
  totalRow: {
    '& .MuiTableCell-root': {
      fontWeight: '800',
    },
  },

  table: {
    '& .MuiTableCell-root': {
      [theme.breakpoints.down('sm')]: {
        padding: 6,
      },
    },
  },
}));

export default function FuelCostsTable() {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const inputs = useInputs();
  const {vehicleSets, dieselPrice, gasolinePrice, lpgPrice} = inputs;

  const getFuelCost = (fuelType) => {
    const fuelTypePrices = {
      diesel: dieselPrice,
      gasoline: gasolinePrice,
      lpg: lpgPrice,
    };
    return +fuelTypePrices[fuelType];
  };

  return (
    <TableContainer>
      <Table
        aria-label={formatMessage({id: 'charging.content.costs.fuel.table.ariaLabel'})}
        className={classes.table}
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>
              {formatMessage({id: 'charging.content.costs.fuel.table.headers.vehicle'})}
            </TableCell>
            <TableCell>
              {formatMessage({id: 'charging.content.costs.fuel.table.headers.count'})}
            </TableCell>
            <TableCell>
              {formatMessage({id: 'charging.content.costs.fuel.table.headers.efficiency'})}
            </TableCell>
            <TableCell>
              {formatMessage({id: 'charging.content.costs.fuel.table.headers.miles'})}
            </TableCell>
            <TableCell>
              {formatMessage({id: 'charging.content.costs.fuel.table.headers.fuelCost'})}
            </TableCell>
            <TableCell>
              {formatMessage({id: 'charging.content.costs.fuel.table.headers.cost'})}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicleSets.map((vehicleSet, idx) => (
            <TableRow key={idx}>
              <TableCell component="th" scope="row">
                {formatVehicleName(vehicleSet.vehicle)}
              </TableCell>
              <TableCell align="right">{formatAsThousands(vehicleSet.vehicleCount)}</TableCell>
              <TableCell align="right">
                {vehicleSet.vehicle.fossilMilesPerGallon.toFixed(1)}
              </TableCell>
              <TableCell align="right">{formatAsThousands(calcMilesPerYear(vehicleSet))}</TableCell>
              <TableCell align="right">
                {formatAsCents(getFuelCost(vehicleSet.vehicle.fossilFuelType))}
              </TableCell>
              <TableCell align="right">
                {formatAsDollars(calcFossilFuelCostForVehicleSet({vehicleSet, ...inputs}))}
              </TableCell>
            </TableRow>
          ))}
          <TableRow className={classes.totalRow}>
            <TableCell component="th" scope="row">
              {formatMessage({id: 'charging.content.costs.fuel.table.total'})}
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align="right">{formatAsDollars(calcAnnualFossilFuelCost(inputs))}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
