import React from 'react';
import {Grid, Box, Divider} from '@material-ui/core';
import {useInputs} from '@bellawatt/use-inputs';
import {useIntl} from 'react-intl';
import FuelInsight from '@generic/components/FuelInsight';
import fuelIcon from '@generic/assets/images/icon-fuel.svg';
import plugIcon from '@generic/assets/images/icon-plug.svg';
import {makeStyles} from '@material-ui/core/styles';
import calcAnnualVehicleElectricityCosts from '@generic/functions/calcAnnualVehicleElectricityCosts';
import calcAnnualFossilFuelCost from '@generic/functions/calcAnnualFossilFuelCosts';
import {formatAsDollarsRounded, formatAsCents} from '@generic/functions/formatters';
import calcPerMileElectricityCosts from '@generic/functions/calcPerMileElectricityCosts';
import calcPerMileFossilFuelCosts from '@generic/functions/calcPerMileFossilFuelCosts';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  smallScreenInsights: {
    display: 'block',
    maxWidth: 400,
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  innerGrid: {
    flexWrap: 'nowrap',
  },
  divider: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function ChargingFuelInsights() {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const inputs = useInputs();

  const {ev: annualElectricityCosts} = calcAnnualVehicleElectricityCosts(inputs);
  const annualFossilFuelCosts = calcAnnualFossilFuelCost(inputs);
  const annualFuelSavings = annualFossilFuelCosts - annualElectricityCosts;

  const perMileElectricityCosts = calcPerMileElectricityCosts(inputs);
  const perMileFossilFuelCosts = calcPerMileFossilFuelCosts(inputs);
  const perMileSavings = perMileFossilFuelCosts - perMileElectricityCosts;

  return (
    <>
      <Grid container justifyContent="center" className={classes.root}>
        <Grid
          container
          item
          md={12}
          lg={10}
          xl={8}
          justifyContent="space-between"
          className={classes.innerGrid}
        >
          <Box display="flex">
            <FuelInsight
              label={formatMessage({id: 'charging.insights.savings.annualFuel'})}
              value={formatAsDollarsRounded(annualFuelSavings)}
              icon={fuelIcon}
            />
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <FuelInsight
              label={formatMessage({id: 'charging.insights.savings.perMile'})}
              value={formatAsCents(perMileSavings)}
            />
          </Box>

          <Box display="flex">
            <FuelInsight
              label={formatMessage({id: 'charging.insights.costs.evFuel'})}
              value={formatAsDollarsRounded(annualElectricityCosts)}
              icon={plugIcon}
            />
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <FuelInsight
              label={formatMessage({id: 'charging.insights.costs.fossilFuel'})}
              value={formatAsDollarsRounded(annualFossilFuelCosts)}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Visible on small screens only */}
      <Box className={classes.smallScreenInsights}>
        <FuelInsight
          label={formatMessage({id: 'charging.insights.savings.annualFuel'})}
          value={formatAsDollarsRounded(annualFuelSavings)}
        />
        <FuelInsight
          label={formatMessage({id: 'charging.insights.savings.perMile'})}
          value={formatAsCents(perMileSavings)}
        />
        <FuelInsight
          label={formatMessage({id: 'charging.insights.costs.evFuel'})}
          value={formatAsDollarsRounded(annualElectricityCosts)}
        />
        <FuelInsight
          label={formatMessage({id: 'charging.insights.costs.fossilFuel'})}
          value={formatAsDollarsRounded(annualFossilFuelCosts)}
        />
      </Box>
    </>
  );
}
