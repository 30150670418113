import calcMilesPerYear from '@generic/functions/calcMilesPerYear';
import sum from 'lodash/sum';
import {FOSSIL_FUEL_MAINTENANCE_COST_FACTOR} from '@generic/data/assumptions';

const calcAnnualFossilFuelMaintenanceCosts = ({vehicleSets}) =>
  sum(
    vehicleSets.map(
      (set) =>
        calcMilesPerYear(set) *
        set.vehicle.fossilMaintenanceCostPerMile *
        FOSSIL_FUEL_MAINTENANCE_COST_FACTOR
    )
  );

export default calcAnnualFossilFuelMaintenanceCosts;
