import {Grid, Box, Typography, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {formatVehicleSetTitle} from '@generic/functions/formatters';
import VEHICLES from '@generic/data/vehicles';

const useStyles = makeStyles((theme) => {
  const {
    vehicleSetDialog: {overview},
  } = theme;

  return {
    img: {
      maxHeight: 100,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        maxHeight: 'none',
      },
    },
    imgContainer: {
      textAlign: 'center',
    },
    title: {...overview.title},
    categoryLabel: {
      fontWeight: '700',
      fontSize: '0.875rem',
    },
    categoryValue: {
      fontSize: '0.875rem',
    },
  };
});

export default function EditableVehicleSetOverview({
  vehicleSet: {vehicle_id: vehicleId, vehicleCount},
}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const vehicle = VEHICLES.find((v) => v.id === vehicleId);
  const {batteryCapacityInKwh, fossilMilesPerGallon, fossilFuelType, imageUrl, rangeInMiles} =
    vehicle;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
      <Grid item xs={12} sm={4}>
        <Box className={classes.imgContainer}>
          <img className={classes.img} src={imageUrl} alt="" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography className={classes.title}>
          {formatVehicleSetTitle({vehicleCount, vehicle})}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.categoryLabel}>
              {formatMessage({id: 'vehicleSetDialog.overview.range.label'})}
            </Typography>
            <Typography className={classes.categoryValue}>
              {formatMessage({id: 'vehicleSetDialog.overview.range.value'}, {range: rangeInMiles})}
            </Typography>
          </Grid>
          <Grid item xs={12} sm>
            <Typography className={classes.categoryLabel}>
              {formatMessage({id: 'vehicleSetDialog.overview.equivGasMpg.label'})}
            </Typography>
            <Typography className={classes.categoryValue}>
              {formatMessage(
                {id: 'vehicleSetDialog.overview.equivGasMpg.value'},
                {equivGasMpg: fossilMilesPerGallon, equivGasType: fossilFuelType}
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.categoryLabel}>
              {formatMessage({id: 'vehicleSetDialog.overview.batteryCapacity.label'})}
            </Typography>
            <Typography className={classes.categoryValue}>
              {formatMessage(
                {id: 'vehicleSetDialog.overview.batteryCapacity.value'},
                {batteryCapacity: batteryCapacityInKwh}
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm>
            <Typography className={classes.categoryLabel}>
              {formatMessage({id: 'vehicleSetDialog.overview.workdays.label'})}
            </Typography>
            <Typography className={classes.categoryValue}>
              {formatMessage(
                {id: 'vehicleSetDialog.overview.workdays.value'},
                {workdays: 'Weekdays'}
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
