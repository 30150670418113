import React from 'react';
import {
  Box,
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import IconCircleQuestion from '@generic/assets/images/IconCircleQuestion';

const useStyles = makeStyles((theme) => ({
  title: (props) => ({
    fontWeight: props.variant === 'gray' ? 600 : 700,
    textTransform: props.variant === 'gray' ? 'none' : 'uppercase',
    color: props.variant === 'gray' ? theme.palette.gray[600] : theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  }),
  select: {
    fontSize: '22px',
    marginRight: '8px',
    fontWeight: 600,
  },
  fullWidth: {
    width: '100%',
  },
  inlineBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltipTrigger: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function DropdownInput({
  id,
  title,
  tooltip,
  value,
  onChange,
  options,
  fullWidth = false,
  selectProps = {},
  variant = '',
  ...rest
}) {
  const classes = useStyles({variant});

  return (
    <Box className={fullWidth ? '' : classes.inlineBox} {...rest}>
      {!!title && (
        <Typography variant="body2" className={classes.title}>
          <label htmlFor={id || title}>{title}</label>
          {tooltip && (
            <Tooltip title={tooltip} arrow placement="right">
              <span className={classes.tooltipTrigger}>
                <IconCircleQuestion />
              </span>
            </Tooltip>
          )}
        </Typography>
      )}

      <FormControl className={fullWidth ? classes.fullWidth : ''}>
        <Select
          className={classes.select}
          value={value}
          onChange={onChange}
          {...selectProps}
          inputProps={{id: id || title}}
        >
          {options.map(({value, children}) => (
            <MenuItem key={value} value={value}>
              {children}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
