import {Box} from '@material-ui/core';
import React from 'react';
import {Pie} from 'react-chartjs-2';
import {useIntl} from 'react-intl';

export default function RateComponentCategoryChart({rateCalculator, onHover, sccRebate}) {
  const {formatMessage} = useIntl();

  const categories = ['fixed', 'demand', 'energy'];
  const categoryCosts = categories.reduce((costsDict, category) => {
    const newCostsDict = {...costsDict};
    const categoryCost =
      rateCalculator.annualCost({classifications: [category]}) - (sccRebate[category] || 0);
    newCostsDict[category] = categoryCost;
    return newCostsDict;
  }, {});

  const overallCost = rateCalculator.annualCost();
  const categoryPercentages = categories.map((category) => {
    const categoryCost = categoryCosts[category];
    return ((categoryCost / overallCost) * 100).toFixed();
  });

  const handleHover = (_, item) => {
    if (item.length) {
      /* eslint-disable no-underscore-dangle */
      const dataIdx = item[0]._index;
      const dataCategory = item[0]._chart.data.datasets[0].ids[dataIdx];
      /* eslint-enable no-underscore-dangle */
      onHover(dataCategory);
    }
  };

  const data = {
    labels: [
      formatMessage({id: 'charging.content.costs.electricity.rateComponents.fixed.title'}),
      formatMessage({id: 'charging.content.costs.electricity.rateComponents.demand.title'}),
      formatMessage({id: 'charging.content.costs.electricity.rateComponents.energy.title'}),
    ],
    datasets: [
      {
        ids: categories,
        data: categoryPercentages,
        backgroundColor: ['#636363', '#004557', '#89cdaa'],
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (data, context) =>
          `${context.labels[data.index]}: ${context.datasets[0].data[data.index]}%`,
      },
    },
    hover: {
      onHover: handleHover,
    },
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Pie data={data} options={options} />
    </Box>
  );
}
