import {Grid, Box, Divider} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import FuelInsight from '@generic/components/FuelInsight';
import {useIntl} from 'react-intl';
import plugIcon from '@generic/assets/images/icon-plug.svg';
import evIcon from '@generic/assets/images/icon-ev.svg';
import {useInputs} from '@bellawatt/use-inputs';
import calcAnnualChargerElectricityCosts from '@generic/functions/calcAnnualChargerElectricityCosts';
import calcTotalChargers from '@generic/functions/calcTotalChargers';
import calcTotalChargerPorts from '@generic/functions/calcTotalChargerPorts';
import {formatAsDollarsRounded} from '@generic/functions/formatters';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  smallScreenInsights: {
    display: 'block',
    maxWidth: 400,
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  innerGrid: {
    flexWrap: 'nowrap',
  },
  divider: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function PlanningInsights() {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const inputs = useInputs();

  const {chargers: annualElectricityCosts} = calcAnnualChargerElectricityCosts(inputs);
  const totalChargers = calcTotalChargers(inputs);
  const totalChargerPorts = calcTotalChargerPorts(inputs);

  return (
    <>
      <Grid container justifyContent="center" className={classes.root}>
        <Grid
          container
          item
          md={10}
          lg={8}
          xl={6}
          justifyContent="space-between"
          className={classes.innerGrid}
        >
          <FuelInsight
            label={formatMessage({id: 'charging.insights.costs.evFuel'})}
            value={formatAsDollarsRounded(annualElectricityCosts)}
            icon={evIcon}
          />
          <Box display="flex">
            <FuelInsight
              label={formatMessage({id: 'charging.insights.count.chargers'})}
              value={totalChargers}
              icon={plugIcon}
            />
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <FuelInsight
              label={formatMessage({id: 'charging.insights.count.ports'})}
              value={totalChargerPorts}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Visible on small screens only */}
      <Box className={classes.smallScreenInsights}>
        <FuelInsight
          label={formatMessage({id: 'charging.insights.costs.evFuel'})}
          value={formatAsDollarsRounded(annualElectricityCosts)}
        />
        <FuelInsight
          label={formatMessage({id: 'charging.insights.count.chargers'})}
          value={totalChargers}
        />
        <FuelInsight
          label={formatMessage({id: 'charging.insights.count.ports'})}
          value={totalChargerPorts}
        />
      </Box>
    </>
  );
}
