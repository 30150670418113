const translations = {
  en: {
    translation: {
      header: {
        name: null,
      },
      emailAnchorLink:
        'mailto:EVMRP@coned.com?subject=Interest in discussing Con Edison EV charging rate calculator&body=Hello, I am using the EV charging calculator and am interested in learning more about EV charging rates at Con Edison. Can someone please contact me?%0D%0A%0D%0AThe configuration I am using is:%0D%0A%0D%0A{inputs}%0D%0A%0D%0AThank you!',
      home: {
        header: 'Plan Your EV Charging Costs With Con Edison',
        subheader: "Electric vehicles are the future. Let's plan for it, together.",
        features: {
          header: 'Everything You Need to Get Started',
          incentives: {
            title: 'Incentives',
            text: 'Save even more with rebates and incentives.',
          },
          rates: {
            title: 'Rate Comparison',
            text: 'Identify the right Con Edison rate for your project.',
          },
          savings: {
            title: 'Fuel Savings',
            text: 'Calculate the savings of electrification.',
          },
          analysis: {
            title: 'Energy Bill Analysis',
            text: 'Evaluate how your charging project will affect your Con Edison bill.',
          },
        },
        impact: {
          header: 'Electrification can make the biggest impact.',
          subheader:
            'Transitioning fleets to electric accelerates progress in the fight against climate change.',
          numberOfFleets: 'There are almost 1 million fleet vehicles in America',
          fleetPercentage: '17% of new vehicles purchased are for fleets',
        },
        cta: "Let's make a plan, together.",
      },
      footer: {
        helpText: "Want to learn more? We're here to help.",
        contactLink: 'Email Us',
        links: {
          site: {
            header: 'Con Edison',
            mainSite: 'Con Edison of New York',
          },
          social: {
            facebookLink: 'https://www.facebook.com/ConEdison',
            twitterLink: 'https://twitter.com/conedison',
            youtubeLink: 'https://www.youtube.com/user/ConEdisonNY',
            linkedInLink: 'https://www.linkedin.com/company/con-edison/',
          },
        },
        secondary: {
          links: {
            privacyPolicyLink: 'https://www.coned.com/conedison-privacy-statement',
            accessibilityPolicyLink: 'https://www.conedison.com/accessibility-policy',
            termsOfServiceLink: 'https://www.coned.com/terms-and-conditions',
            homeNetLink: 'https://cevo.coned.com/',
          },
          copyright:
            '© 2016 - 2020 Consolidated Edison Company of New York, Inc. All Rights Reserved.',
        },
        emailAnchorLink:
          'mailto:EVMRP@coned.com?subject=Interest in discussing Con Edison EV charging rate calculator&body=Hello, I am using the EV charging calculator and am interested in learning more about EV charging rates at Con Edison. Can someone please contact me?%0D%0A%0D%0AThe configuration I am using is:%0D%0A%0D%0A{inputs}%0D%0A%0D%0AThank you!',
      },
      wizard: {
        charging: {
          vehicleTypes: {
            subheader: 'Please select a representative vehicle.',
          },
        },
      },
      seo: {
        title: 'Con Edison Charging Calculator',
        description:
          "Plan Your EV Charging Costs With Con Edison. Electric vehicles are the future. Let's plan for it, together.",
      },
      businessIncentiveRate: {
        link: 'https://www.coned.com/en/our-energy-future/technology-innovation/electric-vehicles/building-businesses-and-parking-lots',
      },
      electricRateExplainer: {
        details:
          'We use the information you enter about your charger type(s) and location to estimate your annual electric charges. The calculation includes supply charges and certain fees, assuming Con Edison supplies electricity to you and assuming prices from the 2019 calendar year. If you get your electricity from a different supplier, these total bill estimates may not apply. You may choose to separately meter your EV charging load or combine it with your building load. If your EV charging load is combined with the building load, we estimate your building load shape from the 2021 NREL Load Shape Study to estimate your pre-existing typical monthly bill. Additional taxes such as sales tax are not included here, and would be added to your actual bill.',
      },
      inputs: {
        advanced: {
          publiclyAvailable: {
            title: 'Publicly Available',
            tooltip:
              'Will your charging station be accessible to the public? If yes, additional incentives may be available to you.',
          },
          zone: {
            title: 'Zone',
            tooltip: 'Supply costs vary by New York Control Area load zones.',
            h: 'H',
            i: 'I',
            j: 'J',
          },
          siteAdditionalQualifications: {
            title: 'Additional Site Characteristics',
            tooltip:
              'You may be eligible for additional incentives if you are a transit agency or your chargers are publicly accessible during operating hours.',
          },
          zipcode: {
            title: 'Zip Code',
            tooltip:
              'The ZIP code will be used to estimate the New York Control Area Zone and cost calculations.',
          },
        },
        siteAdditionalQualificationOptions: {
          transitAgency: 'Transit Agency',
          none: 'None',
          publiclyAvailable: 'Publicly Accessible',
        },
      },
      smartChargeCommercial: {
        title: 'SmartCharge Commercial',
        description:
          'You may be eligible for the SmartCharge Commercial program if you own/operate the chargers on your site. Your incentive payments will depend on your charger count and charging behavior. You may be eligible for additional incentives if you are a transit agency, fleet customer, or your chargers are publicly accessible.',
        enroll: 'Enroll in SCC',
        enrollUrl:
          'https://www.coned.com/en/our-energy-future/electric-vehicles/commercial-electric-vehicle-charging-station-rewards',
        chartSubtitle: {
          text: 'You may be eligible for additional incentives with the SmartCharge Commercial program. Visit our {link} page for more information.',
          href: 'https://www.coned.com/en/our-energy-future/electric-vehicles/best-electric-delivery-rate-for-your-charging-station',
          linkTrigger: 'SCC',
        },
        graphLabel: 'SCC Incentive',
      },
      charging: {
        content: {
          overview: {
            subtitle:
              'Compare the cost of charging your electric fleet with the cost of fueling equivalent gas vehicles.',
            yearlyCostsHeader: 'Yearly Fuel Costs',
            yearlyCosts:
              'Based on your selection, your electric fleet will cost {costDifference} {moreOrLess} per year to fuel than its fossil fuel equivalent. You may be able to reduce your costs by participating in SmartCharge Commercial – see the Costs tab for incentive information.',
            chart: {
              electricFleet: 'Electric Vehicle Fleet',
              yourSelection: '(Your selection)',
              fossilFuelFleet: 'Gasoline Fleet',
              similarVehicle: '(Similar gas vehicle)',
              facility: 'Facility',
              vehicles: 'Vehicles',
              total: 'Total',
            },
            fleetSummary: {
              electricHeader: 'Electric Fleet',
              fossilFuelHeader: 'Equivalent Fossil Fuel Fleet',
              fuelCost: 'Fuel Cost',
              maintenanceCost: 'Maintenance Cost',
              annualElectricCost: 'Annual Electricity Cost',
              electricPerMile: 'Electricity Cost Per Mile',
              annualFossilFuelCost: 'Annual Fossil Fuel Cost',
              fossilFuelPerMile: 'Fossil Fuel Cost Per Mile',
              annualMaintenanceCost: 'Total Annual Maintenance Cost',
              maintenancePerMile: 'Maintenance Cost Per Mile',
            },
          },
          costs: {
            electricity: {
              bill: {
                subheader: 'Your estimated average monthly bill, not inclusive of SCC incentives',
              },
              rateComponents: {
                sccRebateDiscount: {
                  description:
                    'This includes a monthly incentive of {sccRebate} for the SmartCharge Commercial program.',
                },
              },
            },
          },
        },
      },
      demandChargeRebate: {
        disclaimer:
          'Because your DCFCs are publicly available, you are eligible for an average monthly Demand Charge Rebate of <bold>{amount}</bold>. This would be a <bold>{percent}%</bold> rebate applied to the Demand Delivery portion of your bill.', // eslint-disable-line no-template-curly-in-string,
        seeMore: 'Learn More About {link}.',
        linkTrigger: 'Electric Delivery Rates',
        link: 'https://www.coned.com/en/our-energy-future/electric-vehicles/best-electric-delivery-rate-for-your-charging-station',
      },
      errorBoundary: {
        header: 'Something went wrong.',
        body: 'Our team has been notified. Occasionally, this is due to conflicts in certain combinations of inputs. If you would like, you can reset your inputs using the button below.',
        resetButton: 'Reset Inputs',
      },
    },
  },
};

export default translations;
