import calcGallonsOfFossilFuelForVehicleSet from '@generic/functions/calcGallonsOfFossilFuelForVehicleSet';

const calcFossilFuelCostForVehicleSet = ({vehicleSet, dieselPrice, gasolinePrice, lpgPrice}) => {
  const {
    vehicle: {fossilFuelType},
  } = vehicleSet;

  const gallons = calcGallonsOfFossilFuelForVehicleSet(vehicleSet);

  const priceDictionary = {
    diesel: dieselPrice,
    gasoline: gasolinePrice,
    lpg: lpgPrice,
  };
  const price = priceDictionary[fossilFuelType];

  return price * gallons;
};

export default calcFossilFuelCostForVehicleSet;
