import times from 'lodash/times';
import isHourInUse from '@generic/functions/isHourInUse';

const groupVehicleSetsByChargers = (vehicleSets) => {
  const chargingSets = {};
  vehicleSets.forEach(({chargingWindows, ...vehicle}) => {
    chargingWindows.forEach((chargingWindow) => {
      const vehicleChargingUsage = {
        ...vehicle,
        usage: times(24, (hour) =>
          isHourInUse(chargingWindow, hour) ? parseInt(vehicle.vehicleCount, 10) : 0
        ),
        chargingWindow,
      };
      chargingSets[chargingWindow.type] = {
        ...chargingWindow,
        ...chargingWindow.charger,
        vehicles: chargingSets[chargingWindow.type]
          ? [...chargingSets[chargingWindow.type].vehicles, vehicleChargingUsage]
          : [vehicleChargingUsage],
      };
    });
  });

  Object.keys(chargingSets).forEach((key) => {
    const summedHours = chargingSets[key].vehicles.reduce(
      (sum, {usage}) => sum.map((val, i) => val + usage[i]),
      times(24, () => 0)
    );

    chargingSets[key].minimumRequired = Math.ceil(
      Math.max(...summedHours) / chargingSets[key].ports
    );
  });

  return Object.values(chargingSets);
};

export default groupVehicleSetsByChargers;
