const standaloneRetailLoadProfile = [
  4.17, 4.17, 4.25, 4.35, 4.49, 4.89, 5.59, 6.67, 7.95, 8.46, 8.61, 8.71, 8.78, 8.81, 8.68, 8.41,
  8.32, 8.25, 7.92, 7.68, 7.37, 6.58, 4.99, 4.14, 3.96, 3.94, 4.02, 4.14, 4.27, 4.61, 5.35, 6.37,
  7.68, 8.32, 8.5, 8.61, 8.69, 8.71, 8.6, 8.36, 8.28, 8.24, 7.93, 7.68, 7.38, 6.65, 5.09, 4.17, 4.0,
  3.96, 4.03, 4.15, 4.31, 4.73, 5.46, 6.59, 7.85, 8.35, 8.41, 8.39, 8.39, 8.38, 8.35, 8.21, 8.27,
  8.29, 8.04, 7.87, 7.62, 6.83, 5.2, 4.29, 4.1, 4.06, 4.18, 4.27, 4.39, 4.78, 5.5, 6.62, 7.88, 8.34,
  8.47, 8.57, 8.6, 8.59, 8.48, 8.22, 8.17, 8.09, 7.77, 7.54, 7.25, 6.43, 4.83, 3.95, 4.03, 3.82,
  3.74, 3.83, 4.16, 4.38, 4.93, 5.55, 7.03, 7.62, 7.87, 7.98, 7.97, 8.03, 8.15, 8.31, 8.64, 9.15,
  8.99, 8.86, 8.5, 7.44, 5.62, 4.35, 3.74, 3.67, 3.64, 3.68, 3.97, 4.59, 5.15, 5.3, 6.02, 6.96,
  7.42, 7.78, 8.02, 8.08, 8.12, 8.02, 8.17, 8.41, 7.96, 7.35, 6.66, 5.85, 5.12, 4.37, 3.68, 3.66,
  3.72, 3.82, 3.95, 4.29, 5.04, 6.14, 7.5, 8.12, 8.34, 8.49, 8.58, 8.61, 8.52, 8.28, 8.19, 8.18,
  7.9, 7.66, 7.39, 6.68, 5.18, 4.34, 4.17, 4.17, 4.25, 4.35, 4.49, 4.89, 5.59, 6.67, 7.95, 8.46,
  8.61, 8.71, 8.78, 8.81, 8.68, 8.41, 8.32, 8.25, 7.92, 7.68, 7.37, 6.58, 4.99, 4.14, 3.96, 3.94,
  4.02, 4.14, 4.27, 4.61, 5.35, 6.37, 7.68, 8.32, 8.5, 8.61, 8.69, 8.71, 8.6, 8.36, 8.28, 8.24,
  7.93, 7.68, 7.38, 6.65, 5.09, 4.17, 4.0, 3.96, 4.03, 4.15, 4.31, 4.73, 5.46, 6.59, 7.85, 8.35,
  8.41, 8.39, 8.39, 8.38, 8.35, 8.21, 8.27, 8.29, 8.04, 7.87, 7.62, 6.83, 5.2, 4.29, 4.1, 4.06,
  4.18, 4.27, 4.39, 4.78, 5.5, 6.62, 7.88, 8.34, 8.47, 8.57, 8.6, 8.59, 8.48, 8.22, 8.17, 8.09,
  7.77, 7.54, 7.25, 6.43, 4.83, 3.95, 4.03, 3.82, 3.74, 3.83, 4.16, 4.38, 4.93, 5.55, 7.03, 7.62,
  7.87, 7.98, 7.97, 8.03, 8.15, 8.31, 8.64, 9.15, 8.99, 8.86, 8.5, 7.44, 5.62, 4.35, 3.74, 3.67,
  3.64, 3.68, 3.97, 4.59, 5.15, 5.3, 6.02, 6.96, 7.42, 7.78, 8.02, 8.08, 8.12, 8.02, 8.17, 8.41,
  7.96, 7.35, 6.66, 5.85, 5.12, 4.37, 3.68, 3.66, 3.72, 3.82, 3.95, 4.29, 5.04, 6.14, 7.5, 8.12,
  8.34, 8.49, 8.58, 8.61, 8.52, 8.28, 8.19, 8.18, 7.9, 7.66, 7.39, 6.68, 5.18, 4.34, 4.17, 4.17,
  4.25, 4.35, 4.49, 4.89, 5.59, 6.67, 7.95, 8.46, 8.61, 8.71, 8.78, 8.81, 8.68, 8.41, 8.32, 8.25,
  7.92, 7.68, 7.37, 6.58, 4.99, 4.14, 3.96, 3.94, 4.02, 4.14, 4.27, 4.61, 5.35, 6.37, 7.68, 8.32,
  8.5, 8.61, 8.69, 8.71, 8.6, 8.36, 8.28, 8.24, 7.93, 7.68, 7.38, 6.65, 5.09, 4.17, 4.0, 3.96, 4.03,
  4.15, 4.31, 4.73, 5.46, 6.59, 7.85, 8.35, 8.41, 8.39, 8.39, 8.38, 8.35, 8.21, 8.27, 8.29, 8.04,
  7.87, 7.62, 6.83, 5.2, 4.29, 4.1, 4.06, 4.18, 4.27, 4.39, 4.78, 5.5, 6.62, 7.88, 8.34, 8.47, 8.57,
  8.6, 8.59, 8.48, 8.22, 8.17, 8.09, 7.77, 7.54, 7.25, 6.43, 4.83, 3.95, 4.03, 3.82, 3.74, 3.83,
  4.16, 4.38, 4.93, 5.55, 7.03, 7.62, 7.87, 7.98, 7.97, 8.03, 8.15, 8.31, 8.64, 9.15, 8.99, 8.86,
  8.5, 7.44, 5.62, 4.35, 3.74, 3.67, 3.64, 3.68, 3.97, 4.59, 5.15, 5.3, 6.02, 6.96, 7.42, 7.78,
  8.02, 8.08, 8.12, 8.02, 8.17, 8.41, 7.96, 7.35, 6.66, 5.85, 5.12, 4.37, 3.68, 3.66, 3.72, 3.82,
  3.95, 4.29, 5.04, 6.14, 7.5, 8.12, 8.34, 8.49, 8.58, 8.61, 8.52, 8.28, 8.19, 8.18, 7.9, 7.66,
  7.39, 6.68, 5.18, 4.34, 4.17, 4.17, 4.25, 4.35, 4.49, 4.89, 5.59, 6.67, 7.95, 8.46, 8.61, 8.71,
  8.78, 8.81, 8.68, 8.41, 8.32, 8.25, 7.92, 7.68, 7.37, 6.58, 4.99, 4.14, 3.96, 3.94, 4.02, 4.14,
  4.27, 4.61, 5.35, 6.37, 7.68, 8.32, 8.5, 8.61, 8.69, 8.71, 8.6, 8.36, 8.28, 8.24, 7.93, 7.68,
  7.38, 6.65, 5.09, 4.17, 4.0, 3.96, 4.03, 4.15, 4.31, 4.73, 5.46, 6.59, 7.85, 8.35, 8.41, 8.39,
  8.39, 8.38, 8.35, 8.21, 8.27, 8.29, 8.04, 7.87, 7.62, 6.83, 5.2, 4.29, 4.1, 4.06, 4.18, 4.27,
  4.39, 4.78, 5.5, 6.62, 7.88, 8.34, 8.47, 8.57, 8.6, 8.59, 8.48, 8.22, 8.17, 8.09, 7.77, 7.54,
  7.25, 6.43, 4.83, 3.95, 4.03, 3.82, 3.74, 3.83, 4.16, 4.38, 4.93, 5.55, 7.03, 7.62, 7.87, 7.98,
  7.97, 8.03, 8.15, 8.31, 8.64, 9.15, 8.99, 8.86, 8.5, 7.44, 5.62, 4.35, 3.74, 3.67, 3.64, 3.68,
  3.97, 4.59, 5.15, 5.3, 6.02, 6.96, 7.42, 7.78, 8.02, 8.08, 8.12, 8.02, 8.17, 8.41, 7.96, 7.35,
  6.66, 5.85, 5.12, 4.37, 3.68, 3.66, 3.72, 3.82, 3.95, 4.29, 5.04, 6.14, 7.5, 8.12, 8.34, 8.49,
  8.58, 8.61, 8.52, 8.28, 8.19, 8.18, 7.9, 7.66, 7.39, 6.68, 5.18, 4.34, 4.17, 4.17, 4.25, 4.35,
  4.49, 4.89, 5.59, 6.67, 7.95, 8.46, 8.61, 8.71, 8.78, 8.81, 8.68, 8.41, 8.32, 8.25, 7.92, 7.68,
  7.37, 6.58, 4.99, 4.14, 3.96, 3.94, 4.02, 4.14, 4.27, 4.61, 5.35, 6.37, 7.68, 8.32, 8.5, 8.61,
  8.69, 8.71, 8.6, 8.36, 8.28, 8.24, 7.93, 7.68, 7.38, 6.65, 5.09, 4.17, 4.0, 3.96, 4.03, 4.15,
  4.31, 4.73, 5.46, 6.59, 7.85, 8.35, 8.41, 8.39, 8.39, 8.38, 8.35, 8.21, 8.27, 8.29, 8.04, 7.87,
  7.62, 6.83, 5.2, 4.29, 3.38, 3.34, 3.41, 3.49, 3.6, 3.86, 4.59, 5.68, 7.21, 7.83, 8.05, 8.23,
  8.37, 8.45, 8.29, 8.02, 7.71, 7.7, 7.54, 7.29, 6.96, 6.16, 4.55, 3.66, 3.75, 3.57, 3.45, 3.53,
  3.94, 4.17, 4.66, 5.21, 6.86, 7.47, 7.73, 7.88, 7.9, 7.96, 8.07, 8.23, 8.37, 8.92, 8.93, 8.8,
  8.44, 7.39, 5.59, 4.34, 3.75, 3.67, 3.63, 3.7, 3.97, 4.56, 5.0, 5.15, 5.96, 6.9, 7.36, 7.72, 7.97,
  8.05, 8.06, 7.96, 7.94, 8.17, 7.9, 7.3, 6.6, 5.8, 5.1, 4.37, 3.74, 3.76, 3.87, 3.97, 4.1, 4.49,
  5.06, 6.15, 7.58, 8.12, 8.3, 8.42, 8.49, 8.51, 8.39, 8.14, 7.83, 7.81, 7.67, 7.42, 7.12, 6.35,
  4.82, 3.99, 3.76, 3.73, 3.85, 3.94, 4.11, 4.46, 5.06, 6.16, 7.64, 8.21, 8.36, 8.49, 8.56, 8.6,
  8.51, 8.24, 7.96, 7.94, 7.88, 7.66, 7.34, 6.55, 5.0, 4.13, 3.93, 3.9, 3.99, 4.12, 4.23, 4.54,
  5.13, 6.18, 7.61, 8.19, 8.38, 8.51, 8.6, 8.6, 8.48, 8.21, 7.9, 7.84, 7.77, 7.53, 7.25, 6.52, 4.94,
  4.06, 3.73, 3.67, 3.72, 3.77, 3.85, 4.15, 4.81, 5.83, 7.27, 7.76, 7.87, 7.9, 7.97, 8.01, 8.0,
  7.84, 7.6, 7.67, 7.59, 7.38, 7.08, 6.26, 4.57, 3.59, 3.38, 3.34, 3.41, 3.49, 3.6, 3.86, 4.59,
  5.68, 7.21, 7.83, 8.05, 8.23, 8.37, 8.45, 8.29, 8.02, 7.71, 7.7, 7.54, 7.29, 6.96, 6.16, 4.55,
  3.66, 3.75, 3.57, 3.45, 3.53, 3.94, 4.17, 4.66, 5.21, 6.86, 7.47, 7.73, 7.88, 7.9, 7.96, 8.07,
  8.23, 8.37, 8.92, 8.93, 8.8, 8.44, 7.39, 5.59, 4.34, 3.75, 3.67, 3.63, 3.7, 3.97, 4.56, 5.0, 5.15,
  5.96, 6.9, 7.36, 7.72, 7.97, 8.05, 8.06, 7.96, 7.94, 8.17, 7.9, 7.3, 6.6, 5.8, 5.1, 4.37, 3.74,
  3.76, 3.87, 3.97, 4.1, 4.49, 5.06, 6.15, 7.58, 8.12, 8.3, 8.42, 8.49, 8.51, 8.39, 8.14, 7.83,
  7.81, 7.67, 7.42, 7.12, 6.35, 4.82, 3.99, 3.76, 3.73, 3.85, 3.94, 4.11, 4.46, 5.06, 6.16, 7.64,
  8.21, 8.36, 8.49, 8.56, 8.6, 8.51, 8.24, 7.96, 7.94, 7.88, 7.66, 7.34, 6.55, 5.0, 4.13, 3.93, 3.9,
  3.99, 4.12, 4.23, 4.54, 5.13, 6.18, 7.61, 8.19, 8.38, 8.51, 8.6, 8.6, 8.48, 8.21, 7.9, 7.84, 7.77,
  7.53, 7.25, 6.52, 4.94, 4.06, 3.73, 3.67, 3.72, 3.77, 3.85, 4.15, 4.81, 5.83, 7.27, 7.76, 7.87,
  7.9, 7.97, 8.01, 8.0, 7.84, 7.6, 7.67, 7.59, 7.38, 7.08, 6.26, 4.57, 3.59, 3.38, 3.34, 3.41, 3.49,
  3.6, 3.86, 4.59, 5.68, 7.21, 7.83, 8.05, 8.23, 8.37, 8.45, 8.29, 8.02, 7.71, 7.7, 7.54, 7.29,
  6.96, 6.16, 4.55, 3.66, 3.75, 3.57, 3.45, 3.53, 3.94, 4.17, 4.66, 5.21, 6.86, 7.47, 7.73, 7.88,
  7.9, 7.96, 8.07, 8.23, 8.37, 8.92, 8.93, 8.8, 8.44, 7.39, 5.59, 4.34, 3.75, 3.67, 3.63, 3.7, 3.97,
  4.56, 5.0, 5.15, 5.96, 6.9, 7.36, 7.72, 7.97, 8.05, 8.06, 7.96, 7.94, 8.17, 7.9, 7.3, 6.6, 5.8,
  5.1, 4.37, 3.74, 3.76, 3.87, 3.97, 4.1, 4.49, 5.06, 6.15, 7.58, 8.12, 8.3, 8.42, 8.49, 8.51, 8.39,
  8.14, 7.83, 7.81, 7.67, 7.42, 7.12, 6.35, 4.82, 3.99, 3.76, 3.73, 3.85, 3.94, 4.11, 4.46, 5.06,
  6.16, 7.64, 8.21, 8.36, 8.49, 8.56, 8.6, 8.51, 8.24, 7.96, 7.94, 7.88, 7.66, 7.34, 6.55, 5.0,
  4.13, 3.93, 3.9, 3.99, 4.12, 4.23, 4.54, 5.13, 6.18, 7.61, 8.19, 8.38, 8.51, 8.6, 8.6, 8.48, 8.21,
  7.9, 7.84, 7.77, 7.53, 7.25, 6.52, 4.94, 4.06, 3.73, 3.67, 3.72, 3.77, 3.85, 4.15, 4.81, 5.83,
  7.27, 7.76, 7.87, 7.9, 7.97, 8.01, 8.0, 7.84, 7.6, 7.67, 7.59, 7.38, 7.08, 6.26, 4.57, 3.59, 3.38,
  3.34, 3.41, 3.49, 3.6, 3.86, 4.59, 5.68, 7.21, 7.83, 8.05, 8.23, 8.37, 8.45, 8.29, 8.02, 7.71,
  7.7, 7.54, 7.29, 6.96, 6.16, 4.55, 3.66, 3.75, 3.57, 3.45, 3.53, 3.94, 4.17, 4.66, 5.21, 6.86,
  7.47, 7.73, 7.88, 7.9, 7.96, 8.07, 8.23, 8.37, 8.92, 8.93, 8.8, 8.44, 7.39, 5.59, 4.34, 3.75,
  3.67, 3.63, 3.7, 3.97, 4.56, 5.0, 5.15, 5.96, 6.9, 7.36, 7.72, 7.97, 8.05, 8.06, 7.96, 7.94, 8.17,
  7.9, 7.3, 6.6, 5.8, 5.1, 4.37, 3.74, 3.76, 3.87, 3.97, 4.1, 4.49, 5.06, 6.15, 7.58, 8.12, 8.3,
  8.42, 8.49, 8.51, 8.39, 8.14, 7.83, 7.81, 7.67, 7.42, 7.12, 6.35, 4.82, 3.99, 3.76, 3.73, 3.85,
  3.94, 4.11, 4.46, 5.06, 6.16, 7.64, 8.21, 8.36, 8.49, 8.56, 8.6, 8.51, 8.24, 7.96, 7.94, 7.88,
  7.66, 7.34, 6.55, 5.0, 4.13, 3.93, 3.9, 3.99, 4.12, 4.23, 4.54, 5.13, 6.18, 7.61, 8.19, 8.38,
  8.51, 8.6, 8.6, 8.48, 8.21, 7.9, 7.84, 7.77, 7.53, 7.25, 6.52, 4.94, 4.06, 3.73, 3.67, 3.72, 3.77,
  3.85, 4.15, 4.81, 5.83, 7.27, 7.76, 7.87, 7.9, 7.97, 8.01, 8.0, 7.84, 7.6, 7.67, 7.59, 7.38, 7.08,
  6.26, 4.57, 3.59, 3.25, 3.29, 3.37, 3.48, 3.68, 4.34, 5.09, 6.57, 7.61, 8.18, 8.64, 8.97, 9.12,
  9.04, 8.76, 8.3, 7.66, 7.25, 7.41, 7.07, 6.41, 5.04, 3.82, 3.33, 3.41, 3.24, 3.24, 3.5, 3.78,
  4.14, 4.59, 5.98, 7.09, 7.57, 7.84, 8.01, 8.12, 8.23, 8.26, 8.32, 8.33, 8.34, 8.73, 8.48, 7.68,
  6.08, 4.55, 3.7, 3.36, 3.33, 3.38, 3.64, 4.19, 4.72, 4.83, 5.58, 6.51, 7.11, 7.5, 7.79, 7.92,
  8.03, 8.11, 8.09, 7.84, 7.4, 7.23, 6.5, 5.68, 4.92, 4.15, 3.61, 3.27, 3.31, 3.41, 3.53, 3.74,
  4.45, 5.37, 6.87, 7.7, 8.02, 8.2, 8.31, 8.37, 8.32, 8.09, 7.76, 7.25, 6.94, 7.26, 6.96, 6.29,
  4.87, 3.81, 3.47, 3.42, 3.46, 3.54, 3.66, 3.9, 4.57, 5.46, 6.92, 7.73, 8.1, 8.55, 8.93, 9.15,
  9.15, 8.88, 8.55, 7.94, 7.39, 7.55, 7.19, 6.44, 4.95, 3.86, 3.49, 3.47, 3.58, 3.67, 3.79, 4.03,
  4.73, 5.55, 6.97, 7.78, 8.1, 8.27, 8.36, 8.4, 8.34, 8.11, 7.78, 7.26, 6.91, 7.21, 6.95, 6.32, 4.9,
  3.79, 3.39, 3.32, 3.36, 3.45, 3.57, 3.77, 4.45, 5.19, 6.65, 7.54, 7.82, 7.93, 8.11, 8.29, 8.37,
  8.27, 7.93, 7.44, 7.16, 7.43, 7.16, 6.53, 5.15, 3.87, 3.34, 3.25, 3.29, 3.37, 3.48, 3.68, 4.34,
  5.09, 6.57, 7.61, 8.18, 8.64, 8.97, 9.12, 9.04, 8.76, 8.3, 7.66, 7.25, 7.41, 7.07, 6.41, 5.04,
  3.82, 3.33, 3.41, 3.24, 3.24, 3.5, 3.78, 4.14, 4.59, 5.98, 7.09, 7.57, 7.84, 8.01, 8.12, 8.23,
  8.26, 8.32, 8.33, 8.34, 8.73, 8.48, 7.68, 6.08, 4.55, 3.7, 3.36, 3.33, 3.64, 4.19, 4.72, 4.83,
  5.58, 6.51, 7.11, 7.5, 7.79, 7.92, 8.03, 8.11, 8.09, 7.84, 7.4, 7.23, 6.5, 5.68, 4.92, 4.15, 3.61,
  3.27, 3.31, 3.41, 3.53, 3.74, 4.45, 5.37, 6.87, 7.7, 8.02, 8.2, 8.31, 8.37, 8.32, 8.09, 7.76,
  7.25, 6.94, 7.26, 6.96, 6.29, 4.87, 3.81, 3.47, 3.42, 3.46, 3.54, 3.66, 3.9, 4.57, 5.46, 6.92,
  7.73, 8.1, 8.55, 8.93, 9.15, 9.15, 8.88, 8.55, 7.94, 7.39, 7.55, 7.19, 6.44, 4.95, 3.86, 3.49,
  3.47, 3.58, 3.67, 3.79, 4.03, 4.73, 5.55, 6.97, 7.78, 8.1, 8.27, 8.36, 8.4, 8.34, 8.11, 7.78,
  7.26, 6.91, 7.21, 6.95, 6.32, 4.9, 3.79, 3.39, 3.32, 3.36, 3.45, 3.57, 3.77, 4.45, 5.19, 6.65,
  7.54, 7.82, 7.93, 8.11, 8.29, 8.37, 8.27, 7.93, 7.44, 7.16, 7.43, 7.16, 6.53, 5.15, 3.87, 3.34,
  3.25, 3.29, 3.37, 3.48, 3.68, 4.34, 5.09, 6.57, 7.61, 8.18, 8.64, 8.97, 9.12, 9.04, 8.76, 8.3,
  7.66, 7.25, 7.41, 7.07, 6.41, 5.04, 3.82, 3.33, 3.41, 3.24, 3.24, 3.5, 3.78, 4.14, 4.59, 5.98,
  7.09, 7.57, 7.84, 8.01, 8.12, 8.23, 8.26, 8.32, 8.33, 8.34, 8.73, 8.48, 7.68, 6.08, 4.55, 3.7,
  3.36, 3.33, 3.38, 3.64, 4.19, 4.72, 4.83, 5.58, 6.51, 7.11, 7.5, 7.79, 7.92, 8.03, 8.11, 8.09,
  7.84, 7.4, 7.23, 6.5, 5.68, 4.92, 4.15, 3.61, 3.27, 3.31, 3.41, 3.53, 3.74, 4.45, 5.37, 6.87, 7.7,
  8.02, 8.2, 8.31, 8.37, 8.32, 8.09, 7.76, 7.25, 6.94, 7.26, 6.96, 6.29, 4.87, 3.81, 3.47, 3.42,
  3.46, 3.54, 3.66, 3.9, 4.57, 5.46, 6.92, 7.73, 8.1, 8.55, 8.93, 9.15, 9.15, 8.88, 8.55, 7.94,
  7.39, 7.55, 7.19, 6.44, 4.95, 3.86, 3.49, 3.47, 3.58, 3.67, 3.79, 4.03, 4.73, 5.55, 6.97, 7.78,
  8.1, 8.27, 8.36, 8.4, 8.34, 8.11, 7.78, 7.26, 6.91, 7.21, 6.95, 6.32, 4.9, 3.79, 3.39, 3.32, 3.36,
  3.45, 3.57, 3.77, 4.45, 5.19, 6.65, 7.54, 7.82, 7.93, 8.11, 8.29, 8.37, 8.27, 7.93, 7.44, 7.16,
  7.43, 7.16, 6.53, 5.15, 3.87, 3.34, 3.25, 3.29, 3.37, 3.48, 3.68, 4.34, 5.09, 6.57, 7.61, 8.18,
  8.64, 8.97, 9.12, 9.04, 8.76, 8.3, 7.66, 7.25, 7.41, 7.07, 6.41, 5.04, 3.82, 3.33, 3.41, 3.24,
  3.24, 3.5, 3.78, 4.14, 4.59, 5.98, 7.09, 7.57, 7.84, 8.01, 8.12, 8.23, 8.26, 8.32, 8.33, 8.34,
  8.73, 8.48, 7.68, 6.08, 4.55, 3.7, 3.36, 3.33, 3.38, 3.64, 4.19, 4.72, 4.83, 5.58, 6.51, 7.11,
  7.5, 7.79, 7.92, 8.03, 8.11, 8.09, 7.84, 7.4, 7.23, 6.5, 5.68, 4.92, 4.15, 3.61, 3.27, 3.31, 3.41,
  3.53, 3.74, 4.45, 5.37, 6.87, 7.7, 8.02, 8.2, 8.31, 8.37, 8.32, 8.09, 7.76, 7.25, 6.94, 7.26,
  6.96, 6.29, 4.87, 3.81, 3.47, 3.42, 3.46, 3.54, 3.66, 3.9, 4.57, 5.46, 6.92, 7.73, 8.1, 8.55,
  8.93, 9.15, 9.15, 8.88, 8.55, 7.94, 7.39, 7.55, 7.19, 6.44, 4.95, 3.86, 3.49, 3.47, 3.58, 3.67,
  3.79, 4.03, 4.73, 5.55, 6.97, 7.78, 8.1, 8.27, 8.36, 8.4, 8.34, 8.11, 7.78, 7.26, 6.91, 7.21,
  6.95, 6.32, 4.9, 3.79, 3.39, 3.32, 3.36, 3.45, 3.57, 3.77, 4.45, 5.19, 6.65, 7.54, 7.82, 7.93,
  8.11, 8.29, 8.37, 8.27, 7.93, 7.44, 7.16, 7.43, 7.16, 6.53, 5.15, 3.87, 3.34, 3.25, 3.29, 3.37,
  3.48, 3.68, 4.34, 5.09, 6.57, 7.61, 8.18, 8.64, 8.97, 9.12, 9.04, 8.76, 8.3, 7.66, 7.25, 7.41,
  7.07, 6.41, 5.04, 3.82, 3.33, 3.41, 3.24, 3.24, 3.5, 3.78, 4.14, 4.59, 5.98, 7.09, 7.57, 7.84,
  8.01, 8.12, 8.23, 8.26, 8.32, 8.33, 8.34, 8.73, 8.48, 7.68, 6.08, 4.55, 3.7, 3.36, 3.33, 3.38,
  3.64, 4.19, 4.72, 4.83, 5.58, 6.51, 7.11, 7.5, 7.79, 7.92, 8.03, 8.11, 8.09, 7.84, 7.4, 7.23, 6.5,
  5.68, 4.92, 4.15, 3.61, 3.09, 3.15, 3.22, 3.34, 3.58, 3.95, 5.46, 7.06, 7.8, 8.19, 8.54, 8.83,
  9.05, 9.05, 8.87, 8.48, 7.78, 7.21, 7.12, 6.94, 6.04, 4.25, 3.29, 3.09, 3.06, 3.13, 3.23, 3.34,
  3.59, 4.02, 5.48, 7.04, 7.72, 8.09, 8.48, 8.83, 9.01, 8.86, 8.41, 7.93, 7.29, 6.83, 6.92, 6.84,
  6.0, 4.3, 3.38, 3.18, 3.14, 3.2, 3.26, 3.38, 3.61, 3.97, 5.41, 6.98, 7.7, 8.0, 8.19, 8.3, 8.37,
  8.33, 8.12, 7.81, 7.26, 6.85, 6.94, 6.87, 6.1, 4.44, 3.46, 3.22, 3.12, 3.18, 3.26, 3.35, 3.57,
  3.97, 5.47, 7.01, 7.64, 7.96, 8.24, 8.52, 8.66, 8.61, 8.4, 8.06, 7.47, 7.05, 7.1, 7.0, 6.12, 4.36,
  3.36, 3.1, 3.1, 3.17, 3.26, 3.38, 3.62, 4.01, 5.51, 7.09, 7.87, 8.43, 9.0, 9.53, 9.89, 9.79, 9.5,
  8.86, 7.95, 7.37, 7.34, 7.17, 6.25, 4.42, 3.43, 3.21, 3.3, 3.11, 3.2, 3.63, 3.8, 3.92, 5.09, 7.0,
  8.03, 8.83, 9.31, 9.48, 9.69, 9.86, 9.92, 9.88, 9.69, 9.22, 9.2, 8.84, 7.49, 5.47, 4.07, 3.44,
  3.29, 3.21, 3.25, 3.56, 4.18, 4.26, 4.67, 5.59, 6.58, 7.13, 7.59, 7.92, 8.09, 8.21, 8.24, 8.25,
  7.99, 7.26, 6.89, 6.26, 5.39, 4.63, 3.85, 3.34, 3.09, 3.15, 3.22, 3.34, 3.58, 3.95, 5.46, 7.06,
  7.8, 8.19, 8.54, 8.83, 9.05, 9.05, 8.87, 8.48, 7.78, 7.21, 7.12, 6.94, 6.04, 4.25, 3.29, 3.09,
  3.06, 3.13, 3.23, 3.34, 3.59, 4.02, 5.48, 7.04, 7.72, 8.09, 8.48, 8.83, 9.01, 8.86, 8.41, 7.93,
  7.29, 6.83, 6.92, 6.84, 6.0, 4.3, 3.38, 3.18, 3.14, 3.2, 3.26, 3.38, 3.61, 3.97, 5.41, 6.98, 7.7,
  8.0, 8.19, 8.3, 8.37, 8.33, 8.12, 7.81, 7.26, 6.85, 6.94, 6.87, 6.1, 4.44, 3.46, 3.22, 3.12, 3.18,
  3.26, 3.35, 3.57, 3.97, 5.47, 7.01, 7.64, 7.96, 8.24, 8.52, 8.66, 8.61, 8.4, 8.06, 7.47, 7.05,
  7.1, 7.0, 6.12, 4.36, 3.36, 3.1, 3.1, 3.17, 3.26, 3.38, 3.62, 4.01, 5.51, 7.09, 7.87, 8.43, 9.0,
  9.53, 9.89, 9.79, 9.5, 8.86, 7.95, 7.37, 7.34, 7.17, 6.25, 4.42, 3.43, 3.21, 3.3, 3.11, 3.2, 3.63,
  3.8, 3.92, 5.09, 7.0, 8.03, 8.83, 9.31, 9.48, 9.69, 9.86, 9.92, 9.88, 9.69, 9.22, 9.2, 8.84, 7.49,
  5.47, 4.07, 3.44, 3.29, 3.21, 3.25, 3.56, 4.18, 4.26, 4.67, 5.59, 6.58, 7.13, 7.59, 7.92, 8.09,
  8.21, 8.24, 8.25, 7.99, 7.26, 6.89, 6.26, 5.39, 4.63, 3.85, 3.34, 3.09, 3.15, 3.22, 3.34, 3.58,
  3.95, 5.46, 7.06, 7.8, 8.19, 8.54, 8.83, 9.05, 9.05, 8.87, 8.48, 7.78, 7.21, 7.12, 6.94, 6.04,
  4.25, 3.29, 3.09, 3.06, 3.13, 3.23, 3.34, 3.59, 4.02, 5.48, 7.04, 7.72, 8.09, 8.48, 8.83, 9.01,
  8.86, 8.41, 7.93, 7.29, 6.83, 6.92, 6.84, 6.0, 4.3, 3.38, 3.18, 3.14, 3.2, 3.26, 3.38, 3.61, 3.97,
  5.41, 6.98, 7.7, 8.0, 8.19, 8.3, 8.37, 8.33, 8.12, 7.81, 7.26, 6.85, 6.94, 6.87, 6.1, 4.44, 3.46,
  3.22, 3.12, 3.18, 3.26, 3.35, 3.57, 3.97, 5.47, 7.01, 7.64, 7.96, 8.24, 8.52, 8.66, 8.61, 8.4,
  8.06, 7.47, 7.05, 7.1, 7.0, 6.12, 4.36, 3.36, 3.1, 3.1, 3.17, 3.26, 3.38, 3.62, 4.01, 5.51, 7.09,
  7.87, 8.43, 9.0, 9.53, 9.89, 9.79, 9.5, 8.86, 7.95, 7.37, 7.34, 7.17, 6.25, 4.42, 3.43, 3.21, 3.3,
  3.11, 3.2, 3.63, 3.8, 3.92, 5.09, 7.0, 8.03, 8.83, 9.31, 9.48, 9.69, 9.86, 9.92, 9.88, 9.69, 9.22,
  9.2, 8.84, 7.49, 5.47, 4.07, 3.44, 3.29, 3.21, 3.25, 3.56, 4.18, 4.26, 4.67, 5.59, 6.58, 7.13,
  7.59, 7.92, 8.09, 8.21, 8.24, 8.25, 7.99, 7.26, 6.89, 6.26, 5.39, 4.63, 3.85, 3.34, 3.09, 3.15,
  3.22, 3.34, 3.58, 3.95, 5.46, 7.06, 7.8, 8.19, 8.54, 8.83, 9.05, 9.05, 8.87, 8.48, 7.78, 7.21,
  7.12, 6.94, 6.04, 4.25, 3.29, 3.09, 3.06, 3.13, 3.23, 3.34, 3.59, 4.02, 5.48, 7.04, 7.72, 8.09,
  8.48, 8.83, 9.01, 8.86, 8.41, 7.93, 7.29, 6.83, 6.92, 6.84, 6.0, 4.3, 3.38, 3.18, 3.14, 3.2, 3.26,
  3.38, 3.61, 3.97, 5.41, 6.98, 7.7, 8.0, 8.19, 8.3, 8.37, 8.33, 8.12, 7.81, 7.26, 6.85, 6.94, 6.87,
  6.1, 4.44, 3.46, 3.22, 3.12, 3.18, 3.26, 3.35, 3.57, 3.97, 5.47, 7.01, 7.64, 7.96, 8.24, 8.52,
  8.66, 8.61, 8.4, 8.06, 7.47, 7.05, 7.1, 7.0, 6.12, 4.36, 3.36, 3.1, 3.1, 3.17, 3.26, 3.38, 3.62,
  4.01, 5.51, 7.09, 7.87, 8.43, 9.0, 9.53, 9.89, 9.79, 9.5, 8.86, 7.95, 7.37, 7.34, 7.17, 6.25,
  4.42, 3.43, 3.21, 3.3, 3.11, 3.2, 3.63, 3.8, 3.92, 5.09, 7.0, 8.03, 8.83, 9.31, 9.48, 9.69, 9.86,
  9.92, 9.88, 9.69, 9.22, 9.2, 8.84, 7.49, 5.47, 4.07, 3.44, 3.29, 3.21, 3.25, 3.56, 4.18, 4.26,
  4.67, 5.59, 6.58, 7.13, 7.59, 7.92, 8.09, 8.21, 8.24, 8.25, 7.99, 7.26, 6.89, 6.26, 5.39, 4.63,
  3.85, 3.34, 3.09, 3.15, 3.22, 3.34, 3.58, 3.95, 5.46, 7.06, 7.8, 8.19, 8.54, 8.83, 9.05, 9.05,
  8.87, 8.48, 7.78, 7.21, 7.12, 6.94, 6.04, 4.25, 3.29, 3.09, 3.06, 3.13, 3.23, 3.34, 3.59, 4.02,
  5.48, 7.04, 7.72, 8.09, 8.48, 8.83, 9.01, 8.86, 8.41, 7.93, 7.29, 6.83, 6.92, 6.84, 6.0, 4.3,
  3.38, 3.18, 3.08, 3.13, 3.17, 3.27, 3.2, 3.97, 5.96, 8.06, 9.38, 10.32, 11.15, 11.68, 12.04,
  11.99, 11.63, 10.99, 9.82, 8.93, 7.98, 7.75, 6.66, 4.71, 3.62, 3.31, 3.15, 3.16, 3.19, 3.28, 3.32,
  4.0, 5.93, 8.04, 9.35, 10.28, 11.29, 12.08, 12.51, 12.6, 12.16, 11.23, 10.03, 9.27, 8.5, 8.29,
  7.05, 4.83, 3.59, 3.21, 3.11, 3.13, 3.15, 3.24, 3.18, 3.82, 5.69, 7.63, 8.72, 9.3, 9.81, 10.09,
  10.24, 10.18, 9.92, 9.16, 8.03, 7.35, 6.92, 7.03, 6.08, 4.28, 3.3, 3.08, 3.13, 2.97, 3.06, 3.44,
  3.38, 3.6, 4.91, 6.81, 7.88, 8.61, 9.15, 9.4, 9.63, 9.71, 9.66, 9.41, 9.07, 8.7, 8.48, 8.56, 7.3,
  5.3, 3.89, 3.28, 3.15, 3.07, 3.12, 3.43, 3.79, 3.91, 4.55, 5.48, 6.48, 7.06, 7.52, 7.89, 8.09,
  8.17, 8.22, 8.24, 7.94, 7.27, 6.51, 6.22, 5.32, 4.55, 3.77, 3.27, 3.03, 3.09, 3.16, 3.27, 3.27,
  3.69, 5.39, 7.11, 8.13, 8.95, 9.9, 10.63, 11.12, 11.3, 11.25, 10.52, 9.28, 8.4, 7.68, 7.56, 6.45,
  4.48, 3.42, 3.15, 3.04, 3.08, 3.14, 3.24, 3.22, 3.91, 5.84, 7.87, 9.04, 9.89, 10.66, 11.32, 11.75,
  11.86, 11.61, 10.79, 9.45, 8.52, 7.77, 7.61, 6.45, 4.47, 3.44, 3.15, 3.08, 3.13, 3.17, 3.27, 3.2,
  3.97, 5.96, 8.06, 9.38, 10.32, 11.15, 11.68, 12.04, 11.99, 11.63, 10.99, 9.82, 8.93, 7.98, 7.75,
  6.66, 4.71, 3.62, 3.31, 3.15, 3.16, 3.19, 3.28, 3.32, 4.0, 5.93, 8.04, 9.35, 10.28, 11.29, 12.08,
  12.51, 12.6, 12.16, 11.23, 10.03, 9.27, 8.5, 8.29, 7.05, 4.83, 3.59, 3.21, 3.11, 3.13, 3.15, 3.24,
  3.18, 3.82, 5.69, 7.63, 8.72, 9.3, 9.81, 10.09, 10.24, 10.18, 9.92, 9.16, 8.03, 7.35, 6.92, 7.03,
  6.08, 4.28, 3.3, 3.08, 3.13, 2.97, 3.06, 3.44, 3.38, 3.6, 4.91, 6.81, 7.88, 8.61, 9.15, 9.4, 9.63,
  9.71, 9.66, 9.41, 9.07, 8.7, 8.48, 8.56, 7.3, 5.3, 3.89, 3.28, 3.15, 3.07, 3.12, 3.43, 3.79, 3.91,
  4.55, 5.48, 6.48, 7.06, 7.52, 7.89, 8.09, 8.17, 8.22, 8.24, 7.94, 7.27, 6.51, 6.22, 5.32, 4.55,
  3.77, 3.27, 3.03, 3.09, 3.16, 3.27, 3.27, 3.69, 5.39, 7.11, 8.13, 8.95, 9.9, 10.63, 11.12, 11.3,
  11.25, 10.52, 9.28, 8.4, 7.68, 7.56, 6.45, 4.48, 3.42, 3.15, 3.04, 3.08, 3.14, 3.24, 3.22, 3.91,
  5.84, 7.87, 9.04, 9.89, 10.66, 11.32, 11.75, 11.86, 11.61, 10.79, 9.45, 8.52, 7.77, 7.61, 6.45,
  4.47, 3.44, 3.15, 3.08, 3.13, 3.17, 3.27, 3.2, 3.97, 5.96, 8.06, 9.38, 10.32, 11.15, 11.68, 12.04,
  11.99, 11.63, 10.99, 9.82, 8.93, 7.98, 7.75, 6.66, 4.71, 3.62, 3.31, 3.15, 3.16, 3.19, 3.28, 3.32,
  4.0, 5.93, 8.04, 9.35, 10.28, 11.29, 12.08, 12.51, 12.6, 12.16, 11.23, 10.03, 9.27, 8.5, 8.29,
  7.05, 4.83, 3.59, 3.21, 3.11, 3.13, 3.15, 3.24, 3.18, 3.82, 5.69, 7.63, 8.72, 9.3, 9.81, 10.09,
  10.24, 10.18, 9.92, 9.16, 8.03, 7.35, 6.92, 7.03, 6.08, 4.28, 3.3, 3.08, 3.13, 2.97, 3.06, 3.44,
  3.38, 3.6, 4.91, 6.81, 7.88, 8.61, 9.15, 9.4, 9.63, 9.71, 9.66, 9.41, 9.07, 8.7, 8.48, 8.56, 7.3,
  5.3, 3.89, 3.28, 3.15, 3.07, 3.12, 3.43, 3.79, 3.91, 4.55, 5.48, 6.48, 7.06, 7.52, 7.89, 8.09,
  8.17, 8.22, 8.24, 7.94, 7.27, 6.51, 6.22, 5.32, 4.55, 3.77, 3.27, 3.03, 3.09, 3.16, 3.27, 3.27,
  3.69, 5.39, 7.11, 8.13, 8.95, 9.9, 10.63, 11.12, 11.3, 11.25, 10.52, 9.28, 8.4, 7.68, 7.56, 6.45,
  4.48, 3.42, 3.15, 3.04, 3.08, 3.14, 3.24, 3.22, 3.91, 5.84, 7.87, 9.04, 9.89, 10.66, 11.32, 11.75,
  11.86, 11.61, 10.79, 9.45, 8.52, 7.77, 7.61, 6.45, 4.47, 3.44, 3.15, 3.08, 3.13, 3.17, 3.27, 3.2,
  3.97, 5.96, 8.06, 9.38, 10.32, 11.15, 11.68, 12.04, 11.99, 11.63, 10.99, 9.82, 8.93, 7.98, 7.75,
  6.66, 4.71, 3.62, 3.31, 3.15, 3.16, 3.19, 3.28, 3.32, 4.0, 5.93, 8.04, 9.35, 10.28, 11.29, 12.08,
  12.51, 12.6, 12.16, 11.23, 10.03, 9.27, 8.5, 8.29, 7.05, 4.83, 3.59, 3.21, 3.11, 3.13, 3.15, 3.24,
  3.18, 3.82, 5.69, 7.63, 8.72, 9.3, 9.81, 10.09, 10.24, 10.18, 9.92, 9.16, 8.03, 7.35, 6.92, 7.03,
  6.08, 4.28, 3.3, 3.08, 3.13, 2.97, 3.06, 3.44, 3.38, 3.6, 4.91, 6.81, 7.88, 8.61, 9.15, 9.4, 9.63,
  9.71, 9.66, 9.41, 9.07, 8.7, 8.48, 8.56, 7.3, 5.3, 3.89, 3.28, 3.15, 3.07, 3.12, 3.43, 3.79, 3.91,
  4.55, 5.48, 6.48, 7.06, 7.52, 7.89, 8.09, 8.17, 8.22, 8.24, 7.94, 7.27, 6.51, 6.22, 5.32, 4.55,
  3.77, 3.27, 3.03, 3.09, 3.16, 3.27, 3.27, 3.69, 5.39, 7.11, 8.13, 8.95, 9.9, 10.63, 11.12, 11.3,
  11.25, 10.52, 9.28, 8.4, 7.68, 7.56, 6.45, 4.48, 3.42, 3.15, 3.04, 3.08, 3.14, 3.24, 3.22, 3.91,
  5.84, 7.87, 9.04, 9.89, 10.66, 11.32, 11.75, 11.86, 11.61, 10.79, 9.45, 8.52, 7.77, 7.61, 6.45,
  4.47, 3.44, 3.15, 3.08, 3.13, 3.17, 3.27, 3.2, 3.97, 5.96, 8.06, 9.38, 10.32, 11.15, 11.68, 12.04,
  11.99, 11.63, 10.99, 9.82, 8.93, 7.98, 7.75, 6.66, 4.71, 3.62, 3.31, 3.15, 3.16, 3.19, 3.28, 3.32,
  4.0, 5.93, 8.04, 9.35, 10.28, 11.29, 12.08, 12.51, 12.6, 12.16, 11.23, 10.03, 9.27, 8.5, 8.29,
  7.05, 4.83, 3.59, 3.21, 3.11, 3.13, 3.15, 3.24, 3.18, 3.82, 5.69, 7.63, 8.72, 9.3, 9.81, 10.09,
  10.24, 10.18, 9.92, 9.16, 8.03, 7.35, 6.92, 7.03, 6.08, 4.28, 3.3, 3.08, 3.32, 3.09, 3.17, 3.6,
  3.23, 4.03, 5.65, 8.01, 9.64, 10.76, 11.8, 12.72, 13.34, 13.88, 14.17, 14.21, 13.99, 13.3, 12.22,
  11.37, 9.4, 6.71, 4.79, 3.86, 3.45, 3.28, 3.3, 3.61, 3.85, 4.27, 4.88, 6.79, 8.48, 9.8, 10.83,
  11.51, 11.6, 11.8, 11.96, 11.9, 11.22, 9.78, 8.38, 7.34, 6.12, 5.05, 4.05, 3.4, 3.08, 3.1, 3.13,
  3.2, 2.94, 4.01, 6.15, 8.42, 9.84, 10.78, 11.37, 11.52, 11.59, 11.33, 10.83, 9.95, 8.69, 7.85,
  7.27, 7.25, 6.38, 4.41, 3.36, 3.11, 3.04, 3.07, 3.12, 3.22, 2.99, 3.9, 5.94, 8.05, 9.35, 10.29,
  11.18, 11.75, 12.21, 12.21, 11.77, 10.73, 9.37, 8.58, 7.82, 7.59, 6.61, 4.54, 3.41, 3.12, 3.04,
  3.07, 3.1, 3.2, 2.94, 3.89, 5.89, 8.16, 9.8, 10.85, 11.73, 12.38, 12.7, 12.53, 12.05, 10.92, 9.59,
  8.76, 7.97, 7.82, 6.94, 4.84, 3.65, 3.31, 3.16, 3.16, 3.16, 3.23, 2.88, 4.14, 6.25, 8.49, 9.87,
  10.62, 11.22, 11.74, 11.96, 11.94, 11.59, 10.81, 9.83, 9.21, 8.46, 8.17, 7.09, 4.88, 3.65, 3.26,
  3.2, 3.17, 3.17, 3.24, 2.95, 4.02, 6.21, 8.53, 10.01, 11.06, 11.97, 12.4, 12.54, 12.55, 12.29,
  11.37, 9.98, 9.08, 8.29, 8.06, 6.99, 4.76, 3.57, 3.25, 3.32, 3.09, 3.17, 3.6, 3.23, 4.03, 5.65,
  8.01, 9.64, 10.76, 11.8, 12.72, 13.34, 13.88, 14.17, 14.21, 13.99, 13.3, 12.22, 11.37, 9.4, 6.71,
  4.79, 3.86, 3.45, 3.28, 3.3, 3.61, 3.85, 4.27, 4.88, 6.79, 8.48, 9.8, 10.83, 11.51, 11.6, 11.8,
  11.96, 11.9, 11.22, 9.78, 8.38, 7.34, 6.12, 5.05, 4.05, 3.4, 3.08, 3.1, 3.13, 3.2, 2.94, 4.01,
  6.15, 8.42, 9.84, 10.78, 11.37, 11.52, 11.59, 11.33, 10.83, 9.95, 8.69, 7.85, 7.27, 7.25, 6.38,
  4.41, 3.36, 3.11, 3.04, 3.07, 3.12, 3.22, 2.99, 3.9, 5.94, 8.05, 9.35, 10.29, 11.18, 11.75, 12.21,
  12.21, 11.77, 10.73, 9.37, 8.58, 7.82, 7.59, 6.61, 4.54, 3.41, 3.12, 3.04, 3.07, 3.1, 3.2, 2.94,
  3.89, 5.89, 8.16, 9.8, 10.85, 11.73, 12.38, 12.7, 12.53, 12.05, 10.92, 9.59, 8.76, 7.97, 7.82,
  6.94, 4.84, 3.65, 3.31, 3.16, 3.16, 3.16, 3.23, 2.88, 4.14, 6.25, 8.49, 9.87, 10.62, 11.22, 11.74,
  11.96, 11.94, 11.59, 10.81, 9.83, 9.21, 8.46, 8.17, 7.09, 4.88, 3.65, 3.26, 3.2, 3.17, 3.17, 3.24,
  2.95, 4.02, 6.21, 8.53, 10.01, 11.06, 11.97, 12.4, 12.54, 12.55, 12.29, 11.37, 9.98, 9.08, 8.29,
  8.06, 6.99, 4.76, 3.57, 3.25, 3.32, 3.09, 3.17, 3.6, 3.23, 4.03, 5.65, 8.01, 9.64, 10.76, 11.8,
  12.72, 13.34, 13.88, 14.17, 14.21, 13.99, 13.3, 12.22, 11.37, 9.4, 6.71, 4.79, 3.86, 3.45, 3.28,
  3.3, 3.61, 3.85, 4.27, 4.88, 6.79, 8.48, 9.8, 10.83, 11.51, 11.6, 11.8, 11.96, 11.9, 11.22, 9.78,
  8.38, 7.34, 6.12, 5.05, 4.05, 3.4, 3.08, 3.1, 3.13, 3.2, 2.94, 4.01, 6.15, 8.42, 9.84, 10.78,
  11.37, 11.52, 11.59, 11.33, 10.83, 9.95, 8.69, 7.85, 7.27, 7.25, 6.38, 4.41, 3.36, 3.11, 3.04,
  3.07, 3.12, 3.22, 2.99, 3.9, 5.94, 8.05, 9.35, 10.29, 11.18, 11.75, 12.21, 12.21, 11.77, 10.73,
  9.37, 8.58, 7.82, 7.59, 6.61, 4.54, 3.41, 3.12, 3.04, 3.07, 3.1, 3.2, 2.94, 3.89, 5.89, 8.16, 9.8,
  10.85, 11.73, 12.38, 12.7, 12.53, 12.05, 10.92, 9.59, 8.76, 7.97, 7.82, 6.94, 4.84, 3.65, 3.31,
  3.16, 3.16, 3.16, 3.23, 2.88, 4.14, 6.25, 8.49, 9.87, 10.62, 11.22, 11.74, 11.96, 11.94, 11.59,
  10.81, 9.83, 9.21, 8.46, 8.17, 7.09, 4.88, 3.65, 3.26, 3.2, 3.17, 3.17, 3.24, 2.95, 4.02, 6.21,
  8.53, 10.01, 11.06, 11.97, 12.4, 12.54, 12.55, 12.29, 11.37, 9.98, 9.08, 8.29, 8.06, 6.99, 4.76,
  3.57, 3.25, 3.32, 3.09, 3.17, 3.6, 3.23, 4.03, 5.65, 8.01, 9.64, 10.76, 11.8, 12.72, 13.34, 13.88,
  14.17, 14.21, 13.99, 13.3, 12.22, 11.37, 9.4, 6.71, 4.79, 3.86, 3.45, 3.28, 3.3, 3.61, 3.85, 4.27,
  4.88, 6.79, 8.48, 9.8, 10.83, 11.51, 11.6, 11.8, 11.96, 11.9, 11.22, 9.78, 8.38, 7.34, 6.12, 5.05,
  4.05, 3.4, 3.08, 3.1, 3.13, 3.2, 2.94, 4.01, 6.15, 8.42, 9.84, 10.78, 11.37, 11.52, 11.59, 11.33,
  10.83, 9.95, 8.69, 7.85, 7.27, 7.25, 6.38, 4.41, 3.36, 3.11, 3.04, 3.07, 3.12, 3.22, 2.99, 3.9,
  5.94, 8.05, 9.35, 10.29, 11.18, 11.75, 12.21, 12.21, 11.77, 10.73, 9.37, 8.58, 7.82, 7.59, 6.61,
  4.54, 3.41, 3.12, 3.04, 3.07, 3.1, 3.2, 2.94, 3.89, 5.89, 8.16, 9.8, 10.85, 11.73, 12.38, 12.7,
  12.53, 12.05, 10.92, 9.59, 8.76, 7.97, 7.82, 6.94, 4.84, 3.65, 3.31, 3.16, 3.16, 3.16, 3.23, 2.88,
  4.14, 6.25, 8.49, 9.87, 10.62, 11.22, 11.74, 11.96, 11.94, 11.59, 10.81, 9.83, 9.21, 8.46, 8.17,
  7.09, 4.88, 3.65, 3.26, 3.2, 3.17, 3.17, 3.24, 2.95, 4.02, 6.21, 8.53, 10.01, 11.06, 11.97, 12.4,
  12.54, 12.55, 12.29, 11.37, 9.98, 9.08, 8.29, 8.06, 6.99, 4.76, 3.57, 3.25, 3.32, 3.09, 3.17, 3.6,
  3.23, 4.03, 5.65, 8.01, 9.64, 10.76, 11.8, 12.72, 13.34, 13.88, 14.17, 14.21, 13.99, 13.3, 12.22,
  11.37, 9.4, 6.71, 4.79, 3.86, 3.45, 3.28, 3.3, 3.61, 3.85, 4.27, 4.88, 6.79, 8.48, 9.8, 10.83,
  11.51, 11.6, 11.8, 11.96, 11.9, 11.22, 9.78, 8.38, 7.34, 6.12, 5.05, 4.05, 3.4, 3.25, 3.24, 3.27,
  3.36, 3.21, 4.29, 6.54, 8.99, 10.6, 11.9, 12.96, 13.59, 13.86, 13.33, 12.35, 11.32, 10.26, 9.58,
  8.72, 8.35, 7.16, 4.92, 3.75, 3.41, 3.29, 3.3, 3.34, 3.44, 3.4, 4.51, 6.94, 9.65, 11.42, 12.59,
  13.61, 14.4, 14.84, 14.84, 14.48, 13.33, 11.77, 10.6, 9.49, 8.98, 7.68, 5.26, 4.02, 3.65, 3.58,
  3.56, 3.6, 3.71, 3.91, 4.91, 7.45, 10.32, 12.56, 14.28, 15.52, 16.17, 16.34, 15.99, 15.47, 14.27,
  12.5, 11.3, 10.05, 9.43, 8.28, 5.84, 4.44, 4.0, 3.76, 3.64, 3.61, 3.71, 3.92, 4.94, 7.55, 10.61,
  12.7, 13.83, 14.38, 14.26, 14.45, 14.57, 13.9, 12.7, 11.23, 10.31, 9.37, 8.88, 7.53, 5.13, 3.83,
  3.43, 3.36, 3.36, 3.38, 3.47, 3.27, 4.55, 6.9, 9.53, 11.28, 12.54, 13.38, 13.7, 13.99, 14.09,
  13.84, 12.77, 11.23, 10.19, 9.16, 8.69, 7.43, 5.06, 3.83, 3.52, 3.66, 3.4, 3.45, 3.89, 3.83, 4.51,
  6.34, 9.03, 10.93, 12.1, 12.98, 13.7, 14.16, 14.46, 14.27, 13.78, 13.34, 12.79, 12.17, 11.55,
  9.52, 6.79, 4.81, 3.83, 3.5, 3.33, 3.34, 3.65, 4.04, 4.3, 4.9, 6.9, 8.67, 10.16, 11.24, 12.04,
  12.54, 12.93, 13.11, 12.95, 12.21, 10.69, 9.06, 7.92, 6.55, 5.38, 4.31, 3.6, 3.25, 3.24, 3.27,
  3.36, 3.21, 4.29, 6.54, 8.99, 10.6, 11.9, 12.96, 13.59, 13.86, 13.33, 12.35, 11.32, 10.26, 9.58,
  8.72, 8.35, 7.16, 4.92, 3.75, 3.41, 3.29, 3.3, 3.34, 3.44, 3.4, 4.51, 6.94, 9.65, 11.42, 12.59,
  13.61, 14.4, 14.84, 14.84, 14.48, 13.33, 11.77, 10.6, 9.49, 8.98, 7.68, 5.26, 4.02, 3.65, 3.58,
  3.56, 3.6, 3.71, 3.91, 4.91, 7.45, 10.32, 12.56, 14.28, 15.52, 16.17, 16.34, 15.99, 15.47, 14.27,
  12.5, 11.3, 10.05, 9.43, 8.28, 5.84, 4.44, 4.0, 3.76, 3.64, 3.61, 3.71, 3.92, 4.94, 7.55, 10.61,
  12.7, 13.83, 14.38, 14.26, 14.45, 14.57, 13.9, 12.7, 11.23, 10.31, 9.37, 8.88, 7.53, 5.13, 3.83,
  3.43, 3.36, 3.36, 3.38, 3.47, 3.27, 4.55, 6.9, 9.53, 11.28, 12.54, 13.38, 13.7, 13.99, 14.09,
  13.84, 12.77, 11.23, 10.19, 9.16, 8.69, 7.43, 5.06, 3.83, 3.52, 3.66, 3.4, 3.45, 3.89, 3.83, 4.51,
  6.34, 9.03, 10.93, 12.1, 12.98, 13.7, 14.16, 14.46, 14.27, 13.78, 13.34, 12.79, 12.17, 11.55,
  9.52, 6.79, 4.81, 3.83, 3.5, 3.33, 3.34, 3.65, 4.04, 4.3, 4.9, 6.9, 8.67, 10.16, 11.24, 12.04,
  12.54, 12.93, 13.11, 12.95, 12.21, 10.69, 9.06, 7.92, 6.55, 5.38, 4.31, 3.6, 3.25, 3.24, 3.27,
  3.36, 3.21, 4.29, 6.54, 8.99, 10.6, 11.9, 12.96, 13.59, 13.86, 13.33, 12.35, 11.32, 10.26, 9.58,
  8.72, 8.35, 7.16, 4.92, 3.75, 3.41, 3.29, 3.3, 3.34, 3.44, 3.4, 4.51, 6.94, 9.65, 11.42, 12.59,
  13.61, 14.4, 14.84, 14.84, 14.48, 13.33, 11.77, 10.6, 9.49, 8.98, 7.68, 5.26, 4.02, 3.65, 3.58,
  3.56, 3.6, 3.71, 3.91, 4.91, 7.45, 10.32, 12.56, 14.28, 15.52, 16.17, 16.34, 15.99, 15.47, 14.27,
  12.5, 11.3, 10.05, 9.43, 8.28, 5.84, 4.44, 4.0, 3.76, 3.64, 3.61, 3.71, 3.92, 4.94, 7.55, 10.61,
  12.7, 13.83, 14.38, 14.26, 14.45, 14.57, 13.9, 12.7, 11.23, 10.31, 9.37, 8.88, 7.53, 5.13, 3.83,
  3.43, 3.36, 3.36, 3.38, 3.47, 3.27, 4.55, 6.9, 9.53, 11.28, 12.54, 13.38, 13.7, 13.99, 14.09,
  13.84, 12.77, 11.23, 10.19, 9.16, 8.69, 7.43, 5.06, 3.83, 3.52, 3.66, 3.4, 3.45, 3.89, 3.83, 4.51,
  6.34, 9.03, 10.93, 12.1, 12.98, 13.7, 14.16, 14.46, 14.27, 13.78, 13.34, 12.79, 12.17, 11.55,
  9.52, 6.79, 4.81, 3.83, 3.5, 3.33, 3.34, 3.65, 4.04, 4.3, 4.9, 6.9, 8.67, 10.16, 11.24, 12.04,
  12.54, 12.93, 13.11, 12.95, 12.21, 10.69, 9.06, 7.92, 6.55, 5.38, 4.31, 3.6, 3.25, 3.24, 3.27,
  3.36, 3.21, 4.29, 6.54, 8.99, 10.6, 11.9, 12.96, 13.59, 13.86, 13.33, 12.35, 11.32, 10.26, 9.58,
  8.72, 8.35, 7.16, 4.92, 3.75, 3.41, 3.29, 3.3, 3.34, 3.44, 3.4, 4.51, 6.94, 9.65, 11.42, 12.59,
  13.61, 14.4, 14.84, 14.84, 14.48, 13.33, 11.77, 10.6, 9.49, 8.98, 7.68, 5.26, 4.02, 3.65, 3.58,
  3.56, 3.6, 3.71, 3.91, 4.91, 7.45, 10.32, 12.56, 14.28, 15.52, 16.17, 16.34, 15.99, 15.47, 14.27,
  12.5, 11.3, 10.05, 9.43, 8.28, 5.84, 4.44, 4.0, 3.76, 3.64, 3.61, 3.71, 3.92, 4.94, 7.55, 10.61,
  12.7, 13.83, 14.38, 14.26, 14.45, 14.57, 13.9, 12.7, 11.23, 10.31, 9.37, 8.88, 7.53, 5.13, 3.83,
  3.43, 3.36, 3.36, 3.38, 3.47, 3.27, 4.55, 6.9, 9.53, 11.28, 12.54, 13.38, 13.7, 13.99, 14.09,
  13.84, 12.77, 11.23, 10.19, 9.16, 8.69, 7.43, 5.06, 3.83, 3.52, 3.66, 3.4, 3.45, 3.89, 3.83, 4.51,
  6.34, 9.03, 10.93, 12.1, 12.98, 13.7, 14.16, 14.46, 14.27, 13.78, 13.34, 12.79, 12.17, 11.55,
  9.52, 6.79, 4.81, 3.83, 3.5, 3.33, 3.34, 3.65, 4.04, 4.3, 4.9, 6.9, 8.67, 10.16, 11.24, 12.04,
  12.54, 12.93, 13.11, 12.95, 12.21, 10.69, 9.06, 7.92, 6.55, 5.38, 4.31, 3.6, 3.25, 3.24, 3.27,
  3.36, 3.21, 4.29, 6.54, 8.99, 10.6, 11.9, 12.96, 13.59, 13.86, 13.33, 12.35, 11.32, 10.26, 9.58,
  8.72, 8.35, 7.16, 4.92, 3.75, 3.41, 3.29, 3.3, 3.34, 3.44, 3.4, 4.51, 6.94, 9.65, 11.42, 12.59,
  13.61, 14.4, 14.84, 14.84, 14.48, 13.33, 11.77, 10.6, 9.49, 8.98, 7.68, 5.26, 4.02, 3.65, 3.58,
  3.56, 3.6, 3.71, 3.91, 4.91, 7.45, 10.32, 12.56, 14.28, 15.52, 16.17, 16.34, 15.99, 15.47, 14.27,
  12.5, 11.3, 10.05, 9.43, 8.28, 5.84, 4.44, 4.0, 3.35, 3.3, 3.27, 3.34, 3.38, 4.32, 6.38, 8.76,
  10.19, 11.15, 11.91, 12.45, 12.71, 12.47, 12.04, 11.21, 10.0, 9.17, 8.65, 8.37, 7.18, 4.96, 3.71,
  3.35, 3.28, 3.31, 3.36, 3.49, 3.7, 4.52, 6.73, 9.16, 10.76, 11.99, 12.95, 13.63, 14.01, 14.03,
  13.51, 12.3, 10.84, 9.86, 9.1, 8.6, 7.31, 5.07, 3.88, 3.53, 3.75, 3.49, 3.6, 4.04, 4.12, 4.56,
  6.26, 8.95, 10.94, 12.22, 13.11, 13.66, 14.17, 14.48, 14.63, 14.64, 14.37, 13.52, 12.64, 11.94,
  9.87, 7.09, 5.0, 3.91, 3.49, 3.31, 3.33, 3.68, 4.32, 4.36, 5.23, 6.93, 8.71, 10.14, 11.3, 12.2,
  12.62, 12.72, 12.51, 12.19, 11.42, 9.94, 8.65, 7.71, 6.39, 5.31, 4.26, 3.53, 3.18, 3.18, 3.2,
  3.27, 3.4, 4.08, 6.1, 8.38, 9.86, 10.71, 11.41, 11.94, 12.29, 12.14, 11.54, 10.63, 9.39, 8.55,
  8.03, 7.79, 6.61, 4.53, 3.42, 3.14, 3.06, 3.07, 3.1, 3.2, 3.35, 4.0, 5.94, 8.18, 9.73, 10.77,
  11.55, 12.02, 12.26, 12.06, 11.64, 10.93, 9.77, 8.93, 8.28, 7.96, 6.79, 4.7, 3.58, 3.29, 3.21,
  3.22, 3.25, 3.35, 3.42, 4.31, 6.39, 8.78, 10.58, 11.78, 12.8, 13.49, 13.97, 14.09, 13.66, 12.51,
  10.98, 9.85, 9.05, 8.71, 7.48, 5.2, 3.92, 3.54, 3.35, 3.3, 3.27, 3.34, 3.38, 4.32, 6.38, 8.76,
  10.19, 11.15, 11.91, 12.45, 12.71, 12.47, 12.04, 11.21, 10.0, 9.17, 8.65, 8.37, 7.18, 4.96, 3.71,
  3.35, 3.28, 3.31, 3.36, 3.49, 3.7, 4.52, 6.73, 9.16, 10.76, 11.99, 12.95, 13.63, 14.01, 14.03,
  13.51, 12.3, 10.84, 9.86, 9.1, 8.6, 7.31, 5.07, 3.88, 3.53, 3.75, 3.49, 3.6, 4.04, 4.12, 4.56,
  6.26, 8.95, 10.94, 12.22, 13.11, 13.66, 14.17, 14.48, 14.63, 14.64, 14.37, 13.52, 12.64, 11.94,
  9.87, 7.09, 5.0, 3.91, 3.49, 3.31, 3.33, 3.68, 4.32, 4.36, 5.23, 6.93, 8.71, 10.14, 11.3, 12.2,
  12.62, 12.72, 12.51, 12.19, 11.42, 9.94, 8.65, 7.71, 6.39, 5.31, 4.26, 3.53, 3.18, 3.18, 3.2,
  3.27, 3.4, 4.08, 6.1, 8.38, 9.86, 10.71, 11.41, 11.94, 12.29, 12.14, 11.54, 10.63, 9.39, 8.55,
  8.03, 7.79, 6.61, 4.53, 3.42, 3.14, 3.06, 3.07, 3.1, 3.2, 3.35, 4.0, 5.94, 8.18, 9.73, 10.77,
  11.55, 12.02, 12.26, 12.06, 11.64, 10.93, 9.77, 8.93, 8.28, 7.96, 6.79, 4.7, 3.58, 3.29, 3.21,
  3.22, 3.25, 3.35, 3.42, 4.31, 6.39, 8.78, 10.58, 11.78, 12.8, 13.49, 13.97, 14.09, 13.66, 12.51,
  10.98, 9.85, 9.05, 8.71, 7.48, 5.2, 3.92, 3.54, 3.35, 3.3, 3.27, 3.34, 3.38, 4.32, 6.38, 8.76,
  10.19, 11.15, 11.91, 12.45, 12.71, 12.47, 12.04, 11.21, 10.0, 9.17, 8.65, 8.37, 7.18, 4.96, 3.71,
  3.35, 3.28, 3.31, 3.36, 3.49, 3.7, 4.52, 6.73, 9.16, 10.76, 11.99, 12.95, 13.63, 14.01, 14.03,
  13.51, 12.3, 10.84, 9.86, 9.1, 8.6, 7.31, 5.07, 3.88, 3.53, 3.75, 3.49, 3.6, 4.04, 4.12, 4.56,
  6.26, 8.95, 10.94, 12.22, 13.11, 13.66, 14.17, 14.48, 14.63, 14.64, 14.37, 13.52, 12.64, 11.94,
  9.87, 7.09, 5.0, 3.91, 3.49, 3.31, 3.33, 3.68, 4.32, 4.36, 5.23, 6.93, 8.71, 10.14, 11.3, 12.2,
  12.62, 12.72, 12.51, 12.19, 11.42, 9.94, 8.65, 7.71, 6.39, 5.31, 4.26, 3.53, 3.18, 3.18, 3.2,
  3.27, 3.4, 4.08, 6.1, 8.38, 9.86, 10.71, 11.41, 11.94, 12.29, 12.14, 11.54, 10.63, 9.39, 8.55,
  8.03, 7.79, 6.61, 4.53, 3.42, 3.14, 3.06, 3.07, 3.1, 3.2, 3.35, 4.0, 5.94, 8.18, 9.73, 10.77,
  11.55, 12.02, 12.26, 12.06, 11.64, 10.93, 9.77, 8.93, 8.28, 7.96, 6.79, 4.7, 3.58, 3.29, 3.21,
  3.22, 3.25, 3.35, 3.42, 4.31, 6.39, 8.78, 10.58, 11.78, 12.8, 13.49, 13.97, 14.09, 13.66, 12.51,
  10.98, 9.85, 9.05, 8.71, 7.48, 5.2, 3.92, 3.54, 3.35, 3.3, 3.27, 3.34, 3.38, 4.32, 6.38, 8.76,
  10.19, 11.15, 11.91, 12.45, 12.71, 12.47, 12.04, 11.21, 10.0, 9.17, 8.65, 8.37, 7.18, 4.96, 3.71,
  3.35, 3.28, 3.31, 3.36, 3.49, 3.7, 4.52, 6.73, 9.16, 10.76, 11.99, 12.95, 13.63, 14.01, 14.03,
  13.51, 12.3, 10.84, 9.86, 9.1, 8.6, 7.31, 5.07, 3.88, 3.53, 3.75, 3.49, 3.6, 4.04, 4.12, 4.56,
  6.26, 8.95, 10.94, 12.22, 13.11, 13.66, 14.17, 14.48, 14.63, 14.64, 14.37, 13.52, 12.64, 11.94,
  9.87, 7.09, 5.0, 3.91, 3.49, 3.31, 3.33, 3.68, 4.32, 4.36, 5.23, 6.93, 8.71, 10.14, 11.3, 12.2,
  12.62, 12.72, 12.51, 12.19, 11.42, 9.94, 8.65, 7.71, 6.39, 5.31, 4.26, 3.53, 3.18, 3.18, 3.2,
  3.27, 3.4, 4.08, 6.1, 8.38, 9.86, 10.71, 11.41, 11.94, 12.29, 12.14, 11.54, 10.63, 9.39, 8.55,
  8.03, 7.79, 6.61, 4.53, 3.42, 3.14, 3.06, 3.07, 3.1, 3.2, 3.35, 4.0, 5.94, 8.18, 9.73, 10.77,
  11.55, 12.02, 12.26, 12.06, 11.64, 10.93, 9.77, 8.93, 8.28, 7.96, 6.79, 4.7, 3.58, 3.29, 3.21,
  3.22, 3.25, 3.35, 3.42, 4.31, 6.39, 8.78, 10.58, 11.78, 12.8, 13.49, 13.97, 14.09, 13.66, 12.51,
  10.98, 9.85, 9.05, 8.71, 7.48, 5.2, 3.92, 3.54, 3.35, 3.3, 3.27, 3.34, 3.38, 4.32, 6.38, 8.76,
  10.19, 11.15, 11.91, 12.45, 12.71, 12.47, 12.04, 11.21, 10.0, 9.17, 8.65, 8.37, 7.18, 4.96, 3.71,
  3.35, 3.28, 3.31, 3.36, 3.49, 3.7, 4.52, 6.73, 9.16, 10.76, 11.99, 12.95, 13.63, 14.01, 14.03,
  13.51, 12.3, 10.84, 9.86, 9.1, 8.6, 7.31, 5.07, 3.88, 3.53, 3.75, 3.49, 3.6, 4.04, 4.12, 4.56,
  6.26, 8.95, 10.94, 12.22, 13.11, 13.66, 14.17, 14.48, 14.63, 14.64, 14.37, 13.52, 12.64, 11.94,
  9.87, 7.09, 5.0, 3.91, 3.18, 3.06, 3.1, 3.41, 4.04, 4.3, 4.53, 5.69, 6.92, 7.9, 8.74, 9.41, 9.87,
  10.12, 10.15, 10.0, 9.44, 8.5, 7.93, 6.85, 5.75, 4.82, 3.91, 3.33, 3.07, 3.11, 3.18, 3.3, 3.63,
  4.29, 5.9, 7.96, 9.24, 10.2, 11.07, 11.64, 11.95, 11.76, 11.28, 10.45, 9.22, 8.43, 8.24, 7.66,
  6.59, 4.58, 3.49, 3.21, 3.13, 3.14, 3.17, 3.26, 3.42, 4.33, 5.95, 8.02, 9.37, 10.34, 11.14, 11.72,
  12.08, 11.97, 11.45, 10.58, 9.32, 8.47, 8.24, 7.64, 6.54, 4.5, 3.41, 3.13, 3.06, 3.09, 3.14, 3.26,
  3.55, 4.31, 5.8, 7.85, 9.3, 10.39, 11.25, 11.76, 12.03, 11.85, 11.26, 10.32, 9.08, 8.36, 8.18,
  7.65, 6.66, 4.68, 3.55, 3.25, 3.14, 3.17, 3.22, 3.35, 3.7, 4.48, 6.06, 8.01, 9.06, 9.65, 10.18,
  10.57, 10.79, 10.8, 10.58, 9.99, 8.97, 8.35, 8.24, 7.74, 6.69, 4.63, 3.47, 3.16, 3.14, 3.17, 3.21,
  3.31, 3.45, 4.54, 6.15, 8.25, 9.57, 10.46, 11.25, 11.74, 11.96, 11.8, 11.33, 10.42, 9.21, 8.54,
  8.37, 7.79, 6.66, 4.56, 3.46, 3.17, 3.25, 3.02, 3.08, 3.52, 3.6, 4.23, 5.32, 7.42, 8.74, 9.62,
  10.3, 10.71, 11.07, 11.3, 11.41, 11.34, 10.95, 10.4, 10.29, 9.55, 8.01, 5.71, 4.1, 3.37, 3.18,
  3.06, 3.1, 3.41, 4.04, 4.3, 4.53, 5.69, 6.92, 7.9, 8.74, 9.41, 9.87, 10.12, 10.15, 10.0, 9.44,
  8.5, 7.93, 6.85, 5.75, 4.82, 3.91, 3.33, 3.07, 3.11, 3.18, 3.3, 3.63, 4.29, 5.9, 7.96, 9.24, 10.2,
  11.07, 11.64, 11.95, 11.76, 11.28, 10.45, 9.22, 8.43, 8.24, 7.66, 6.59, 4.58, 3.49, 3.21, 3.13,
  3.14, 3.17, 3.26, 3.42, 4.33, 5.95, 8.02, 9.37, 10.34, 11.14, 11.72, 12.08, 11.97, 11.45, 10.58,
  9.32, 8.47, 8.24, 7.64, 6.54, 4.5, 3.41, 3.13, 3.06, 3.09, 3.14, 3.26, 3.55, 4.31, 5.8, 7.85, 9.3,
  10.39, 11.25, 11.76, 12.03, 11.85, 11.26, 10.32, 9.08, 8.36, 8.18, 7.65, 6.66, 4.68, 3.55, 3.25,
  3.14, 3.17, 3.22, 3.35, 3.7, 4.48, 6.06, 8.01, 9.06, 9.65, 10.18, 10.57, 10.79, 10.8, 10.58, 9.99,
  8.97, 8.35, 8.24, 7.74, 6.69, 4.63, 3.47, 3.16, 3.14, 3.17, 3.21, 3.31, 3.45, 4.54, 6.15, 8.25,
  9.57, 10.46, 11.25, 11.74, 11.96, 11.8, 11.33, 10.42, 9.21, 8.54, 8.37, 7.79, 6.66, 4.56, 3.46,
  3.17, 3.25, 3.02, 3.08, 3.52, 3.6, 4.23, 5.32, 7.42, 8.74, 9.62, 10.3, 10.71, 11.07, 11.3, 11.41,
  11.34, 10.95, 10.4, 10.29, 9.55, 8.01, 5.71, 4.1, 3.37, 3.18, 3.06, 3.1, 3.41, 4.04, 4.3, 4.53,
  5.69, 6.92, 7.9, 8.74, 9.41, 9.87, 10.12, 10.15, 10.0, 9.44, 8.5, 7.93, 6.85, 5.75, 4.82, 3.91,
  3.33, 3.07, 3.11, 3.18, 3.3, 3.63, 4.29, 5.9, 7.96, 9.24, 10.2, 11.07, 11.64, 11.95, 11.76, 11.28,
  10.45, 9.22, 8.43, 8.24, 7.66, 6.59, 4.58, 3.49, 3.21, 3.13, 3.14, 3.17, 3.26, 3.42, 4.33, 5.95,
  8.02, 9.37, 10.34, 11.14, 11.72, 12.08, 11.97, 11.45, 10.58, 9.32, 8.47, 8.24, 7.64, 6.54, 4.5,
  3.41, 3.13, 3.06, 3.09, 3.14, 3.26, 3.55, 4.31, 5.8, 7.85, 9.3, 10.39, 11.25, 11.76, 12.03, 11.85,
  11.26, 10.32, 9.08, 8.36, 8.18, 7.65, 6.66, 4.68, 3.55, 3.25, 3.14, 3.17, 3.22, 3.35, 3.7, 4.48,
  6.06, 8.01, 9.06, 9.65, 10.18, 10.57, 10.79, 10.8, 10.58, 9.99, 8.97, 8.35, 8.24, 7.74, 6.69,
  4.63, 3.47, 3.16, 3.14, 3.17, 3.21, 3.31, 3.45, 4.54, 6.15, 8.25, 9.57, 10.46, 11.25, 11.74,
  11.96, 11.8, 11.33, 10.42, 9.21, 8.54, 8.37, 7.79, 6.66, 4.56, 3.46, 3.17, 3.25, 3.02, 3.08, 3.52,
  3.6, 4.23, 5.32, 7.42, 8.74, 9.62, 10.3, 10.71, 11.07, 11.3, 11.41, 11.34, 10.95, 10.4, 10.29,
  9.55, 8.01, 5.71, 4.1, 3.37, 3.18, 3.06, 3.1, 3.41, 4.04, 4.3, 4.53, 5.69, 6.92, 7.9, 8.74, 9.41,
  9.87, 10.12, 10.15, 10.0, 9.44, 8.5, 7.93, 6.85, 5.75, 4.82, 3.91, 3.33, 3.07, 3.11, 3.18, 3.3,
  3.63, 4.29, 5.9, 7.96, 9.24, 10.2, 11.07, 11.64, 11.95, 11.76, 11.28, 10.45, 9.22, 8.43, 8.24,
  7.66, 6.59, 4.58, 3.49, 3.21, 3.13, 3.14, 3.17, 3.26, 3.42, 4.33, 5.95, 8.02, 9.37, 10.34, 11.14,
  11.72, 12.08, 11.97, 11.45, 10.58, 9.32, 8.47, 8.24, 7.64, 6.54, 4.5, 3.41, 3.13, 3.06, 3.09,
  3.14, 3.26, 3.55, 4.31, 5.8, 7.85, 9.3, 10.39, 11.25, 11.76, 12.03, 11.85, 11.26, 10.32, 9.08,
  8.36, 8.18, 7.65, 6.66, 4.68, 3.55, 3.25, 3.14, 3.17, 3.22, 3.35, 3.7, 4.48, 6.06, 8.01, 9.06,
  9.65, 10.18, 10.57, 10.79, 10.8, 10.58, 9.99, 8.97, 8.35, 8.24, 7.74, 6.69, 4.63, 3.47, 3.16,
  3.14, 3.17, 3.21, 3.31, 3.45, 4.54, 6.15, 8.25, 9.57, 10.46, 11.25, 11.74, 11.96, 11.8, 11.33,
  10.42, 9.21, 8.54, 8.37, 7.79, 6.66, 4.56, 3.46, 3.17, 3.25, 3.02, 3.08, 3.52, 3.6, 4.23, 5.32,
  7.42, 8.74, 9.62, 10.3, 10.71, 11.07, 11.3, 11.41, 11.34, 10.95, 10.4, 10.29, 9.55, 8.01, 5.71,
  4.1, 3.37, 3.18, 3.06, 3.1, 3.41, 4.04, 4.3, 4.53, 5.69, 6.92, 7.9, 8.74, 9.41, 9.87, 10.12,
  10.15, 10.0, 9.44, 8.5, 7.93, 6.85, 5.75, 4.82, 3.91, 3.33, 3.07, 3.11, 3.18, 3.3, 3.63, 4.29,
  5.9, 7.96, 9.24, 10.2, 11.07, 11.64, 11.95, 11.76, 11.28, 10.45, 9.22, 8.43, 8.24, 7.66, 6.59,
  4.58, 3.49, 3.21, 3.05, 3.11, 3.18, 3.29, 3.52, 4.43, 5.57, 7.17, 7.97, 8.43, 8.83, 9.17, 9.42,
  9.37, 9.04, 8.53, 7.72, 7.68, 7.35, 6.94, 6.03, 4.26, 3.31, 3.1, 3.06, 3.12, 3.19, 3.31, 3.53,
  4.41, 5.49, 7.04, 7.9, 8.38, 8.79, 9.12, 9.34, 9.24, 8.84, 8.25, 7.52, 7.53, 7.25, 6.9, 6.1, 4.37,
  3.34, 3.08, 2.97, 3.01, 3.05, 3.14, 3.35, 4.3, 5.48, 7.18, 8.02, 8.45, 8.75, 8.99, 9.18, 9.14,
  8.87, 8.35, 7.66, 7.68, 7.44, 7.06, 6.15, 4.33, 3.29, 3.04, 3.04, 3.09, 3.16, 3.28, 3.51, 4.41,
  5.53, 7.06, 7.8, 8.19, 8.58, 8.87, 9.02, 8.93, 8.63, 8.17, 7.48, 7.52, 7.23, 6.85, 5.99, 4.26,
  3.32, 3.12, 3.19, 3.03, 3.14, 3.53, 3.71, 4.32, 4.91, 6.53, 7.23, 7.58, 7.85, 8.0, 8.18, 8.34,
  8.5, 8.57, 8.52, 8.82, 8.69, 8.28, 7.16, 5.28, 3.96, 3.39, 3.26, 3.19, 3.23, 3.53, 4.14, 4.7,
  4.75, 5.57, 6.54, 7.06, 7.5, 7.82, 7.97, 8.07, 8.08, 8.02, 7.7, 7.68, 6.99, 6.2, 5.32, 4.58, 3.81,
  3.32, 3.06, 3.11, 3.18, 3.29, 3.53, 4.4, 5.54, 7.11, 7.89, 8.32, 8.74, 9.05, 9.26, 9.2, 8.9, 8.39,
  7.63, 7.64, 7.33, 6.95, 6.06, 4.29, 3.33, 3.1, 3.05, 3.11, 3.18, 3.29, 3.52, 4.43, 5.57, 7.17,
  7.97, 8.43, 8.83, 9.17, 9.42, 9.37, 9.04, 8.53, 7.72, 7.68, 7.35, 6.94, 6.03, 4.26, 3.31, 3.1,
  3.06, 3.12, 3.19, 3.31, 3.53, 4.41, 5.49, 7.04, 7.9, 8.38, 8.79, 9.12, 9.34, 9.24, 8.84, 8.25,
  7.52, 7.53, 7.25, 6.9, 6.1, 4.37, 3.34, 3.08, 2.97, 3.01, 3.05, 3.14, 3.35, 4.3, 5.48, 7.18, 8.02,
  8.45, 8.75, 8.99, 9.18, 9.14, 8.87, 8.35, 7.66, 7.68, 7.44, 7.06, 6.15, 4.33, 3.29, 3.04, 3.04,
  3.09, 3.16, 3.28, 3.51, 4.41, 5.53, 7.06, 7.8, 8.19, 8.58, 8.87, 9.02, 8.93, 8.63, 8.17, 7.48,
  7.52, 7.23, 6.85, 5.99, 4.26, 3.32, 3.12, 3.19, 3.03, 3.14, 3.53, 3.71, 4.32, 4.91, 6.53, 7.23,
  7.58, 7.85, 8.0, 8.18, 8.34, 8.5, 8.57, 8.52, 8.82, 8.69, 8.28, 7.16, 5.28, 3.96, 3.39, 3.26,
  3.19, 3.23, 3.53, 4.14, 4.7, 4.75, 5.57, 6.54, 7.06, 7.5, 7.82, 7.97, 8.07, 8.08, 8.02, 7.7, 7.68,
  6.99, 6.2, 5.32, 4.58, 3.81, 3.32, 3.06, 3.11, 3.18, 3.29, 3.53, 4.4, 5.54, 7.11, 7.89, 8.32,
  8.74, 9.05, 9.26, 9.2, 8.9, 8.39, 7.63, 7.64, 7.33, 6.95, 6.06, 4.29, 3.33, 3.1, 3.05, 3.11, 3.18,
  3.29, 3.52, 4.43, 5.57, 7.17, 7.97, 8.43, 8.83, 9.17, 9.42, 9.37, 9.04, 8.53, 7.72, 7.68, 7.35,
  6.94, 6.03, 4.26, 3.31, 3.1, 3.06, 3.12, 3.19, 3.31, 3.53, 4.41, 5.49, 7.04, 7.9, 8.38, 8.79,
  9.12, 9.34, 9.24, 8.84, 8.25, 7.52, 7.53, 7.25, 6.9, 6.1, 4.37, 3.34, 3.08, 2.97, 3.01, 3.05,
  3.14, 3.35, 4.3, 5.48, 7.18, 8.02, 8.45, 8.75, 8.99, 9.18, 9.14, 8.87, 8.35, 7.66, 7.68, 7.44,
  7.06, 6.15, 4.33, 3.29, 3.04, 3.04, 3.09, 3.16, 3.28, 3.51, 4.41, 5.53, 7.06, 7.8, 8.19, 8.58,
  8.87, 9.02, 8.93, 8.63, 8.17, 7.48, 7.52, 7.23, 6.85, 5.99, 4.26, 3.32, 3.12, 3.19, 3.03, 3.14,
  3.53, 3.71, 4.32, 4.91, 6.53, 7.23, 7.58, 7.85, 8.0, 8.18, 8.34, 8.5, 8.57, 8.52, 8.82, 8.69,
  8.28, 7.16, 5.28, 3.96, 3.39, 3.26, 3.19, 3.23, 3.53, 4.14, 4.7, 4.75, 5.57, 6.54, 7.06, 7.5,
  7.82, 7.97, 8.07, 8.08, 8.02, 7.7, 7.68, 6.99, 6.2, 5.32, 4.58, 3.81, 3.32, 3.06, 3.11, 3.18,
  3.29, 3.53, 4.4, 5.54, 7.11, 7.89, 8.32, 8.74, 9.05, 9.26, 9.2, 8.9, 8.39, 7.63, 7.64, 7.33, 6.95,
  6.06, 4.29, 3.33, 3.1, 3.05, 3.11, 3.18, 3.29, 3.52, 4.43, 5.57, 7.17, 7.97, 8.43, 8.83, 9.17,
  9.42, 9.37, 9.04, 8.53, 7.72, 7.68, 7.35, 6.94, 6.03, 4.26, 3.31, 3.1, 3.06, 3.12, 3.19, 3.31,
  3.53, 4.41, 5.49, 7.04, 7.9, 8.38, 8.79, 9.12, 9.34, 9.24, 8.84, 8.25, 7.52, 7.53, 7.25, 6.9, 6.1,
  4.37, 3.34, 3.08, 2.97, 3.01, 3.05, 3.14, 3.35, 4.3, 5.48, 7.18, 8.02, 8.45, 8.75, 8.99, 9.18,
  9.14, 8.87, 8.35, 7.66, 7.68, 7.44, 7.06, 6.15, 4.33, 3.29, 3.04, 3.04, 3.09, 3.16, 3.28, 3.51,
  4.41, 5.53, 7.06, 7.8, 8.19, 8.58, 8.87, 9.02, 8.93, 8.63, 8.17, 7.48, 7.52, 7.23, 6.85, 5.99,
  4.26, 3.32, 3.12, 3.19, 3.03, 3.14, 3.53, 3.71, 4.32, 4.91, 6.53, 7.23, 7.58, 7.85, 8.0, 8.18,
  8.34, 8.5, 8.57, 8.52, 8.82, 8.69, 8.28, 7.16, 5.28, 3.96, 3.39, 3.26, 3.19, 3.23, 3.53, 4.14,
  4.7, 4.75, 5.57, 6.54, 7.06, 7.5, 7.82, 7.97, 8.07, 8.08, 8.02, 7.7, 7.68, 6.99, 6.2, 5.32, 4.58,
  3.81, 3.32, 3.06, 3.11, 3.18, 3.29, 3.53, 4.4, 5.54, 7.11, 7.89, 8.32, 8.74, 9.05, 9.26, 9.2, 8.9,
  8.39, 7.63, 7.64, 7.33, 6.95, 6.06, 4.29, 3.33, 3.1, 3.05, 3.11, 3.18, 3.29, 3.52, 4.43, 5.57,
  7.17, 7.97, 8.43, 8.83, 9.17, 9.42, 9.37, 9.04, 8.53, 7.72, 7.68, 7.35, 6.94, 6.03, 4.26, 3.31,
  3.1, 3.06, 3.12, 3.19, 3.31, 3.53, 4.41, 5.49, 7.04, 7.9, 8.38, 8.79, 9.12, 9.34, 9.24, 8.84,
  8.25, 7.52, 7.53, 7.25, 6.9, 6.1, 4.37, 3.34, 3.08, 2.97, 3.01, 3.05, 3.14, 3.35, 4.3, 5.48, 7.18,
  8.02, 8.45, 8.75, 8.99, 9.18, 9.14, 8.87, 8.35, 7.66, 7.68, 7.44, 7.06, 6.15, 4.33, 3.29, 3.04,
  3.27, 3.26, 3.34, 3.41, 3.55, 3.91, 4.77, 5.93, 7.28, 7.84, 8.11, 8.33, 8.5, 8.57, 8.44, 8.1, 8.0,
  7.74, 7.38, 7.08, 6.64, 5.65, 4.12, 3.35, 3.36, 3.17, 3.09, 3.09, 3.26, 3.6, 3.89, 4.39, 5.25,
  6.69, 7.32, 7.72, 8.0, 8.14, 8.29, 8.41, 8.45, 8.7, 8.92, 8.74, 8.53, 7.96, 6.66, 4.9, 3.78, 3.29,
  3.22, 3.17, 3.27, 3.65, 4.26, 4.58, 4.85, 5.82, 6.72, 7.29, 7.71, 7.97, 8.05, 8.07, 8.0, 8.13,
  8.09, 7.54, 6.86, 6.09, 5.28, 4.53, 3.83, 3.26, 3.22, 3.29, 3.37, 3.49, 3.78, 4.37, 5.62, 7.15,
  7.78, 8.04, 8.27, 8.44, 8.53, 8.44, 8.14, 8.11, 7.84, 7.49, 7.21, 6.88, 6.07, 4.41, 3.52, 3.32,
  3.3, 3.36, 3.44, 3.55, 3.83, 4.49, 5.7, 7.25, 7.91, 8.24, 8.52, 8.7, 8.78, 8.62, 8.25, 8.18, 7.86,
  7.49, 7.2, 6.88, 6.05, 4.38, 3.46, 3.26, 3.25, 3.3, 3.33, 3.43, 3.65, 4.33, 5.46, 7.03, 7.75,
  8.09, 8.38, 8.58, 8.65, 8.56, 8.24, 8.24, 7.9, 7.53, 7.23, 6.92, 6.14, 4.48, 3.54, 3.2, 3.17,
  3.23, 3.3, 3.45, 3.88, 4.64, 5.85, 7.19, 7.68, 7.85, 7.99, 8.13, 8.17, 8.1, 7.88, 7.91, 7.77,
  7.49, 7.25, 6.85, 5.83, 4.27, 3.44, 3.27, 3.26, 3.34, 3.41, 3.55, 3.91, 4.77, 5.93, 7.28, 7.84,
  8.11, 8.33, 8.5, 8.57, 8.44, 8.1, 8.0, 7.74, 7.38, 7.08, 6.64, 5.65, 4.12, 3.35, 3.36, 3.17, 3.09,
  3.26, 3.6, 3.89, 4.39, 5.25, 6.69, 7.32, 7.72, 8.0, 8.14, 8.29, 8.41, 8.45, 8.7, 8.92, 8.74, 8.53,
  7.96, 6.66, 4.9, 3.78, 3.29, 3.22, 3.17, 3.27, 3.65, 4.26, 4.58, 4.85, 5.82, 6.72, 7.29, 7.71,
  7.97, 8.05, 8.07, 8.0, 8.13, 8.09, 7.54, 6.86, 6.09, 5.28, 4.53, 3.83, 3.26, 3.22, 3.29, 3.37,
  3.49, 3.78, 4.37, 5.62, 7.15, 7.78, 8.04, 8.27, 8.44, 8.53, 8.44, 8.14, 8.11, 7.84, 7.49, 7.21,
  6.88, 6.07, 4.41, 3.52, 3.32, 3.3, 3.36, 3.44, 3.55, 3.83, 4.49, 5.7, 7.25, 7.91, 8.24, 8.52, 8.7,
  8.78, 8.62, 8.25, 8.18, 7.86, 7.49, 7.2, 6.88, 6.05, 4.38, 3.46, 3.26, 3.25, 3.3, 3.33, 3.43,
  3.65, 4.33, 5.46, 7.03, 7.75, 8.09, 8.38, 8.58, 8.65, 8.56, 8.24, 8.24, 7.9, 7.53, 7.23, 6.92,
  6.14, 4.48, 3.54, 3.2, 3.17, 3.23, 3.3, 3.45, 3.88, 4.64, 5.85, 7.19, 7.68, 7.85, 7.99, 8.13,
  8.17, 8.1, 7.88, 7.91, 7.77, 7.49, 7.25, 6.85, 5.83, 4.27, 3.44, 3.27, 3.26, 3.34, 3.41, 3.55,
  3.91, 4.77, 5.93, 7.28, 7.84, 8.11, 8.33, 8.5, 8.57, 8.44, 8.1, 8.0, 7.74, 7.38, 7.08, 6.64, 5.65,
  4.12, 3.35, 3.36, 3.17, 3.09, 3.26, 3.6, 3.89, 4.39, 5.25, 6.69, 7.32, 7.72, 8.0, 8.14, 8.29,
  8.41, 8.45, 8.7, 8.92, 8.74, 8.53, 7.96, 6.66, 4.9, 3.78, 3.29, 3.22, 3.17, 3.27, 3.65, 4.26,
  4.58, 4.85, 5.82, 6.72, 7.29, 7.71, 7.97, 8.05, 8.07, 8.0, 8.13, 8.09, 7.54, 6.86, 6.09, 5.28,
  4.53, 3.83, 3.26, 3.22, 3.29, 3.37, 3.49, 3.78, 4.37, 5.62, 7.15, 7.78, 8.04, 8.27, 8.44, 8.53,
  8.44, 8.14, 8.11, 7.84, 7.49, 7.21, 6.88, 6.07, 4.41, 3.52, 3.32, 3.3, 3.36, 3.44, 3.55, 3.83,
  4.49, 5.7, 7.25, 7.91, 8.24, 8.52, 8.7, 8.78, 8.62, 8.25, 8.18, 7.86, 7.49, 7.2, 6.88, 6.05, 4.38,
  3.46, 3.26, 3.25, 3.3, 3.33, 3.43, 3.65, 4.33, 5.46, 7.03, 7.75, 8.09, 8.38, 8.58, 8.65, 8.56,
  8.24, 8.24, 7.9, 7.53, 7.23, 6.92, 6.14, 4.48, 3.54, 3.2, 3.17, 3.23, 3.3, 3.45, 3.88, 4.64, 5.85,
  7.19, 7.68, 7.85, 7.99, 8.13, 8.17, 8.1, 7.88, 7.91, 7.77, 7.49, 7.25, 6.85, 5.83, 4.27, 3.44,
  3.27, 3.26, 3.34, 3.41, 3.55, 3.91, 4.77, 5.93, 7.28, 7.84, 8.11, 8.33, 8.5, 8.57, 8.44, 8.1, 8.0,
  7.74, 7.38, 7.08, 6.64, 5.65, 4.12, 3.35, 3.36, 3.17, 3.09, 3.26, 3.6, 3.89, 4.39, 5.25, 6.69,
  7.32, 7.72, 8.0, 8.14, 8.29, 8.41, 8.45, 8.7, 8.92, 8.74, 8.53, 7.96, 6.66, 4.9, 3.78, 3.29, 3.22,
  3.17, 3.27, 3.65, 4.26, 4.58, 4.85, 5.82, 6.72, 7.29, 7.71, 7.97, 8.05, 8.07, 8.0, 8.13, 8.09,
  7.54, 6.86, 6.09, 5.28, 4.53, 3.83, 3.26, 3.22, 3.29, 3.37, 3.49, 3.78, 4.37, 5.62, 7.15, 7.78,
  8.04, 8.27, 8.44, 8.53, 8.44, 8.14, 8.11, 7.84, 7.49, 7.21, 6.88, 6.07, 4.41, 3.52, 3.32, 3.3,
  3.36, 3.44, 3.55, 3.83, 4.49, 5.7, 7.25, 7.91, 8.24, 8.52, 8.7, 8.78, 8.62, 8.25, 8.18, 7.86,
  7.49, 7.2, 6.88, 6.05, 4.38, 3.46, 3.26, 3.25, 3.3, 3.33, 3.43, 3.65, 4.33, 5.46, 7.03, 7.75,
  8.09, 8.38, 8.58, 8.65, 8.56, 8.24, 8.24, 7.9, 7.53, 7.23, 6.92, 6.14, 4.48, 3.54, 3.2, 3.17,
  3.23, 3.3, 3.45, 3.88, 4.64, 5.85, 7.19, 7.68, 7.85, 7.99, 8.13, 8.17, 8.1, 7.88, 7.91, 7.77,
  7.49, 7.25, 6.85, 5.83, 4.27, 3.44, 3.27, 3.26, 3.34, 3.41, 3.55, 3.91, 4.77, 5.93, 7.28, 7.84,
  8.11, 8.33, 8.5, 8.57, 8.44, 8.1, 8.0, 7.74, 7.38, 7.08, 6.64, 5.65, 4.12, 3.35, 3.36, 3.17, 3.09,
  3.26, 3.6, 3.89, 4.39, 5.25, 6.69, 7.32, 7.72, 8.0, 8.14, 8.29, 8.41, 8.45, 8.7, 8.92, 8.74, 8.53,
  7.96, 6.66, 4.9, 3.78, 3.69, 3.6, 3.54, 3.6, 3.87, 4.49, 5.06, 5.12, 5.89, 6.83, 7.3, 7.67, 7.91,
  7.97, 7.97, 7.88, 8.32, 8.26, 7.79, 7.18, 6.49, 5.67, 4.94, 4.2, 3.5, 3.48, 3.57, 3.66, 3.78,
  4.02, 4.9, 5.98, 7.38, 7.97, 8.17, 8.32, 8.4, 8.43, 8.33, 8.06, 8.23, 7.92, 7.6, 7.36, 7.08, 6.28,
  4.67, 3.81, 3.59, 3.57, 3.67, 3.76, 3.89, 4.21, 5.03, 6.06, 7.48, 8.06, 8.25, 8.4, 8.48, 8.51,
  8.44, 8.21, 8.42, 8.07, 7.74, 7.48, 7.17, 6.36, 4.78, 3.91, 3.74, 3.69, 3.76, 3.88, 4.02, 4.35,
  5.11, 6.15, 7.5, 8.13, 8.36, 8.51, 8.59, 8.62, 8.53, 8.27, 8.46, 8.11, 7.8, 7.54, 7.22, 6.47,
  4.86, 3.97, 3.68, 3.62, 3.67, 3.73, 3.84, 4.14, 4.95, 6.05, 7.4, 7.93, 8.04, 8.08, 8.14, 8.18,
  8.17, 8.05, 8.37, 8.09, 7.84, 7.63, 7.35, 6.53, 4.85, 3.88, 3.7, 3.67, 3.74, 3.86, 4.03, 4.36,
  5.08, 6.14, 7.5, 8.1, 8.3, 8.43, 8.5, 8.51, 8.42, 8.15, 8.35, 8.0, 7.67, 7.43, 7.11, 6.33, 4.73,
  3.87, 3.88, 3.69, 3.57, 3.67, 4.03, 4.25, 4.84, 5.41, 6.97, 7.58, 7.81, 7.93, 7.95, 8.0, 8.09,
  8.26, 8.9, 9.11, 8.95, 8.82, 8.45, 7.39, 5.56, 4.3, 3.69, 3.6, 3.54, 3.6, 3.87, 4.49, 5.06, 5.12,
  5.89, 6.83, 7.3, 7.67, 7.91, 7.97, 7.97, 7.88, 8.32, 8.26, 7.79, 7.18, 6.49, 5.67, 4.94, 4.2, 3.5,
  3.48, 3.57, 3.66, 3.78, 4.02, 4.9, 5.98, 7.38, 7.97, 8.17, 8.32, 8.4, 8.43, 8.33, 8.06, 8.23,
  7.92, 7.6, 7.36, 7.08, 6.28, 4.67, 3.81, 3.59, 3.57, 3.67, 3.76, 3.89, 4.21, 5.03, 6.06, 7.48,
  8.06, 8.25, 8.4, 8.48, 8.51, 8.44, 8.21, 8.42, 8.07, 7.74, 7.48, 7.17, 6.36, 4.78, 3.91, 3.74,
  3.69, 3.76, 3.88, 4.02, 4.35, 5.11, 6.15, 7.5, 8.13, 8.36, 8.51, 8.59, 8.62, 8.53, 8.27, 8.46,
  8.11, 7.8, 7.54, 7.22, 6.47, 4.86, 3.97, 3.68, 3.62, 3.67, 3.73, 3.84, 4.14, 4.95, 6.05, 7.4,
  7.93, 8.04, 8.08, 8.14, 8.18, 8.17, 8.05, 8.37, 8.09, 7.84, 7.63, 7.35, 6.53, 4.85, 3.88, 3.7,
  3.67, 3.74, 3.86, 4.03, 4.36, 5.08, 6.14, 7.5, 8.1, 8.3, 8.43, 8.5, 8.51, 8.42, 8.15, 8.35, 8.0,
  7.67, 7.43, 7.11, 6.33, 4.73, 3.87, 3.88, 3.69, 3.57, 3.67, 4.03, 4.25, 4.84, 5.41, 6.97, 7.58,
  7.81, 7.93, 7.95, 8.0, 8.09, 8.26, 8.9, 9.11, 8.95, 8.82, 8.45, 7.39, 5.56, 4.3, 3.69, 3.6, 3.54,
  3.6, 3.87, 4.49, 5.06, 5.12, 5.89, 6.83, 7.3, 7.67, 7.91, 7.97, 7.97, 7.88, 8.32, 8.26, 7.79,
  7.18, 6.49, 5.67, 4.94, 4.2, 3.5, 3.48, 3.57, 3.66, 3.78, 4.02, 4.9, 5.98, 7.38, 7.97, 8.17, 8.32,
  8.4, 8.43, 8.33, 8.06, 8.23, 7.92, 7.6, 7.36, 7.08, 6.28, 4.67, 3.81, 3.59, 3.57, 3.67, 3.76,
  3.89, 4.21, 5.03, 6.06, 7.48, 8.06, 8.25, 8.4, 8.48, 8.51, 8.44, 8.21, 8.42, 8.07, 7.74, 7.48,
  7.17, 6.36, 4.78, 3.91, 3.74, 3.69, 3.76, 3.88, 4.02, 4.35, 5.11, 6.15, 7.5, 8.13, 8.36, 8.51,
  8.59, 8.62, 8.53, 8.27, 8.46, 8.11, 7.8, 7.54, 7.22, 6.47, 4.86, 3.97, 3.68, 3.62, 3.67, 3.73,
  3.84, 4.14, 4.95, 6.05, 7.4, 7.93, 8.04, 8.08, 8.14, 8.18, 8.17, 8.05, 8.37, 8.09, 7.84, 7.63,
  7.35, 6.53, 4.85, 3.88, 3.7, 3.67, 3.74, 3.86, 4.03, 4.36, 5.08, 6.14, 7.5, 8.1, 8.3, 8.43, 8.5,
  8.51, 8.42, 8.15, 8.35, 8.0, 7.67, 7.43, 7.11, 6.33, 4.73, 3.87, 3.88, 3.69, 3.57, 3.67, 4.03,
  4.25, 4.84, 5.41, 6.97, 7.58, 7.81, 7.93, 7.95, 8.0, 8.09, 8.26, 8.9, 9.11, 8.95, 8.82, 8.45,
  7.39, 5.56, 4.3, 3.69, 3.6, 3.54, 3.6, 3.87, 4.49, 5.06, 5.12, 5.89, 6.83, 7.3, 7.67, 7.91, 7.97,
  7.97, 7.88, 8.32, 8.26, 7.79, 7.18, 6.49, 5.67, 4.94, 4.2, 3.5, 3.48, 3.57, 3.66, 3.78, 4.02, 4.9,
  5.98, 7.38, 7.97, 8.17, 8.32, 8.4, 8.43, 8.33, 8.06, 8.23, 7.92, 7.6, 7.36, 7.08, 6.28, 4.67,
  3.81, 3.59, 3.57, 3.67, 3.76, 3.89, 4.21, 5.03, 6.06, 7.48, 8.06, 8.25, 8.4, 8.48, 8.51, 8.44,
  8.21, 8.42, 8.07, 7.74, 7.48, 7.17, 6.36, 4.78, 3.91, 3.74, 3.69, 3.76, 3.88, 4.02, 4.35, 5.11,
  6.15, 7.5, 8.13, 8.36, 8.51, 8.59, 8.62, 8.53, 8.27, 8.46, 8.11, 7.8, 7.54, 7.22, 6.47, 4.86,
  3.97, 3.68, 3.62, 3.67, 3.73, 3.84, 4.14, 4.95, 6.05, 7.4, 7.93, 8.04, 8.08, 8.14, 8.18, 8.17,
  8.05, 8.37, 8.09, 7.84, 7.63, 7.35, 6.53, 4.85, 3.88, 3.7, 3.67, 3.74, 3.86, 4.03, 4.36, 5.08,
  6.14, 7.5, 8.1, 8.3, 8.43, 8.5, 8.51, 8.42, 8.15, 8.35, 8.0, 7.67, 7.43, 7.11, 6.33, 4.73, 3.87,
  3.88, 3.69, 3.57, 3.67, 4.03, 4.25, 4.84, 5.41, 6.97, 7.58, 7.81, 7.93, 7.95, 8.0, 8.09, 8.26,
  8.9, 9.11, 8.95, 8.82, 8.45, 7.39, 5.56, 4.3, 3.69, 3.6, 3.54, 3.6, 3.87, 4.49, 5.06, 5.12, 5.89,
  6.83, 7.3, 7.67, 7.91, 7.97, 7.97, 7.88, 8.32, 8.26, 7.79, 7.18, 6.49, 5.67, 4.94, 4.2, 3.5, 3.48,
  3.57, 3.66, 3.78, 4.02, 4.9, 5.98, 7.38, 7.97, 8.17, 8.32, 8.4, 8.43, 8.33, 8.06, 8.23, 7.92, 7.6,
  7.36, 7.08, 6.28, 4.67, 3.81, 3.59, 3.57, 3.67, 3.76, 3.89, 4.21, 5.03, 6.06, 7.48, 8.06, 8.25,
  8.4, 8.48, 8.51, 8.44, 8.21, 8.42, 8.07, 7.74, 7.48, 7.17, 6.36, 4.78, 3.91,
];

export default standaloneRetailLoadProfile;
