import {SEASONS} from '@generic/data/assumptions';
import getMarketRateElements from './shared/getMarketRateElements';
import sharedConEdStaticRateElements from './shared/sharedConEdStaticRateElements';

// Service Classification ("SC") 9
// Rate 4
const RATE_NAME = 'sc9Rate4';

// The prices are the same at both low and high tension, but this format matches the lookup sheet here:
// https://www.coned.com/en/accounts-billing/your-bill/rate-calculators/market-supply-charge
//
// and thus will avoid confusion on updates.
const MSC_ENERGY_CHARGES = {
  low: {
    H: 0.081748,
    I: 0.083699,
    J: 0.084389,
  },
  high: {
    H: 0.081748,
    I: 0.083699,
    J: 0.084389,
  },
};

const getMscCapacityCharge = (isLocatedInWestchester) => (isLocatedInWestchester ? 5.6 : 15.26);

const sc9Rate4 = ({zone, isHighTension, isOnMarketBasedRate, isLocatedInWestchester}) => ({
  name: RATE_NAME,
  title: 'SC 9 Rate IV',
  maxKw: 1500,
  rateElements: [
    ...sharedConEdStaticRateElements,
    ...getMarketRateElements(zone, isOnMarketBasedRate),
    {
      rateElementType: 'MonthlyDemand',
      name: 'MSC Capacity Charge',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: getMscCapacityCharge(isLocatedInWestchester),
          name: 'MSC Capacity Charge',
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'MSC Energy Charge',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: isOnMarketBasedRate
            ? 0
            : MSC_ENERGY_CHARGES[isHighTension ? 'high' : 'low'][zone],
          name: 'MSC Energy Charge',
        },
      ],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: 1713.59,
          name: 'Customer Charge',
        },
      ],
    },
    {
      rateElementType: 'AnnualDemand',
      name: 'Dynamic Load Management ("DLM") Surcharge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: 0.25,
          name: 'CSRP & Term-/Auto-DLM Component',
        },
        {
          charge: 0.15,
          name: 'DLC & DLRP Component',
        },
      ],
    },
    {
      id: 'demandChargeRebate',
      rateElementType: 'AnnualDemand',
      name: 'Contract Demand Charge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: isHighTension ? 7.63 : 9.13,
          name: 'All Hours',
        },
      ],
    },
    {
      id: 'demandChargeRebate',
      rateElementType: 'DemandPerDay',
      name: 'As-Used Demand Charge',
      billingCategory: 'delivery',
      rateComponents: [
        // ConEd does not describe all hours for demands, which may trigger warnings in console
        {
          charge: isHighTension ? 0.7096 : 0.6869,
          months: SEASONS.map((season, i) => (season === 'SUMMER' ? i : -1)).filter((x) => x > -1),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17], // 8am to 6pm
          name: 'Summer - 8am to 6p',
        },
        {
          charge: isHighTension ? 0.5279 : 11.435,
          months: SEASONS.map((season, i) => (season === 'SUMMER' ? i : -1)).filter((x) => x > -1),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21], // 8am to 10pm
          name: 'Summer - 8am to 10p',
        },
        {
          charge: isHighTension ? 0.6456 : 1.0293,
          months: SEASONS.map((season, i) => (season === 'WINTER' ? i : -1)).filter((x) => x > -1),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21], // 8am to 10pm
          name: 'Winter - 8am to 10p',
        },
      ],
    },
    {
      id: 'demandChargeRebate',
      rateElementType: 'MonthlyDemand',
      name: 'VDER Cost Recovery Charge - Demand',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: isHighTension ? -0.01 : 0,
          name: 'VDER Cost Recovery Charge - Demand',
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'VDER Cost Recovery Charge - Energy',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: 0,
          name: 'VDER Cost Recovery Charge - Energy',
        },
      ],
    },
  ],
});

export default sc9Rate4;
