import React, {useState, useMemo, useEffect} from 'react';
import {Box, Grid, makeStyles} from '@material-ui/core';
import RateBreakdownSelector from '@generic/pages/ChargingCalculator/RateBreakdownSelector';
import RateComponentCategoryChart from '@generic/pages/ChargingCalculator/RateComponentCategoryChart';
import RateChargePanel from '@generic/pages/ChargingCalculator/RateChargePanel';
import SupportingCopyPrintout from '@generic/pages/ChargingCalculator/SupportingCopyPrintout';
import PlanningSiteMonthlyDemand from '@generic/pages/SitePlanner/PlanningSiteMonthlyDemand';
import PlanningSiteLoadProfile from '@generic/pages/SitePlanner/PlanningSiteLoadProfile';
import ElectricityBill from '@generic/pages/ChargingCalculator/ElectricityBill';
import {getRates} from '@generic/data/rates';
import calcChargerSetFacilityLoadProfile from '@generic/functions/calcChargerSetFacilityLoadProfile';
import {LoadProfile, RateCalculator} from '@bellawatt/electric-rate-engine';
import {YEAR} from '@generic/data/assumptions';
import calcScaledFacilityLoadProfile from '@generic/functions/calcScaledFacilityLoadProfile';
import {useInputs} from '@bellawatt/use-inputs';
import DemandChargeRebateDisclaimer from '../../components/DemandChargeRebateDisclaimer';
import SmartChargeCommercialBreakdown from '../../components/SmartChargeCommercialBreakdown';
import isEligibleForDemandChargeRebate from '../../functions/isEligibleForDemandChargeRebate';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 'bold',
    marginRight: '1rem',
    lineHeight: '1.5rem',
  },
  alert: {
    flexGrow: 1,
  },
  flexedGridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function PlanningRateBreakdown({annualCosts}) {
  const inputs = useInputs();
  const classes = useStyles();
  const {separatelyMetered, chargerSets} = inputs;
  RateCalculator.shouldValidate = false;

  const chargerLoadProfileData = calcChargerSetFacilityLoadProfile(inputs);
  const chargerLoadProfile = new LoadProfile(chargerLoadProfileData, {year: YEAR});
  const totalLoadProfile = separatelyMetered
    ? chargerLoadProfile
    : chargerLoadProfile.aggregate(calcScaledFacilityLoadProfile(inputs));

  const maxDemand = totalLoadProfile.max();

  const rates = useMemo(() => {
    const rates = getRates(inputs, maxDemand);
    return rates.reduce(
      (acc, rate) => ({
        ...acc,
        [rate.name]: rate,
      }),
      {}
    );
  }, [inputs, maxDemand]);

  const [activeRateName, setActiveRateName] = useState(Object.keys(rates)[0]);
  const rate = rates[activeRateName];
  const [selectedRateComponent, setSelectedRateComponent] = useState('energy');

  const lowestRateName = annualCosts.sort((a, b) => a.total - b.total)[0].name;
  const selectedAnnualCosts = annualCosts.find((c) => c.name === activeRateName) || annualCosts[0];
  const {siteSccRebate} = selectedAnnualCosts;

  useEffect(() => {
    setActiveRateName(lowestRateName);
  }, [lowestRateName]);

  const rateCalculator =
    rate &&
    new RateCalculator({
      ...rate,
      loadProfile: totalLoadProfile,
    });

  const dcfcChargerSets = chargerSets.filter(
    ({charger: {chargerType}}) => chargerType === 'dc_fast_charging'
  );
  const dcfcChargerSetLoadProfileData = calcChargerSetFacilityLoadProfile({
    chargerSets: dcfcChargerSets,
  });
  const dcfcChargerSetLoadProfile = new LoadProfile(dcfcChargerSetLoadProfileData, {year: YEAR});

  const isEligibleForDcr = isEligibleForDemandChargeRebate({
    ...inputs,
    dcfcLoadProfile: dcfcChargerSetLoadProfile,
    maxDemand,
  });
  if (!rateCalculator) return null;

  return (
    <Box mt={5}>
      <Box mb={5}>
        <Grid container spacing={2} mb={5}>
          <Grid item xs={12} lg={6}>
            <RateBreakdownSelector
              rateName={rate.name}
              handleChange={setActiveRateName}
              maxDemand={maxDemand}
            />

            <SupportingCopyPrintout rateName={rate.name} />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.flexedGridItem}>
            <SmartChargeCommercialBreakdown />
          </Grid>
        </Grid>
      </Box>

      <Grid container>
        <Grid item xs={12} lg={6}>
          <RateComponentCategoryChart
            rateCalculator={rateCalculator}
            onHover={setSelectedRateComponent}
            sccRebate={siteSccRebate}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box px={10} display="flex" justifyContent="center">
            <RateChargePanel
              rateCalculator={rateCalculator}
              loadProfile={totalLoadProfile}
              rateTitle={rate.title}
              rateComponent={selectedRateComponent}
              sccRebate={siteSccRebate}
            />
          </Box>
        </Grid>
      </Grid>

      <PlanningSiteMonthlyDemand />
      <PlanningSiteLoadProfile />

      <ElectricityBill
        rateCalculator={rateCalculator}
        loadProfile={totalLoadProfile}
        rateTitle={rate.title}
      />

      {isEligibleForDcr && (
        <Box mb={5}>
          <DemandChargeRebateDisclaimer rateCalculator={rateCalculator} />
        </Box>
      )}
    </Box>
  );
}
