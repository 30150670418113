import React from 'react';
import triangleIcon from '@generic/assets/images/icon-triangle.svg';
import {Box, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  arrowImg: {
    transform: 'rotate(90deg)',
    padding: '1rem',
    [theme.breakpoints.up('md')]: {
      transform: 'none',
      padding: 0,
    },
  },
}));

export default function ChargingBreakdownDivider() {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      <img
        src={triangleIcon}
        alt={formatMessage({id: 'chargingBreakdown.dividerImgAlt'})}
        className={classes.arrowImg}
      />
    </Box>
  );
}
