const fullServiceRestaurantLoadProfile = [
  5.24, 5.09, 5.0, 4.74, 4.35, 4.68, 4.3, 4.08, 4.82, 5.37, 5.86, 6.03, 6.24, 6.4, 6.7, 7.27, 8.17,
  9.22, 9.67, 9.3, 8.66, 7.79, 6.93, 6.51, 5.24, 5.08, 5.01, 4.77, 4.36, 4.72, 4.34, 4.09, 4.83,
  5.37, 5.85, 6.01, 6.2, 6.37, 6.65, 7.2, 8.12, 9.16, 9.64, 9.29, 8.67, 7.82, 6.95, 6.54, 5.24,
  5.09, 5.01, 4.77, 4.38, 4.73, 4.33, 4.12, 4.83, 5.36, 5.85, 6.0, 6.19, 6.37, 6.65, 7.2, 8.15,
  9.16, 9.64, 9.29, 8.67, 7.82, 6.96, 6.53, 5.23, 5.08, 4.98, 4.73, 4.33, 4.67, 4.28, 4.09, 4.82,
  5.37, 5.86, 6.03, 6.23, 6.42, 6.7, 7.28, 8.22, 9.23, 9.69, 9.31, 8.67, 7.79, 6.93, 6.51, 5.37,
  5.05, 4.98, 4.78, 4.67, 4.73, 4.67, 4.5, 4.73, 5.02, 5.34, 5.76, 6.16, 6.45, 6.65, 6.91, 7.27,
  7.98, 8.46, 8.33, 7.91, 7.12, 6.45, 5.83, 5.37, 5.05, 4.94, 4.71, 4.77, 5.42, 5.18, 4.65, 4.68,
  4.93, 5.15, 5.46, 6.0, 6.37, 6.58, 6.96, 7.3, 7.96, 8.49, 8.42, 8.14, 7.63, 7.07, 6.49, 5.18,
  5.09, 4.98, 4.71, 4.32, 4.66, 4.27, 4.06, 4.81, 5.37, 5.86, 6.03, 6.23, 6.4, 6.7, 7.27, 8.17,
  9.22, 9.67, 9.3, 8.65, 7.8, 6.94, 6.51, 5.24, 5.09, 5.0, 4.74, 4.35, 4.68, 4.3, 4.08, 4.82, 5.37,
  5.86, 6.03, 6.24, 6.4, 6.7, 7.27, 8.17, 9.22, 9.67, 9.3, 8.66, 7.79, 6.93, 6.51, 5.24, 5.08, 5.01,
  4.77, 4.36, 4.72, 4.34, 4.09, 4.83, 5.37, 5.85, 6.01, 6.2, 6.37, 6.65, 7.2, 8.12, 9.16, 9.64,
  9.29, 8.67, 7.82, 6.95, 6.54, 5.24, 5.09, 5.01, 4.77, 4.38, 4.73, 4.33, 4.12, 4.83, 5.36, 5.85,
  6.0, 6.19, 6.37, 6.65, 7.2, 8.15, 9.16, 9.64, 9.29, 8.67, 7.82, 6.96, 6.53, 5.23, 5.08, 4.98,
  4.73, 4.33, 4.67, 4.28, 4.09, 4.82, 5.37, 5.86, 6.03, 6.23, 6.42, 6.7, 7.28, 8.22, 9.23, 9.69,
  9.31, 8.67, 7.79, 6.93, 6.51, 5.37, 5.05, 4.98, 4.78, 4.67, 4.73, 4.67, 4.5, 4.73, 5.02, 5.34,
  5.76, 6.16, 6.45, 6.65, 6.91, 7.27, 7.98, 8.46, 8.33, 7.91, 7.12, 6.45, 5.83, 5.37, 5.05, 4.94,
  4.71, 4.77, 5.42, 5.18, 4.65, 4.68, 4.93, 5.15, 5.46, 6.0, 6.37, 6.58, 6.96, 7.3, 7.96, 8.49,
  8.42, 8.14, 7.63, 7.07, 6.49, 5.18, 5.09, 4.98, 4.71, 4.32, 4.66, 4.27, 4.06, 4.81, 5.37, 5.86,
  6.03, 6.23, 6.4, 6.7, 7.27, 8.17, 9.22, 9.67, 9.3, 8.65, 7.8, 6.94, 6.51, 5.24, 5.09, 5.0, 4.74,
  4.35, 4.68, 4.3, 4.08, 4.82, 5.37, 5.86, 6.03, 6.24, 6.4, 6.7, 7.27, 8.17, 9.22, 9.67, 9.3, 8.66,
  7.79, 6.93, 6.51, 5.24, 5.08, 5.01, 4.77, 4.36, 4.72, 4.34, 4.09, 4.83, 5.37, 5.85, 6.01, 6.2,
  6.37, 6.65, 7.2, 8.12, 9.16, 9.64, 9.29, 8.67, 7.82, 6.95, 6.54, 5.24, 5.09, 5.01, 4.77, 4.38,
  4.73, 4.33, 4.12, 4.83, 5.36, 5.85, 6.0, 6.19, 6.37, 6.65, 7.2, 8.15, 9.16, 9.64, 9.29, 8.67,
  7.82, 6.96, 6.53, 5.23, 5.08, 4.98, 4.73, 4.33, 4.67, 4.28, 4.09, 4.82, 5.37, 5.86, 6.03, 6.23,
  6.42, 6.7, 7.28, 8.22, 9.23, 9.69, 9.31, 8.67, 7.79, 6.93, 6.51, 5.37, 5.05, 4.98, 4.78, 4.67,
  4.73, 4.67, 4.5, 4.73, 5.02, 5.34, 5.76, 6.16, 6.45, 6.65, 6.91, 7.27, 7.98, 8.46, 8.33, 7.91,
  7.12, 6.45, 5.83, 5.37, 5.05, 4.94, 4.71, 4.77, 5.42, 5.18, 4.65, 4.68, 4.93, 5.15, 5.46, 6.0,
  6.37, 6.58, 6.96, 7.3, 7.96, 8.49, 8.42, 8.14, 7.63, 7.07, 6.49, 5.18, 5.09, 4.98, 4.71, 4.32,
  4.66, 4.27, 4.06, 4.81, 5.37, 5.86, 6.03, 6.23, 6.4, 6.7, 7.27, 8.17, 9.22, 9.67, 9.3, 8.65, 7.8,
  6.94, 6.51, 5.24, 5.09, 5.0, 4.74, 4.35, 4.68, 4.3, 4.08, 4.82, 5.37, 5.86, 6.03, 6.24, 6.4, 6.7,
  7.27, 8.17, 9.22, 9.67, 9.3, 8.66, 7.79, 6.93, 6.51, 5.24, 5.08, 5.01, 4.77, 4.36, 4.72, 4.34,
  4.09, 4.83, 5.37, 5.85, 6.01, 6.2, 6.37, 6.65, 7.2, 8.12, 9.16, 9.64, 9.29, 8.67, 7.82, 6.95,
  6.54, 5.24, 5.09, 5.01, 4.77, 4.38, 4.73, 4.33, 4.12, 4.83, 5.36, 5.85, 6.0, 6.19, 6.37, 6.65,
  7.2, 8.15, 9.16, 9.64, 9.29, 8.67, 7.82, 6.96, 6.53, 5.23, 5.08, 4.98, 4.73, 4.33, 4.67, 4.28,
  4.09, 4.82, 5.37, 5.86, 6.03, 6.23, 6.42, 6.7, 7.28, 8.22, 9.23, 9.69, 9.31, 8.67, 7.79, 6.93,
  6.51, 5.37, 5.05, 4.98, 4.78, 4.67, 4.73, 4.67, 4.5, 4.73, 5.02, 5.34, 5.76, 6.16, 6.45, 6.65,
  6.91, 7.27, 7.98, 8.46, 8.33, 7.91, 7.12, 6.45, 5.83, 5.37, 5.05, 4.94, 4.71, 4.77, 5.42, 5.18,
  4.65, 4.68, 4.93, 5.15, 5.46, 6.0, 6.37, 6.58, 6.96, 7.3, 7.96, 8.49, 8.42, 8.14, 7.63, 7.07,
  6.49, 5.18, 5.09, 4.98, 4.71, 4.32, 4.66, 4.27, 4.06, 4.81, 5.37, 5.86, 6.03, 6.23, 6.4, 6.7,
  7.27, 8.17, 9.22, 9.67, 9.3, 8.65, 7.8, 6.94, 6.51, 5.24, 5.09, 5.0, 4.74, 4.35, 4.68, 4.3, 4.08,
  4.82, 5.37, 5.86, 6.03, 6.24, 6.4, 6.7, 7.27, 8.17, 9.22, 9.67, 9.3, 8.66, 7.79, 6.93, 6.51, 5.24,
  5.08, 5.01, 4.77, 4.36, 4.72, 4.34, 4.09, 4.83, 5.37, 5.85, 6.01, 6.2, 6.37, 6.65, 7.2, 8.12,
  9.16, 9.64, 9.29, 8.67, 7.82, 6.95, 6.54, 5.24, 5.09, 5.01, 4.77, 4.38, 4.73, 4.33, 4.12, 4.83,
  5.36, 5.85, 6.0, 6.19, 6.37, 6.65, 7.2, 8.15, 9.16, 9.64, 9.29, 8.67, 7.82, 6.96, 6.53, 5.25,
  5.06, 4.94, 4.69, 4.29, 4.64, 4.18, 3.96, 4.8, 5.39, 5.92, 6.13, 6.35, 6.5, 6.74, 7.29, 8.1, 9.16,
  9.71, 9.34, 8.7, 7.81, 6.95, 6.51, 5.37, 5.05, 4.98, 4.77, 4.67, 4.74, 4.61, 4.41, 4.74, 5.04,
  5.36, 5.76, 6.17, 6.46, 6.66, 6.92, 7.15, 7.89, 8.46, 8.33, 7.91, 7.11, 6.45, 5.82, 5.37, 5.04,
  4.95, 4.71, 4.78, 5.43, 5.11, 4.56, 4.7, 4.94, 5.16, 5.48, 6.02, 6.38, 6.59, 6.98, 7.22, 7.88,
  8.5, 8.43, 8.15, 7.63, 7.07, 6.49, 5.19, 5.1, 4.99, 4.71, 4.33, 4.68, 4.18, 4.01, 4.81, 5.37,
  5.88, 6.05, 6.25, 6.42, 6.71, 7.28, 8.08, 9.15, 9.69, 9.32, 8.67, 7.81, 6.94, 6.51, 5.24, 5.08,
  4.98, 4.72, 4.32, 4.67, 4.18, 3.98, 4.81, 5.38, 5.88, 6.04, 6.25, 6.42, 6.71, 7.29, 8.08, 9.12,
  9.7, 9.32, 8.68, 7.8, 6.94, 6.51, 5.25, 5.09, 5.0, 4.77, 4.36, 4.72, 4.24, 4.02, 4.83, 5.37, 5.86,
  6.01, 6.21, 6.38, 6.66, 7.22, 8.03, 9.06, 9.66, 9.31, 8.68, 7.82, 6.96, 6.53, 5.24, 5.08, 5.0,
  4.76, 4.35, 4.71, 4.26, 4.01, 4.83, 5.38, 5.88, 6.05, 6.26, 6.43, 6.72, 7.28, 8.1, 9.16, 9.72,
  9.37, 8.73, 7.84, 6.98, 6.53, 5.25, 5.06, 4.94, 4.69, 4.29, 4.64, 4.18, 3.96, 4.8, 5.39, 5.92,
  6.13, 6.35, 6.5, 6.74, 7.29, 8.1, 9.16, 9.71, 9.34, 8.7, 7.81, 6.95, 6.51, 5.37, 5.05, 4.98, 4.77,
  4.67, 4.74, 4.61, 4.41, 4.74, 5.04, 5.36, 5.76, 6.17, 6.46, 6.66, 6.92, 7.15, 7.89, 8.46, 8.33,
  7.91, 7.11, 6.45, 5.82, 5.37, 5.04, 4.95, 4.71, 4.78, 5.43, 5.11, 4.56, 4.7, 4.94, 5.16, 5.48,
  6.02, 6.38, 6.59, 6.98, 7.22, 7.88, 8.5, 8.43, 8.15, 7.63, 7.07, 6.49, 5.19, 5.1, 4.99, 4.71,
  4.33, 4.68, 4.18, 4.01, 4.81, 5.37, 5.88, 6.05, 6.25, 6.42, 6.71, 7.28, 8.08, 9.15, 9.69, 9.32,
  8.67, 7.81, 6.94, 6.51, 5.24, 5.08, 4.98, 4.72, 4.32, 4.67, 4.18, 3.98, 4.81, 5.38, 5.88, 6.04,
  6.25, 6.42, 6.71, 7.29, 8.08, 9.12, 9.7, 9.32, 8.68, 7.8, 6.94, 6.51, 5.25, 5.09, 5.0, 4.77, 4.36,
  4.72, 4.24, 4.02, 4.83, 5.37, 5.86, 6.01, 6.21, 6.38, 6.66, 7.22, 8.03, 9.06, 9.66, 9.31, 8.68,
  7.82, 6.96, 6.53, 5.24, 5.08, 5.0, 4.76, 4.35, 4.71, 4.26, 4.01, 4.83, 5.38, 5.88, 6.05, 6.26,
  6.43, 6.72, 7.28, 8.1, 9.16, 9.72, 9.37, 8.73, 7.84, 6.98, 6.53, 5.25, 5.06, 4.94, 4.69, 4.29,
  4.64, 4.18, 3.96, 4.8, 5.39, 5.92, 6.13, 6.35, 6.5, 6.74, 7.29, 8.1, 9.16, 9.71, 9.34, 8.7, 7.81,
  6.95, 6.51, 5.37, 5.05, 4.98, 4.77, 4.67, 4.74, 4.61, 4.41, 4.74, 5.04, 5.36, 5.76, 6.17, 6.46,
  6.66, 6.92, 7.15, 7.89, 8.46, 8.33, 7.91, 7.11, 6.45, 5.82, 5.37, 5.04, 4.95, 4.71, 4.78, 5.43,
  5.11, 4.56, 4.7, 4.94, 5.16, 5.48, 6.02, 6.38, 6.59, 6.98, 7.22, 7.88, 8.5, 8.43, 8.15, 7.63,
  7.07, 6.49, 5.19, 5.1, 4.99, 4.71, 4.33, 4.68, 4.18, 4.01, 4.81, 5.37, 5.88, 6.05, 6.25, 6.42,
  6.71, 7.28, 8.08, 9.15, 9.69, 9.32, 8.67, 7.81, 6.94, 6.51, 5.24, 5.08, 4.98, 4.72, 4.32, 4.67,
  4.18, 3.98, 4.81, 5.38, 5.88, 6.04, 6.25, 6.42, 6.71, 7.29, 8.08, 9.12, 9.7, 9.32, 8.68, 7.8,
  6.94, 6.51, 5.25, 5.09, 5.0, 4.77, 4.36, 4.72, 4.24, 4.02, 4.83, 5.37, 5.86, 6.01, 6.21, 6.38,
  6.66, 7.22, 8.03, 9.06, 9.66, 9.31, 8.68, 7.82, 6.96, 6.53, 5.24, 5.08, 5.0, 4.76, 4.35, 4.71,
  4.26, 4.01, 4.83, 5.38, 5.88, 6.05, 6.26, 6.43, 6.72, 7.28, 8.1, 9.16, 9.72, 9.37, 8.73, 7.84,
  6.98, 6.53, 5.25, 5.06, 4.94, 4.69, 4.29, 4.64, 4.18, 3.96, 4.8, 5.39, 5.92, 6.13, 6.35, 6.5,
  6.74, 7.29, 8.1, 9.16, 9.71, 9.34, 8.7, 7.81, 6.95, 6.51, 5.37, 5.05, 4.98, 4.77, 4.67, 4.74,
  4.61, 4.41, 4.74, 5.04, 5.36, 5.76, 6.17, 6.46, 6.66, 6.92, 7.15, 7.89, 8.46, 8.33, 7.91, 7.11,
  6.45, 5.82, 5.37, 5.04, 4.95, 4.71, 4.78, 5.43, 5.11, 4.56, 4.7, 4.94, 5.16, 5.48, 6.02, 6.38,
  6.59, 6.98, 7.22, 7.88, 8.5, 8.43, 8.15, 7.63, 7.07, 6.49, 5.19, 5.1, 4.99, 4.71, 4.33, 4.68,
  4.18, 4.01, 4.81, 5.37, 5.88, 6.05, 6.25, 6.42, 6.71, 7.28, 8.08, 9.15, 9.69, 9.32, 8.67, 7.81,
  6.94, 6.51, 5.24, 5.08, 4.98, 4.72, 4.32, 4.67, 4.18, 3.98, 4.81, 5.38, 5.88, 6.04, 6.25, 6.42,
  6.71, 7.29, 8.08, 9.12, 9.7, 9.32, 8.68, 7.8, 6.94, 6.51, 5.25, 5.09, 5.0, 4.77, 4.36, 4.72, 4.24,
  4.02, 4.83, 5.37, 5.86, 6.01, 6.21, 6.38, 6.66, 7.22, 8.03, 9.06, 9.66, 9.31, 8.68, 7.82, 6.96,
  6.53, 5.24, 5.08, 5.0, 4.76, 4.35, 4.71, 4.26, 4.01, 4.83, 5.38, 5.88, 6.05, 6.26, 6.43, 6.72,
  7.28, 8.1, 9.16, 9.72, 9.37, 8.73, 7.84, 6.98, 6.53, 5.12, 4.98, 4.78, 4.42, 4.49, 4.36, 3.96,
  4.46, 5.14, 5.79, 6.3, 6.6, 6.78, 7.01, 7.52, 8.28, 9.01, 9.53, 9.67, 9.07, 8.22, 7.32, 6.7, 5.75,
  5.17, 4.97, 4.82, 4.65, 4.66, 4.62, 4.37, 4.55, 4.88, 5.24, 5.62, 6.07, 6.44, 6.67, 6.88, 7.12,
  7.52, 8.07, 8.47, 8.18, 7.52, 6.76, 6.08, 5.55, 5.11, 4.93, 4.73, 4.71, 5.23, 5.19, 4.64, 4.62,
  4.84, 5.07, 5.34, 5.85, 6.28, 6.56, 6.95, 7.25, 7.66, 8.16, 8.47, 8.22, 7.74, 7.19, 6.6, 5.6,
  5.07, 4.97, 4.73, 4.38, 4.52, 4.31, 3.96, 4.57, 5.2, 5.7, 5.98, 6.17, 6.35, 6.62, 7.12, 7.89,
  8.69, 9.27, 9.4, 8.83, 7.99, 7.13, 6.59, 5.54, 5.09, 4.98, 4.75, 4.39, 4.54, 4.31, 3.97, 4.59,
  5.21, 5.77, 6.28, 6.64, 6.9, 7.17, 7.67, 8.56, 9.44, 9.82, 9.78, 9.08, 8.14, 7.23, 6.65, 5.56,
  5.09, 4.99, 4.78, 4.41, 4.58, 4.36, 4.0, 4.59, 5.2, 5.69, 5.93, 6.13, 6.31, 6.56, 7.06, 7.83,
  8.65, 9.25, 9.4, 8.83, 8.01, 7.16, 6.6, 5.55, 5.11, 5.0, 4.82, 4.48, 4.54, 4.42, 4.0, 4.46, 5.13,
  5.63, 5.96, 6.2, 6.44, 6.76, 7.28, 7.98, 8.71, 9.33, 9.57, 9.02, 8.23, 7.35, 6.72, 5.77, 5.12,
  4.98, 4.78, 4.42, 4.49, 4.36, 3.96, 4.46, 5.14, 5.79, 6.3, 6.6, 6.78, 7.01, 7.52, 8.28, 9.01,
  9.53, 9.67, 9.07, 8.22, 7.32, 6.7, 5.75, 5.17, 4.97, 4.82, 4.65, 4.66, 4.62, 4.37, 4.55, 4.88,
  5.24, 5.62, 6.07, 6.44, 6.67, 6.88, 7.12, 7.52, 8.07, 8.47, 8.18, 7.52, 6.76, 6.08, 5.55, 5.11,
  4.93, 4.71, 5.23, 5.19, 4.64, 4.62, 4.84, 5.07, 5.34, 5.85, 6.28, 6.56, 6.95, 7.25, 7.66, 8.16,
  8.47, 8.22, 7.74, 7.19, 6.6, 5.6, 5.07, 4.97, 4.73, 4.38, 4.52, 4.31, 3.96, 4.57, 5.2, 5.7, 5.98,
  6.17, 6.35, 6.62, 7.12, 7.89, 8.69, 9.27, 9.4, 8.83, 7.99, 7.13, 6.59, 5.54, 5.09, 4.98, 4.75,
  4.39, 4.54, 4.31, 3.97, 4.59, 5.21, 5.77, 6.28, 6.64, 6.9, 7.17, 7.67, 8.56, 9.44, 9.82, 9.78,
  9.08, 8.14, 7.23, 6.65, 5.56, 5.09, 4.99, 4.78, 4.41, 4.58, 4.36, 4.0, 4.59, 5.2, 5.69, 5.93,
  6.13, 6.31, 6.56, 7.06, 7.83, 8.65, 9.25, 9.4, 8.83, 8.01, 7.16, 6.6, 5.55, 5.11, 5.0, 4.82, 4.48,
  4.54, 4.42, 4.0, 4.46, 5.13, 5.63, 5.96, 6.2, 6.44, 6.76, 7.28, 7.98, 8.71, 9.33, 9.57, 9.02,
  8.23, 7.35, 6.72, 5.77, 5.12, 4.98, 4.78, 4.42, 4.49, 4.36, 3.96, 4.46, 5.14, 5.79, 6.3, 6.6,
  6.78, 7.01, 7.52, 8.28, 9.01, 9.53, 9.67, 9.07, 8.22, 7.32, 6.7, 5.75, 5.17, 4.97, 4.82, 4.65,
  4.66, 4.62, 4.37, 4.55, 4.88, 5.24, 5.62, 6.07, 6.44, 6.67, 6.88, 7.12, 7.52, 8.07, 8.47, 8.18,
  7.52, 6.76, 6.08, 5.55, 5.11, 4.93, 4.73, 4.71, 5.23, 5.19, 4.64, 4.62, 4.84, 5.07, 5.34, 5.85,
  6.28, 6.56, 6.95, 7.25, 7.66, 8.16, 8.47, 8.22, 7.74, 7.19, 6.6, 5.6, 5.07, 4.97, 4.73, 4.38,
  4.52, 4.31, 3.96, 4.57, 5.2, 5.7, 5.98, 6.17, 6.35, 6.62, 7.12, 7.89, 8.69, 9.27, 9.4, 8.83, 7.99,
  7.13, 6.59, 5.54, 5.09, 4.98, 4.75, 4.39, 4.54, 4.31, 3.97, 4.59, 5.21, 5.77, 6.28, 6.64, 6.9,
  7.17, 7.67, 8.56, 9.44, 9.82, 9.78, 9.08, 8.14, 7.23, 6.65, 5.56, 5.09, 4.99, 4.78, 4.41, 4.58,
  4.36, 4.0, 4.59, 5.2, 5.69, 5.93, 6.13, 6.31, 6.56, 7.06, 7.83, 8.65, 9.25, 9.4, 8.83, 8.01, 7.16,
  6.6, 5.55, 5.11, 5.0, 4.82, 4.48, 4.54, 4.42, 4.0, 4.46, 5.13, 5.63, 5.96, 6.2, 6.44, 6.76, 7.28,
  7.98, 8.71, 9.33, 9.57, 9.02, 8.23, 7.35, 6.72, 5.77, 5.12, 4.98, 4.78, 4.42, 4.49, 4.36, 3.96,
  4.46, 5.14, 5.79, 6.3, 6.6, 6.78, 7.01, 7.52, 8.28, 9.01, 9.53, 9.67, 9.07, 8.22, 7.32, 6.7, 5.75,
  5.17, 4.97, 4.82, 4.65, 4.66, 4.62, 4.37, 4.55, 4.88, 5.24, 5.62, 6.07, 6.44, 6.67, 6.88, 7.12,
  7.52, 8.07, 8.47, 8.18, 7.52, 6.76, 6.08, 5.55, 5.11, 4.93, 4.73, 4.71, 5.23, 5.19, 4.64, 4.62,
  4.84, 5.07, 5.34, 5.85, 6.28, 6.56, 6.95, 7.25, 7.66, 8.16, 8.47, 8.22, 7.74, 7.19, 6.6, 5.6,
  5.07, 4.97, 4.73, 4.38, 4.52, 4.31, 3.96, 4.57, 5.2, 5.7, 5.98, 6.17, 6.35, 6.62, 7.12, 7.89,
  8.69, 9.27, 9.4, 8.83, 7.99, 7.13, 6.59, 5.54, 5.09, 4.98, 4.75, 4.39, 4.54, 4.31, 3.97, 4.59,
  5.21, 5.77, 6.28, 6.64, 6.9, 7.17, 7.67, 8.56, 9.44, 9.82, 9.78, 9.08, 8.14, 7.23, 6.65, 5.56,
  5.09, 4.99, 4.78, 4.41, 4.58, 4.36, 4.0, 4.59, 5.2, 5.69, 5.93, 6.13, 6.31, 6.56, 7.06, 7.83,
  8.65, 9.25, 9.4, 8.83, 8.01, 7.16, 6.6, 5.55, 5.11, 5.0, 4.82, 4.48, 4.54, 4.42, 4.0, 4.46, 5.13,
  5.63, 5.96, 6.2, 6.44, 6.76, 7.28, 7.98, 8.71, 9.33, 9.57, 9.02, 8.23, 7.35, 6.72, 5.77, 5.12,
  4.98, 4.78, 4.42, 4.49, 4.36, 3.96, 4.46, 5.14, 5.79, 6.3, 6.6, 6.78, 7.01, 7.52, 8.28, 9.01,
  9.53, 9.67, 9.07, 8.22, 7.32, 6.7, 5.75, 5.17, 4.97, 4.82, 4.65, 4.66, 4.62, 4.37, 4.55, 4.88,
  5.24, 5.62, 6.07, 6.44, 6.67, 6.88, 7.12, 7.52, 8.07, 8.47, 8.18, 7.52, 6.76, 6.08, 5.55, 5.11,
  4.93, 4.73, 4.71, 5.23, 5.19, 4.64, 4.62, 4.84, 5.07, 5.34, 5.85, 6.28, 6.56, 6.95, 7.25, 7.66,
  8.16, 8.47, 8.22, 7.74, 7.19, 6.6, 5.6, 5.05, 4.94, 4.64, 4.25, 4.59, 3.92, 3.91, 4.75, 5.32, 5.9,
  6.2, 6.44, 6.66, 7.02, 7.72, 8.71, 9.6, 9.92, 9.58, 8.92, 7.91, 6.97, 6.48, 5.24, 5.03, 4.94,
  4.65, 4.24, 4.58, 3.95, 3.89, 4.75, 5.29, 5.86, 6.18, 6.44, 6.62, 6.86, 7.4, 8.24, 9.05, 9.43,
  9.24, 8.71, 7.78, 6.93, 6.48, 5.25, 5.03, 4.96, 4.69, 4.29, 4.64, 3.97, 3.92, 4.75, 5.27, 5.76,
  5.93, 6.13, 6.3, 6.6, 7.2, 8.11, 8.96, 9.39, 9.21, 8.67, 7.79, 6.94, 6.48, 5.23, 5.04, 4.95, 4.69,
  4.29, 4.65, 3.96, 3.91, 4.76, 5.28, 5.84, 6.16, 6.48, 6.68, 6.94, 7.49, 8.41, 9.22, 9.57, 9.32,
  8.76, 7.84, 6.96, 6.5, 5.24, 5.05, 4.93, 4.67, 4.26, 4.6, 3.91, 3.89, 4.75, 5.32, 6.06, 6.6, 7.03,
  7.37, 7.63, 8.3, 9.14, 9.85, 10.18, 9.89, 9.25, 8.24, 7.32, 6.79, 5.5, 5.16, 5.04, 4.8, 4.65, 4.7,
  4.33, 4.39, 4.88, 5.41, 6.13, 6.7, 7.19, 7.51, 7.67, 7.87, 8.08, 8.6, 8.98, 8.89, 8.42, 7.45,
  6.68, 5.98, 5.48, 5.06, 4.92, 4.67, 4.68, 5.32, 4.81, 4.43, 4.57, 4.84, 5.08, 5.41, 5.99, 6.36,
  6.61, 7.04, 7.37, 7.88, 8.27, 8.34, 8.13, 7.59, 7.02, 6.44, 5.3, 5.05, 4.94, 4.64, 4.25, 4.59,
  3.92, 3.91, 4.75, 5.32, 5.9, 6.2, 6.44, 6.66, 7.02, 7.72, 8.71, 9.6, 9.92, 9.58, 8.92, 7.91, 6.97,
  6.48, 5.24, 5.03, 4.94, 4.65, 4.24, 4.58, 3.95, 3.89, 4.75, 5.29, 5.86, 6.18, 6.44, 6.62, 6.86,
  7.4, 8.24, 9.05, 9.43, 9.24, 8.71, 7.78, 6.93, 6.48, 5.25, 5.03, 4.96, 4.69, 4.29, 4.64, 3.97,
  3.92, 4.75, 5.27, 5.76, 5.93, 6.13, 6.3, 6.6, 7.2, 8.11, 8.96, 9.39, 9.21, 8.67, 7.79, 6.94, 6.48,
  5.23, 5.04, 4.95, 4.69, 4.29, 4.65, 3.96, 3.91, 4.76, 5.28, 5.84, 6.16, 6.48, 6.68, 6.94, 7.49,
  8.41, 9.22, 9.57, 9.32, 8.76, 7.84, 6.96, 6.5, 5.24, 5.05, 4.93, 4.67, 4.26, 4.6, 3.91, 3.89,
  4.75, 5.32, 6.06, 6.6, 7.03, 7.37, 7.63, 8.3, 9.14, 9.85, 10.18, 9.89, 9.25, 8.24, 7.32, 6.79,
  5.5, 5.16, 5.04, 4.8, 4.65, 4.7, 4.33, 4.39, 4.88, 5.41, 6.13, 6.7, 7.19, 7.51, 7.67, 7.87, 8.08,
  8.6, 8.98, 8.89, 8.42, 7.45, 6.68, 5.98, 5.48, 5.06, 4.92, 4.67, 4.68, 5.32, 4.81, 4.43, 4.57,
  4.84, 5.08, 5.41, 5.99, 6.36, 6.61, 7.04, 7.37, 7.88, 8.27, 8.34, 8.13, 7.59, 7.02, 6.44, 5.3,
  5.05, 4.94, 4.64, 4.25, 4.59, 3.92, 3.91, 4.75, 5.32, 5.9, 6.2, 6.44, 6.66, 7.02, 7.72, 8.71, 9.6,
  9.92, 9.58, 8.92, 7.91, 6.97, 6.48, 5.24, 5.03, 4.94, 4.65, 4.24, 4.58, 3.95, 3.89, 4.75, 5.29,
  5.86, 6.18, 6.44, 6.62, 6.86, 7.4, 8.24, 9.05, 9.43, 9.24, 8.71, 7.78, 6.93, 6.48, 5.25, 5.03,
  4.96, 4.69, 4.29, 4.64, 3.97, 3.92, 4.75, 5.27, 5.76, 5.93, 6.13, 6.3, 6.6, 7.2, 8.11, 8.96, 9.39,
  9.21, 8.67, 7.79, 6.94, 6.48, 5.23, 5.04, 4.95, 4.69, 4.29, 4.65, 3.96, 3.91, 4.76, 5.28, 5.84,
  6.16, 6.48, 6.68, 6.94, 7.49, 8.41, 9.22, 9.57, 9.32, 8.76, 7.84, 6.96, 6.5, 5.24, 5.05, 4.93,
  4.67, 4.26, 4.6, 3.91, 3.89, 4.75, 5.32, 6.06, 6.6, 7.03, 7.37, 7.63, 8.3, 9.14, 9.85, 10.18,
  9.89, 9.25, 8.24, 7.32, 6.79, 5.5, 5.16, 5.04, 4.8, 4.65, 4.7, 4.33, 4.39, 4.88, 5.41, 6.13, 6.7,
  7.19, 7.51, 7.67, 7.87, 8.08, 8.6, 8.98, 8.89, 8.42, 7.45, 6.68, 5.98, 5.48, 5.06, 4.92, 4.67,
  4.68, 5.32, 4.81, 4.43, 4.57, 4.84, 5.08, 5.41, 5.99, 6.36, 6.61, 7.04, 7.37, 7.88, 8.27, 8.34,
  8.13, 7.59, 7.02, 6.44, 5.3, 5.05, 4.94, 4.64, 4.25, 4.59, 3.92, 3.91, 4.75, 5.32, 5.9, 6.2, 6.44,
  6.66, 7.02, 7.72, 8.71, 9.6, 9.92, 9.58, 8.92, 7.91, 6.97, 6.48, 5.24, 5.03, 4.94, 4.65, 4.24,
  4.58, 3.95, 3.89, 4.75, 5.29, 5.86, 6.18, 6.44, 6.62, 6.86, 7.4, 8.24, 9.05, 9.43, 9.24, 8.71,
  7.78, 6.93, 6.48, 5.25, 5.03, 4.96, 4.69, 4.29, 4.64, 3.97, 3.92, 4.75, 5.27, 5.76, 5.93, 6.13,
  6.3, 6.6, 7.2, 8.11, 8.96, 9.39, 9.21, 8.67, 7.79, 6.94, 6.48, 5.23, 5.04, 4.95, 4.69, 4.29, 4.65,
  3.96, 3.91, 4.76, 5.28, 5.84, 6.16, 6.48, 6.68, 6.94, 7.49, 8.41, 9.22, 9.57, 9.32, 8.76, 7.84,
  6.96, 6.5, 5.24, 5.05, 4.93, 4.67, 4.26, 4.6, 3.91, 3.89, 4.75, 5.32, 6.06, 6.6, 7.03, 7.37, 7.63,
  8.3, 9.14, 9.85, 10.18, 9.89, 9.25, 8.24, 7.32, 6.79, 5.5, 5.16, 5.04, 4.8, 4.65, 4.7, 4.33, 4.39,
  4.88, 5.41, 6.13, 6.7, 7.19, 7.51, 7.67, 7.87, 8.08, 8.6, 8.98, 8.89, 8.42, 7.45, 6.68, 5.98,
  5.48, 5.06, 4.92, 4.67, 4.68, 5.32, 4.81, 4.43, 4.57, 4.84, 5.08, 5.41, 5.99, 6.36, 6.61, 7.04,
  7.37, 7.88, 8.27, 8.34, 8.13, 7.59, 7.02, 6.44, 5.3, 5.05, 4.94, 4.64, 4.25, 4.59, 3.92, 3.91,
  4.75, 5.32, 5.9, 6.2, 6.44, 6.66, 7.02, 7.72, 8.71, 9.6, 9.92, 9.58, 8.92, 7.91, 6.97, 6.48, 5.24,
  5.03, 4.94, 4.65, 4.24, 4.58, 3.95, 3.89, 4.75, 5.29, 5.86, 6.18, 6.44, 6.62, 6.86, 7.4, 8.24,
  9.05, 9.43, 9.24, 8.71, 7.78, 6.93, 6.48, 5.25, 5.22, 5.13, 4.85, 4.41, 4.67, 4.15, 4.36, 5.52,
  6.3, 7.27, 7.89, 8.25, 8.6, 8.87, 9.63, 10.91, 11.76, 11.93, 10.81, 9.95, 8.73, 7.76, 7.24, 5.86,
  5.34, 5.24, 4.95, 4.49, 4.87, 4.21, 4.32, 5.42, 6.3, 7.42, 8.36, 8.92, 9.32, 9.72, 10.3, 11.29,
  12.02, 12.23, 11.26, 10.39, 9.1, 7.93, 7.23, 5.76, 5.21, 5.07, 4.74, 4.3, 4.46, 3.85, 3.97, 4.97,
  5.73, 6.45, 6.88, 7.08, 7.28, 7.63, 8.39, 9.31, 9.88, 10.0, 9.45, 8.95, 7.92, 7.02, 6.53, 5.28,
  5.05, 4.95, 4.73, 4.58, 4.5, 4.15, 4.28, 4.78, 5.31, 5.9, 6.48, 7.01, 7.36, 7.46, 7.59, 7.67, 8.1,
  8.56, 8.4, 8.17, 7.28, 6.54, 5.87, 5.38, 5.04, 4.93, 4.67, 4.64, 5.14, 4.6, 4.35, 4.48, 4.76, 5.0,
  5.31, 5.93, 6.29, 6.52, 6.96, 7.3, 7.82, 8.3, 8.17, 8.14, 7.57, 6.98, 6.4, 5.29, 5.06, 4.94, 4.64,
  4.26, 4.44, 3.82, 3.9, 4.75, 5.39, 6.33, 7.09, 7.55, 7.92, 8.4, 9.43, 10.58, 11.29, 11.38, 10.57,
  9.81, 8.61, 7.61, 7.02, 5.67, 5.17, 5.06, 4.77, 4.32, 4.57, 4.0, 4.19, 5.27, 6.01, 6.98, 7.6,
  8.03, 8.35, 8.78, 9.6, 10.67, 11.32, 11.41, 10.57, 9.81, 8.56, 7.56, 7.08, 5.64, 5.22, 5.13, 4.85,
  4.41, 4.67, 4.15, 4.36, 5.52, 6.3, 7.27, 7.89, 8.25, 8.6, 8.87, 9.63, 10.91, 11.76, 11.93, 10.81,
  9.95, 8.73, 7.76, 7.24, 5.86, 5.34, 5.24, 4.95, 4.49, 4.87, 4.21, 4.32, 5.42, 6.3, 7.42, 8.36,
  8.92, 9.32, 9.72, 10.3, 11.29, 12.02, 12.23, 11.26, 10.39, 9.1, 7.93, 7.23, 5.76, 5.21, 5.07,
  4.74, 4.3, 4.46, 3.85, 3.97, 4.97, 5.73, 6.45, 6.88, 7.08, 7.28, 7.63, 8.39, 9.31, 9.88, 10.0,
  9.45, 8.95, 7.92, 7.02, 6.53, 5.28, 5.05, 4.95, 4.73, 4.58, 4.5, 4.15, 4.28, 4.78, 5.31, 5.9,
  6.48, 7.01, 7.36, 7.46, 7.59, 7.67, 8.1, 8.56, 8.4, 8.17, 7.28, 6.54, 5.87, 5.38, 5.04, 4.93,
  4.67, 4.64, 5.14, 4.6, 4.35, 4.48, 4.76, 5.0, 5.31, 5.93, 6.29, 6.52, 6.96, 7.3, 7.82, 8.3, 8.17,
  8.14, 7.57, 6.98, 6.4, 5.29, 5.06, 4.94, 4.64, 4.26, 4.44, 3.82, 3.9, 4.75, 5.39, 6.33, 7.09,
  7.55, 7.92, 8.4, 9.43, 10.58, 11.29, 11.38, 10.57, 9.81, 8.61, 7.61, 7.02, 5.67, 5.17, 5.06, 4.77,
  4.32, 4.57, 4.0, 4.19, 5.27, 6.01, 6.98, 7.6, 8.03, 8.35, 8.78, 9.6, 10.67, 11.32, 11.41, 10.57,
  9.81, 8.56, 7.56, 7.08, 5.64, 5.22, 5.13, 4.85, 4.41, 4.67, 4.15, 4.36, 5.52, 6.3, 7.27, 7.89,
  8.25, 8.6, 8.87, 9.63, 10.91, 11.76, 11.93, 10.81, 9.95, 8.73, 7.76, 7.24, 5.86, 5.34, 5.24, 4.95,
  4.49, 4.87, 4.21, 4.32, 5.42, 6.3, 7.42, 8.36, 8.92, 9.32, 9.72, 10.3, 11.29, 12.02, 12.23, 11.26,
  10.39, 9.1, 7.93, 7.23, 5.76, 5.21, 5.07, 4.74, 4.3, 4.46, 3.85, 3.97, 4.97, 5.73, 6.45, 6.88,
  7.08, 7.28, 7.63, 8.39, 9.31, 9.88, 10.0, 9.45, 8.95, 7.92, 7.02, 6.53, 5.28, 5.05, 4.95, 4.73,
  4.58, 4.5, 4.15, 4.28, 4.78, 5.31, 5.9, 6.48, 7.01, 7.36, 7.46, 7.59, 7.67, 8.1, 8.56, 8.4, 8.17,
  7.28, 6.54, 5.87, 5.38, 5.04, 4.93, 4.67, 4.64, 5.14, 4.6, 4.35, 4.48, 4.76, 5.0, 5.31, 5.93,
  6.29, 6.52, 6.96, 7.3, 7.82, 8.3, 8.17, 8.14, 7.57, 6.98, 6.4, 5.29, 5.06, 4.94, 4.64, 4.26, 4.44,
  3.82, 3.9, 4.75, 5.39, 6.33, 7.09, 7.55, 7.92, 8.4, 9.43, 10.58, 11.29, 11.38, 10.57, 9.81, 8.61,
  7.61, 7.02, 5.67, 5.17, 5.06, 4.77, 4.32, 4.57, 4.0, 4.19, 5.27, 6.01, 6.98, 7.6, 8.03, 8.35,
  8.78, 9.6, 10.67, 11.32, 11.41, 10.57, 9.81, 8.56, 7.56, 7.08, 5.64, 5.22, 5.13, 4.85, 4.41, 4.67,
  4.15, 4.36, 5.52, 6.3, 7.27, 7.89, 8.25, 8.6, 8.87, 9.63, 10.91, 11.76, 11.93, 10.81, 9.95, 8.73,
  7.76, 7.24, 5.86, 5.34, 5.24, 4.95, 4.49, 4.87, 4.21, 4.32, 5.42, 6.3, 7.42, 8.36, 8.92, 9.32,
  9.72, 10.3, 11.29, 12.02, 12.23, 11.26, 10.39, 9.1, 7.93, 7.23, 5.76, 5.21, 5.07, 4.74, 4.3, 4.46,
  3.85, 3.97, 4.97, 5.73, 6.45, 6.88, 7.08, 7.28, 7.63, 8.39, 9.31, 9.88, 10.0, 9.45, 8.95, 7.92,
  7.02, 6.53, 5.28, 5.05, 4.95, 4.73, 4.58, 4.5, 4.15, 4.28, 4.78, 5.31, 5.9, 6.48, 7.01, 7.36,
  7.46, 7.59, 7.67, 8.1, 8.56, 8.4, 8.17, 7.28, 6.54, 5.87, 5.38, 5.04, 4.93, 4.67, 4.64, 5.14, 4.6,
  4.35, 4.48, 4.76, 5.0, 5.31, 5.93, 6.29, 6.52, 6.96, 7.3, 7.82, 8.3, 8.17, 8.14, 7.57, 6.98, 6.4,
  5.29, 5.06, 4.94, 4.64, 4.26, 4.44, 3.82, 3.9, 4.75, 5.39, 6.33, 7.09, 7.55, 7.92, 8.4, 9.43,
  10.58, 11.29, 11.38, 10.57, 9.81, 8.61, 7.61, 7.02, 5.67, 5.17, 5.06, 4.77, 4.32, 4.57, 4.0, 4.19,
  5.27, 6.01, 6.98, 7.6, 8.03, 8.35, 8.78, 9.6, 10.67, 11.32, 11.41, 10.57, 9.81, 8.56, 7.56, 7.08,
  5.64, 5.22, 5.13, 4.85, 4.41, 4.67, 4.15, 4.36, 5.52, 6.3, 7.27, 7.89, 8.25, 8.6, 8.87, 9.63,
  10.91, 11.76, 11.93, 10.81, 9.95, 8.73, 7.76, 7.24, 5.86, 5.34, 5.24, 4.95, 4.49, 4.87, 4.21,
  4.32, 5.42, 6.3, 7.42, 8.36, 8.92, 9.32, 9.72, 10.3, 11.29, 12.02, 12.23, 11.26, 10.39, 9.1, 7.93,
  7.23, 5.76, 5.21, 5.07, 4.74, 4.3, 4.46, 3.85, 3.97, 4.97, 5.73, 6.45, 6.88, 7.08, 7.28, 7.63,
  8.39, 9.31, 9.88, 10.0, 9.45, 8.95, 7.92, 7.02, 6.53, 5.28, 5.35, 5.24, 5.0, 4.82, 4.72, 4.53,
  4.77, 5.52, 6.44, 7.22, 8.15, 9.23, 9.84, 10.18, 10.43, 10.63, 11.34, 11.79, 11.16, 10.33, 8.92,
  7.89, 7.01, 6.38, 5.48, 5.34, 5.02, 4.92, 5.52, 4.99, 4.72, 5.35, 6.2, 6.85, 7.46, 8.3, 8.6, 8.84,
  9.25, 9.57, 10.1, 10.36, 9.88, 9.42, 8.49, 7.78, 7.04, 5.73, 5.3, 5.16, 4.83, 4.42, 4.43, 4.0,
  4.26, 5.42, 6.35, 7.42, 7.97, 8.01, 8.07, 8.22, 8.88, 9.91, 10.63, 10.75, 10.12, 9.48, 8.43, 7.44,
  6.88, 5.57, 5.2, 5.07, 4.78, 4.35, 4.44, 3.96, 4.18, 5.26, 6.08, 7.06, 7.79, 8.14, 8.51, 8.93,
  9.67, 10.68, 11.33, 11.5, 10.61, 9.77, 8.67, 7.62, 7.0, 5.64, 5.24, 5.13, 4.86, 4.4, 4.54, 4.02,
  4.22, 5.32, 6.31, 7.41, 8.21, 8.64, 8.91, 9.15, 9.89, 10.84, 11.59, 11.83, 10.92, 10.17, 9.11,
  8.04, 7.48, 6.05, 5.41, 5.33, 5.02, 4.54, 4.69, 4.22, 4.4, 5.5, 6.45, 7.49, 8.12, 8.46, 8.65,
  8.95, 9.68, 10.78, 11.69, 12.09, 11.25, 10.35, 9.15, 8.12, 7.53, 6.01, 5.39, 5.23, 4.88, 4.42,
  4.49, 3.99, 4.29, 5.45, 6.37, 7.49, 8.31, 8.59, 8.74, 9.2, 10.09, 11.18, 11.91, 12.1, 11.24,
  10.42, 9.2, 7.99, 7.35, 5.91, 5.35, 5.24, 5.0, 4.82, 4.72, 4.53, 4.77, 5.52, 6.44, 7.22, 8.15,
  9.23, 9.84, 10.18, 10.43, 10.63, 11.34, 11.79, 11.16, 10.33, 8.92, 7.89, 7.01, 6.38, 5.48, 5.34,
  5.02, 4.92, 5.52, 4.99, 4.72, 5.35, 6.2, 6.85, 7.46, 8.3, 8.6, 8.84, 9.25, 9.57, 10.1, 10.36,
  9.88, 9.42, 8.49, 7.78, 7.04, 5.73, 5.3, 5.16, 4.83, 4.42, 4.43, 4.0, 4.26, 5.42, 6.35, 7.42,
  7.97, 8.01, 8.07, 8.22, 8.88, 9.91, 10.63, 10.75, 10.12, 9.48, 8.43, 7.44, 6.88, 5.57, 5.2, 5.07,
  4.78, 4.35, 4.44, 3.96, 4.18, 5.26, 6.08, 7.06, 7.79, 8.14, 8.51, 8.93, 9.67, 10.68, 11.33, 11.5,
  10.61, 9.77, 8.67, 7.62, 7.0, 5.64, 5.24, 5.13, 4.86, 4.4, 4.54, 4.02, 4.22, 5.32, 6.31, 7.41,
  8.21, 8.64, 8.91, 9.15, 9.89, 10.84, 11.59, 11.83, 10.92, 10.17, 9.11, 8.04, 7.48, 6.05, 5.41,
  5.33, 5.02, 4.54, 4.69, 4.22, 4.4, 5.5, 6.45, 7.49, 8.12, 8.46, 8.65, 8.95, 9.68, 10.78, 11.69,
  12.09, 11.25, 10.35, 9.15, 8.12, 7.53, 6.01, 5.39, 5.23, 4.88, 4.42, 4.49, 3.99, 4.29, 5.45, 6.37,
  7.49, 8.31, 8.59, 8.74, 9.2, 10.09, 11.18, 11.91, 12.1, 11.24, 10.42, 9.2, 7.99, 7.35, 5.91, 5.35,
  5.24, 5.0, 4.82, 4.72, 4.53, 4.77, 5.52, 6.44, 7.22, 8.15, 9.23, 9.84, 10.18, 10.43, 10.63, 11.34,
  11.79, 11.16, 10.33, 8.92, 7.89, 7.01, 6.38, 5.48, 5.34, 5.02, 4.92, 5.52, 4.99, 4.72, 5.35, 6.2,
  6.85, 7.46, 8.3, 8.6, 8.84, 9.25, 9.57, 10.1, 10.36, 9.88, 9.42, 8.49, 7.78, 7.04, 5.73, 5.3,
  5.16, 4.83, 4.42, 4.43, 4.0, 4.26, 5.42, 6.35, 7.42, 7.97, 8.01, 8.07, 8.22, 8.88, 9.91, 10.63,
  10.75, 10.12, 9.48, 8.43, 7.44, 6.88, 5.57, 5.2, 5.07, 4.78, 4.35, 4.44, 3.96, 4.18, 5.26, 6.08,
  7.06, 7.79, 8.14, 8.51, 8.93, 9.67, 10.68, 11.33, 11.5, 10.61, 9.77, 8.67, 7.62, 7.0, 5.64, 5.24,
  5.13, 4.86, 4.4, 4.54, 4.02, 4.22, 5.32, 6.31, 7.41, 8.21, 8.64, 8.91, 9.15, 9.89, 10.84, 11.59,
  11.83, 10.92, 10.17, 9.11, 8.04, 7.48, 6.05, 5.41, 5.33, 5.02, 4.54, 4.69, 4.22, 4.4, 5.5, 6.45,
  7.49, 8.12, 8.46, 8.65, 8.95, 9.68, 10.78, 11.69, 12.09, 11.25, 10.35, 9.15, 8.12, 7.53, 6.01,
  5.39, 5.23, 4.88, 4.42, 4.49, 3.99, 4.29, 5.45, 6.37, 7.49, 8.31, 8.59, 8.74, 9.2, 10.09, 11.18,
  11.91, 12.1, 11.24, 10.42, 9.2, 7.99, 7.35, 5.91, 5.35, 5.24, 5.0, 4.82, 4.72, 4.53, 4.77, 5.52,
  6.44, 7.22, 8.15, 9.23, 9.84, 10.18, 10.43, 10.63, 11.34, 11.79, 11.16, 10.33, 8.92, 7.89, 7.01,
  6.38, 5.48, 5.34, 5.02, 4.92, 5.52, 4.99, 4.72, 5.35, 6.2, 6.85, 7.46, 8.3, 8.6, 8.84, 9.25, 9.57,
  10.1, 10.36, 9.88, 9.42, 8.49, 7.78, 7.04, 5.73, 5.3, 5.16, 4.83, 4.42, 4.43, 4.0, 4.26, 5.42,
  6.35, 7.42, 7.97, 8.01, 8.07, 8.22, 8.88, 9.91, 10.63, 10.75, 10.12, 9.48, 8.43, 7.44, 6.88, 5.57,
  5.2, 5.07, 4.78, 4.35, 4.44, 3.96, 4.18, 5.26, 6.08, 7.06, 7.79, 8.14, 8.51, 8.93, 9.67, 10.68,
  11.33, 11.5, 10.61, 9.77, 8.67, 7.62, 7.0, 5.64, 5.24, 5.13, 4.86, 4.4, 4.54, 4.02, 4.22, 5.32,
  6.31, 7.41, 8.21, 8.64, 8.91, 9.15, 9.89, 10.84, 11.59, 11.83, 10.92, 10.17, 9.11, 8.04, 7.48,
  6.05, 5.41, 5.33, 5.02, 4.54, 4.69, 4.22, 4.4, 5.5, 6.45, 7.49, 8.12, 8.46, 8.65, 8.95, 9.68,
  10.78, 11.69, 12.09, 11.25, 10.35, 9.15, 8.12, 7.53, 6.01, 5.39, 5.23, 4.88, 4.42, 4.49, 3.99,
  4.29, 5.45, 6.37, 7.49, 8.31, 8.59, 8.74, 9.2, 10.09, 11.18, 11.91, 12.1, 11.24, 10.42, 9.2, 7.99,
  7.35, 5.91, 5.35, 5.24, 5.0, 4.82, 4.72, 4.53, 4.77, 5.52, 6.44, 7.22, 8.15, 9.23, 9.84, 10.18,
  10.43, 10.63, 11.34, 11.79, 11.16, 10.33, 8.92, 7.89, 7.01, 6.38, 5.48, 5.34, 5.02, 4.92, 5.52,
  4.99, 4.72, 5.35, 6.2, 6.85, 7.46, 8.3, 8.6, 8.84, 9.25, 9.57, 10.1, 10.36, 9.88, 9.42, 8.49,
  7.78, 7.04, 5.73, 5.47, 5.3, 4.97, 4.57, 4.82, 4.26, 4.59, 5.86, 6.87, 8.25, 9.11, 9.51, 9.76,
  9.79, 10.21, 11.32, 12.45, 12.88, 11.89, 10.89, 9.53, 8.43, 7.81, 6.32, 5.54, 5.42, 5.11, 4.63,
  5.1, 4.56, 4.88, 6.36, 7.42, 8.7, 9.52, 10.07, 10.49, 10.9, 11.76, 12.87, 13.75, 13.86, 12.72,
  11.7, 10.19, 8.91, 8.24, 6.68, 5.81, 5.71, 5.41, 4.9, 5.75, 5.11, 5.46, 7.06, 8.35, 9.96, 10.89,
  11.3, 11.54, 11.76, 12.46, 13.59, 14.45, 14.65, 13.42, 12.25, 10.91, 9.64, 8.84, 7.21, 5.98, 5.82,
  5.45, 4.93, 5.82, 5.08, 5.46, 7.16, 8.46, 9.85, 10.42, 10.27, 10.44, 10.88, 11.52, 12.6, 13.38,
  13.56, 12.48, 11.31, 9.83, 8.65, 7.98, 6.43, 5.6, 5.47, 5.15, 4.64, 4.97, 4.48, 4.83, 6.25, 7.36,
  8.7, 9.43, 9.65, 9.91, 10.36, 11.27, 12.41, 13.23, 13.45, 12.35, 11.3, 9.91, 8.68, 8.03, 6.56,
  5.66, 5.55, 5.25, 5.0, 5.28, 4.97, 5.42, 6.35, 7.33, 8.17, 8.92, 9.79, 10.26, 10.46, 10.45, 10.35,
  10.97, 11.56, 11.23, 10.53, 9.0, 7.91, 6.97, 6.3, 5.52, 5.38, 5.06, 4.92, 5.66, 5.02, 4.76, 5.51,
  6.37, 7.14, 7.81, 8.68, 9.24, 9.59, 10.03, 10.26, 10.8, 11.12, 10.51, 10.07, 9.1, 8.38, 7.56,
  6.15, 5.47, 5.3, 4.97, 4.57, 4.82, 4.26, 4.59, 5.86, 6.87, 8.25, 9.11, 9.51, 9.76, 9.79, 10.21,
  11.32, 12.45, 12.88, 11.89, 10.89, 9.53, 8.43, 7.81, 6.32, 5.54, 5.42, 5.11, 4.63, 5.1, 4.56,
  4.88, 6.36, 7.42, 8.7, 9.52, 10.07, 10.49, 10.9, 11.76, 12.87, 13.75, 13.86, 12.72, 11.7, 10.19,
  8.91, 8.24, 6.68, 5.81, 5.71, 5.41, 4.9, 5.75, 5.11, 5.46, 7.06, 8.35, 9.96, 10.89, 11.3, 11.54,
  11.76, 12.46, 13.59, 14.45, 14.65, 13.42, 12.25, 10.91, 9.64, 8.84, 7.21, 5.98, 5.82, 5.45, 4.93,
  5.82, 5.08, 5.46, 7.16, 8.46, 9.85, 10.42, 10.27, 10.44, 10.88, 11.52, 12.6, 13.38, 13.56, 12.48,
  11.31, 9.83, 8.65, 7.98, 6.43, 5.6, 5.47, 5.15, 4.64, 4.97, 4.48, 4.83, 6.25, 7.36, 8.7, 9.43,
  9.65, 9.91, 10.36, 11.27, 12.41, 13.23, 13.45, 12.35, 11.3, 9.91, 8.68, 8.03, 6.56, 5.66, 5.55,
  5.25, 5.0, 5.28, 4.97, 5.42, 6.35, 7.33, 8.17, 8.92, 9.79, 10.26, 10.46, 10.45, 10.35, 10.97,
  11.56, 11.23, 10.53, 9.0, 7.91, 6.97, 6.3, 5.52, 5.38, 5.06, 4.92, 5.66, 5.02, 4.76, 5.51, 6.37,
  7.14, 7.81, 8.68, 9.24, 9.59, 10.03, 10.26, 10.8, 11.12, 10.51, 10.07, 9.1, 8.38, 7.56, 6.15,
  5.47, 5.3, 4.97, 4.57, 4.82, 4.26, 4.59, 5.86, 6.87, 8.25, 9.11, 9.51, 9.76, 9.79, 10.21, 11.32,
  12.45, 12.88, 11.89, 10.89, 9.53, 8.43, 7.81, 6.32, 5.54, 5.42, 5.11, 4.63, 5.1, 4.56, 4.88, 6.36,
  7.42, 8.7, 9.52, 10.07, 10.49, 10.9, 11.76, 12.87, 13.75, 13.86, 12.72, 11.7, 10.19, 8.91, 8.24,
  6.68, 5.81, 5.71, 5.41, 4.9, 5.75, 5.11, 5.46, 7.06, 8.35, 9.96, 10.89, 11.3, 11.54, 11.76, 12.46,
  13.59, 14.45, 14.65, 13.42, 12.25, 10.91, 9.64, 8.84, 7.21, 5.98, 5.82, 5.45, 4.93, 5.82, 5.08,
  5.46, 7.16, 8.46, 9.85, 10.42, 10.27, 10.44, 10.88, 11.52, 12.6, 13.38, 13.56, 12.48, 11.31, 9.83,
  8.65, 7.98, 6.43, 5.6, 5.47, 5.15, 4.64, 4.97, 4.48, 4.83, 6.25, 7.36, 8.7, 9.43, 9.65, 9.91,
  10.36, 11.27, 12.41, 13.23, 13.45, 12.35, 11.3, 9.91, 8.68, 8.03, 6.56, 5.66, 5.55, 5.25, 5.0,
  5.28, 4.97, 5.42, 6.35, 7.33, 8.17, 8.92, 9.79, 10.26, 10.46, 10.45, 10.35, 10.97, 11.56, 11.23,
  10.53, 9.0, 7.91, 6.97, 6.3, 5.52, 5.38, 5.06, 4.92, 5.66, 5.02, 4.76, 5.51, 6.37, 7.14, 7.81,
  8.68, 9.24, 9.59, 10.03, 10.26, 10.8, 11.12, 10.51, 10.07, 9.1, 8.38, 7.56, 6.15, 5.47, 5.3, 4.97,
  4.57, 4.82, 4.26, 4.59, 5.86, 6.87, 8.25, 9.11, 9.51, 9.76, 9.79, 10.21, 11.32, 12.45, 12.88,
  11.89, 10.89, 9.53, 8.43, 7.81, 6.32, 5.54, 5.42, 5.11, 4.63, 5.1, 4.56, 4.88, 6.36, 7.42, 8.7,
  9.52, 10.07, 10.49, 10.9, 11.76, 12.87, 13.75, 13.86, 12.72, 11.7, 10.19, 8.91, 8.24, 6.68, 5.81,
  5.71, 5.41, 4.9, 5.75, 5.11, 5.46, 7.06, 8.35, 9.96, 10.89, 11.3, 11.54, 11.76, 12.46, 13.59,
  14.45, 14.65, 13.42, 12.25, 10.91, 9.64, 8.84, 7.21, 5.98, 5.82, 5.45, 4.93, 5.82, 5.08, 5.46,
  7.16, 8.46, 9.85, 10.42, 10.27, 10.44, 10.88, 11.52, 12.6, 13.38, 13.56, 12.48, 11.31, 9.83, 8.65,
  7.98, 6.43, 5.6, 5.47, 5.15, 4.64, 4.97, 4.48, 4.83, 6.25, 7.36, 8.7, 9.43, 9.65, 9.91, 10.36,
  11.27, 12.41, 13.23, 13.45, 12.35, 11.3, 9.91, 8.68, 8.03, 6.56, 5.66, 5.55, 5.25, 5.0, 5.28,
  4.97, 5.42, 6.35, 7.33, 8.17, 8.92, 9.79, 10.26, 10.46, 10.45, 10.35, 10.97, 11.56, 11.23, 10.53,
  9.0, 7.91, 6.97, 6.3, 5.52, 5.38, 5.06, 4.92, 5.66, 5.02, 4.76, 5.51, 6.37, 7.14, 7.81, 8.68,
  9.24, 9.59, 10.03, 10.26, 10.8, 11.12, 10.51, 10.07, 9.1, 8.38, 7.56, 6.15, 5.47, 5.3, 4.97, 4.57,
  4.82, 4.26, 4.59, 5.86, 6.87, 8.25, 9.11, 9.51, 9.76, 9.79, 10.21, 11.32, 12.45, 12.88, 11.89,
  10.89, 9.53, 8.43, 7.81, 6.32, 5.54, 5.42, 5.11, 4.63, 5.1, 4.56, 4.88, 6.36, 7.42, 8.7, 9.52,
  10.07, 10.49, 10.9, 11.76, 12.87, 13.75, 13.86, 12.72, 11.7, 10.19, 8.91, 8.24, 6.68, 5.81, 5.71,
  5.41, 4.9, 5.75, 5.11, 5.46, 7.06, 8.35, 9.96, 10.89, 11.3, 11.54, 11.76, 12.46, 13.59, 14.45,
  14.65, 13.42, 12.25, 10.91, 9.64, 8.84, 7.21, 5.6, 5.45, 5.1, 4.6, 5.07, 4.37, 4.53, 5.79, 6.69,
  7.88, 8.68, 9.01, 9.22, 9.35, 10.05, 11.2, 12.03, 12.28, 11.52, 10.64, 9.36, 8.29, 7.68, 6.23,
  5.52, 5.41, 5.1, 4.64, 5.28, 4.56, 4.82, 6.04, 7.04, 8.37, 9.16, 9.55, 9.89, 10.34, 11.0, 11.99,
  12.83, 13.07, 12.18, 11.16, 9.73, 8.64, 8.01, 6.49, 5.73, 5.66, 5.4, 5.13, 5.56, 5.08, 5.32, 6.19,
  7.2, 8.11, 8.89, 9.65, 10.22, 10.44, 10.63, 10.84, 11.56, 11.95, 11.45, 10.74, 9.27, 8.18, 7.19,
  6.43, 5.55, 5.39, 5.08, 4.95, 5.86, 5.09, 4.98, 5.57, 6.5, 7.21, 7.89, 8.81, 9.31, 9.41, 9.67,
  9.89, 10.4, 10.59, 10.2, 9.87, 9.0, 8.32, 7.48, 5.99, 5.4, 5.25, 4.9, 4.5, 4.77, 4.04, 4.21, 5.33,
  6.23, 7.26, 7.93, 8.26, 8.59, 8.84, 9.5, 10.58, 11.35, 11.58, 10.88, 10.0, 8.69, 7.61, 7.03, 5.68,
  5.28, 5.13, 4.84, 4.39, 4.68, 3.96, 4.09, 5.21, 6.26, 7.39, 8.06, 8.32, 8.54, 8.8, 9.6, 10.87,
  11.79, 12.01, 11.17, 10.26, 9.01, 8.01, 7.44, 6.04, 5.44, 5.32, 5.04, 4.59, 5.01, 4.35, 4.59,
  5.88, 6.97, 8.26, 9.09, 9.45, 9.8, 10.27, 11.09, 12.26, 13.15, 13.22, 12.22, 11.3, 9.88, 8.67,
  8.0, 6.49, 5.6, 5.45, 5.1, 4.6, 5.07, 4.37, 4.53, 5.79, 6.69, 7.88, 8.68, 9.01, 9.22, 9.35, 10.05,
  11.2, 12.03, 12.28, 11.52, 10.64, 9.36, 8.29, 7.68, 6.23, 5.52, 5.41, 5.1, 4.64, 5.28, 4.56, 4.82,
  6.04, 7.04, 8.37, 9.16, 9.55, 9.89, 10.34, 11.0, 11.99, 12.83, 13.07, 12.18, 11.16, 9.73, 8.64,
  8.01, 6.49, 5.73, 5.66, 5.4, 5.13, 5.56, 5.08, 5.32, 6.19, 7.2, 8.11, 8.89, 9.65, 10.22, 10.44,
  10.63, 10.84, 11.56, 11.95, 11.45, 10.74, 9.27, 8.18, 7.19, 6.43, 5.55, 5.39, 5.08, 4.95, 5.86,
  5.09, 4.98, 5.57, 6.5, 7.21, 7.89, 8.81, 9.31, 9.41, 9.67, 9.89, 10.4, 10.59, 10.2, 9.87, 9.0,
  8.32, 7.48, 5.99, 5.4, 5.25, 4.9, 4.5, 4.77, 4.04, 4.21, 5.33, 6.23, 7.26, 7.93, 8.26, 8.59, 8.84,
  9.5, 10.58, 11.35, 11.58, 10.88, 10.0, 8.69, 7.61, 7.03, 5.68, 5.28, 5.13, 4.84, 4.39, 4.68, 3.96,
  4.09, 5.21, 6.26, 7.39, 8.06, 8.32, 8.54, 8.8, 9.6, 10.87, 11.79, 12.01, 11.17, 10.26, 9.01, 8.01,
  7.44, 6.04, 5.44, 5.32, 5.04, 4.59, 5.01, 4.35, 4.59, 5.88, 6.97, 8.26, 9.09, 9.45, 9.8, 10.27,
  11.09, 12.26, 13.15, 13.22, 12.22, 11.3, 9.88, 8.67, 8.0, 6.49, 5.6, 5.45, 5.1, 4.6, 5.07, 4.37,
  4.53, 5.79, 6.69, 7.88, 8.68, 9.01, 9.22, 9.35, 10.05, 11.2, 12.03, 12.28, 11.52, 10.64, 9.36,
  8.29, 7.68, 6.23, 5.52, 5.41, 5.1, 4.64, 5.28, 4.56, 4.82, 6.04, 7.04, 8.37, 9.16, 9.55, 9.89,
  10.34, 11.0, 11.99, 12.83, 13.07, 12.18, 11.16, 9.73, 8.64, 8.01, 6.49, 5.73, 5.66, 5.4, 5.13,
  5.56, 5.08, 5.32, 6.19, 7.2, 8.11, 8.89, 9.65, 10.22, 10.44, 10.63, 10.84, 11.56, 11.95, 11.45,
  10.74, 9.27, 8.18, 7.19, 6.43, 5.55, 5.39, 5.08, 4.95, 5.86, 5.09, 4.98, 5.57, 6.5, 7.21, 7.89,
  8.81, 9.31, 9.41, 9.67, 9.89, 10.4, 10.59, 10.2, 9.87, 9.0, 8.32, 7.48, 5.99, 5.4, 5.25, 4.9, 4.5,
  4.77, 4.04, 4.21, 5.33, 6.23, 7.26, 7.93, 8.26, 8.59, 8.84, 9.5, 10.58, 11.35, 11.58, 10.88, 10.0,
  8.69, 7.61, 7.03, 5.68, 5.28, 5.13, 4.84, 4.39, 4.68, 3.96, 4.09, 5.21, 6.26, 7.39, 8.06, 8.32,
  8.54, 8.8, 9.6, 10.87, 11.79, 12.01, 11.17, 10.26, 9.01, 8.01, 7.44, 6.04, 5.44, 5.32, 5.04, 4.59,
  5.01, 4.35, 4.59, 5.88, 6.97, 8.26, 9.09, 9.45, 9.8, 10.27, 11.09, 12.26, 13.15, 13.22, 12.22,
  11.3, 9.88, 8.67, 8.0, 6.49, 5.6, 5.45, 5.1, 4.6, 5.07, 4.37, 4.53, 5.79, 6.69, 7.88, 8.68, 9.01,
  9.22, 9.35, 10.05, 11.2, 12.03, 12.28, 11.52, 10.64, 9.36, 8.29, 7.68, 6.23, 5.52, 5.41, 5.1,
  4.64, 5.28, 4.56, 4.82, 6.04, 7.04, 8.37, 9.16, 9.55, 9.89, 10.34, 11.0, 11.99, 12.83, 13.07,
  12.18, 11.16, 9.73, 8.64, 8.01, 6.49, 5.73, 5.66, 5.4, 5.13, 5.56, 5.08, 5.32, 6.19, 7.2, 8.11,
  8.89, 9.65, 10.22, 10.44, 10.63, 10.84, 11.56, 11.95, 11.45, 10.74, 9.27, 8.18, 7.19, 6.43, 5.55,
  5.39, 5.08, 4.95, 5.86, 5.09, 4.98, 5.57, 6.5, 7.21, 7.89, 8.81, 9.31, 9.41, 9.67, 9.89, 10.4,
  10.59, 10.2, 9.87, 9.0, 8.32, 7.48, 5.99, 5.4, 5.25, 4.9, 4.5, 4.77, 4.04, 4.21, 5.33, 6.23, 7.26,
  7.93, 8.26, 8.59, 8.84, 9.5, 10.58, 11.35, 11.58, 10.88, 10.0, 8.69, 7.61, 7.03, 5.68, 5.28, 5.13,
  4.84, 4.39, 4.68, 3.96, 4.09, 5.21, 6.26, 7.39, 8.06, 8.32, 8.54, 8.8, 9.6, 10.87, 11.79, 12.01,
  11.17, 10.26, 9.01, 8.01, 7.44, 6.04, 5.44, 5.32, 5.04, 4.59, 5.01, 4.35, 4.59, 5.88, 6.97, 8.26,
  9.09, 9.45, 9.8, 10.27, 11.09, 12.26, 13.15, 13.22, 12.22, 11.3, 9.88, 8.67, 8.0, 6.49, 5.6, 5.45,
  5.1, 4.6, 5.07, 4.37, 4.53, 5.79, 6.69, 7.88, 8.68, 9.01, 9.22, 9.35, 10.05, 11.2, 12.03, 12.28,
  11.52, 10.64, 9.36, 8.29, 7.68, 6.23, 5.52, 5.41, 5.1, 4.64, 5.28, 4.56, 4.82, 6.04, 7.04, 8.37,
  9.16, 9.55, 9.89, 10.34, 11.0, 11.99, 12.83, 13.07, 12.18, 11.16, 9.73, 8.64, 8.01, 6.49, 5.73,
  5.66, 5.4, 5.13, 5.56, 5.08, 5.32, 6.19, 7.2, 8.11, 8.89, 9.65, 10.22, 10.44, 10.63, 10.84, 11.56,
  11.95, 11.45, 10.74, 9.27, 8.18, 7.19, 6.43, 5.17, 5.05, 4.76, 4.68, 5.33, 4.78, 4.29, 4.53, 5.03,
  5.5, 5.93, 6.71, 7.25, 7.53, 7.97, 8.29, 8.83, 9.27, 9.3, 8.85, 8.11, 7.49, 6.84, 5.64, 5.26,
  5.12, 4.82, 4.42, 4.92, 4.32, 4.24, 5.22, 5.99, 7.08, 7.79, 8.12, 8.36, 8.61, 9.27, 10.34, 11.12,
  11.34, 10.83, 9.85, 8.71, 7.71, 7.15, 5.81, 5.33, 5.2, 4.88, 4.42, 4.8, 4.25, 4.19, 5.21, 6.06,
  7.15, 7.8, 8.16, 8.45, 8.71, 9.38, 10.46, 11.25, 11.44, 10.88, 9.85, 8.65, 7.6, 7.03, 5.69, 5.26,
  5.16, 4.89, 4.45, 4.97, 4.34, 4.22, 5.21, 6.04, 7.14, 7.85, 8.15, 8.39, 8.59, 9.2, 10.24, 11.0,
  11.3, 10.82, 9.87, 8.73, 7.74, 7.2, 5.85, 5.35, 5.26, 4.99, 4.53, 5.15, 4.5, 4.38, 5.33, 6.01,
  6.89, 7.39, 7.61, 7.78, 8.09, 8.86, 10.02, 10.82, 11.12, 10.64, 9.76, 8.67, 7.7, 7.18, 5.86, 5.35,
  5.23, 4.93, 4.47, 4.94, 4.45, 4.39, 5.48, 6.29, 7.35, 8.05, 8.33, 8.51, 8.76, 9.52, 10.7, 11.55,
  11.88, 11.3, 10.23, 8.91, 7.8, 7.18, 5.8, 5.33, 5.22, 4.98, 4.75, 4.81, 4.54, 4.49, 5.08, 5.71,
  6.31, 6.94, 7.63, 8.07, 8.25, 8.48, 8.73, 9.35, 9.78, 9.66, 8.92, 7.83, 6.96, 6.2, 5.63, 5.17,
  5.05, 4.76, 4.68, 5.33, 4.78, 4.29, 4.53, 5.03, 5.5, 5.93, 6.71, 7.25, 7.53, 7.97, 8.29, 8.83,
  9.27, 9.3, 8.85, 8.11, 7.49, 6.84, 5.64, 5.26, 5.12, 4.82, 4.42, 4.92, 4.32, 4.24, 5.22, 5.99,
  7.08, 7.79, 8.12, 8.36, 8.61, 9.27, 10.34, 11.12, 11.34, 10.83, 9.85, 8.71, 7.71, 7.15, 5.81,
  5.33, 5.2, 4.88, 4.42, 4.8, 4.25, 4.19, 5.21, 6.06, 7.15, 7.8, 8.16, 8.45, 8.71, 9.38, 10.46,
  11.25, 11.44, 10.88, 9.85, 8.65, 7.6, 7.03, 5.69, 5.26, 5.16, 4.89, 4.45, 4.97, 4.34, 4.22, 5.21,
  6.04, 7.14, 7.85, 8.15, 8.39, 8.59, 9.2, 10.24, 11.0, 11.3, 10.82, 9.87, 8.73, 7.74, 7.2, 5.85,
  5.35, 5.26, 4.99, 4.53, 5.15, 4.5, 4.38, 5.33, 6.01, 6.89, 7.39, 7.61, 7.78, 8.09, 8.86, 10.02,
  10.82, 11.12, 10.64, 9.76, 8.67, 7.7, 7.18, 5.86, 5.35, 5.23, 4.93, 4.47, 4.94, 4.45, 4.39, 5.48,
  6.29, 7.35, 8.05, 8.33, 8.51, 8.76, 9.52, 10.7, 11.55, 11.88, 11.3, 10.23, 8.91, 7.8, 7.18, 5.8,
  5.33, 5.22, 4.98, 4.75, 4.81, 4.54, 4.49, 5.08, 5.71, 6.31, 6.94, 7.63, 8.07, 8.25, 8.48, 8.73,
  9.35, 9.78, 9.66, 8.92, 7.83, 6.96, 6.2, 5.63, 5.17, 5.05, 4.76, 4.68, 5.33, 4.78, 4.29, 4.53,
  5.03, 5.5, 5.93, 6.71, 7.25, 7.53, 7.97, 8.29, 8.83, 9.27, 9.3, 8.85, 8.11, 7.49, 6.84, 5.64,
  5.26, 5.12, 4.82, 4.42, 4.92, 4.32, 4.24, 5.22, 5.99, 7.08, 7.79, 8.12, 8.36, 8.61, 9.27, 10.34,
  11.12, 11.34, 10.83, 9.85, 8.71, 7.71, 7.15, 5.81, 5.33, 5.2, 4.88, 4.42, 4.8, 4.25, 4.19, 5.21,
  6.06, 7.15, 7.8, 8.16, 8.45, 8.71, 9.38, 10.46, 11.25, 11.44, 10.88, 9.85, 8.65, 7.6, 7.03, 5.69,
  5.26, 5.16, 4.89, 4.45, 4.97, 4.34, 4.22, 5.21, 6.04, 7.14, 7.85, 8.15, 8.39, 8.59, 9.2, 10.24,
  11.0, 11.3, 10.82, 9.87, 8.73, 7.74, 7.2, 5.85, 5.35, 5.26, 4.99, 4.53, 5.15, 4.5, 4.38, 5.33,
  6.01, 6.89, 7.39, 7.61, 7.78, 8.09, 8.86, 10.02, 10.82, 11.12, 10.64, 9.76, 8.67, 7.7, 7.18, 5.86,
  5.35, 5.23, 4.93, 4.47, 4.94, 4.45, 4.39, 5.48, 6.29, 7.35, 8.05, 8.33, 8.51, 8.76, 9.52, 10.7,
  11.55, 11.88, 11.3, 10.23, 8.91, 7.8, 7.18, 5.8, 5.33, 5.22, 4.98, 4.75, 4.81, 4.54, 4.49, 5.08,
  5.71, 6.31, 6.94, 7.63, 8.07, 8.25, 8.48, 8.73, 9.35, 9.78, 9.66, 8.92, 7.83, 6.96, 6.2, 5.63,
  5.17, 5.05, 4.76, 4.68, 5.33, 4.78, 4.29, 4.53, 5.03, 5.5, 5.93, 6.71, 7.25, 7.53, 7.97, 8.29,
  8.83, 9.27, 9.3, 8.85, 8.11, 7.49, 6.84, 5.64, 5.26, 5.12, 4.82, 4.42, 4.92, 4.32, 4.24, 5.22,
  5.99, 7.08, 7.79, 8.12, 8.36, 8.61, 9.27, 10.34, 11.12, 11.34, 10.83, 9.85, 8.71, 7.71, 7.15,
  5.81, 5.33, 5.2, 4.88, 4.42, 4.8, 4.25, 4.19, 5.21, 6.06, 7.15, 7.8, 8.16, 8.45, 8.71, 9.38,
  10.46, 11.25, 11.44, 10.88, 9.85, 8.65, 7.6, 7.03, 5.69, 5.26, 5.16, 4.89, 4.45, 4.97, 4.34, 4.22,
  5.21, 6.04, 7.14, 7.85, 8.15, 8.39, 8.59, 9.2, 10.24, 11.0, 11.3, 10.82, 9.87, 8.73, 7.74, 7.2,
  5.85, 5.35, 5.26, 4.99, 4.53, 5.15, 4.5, 4.38, 5.33, 6.01, 6.89, 7.39, 7.61, 7.78, 8.09, 8.86,
  10.02, 10.82, 11.12, 10.64, 9.76, 8.67, 7.7, 7.18, 5.86, 5.35, 5.23, 4.93, 4.47, 4.94, 4.45, 4.39,
  5.48, 6.29, 7.35, 8.05, 8.33, 8.51, 8.76, 9.52, 10.7, 11.55, 11.88, 11.3, 10.23, 8.91, 7.8, 7.18,
  5.8, 5.33, 5.22, 4.98, 4.75, 4.81, 4.54, 4.49, 5.08, 5.71, 6.31, 6.94, 7.63, 8.07, 8.25, 8.48,
  8.73, 9.35, 9.78, 9.66, 8.92, 7.83, 6.96, 6.2, 5.63, 5.17, 5.05, 4.76, 4.68, 5.33, 4.78, 4.29,
  4.53, 5.03, 5.5, 5.93, 6.71, 7.25, 7.53, 7.97, 8.29, 8.83, 9.27, 9.3, 8.85, 8.11, 7.49, 6.84,
  5.64, 5.26, 5.12, 4.82, 4.42, 4.92, 4.32, 4.24, 5.22, 5.99, 7.08, 7.79, 8.12, 8.36, 8.61, 9.27,
  10.34, 11.12, 11.34, 10.83, 9.85, 8.71, 7.71, 7.15, 5.81, 5.07, 4.95, 4.68, 4.26, 4.59, 4.17,
  3.92, 4.7, 5.26, 5.84, 6.17, 6.44, 6.7, 7.01, 7.71, 8.73, 9.55, 10.11, 9.64, 8.86, 7.87, 6.99,
  6.5, 5.27, 5.06, 4.97, 4.71, 4.3, 4.63, 4.19, 3.92, 4.68, 5.25, 5.85, 6.2, 6.48, 6.7, 6.97, 7.54,
  8.46, 9.29, 9.92, 9.53, 8.8, 7.89, 7.02, 6.55, 5.31, 5.12, 5.02, 4.76, 4.33, 4.68, 4.26, 3.98,
  4.8, 5.39, 6.1, 6.49, 6.72, 6.9, 7.14, 7.77, 8.8, 9.57, 10.11, 9.67, 8.88, 7.91, 7.02, 6.53, 5.29,
  5.07, 4.96, 4.68, 4.27, 4.58, 4.15, 3.9, 4.66, 5.2, 5.73, 5.99, 6.23, 6.42, 6.71, 7.36, 8.31,
  9.14, 9.78, 9.38, 8.68, 7.77, 6.92, 6.45, 5.24, 5.04, 4.94, 4.73, 4.57, 4.58, 4.45, 4.18, 4.48,
  4.78, 5.09, 5.53, 6.01, 6.33, 6.53, 6.81, 7.16, 7.75, 8.54, 8.42, 7.93, 7.09, 6.39, 5.75, 5.31,
  5.01, 4.91, 4.65, 4.62, 5.26, 4.95, 4.35, 4.43, 4.7, 4.91, 5.21, 5.84, 6.2, 6.43, 6.86, 7.19,
  7.71, 8.51, 8.42, 8.1, 7.54, 6.96, 6.39, 5.27, 5.06, 4.94, 4.65, 4.25, 4.57, 4.17, 3.92, 4.67,
  5.23, 5.84, 6.19, 6.47, 6.69, 7.0, 7.66, 8.64, 9.46, 10.05, 9.56, 8.81, 7.86, 7.0, 6.52, 5.28,
  5.07, 4.95, 4.68, 4.26, 4.59, 4.17, 3.92, 4.7, 5.26, 5.84, 6.17, 6.44, 6.7, 7.01, 7.71, 8.73,
  9.55, 10.11, 9.64, 8.86, 7.87, 6.99, 6.5, 5.27, 5.06, 4.97, 4.71, 4.3, 4.63, 4.19, 3.92, 4.68,
  5.25, 5.85, 6.2, 6.48, 6.7, 6.97, 7.54, 8.46, 9.29, 9.92, 9.53, 8.8, 7.89, 7.02, 6.55, 5.31, 5.12,
  5.02, 4.76, 4.33, 4.68, 4.26, 3.98, 4.8, 5.39, 6.1, 6.49, 6.72, 6.9, 7.14, 7.77, 8.8, 9.57, 10.11,
  9.67, 8.88, 7.91, 7.02, 6.53, 5.29, 5.07, 4.96, 4.68, 4.27, 4.58, 4.15, 3.9, 4.66, 5.2, 5.73,
  5.99, 6.23, 6.42, 6.71, 7.36, 8.31, 9.14, 9.78, 9.38, 8.68, 7.77, 6.92, 6.45, 5.24, 5.04, 4.94,
  4.73, 4.57, 4.58, 4.45, 4.18, 4.48, 4.78, 5.09, 5.53, 6.01, 6.33, 6.53, 6.81, 7.16, 7.75, 8.54,
  8.42, 7.93, 7.09, 6.39, 5.75, 5.31, 5.01, 4.91, 4.65, 4.62, 5.26, 4.95, 4.35, 4.43, 4.7, 4.91,
  5.21, 5.84, 6.2, 6.43, 6.86, 7.19, 7.71, 8.51, 8.42, 8.1, 7.54, 6.96, 6.39, 5.27, 5.06, 4.94,
  4.65, 4.25, 4.57, 4.17, 3.92, 4.67, 5.23, 5.84, 6.19, 6.47, 6.69, 7.0, 7.66, 8.64, 9.46, 10.05,
  9.56, 8.81, 7.86, 7.0, 6.52, 5.28, 5.07, 4.95, 4.68, 4.26, 4.59, 4.17, 3.92, 4.7, 5.26, 5.84,
  6.17, 6.44, 6.7, 7.01, 7.71, 8.73, 9.55, 10.11, 9.64, 8.86, 7.87, 6.99, 6.5, 5.27, 5.06, 4.97,
  4.71, 4.3, 4.63, 4.19, 3.92, 4.68, 5.25, 5.85, 6.2, 6.48, 6.7, 6.97, 7.54, 8.46, 9.29, 9.92, 9.53,
  8.8, 7.89, 7.02, 6.55, 5.31, 5.12, 5.02, 4.76, 4.33, 4.68, 4.26, 3.98, 4.8, 5.39, 6.1, 6.49, 6.72,
  6.9, 7.14, 7.77, 8.8, 9.57, 10.11, 9.67, 8.88, 7.91, 7.02, 6.53, 5.29, 5.07, 4.96, 4.68, 4.27,
  4.58, 4.15, 3.9, 4.66, 5.2, 5.73, 5.99, 6.23, 6.42, 6.71, 7.36, 8.31, 9.14, 9.78, 9.38, 8.68,
  7.77, 6.92, 6.45, 5.24, 5.04, 4.94, 4.73, 4.57, 4.58, 4.45, 4.18, 4.48, 4.78, 5.09, 5.53, 6.01,
  6.33, 6.53, 6.81, 7.16, 7.75, 8.54, 8.42, 7.93, 7.09, 6.39, 5.75, 5.31, 5.01, 4.91, 4.65, 4.62,
  5.26, 4.95, 4.35, 4.43, 4.7, 4.91, 5.21, 5.84, 6.2, 6.43, 6.86, 7.19, 7.71, 8.51, 8.42, 8.1, 7.54,
  6.96, 6.39, 5.27, 5.06, 4.94, 4.65, 4.25, 4.57, 4.17, 3.92, 4.67, 5.23, 5.84, 6.19, 6.47, 6.69,
  7.0, 7.66, 8.64, 9.46, 10.05, 9.56, 8.81, 7.86, 7.0, 6.52, 5.28, 5.07, 4.95, 4.68, 4.26, 4.59,
  4.17, 3.92, 4.7, 5.26, 5.84, 6.17, 6.44, 6.7, 7.01, 7.71, 8.73, 9.55, 10.11, 9.64, 8.86, 7.87,
  6.99, 6.5, 5.27, 5.06, 4.97, 4.71, 4.3, 4.63, 4.19, 3.92, 4.68, 5.25, 5.85, 6.2, 6.48, 6.7, 6.97,
  7.54, 8.46, 9.29, 9.92, 9.53, 8.8, 7.89, 7.02, 6.55, 5.31, 5.12, 5.02, 4.76, 4.33, 4.68, 4.26,
  3.98, 4.8, 5.39, 6.1, 6.49, 6.72, 6.9, 7.14, 7.77, 8.8, 9.57, 10.11, 9.67, 8.88, 7.91, 7.02, 6.53,
  5.29, 5.07, 4.96, 4.68, 4.27, 4.58, 4.15, 3.9, 4.66, 5.2, 5.73, 5.99, 6.23, 6.42, 6.71, 7.36,
  8.31, 9.14, 9.78, 9.38, 8.68, 7.77, 6.92, 6.45, 5.24, 5.04, 4.94, 4.73, 4.57, 4.58, 4.45, 4.18,
  4.48, 4.78, 5.09, 5.53, 6.01, 6.33, 6.53, 6.81, 7.16, 7.75, 8.54, 8.42, 7.93, 7.09, 6.39, 5.75,
  5.31, 5.01, 4.91, 4.65, 4.62, 5.26, 4.95, 4.35, 4.43, 4.7, 4.91, 5.21, 5.84, 6.2, 6.43, 6.86,
  7.19, 7.71, 8.51, 8.42, 8.1, 7.54, 6.96, 6.39, 5.27, 5.06, 4.94, 4.65, 4.25, 4.57, 4.17, 3.92,
  4.67, 5.23, 5.84, 6.19, 6.47, 6.69, 7.0, 7.66, 8.64, 9.46, 10.05, 9.56, 8.81, 7.86, 7.0, 6.52,
  5.28, 5.07, 4.95, 4.68, 4.26, 4.59, 4.17, 3.92, 4.7, 5.26, 5.84, 6.17, 6.44, 6.7, 7.01, 7.71,
  8.73, 9.55, 10.11, 9.64, 8.86, 7.87, 6.99, 6.5, 5.27, 5.06, 4.97, 4.71, 4.3, 4.63, 4.19, 3.92,
  4.68, 5.25, 5.85, 6.2, 6.48, 6.7, 6.97, 7.54, 8.46, 9.29, 9.92, 9.53, 8.8, 7.89, 7.02, 6.55, 5.31,
  5.12, 5.02, 4.76, 4.33, 4.68, 4.26, 3.98, 4.8, 5.39, 6.1, 6.49, 6.72, 6.9, 7.14, 7.77, 8.8, 9.57,
  10.11, 9.67, 8.88, 7.91, 7.02, 6.53, 5.29, 5.19, 5.03, 4.89, 4.59, 4.33, 4.52, 4.1, 4.04, 4.83,
  5.36, 5.79, 6.0, 6.19, 6.41, 6.76, 7.44, 8.45, 9.32, 9.58, 9.11, 8.41, 7.56, 6.79, 6.2, 5.27,
  5.01, 4.88, 4.88, 4.68, 4.6, 4.59, 4.39, 4.29, 4.65, 4.97, 5.34, 5.8, 6.22, 6.49, 6.72, 6.96,
  7.48, 8.22, 8.58, 8.36, 7.81, 7.01, 6.32, 5.7, 5.3, 5.0, 4.85, 4.67, 4.83, 5.24, 4.82, 4.41, 4.59,
  4.88, 5.14, 5.52, 6.05, 6.36, 6.6, 6.97, 7.46, 8.11, 8.45, 8.31, 7.95, 7.42, 6.85, 6.14, 5.17,
  5.06, 4.94, 4.65, 4.26, 4.6, 4.07, 3.88, 4.7, 5.23, 5.74, 5.93, 6.15, 6.32, 6.61, 7.2, 8.24, 9.2,
  9.61, 9.22, 8.58, 7.71, 6.87, 6.43, 5.22, 5.04, 4.94, 4.66, 4.27, 4.59, 4.09, 3.88, 4.72, 5.25,
  5.78, 6.01, 6.25, 6.41, 6.67, 7.23, 8.27, 9.21, 9.64, 9.25, 8.6, 7.72, 6.9, 6.45, 5.24, 5.05,
  4.97, 4.72, 4.32, 4.64, 4.16, 3.92, 4.74, 5.24, 5.78, 6.0, 6.2, 6.36, 6.65, 7.22, 8.32, 9.28,
  9.71, 9.35, 8.68, 7.8, 6.95, 6.48, 5.21, 5.03, 4.92, 4.63, 4.37, 4.56, 4.13, 4.07, 4.83, 5.34,
  5.77, 5.96, 6.14, 6.34, 6.67, 7.31, 8.34, 9.2, 9.48, 9.07, 8.41, 7.56, 6.81, 6.21, 5.19, 5.03,
  4.89, 4.59, 4.33, 4.52, 4.1, 4.04, 4.83, 5.36, 5.79, 6.0, 6.19, 6.41, 6.76, 7.44, 8.45, 9.32,
  9.58, 9.11, 8.41, 7.56, 6.79, 6.2, 5.27, 5.01, 4.88, 4.68, 4.6, 4.59, 4.39, 4.29, 4.65, 4.97,
  5.34, 5.8, 6.22, 6.49, 6.72, 6.96, 7.48, 8.22, 8.58, 8.36, 7.81, 7.01, 6.32, 5.7, 5.3, 5.0, 4.85,
  4.67, 4.83, 5.24, 4.82, 4.41, 4.59, 4.88, 5.14, 5.52, 6.05, 6.36, 6.6, 6.97, 7.46, 8.11, 8.45,
  8.31, 7.95, 7.42, 6.85, 6.14, 5.17, 5.06, 4.94, 4.65, 4.26, 4.6, 4.07, 3.88, 4.7, 5.23, 5.74,
  5.93, 6.15, 6.32, 6.61, 7.2, 8.24, 9.2, 9.61, 9.22, 8.58, 7.71, 6.87, 6.43, 5.22, 5.04, 4.94,
  4.66, 4.27, 4.59, 4.09, 3.88, 4.72, 5.25, 5.78, 6.01, 6.25, 6.41, 6.67, 7.23, 8.27, 9.21, 9.64,
  9.25, 8.6, 7.72, 6.9, 6.45, 5.24, 5.05, 4.97, 4.72, 4.32, 4.64, 4.16, 3.92, 4.74, 5.24, 5.78, 6.0,
  6.2, 6.36, 6.65, 7.22, 8.32, 9.28, 9.71, 9.35, 8.68, 7.8, 6.95, 6.48, 5.21, 5.03, 4.92, 4.63,
  4.37, 4.56, 4.13, 4.07, 4.83, 5.34, 5.77, 5.96, 6.14, 6.34, 6.67, 7.31, 8.34, 9.2, 9.48, 9.07,
  8.41, 7.56, 6.81, 6.21, 5.19, 5.03, 4.89, 4.59, 4.33, 4.52, 4.1, 4.04, 4.83, 5.36, 5.79, 6.0,
  6.19, 6.41, 6.76, 7.44, 8.45, 9.32, 9.58, 9.11, 8.41, 7.56, 6.79, 6.2, 5.27, 5.01, 4.88, 4.68,
  4.6, 4.59, 4.39, 4.29, 4.65, 4.97, 5.34, 5.8, 6.22, 6.49, 6.72, 6.96, 7.48, 8.22, 8.58, 8.36,
  7.81, 7.01, 6.32, 5.7, 5.3, 5.0, 4.85, 4.67, 4.83, 5.24, 4.82, 4.41, 4.59, 4.88, 5.14, 5.52, 6.05,
  6.36, 6.6, 6.97, 7.46, 8.11, 8.45, 8.31, 7.95, 7.42, 6.85, 6.14, 5.17, 5.06, 4.94, 4.65, 4.26,
  4.6, 4.07, 3.88, 4.7, 5.23, 5.74, 5.93, 6.15, 6.32, 6.61, 7.2, 8.24, 9.2, 9.61, 9.22, 8.58, 7.71,
  6.87, 6.43, 5.22, 5.04, 4.94, 4.66, 4.27, 4.59, 4.09, 3.88, 4.72, 5.25, 5.78, 6.01, 6.25, 6.41,
  6.67, 7.23, 8.27, 9.21, 9.64, 9.25, 8.6, 7.72, 6.9, 6.45, 5.24, 5.05, 4.97, 4.72, 4.32, 4.64,
  4.16, 3.92, 4.74, 5.24, 5.78, 6.0, 6.2, 6.36, 6.65, 7.22, 8.32, 9.28, 9.71, 9.35, 8.68, 7.8, 6.95,
  6.48, 5.21, 5.03, 4.92, 4.63, 4.37, 4.56, 4.13, 4.07, 4.83, 5.34, 5.77, 5.96, 6.14, 6.34, 6.67,
  7.31, 8.34, 9.2, 9.48, 9.07, 8.41, 7.56, 6.81, 6.21, 5.19, 5.03, 4.89, 4.59, 4.33, 4.52, 4.1,
  4.04, 4.83, 5.36, 5.79, 6.0, 6.19, 6.41, 6.76, 7.44, 8.45, 9.32, 9.58, 9.11, 8.41, 7.56, 6.79,
  6.2, 5.27, 5.01, 4.88, 4.68, 4.6, 4.59, 4.39, 4.29, 4.65, 4.97, 5.34, 5.8, 6.22, 6.49, 6.72, 6.96,
  7.48, 8.22, 8.58, 8.36, 7.81, 7.01, 6.32, 5.7, 5.3, 5.0, 4.85, 4.67, 4.83, 5.24, 4.82, 4.41, 4.59,
  4.88, 5.14, 5.52, 6.05, 6.36, 6.6, 6.97, 7.46, 8.11, 8.45, 8.31, 7.95, 7.42, 6.85, 6.14, 5.17,
  5.06, 4.94, 4.65, 4.26, 4.6, 4.07, 3.88, 4.7, 5.23, 5.74, 5.93, 6.15, 6.32, 6.61, 7.2, 8.24, 9.2,
  9.61, 9.22, 8.58, 7.71, 6.87, 6.43, 5.22, 5.04, 4.94, 4.66, 4.27, 4.59, 4.09, 3.88, 4.72, 5.25,
  5.78, 6.01, 6.25, 6.41, 6.67, 7.23, 8.27, 9.21, 9.64, 9.25, 8.6, 7.72, 6.9, 6.45, 5.24, 5.05,
  4.97, 4.72, 4.32, 4.64, 4.16, 3.92, 4.74, 5.24, 5.78, 6.0, 6.2, 6.36, 6.65, 7.22, 8.32, 9.28,
  9.71, 9.35, 8.68, 7.8, 6.95, 6.48, 5.21, 5.03, 4.92, 4.63, 4.37, 4.56, 4.13, 4.07, 4.83, 5.34,
  5.77, 5.96, 6.14, 6.34, 6.67, 7.31, 8.34, 9.2, 9.48, 9.07, 8.41, 7.56, 6.81, 6.21, 5.19, 5.03,
  4.89, 4.59, 4.33, 4.52, 4.1, 4.04, 4.83, 5.36, 5.79, 6.0, 6.19, 6.41, 6.76, 7.44, 8.45, 9.32,
  9.58, 9.11, 8.41, 7.56, 6.79, 6.2, 5.27, 5.01, 4.88, 4.68, 4.6, 4.59, 4.39, 4.29, 4.65, 4.97,
  5.34, 5.8, 6.22, 6.49, 6.72, 6.96, 7.48, 8.22, 8.58, 8.36, 7.81, 7.01, 6.32, 5.7, 5.36, 5.04,
  4.94, 4.69, 4.74, 5.39, 5.13, 4.54, 4.63, 4.88, 5.08, 5.4, 5.96, 6.32, 6.53, 6.92, 7.44, 7.96,
  8.49, 8.42, 8.13, 7.61, 7.05, 6.47, 5.18, 5.09, 4.97, 4.68, 4.3, 4.66, 4.26, 4.02, 4.79, 5.33,
  5.81, 6.0, 6.2, 6.37, 6.66, 7.24, 8.29, 9.19, 9.65, 9.25, 8.64, 7.76, 6.92, 6.49, 5.24, 5.06,
  4.96, 4.7, 4.29, 4.66, 4.25, 3.99, 4.79, 5.33, 5.81, 5.98, 6.18, 6.36, 6.64, 7.23, 8.3, 9.19,
  9.63, 9.23, 8.62, 7.75, 6.92, 6.48, 5.24, 5.08, 5.01, 4.77, 4.37, 4.71, 4.32, 4.06, 4.82, 5.32,
  5.79, 5.96, 6.14, 6.32, 6.61, 7.15, 8.25, 9.12, 9.59, 9.23, 8.63, 7.78, 6.93, 6.51, 5.24, 5.07,
  5.0, 4.76, 4.35, 4.7, 4.31, 4.06, 4.8, 5.33, 5.79, 5.95, 6.15, 6.32, 6.61, 7.16, 8.24, 9.12, 9.61,
  9.23, 8.63, 7.79, 6.93, 6.5, 5.24, 5.07, 4.98, 4.72, 4.31, 4.66, 4.27, 4.03, 4.8, 5.34, 5.82,
  5.99, 6.2, 6.37, 6.66, 7.25, 8.31, 9.21, 9.66, 9.27, 8.65, 7.77, 6.92, 6.5, 5.36, 5.06, 4.98,
  4.78, 4.65, 4.7, 4.63, 4.39, 4.68, 4.97, 5.29, 5.69, 6.12, 6.39, 6.6, 6.86, 7.4, 7.97, 8.45, 8.32,
  7.91, 7.11, 6.44, 5.8, 5.36, 5.04, 4.94, 4.69, 4.74, 5.39, 5.13, 4.54, 4.63, 4.88, 5.08, 5.4,
  5.96, 6.32, 6.53, 6.92, 7.44, 7.96, 8.49, 8.42, 8.13, 7.61, 7.05, 6.47, 5.18, 5.09, 4.97, 4.68,
  4.3, 4.66, 4.26, 4.02, 4.79, 5.33, 5.81, 6.0, 6.2, 6.37, 6.66, 7.24, 8.29, 9.19, 9.65, 9.25, 8.64,
  7.76, 6.92, 6.49, 5.24, 5.06, 4.96, 4.7, 4.29, 4.66, 4.25, 3.99, 4.79, 5.33, 5.81, 5.98, 6.18,
  6.36, 6.64, 7.23, 8.3, 9.19, 9.63, 9.23, 8.62, 7.75, 6.92, 6.48, 5.24, 5.08, 5.01, 4.77, 4.37,
  4.71, 4.32, 4.06, 4.82, 5.32, 5.79, 5.96, 6.14, 6.32, 6.61, 7.15, 8.25, 9.12, 9.59, 9.23, 8.63,
  7.78, 6.93, 6.51, 5.24, 5.07, 5.0, 4.76, 4.35, 4.7, 4.31, 4.06, 4.8, 5.33, 5.79, 5.95, 6.15, 6.32,
  6.61, 7.16, 8.24, 9.12, 9.61, 9.23, 8.63, 7.79, 6.93, 6.5, 5.24, 5.07, 4.98, 4.72, 4.31, 4.66,
  4.27, 4.03, 4.8, 5.34, 5.82, 5.99, 6.2, 6.37, 6.66, 7.25, 8.31, 9.21, 9.66, 9.27, 8.65, 7.77,
  6.92, 6.5, 5.36, 5.06, 4.98, 4.78, 4.65, 4.7, 4.63, 4.39, 4.68, 4.97, 5.29, 5.69, 6.12, 6.39, 6.6,
  6.86, 7.4, 7.97, 8.45, 8.32, 7.91, 7.11, 6.44, 5.8, 5.36, 5.04, 4.94, 4.69, 4.74, 5.39, 5.13,
  4.54, 4.63, 4.88, 5.08, 5.4, 5.96, 6.32, 6.53, 6.92, 7.44, 7.96, 8.49, 8.42, 8.13, 7.61, 7.05,
  6.47, 5.18, 5.09, 4.97, 4.68, 4.3, 4.66, 4.26, 4.02, 4.79, 5.33, 5.81, 6.0, 6.2, 6.37, 6.66, 7.24,
  8.29, 9.19, 9.65, 9.25, 8.64, 7.76, 6.92, 6.49, 5.24, 5.06, 4.96, 4.7, 4.29, 4.66, 4.25, 3.99,
  4.79, 5.33, 5.81, 5.98, 6.18, 6.36, 6.64, 7.23, 8.3, 9.19, 9.63, 9.23, 8.62, 7.75, 6.92, 6.48,
  5.24, 5.08, 5.01, 4.77, 4.37, 4.71, 4.32, 4.06, 4.82, 5.32, 5.79, 5.96, 6.14, 6.32, 6.61, 7.15,
  8.25, 9.12, 9.59, 9.23, 8.63, 7.78, 6.93, 6.51, 5.24, 5.07, 5.0, 4.76, 4.35, 4.7, 4.31, 4.06, 4.8,
  5.33, 5.79, 5.95, 6.15, 6.32, 6.61, 7.16, 8.24, 9.12, 9.61, 9.23, 8.63, 7.79, 6.93, 6.5, 5.24,
  5.07, 4.98, 4.72, 4.31, 4.66, 4.27, 4.03, 4.8, 5.34, 5.82, 5.99, 6.2, 6.37, 6.66, 7.25, 8.31,
  9.21, 9.66, 9.27, 8.65, 7.77, 6.92, 6.5, 5.36, 5.06, 4.98, 4.78, 4.65, 4.7, 4.63, 4.39, 4.68,
  4.97, 5.29, 5.69, 6.12, 6.39, 6.6, 6.86, 7.4, 7.97, 8.45, 8.32, 7.91, 7.11, 6.44, 5.8, 5.36, 5.04,
  4.94, 4.69, 4.74, 5.39, 5.13, 4.54, 4.63, 4.88, 5.08, 5.4, 5.96, 6.32, 6.53, 6.92, 7.44, 7.96,
  8.49, 8.42, 8.13, 7.61, 7.05, 6.47, 5.18, 5.09, 4.97, 4.68, 4.3, 4.66, 4.26, 4.02, 4.79, 5.33,
  5.81, 6.0, 6.2, 6.37, 6.66, 7.24, 8.29, 9.19, 9.65, 9.25, 8.64, 7.76, 6.92, 6.49, 5.24, 5.06,
  4.96, 4.7, 4.29, 4.66, 4.25, 3.99, 4.79, 5.33, 5.81, 5.98, 6.18, 6.36, 6.64, 7.23, 8.3, 9.19,
  9.63, 9.23, 8.62, 7.75, 6.92, 6.48, 5.24, 5.08, 5.01, 4.77, 4.37, 4.71, 4.32, 4.06, 4.82, 5.32,
  5.79, 5.96, 6.14, 6.32, 6.61, 7.15, 8.25, 9.12, 9.59, 9.23, 8.63, 7.78, 6.93, 6.51, 5.24, 5.07,
  5.0, 4.76, 4.35, 4.7, 4.31, 4.06, 4.8, 5.33, 5.79, 5.95, 6.15, 6.32, 6.61, 7.16, 8.24, 9.12, 9.61,
  9.23, 8.63, 7.79, 6.93, 6.5, 5.24, 5.07, 4.98, 4.72, 4.31, 4.66, 4.27, 4.03, 4.8, 5.34, 5.82,
  5.99, 6.2, 6.37, 6.66, 7.25, 8.31, 9.21, 9.66, 9.27, 8.65, 7.77, 6.92, 6.5, 5.36, 5.06, 4.98,
  4.78, 4.65, 4.7, 4.63, 4.39, 4.68, 4.97, 5.29, 5.69, 6.12, 6.39, 6.6, 6.86, 7.4, 7.97, 8.45, 8.32,
  7.91, 7.11, 6.44, 5.8, 5.36, 5.04, 4.94, 4.69, 4.74, 5.39, 5.13, 4.54, 4.63, 4.88, 5.08, 5.4,
  5.96, 6.32, 6.53, 6.92, 7.44, 7.96, 8.49, 8.42, 8.13, 7.61, 7.05, 6.47, 5.18, 5.09, 4.97, 4.68,
  4.3, 4.66, 4.26, 4.02, 4.79, 5.33, 5.81, 6.0, 6.2, 6.37, 6.66, 7.24, 8.29, 9.19, 9.65, 9.25, 8.64,
  7.76, 6.92, 6.49, 5.24, 5.06, 4.96, 4.7, 4.29, 4.66, 4.25, 3.99, 4.79, 5.33, 5.81, 5.98, 6.18,
  6.36, 6.64, 7.23, 8.3, 9.19, 9.63, 9.23, 8.62, 7.75, 6.92, 6.48,
];

export default fullServiceRestaurantLoadProfile;
