import isHourInUse from '@generic/functions/isHourInUse';
import times from 'lodash/times';

const calcHasConflictingChargingWindows = (windows) => {
  const rawPlugSchedule = times(24, (i) => i).map((hour) =>
    windows.filter((window) => isHourInUse(window, hour))
  );
  return rawPlugSchedule.map((hour) => hour.length).some((count) => count > 1);
};

export default calcHasConflictingChargingWindows;
