import React, {useState} from 'react';
import {Typography, Box, Grid, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {useIntl} from 'react-intl';
import Layout from '@generic/components/Layout';
import ChargingFuelInsights from '@generic/pages/ChargingCalculator/ChargingFuelInsights';
import ChargingInputs from '@generic/pages/ChargingCalculator/ChargingInputs';
import ChargingTabs from '@generic/pages/ChargingCalculator/ChargingTabs';
import ChargingErrors from '@generic/pages/ChargingCalculator/ChargingErrors';
import ChargingWizard from '@generic/pages/ChargingCalculator/wizard/ChargingWizard';
import {useInputs} from '@bellawatt/use-inputs';
import CtaButton from '@generic/components/CtaButton';
import InputsDialog from '@generic/components/inputs/InputsDialog';
import calcVehicleSetErrors from '@generic/functions/vehicleSet/calcVehicleSetErrors';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    paddingBottom: 20,
    fontWeight: '800',
    textAlign: 'left',

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      height: 4,
      width: 85,
      backgroundColor: theme.palette.primary.light,
    },

    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  inputs: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  smallScreenFilterTrigger: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  smallScreenFilterTriggerButton: {
    width: 300,
  },
}));

export default function ChargingCalculator() {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const {chargingWizardShown, vehicleSets} = useInputs();
  const theme = useTheme();

  const [isSmallScreenFiltersOpen, setIsSmallScreenFiltersOpen] = useState(false);

  const isMediumOrSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  const errors = calcVehicleSetErrors(vehicleSets);

  return (
    <Layout>
      <Box mb={6} mt={3}>
        <Typography variant="h1" className={classes.header}>
          {formatMessage({id: 'charging.header'})}
        </Typography>
      </Box>

      <Box mb={6}>
        <ChargingFuelInsights />
      </Box>

      <Box mb={6} className={classes.smallScreenFilterTrigger}>
        <CtaButton
          className={classes.smallScreenFilterTriggerButton}
          onClick={() => setIsSmallScreenFiltersOpen(true)}
        >
          {formatMessage({id: 'charging.inputs.adjustFilters'})}
        </CtaButton>
      </Box>

      <Grid container spacing={isMediumOrSmallScreen ? 2 : 6}>
        <Grid item md={3} className={classes.inputs}>
          <ChargingInputs />
        </Grid>
        <Grid item xs={12} md={9}>
          {Object.values(errors).flat().length ? (
            <ChargingErrors errors={errors} />
          ) : (
            <ChargingTabs />
          )}
        </Grid>
      </Grid>

      {!chargingWizardShown && <ChargingWizard />}

      <InputsDialog
        isOpen={isSmallScreenFiltersOpen}
        onClose={() => setIsSmallScreenFiltersOpen(false)}
      >
        <ChargingInputs />
      </InputsDialog>
    </Layout>
  );
}
