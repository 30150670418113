import {SEASONS} from '@generic/data/assumptions';
import getMarketRateElements from './shared/getMarketRateElements';
import sharedConEdStaticRateElements from './shared/sharedConEdStaticRateElements';

// Service Classification ("SC") 9
// Rate 2
const RATE_NAME = 'sc9Rate2';

const MSC_OFFPEAK_ENERGY_CHARGES = {
  low: {
    H: 0.079428,
    I: 0.078776,
    J: 0.080602,
  },
  high: {
    H: 0.079428,
    I: 0.078776,
    J: 0.080602,
  },
};
const MSC_ONPEAK_ENERGY_CHARGES = {
  low: {
    H: 0.084923,
    I: 0.089778,
    J: 0.088759,
  },
  high: {
    H: 0.084923,
    I: 0.089778,
    J: 0.088759,
  },
};

const getMscCapacityCharge = (isLocatedInWestchester) => (isLocatedInWestchester ? 6.0 : 16.36);

const sc9Rate2 = ({zone, isHighTension, isOnMarketBasedRate, isLocatedInWestchester}) => ({
  name: RATE_NAME,
  title: `SC 9 Rate II`,
  minKw: 1500,
  rateElements: [
    ...sharedConEdStaticRateElements,
    ...getMarketRateElements(zone, isOnMarketBasedRate),
    {
      rateElementType: 'MonthlyDemand',
      name: 'MSC Capacity Charge',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: getMscCapacityCharge(isLocatedInWestchester),
          name: 'MSC Capacity Charge',
        },
      ],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      name: 'MSC Energy Charge',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: isOnMarketBasedRate
            ? 0
            : MSC_ONPEAK_ENERGY_CHARGES[isHighTension ? 'high' : 'low'][zone],
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21], // 8am to 10pm
          name: 'On Peak',
        },
        {
          charge: isOnMarketBasedRate
            ? 0
            : MSC_OFFPEAK_ENERGY_CHARGES[isHighTension ? 'high' : 'low'][zone],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 22, 23], // 10p to 8a
          name: 'Off Peak',
        },
      ],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: 500.0,
          name: 'First 5kW (min)',
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Energy Delivery Charge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: 0.0079,
          name: 'Energy Delivery Charge',
        },
      ],
    },
    {
      id: 'demandChargeRebate',
      rateElementType: 'MonthlyDemand',
      name: 'Dynamic Load Management ("DLM") Surcharge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: 0.32,
          name: 'CSRP & Term-/Auto-DLM Component',
        },
        {
          charge: 0.2,
          name: 'DLC & DLRP Component',
        },
      ],
    },
    {
      id: 'demandChargeRebate',
      rateElementType: 'DemandTimeOfUse',
      name: 'Demand Delivery Charge',
      billingCategory: 'delivery',
      rateComponents: [
        // ConEd does not describe all hours for demands, which may trigger warnings in console
        {
          charge: 10.94,
          months: SEASONS.map((season, i) => (season === 'SUMMER' ? i : -1)).filter((x) => x > -1),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17], // 8am to 6pm
          name: 'Summer - 8am to 6p',
        },
        {
          charge: 22.04,
          months: SEASONS.map((season, i) => (season === 'SUMMER' ? i : -1)).filter((x) => x > -1),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21], // 8am to 10pm
          name: 'Summer - 8am to 10p',
        },
        {
          charge: 17.56,
          months: SEASONS.map((season, i) => (season === 'WINTER' ? i : -1)).filter((x) => x > -1),
          hourStarts: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21], // 8am to 10pm
          name: 'Winter - 8am to 10p',
        },
        {
          charge: isHighTension ? 0 : SEASONS.map((season) => (season === 'WINTER' ? 5.29 : 17.56)), // yes, this is only a Low Tension charge
          name: 'All Hours',
        },
      ],
    },
  ],
});

export default sc9Rate2;
