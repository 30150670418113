const primarySchoolLoadProfile = [
  4.99, 5.08, 5.1, 5.09, 5.17, 5.39, 8.27, 9.72, 11.11, 11.56, 11.87, 11.94, 12.0, 11.97, 11.43,
  11.07, 10.36, 10.79, 10.62, 9.14, 7.04, 4.99, 4.74, 4.84, 4.83, 4.92, 4.95, 4.94, 4.99, 5.14,
  8.14, 9.67, 11.01, 11.53, 11.86, 11.93, 12.0, 11.92, 11.35, 11.0, 10.33, 10.77, 10.57, 9.11, 7.03,
  4.86, 4.63, 4.74, 4.83, 4.96, 5.02, 5.05, 5.23, 5.4, 8.26, 9.81, 11.13, 11.64, 11.98, 12.05,
  12.06, 11.96, 11.39, 11.03, 10.42, 10.79, 10.59, 9.12, 7.09, 5.0, 4.76, 4.83, 4.83, 4.89, 4.96,
  5.01, 5.06, 5.27, 8.22, 9.77, 11.06, 11.51, 11.8, 11.85, 11.84, 11.71, 11.12, 10.73, 10.13, 10.53,
  10.37, 8.91, 6.88, 4.75, 4.45, 4.49, 4.18, 4.15, 4.2, 4.22, 4.21, 4.11, 5.29, 4.24, 3.76, 3.6,
  3.55, 3.54, 3.48, 3.43, 3.42, 3.47, 3.93, 4.86, 5.03, 5.16, 5.29, 4.22, 4.32, 4.25, 3.7, 3.73,
  3.87, 3.89, 3.89, 3.94, 5.22, 3.49, 3.68, 3.53, 3.41, 3.36, 3.33, 3.29, 3.23, 3.27, 3.66, 4.65,
  4.79, 4.84, 4.9, 3.69, 3.78, 3.85, 4.45, 4.55, 4.55, 4.58, 4.62, 4.83, 7.89, 9.41, 10.79, 11.27,
  11.62, 11.76, 11.84, 11.75, 11.18, 10.86, 10.2, 10.7, 10.57, 9.11, 7.07, 5.05, 4.81, 4.91, 4.99,
  5.08, 5.1, 5.09, 5.17, 5.39, 8.27, 9.72, 11.11, 11.56, 11.87, 11.94, 12.0, 11.97, 11.43, 11.07,
  10.36, 10.79, 10.62, 9.14, 7.04, 4.99, 4.74, 4.84, 4.83, 4.92, 4.95, 4.94, 4.99, 5.14, 8.14, 9.67,
  11.01, 11.53, 11.86, 11.93, 12.0, 11.92, 11.35, 11.0, 10.33, 10.77, 10.57, 9.11, 7.03, 4.86, 4.63,
  4.74, 4.83, 4.96, 5.02, 5.05, 5.23, 5.4, 8.26, 9.81, 11.13, 11.64, 11.98, 12.05, 12.06, 11.96,
  11.39, 11.03, 10.42, 10.79, 10.59, 9.12, 7.09, 5.0, 4.76, 4.83, 4.83, 4.89, 4.96, 5.01, 5.06,
  5.27, 8.22, 9.77, 11.06, 11.51, 11.8, 11.85, 11.84, 11.71, 11.12, 10.73, 10.13, 10.53, 10.37,
  8.91, 6.88, 4.75, 4.45, 4.49, 4.18, 4.15, 4.2, 4.22, 4.21, 4.11, 5.29, 4.24, 3.76, 3.6, 3.55,
  3.54, 3.48, 3.43, 3.42, 3.47, 3.93, 4.86, 5.03, 5.16, 5.29, 4.22, 4.32, 4.25, 3.7, 3.73, 3.87,
  3.89, 3.89, 3.94, 5.22, 3.49, 3.68, 3.53, 3.41, 3.36, 3.33, 3.29, 3.23, 3.27, 3.66, 4.65, 4.79,
  4.84, 4.9, 3.69, 3.78, 3.85, 4.45, 4.55, 4.55, 4.58, 4.62, 4.83, 7.89, 9.41, 10.79, 11.27, 11.62,
  11.76, 11.84, 11.75, 11.18, 10.86, 10.2, 10.7, 10.57, 9.11, 7.07, 5.05, 4.81, 4.91, 4.99, 5.08,
  5.1, 5.09, 5.17, 5.39, 8.27, 9.72, 11.11, 11.56, 11.87, 11.94, 12.0, 11.97, 11.43, 11.07, 10.36,
  10.79, 10.62, 9.14, 7.04, 4.99, 4.74, 4.84, 4.83, 4.92, 4.95, 4.94, 4.99, 5.14, 8.14, 9.67, 11.01,
  11.53, 11.86, 11.93, 12.0, 11.92, 11.35, 11.0, 10.33, 10.77, 10.57, 9.11, 7.03, 4.86, 4.63, 4.74,
  4.83, 4.96, 5.02, 5.05, 5.23, 5.4, 8.26, 9.81, 11.13, 11.64, 11.98, 12.05, 12.06, 11.96, 11.39,
  11.03, 10.42, 10.79, 10.59, 9.12, 7.09, 5.0, 4.76, 4.83, 4.83, 4.89, 4.96, 5.01, 5.06, 5.27, 8.22,
  9.77, 11.06, 11.51, 11.8, 11.85, 11.84, 11.71, 11.12, 10.73, 10.13, 10.53, 10.37, 8.91, 6.88,
  4.75, 4.45, 4.49, 4.18, 4.15, 4.2, 4.22, 4.21, 4.11, 5.29, 4.24, 3.76, 3.6, 3.55, 3.54, 3.48,
  3.43, 3.42, 3.47, 3.93, 4.86, 5.03, 5.16, 5.29, 4.22, 4.32, 4.25, 3.7, 3.73, 3.87, 3.89, 3.89,
  3.94, 5.22, 3.49, 3.68, 3.53, 3.41, 3.36, 3.33, 3.29, 3.23, 3.27, 3.66, 4.65, 4.79, 4.84, 4.9,
  3.69, 3.78, 3.85, 4.45, 4.55, 4.55, 4.58, 4.62, 4.83, 7.89, 9.41, 10.79, 11.27, 11.62, 11.76,
  11.84, 11.75, 11.18, 10.86, 10.2, 10.7, 10.57, 9.11, 7.07, 5.05, 4.81, 4.91, 4.99, 5.08, 5.1,
  5.09, 5.17, 5.39, 8.27, 9.72, 11.11, 11.56, 11.87, 11.94, 12.0, 11.97, 11.43, 11.07, 10.36, 10.79,
  10.62, 9.14, 7.04, 4.99, 4.74, 4.84, 4.83, 4.92, 4.95, 4.94, 4.99, 5.14, 8.14, 9.67, 11.01, 11.53,
  11.86, 11.93, 12.0, 11.92, 11.35, 11.0, 10.33, 10.77, 10.57, 9.11, 7.03, 4.86, 4.63, 4.74, 4.83,
  4.96, 5.02, 5.05, 5.23, 5.4, 8.26, 9.81, 11.13, 11.64, 11.98, 12.05, 12.06, 11.96, 11.39, 11.03,
  10.42, 10.79, 10.59, 9.12, 7.09, 5.0, 4.76, 4.83, 4.83, 4.89, 4.96, 5.01, 5.06, 5.27, 8.22, 9.77,
  11.06, 11.51, 11.8, 11.85, 11.84, 11.71, 11.12, 10.73, 10.13, 10.53, 10.37, 8.91, 6.88, 4.75,
  4.45, 4.49, 4.18, 4.15, 4.2, 4.22, 4.21, 4.11, 5.29, 4.24, 3.76, 3.6, 3.55, 3.54, 3.48, 3.43,
  3.42, 3.47, 3.93, 4.86, 5.03, 5.16, 5.29, 4.22, 4.32, 4.25, 3.7, 3.73, 3.87, 3.89, 3.89, 3.94,
  5.22, 3.49, 3.68, 3.53, 3.41, 3.36, 3.33, 3.29, 3.23, 3.27, 3.66, 4.65, 4.79, 4.84, 4.9, 3.69,
  3.78, 3.85, 4.45, 4.55, 4.55, 4.58, 4.62, 4.83, 7.89, 9.41, 10.79, 11.27, 11.62, 11.76, 11.84,
  11.75, 11.18, 10.86, 10.2, 10.7, 10.57, 9.11, 7.07, 5.05, 4.81, 4.91, 4.99, 5.08, 5.1, 5.09, 5.17,
  5.39, 8.27, 9.72, 11.11, 11.56, 11.87, 11.94, 12.0, 11.97, 11.43, 11.07, 10.36, 10.79, 10.62,
  9.14, 7.04, 4.99, 4.74, 4.84, 4.83, 4.92, 4.95, 4.94, 4.99, 5.14, 8.14, 9.67, 11.01, 11.53, 11.86,
  11.93, 12.0, 11.92, 11.35, 11.0, 10.33, 10.77, 10.57, 9.11, 7.03, 4.86, 4.63, 4.74, 4.83, 4.96,
  5.02, 5.05, 5.23, 5.4, 8.26, 9.81, 11.13, 11.64, 11.98, 12.05, 12.06, 11.96, 11.39, 11.03, 10.42,
  10.79, 10.59, 9.12, 7.09, 5.0, 4.76, 4.83, 4.13, 4.19, 4.18, 4.16, 4.19, 4.28, 7.27, 8.77, 10.24,
  10.77, 11.13, 11.29, 11.41, 11.41, 10.71, 10.25, 9.27, 9.92, 10.12, 8.65, 6.59, 4.58, 4.23, 4.24,
  3.95, 3.87, 3.85, 3.76, 3.76, 3.7, 5.06, 3.86, 3.65, 3.49, 3.37, 3.25, 3.17, 3.19, 3.18, 3.24,
  3.41, 4.49, 4.99, 5.12, 5.25, 4.18, 4.27, 4.24, 3.68, 3.7, 3.74, 3.68, 3.72, 3.75, 5.02, 3.51,
  3.56, 3.39, 3.23, 3.15, 3.11, 3.07, 3.09, 3.11, 3.21, 4.2, 4.67, 4.76, 4.84, 3.68, 3.81, 3.89,
  4.53, 4.72, 4.77, 4.81, 4.89, 5.1, 7.77, 9.27, 10.88, 11.33, 11.62, 11.72, 11.76, 11.64, 11.04,
  10.68, 9.67, 10.25, 10.41, 8.94, 6.84, 4.76, 4.46, 4.53, 4.58, 4.67, 4.7, 4.72, 4.87, 5.02, 7.63,
  9.17, 10.77, 11.27, 11.65, 11.77, 11.82, 11.79, 11.22, 10.83, 9.78, 10.27, 10.49, 9.05, 6.95,
  4.86, 4.68, 4.74, 4.76, 4.9, 4.93, 4.95, 4.99, 5.11, 7.89, 9.41, 10.99, 11.4, 11.71, 11.81, 11.87,
  11.75, 11.11, 10.73, 9.71, 10.24, 10.5, 9.04, 6.96, 4.78, 4.48, 4.55, 4.57, 4.67, 4.69, 4.8, 4.82,
  4.96, 7.73, 9.07, 10.54, 10.96, 11.22, 11.33, 11.41, 11.34, 10.7, 10.27, 9.19, 9.76, 9.92, 8.44,
  6.41, 4.49, 4.14, 4.15, 4.13, 4.19, 4.18, 4.16, 4.19, 4.28, 7.27, 8.77, 10.24, 10.77, 11.13,
  11.29, 11.41, 11.41, 10.71, 10.25, 9.27, 9.92, 10.12, 8.65, 6.59, 4.58, 4.23, 4.24, 3.95, 3.87,
  3.85, 3.76, 3.76, 3.7, 5.06, 3.86, 3.65, 3.49, 3.37, 3.25, 3.17, 3.19, 3.18, 3.24, 3.41, 4.49,
  4.99, 5.12, 5.25, 4.18, 4.27, 4.24, 3.68, 3.7, 3.74, 3.68, 3.72, 3.75, 5.02, 3.51, 3.56, 3.39,
  3.23, 3.15, 3.11, 3.07, 3.09, 3.11, 3.21, 4.2, 4.67, 4.76, 4.84, 3.68, 3.81, 3.89, 4.53, 4.72,
  4.77, 4.81, 4.89, 5.1, 7.77, 9.27, 10.88, 11.33, 11.62, 11.72, 11.76, 11.64, 11.04, 10.68, 9.67,
  10.25, 10.41, 8.94, 6.84, 4.76, 4.46, 4.53, 4.58, 4.67, 4.7, 4.72, 4.87, 5.02, 7.63, 9.17, 10.77,
  11.27, 11.65, 11.77, 11.82, 11.79, 11.22, 10.83, 9.78, 10.27, 10.49, 9.05, 6.95, 4.86, 4.68, 4.74,
  4.76, 4.9, 4.93, 4.95, 4.99, 5.11, 7.89, 9.41, 10.99, 11.4, 11.71, 11.81, 11.87, 11.75, 11.11,
  10.73, 9.71, 10.24, 10.5, 9.04, 6.96, 4.78, 4.48, 4.55, 4.57, 4.67, 4.69, 4.8, 4.82, 4.96, 7.73,
  9.07, 10.54, 10.96, 11.22, 11.33, 11.41, 11.34, 10.7, 10.27, 9.19, 9.76, 9.92, 8.44, 6.41, 4.49,
  4.14, 4.15, 4.13, 4.19, 4.18, 4.16, 4.19, 4.28, 7.27, 8.77, 10.24, 10.77, 11.13, 11.29, 11.41,
  11.41, 10.71, 10.25, 9.27, 9.92, 10.12, 8.65, 6.59, 4.58, 4.23, 4.24, 3.95, 3.87, 3.85, 3.76,
  3.76, 3.7, 5.06, 3.86, 3.65, 3.49, 3.37, 3.25, 3.17, 3.19, 3.18, 3.24, 3.41, 4.49, 4.99, 5.12,
  5.25, 4.18, 4.27, 4.24, 3.68, 3.7, 3.74, 3.68, 3.72, 3.75, 5.02, 3.51, 3.56, 3.39, 3.23, 3.15,
  3.11, 3.07, 3.09, 3.11, 3.21, 4.2, 4.67, 4.76, 4.84, 3.68, 3.81, 3.89, 4.53, 4.72, 4.77, 4.81,
  4.89, 5.1, 7.77, 9.27, 10.88, 11.33, 11.62, 11.72, 11.76, 11.64, 11.04, 10.68, 9.67, 10.25, 10.41,
  8.94, 6.84, 4.76, 4.46, 4.53, 4.58, 4.67, 4.7, 4.72, 4.87, 5.02, 7.63, 9.17, 10.77, 11.27, 11.65,
  11.77, 11.82, 11.79, 11.22, 10.83, 9.78, 10.27, 10.49, 9.05, 6.95, 4.86, 4.68, 4.74, 4.76, 4.9,
  4.93, 4.95, 4.99, 5.11, 7.89, 9.41, 10.99, 11.4, 11.71, 11.81, 11.87, 11.75, 11.11, 10.73, 9.71,
  10.24, 10.5, 9.04, 6.96, 4.78, 4.48, 4.55, 4.57, 4.67, 4.69, 4.8, 4.82, 4.96, 7.73, 9.07, 10.54,
  10.96, 11.22, 11.33, 11.41, 11.34, 10.7, 10.27, 9.19, 9.76, 9.92, 8.44, 6.41, 4.49, 4.14, 4.15,
  4.13, 4.19, 4.18, 4.16, 4.19, 4.28, 7.27, 8.77, 10.24, 10.77, 11.13, 11.29, 11.41, 11.41, 10.71,
  10.25, 9.27, 9.92, 10.12, 8.65, 6.59, 4.58, 4.23, 4.24, 3.95, 3.87, 3.85, 3.76, 3.76, 3.7, 5.06,
  3.86, 3.65, 3.49, 3.37, 3.25, 3.17, 3.19, 3.18, 3.24, 3.41, 4.49, 4.99, 5.12, 5.25, 4.18, 4.27,
  4.24, 3.68, 3.7, 3.74, 3.68, 3.72, 3.75, 5.02, 3.51, 3.56, 3.39, 3.23, 3.15, 3.11, 3.07, 3.09,
  3.11, 3.21, 4.2, 4.67, 4.76, 4.84, 3.68, 3.81, 3.89, 4.53, 4.72, 4.77, 4.81, 4.89, 5.1, 7.77,
  9.27, 10.88, 11.33, 11.62, 11.72, 11.76, 11.64, 11.04, 10.68, 9.67, 10.25, 10.41, 8.94, 6.84,
  4.76, 4.46, 4.53, 4.58, 4.67, 4.7, 4.72, 4.87, 5.02, 7.63, 9.17, 10.77, 11.27, 11.65, 11.77,
  11.82, 11.79, 11.22, 10.83, 9.78, 10.27, 10.49, 9.05, 6.95, 4.86, 4.68, 4.74, 4.76, 4.9, 4.93,
  4.95, 4.99, 5.11, 7.89, 9.41, 10.99, 11.4, 11.71, 11.81, 11.87, 11.75, 11.11, 10.73, 9.71, 10.24,
  10.5, 9.04, 6.96, 4.78, 4.48, 4.55, 4.57, 4.67, 4.69, 4.8, 4.82, 4.96, 7.73, 9.07, 10.54, 10.96,
  11.22, 11.33, 11.41, 11.34, 10.7, 10.27, 9.19, 9.76, 9.92, 8.44, 6.41, 4.49, 4.14, 4.15, 4.07,
  4.1, 4.09, 4.12, 4.2, 6.03, 7.94, 9.58, 10.54, 11.27, 11.98, 12.47, 12.58, 12.08, 11.34, 10.29,
  9.32, 9.02, 8.82, 6.97, 5.14, 4.17, 4.02, 4.0, 3.65, 3.58, 3.51, 3.47, 3.4, 3.94, 3.65, 3.4, 3.29,
  3.17, 3.06, 2.94, 2.84, 2.77, 2.75, 2.84, 3.0, 3.45, 4.58, 4.76, 4.3, 4.01, 3.98, 3.83, 3.33, 3.4,
  3.38, 3.4, 3.46, 4.49, 3.03, 3.3, 3.21, 2.99, 2.84, 2.7, 2.65, 2.62, 2.59, 2.58, 2.64, 3.07, 4.15,
  4.34, 3.68, 3.41, 3.46, 3.4, 4.12, 4.15, 4.15, 4.19, 4.27, 6.64, 8.34, 9.85, 10.58, 11.04, 11.22,
  11.32, 11.3, 10.83, 10.35, 9.37, 8.79, 8.81, 8.73, 6.85, 5.02, 4.22, 4.14, 4.14, 4.23, 4.25, 4.24,
  4.26, 4.32, 6.67, 8.4, 9.93, 10.64, 11.16, 11.89, 12.52, 12.72, 12.27, 11.73, 10.82, 10.19, 9.66,
  9.11, 6.96, 5.12, 4.3, 4.23, 4.21, 4.3, 4.49, 4.48, 4.52, 4.62, 6.99, 8.63, 10.1, 10.76, 11.17,
  11.32, 11.4, 11.36, 10.84, 10.3, 9.3, 8.74, 8.69, 8.56, 6.76, 4.93, 4.13, 4.04, 4.03, 4.14, 4.19,
  4.21, 4.25, 4.32, 6.36, 8.23, 9.8, 10.64, 11.02, 11.26, 11.53, 11.77, 11.51, 10.91, 9.76, 8.87,
  8.8, 8.78, 6.99, 5.22, 4.17, 4.04, 4.03, 4.07, 4.1, 4.09, 4.12, 4.2, 6.03, 7.94, 9.58, 10.54,
  11.27, 11.98, 12.47, 12.58, 12.08, 11.34, 10.29, 9.32, 9.02, 8.82, 6.97, 5.14, 4.17, 4.02, 4.0,
  3.65, 3.58, 3.51, 3.47, 3.4, 3.94, 3.65, 3.4, 3.29, 3.17, 3.06, 2.94, 2.84, 2.77, 2.75, 2.84, 3.0,
  3.45, 4.58, 4.76, 4.3, 4.01, 3.98, 3.83, 3.33, 3.4, 3.4, 3.46, 4.49, 3.03, 3.3, 3.21, 2.99, 2.84,
  2.7, 2.65, 2.62, 2.59, 2.58, 2.64, 3.07, 4.15, 4.34, 3.68, 3.41, 3.46, 3.4, 4.12, 4.15, 4.15,
  4.19, 4.27, 6.64, 8.34, 9.85, 10.58, 11.04, 11.22, 11.32, 11.3, 10.83, 10.35, 9.37, 8.79, 8.81,
  8.73, 6.85, 5.02, 4.22, 4.14, 4.14, 4.23, 4.25, 4.24, 4.26, 4.32, 6.67, 8.4, 9.93, 10.64, 11.16,
  11.89, 12.52, 12.72, 12.27, 11.73, 10.82, 10.19, 9.66, 9.11, 6.96, 5.12, 4.3, 4.23, 4.21, 4.3,
  4.49, 4.48, 4.52, 4.62, 6.99, 8.63, 10.1, 10.76, 11.17, 11.32, 11.4, 11.36, 10.84, 10.3, 9.3,
  8.74, 8.69, 8.56, 6.76, 4.93, 4.13, 4.04, 4.03, 4.14, 4.19, 4.21, 4.25, 4.32, 6.36, 8.23, 9.8,
  10.64, 11.02, 11.26, 11.53, 11.77, 11.51, 10.91, 9.76, 8.87, 8.8, 8.78, 6.99, 5.22, 4.17, 4.04,
  4.03, 4.07, 4.1, 4.09, 4.12, 4.2, 6.03, 7.94, 9.58, 10.54, 11.27, 11.98, 12.47, 12.58, 12.08,
  11.34, 10.29, 9.32, 9.02, 8.82, 6.97, 5.14, 4.17, 4.02, 4.0, 3.65, 3.58, 3.51, 3.47, 3.4, 3.94,
  3.65, 3.4, 3.29, 3.17, 3.06, 2.94, 2.84, 2.77, 2.75, 2.84, 3.0, 3.45, 4.58, 4.76, 4.3, 4.01, 3.98,
  3.83, 3.33, 3.4, 3.38, 3.4, 3.46, 4.49, 3.03, 3.3, 3.21, 2.99, 2.84, 2.7, 2.65, 2.62, 2.59, 2.58,
  2.64, 3.07, 4.15, 4.34, 3.68, 3.41, 3.46, 3.4, 4.12, 4.15, 4.15, 4.19, 4.27, 6.64, 8.34, 9.85,
  10.58, 11.04, 11.22, 11.32, 11.3, 10.83, 10.35, 9.37, 8.79, 8.81, 8.73, 6.85, 5.02, 4.22, 4.14,
  4.14, 4.23, 4.25, 4.24, 4.26, 4.32, 6.67, 8.4, 9.93, 10.64, 11.16, 11.89, 12.52, 12.72, 12.27,
  11.73, 10.82, 10.19, 9.66, 9.11, 6.96, 5.12, 4.3, 4.23, 4.21, 4.3, 4.49, 4.48, 4.52, 4.62, 6.99,
  8.63, 10.1, 10.76, 11.17, 11.32, 11.4, 11.36, 10.84, 10.3, 9.3, 8.74, 8.69, 8.56, 6.76, 4.93,
  4.13, 4.04, 4.03, 4.14, 4.19, 4.21, 4.25, 4.32, 6.36, 8.23, 9.8, 10.64, 11.02, 11.26, 11.53,
  11.77, 11.51, 10.91, 9.76, 8.87, 8.8, 8.78, 6.99, 5.22, 4.17, 4.04, 4.03, 4.07, 4.1, 4.09, 4.12,
  4.2, 6.03, 7.94, 9.58, 10.54, 11.27, 11.98, 12.47, 12.58, 12.08, 11.34, 10.29, 9.32, 9.02, 8.82,
  6.97, 5.14, 4.17, 4.02, 4.0, 3.65, 3.58, 3.51, 3.47, 3.4, 3.94, 3.65, 3.4, 3.29, 3.17, 3.06, 2.94,
  2.84, 2.77, 2.75, 2.84, 3.0, 3.45, 4.58, 4.76, 4.3, 4.01, 3.98, 3.83, 3.33, 3.4, 3.38, 3.4, 3.46,
  4.49, 3.03, 3.3, 3.21, 2.99, 2.84, 2.7, 2.65, 2.62, 2.59, 2.58, 2.64, 3.07, 4.15, 4.34, 3.68,
  3.41, 3.46, 3.4, 4.12, 4.15, 4.15, 4.19, 4.27, 6.64, 8.34, 9.85, 10.58, 11.04, 11.22, 11.32, 11.3,
  10.83, 10.35, 9.37, 8.79, 8.81, 8.73, 6.85, 5.02, 4.22, 4.14, 4.14, 4.23, 4.25, 4.24, 4.26, 4.32,
  6.67, 8.4, 9.93, 10.64, 11.16, 11.89, 12.52, 12.72, 12.27, 11.73, 10.82, 10.19, 9.66, 9.11, 6.96,
  5.12, 4.3, 4.23, 4.21, 4.3, 4.49, 4.48, 4.52, 4.62, 6.99, 8.63, 10.1, 10.76, 11.17, 11.32, 11.4,
  11.36, 10.84, 10.3, 9.3, 8.74, 8.69, 8.56, 6.76, 4.93, 4.13, 4.04, 4.03, 4.14, 4.19, 4.21, 4.25,
  4.32, 6.36, 8.23, 9.8, 10.64, 11.02, 11.26, 11.53, 11.77, 11.51, 10.91, 9.76, 8.87, 8.8, 8.78,
  6.99, 5.22, 4.17, 4.04, 4.03, 4.07, 4.1, 4.09, 4.12, 4.2, 6.03, 7.94, 9.58, 10.54, 11.27, 11.98,
  12.47, 12.58, 12.08, 11.34, 10.29, 9.32, 9.02, 8.82, 6.97, 5.14, 4.17, 4.02, 4.0, 3.65, 3.58,
  3.51, 3.47, 3.4, 3.94, 3.65, 3.4, 3.29, 3.17, 3.06, 2.94, 2.84, 2.77, 2.75, 2.84, 3.0, 3.45, 4.58,
  4.76, 4.3, 4.01, 3.98, 3.83, 3.33, 3.4, 3.38, 3.4, 3.46, 4.49, 3.03, 3.3, 3.21, 2.99, 2.84, 2.7,
  2.65, 2.62, 2.59, 2.58, 2.64, 3.07, 4.15, 4.34, 3.68, 3.41, 3.46, 3.4, 3.96, 3.95, 3.93, 3.94,
  4.01, 6.19, 8.29, 9.71, 10.43, 11.16, 11.57, 11.97, 12.19, 11.73, 11.3, 10.08, 9.43, 8.82, 7.59,
  5.71, 4.27, 3.87, 3.84, 3.79, 3.85, 3.85, 3.85, 3.89, 3.96, 6.15, 8.29, 9.75, 10.38, 10.98, 11.4,
  11.93, 12.11, 11.33, 10.5, 9.11, 8.6, 8.32, 7.51, 5.88, 4.32, 3.93, 3.91, 3.87, 3.94, 3.94, 3.91,
  3.97, 4.05, 6.23, 8.43, 9.88, 10.43, 10.89, 11.02, 11.17, 11.15, 10.53, 10.05, 8.97, 8.56, 8.29,
  7.51, 5.95, 4.32, 3.96, 3.95, 3.91, 3.98, 3.98, 3.96, 4.0, 4.04, 6.39, 8.54, 9.9, 10.49, 11.16,
  11.69, 12.18, 12.28, 11.58, 10.9, 9.53, 8.84, 8.33, 7.35, 5.7, 4.25, 3.89, 3.89, 3.85, 3.92, 3.91,
  3.89, 3.94, 4.01, 6.22, 8.47, 9.86, 10.56, 11.66, 12.5, 13.29, 13.71, 12.96, 12.31, 10.76, 9.82,
  9.27, 8.07, 6.13, 4.31, 3.9, 3.89, 3.85, 3.46, 3.41, 3.33, 3.32, 3.24, 3.37, 3.13, 3.04, 3.08,
  3.36, 3.66, 3.69, 3.67, 3.54, 3.36, 3.35, 3.37, 3.29, 3.96, 4.44, 3.83, 3.88, 3.81, 3.62, 3.21,
  3.25, 3.17, 3.22, 3.27, 3.8, 2.29, 2.94, 2.92, 2.73, 2.6, 2.52, 2.45, 2.42, 2.43, 2.54, 2.65, 2.7,
  3.54, 4.0, 3.28, 3.3, 3.33, 3.23, 3.96, 3.95, 3.93, 3.94, 4.01, 6.19, 8.29, 9.71, 10.43, 11.16,
  11.57, 11.97, 12.19, 11.73, 11.3, 10.08, 9.43, 8.82, 7.59, 5.71, 4.27, 3.87, 3.84, 3.79, 3.85,
  3.85, 3.85, 3.89, 3.96, 6.15, 8.29, 9.75, 10.38, 10.98, 11.4, 11.93, 12.11, 11.33, 10.5, 9.11,
  8.6, 8.32, 7.51, 5.88, 4.32, 3.93, 3.91, 3.87, 3.94, 3.94, 3.91, 3.97, 4.05, 6.23, 8.43, 9.88,
  10.43, 10.89, 11.02, 11.17, 11.15, 10.53, 10.05, 8.97, 8.56, 8.29, 7.51, 5.95, 4.32, 3.96, 3.95,
  3.91, 3.98, 3.98, 3.96, 4.0, 4.04, 6.39, 8.54, 9.9, 10.49, 11.16, 11.69, 12.18, 12.28, 11.58,
  10.9, 9.53, 8.84, 8.33, 7.35, 5.7, 4.25, 3.89, 3.89, 3.85, 3.92, 3.91, 3.89, 3.94, 4.01, 6.22,
  8.47, 9.86, 10.56, 11.66, 12.5, 13.29, 13.71, 12.96, 12.31, 10.76, 9.82, 9.27, 8.07, 6.13, 4.31,
  3.9, 3.89, 3.85, 3.46, 3.41, 3.33, 3.32, 3.24, 3.37, 3.13, 3.04, 3.08, 3.36, 3.66, 3.69, 3.67,
  3.54, 3.36, 3.35, 3.37, 3.29, 3.96, 4.44, 3.83, 3.88, 3.81, 3.62, 3.21, 3.25, 3.17, 3.22, 3.27,
  3.8, 2.29, 2.94, 2.92, 2.73, 2.6, 2.52, 2.45, 2.42, 2.43, 2.54, 2.65, 2.7, 3.54, 4.0, 3.28, 3.3,
  3.33, 3.23, 3.96, 3.95, 3.93, 3.94, 4.01, 6.19, 8.29, 9.71, 10.43, 11.16, 11.57, 11.97, 12.19,
  11.73, 11.3, 10.08, 9.43, 8.82, 7.59, 5.71, 4.27, 3.87, 3.84, 3.79, 3.85, 3.85, 3.85, 3.89, 3.96,
  6.15, 8.29, 9.75, 10.38, 10.98, 11.4, 11.93, 12.11, 11.33, 10.5, 9.11, 8.6, 8.32, 7.51, 5.88,
  4.32, 3.93, 3.91, 3.87, 3.94, 3.94, 3.91, 3.97, 4.05, 6.23, 8.43, 9.88, 10.43, 10.89, 11.02,
  11.17, 11.15, 10.53, 10.05, 8.97, 8.56, 8.29, 7.51, 5.95, 4.32, 3.96, 3.95, 3.91, 3.98, 3.98,
  3.96, 4.0, 4.04, 6.39, 8.54, 9.9, 10.49, 11.16, 11.69, 12.18, 12.28, 11.58, 10.9, 9.53, 8.84,
  8.33, 7.35, 5.7, 4.25, 3.89, 3.89, 3.85, 3.92, 3.91, 3.89, 3.94, 4.01, 6.22, 8.47, 9.86, 10.56,
  11.66, 12.5, 13.29, 13.71, 12.96, 12.31, 10.76, 9.82, 9.27, 8.07, 6.13, 4.31, 3.9, 3.89, 3.85,
  3.46, 3.41, 3.33, 3.32, 3.24, 3.37, 3.13, 3.04, 3.08, 3.36, 3.66, 3.69, 3.67, 3.54, 3.36, 3.35,
  3.37, 3.29, 3.96, 4.44, 3.83, 3.88, 3.81, 3.62, 3.21, 3.25, 3.17, 3.22, 3.27, 3.8, 2.29, 2.94,
  2.92, 2.73, 2.6, 2.52, 2.45, 2.42, 2.43, 2.54, 2.65, 2.7, 3.54, 4.0, 3.28, 3.3, 3.33, 3.23, 3.96,
  3.95, 3.93, 3.94, 4.01, 6.19, 8.29, 9.71, 10.43, 11.16, 11.57, 11.97, 12.19, 11.73, 11.3, 10.08,
  9.43, 8.82, 7.59, 5.71, 4.27, 3.87, 3.84, 3.79, 3.85, 3.85, 3.85, 3.89, 3.96, 6.15, 8.29, 9.75,
  10.38, 10.98, 11.4, 11.93, 12.11, 11.33, 10.5, 9.11, 8.6, 8.32, 7.51, 5.88, 4.32, 3.93, 3.91,
  3.87, 3.94, 3.94, 3.91, 3.97, 4.05, 6.23, 8.43, 9.88, 10.43, 10.89, 11.02, 11.17, 11.15, 10.53,
  10.05, 8.97, 8.56, 8.29, 7.51, 5.95, 4.32, 3.96, 3.95, 3.91, 3.98, 3.98, 3.96, 4.0, 4.04, 6.39,
  8.54, 9.9, 10.49, 11.16, 11.69, 12.18, 12.28, 11.58, 10.9, 9.53, 8.84, 8.33, 7.35, 5.7, 4.25,
  3.89, 3.89, 3.85, 3.92, 3.91, 3.89, 3.94, 4.01, 6.22, 8.47, 9.86, 10.56, 11.66, 12.5, 13.29,
  13.71, 12.96, 12.31, 10.76, 9.82, 9.27, 8.07, 6.13, 4.31, 3.9, 3.89, 3.85, 3.46, 3.41, 3.33, 3.32,
  3.24, 3.37, 3.13, 3.04, 3.08, 3.36, 3.66, 3.69, 3.67, 3.54, 3.36, 3.35, 3.37, 3.29, 3.96, 4.44,
  3.83, 3.88, 3.81, 3.62, 3.21, 3.25, 3.17, 3.22, 3.27, 3.8, 2.29, 2.94, 2.92, 2.73, 2.6, 2.52,
  2.45, 2.42, 2.43, 2.54, 2.65, 2.7, 3.54, 4.0, 3.28, 3.3, 3.33, 3.23, 3.96, 3.95, 3.93, 3.94, 4.01,
  6.19, 8.29, 9.71, 10.43, 11.16, 11.57, 11.97, 12.19, 11.73, 11.3, 10.08, 9.43, 8.82, 7.59, 5.71,
  4.27, 3.87, 3.84, 3.79, 3.85, 3.85, 3.85, 3.89, 3.96, 6.15, 8.29, 9.75, 10.38, 10.98, 11.4, 11.93,
  12.11, 11.33, 10.5, 9.11, 8.6, 8.32, 7.51, 5.88, 4.32, 3.93, 3.91, 3.87, 3.8, 3.78, 3.74, 3.78,
  3.48, 5.57, 9.02, 11.57, 13.12, 14.77, 15.92, 16.68, 17.09, 16.48, 15.74, 14.47, 13.26, 11.97,
  8.77, 6.4, 4.37, 3.93, 3.92, 3.85, 3.86, 3.81, 3.76, 3.77, 3.5, 5.98, 9.02, 11.39, 13.24, 15.23,
  17.02, 18.2, 18.67, 18.11, 17.03, 15.14, 13.78, 12.68, 9.65, 7.06, 4.44, 3.92, 3.85, 3.79, 3.84,
  3.82, 3.77, 3.8, 3.53, 5.4, 8.2, 10.42, 11.89, 12.98, 13.68, 14.04, 14.11, 13.46, 12.89, 11.13,
  9.69, 8.76, 6.93, 5.61, 4.25, 3.86, 3.85, 3.8, 3.4, 3.35, 3.24, 3.23, 2.68, 2.93, 2.88, 2.79, 2.8,
  2.9, 3.04, 3.06, 3.15, 3.12, 2.96, 2.7, 2.61, 2.68, 2.95, 4.14, 3.8, 3.83, 3.75, 3.54, 3.09, 3.12,
  3.04, 3.06, 2.72, 3.13, 2.27, 2.64, 2.62, 2.53, 2.41, 2.3, 2.18, 2.19, 2.26, 2.35, 2.34, 2.42,
  2.62, 3.78, 3.19, 3.21, 3.24, 3.13, 3.87, 3.87, 3.85, 3.85, 3.5, 5.75, 8.01, 9.53, 10.7, 12.3,
  13.79, 14.91, 15.56, 15.34, 15.17, 13.54, 12.05, 10.78, 8.16, 6.11, 4.32, 3.86, 3.81, 3.74, 3.8,
  3.79, 3.75, 3.77, 3.52, 5.54, 8.65, 10.91, 12.33, 13.92, 15.03, 16.05, 16.59, 16.32, 15.77, 14.04,
  12.38, 11.09, 8.43, 6.22, 4.32, 3.87, 3.82, 3.75, 3.8, 3.78, 3.74, 3.78, 3.48, 5.57, 9.02, 11.57,
  13.12, 14.77, 15.92, 16.68, 17.09, 16.48, 15.74, 14.47, 13.26, 11.97, 8.77, 6.4, 4.37, 3.93, 3.92,
  3.85, 3.86, 3.81, 3.76, 3.77, 3.5, 5.98, 9.02, 11.39, 13.24, 15.23, 17.02, 18.2, 18.67, 18.11,
  17.03, 15.14, 13.78, 12.68, 9.65, 7.06, 4.44, 3.92, 3.85, 3.79, 3.84, 3.82, 3.77, 3.8, 3.53, 5.4,
  8.2, 10.42, 11.89, 12.98, 13.68, 14.04, 14.11, 13.46, 12.89, 11.13, 9.69, 8.76, 6.93, 5.61, 4.25,
  3.86, 3.85, 3.8, 3.4, 3.35, 3.24, 3.23, 2.68, 2.93, 2.88, 2.79, 2.8, 2.9, 3.04, 3.06, 3.15, 3.12,
  2.96, 2.7, 2.61, 2.68, 2.95, 4.14, 3.8, 3.83, 3.75, 3.54, 3.09, 3.12, 3.04, 3.06, 2.72, 3.13,
  2.27, 2.64, 2.62, 2.53, 2.41, 2.3, 2.18, 2.19, 2.26, 2.35, 2.34, 2.42, 2.62, 3.78, 3.19, 3.21,
  3.24, 3.13, 3.87, 3.87, 3.85, 3.85, 3.5, 5.75, 8.01, 9.53, 10.7, 12.3, 13.79, 14.91, 15.56, 15.34,
  15.17, 13.54, 12.05, 10.78, 8.16, 6.11, 4.32, 3.86, 3.81, 3.74, 3.8, 3.79, 3.75, 3.77, 3.52, 5.54,
  8.65, 10.91, 12.33, 13.92, 15.03, 16.05, 16.59, 16.32, 15.77, 14.04, 12.38, 11.09, 8.43, 6.22,
  4.32, 3.87, 3.82, 3.75, 3.8, 3.78, 3.74, 3.78, 3.48, 5.57, 9.02, 11.57, 13.12, 14.77, 15.92,
  16.68, 17.09, 16.48, 15.74, 14.47, 13.26, 11.97, 8.77, 6.4, 4.37, 3.93, 3.92, 3.85, 3.86, 3.81,
  3.76, 3.77, 3.5, 5.98, 9.02, 11.39, 13.24, 15.23, 17.02, 18.2, 18.67, 18.11, 17.03, 15.14, 13.78,
  12.68, 9.65, 7.06, 4.44, 3.92, 3.85, 3.79, 3.84, 3.82, 3.77, 3.8, 3.53, 5.4, 8.2, 10.42, 11.89,
  12.98, 13.68, 14.04, 14.11, 13.46, 12.89, 11.13, 9.69, 8.76, 6.93, 5.61, 4.25, 3.86, 3.85, 3.8,
  3.4, 3.35, 3.24, 3.23, 2.68, 2.93, 2.88, 2.79, 2.8, 2.9, 3.04, 3.06, 3.15, 3.12, 2.96, 2.7, 2.61,
  2.68, 2.95, 4.14, 3.8, 3.83, 3.75, 3.54, 3.09, 3.12, 3.04, 3.06, 2.72, 3.13, 2.27, 2.64, 2.62,
  2.53, 2.41, 2.3, 2.18, 2.19, 2.26, 2.35, 2.34, 2.42, 2.62, 3.78, 3.19, 3.21, 3.24, 3.13, 3.87,
  3.87, 3.85, 3.85, 3.5, 5.75, 8.01, 9.53, 10.7, 12.3, 13.79, 14.91, 15.56, 15.34, 15.17, 13.54,
  12.05, 10.78, 8.16, 6.11, 4.32, 3.86, 3.81, 3.74, 3.8, 3.79, 3.75, 3.77, 3.52, 5.54, 8.65, 10.91,
  12.33, 13.92, 15.03, 16.05, 16.59, 16.32, 15.77, 14.04, 12.38, 11.09, 8.43, 6.22, 4.32, 3.87,
  3.82, 3.75, 3.8, 3.78, 3.74, 3.78, 3.48, 5.57, 9.02, 11.57, 13.12, 14.77, 15.92, 16.68, 17.09,
  16.48, 15.74, 14.47, 13.26, 11.97, 8.77, 6.4, 4.37, 3.93, 3.92, 3.85, 3.86, 3.81, 3.76, 3.77, 3.5,
  5.98, 9.02, 11.39, 13.24, 15.23, 17.02, 18.2, 18.67, 18.11, 17.03, 15.14, 13.78, 12.68, 9.65,
  7.06, 4.44, 3.92, 3.85, 3.79, 3.84, 3.82, 3.77, 3.8, 3.53, 5.4, 8.2, 10.42, 11.89, 12.98, 13.68,
  14.04, 14.11, 13.46, 12.89, 11.13, 9.69, 8.76, 6.93, 5.61, 4.25, 3.86, 3.85, 3.8, 3.4, 3.35, 3.24,
  3.23, 2.68, 2.93, 2.88, 2.79, 2.8, 2.9, 3.04, 3.06, 3.15, 3.12, 2.96, 2.7, 2.61, 2.68, 2.95, 4.14,
  3.8, 3.83, 3.75, 3.54, 3.09, 3.12, 3.04, 3.06, 2.72, 3.13, 2.27, 2.64, 2.62, 2.53, 2.41, 2.3,
  2.18, 2.19, 2.26, 2.35, 2.34, 2.42, 2.62, 3.78, 3.19, 3.21, 3.24, 3.13, 3.87, 3.87, 3.85, 3.85,
  3.5, 5.75, 8.01, 9.53, 10.7, 12.3, 13.79, 14.91, 15.56, 15.34, 15.17, 13.54, 12.05, 10.78, 8.16,
  6.11, 4.32, 3.86, 3.81, 3.74, 3.8, 3.79, 3.75, 3.77, 3.52, 5.54, 8.65, 10.91, 12.33, 13.92, 15.03,
  16.05, 16.59, 16.32, 15.77, 14.04, 12.38, 11.09, 8.43, 6.22, 4.32, 3.87, 3.82, 3.75, 3.8, 3.78,
  3.74, 3.78, 3.48, 5.57, 9.02, 11.57, 13.12, 14.77, 15.92, 16.68, 17.09, 16.48, 15.74, 14.47,
  13.26, 11.97, 8.77, 6.4, 4.37, 3.93, 3.92, 3.85, 3.86, 3.81, 3.76, 3.77, 3.5, 5.98, 9.02, 11.39,
  13.24, 15.23, 17.02, 18.2, 18.67, 18.11, 17.03, 15.14, 13.78, 12.68, 9.65, 7.06, 4.44, 3.92, 3.85,
  3.79, 3.84, 3.82, 3.77, 3.8, 3.53, 5.4, 8.2, 10.42, 11.89, 12.98, 13.68, 14.04, 14.11, 13.46,
  12.89, 11.13, 9.69, 8.76, 6.93, 5.61, 4.25, 3.86, 3.85, 3.8, 3.36, 3.35, 3.19, 3.14, 2.39, 2.43,
  2.48, 2.54, 2.69, 3.0, 3.52, 4.22, 4.86, 5.47, 5.77, 5.82, 5.7, 5.15, 4.26, 4.45, 3.75, 3.77, 3.7,
  3.5, 3.07, 3.15, 3.02, 3.04, 2.4, 2.28, 2.19, 2.45, 2.71, 3.04, 3.54, 3.88, 3.81, 4.03, 4.42,
  4.47, 4.06, 3.44, 2.84, 3.23, 3.1, 3.14, 3.19, 3.09, 3.45, 3.43, 3.41, 3.4, 2.87, 4.91, 7.38,
  9.81, 11.86, 13.66, 14.43, 14.48, 14.39, 13.63, 12.81, 11.32, 9.94, 8.87, 6.77, 5.11, 3.97, 3.54,
  3.48, 3.42, 3.46, 3.43, 3.4, 3.42, 2.87, 5.03, 7.32, 9.35, 11.04, 12.82, 14.13, 14.92, 15.56,
  15.26, 14.6, 12.97, 11.43, 10.44, 7.83, 5.58, 4.01, 3.56, 3.49, 3.43, 3.46, 3.44, 3.4, 3.42, 2.88,
  4.75, 6.6, 9.62, 12.0, 13.85, 15.17, 16.06, 16.4, 15.88, 15.06, 13.25, 11.92, 10.91, 8.61, 6.91,
  3.99, 3.57, 3.51, 3.46, 3.48, 3.45, 3.42, 3.44, 2.83, 4.74, 6.93, 10.13, 12.4, 13.91, 14.9, 15.65,
  15.83, 15.45, 14.58, 13.22, 12.38, 11.67, 9.38, 7.34, 4.05, 3.59, 3.52, 3.45, 3.55, 3.52, 3.48,
  3.49, 2.93, 4.76, 7.2, 10.42, 12.58, 14.53, 15.86, 16.37, 16.45, 16.2, 15.71, 14.28, 12.87, 11.71,
  9.32, 7.36, 4.13, 3.64, 3.57, 3.51, 3.36, 3.35, 3.19, 3.14, 2.39, 2.43, 2.48, 2.54, 2.69, 3.0,
  3.52, 4.22, 4.86, 5.47, 5.77, 5.82, 5.7, 5.15, 4.26, 4.45, 3.75, 3.77, 3.7, 3.5, 3.07, 3.15, 3.02,
  3.04, 2.4, 2.28, 2.19, 2.45, 2.71, 3.04, 3.54, 3.88, 3.81, 4.03, 4.42, 4.47, 4.06, 3.44, 2.84,
  3.23, 3.1, 3.14, 3.19, 3.09, 3.45, 3.43, 3.41, 3.4, 2.87, 4.91, 7.38, 9.81, 11.86, 13.66, 14.43,
  14.48, 14.39, 13.63, 12.81, 11.32, 9.94, 8.87, 6.77, 5.11, 3.97, 3.54, 3.48, 3.42, 3.46, 3.43,
  3.4, 3.42, 2.87, 5.03, 7.32, 9.35, 11.04, 12.82, 14.13, 14.92, 15.56, 15.26, 14.6, 12.97, 11.43,
  10.44, 7.83, 5.58, 4.01, 3.56, 3.49, 3.43, 3.46, 3.44, 3.4, 3.42, 2.88, 4.75, 6.6, 9.62, 12.0,
  13.85, 15.17, 16.06, 16.4, 15.88, 15.06, 13.25, 11.92, 10.91, 8.61, 6.91, 3.99, 3.57, 3.51, 3.46,
  3.48, 3.45, 3.42, 3.44, 2.83, 4.74, 6.93, 10.13, 12.4, 13.91, 14.9, 15.65, 15.83, 15.45, 14.58,
  13.22, 12.38, 11.67, 9.38, 7.34, 4.05, 3.59, 3.52, 3.45, 3.55, 3.52, 3.48, 3.49, 2.93, 4.76, 7.2,
  10.42, 12.58, 14.53, 15.86, 16.37, 16.45, 16.2, 15.71, 14.28, 12.87, 11.71, 9.32, 7.36, 4.13,
  3.64, 3.57, 3.51, 3.36, 3.35, 3.19, 3.14, 2.39, 2.43, 2.48, 2.54, 2.69, 3.0, 3.52, 4.22, 4.86,
  5.47, 5.77, 5.82, 5.7, 5.15, 4.26, 4.45, 3.75, 3.77, 3.7, 3.5, 3.07, 3.15, 3.02, 3.04, 2.4, 2.28,
  2.19, 2.45, 2.71, 3.04, 3.54, 3.88, 3.81, 4.03, 4.42, 4.47, 4.06, 3.44, 2.84, 3.23, 3.1, 3.14,
  3.19, 3.09, 3.45, 3.43, 3.41, 3.4, 2.87, 4.91, 7.38, 9.81, 11.86, 13.66, 14.43, 14.48, 14.39,
  13.63, 12.81, 11.32, 9.94, 8.87, 6.77, 5.11, 3.97, 3.54, 3.48, 3.42, 3.46, 3.43, 3.4, 3.42, 2.87,
  5.03, 7.32, 9.35, 11.04, 12.82, 14.13, 14.92, 15.56, 15.26, 14.6, 12.97, 11.43, 10.44, 7.83, 5.58,
  4.01, 3.56, 3.49, 3.43, 3.46, 3.44, 3.4, 3.42, 2.88, 4.75, 6.6, 9.62, 12.0, 13.85, 15.17, 16.06,
  16.4, 15.88, 15.06, 13.25, 11.92, 10.91, 8.61, 6.91, 3.99, 3.57, 3.51, 3.46, 3.48, 3.45, 3.42,
  3.44, 2.83, 4.74, 6.93, 10.13, 12.4, 13.91, 14.9, 15.65, 15.83, 15.45, 14.58, 13.22, 12.38, 11.67,
  9.38, 7.34, 4.05, 3.59, 3.52, 3.45, 3.55, 3.52, 3.48, 3.49, 2.93, 4.76, 7.2, 10.42, 12.58, 14.53,
  15.86, 16.37, 16.45, 16.2, 15.71, 14.28, 12.87, 11.71, 9.32, 7.36, 4.13, 3.64, 3.57, 3.51, 3.36,
  3.35, 3.19, 3.14, 2.39, 2.43, 2.48, 2.54, 2.69, 3.0, 3.52, 4.22, 4.86, 5.47, 5.77, 5.82, 5.7,
  5.15, 4.26, 4.45, 3.75, 3.77, 3.7, 3.5, 3.07, 3.15, 3.02, 3.04, 2.4, 2.28, 2.19, 2.45, 2.71, 3.04,
  3.54, 3.88, 3.81, 4.03, 4.42, 4.47, 4.06, 3.44, 2.84, 3.23, 3.1, 3.14, 3.19, 3.09, 3.45, 3.43,
  3.41, 3.4, 2.87, 4.91, 7.38, 9.81, 11.86, 13.66, 14.43, 14.48, 14.39, 13.63, 12.81, 11.32, 9.94,
  8.87, 6.77, 5.11, 3.97, 3.54, 3.48, 3.42, 3.46, 3.43, 3.4, 3.42, 2.87, 5.03, 7.32, 9.35, 11.04,
  12.82, 14.13, 14.92, 15.56, 15.26, 14.6, 12.97, 11.43, 10.44, 7.83, 5.58, 4.01, 3.56, 3.49, 3.43,
  3.46, 3.44, 3.4, 3.42, 2.88, 4.75, 6.6, 9.62, 12.0, 13.85, 15.17, 16.06, 16.4, 15.88, 15.06,
  13.25, 11.92, 10.91, 8.61, 6.91, 3.99, 3.57, 3.51, 3.46, 3.48, 3.45, 3.42, 3.44, 2.83, 4.74, 6.93,
  10.13, 12.4, 13.91, 14.9, 15.65, 15.83, 15.45, 14.58, 13.22, 12.38, 11.67, 9.38, 7.34, 4.05, 3.59,
  3.52, 3.45, 3.55, 3.52, 3.48, 3.49, 2.93, 4.76, 7.2, 10.42, 12.58, 14.53, 15.86, 16.37, 16.45,
  16.2, 15.71, 14.28, 12.87, 11.71, 9.32, 7.36, 4.13, 3.64, 3.57, 3.51, 3.36, 3.35, 3.19, 3.14,
  2.39, 2.43, 2.48, 2.54, 2.69, 3.0, 3.52, 4.22, 4.86, 5.47, 5.77, 5.82, 5.7, 5.15, 4.26, 4.45,
  3.75, 3.77, 3.7, 3.5, 3.07, 3.15, 3.02, 3.04, 2.4, 2.28, 2.19, 2.45, 2.71, 3.04, 3.54, 3.88, 3.81,
  4.03, 4.42, 4.47, 4.06, 3.44, 2.84, 3.23, 3.1, 3.14, 3.19, 3.09, 3.12, 3.11, 3.1, 3.1, 2.79, 3.43,
  4.17, 8.43, 10.46, 12.06, 13.39, 14.17, 14.65, 14.27, 12.7, 12.05, 11.99, 8.54, 8.1, 7.93, 3.63,
  3.31, 3.22, 3.15, 3.15, 3.12, 3.1, 3.12, 2.79, 3.44, 4.5, 9.57, 11.82, 13.14, 14.44, 15.6, 16.49,
  17.09, 16.58, 15.94, 15.16, 9.62, 9.08, 8.8, 3.87, 3.46, 3.31, 3.22, 3.22, 3.17, 3.14, 3.15, 2.77,
  3.49, 5.0, 11.05, 14.03, 16.15, 17.7, 18.57, 18.9, 19.0, 18.19, 17.56, 16.59, 10.39, 9.8, 9.38,
  4.08, 3.66, 3.46, 3.36, 3.29, 3.18, 3.14, 3.15, 2.78, 3.48, 5.12, 11.61, 14.53, 15.87, 16.37,
  15.94, 16.45, 17.29, 16.0, 15.06, 14.13, 9.02, 8.47, 8.18, 3.62, 3.32, 3.21, 3.15, 3.14, 3.11,
  3.09, 3.12, 2.82, 3.44, 4.2, 9.28, 11.6, 13.14, 14.07, 14.34, 14.9, 15.72, 15.46, 14.91, 14.04,
  9.1, 8.57, 8.31, 3.68, 3.33, 3.22, 3.16, 3.39, 3.44, 3.25, 3.21, 2.79, 2.07, 3.0, 3.27, 3.71, 4.3,
  4.92, 5.73, 6.37, 6.75, 6.26, 5.55, 5.11, 2.84, 2.79, 3.56, 3.77, 3.78, 3.75, 3.52, 3.14, 3.21,
  3.09, 3.12, 2.76, 2.01, 2.39, 2.71, 3.03, 3.42, 3.87, 4.26, 4.78, 5.26, 5.63, 5.64, 5.18, 2.42,
  2.19, 2.93, 3.12, 3.17, 3.25, 3.13, 3.12, 3.11, 3.1, 3.1, 2.79, 3.43, 4.17, 8.43, 10.46, 12.06,
  13.39, 14.17, 14.65, 14.27, 12.7, 12.05, 11.99, 8.54, 8.1, 7.93, 3.63, 3.31, 3.22, 3.15, 3.15,
  3.12, 3.1, 3.12, 2.79, 3.44, 4.5, 9.57, 11.82, 13.14, 14.44, 15.6, 16.49, 17.09, 16.58, 15.94,
  15.16, 9.62, 9.08, 8.8, 3.87, 3.46, 3.31, 3.22, 3.22, 3.17, 3.14, 3.15, 2.77, 3.49, 5.0, 11.05,
  14.03, 16.15, 17.7, 18.57, 18.9, 19.0, 18.19, 17.56, 16.59, 10.39, 9.8, 9.38, 4.08, 3.66, 3.46,
  3.36, 3.29, 3.18, 3.14, 3.15, 2.78, 3.48, 5.12, 11.61, 14.53, 15.87, 16.37, 15.94, 16.45, 17.29,
  16.0, 15.06, 14.13, 9.02, 8.47, 8.18, 3.62, 3.32, 3.21, 3.15, 3.14, 3.11, 3.09, 3.12, 2.82, 3.44,
  4.2, 9.28, 11.6, 13.14, 14.07, 14.34, 14.9, 15.72, 15.46, 14.91, 14.04, 9.1, 8.57, 8.31, 3.68,
  3.33, 3.22, 3.16, 3.39, 3.44, 3.25, 3.21, 2.79, 2.07, 3.0, 3.27, 3.71, 4.3, 4.92, 5.73, 6.37,
  6.75, 6.26, 5.55, 5.11, 2.84, 2.79, 3.56, 3.77, 3.78, 3.75, 3.52, 3.14, 3.21, 3.09, 3.12, 2.76,
  2.01, 2.39, 2.71, 3.03, 3.42, 3.87, 4.26, 4.78, 5.26, 5.63, 5.64, 5.18, 2.42, 2.19, 2.93, 3.12,
  3.17, 3.25, 3.13, 3.12, 3.11, 3.1, 3.1, 2.79, 3.43, 4.17, 8.43, 10.46, 12.06, 13.39, 14.17, 14.65,
  14.27, 12.7, 12.05, 11.99, 8.54, 8.1, 7.93, 3.63, 3.31, 3.22, 3.15, 3.15, 3.12, 3.1, 3.12, 2.79,
  3.44, 4.5, 9.57, 11.82, 13.14, 14.44, 15.6, 16.49, 17.09, 16.58, 15.94, 15.16, 9.62, 9.08, 8.8,
  3.87, 3.46, 3.31, 3.22, 3.22, 3.17, 3.14, 3.15, 2.77, 3.49, 5.0, 11.05, 14.03, 16.15, 17.7, 18.57,
  18.9, 19.0, 18.19, 17.56, 16.59, 10.39, 9.8, 9.38, 4.08, 3.66, 3.46, 3.36, 3.29, 3.18, 3.14, 3.15,
  2.78, 3.48, 5.12, 11.61, 14.53, 15.87, 16.37, 15.94, 16.45, 17.29, 16.0, 15.06, 14.13, 9.02, 8.47,
  8.18, 3.62, 3.32, 3.21, 3.15, 3.14, 3.11, 3.09, 3.12, 2.82, 3.44, 4.2, 9.28, 11.6, 13.14, 14.07,
  14.34, 14.9, 15.72, 15.46, 14.91, 14.04, 9.1, 8.57, 8.31, 3.68, 3.33, 3.22, 3.16, 3.39, 3.44,
  3.25, 3.21, 2.79, 2.07, 3.0, 3.27, 3.71, 4.3, 4.92, 5.73, 6.37, 6.75, 6.26, 5.55, 5.11, 2.84,
  2.79, 3.56, 3.77, 3.78, 3.75, 3.52, 3.14, 3.21, 3.09, 3.12, 2.76, 2.01, 2.39, 2.71, 3.03, 3.42,
  3.87, 4.26, 4.78, 5.26, 5.63, 5.64, 5.18, 2.42, 2.19, 2.93, 3.12, 3.17, 3.25, 3.13, 3.12, 3.11,
  3.1, 3.1, 2.79, 3.43, 4.17, 8.43, 10.46, 12.06, 13.39, 14.17, 14.65, 14.27, 12.7, 12.05, 11.99,
  8.54, 8.1, 7.93, 3.63, 3.31, 3.22, 3.15, 3.15, 3.12, 3.1, 3.12, 2.79, 3.44, 4.5, 9.57, 11.82,
  13.14, 14.44, 15.6, 16.49, 17.09, 16.58, 15.94, 15.16, 9.62, 9.08, 8.8, 3.87, 3.46, 3.31, 3.22,
  3.22, 3.17, 3.14, 3.15, 2.77, 3.49, 5.0, 11.05, 14.03, 16.15, 17.7, 18.57, 18.9, 19.0, 18.19,
  17.56, 16.59, 10.39, 9.8, 9.38, 4.08, 3.66, 3.46, 3.36, 3.29, 3.18, 3.14, 3.15, 2.78, 3.48, 5.12,
  11.61, 14.53, 15.87, 16.37, 15.94, 16.45, 17.29, 16.0, 15.06, 14.13, 9.02, 8.47, 8.18, 3.62, 3.32,
  3.21, 3.15, 3.14, 3.11, 3.09, 3.12, 2.82, 3.44, 4.2, 9.28, 11.6, 13.14, 14.07, 14.34, 14.9, 15.72,
  15.46, 14.91, 14.04, 9.1, 8.57, 8.31, 3.68, 3.33, 3.22, 3.16, 3.39, 3.44, 3.25, 3.21, 2.79, 2.07,
  3.0, 3.27, 3.71, 4.3, 4.92, 5.73, 6.37, 6.75, 6.26, 5.55, 5.11, 2.84, 2.79, 3.56, 3.77, 3.78,
  3.75, 3.52, 3.14, 3.21, 3.09, 3.12, 2.76, 2.01, 2.39, 2.71, 3.03, 3.42, 3.87, 4.26, 4.78, 5.26,
  5.63, 5.64, 5.18, 2.42, 2.19, 2.93, 3.12, 3.17, 3.25, 3.13, 3.12, 3.11, 3.1, 3.1, 2.79, 3.43,
  4.17, 8.43, 10.46, 12.06, 13.39, 14.17, 14.65, 14.27, 12.7, 12.05, 11.99, 8.54, 8.1, 7.93, 3.63,
  3.31, 3.22, 3.15, 3.15, 3.12, 3.1, 3.12, 2.79, 3.44, 4.5, 9.57, 11.82, 13.14, 14.44, 15.6, 16.49,
  17.09, 16.58, 15.94, 15.16, 9.62, 9.08, 8.8, 3.87, 3.46, 3.31, 3.22, 3.22, 3.17, 3.14, 3.15, 2.77,
  3.49, 5.0, 11.05, 14.03, 16.15, 17.7, 18.57, 18.9, 19.0, 18.19, 17.56, 16.59, 10.39, 9.8, 9.38,
  4.08, 3.66, 3.46, 3.36, 3.29, 3.28, 3.23, 3.24, 3.31, 3.95, 4.79, 8.75, 10.51, 11.74, 12.77,
  13.53, 14.05, 13.92, 13.13, 12.55, 11.79, 8.29, 7.92, 7.48, 3.72, 3.42, 3.33, 3.27, 3.28, 3.26,
  3.22, 3.23, 3.32, 3.96, 5.31, 9.3, 11.13, 12.66, 13.77, 14.71, 15.48, 16.02, 15.23, 14.29, 13.44,
  9.13, 8.71, 8.05, 3.9, 3.54, 3.43, 3.33, 3.46, 3.53, 3.31, 3.25, 3.17, 2.16, 3.52, 3.63, 4.09,
  4.79, 5.48, 6.11, 6.7, 6.89, 6.88, 6.8, 6.42, 3.1, 3.19, 3.84, 3.79, 3.79, 3.74, 3.52, 3.13, 3.22,
  3.1, 3.11, 3.2, 2.09, 2.8, 2.97, 3.06, 3.25, 3.81, 4.3, 4.69, 4.92, 4.76, 4.44, 3.95, 2.12, 2.27,
  3.06, 3.08, 3.14, 3.24, 3.12, 3.29, 3.27, 3.27, 3.25, 3.4, 3.71, 4.43, 7.99, 9.59, 10.45, 11.27,
  12.05, 12.73, 12.86, 11.92, 11.35, 10.59, 7.92, 7.77, 7.59, 3.67, 3.41, 3.32, 3.27, 3.3, 3.26,
  3.25, 3.26, 3.39, 4.06, 4.9, 7.75, 9.54, 10.82, 11.71, 12.25, 12.74, 12.85, 12.28, 11.98, 11.38,
  8.23, 7.69, 7.17, 3.74, 3.42, 3.35, 3.29, 3.27, 3.25, 3.23, 3.23, 3.31, 3.95, 4.81, 8.77, 10.93,
  12.23, 13.39, 14.34, 15.29, 16.0, 15.41, 14.69, 13.77, 8.93, 8.42, 7.99, 3.8, 3.46, 3.36, 3.29,
  3.29, 3.28, 3.23, 3.24, 3.31, 3.95, 4.79, 8.75, 10.51, 11.74, 12.77, 13.53, 14.05, 13.92, 13.13,
  12.55, 11.79, 8.29, 7.92, 7.48, 3.72, 3.42, 3.33, 3.27, 3.28, 3.26, 3.22, 3.23, 3.32, 3.96, 5.31,
  9.3, 11.13, 12.66, 13.77, 14.71, 15.48, 16.02, 15.23, 14.29, 13.44, 9.13, 8.71, 8.05, 3.9, 3.54,
  3.43, 3.33, 3.46, 3.53, 3.31, 3.25, 3.17, 2.16, 3.52, 3.63, 4.09, 4.79, 5.48, 6.11, 6.7, 6.89,
  6.88, 6.8, 6.42, 3.1, 3.19, 3.84, 3.79, 3.79, 3.74, 3.52, 3.13, 3.22, 3.1, 3.11, 3.2, 2.09, 2.8,
  2.97, 3.06, 3.25, 3.81, 4.3, 4.69, 4.92, 4.76, 4.44, 3.95, 2.12, 2.27, 3.06, 3.08, 3.14, 3.24,
  3.12, 3.29, 3.27, 3.27, 3.25, 3.4, 3.71, 4.43, 7.99, 9.59, 10.45, 11.27, 12.05, 12.73, 12.86,
  11.92, 11.35, 10.59, 7.92, 7.77, 7.59, 3.67, 3.41, 3.32, 3.27, 3.3, 3.26, 3.25, 3.26, 3.39, 4.06,
  4.9, 7.75, 9.54, 10.82, 11.71, 12.25, 12.74, 12.85, 12.28, 11.98, 11.38, 8.23, 7.69, 7.17, 3.74,
  3.42, 3.35, 3.29, 3.27, 3.25, 3.23, 3.23, 3.31, 3.95, 4.81, 8.77, 10.93, 12.23, 13.39, 14.34,
  15.29, 16.0, 15.41, 14.69, 13.77, 8.93, 8.42, 7.99, 3.8, 3.46, 3.36, 3.29, 3.29, 3.28, 3.23, 3.24,
  3.31, 3.95, 4.79, 8.75, 10.51, 11.74, 12.77, 13.53, 14.05, 13.92, 13.13, 12.55, 11.79, 8.29, 7.92,
  7.48, 3.72, 3.42, 3.33, 3.27, 3.28, 3.26, 3.22, 3.23, 3.32, 3.96, 5.31, 9.3, 11.13, 12.66, 13.77,
  14.71, 15.48, 16.02, 15.23, 14.29, 13.44, 9.13, 8.71, 8.05, 3.9, 3.54, 3.43, 3.33, 3.46, 3.53,
  3.31, 3.25, 3.17, 2.16, 3.52, 3.63, 4.09, 4.79, 5.48, 6.11, 6.7, 6.89, 6.88, 6.8, 6.42, 3.1, 3.19,
  3.84, 3.79, 3.79, 3.74, 3.52, 3.13, 3.22, 3.1, 3.11, 3.2, 2.09, 2.8, 2.97, 3.06, 3.25, 3.81, 4.3,
  4.69, 4.92, 4.76, 4.44, 3.95, 2.12, 2.27, 3.06, 3.08, 3.14, 3.24, 3.12, 3.29, 3.27, 3.27, 3.25,
  3.4, 3.71, 4.43, 7.99, 9.59, 10.45, 11.27, 12.05, 12.73, 12.86, 11.92, 11.35, 10.59, 7.92, 7.77,
  7.59, 3.67, 3.41, 3.32, 3.27, 3.3, 3.26, 3.25, 3.26, 3.39, 4.06, 4.9, 7.75, 9.54, 10.82, 11.71,
  12.25, 12.74, 12.85, 12.28, 11.98, 11.38, 8.23, 7.69, 7.17, 3.74, 3.42, 3.35, 3.29, 3.27, 3.25,
  3.23, 3.23, 3.31, 3.95, 4.81, 8.77, 10.93, 12.23, 13.39, 14.34, 15.29, 16.0, 15.41, 14.69, 13.77,
  8.93, 8.42, 7.99, 3.8, 3.46, 3.36, 3.29, 3.29, 3.28, 3.23, 3.24, 3.31, 3.95, 4.79, 8.75, 10.51,
  11.74, 12.77, 13.53, 14.05, 13.92, 13.13, 12.55, 11.79, 8.29, 7.92, 7.48, 3.72, 3.42, 3.33, 3.27,
  3.28, 3.26, 3.22, 3.23, 3.32, 3.96, 5.31, 9.3, 11.13, 12.66, 13.77, 14.71, 15.48, 16.02, 15.23,
  14.29, 13.44, 9.13, 8.71, 8.05, 3.9, 3.54, 3.43, 3.33, 3.46, 3.53, 3.31, 3.25, 3.17, 2.16, 3.52,
  3.63, 4.09, 4.79, 5.48, 6.11, 6.7, 6.89, 6.88, 6.8, 6.42, 3.1, 3.19, 3.84, 3.79, 3.79, 3.74, 3.52,
  3.13, 3.22, 3.1, 3.11, 3.2, 2.09, 2.8, 2.97, 3.06, 3.25, 3.81, 4.3, 4.69, 4.92, 4.76, 4.44, 3.95,
  2.12, 2.27, 3.06, 3.08, 3.14, 3.24, 3.12, 3.29, 3.27, 3.27, 3.25, 3.4, 3.71, 4.43, 7.99, 9.59,
  10.45, 11.27, 12.05, 12.73, 12.86, 11.92, 11.35, 10.59, 7.92, 7.77, 7.59, 3.67, 3.41, 3.32, 3.27,
  3.3, 3.26, 3.25, 3.26, 3.39, 4.06, 4.9, 7.75, 9.54, 10.82, 11.71, 12.25, 12.74, 12.85, 12.28,
  11.98, 11.38, 8.23, 7.69, 7.17, 3.74, 3.42, 3.35, 3.29, 3.27, 3.25, 3.23, 3.23, 3.31, 3.95, 4.81,
  8.77, 10.93, 12.23, 13.39, 14.34, 15.29, 16.0, 15.41, 14.69, 13.77, 8.93, 8.42, 7.99, 3.8, 3.46,
  3.36, 3.29, 3.29, 3.28, 3.23, 3.24, 3.31, 3.95, 4.79, 8.75, 10.51, 11.74, 12.77, 13.53, 14.05,
  13.92, 13.13, 12.55, 11.79, 8.29, 7.92, 7.48, 3.72, 3.42, 3.33, 3.27, 3.28, 3.26, 3.22, 3.23,
  3.32, 3.96, 5.31, 9.3, 11.13, 12.66, 13.77, 14.71, 15.48, 16.02, 15.23, 14.29, 13.44, 9.13, 8.71,
  8.05, 3.9, 3.54, 3.43, 3.33, 3.46, 3.53, 3.31, 3.25, 3.17, 2.16, 3.52, 3.63, 4.09, 4.79, 5.48,
  6.11, 6.7, 6.89, 6.88, 6.8, 6.42, 3.1, 3.19, 3.84, 3.79, 3.79, 3.74, 3.52, 3.01, 3.04, 2.95, 2.98,
  3.03, 3.35, 2.32, 2.51, 2.41, 2.22, 2.16, 2.23, 2.45, 2.63, 2.67, 2.66, 2.58, 2.63, 3.46, 3.52,
  3.14, 3.16, 3.18, 3.06, 3.79, 3.78, 3.76, 3.75, 3.91, 6.63, 8.6, 10.45, 12.0, 13.81, 15.05, 15.78,
  16.1, 15.37, 14.52, 13.11, 11.76, 10.73, 9.01, 6.25, 4.33, 3.87, 3.82, 3.75, 3.79, 3.78, 3.73,
  3.75, 3.9, 6.31, 8.48, 10.55, 12.37, 14.2, 15.25, 15.97, 16.39, 15.77, 14.83, 13.4, 12.02, 10.85,
  8.98, 6.13, 4.3, 3.85, 3.81, 3.75, 3.8, 3.78, 3.74, 3.76, 3.91, 6.48, 8.56, 10.5, 12.2, 14.26,
  15.5, 16.13, 16.37, 15.61, 14.54, 12.93, 11.57, 10.68, 8.91, 6.16, 4.33, 3.86, 3.82, 3.76, 3.81,
  3.8, 3.75, 3.78, 3.91, 6.99, 9.1, 10.82, 12.05, 13.4, 14.23, 14.72, 14.88, 14.37, 13.73, 12.5,
  11.31, 10.41, 8.74, 6.09, 4.32, 3.86, 3.81, 3.76, 3.8, 3.78, 3.74, 3.76, 3.92, 6.54, 8.92, 11.04,
  12.62, 14.41, 15.54, 16.13, 16.25, 15.5, 14.65, 13.16, 11.86, 11.03, 9.14, 6.13, 4.3, 3.85, 3.82,
  3.76, 3.33, 3.28, 3.15, 3.11, 3.06, 2.89, 2.25, 2.16, 2.27, 2.49, 2.75, 3.0, 3.21, 3.35, 3.36,
  3.19, 2.94, 2.93, 3.77, 3.88, 3.74, 3.78, 3.68, 3.48, 3.01, 3.04, 2.95, 2.98, 3.03, 3.35, 2.32,
  2.51, 2.41, 2.22, 2.16, 2.23, 2.45, 2.63, 2.67, 2.66, 2.58, 2.63, 3.46, 3.52, 3.14, 3.16, 3.18,
  3.06, 3.79, 3.78, 3.76, 3.75, 3.91, 6.63, 8.6, 10.45, 12.0, 13.81, 15.05, 15.78, 16.1, 15.37,
  14.52, 13.11, 11.76, 10.73, 9.01, 6.25, 4.33, 3.87, 3.82, 3.75, 3.79, 3.78, 3.73, 3.75, 3.9, 6.31,
  8.48, 10.55, 12.37, 14.2, 15.25, 15.97, 16.39, 15.77, 14.83, 13.4, 12.02, 10.85, 8.98, 6.13, 4.3,
  3.85, 3.81, 3.75, 3.8, 3.78, 3.74, 3.76, 3.91, 6.48, 8.56, 10.5, 12.2, 14.26, 15.5, 16.13, 16.37,
  15.61, 14.54, 12.93, 11.57, 10.68, 8.91, 6.16, 4.33, 3.86, 3.82, 3.76, 3.81, 3.8, 3.75, 3.78,
  3.91, 6.99, 9.1, 10.82, 12.05, 13.4, 14.23, 14.72, 14.88, 14.37, 13.73, 12.5, 11.31, 10.41, 8.74,
  6.09, 4.32, 3.86, 3.81, 3.76, 3.8, 3.78, 3.74, 3.76, 3.92, 6.54, 8.92, 11.04, 12.62, 14.41, 15.54,
  16.13, 16.25, 15.5, 14.65, 13.16, 11.86, 11.03, 9.14, 6.13, 4.3, 3.85, 3.82, 3.76, 3.33, 3.28,
  3.15, 3.11, 3.06, 2.89, 2.25, 2.16, 2.27, 2.49, 2.75, 3.0, 3.21, 3.35, 3.36, 3.19, 2.94, 2.93,
  3.77, 3.88, 3.74, 3.78, 3.68, 3.48, 3.01, 3.04, 2.95, 2.98, 3.03, 3.35, 2.32, 2.51, 2.41, 2.22,
  2.16, 2.23, 2.45, 2.63, 2.67, 2.66, 2.58, 2.63, 3.46, 3.52, 3.14, 3.16, 3.18, 3.06, 3.79, 3.78,
  3.76, 3.75, 3.91, 6.63, 8.6, 10.45, 12.0, 13.81, 15.05, 15.78, 16.1, 15.37, 14.52, 13.11, 11.76,
  10.73, 9.01, 6.25, 4.33, 3.87, 3.82, 3.75, 3.79, 3.78, 3.73, 3.75, 3.9, 6.31, 8.48, 10.55, 12.37,
  14.2, 15.25, 15.97, 16.39, 15.77, 14.83, 13.4, 12.02, 10.85, 8.98, 6.13, 4.3, 3.85, 3.81, 3.75,
  3.8, 3.78, 3.74, 3.76, 3.91, 6.48, 8.56, 10.5, 12.2, 14.26, 15.5, 16.13, 16.37, 15.61, 14.54,
  12.93, 11.57, 10.68, 8.91, 6.16, 4.33, 3.86, 3.82, 3.76, 3.81, 3.8, 3.75, 3.78, 3.91, 6.99, 9.1,
  10.82, 12.05, 13.4, 14.23, 14.72, 14.88, 14.37, 13.73, 12.5, 11.31, 10.41, 8.74, 6.09, 4.32, 3.86,
  3.81, 3.76, 3.8, 3.78, 3.74, 3.76, 3.92, 6.54, 8.92, 11.04, 12.62, 14.41, 15.54, 16.13, 16.25,
  15.5, 14.65, 13.16, 11.86, 11.03, 9.14, 6.13, 4.3, 3.85, 3.82, 3.76, 3.33, 3.28, 3.15, 3.11, 3.06,
  2.89, 2.25, 2.16, 2.27, 2.49, 2.75, 3.0, 3.21, 3.35, 3.36, 3.19, 2.94, 2.93, 3.77, 3.88, 3.74,
  3.78, 3.68, 3.48, 3.01, 3.04, 2.95, 2.98, 3.03, 3.35, 2.32, 2.51, 2.41, 2.22, 2.16, 2.23, 2.45,
  2.63, 2.67, 2.66, 2.58, 2.63, 3.46, 3.52, 3.14, 3.16, 3.18, 3.06, 3.79, 3.78, 3.76, 3.75, 3.91,
  6.63, 8.6, 10.45, 12.0, 13.81, 15.05, 15.78, 16.1, 15.37, 14.52, 13.11, 11.76, 10.73, 9.01, 6.25,
  4.33, 3.87, 3.82, 3.75, 3.79, 3.78, 3.73, 3.75, 3.9, 6.31, 8.48, 10.55, 12.37, 14.2, 15.25, 15.97,
  16.39, 15.77, 14.83, 13.4, 12.02, 10.85, 8.98, 6.13, 4.3, 3.85, 3.81, 3.75, 3.8, 3.78, 3.74, 3.76,
  3.91, 6.48, 8.56, 10.5, 12.2, 14.26, 15.5, 16.13, 16.37, 15.61, 14.54, 12.93, 11.57, 10.68, 8.91,
  6.16, 4.33, 3.86, 3.82, 3.76, 3.81, 3.8, 3.75, 3.78, 3.91, 6.99, 9.1, 10.82, 12.05, 13.4, 14.23,
  14.72, 14.88, 14.37, 13.73, 12.5, 11.31, 10.41, 8.74, 6.09, 4.32, 3.86, 3.81, 3.76, 3.8, 3.78,
  3.74, 3.76, 3.92, 6.54, 8.92, 11.04, 12.62, 14.41, 15.54, 16.13, 16.25, 15.5, 14.65, 13.16, 11.86,
  11.03, 9.14, 6.13, 4.3, 3.85, 3.82, 3.76, 3.33, 3.28, 3.15, 3.11, 3.06, 2.89, 2.25, 2.16, 2.27,
  2.49, 2.75, 3.0, 3.21, 3.35, 3.36, 3.19, 2.94, 2.93, 3.77, 3.88, 3.74, 3.78, 3.68, 3.48, 3.01,
  3.04, 2.95, 2.98, 3.03, 3.35, 2.32, 2.51, 2.41, 2.22, 2.16, 2.23, 2.45, 2.63, 2.67, 2.66, 2.58,
  2.63, 3.46, 3.52, 3.14, 3.16, 3.18, 3.06, 3.79, 3.78, 3.76, 3.75, 3.91, 6.63, 8.6, 10.45, 12.0,
  13.81, 15.05, 15.78, 16.1, 15.37, 14.52, 13.11, 11.76, 10.73, 9.01, 6.25, 4.33, 3.87, 3.82, 3.75,
  3.89, 3.88, 3.85, 3.87, 3.96, 6.78, 8.3, 9.68, 10.4, 11.27, 11.77, 12.28, 12.59, 12.03, 11.33,
  9.94, 9.08, 9.37, 7.78, 5.68, 4.28, 3.89, 3.86, 3.82, 3.89, 3.88, 3.86, 3.89, 3.99, 6.82, 8.39,
  9.72, 10.41, 11.27, 11.8, 12.28, 12.49, 11.8, 10.98, 9.47, 8.8, 9.19, 7.67, 5.64, 4.25, 3.85,
  3.82, 3.78, 3.83, 3.81, 3.77, 3.78, 3.9, 6.49, 8.0, 9.62, 10.67, 11.76, 12.32, 12.66, 12.8, 12.06,
  11.21, 9.56, 8.69, 9.0, 7.49, 5.43, 4.24, 3.84, 3.82, 3.77, 3.85, 3.84, 3.8, 3.83, 3.93, 6.69,
  8.25, 9.59, 10.21, 10.88, 11.28, 11.68, 11.8, 11.19, 10.56, 9.26, 8.68, 9.16, 7.67, 5.65, 4.29,
  3.9, 3.88, 3.83, 3.46, 3.42, 3.3, 3.27, 3.13, 4.34, 3.33, 3.01, 2.88, 2.85, 2.83, 2.73, 2.71,
  2.66, 2.62, 2.66, 2.82, 3.94, 4.3, 4.5, 3.82, 3.89, 3.8, 3.61, 3.18, 3.22, 3.14, 3.15, 3.2, 4.63,
  2.44, 2.96, 2.87, 2.69, 2.58, 2.52, 2.5, 2.43, 2.41, 2.46, 2.67, 3.87, 4.0, 4.13, 3.23, 3.27, 3.3,
  3.2, 3.9, 3.91, 3.88, 3.88, 3.97, 6.9, 8.36, 9.66, 10.35, 11.16, 11.69, 12.17, 12.36, 11.76,
  11.11, 9.79, 9.07, 9.42, 7.82, 5.73, 4.28, 3.9, 3.87, 3.83, 3.89, 3.88, 3.85, 3.87, 3.96, 6.78,
  8.3, 9.68, 10.4, 11.27, 11.77, 12.28, 12.59, 12.03, 11.33, 9.94, 9.08, 9.37, 7.78, 5.68, 4.28,
  3.89, 3.86, 3.82, 3.89, 3.88, 3.86, 3.89, 3.99, 6.82, 8.39, 9.72, 10.41, 11.27, 11.8, 12.28,
  12.49, 11.8, 10.98, 9.47, 8.8, 9.19, 7.67, 5.64, 4.25, 3.85, 3.82, 3.78, 3.83, 3.81, 3.77, 3.78,
  3.9, 6.49, 8.0, 9.62, 10.67, 11.76, 12.32, 12.66, 12.8, 12.06, 11.21, 9.56, 8.69, 9.0, 7.49, 5.43,
  4.24, 3.84, 3.82, 3.77, 3.85, 3.84, 3.8, 3.83, 3.93, 6.69, 8.25, 9.59, 10.21, 10.88, 11.28, 11.68,
  11.8, 11.19, 10.56, 9.26, 8.68, 9.16, 7.67, 5.65, 4.29, 3.9, 3.88, 3.83, 3.46, 3.42, 3.3, 3.27,
  3.13, 4.34, 3.33, 3.01, 2.88, 2.85, 2.83, 2.73, 2.71, 2.66, 2.62, 2.66, 2.82, 3.94, 4.3, 4.5,
  3.82, 3.89, 3.8, 3.61, 3.18, 3.22, 3.14, 3.15, 3.2, 4.63, 2.44, 2.96, 2.87, 2.69, 2.58, 2.52, 2.5,
  2.43, 2.41, 2.46, 2.67, 3.87, 4.0, 4.13, 3.23, 3.27, 3.3, 3.2, 3.9, 3.91, 3.88, 3.88, 3.97, 6.9,
  8.36, 9.66, 10.35, 11.16, 11.69, 12.17, 12.36, 11.76, 11.11, 9.79, 9.07, 9.42, 7.82, 5.73, 4.28,
  3.9, 3.87, 3.83, 3.89, 3.88, 3.85, 3.87, 3.96, 6.78, 8.3, 9.68, 10.4, 11.27, 11.77, 12.28, 12.59,
  12.03, 11.33, 9.94, 9.08, 9.37, 7.78, 5.68, 4.28, 3.89, 3.86, 3.82, 3.89, 3.88, 3.86, 3.89, 3.99,
  6.82, 8.39, 9.72, 10.41, 11.27, 11.8, 12.28, 12.49, 11.8, 10.98, 9.47, 8.8, 9.19, 7.67, 5.64,
  4.25, 3.85, 3.82, 3.78, 3.83, 3.81, 3.77, 3.78, 3.9, 6.49, 8.0, 9.62, 10.67, 11.76, 12.32, 12.66,
  12.8, 12.06, 11.21, 9.56, 8.69, 9.0, 7.49, 5.43, 4.24, 3.84, 3.82, 3.77, 3.85, 3.84, 3.8, 3.83,
  3.93, 6.69, 8.25, 9.59, 10.21, 10.88, 11.28, 11.68, 11.8, 11.19, 10.56, 9.26, 8.68, 9.16, 7.67,
  5.65, 4.29, 3.9, 3.88, 3.83, 3.46, 3.42, 3.3, 3.27, 3.13, 4.34, 3.33, 3.01, 2.88, 2.85, 2.83,
  2.73, 2.71, 2.66, 2.62, 2.66, 2.82, 3.94, 4.3, 4.5, 3.82, 3.89, 3.8, 3.61, 3.18, 3.22, 3.14, 3.15,
  3.2, 4.63, 2.44, 2.96, 2.87, 2.69, 2.58, 2.52, 2.5, 2.43, 2.41, 2.46, 2.67, 3.87, 4.0, 4.13, 3.23,
  3.27, 3.3, 3.2, 3.9, 3.91, 3.88, 3.88, 3.97, 6.9, 8.36, 9.66, 10.35, 11.16, 11.69, 12.17, 12.36,
  11.76, 11.11, 9.79, 9.07, 9.42, 7.82, 5.73, 4.28, 3.9, 3.87, 3.83, 3.89, 3.88, 3.85, 3.87, 3.96,
  6.78, 8.3, 9.68, 10.4, 11.27, 11.77, 12.28, 12.59, 12.03, 11.33, 9.94, 9.08, 9.37, 7.78, 5.68,
  4.28, 3.89, 3.86, 3.82, 3.89, 3.88, 3.86, 3.89, 3.99, 6.82, 8.39, 9.72, 10.41, 11.27, 11.8, 12.28,
  12.49, 11.8, 10.98, 9.47, 8.8, 9.19, 7.67, 5.64, 4.25, 3.85, 3.82, 3.78, 3.83, 3.81, 3.77, 3.78,
  3.9, 6.49, 8.0, 9.62, 10.67, 11.76, 12.32, 12.66, 12.8, 12.06, 11.21, 9.56, 8.69, 9.0, 7.49, 5.43,
  4.24, 3.84, 3.82, 3.77, 3.85, 3.84, 3.8, 3.83, 3.93, 6.69, 8.25, 9.59, 10.21, 10.88, 11.28, 11.68,
  11.8, 11.19, 10.56, 9.26, 8.68, 9.16, 7.67, 5.65, 4.29, 3.9, 3.88, 3.83, 3.46, 3.42, 3.3, 3.27,
  3.13, 4.34, 3.33, 3.01, 2.88, 2.85, 2.83, 2.73, 2.71, 2.66, 2.62, 2.66, 2.82, 3.94, 4.3, 4.5,
  3.82, 3.89, 3.8, 3.61, 3.18, 3.22, 3.14, 3.15, 3.2, 4.63, 2.44, 2.96, 2.87, 2.69, 2.58, 2.52, 2.5,
  2.43, 2.41, 2.46, 2.67, 3.87, 4.0, 4.13, 3.23, 3.27, 3.3, 3.2, 3.9, 3.91, 3.88, 3.88, 3.97, 6.9,
  8.36, 9.66, 10.35, 11.16, 11.69, 12.17, 12.36, 11.76, 11.11, 9.79, 9.07, 9.42, 7.82, 5.73, 4.28,
  3.9, 3.87, 3.83, 3.89, 3.88, 3.85, 3.87, 3.96, 6.78, 8.3, 9.68, 10.4, 11.27, 11.77, 12.28, 12.59,
  12.03, 11.33, 9.94, 9.08, 9.37, 7.78, 5.68, 4.28, 3.89, 3.86, 3.82, 3.89, 3.88, 3.86, 3.89, 3.99,
  6.82, 8.39, 9.72, 10.41, 11.27, 11.8, 12.28, 12.49, 11.8, 10.98, 9.47, 8.8, 9.19, 7.67, 5.64,
  4.25, 3.85, 3.82, 3.78, 3.83, 3.81, 3.77, 3.78, 3.9, 6.49, 8.0, 9.62, 10.67, 11.76, 12.32, 12.66,
  12.8, 12.06, 11.21, 9.56, 8.69, 9.0, 7.49, 5.43, 4.24, 3.84, 3.82, 3.77, 4.0, 4.08, 4.07, 4.07,
  4.1, 4.73, 7.46, 8.94, 10.1, 10.56, 10.97, 11.17, 11.34, 11.24, 10.65, 9.95, 9.56, 9.71, 9.21,
  7.64, 5.79, 4.29, 3.99, 3.96, 3.62, 3.49, 3.43, 3.43, 3.35, 3.29, 3.47, 4.07, 3.21, 3.02, 2.95,
  2.97, 3.02, 3.05, 3.01, 2.93, 2.85, 3.47, 4.23, 4.37, 4.53, 4.49, 3.82, 3.83, 3.72, 3.16, 3.15,
  3.16, 3.11, 3.15, 3.54, 3.94, 2.63, 2.97, 2.88, 2.76, 2.7, 2.69, 2.64, 2.64, 2.64, 3.31, 4.05,
  4.22, 4.3, 4.13, 3.38, 3.41, 3.43, 4.03, 4.09, 4.09, 4.08, 4.1, 4.19, 6.89, 8.55, 9.96, 10.48,
  10.96, 11.12, 11.31, 11.33, 10.72, 10.19, 9.71, 9.9, 9.69, 8.23, 6.24, 4.43, 4.06, 4.07, 4.04,
  4.12, 4.12, 4.09, 4.14, 4.2, 7.04, 8.71, 10.11, 10.64, 11.18, 11.47, 11.75, 11.74, 11.03, 10.35,
  9.79, 9.91, 9.69, 8.2, 6.23, 4.46, 4.06, 4.04, 4.01, 4.08, 4.08, 4.04, 4.06, 4.08, 6.89, 8.5,
  9.96, 10.49, 11.0, 11.26, 11.5, 11.48, 10.89, 10.35, 9.87, 9.91, 9.67, 8.17, 6.17, 4.43, 4.06,
  4.06, 3.99, 4.05, 4.04, 4.03, 4.08, 4.78, 7.3, 8.79, 10.08, 10.58, 11.0, 11.18, 11.34, 11.18,
  10.57, 9.95, 9.61, 9.77, 9.31, 7.71, 5.9, 4.33, 4.03, 4.03, 4.0, 4.08, 4.07, 4.07, 4.1, 4.73,
  7.46, 8.94, 10.1, 10.56, 10.97, 11.17, 11.34, 11.24, 10.65, 9.95, 9.56, 9.71, 9.21, 7.64, 5.79,
  4.29, 3.99, 3.96, 3.62, 3.49, 3.43, 3.35, 3.29, 3.47, 4.07, 3.21, 3.02, 2.95, 2.97, 3.02, 3.05,
  3.01, 2.93, 2.85, 3.47, 4.23, 4.37, 4.53, 4.49, 3.82, 3.83, 3.72, 3.16, 3.15, 3.16, 3.11, 3.15,
  3.54, 3.94, 2.63, 2.97, 2.88, 2.76, 2.7, 2.69, 2.64, 2.64, 2.64, 3.31, 4.05, 4.22, 4.3, 4.13,
  3.38, 3.41, 3.43, 4.03, 4.09, 4.09, 4.08, 4.1, 4.19, 6.89, 8.55, 9.96, 10.48, 10.96, 11.12, 11.31,
  11.33, 10.72, 10.19, 9.71, 9.9, 9.69, 8.23, 6.24, 4.43, 4.06, 4.07, 4.04, 4.12, 4.12, 4.09, 4.14,
  4.2, 7.04, 8.71, 10.11, 10.64, 11.18, 11.47, 11.75, 11.74, 11.03, 10.35, 9.79, 9.91, 9.69, 8.2,
  6.23, 4.46, 4.06, 4.04, 4.01, 4.08, 4.08, 4.04, 4.06, 4.08, 6.89, 8.5, 9.96, 10.49, 11.0, 11.26,
  11.5, 11.48, 10.89, 10.35, 9.87, 9.91, 9.67, 8.17, 6.17, 4.43, 4.06, 4.06, 3.99, 4.05, 4.04, 4.03,
  4.08, 4.78, 7.3, 8.79, 10.08, 10.58, 11.0, 11.18, 11.34, 11.18, 10.57, 9.95, 9.61, 9.77, 9.31,
  7.71, 5.9, 4.33, 4.03, 4.03, 4.0, 4.08, 4.07, 4.07, 4.1, 4.73, 7.46, 8.94, 10.1, 10.56, 10.97,
  11.17, 11.34, 11.24, 10.65, 9.95, 9.56, 9.71, 9.21, 7.64, 5.79, 4.29, 3.99, 3.96, 3.62, 3.49,
  3.43, 3.35, 3.29, 3.47, 4.07, 3.21, 3.02, 2.95, 2.97, 3.02, 3.05, 3.01, 2.93, 2.85, 3.47, 4.23,
  4.37, 4.53, 4.49, 3.82, 3.83, 3.72, 3.16, 3.15, 3.16, 3.11, 3.15, 3.54, 3.94, 2.63, 2.97, 2.88,
  2.76, 2.7, 2.69, 2.64, 2.64, 2.64, 3.31, 4.05, 4.22, 4.3, 4.13, 3.38, 3.41, 3.43, 4.03, 4.09,
  4.09, 4.08, 4.1, 4.19, 6.89, 8.55, 9.96, 10.48, 10.96, 11.12, 11.31, 11.33, 10.72, 10.19, 9.71,
  9.9, 9.69, 8.23, 6.24, 4.43, 4.06, 4.07, 4.04, 4.12, 4.12, 4.09, 4.14, 4.2, 7.04, 8.71, 10.11,
  10.64, 11.18, 11.47, 11.75, 11.74, 11.03, 10.35, 9.79, 9.91, 9.69, 8.2, 6.23, 4.46, 4.06, 4.04,
  4.01, 4.08, 4.08, 4.04, 4.06, 4.08, 6.89, 8.5, 9.96, 10.49, 11.0, 11.26, 11.5, 11.48, 10.89,
  10.35, 9.87, 9.91, 9.67, 8.17, 6.17, 4.43, 4.06, 4.06, 3.99, 4.05, 4.04, 4.03, 4.08, 4.78, 7.3,
  8.79, 10.08, 10.58, 11.0, 11.18, 11.34, 11.18, 10.57, 9.95, 9.61, 9.77, 9.31, 7.71, 5.9, 4.33,
  4.03, 4.03, 4.0, 4.08, 4.07, 4.07, 4.1, 4.73, 7.46, 8.94, 10.1, 10.56, 10.97, 11.17, 11.34, 11.24,
  10.65, 9.95, 9.56, 9.71, 9.21, 7.64, 5.79, 4.29, 3.99, 3.96, 3.62, 3.49, 3.43, 3.35, 3.29, 3.47,
  4.07, 3.21, 3.02, 2.95, 2.97, 3.02, 3.05, 3.01, 2.93, 2.85, 3.47, 4.23, 4.37, 4.53, 4.49, 3.82,
  3.83, 3.72, 3.16, 3.15, 3.16, 3.11, 3.15, 3.54, 3.94, 2.63, 2.97, 2.88, 2.76, 2.7, 2.69, 2.64,
  2.64, 2.64, 3.31, 4.05, 4.22, 4.3, 4.13, 3.38, 3.41, 3.43, 4.03, 4.09, 4.09, 4.08, 4.1, 4.19,
  6.89, 8.55, 9.96, 10.48, 10.96, 11.12, 11.31, 11.33, 10.72, 10.19, 9.71, 9.9, 9.69, 8.23, 6.24,
  4.43, 4.06, 4.07, 4.04, 4.12, 4.12, 4.09, 4.14, 4.2, 7.04, 8.71, 10.11, 10.64, 11.18, 11.47,
  11.75, 11.74, 11.03, 10.35, 9.79, 9.91, 9.69, 8.2, 6.23, 4.46, 4.06, 4.04, 4.01, 4.08, 4.08, 4.04,
  4.06, 4.08, 6.89, 8.5, 9.96, 10.49, 11.0, 11.26, 11.5, 11.48, 10.89, 10.35, 9.87, 9.91, 9.67,
  8.17, 6.17, 4.43, 4.06, 4.06, 3.99, 4.05, 4.04, 4.03, 4.08, 4.78, 7.3, 8.79, 10.08, 10.58, 11.0,
  11.18, 11.34, 11.18, 10.57, 9.95, 9.61, 9.77, 9.31, 7.71, 5.9, 4.33, 4.03, 4.03, 4.0, 4.08, 4.07,
  4.07, 4.1, 4.73, 7.46, 8.94, 10.1, 10.56, 10.97, 11.17, 11.34, 11.24, 10.65, 9.95, 9.56, 9.71,
  9.21, 7.64, 5.79, 4.29, 3.99, 3.96, 3.62, 3.49, 3.43, 3.35, 3.29, 3.47, 4.07, 3.21, 3.02, 2.95,
  2.97, 3.02, 3.05, 3.01, 2.93, 2.85, 3.47, 4.23, 4.37, 4.53, 4.49, 3.82, 3.83, 3.72, 3.6, 3.59,
  3.64, 3.64, 3.67, 3.73, 5.14, 3.18, 3.47, 3.37, 3.23, 3.16, 3.09, 3.01, 2.97, 2.99, 3.92, 4.4,
  4.54, 4.6, 4.67, 3.56, 3.64, 3.71, 4.35, 4.47, 4.5, 4.5, 4.52, 4.54, 7.64, 9.24, 10.5, 10.92,
  11.23, 11.29, 11.38, 11.34, 10.75, 10.39, 10.21, 10.3, 10.18, 8.74, 6.75, 4.68, 4.36, 4.42, 4.45,
  4.54, 4.57, 4.56, 4.61, 4.71, 7.73, 9.17, 10.54, 11.02, 11.41, 11.5, 11.6, 11.53, 11.01, 10.69,
  10.55, 10.54, 10.36, 8.94, 6.91, 4.77, 4.47, 4.62, 4.61, 4.68, 4.7, 4.7, 4.75, 4.87, 7.93, 9.46,
  10.79, 11.27, 11.64, 11.74, 11.82, 11.76, 11.24, 10.87, 10.72, 10.65, 10.46, 9.01, 6.93, 4.8,
  4.44, 4.51, 4.52, 4.64, 4.63, 4.61, 4.63, 4.72, 7.77, 9.36, 10.64, 11.13, 11.5, 11.57, 11.67,
  11.61, 11.12, 10.82, 10.7, 10.63, 10.44, 8.98, 6.92, 4.78, 4.45, 4.47, 4.46, 4.53, 4.64, 4.72,
  4.81, 4.95, 7.86, 9.31, 10.62, 11.12, 11.52, 11.59, 11.67, 11.57, 11.0, 10.58, 10.41, 10.38,
  10.21, 8.77, 6.73, 4.71, 4.43, 4.45, 4.09, 4.1, 4.07, 4.04, 4.03, 3.93, 5.16, 3.97, 3.57, 3.41,
  3.37, 3.38, 3.34, 3.31, 3.29, 3.34, 4.3, 4.74, 4.91, 5.03, 5.18, 4.12, 4.23, 4.19, 3.6, 3.59,
  3.64, 3.64, 3.67, 3.73, 5.14, 3.18, 3.47, 3.37, 3.23, 3.16, 3.09, 3.01, 2.97, 2.99, 3.92, 4.4,
  4.54, 4.6, 4.67, 3.56, 3.64, 3.71, 4.35, 4.47, 4.5, 4.5, 4.52, 4.54, 7.64, 9.24, 10.5, 10.92,
  11.23, 11.29, 11.38, 11.34, 10.75, 10.39, 10.21, 10.3, 10.18, 8.74, 6.75, 4.68, 4.36, 4.42, 4.45,
  4.54, 4.57, 4.56, 4.61, 4.71, 7.73, 9.17, 10.54, 11.02, 11.41, 11.5, 11.6, 11.53, 11.01, 10.69,
  10.55, 10.54, 10.36, 8.94, 6.91, 4.77, 4.47, 4.62, 4.61, 4.68, 4.7, 4.7, 4.75, 4.87, 7.93, 9.46,
  10.79, 11.27, 11.64, 11.74, 11.82, 11.76, 11.24, 10.87, 10.72, 10.65, 10.46, 9.01, 6.93, 4.8,
  4.44, 4.51, 4.52, 4.64, 4.63, 4.61, 4.63, 4.72, 7.77, 9.36, 10.64, 11.13, 11.5, 11.57, 11.67,
  11.61, 11.12, 10.82, 10.7, 10.63, 10.44, 8.98, 6.92, 4.78, 4.45, 4.47, 4.46, 4.53, 4.64, 4.72,
  4.81, 4.95, 7.86, 9.31, 10.62, 11.12, 11.52, 11.59, 11.67, 11.57, 11.0, 10.58, 10.41, 10.38,
  10.21, 8.77, 6.73, 4.71, 4.43, 4.45, 4.09, 4.1, 4.07, 4.04, 4.03, 3.93, 5.16, 3.97, 3.57, 3.41,
  3.37, 3.38, 3.34, 3.31, 3.29, 3.34, 4.3, 4.74, 4.91, 5.03, 5.18, 4.12, 4.23, 4.19, 3.6, 3.59,
  3.64, 3.64, 3.67, 3.73, 5.14, 3.18, 3.47, 3.37, 3.23, 3.16, 3.09, 3.01, 2.97, 2.99, 3.92, 4.4,
  4.54, 4.6, 4.67, 3.56, 3.64, 3.71, 4.35, 4.47, 4.5, 4.5, 4.52, 4.54, 7.64, 9.24, 10.5, 10.92,
  11.23, 11.29, 11.38, 11.34, 10.75, 10.39, 10.21, 10.3, 10.18, 8.74, 6.75, 4.68, 4.36, 4.42, 4.45,
  4.54, 4.57, 4.56, 4.61, 4.71, 7.73, 9.17, 10.54, 11.02, 11.41, 11.5, 11.6, 11.53, 11.01, 10.69,
  10.55, 10.54, 10.36, 8.94, 6.91, 4.77, 4.47, 4.62, 4.61, 4.68, 4.7, 4.7, 4.75, 4.87, 7.93, 9.46,
  10.79, 11.27, 11.64, 11.74, 11.82, 11.76, 11.24, 10.87, 10.72, 10.65, 10.46, 9.01, 6.93, 4.8,
  4.44, 4.51, 4.52, 4.64, 4.63, 4.61, 4.63, 4.72, 7.77, 9.36, 10.64, 11.13, 11.5, 11.57, 11.67,
  11.61, 11.12, 10.82, 10.7, 10.63, 10.44, 8.98, 6.92, 4.78, 4.45, 4.47, 4.46, 4.53, 4.64, 4.72,
  4.81, 4.95, 7.86, 9.31, 10.62, 11.12, 11.52, 11.59, 11.67, 11.57, 11.0, 10.58, 10.41, 10.38,
  10.21, 8.77, 6.73, 4.71, 4.43, 4.45, 4.09, 4.1, 4.07, 4.04, 4.03, 3.93, 5.16, 3.97, 3.57, 3.41,
  3.37, 3.38, 3.34, 3.31, 3.29, 3.34, 4.3, 4.74, 4.91, 5.03, 5.18, 4.12, 4.23, 4.19, 3.6, 3.59,
  3.64, 3.64, 3.67, 3.73, 5.14, 3.18, 3.47, 3.37, 3.23, 3.16, 3.09, 3.01, 2.97, 2.99, 3.92, 4.4,
  4.54, 4.6, 4.67, 3.56, 3.64, 3.71, 4.35, 4.47, 4.5, 4.5, 4.52, 4.54, 7.64, 9.24, 10.5, 10.92,
  11.23, 11.29, 11.38, 11.34, 10.75, 10.39, 10.21, 10.3, 10.18, 8.74, 6.75, 4.68, 4.36, 4.42, 4.45,
  4.54, 4.57, 4.56, 4.61, 4.71, 7.73, 9.17, 10.54, 11.02, 11.41, 11.5, 11.6, 11.53, 11.01, 10.69,
  10.55, 10.54, 10.36, 8.94, 6.91, 4.77, 4.47, 4.62, 4.61, 4.68, 4.7, 4.7, 4.75, 4.87, 7.93, 9.46,
  10.79, 11.27, 11.64, 11.74, 11.82, 11.76, 11.24, 10.87, 10.72, 10.65, 10.46, 9.01, 6.93, 4.8,
  4.44, 4.51, 4.52, 4.64, 4.63, 4.61, 4.63, 4.72, 7.77, 9.36, 10.64, 11.13, 11.5, 11.57, 11.67,
  11.61, 11.12, 10.82, 10.7, 10.63, 10.44, 8.98, 6.92, 4.78, 4.45, 4.47, 4.46, 4.53, 4.64, 4.72,
  4.81, 4.95, 7.86, 9.31, 10.62, 11.12, 11.52, 11.59, 11.67, 11.57, 11.0, 10.58, 10.41, 10.38,
  10.21, 8.77, 6.73, 4.71, 4.43, 4.45, 4.09, 4.1, 4.07, 4.04, 4.03, 3.93, 5.16, 3.97, 3.57, 3.41,
  3.37, 3.38, 3.34, 3.31, 3.29, 3.34, 4.3, 4.74, 4.91, 5.03, 5.18, 4.12, 4.23, 4.19, 3.6, 3.59,
  3.64, 3.64, 3.67, 3.73, 5.14, 3.18, 3.47, 3.37, 3.23, 3.16, 3.09, 3.01, 2.97, 2.99, 3.92, 4.4,
  4.54, 4.6, 4.67, 3.56, 3.64, 3.71, 4.35, 4.47, 4.5, 4.5, 4.52, 4.54, 7.64, 9.24, 10.5, 10.92,
  11.23, 11.29, 11.38, 11.34, 10.75, 10.39, 10.21, 10.3, 10.18, 8.74, 6.75, 4.68, 4.36, 4.42, 4.45,
  4.54, 4.57, 4.56, 4.61, 4.71, 7.73, 9.17, 10.54, 11.02, 11.41, 11.5, 11.6, 11.53, 11.01, 10.69,
  10.55, 10.54, 10.36, 8.94, 6.91, 4.77, 4.47, 4.62,
];

export default primarySchoolLoadProfile;
