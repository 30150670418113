import calcMilesPerYear from '@generic/functions/calcMilesPerYear';
import calcAnnualVehicleElectricityCosts from '@generic/functions/calcAnnualVehicleElectricityCosts';

const calcPerMileElectricityCosts = (inputs) => {
  const {vehicleSets} = inputs;

  const {ev: electricityCosts} = calcAnnualVehicleElectricityCosts(inputs);
  const totalMiles = vehicleSets.reduce((sum, set) => sum + calcMilesPerYear(set), 0);

  return electricityCosts / totalMiles;
};

export default calcPerMileElectricityCosts;
