import getDefaults from '@generic/functions/getDefaults';

export default function getConEdDefaults() {
  const customDefaults = {
    siteAdditionalQualifications: 'none',
    zipcode: '10001',
    includeSCCRebate: true,
  };

  return {
    ...getDefaults(),
    ...customDefaults,
  };
}
