import {makeStyles, Box, Typography, Tooltip} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import IconCircleQuestion from '@generic/assets/images/IconCircleQuestion';

const useStyles = makeStyles((theme) => ({
  input: {
    '-webkit-appearance': 'none',
    height: theme.slider.track.height,
    width: '100%',
    borderRadius: theme.slider.track.borderRadius,
    background: theme.slider.track.background,
    outline: 'none',

    '&::-webkit-slider-thumb': {
      background: theme.slider.thumb.background,
      borderRadius: theme.slider.thumb.borderRadius,
      cursor: 'pointer',
      height: theme.slider.thumb.height,
      width: theme.slider.thumb.width,
      appearance: 'none',
      '-webkit-appearance': 'none',
    },

    '&::-moz-range-thumb': {
      background: theme.slider.thumb.background,
      cursor: 'pointer',
      height: theme.slider.thumb.height,
      width: theme.slider.thumb.width,
    },
  },
  label: (props) => ({
    color: props.variant === 'gray' ? theme.palette.gray[600] : theme.slider.label.color,
    fontWeight: props.variant === 'gray' ? 600 : 'auto',
    display: 'flex',
    alignItems: 'center',
  }),
  valueBox: {
    minWidth: '40px',
    padding: theme.slider.value.padding,
    backgroundColor: theme.slider.value.background,
  },
  value: {
    fontWeight: theme.slider.value.fontWeight,
  },
  minMaxLabel: {
    fontStyle: 'italic',
  },
  tooltipTrigger: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function SliderInput({
  value,
  onChange,
  label,
  ariaLabel,
  tooltip,
  min,
  max,
  step,
  variant = '',
  formatFn = (v) => v,
}) {
  const classes = useStyles({variant});

  const [workingValue, setWorkingValue] = useState(value);

  useEffect(() => {
    setWorkingValue(value);
  }, [value]);

  const handleBlur = (e) => {
    setWorkingValue(parseFloat(e.currentTarget.value));
    onChange(workingValue);
  };

  return (
    <Box mb={5}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography className={classes.label} variant="body2">
          {label}
          {tooltip && (
            <Tooltip title={tooltip} arrow placement="right">
              <span className={classes.tooltipTrigger}>
                <IconCircleQuestion />
              </span>
            </Tooltip>
          )}
        </Typography>
        <Box className={classes.valueBox}>
          <Typography align="center" className={classes.value} variant="body2">
            {formatFn(workingValue)}
          </Typography>
        </Box>
      </Box>

      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={workingValue}
        onMouseUp={handleBlur}
        onChange={(e) => setWorkingValue(parseFloat(e.currentTarget.value))}
        className={classes.input}
        aria-label={ariaLabel || label}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" className={classes.minMaxLabel}>
          {formatFn(min)}
        </Typography>
        <Typography variant="body2" className={classes.minMaxLabel}>
          {formatFn(max)}
        </Typography>
      </Box>
    </Box>
  );
}
