import {Box, makeStyles, Typography} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  info: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      width: 'auto',
    },
  },
  icon: {
    marginRight: 16,
  },
  value: {
    fontFamily: theme.typography.primary.fontFamily,
  },
}));

export default function FuelInsight({icon, label, value}) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" mx={{md: 4}} className={classes.root}>
      {icon && <img src={icon} alt="" className={classes.icon} />}
      <Box display="flex" className={classes.info}>
        <Typography variant="body2">{label}</Typography>
        <Typography variant="h5" className={classes.value}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
