import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import without from 'lodash/without';
import CheckboxButtonInput from '@generic/components/inputs/CheckboxButtonInput';
import {MONTHS_ABBREVIATED} from '@generic/data/timePeriods';

const useStyles = makeStyles((theme) => ({
  toggleLabel: {
    color: theme.toggleButtonGroup.toggleLabel.color,
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  text: {
    marginBottom: theme.spacing(1),
  },
}));

export default function MonthsInOperation({onChange, workmonths = []}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const toggleButton = (monthIdx) => {
    if (workmonths.includes(monthIdx)) {
      onChange(without(workmonths, monthIdx));
    } else {
      onChange([...workmonths, monthIdx]);
    }
  };

  return (
    <Box>
      <Box className={classes.text}>
        <Typography className={classes.toggleLabel}>
          {formatMessage({id: 'monthsInOperation.label'})}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {MONTHS_ABBREVIATED.map(({monthIdx, translationKey}) => (
          <Grid item xs={6} md key={monthIdx}>
            <CheckboxButtonInput
              checked={workmonths.includes(monthIdx)}
              onClick={() => toggleButton(monthIdx)}
              variant="compact"
            >
              {formatMessage({id: translationKey})}
            </CheckboxButtonInput>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
