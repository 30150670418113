const hourlyMarketPricesZoneH = [
  0.02263, 0.02007, 0.01848, 0.01831, 0.01854, 0.01869, 0.02113, 0.0198, 0.02106, 0.023, 0.02428,
  0.0239, 0.02321, 0.02289, 0.02224, 0.02457, 0.02849, 0.03625, 0.03377, 0.03068, 0.02853, 0.02637,
  0.02454, 0.02252, 0.02507, 0.02226, 0.02146, 0.02106, 0.02144, 0.02591, 0.03207, 0.03489, 0.03541,
  0.03462, 0.03347, 0.03251, 0.03112, 0.03039, 0.03063, 0.03194, 0.03975, 0.05113, 0.04702, 0.0422,
  0.0365, 0.03335, 0.0303, 0.02775, 0.02638, 0.02406, 0.02277, 0.02211, 0.0231, 0.02526, 0.03087,
  0.03406, 0.03511, 0.03423, 0.03359, 0.03238, 0.03038, 0.02963, 0.02924, 0.02992, 0.03749, 0.04826,
  0.04562, 0.04169, 0.03673, 0.03323, 0.02946, 0.02652, 0.02375, 0.02232, 0.02131, 0.02126, 0.02151,
  0.02394, 0.02759, 0.02911, 0.03063, 0.02938, 0.02816, 0.02713, 0.02513, 0.02429, 0.02422, 0.024,
  0.03057, 0.03898, 0.03655, 0.03287, 0.02914, 0.02766, 0.0246, 0.02323, 0.02327, 0.01958, 0.01819,
  0.01709, 0.01755, 0.01879, 0.0197, 0.02094, 0.02256, 0.02508, 0.02558, 0.02545, 0.02523, 0.02404,
  0.0239, 0.02327, 0.02583, 0.03017, 0.03023, 0.02858, 0.0243, 0.02532, 0.02294, 0.02167, 0.02344,
  0.02093, 0.01962, 0.01941, 0.01869, 0.01774, 0.01873, 0.02081, 0.02266, 0.02516, 0.02429, 0.02422,
  0.0226, 0.02183, 0.02148, 0.02159, 0.0283, 0.03328, 0.03516, 0.0338, 0.03013, 0.02916, 0.02574,
  0.02355, 0.026, 0.02417, 0.02343, 0.02307, 0.02326, 0.02687, 0.03173, 0.03508, 0.03675, 0.03592,
  0.03438, 0.03215, 0.03026, 0.03069, 0.02993, 0.03025, 0.03932, 0.04655, 0.04153, 0.04024, 0.03572,
  0.03051, 0.02846, 0.02547, 0.02611, 0.02378, 0.02312, 0.0229, 0.02347, 0.02521, 0.03047, 0.0313,
  0.02962, 0.02928, 0.02965, 0.02897, 0.02846, 0.02824, 0.02794, 0.02784, 0.03251, 0.03876, 0.03425,
  0.03129, 0.02837, 0.02755, 0.02468, 0.02267, 0.0209, 0.01971, 0.01851, 0.01847, 0.0187, 0.02078,
  0.02598, 0.02838, 0.0278, 0.02708, 0.02714, 0.02773, 0.02655, 0.02672, 0.02662, 0.02597, 0.03198,
  0.03712, 0.03377, 0.03166, 0.02771, 0.02633, 0.02402, 0.02215, 0.02722, 0.02388, 0.02197, 0.02148,
  0.02267, 0.0272, 0.03126, 0.03611, 0.03666, 0.0346, 0.03629, 0.03436, 0.03326, 0.03375, 0.03336,
  0.03364, 0.04219, 0.05008, 0.04772, 0.04464, 0.03973, 0.03653, 0.0324, 0.03118, 0.04012, 0.03677,
  0.03198, 0.03197, 0.03365, 0.04028, 0.05318, 0.05797, 0.05704, 0.05472, 0.05405, 0.05112, 0.04805,
  0.04907, 0.04954, 0.05125, 0.05986, 0.07533, 0.07214, 0.06514, 0.06101, 0.05619, 0.05447, 0.04721,
  0.04386, 0.0387, 0.03777, 0.03629, 0.03661, 0.04033, 0.03903, 0.04056, 0.04321, 0.04364, 0.04364,
  0.04178, 0.03989, 0.04045, 0.03928, 0.04012, 0.04253, 0.04913, 0.04694, 0.0455, 0.04336, 0.04151,
  0.04008, 0.0386, 0.03615, 0.03409, 0.03142, 0.0313, 0.03137, 0.02985, 0.03011, 0.03201, 0.03507,
  0.03689, 0.03527, 0.03494, 0.03444, 0.03374, 0.03211, 0.03306, 0.0366, 0.04638, 0.04404, 0.04081,
  0.03815, 0.03724, 0.03608, 0.03478, 0.03327, 0.03105, 0.02978, 0.0264, 0.03208, 0.03501, 0.0436,
  0.05186, 0.05016, 0.04435, 0.04068, 0.03793, 0.03611, 0.03606, 0.03562, 0.03616, 0.04276, 0.05575,
  0.0524, 0.04755, 0.04074, 0.03913, 0.03623, 0.03458, 0.03487, 0.03304, 0.0315, 0.03158, 0.03309,
  0.03584, 0.04422, 0.05002, 0.04589, 0.04246, 0.03829, 0.03638, 0.03486, 0.03486, 0.0346, 0.03484,
  0.04036, 0.05295, 0.04779, 0.04324, 0.04016, 0.03756, 0.03425, 0.03175, 0.0385, 0.03819, 0.03552,
  0.03719, 0.03777, 0.03838, 0.05243, 0.05641, 0.0538, 0.04995, 0.04221, 0.04116, 0.03915, 0.03863,
  0.03874, 0.03806, 0.04602, 0.05144, 0.05162, 0.04874, 0.04343, 0.04197, 0.03702, 0.03682, 0.03591,
  0.03564, 0.03322, 0.03322, 0.03389, 0.03932, 0.05259, 0.05782, 0.05469, 0.05082, 0.04551, 0.04271,
  0.04128, 0.04057, 0.0404, 0.04038, 0.04737, 0.05615, 0.05422, 0.05086, 0.04625, 0.04245, 0.0395,
  0.03836, 0.04416, 0.0427, 0.04063, 0.03925, 0.04038, 0.03745, 0.05066, 0.05708, 0.05988, 0.06187,
  0.05924, 0.05428, 0.0511, 0.05086, 0.05052, 0.04916, 0.04897, 0.0567, 0.05311, 0.05082, 0.04764,
  0.04359, 0.04468, 0.04264, 0.04808, 0.0499, 0.04654, 0.0463, 0.04556, 0.04729, 0.04132, 0.04077,
  0.05525, 0.0617, 0.07253, 0.06661, 0.06505, 0.06357, 0.06336, 0.0633, 0.07383, 0.0806, 0.07411,
  0.07736, 0.06893, 0.06559, 0.0617, 0.05747, 0.07138, 0.04916, 0.04892, 0.06925, 0.05889, 0.04503,
  0.04935, 0.07252, 0.06536, 0.08328, 0.09274, 0.092, 0.08855, 0.08842, 0.08915, 0.09119, 0.1127,
  0.13598, 0.13183, 0.12129, 0.11202, 0.10739, 0.10695, 0.0996, 0.10298, 0.09548, 0.08973, 0.09121,
  0.09559, 0.10346, 0.11299, 0.11611, 0.13946, 0.12933, 0.12121, 0.11916, 0.11641, 0.1166, 0.11218,
  0.11783, 0.12665, 0.15173, 0.14809, 0.14134, 0.12501, 0.11719, 0.1134, 0.10725, 0.10099, 0.0955,
  0.08885, 0.09215, 0.09685, 0.10528, 0.12844, 0.14423, 0.13126, 0.11327, 0.10876, 0.0931, 0.09197,
  0.08672, 0.08279, 0.09181, 0.10466, 0.12733, 0.12665, 0.11372, 0.10605, 0.09436, 0.09118, 0.07499,
  0.07054, 0.06778, 0.06511, 0.06526, 0.06621, 0.06632, 0.07785, 0.08825, 0.08622, 0.08332, 0.06761,
  0.06378, 0.05918, 0.05291, 0.05108, 0.05223, 0.06041, 0.06785, 0.06467, 0.06067, 0.05348, 0.0496,
  0.04402, 0.04177, 0.03212, 0.03051, 0.02858, 0.02829, 0.02875, 0.02797, 0.03035, 0.03456, 0.0357,
  0.03471, 0.03471, 0.0363, 0.0348, 0.03394, 0.03245, 0.03394, 0.03697, 0.04312, 0.04295, 0.04093,
  0.03758, 0.03394, 0.03082, 0.02934, 0.03056, 0.02926, 0.02889, 0.02888, 0.02903, 0.03026, 0.03422,
  0.03841, 0.03568, 0.03479, 0.04208, 0.04041, 0.03847, 0.03911, 0.03867, 0.03862, 0.04323, 0.05007,
  0.04999, 0.05583, 0.04726, 0.04666, 0.0413, 0.03893, 0.04269, 0.03979, 0.03787, 0.03802, 0.03865,
  0.03837, 0.04157, 0.04015, 0.04783, 0.04659, 0.04667, 0.046, 0.04365, 0.04297, 0.04262, 0.04238,
  0.04479, 0.05306, 0.0506, 0.04971, 0.04603, 0.04642, 0.04675, 0.04257, 0.03352, 0.03334, 0.03244,
  0.03051, 0.03091, 0.03094, 0.03404, 0.03321, 0.0339, 0.03559, 0.03408, 0.03265, 0.0349, 0.03273,
  0.03196, 0.02858, 0.03318, 0.04737, 0.04636, 0.04389, 0.03565, 0.03496, 0.03433, 0.03057, 0.03194,
  0.03067, 0.02997, 0.02994, 0.03056, 0.03315, 0.049, 0.06332, 0.05424, 0.05156, 0.04798, 0.04215,
  0.0406, 0.0392, 0.03647, 0.03793, 0.04543, 0.06689, 0.05991, 0.05371, 0.04555, 0.03786, 0.03573,
  0.03403, 0.03996, 0.03645, 0.03705, 0.03551, 0.03598, 0.03625, 0.04408, 0.05203, 0.04925, 0.0472,
  0.05426, 0.04653, 0.04494, 0.04451, 0.04419, 0.04436, 0.04399, 0.05202, 0.05241, 0.04859, 0.04557,
  0.04139, 0.04243, 0.0368, 0.04514, 0.04378, 0.04274, 0.04433, 0.04569, 0.05062, 0.06896, 0.07048,
  0.07202, 0.06902, 0.08162, 0.07281, 0.06951, 0.07815, 0.07806, 0.0791, 0.08827, 0.13639, 0.13562,
  0.12303, 0.11502, 0.09959, 0.09395, 0.08243, 0.09273, 0.09057, 0.08752, 0.08652, 0.08771, 0.09994,
  0.12675, 0.13278, 0.12766, 0.11899, 0.11988, 0.1108, 0.10479, 0.10094, 0.0992, 0.09996, 0.10394,
  0.13724, 0.13722, 0.13305, 0.11582, 0.10257, 0.10993, 0.10367, 0.0751, 0.07053, 0.06875, 0.06905,
  0.07108, 0.07526, 0.10271, 0.09697, 0.08838, 0.0817, 0.07518, 0.06778, 0.06087, 0.05915, 0.05761,
  0.05722, 0.06784, 0.09065, 0.09355, 0.08006, 0.07097, 0.0659, 0.06202, 0.06203, 0.04568, 0.04283,
  0.04227, 0.04131, 0.04003, 0.04331, 0.04748, 0.04765, 0.04955, 0.0491, 0.0428, 0.03809, 0.03641,
  0.0349, 0.03389, 0.03402, 0.03685, 0.04676, 0.04346, 0.0397, 0.03738, 0.03462, 0.03364, 0.03126,
  0.03047, 0.02874, 0.02791, 0.02668, 0.02707, 0.02757, 0.02872, 0.02956, 0.02938, 0.03109, 0.03033,
  0.0294, 0.0277, 0.02716, 0.02523, 0.02624, 0.02759, 0.03513, 0.03404, 0.03098, 0.02831, 0.02694,
  0.02824, 0.02678, 0.02748, 0.02574, 0.02513, 0.02527, 0.02595, 0.02726, 0.03038, 0.03248, 0.033,
  0.03218, 0.03144, 0.02996, 0.02956, 0.02822, 0.02592, 0.02608, 0.02811, 0.03653, 0.0366, 0.03418,
  0.02929, 0.02527, 0.02603, 0.02501, 0.02447, 0.02347, 0.02148, 0.02112, 0.02175, 0.02249, 0.02761,
  0.03016, 0.03254, 0.03016, 0.03012, 0.02896, 0.02793, 0.02688, 0.02645, 0.0264, 0.02961, 0.0373,
  0.03961, 0.03579, 0.03262, 0.03009, 0.02741, 0.0239, 0.02281, 0.02029, 0.01909, 0.0188, 0.02084,
  0.02332, 0.02674, 0.03051, 0.0291, 0.02882, 0.02817, 0.02663, 0.0261, 0.02563, 0.02481, 0.02545,
  0.0285, 0.03803, 0.03798, 0.03224, 0.02899, 0.02542, 0.02457, 0.02371, 0.01916, 0.01739, 0.01641,
  0.01819, 0.01867, 0.01974, 0.02572, 0.02722, 0.02777, 0.02811, 0.02625, 0.02556, 0.02525, 0.02515,
  0.0243, 0.02405, 0.0245, 0.03305, 0.03266, 0.02814, 0.02564, 0.02434, 0.02307, 0.02278, 0.01815,
  0.01681, 0.01586, 0.01526, 0.01634, 0.01844, 0.02183, 0.02368, 0.02228, 0.02098, 0.02234, 0.02209,
  0.0211, 0.02148, 0.02026, 0.02093, 0.02315, 0.03208, 0.0299, 0.02756, 0.02577, 0.02292, 0.02286,
  0.02209, 0.0257, 0.0251, 0.02194, 0.02194, 0.02251, 0.02189, 0.02598, 0.02851, 0.02857, 0.02792,
  0.02819, 0.02615, 0.02615, 0.0253, 0.02356, 0.02435, 0.02643, 0.03409, 0.03463, 0.03363, 0.03126,
  0.03034, 0.03, 0.0273, 0.02866, 0.02796, 0.02639, 0.0267, 0.02707, 0.0267, 0.02968, 0.02974,
  0.0299, 0.02933, 0.02987, 0.02692, 0.02751, 0.02655, 0.02626, 0.02572, 0.02823, 0.03632, 0.03861,
  0.0374, 0.03369, 0.03129, 0.02964, 0.02808, 0.02809, 0.0268, 0.02569, 0.02556, 0.02673, 0.02927,
  0.03553, 0.03887, 0.04002, 0.03721, 0.03732, 0.03408, 0.03304, 0.03205, 0.0314, 0.03208, 0.0333,
  0.04226, 0.0406, 0.03965, 0.03544, 0.03214, 0.03022, 0.02848, 0.02814, 0.02642, 0.02572, 0.02558,
  0.02625, 0.02873, 0.03471, 0.04029, 0.04026, 0.04076, 0.04128, 0.03877, 0.03836, 0.03667, 0.0357,
  0.03574, 0.03842, 0.04674, 0.04282, 0.04021, 0.03623, 0.03217, 0.02991, 0.02759, 0.02487, 0.02317,
  0.02186, 0.0218, 0.02234, 0.02425, 0.03105, 0.03046, 0.0336, 0.03206, 0.02845, 0.0284, 0.0284,
  0.02812, 0.02743, 0.02782, 0.02843, 0.03788, 0.03881, 0.03553, 0.03142, 0.02869, 0.02781, 0.02617,
  0.02411, 0.02212, 0.02087, 0.02085, 0.02188, 0.02612, 0.02843, 0.03131, 0.0304, 0.02869, 0.02726,
  0.02611, 0.02501, 0.02464, 0.02386, 0.02476, 0.02705, 0.03189, 0.03565, 0.0308, 0.02837, 0.02528,
  0.02458, 0.02336, 0.02469, 0.02437, 0.02202, 0.02216, 0.02265, 0.02387, 0.02859, 0.02943, 0.02788,
  0.02686, 0.02611, 0.02542, 0.02489, 0.02432, 0.02323, 0.02307, 0.02488, 0.03049, 0.03093, 0.02875,
  0.02546, 0.02411, 0.02346, 0.02155, 0.0248, 0.02336, 0.02202, 0.02217, 0.02227, 0.02316, 0.02474,
  0.02451, 0.02464, 0.02653, 0.02717, 0.02712, 0.02627, 0.02579, 0.02509, 0.02463, 0.02678, 0.03147,
  0.03634, 0.03363, 0.03116, 0.02945, 0.02894, 0.0262, 0.02541, 0.02448, 0.02276, 0.0234, 0.02385,
  0.02454, 0.02628, 0.02731, 0.02684, 0.02788, 0.02762, 0.02759, 0.02695, 0.02624, 0.02559, 0.02566,
  0.02813, 0.03274, 0.03665, 0.03324, 0.03086, 0.02908, 0.0281, 0.02469, 0.02546, 0.02371, 0.02241,
  0.0229, 0.02392, 0.02595, 0.02839, 0.02934, 0.03016, 0.03102, 0.03196, 0.03301, 0.03209, 0.03143,
  0.03041, 0.03084, 0.03141, 0.03591, 0.03881, 0.03777, 0.03464, 0.03133, 0.02894, 0.02612, 0.02653,
  0.02488, 0.02438, 0.025, 0.02583, 0.02825, 0.03662, 0.04199, 0.03781, 0.0334, 0.03224, 0.0307,
  0.02967, 0.02884, 0.02777, 0.02752, 0.02983, 0.04129, 0.04508, 0.04059, 0.03609, 0.03091, 0.02869,
  0.02602, 0.02872, 0.02712, 0.0264, 0.02672, 0.02796, 0.03138, 0.03974, 0.04218, 0.03793, 0.03604,
  0.03537, 0.0345, 0.03393, 0.03345, 0.03177, 0.03202, 0.03417, 0.04048, 0.04517, 0.04078, 0.03474,
  0.0294, 0.02943, 0.02689, 0.02523, 0.02386, 0.0219, 0.02133, 0.02223, 0.02464, 0.02947, 0.03007,
  0.02925, 0.02746, 0.02724, 0.02563, 0.024, 0.02394, 0.02316, 0.02302, 0.02413, 0.03091, 0.03362,
  0.02918, 0.02649, 0.02459, 0.02393, 0.02206, 0.0254, 0.02501, 0.02279, 0.02234, 0.02325, 0.02356,
  0.03011, 0.03027, 0.03055, 0.02987, 0.02905, 0.02736, 0.02537, 0.02502, 0.02403, 0.02401, 0.02565,
  0.03117, 0.03393, 0.03115, 0.02842, 0.02713, 0.02515, 0.02423, 0.02704, 0.0254, 0.02441, 0.02432,
  0.02462, 0.02496, 0.02599, 0.02517, 0.02797, 0.02837, 0.02922, 0.02771, 0.02584, 0.02596, 0.02484,
  0.025, 0.02546, 0.02986, 0.03343, 0.03058, 0.02955, 0.02729, 0.02792, 0.02659, 0.02687, 0.02397,
  0.02291, 0.02247, 0.0223, 0.02254, 0.02305, 0.02106, 0.0235, 0.02616, 0.02886, 0.02766, 0.02528,
  0.02498, 0.02488, 0.02478, 0.02571, 0.03238, 0.03412, 0.03077, 0.02921, 0.02623, 0.02442, 0.0232,
  0.02435, 0.02269, 0.0218, 0.02106, 0.02355, 0.02497, 0.03128, 0.0316, 0.03435, 0.03139, 0.03124,
  0.0302, 0.02875, 0.02842, 0.02754, 0.02954, 0.03067, 0.03764, 0.04503, 0.0413, 0.03543, 0.03145,
  0.03086, 0.02834, 0.03265, 0.03129, 0.03066, 0.03055, 0.03086, 0.0328, 0.04256, 0.04266, 0.04219,
  0.03729, 0.03733, 0.03507, 0.03372, 0.03421, 0.0338, 0.03483, 0.03609, 0.04155, 0.04945, 0.04381,
  0.03979, 0.03668, 0.03679, 0.03326, 0.03941, 0.03335, 0.03267, 0.03266, 0.03589, 0.03948, 0.05536,
  0.05468, 0.04938, 0.04844, 0.04809, 0.04681, 0.04762, 0.04663, 0.04118, 0.04231, 0.04802, 0.05289,
  0.0592, 0.05059, 0.04822, 0.04404, 0.04419, 0.04126, 0.03566, 0.03375, 0.03479, 0.03438, 0.03438,
  0.03459, 0.05136, 0.04632, 0.04983, 0.04511, 0.04596, 0.04238, 0.03612, 0.03692, 0.03476, 0.03567,
  0.03917, 0.04903, 0.05563, 0.05056, 0.04487, 0.03668, 0.03556, 0.03208, 0.02809, 0.02732, 0.02564,
  0.02513, 0.0251, 0.02698, 0.03259, 0.03461, 0.03666, 0.0354, 0.03274, 0.02978, 0.02644, 0.0266,
  0.02594, 0.02577, 0.02636, 0.02919, 0.03205, 0.03172, 0.0287, 0.02737, 0.02758, 0.02614, 0.02857,
  0.02797, 0.02683, 0.02635, 0.02632, 0.02714, 0.02989, 0.02992, 0.03106, 0.03187, 0.03138, 0.0308,
  0.03003, 0.02985, 0.02864, 0.02873, 0.02905, 0.03092, 0.03435, 0.03221, 0.03093, 0.02983, 0.02852,
  0.02553, 0.02894, 0.03062, 0.02691, 0.0278, 0.02715, 0.02766, 0.02828, 0.02941, 0.03152, 0.03374,
  0.03408, 0.03319, 0.03372, 0.03322, 0.033, 0.0332, 0.03426, 0.03843, 0.04252, 0.03886, 0.03651,
  0.03307, 0.02984, 0.02804, 0.03056, 0.02788, 0.02795, 0.02744, 0.02859, 0.03078, 0.03766, 0.04323,
  0.042, 0.04205, 0.04309, 0.03961, 0.03959, 0.03974, 0.03943, 0.03944, 0.03976, 0.05062, 0.05854,
  0.05768, 0.05745, 0.04986, 0.0397, 0.03607, 0.03618, 0.03619, 0.03623, 0.03626, 0.03598, 0.03832,
  0.0553, 0.05904, 0.05813, 0.05373, 0.0621, 0.0594, 0.05072, 0.05471, 0.05211, 0.05278, 0.04758,
  0.06211, 0.07296, 0.06393, 0.06068, 0.05482, 0.05024, 0.04439, 0.04713, 0.04466, 0.04185, 0.04234,
  0.04088, 0.04978, 0.06531, 0.07273, 0.07088, 0.0646, 0.06304, 0.05478, 0.05222, 0.05438, 0.05076,
  0.0529, 0.04775, 0.06011, 0.07711, 0.07035, 0.06266, 0.05403, 0.05434, 0.04914, 0.04068, 0.03809,
  0.03619, 0.03531, 0.0382, 0.0444, 0.04859, 0.05528, 0.05535, 0.05026, 0.0495, 0.04387, 0.04233,
  0.04303, 0.04251, 0.04178, 0.04341, 0.04566, 0.05478, 0.05056, 0.04796, 0.0469, 0.04483, 0.04117,
  0.03277, 0.03074, 0.03072, 0.03078, 0.03074, 0.03154, 0.04124, 0.04406, 0.04034, 0.03645, 0.03555,
  0.03257, 0.03196, 0.03215, 0.03021, 0.03015, 0.03108, 0.03813, 0.04152, 0.03748, 0.03878, 0.03249,
  0.03148, 0.02999, 0.02948, 0.02802, 0.02661, 0.02645, 0.02645, 0.02588, 0.02878, 0.02964, 0.0322,
  0.03197, 0.0309, 0.02878, 0.02693, 0.02628, 0.02569, 0.02583, 0.02702, 0.03026, 0.03425, 0.03326,
  0.03138, 0.02933, 0.02722, 0.0266, 0.02826, 0.02535, 0.02389, 0.02298, 0.02124, 0.02167, 0.02193,
  0.02411, 0.02716, 0.02941, 0.02945, 0.02842, 0.02767, 0.02685, 0.02679, 0.02703, 0.02908, 0.03171,
  0.03612, 0.03175, 0.0299, 0.02752, 0.02587, 0.0263, 0.02413, 0.02277, 0.02269, 0.02333, 0.02576,
  0.03459, 0.03734, 0.03883, 0.03604, 0.03555, 0.03228, 0.03072, 0.02951, 0.02881, 0.02894, 0.03087,
  0.03493, 0.03945, 0.04135, 0.03962, 0.034, 0.02965, 0.02717, 0.02761, 0.02614, 0.02516, 0.02499,
  0.02583, 0.0273, 0.03602, 0.04055, 0.04002, 0.03633, 0.03343, 0.03141, 0.02946, 0.02897, 0.0286,
  0.02841, 0.02936, 0.03145, 0.03584, 0.042, 0.03919, 0.03307, 0.02927, 0.02752, 0.02653, 0.02494,
  0.02439, 0.02495, 0.02516, 0.02723, 0.03534, 0.04065, 0.03761, 0.03392, 0.03076, 0.0291, 0.02785,
  0.02737, 0.02624, 0.02622, 0.02694, 0.02885, 0.0312, 0.03887, 0.03458, 0.03061, 0.02717, 0.02571,
  0.02447, 0.02346, 0.0229, 0.02313, 0.02317, 0.02453, 0.02981, 0.03378, 0.0326, 0.03081, 0.02964,
  0.0278, 0.02669, 0.02606, 0.02542, 0.0252, 0.02569, 0.02782, 0.02952, 0.03341, 0.03066, 0.02718,
  0.02517, 0.02424, 0.0238, 0.02343, 0.02176, 0.02188, 0.02217, 0.02355, 0.02804, 0.03306, 0.03262,
  0.03131, 0.02998, 0.02945, 0.02697, 0.02622, 0.02538, 0.02585, 0.02638, 0.02783, 0.02854, 0.03203,
  0.02872, 0.02702, 0.02381, 0.02345, 0.02474, 0.02247, 0.02119, 0.02078, 0.02061, 0.02054, 0.02148,
  0.02265, 0.02636, 0.02767, 0.02746, 0.02572, 0.02483, 0.02418, 0.02314, 0.02337, 0.02524, 0.02785,
  0.02927, 0.03548, 0.03186, 0.02931, 0.02727, 0.02606, 0.02804, 0.02718, 0.02564, 0.02637, 0.02656,
  0.0264, 0.02738, 0.02792, 0.02926, 0.03065, 0.02913, 0.02885, 0.02805, 0.02733, 0.02659, 0.02687,
  0.02821, 0.03139, 0.03759, 0.04433, 0.0404, 0.0372, 0.03159, 0.02982, 0.03088, 0.02928, 0.02874,
  0.02877, 0.02907, 0.03181, 0.04228, 0.04545, 0.04337, 0.04011, 0.03872, 0.03524, 0.03364, 0.03218,
  0.03065, 0.03096, 0.03172, 0.03696, 0.03946, 0.04624, 0.04223, 0.03813, 0.0332, 0.03182, 0.03159,
  0.03053, 0.03002, 0.03009, 0.03064, 0.03493, 0.04506, 0.04849, 0.04499, 0.04013, 0.03808, 0.03533,
  0.03442, 0.03419, 0.03172, 0.03116, 0.03307, 0.03597, 0.04142, 0.04979, 0.04508, 0.04118, 0.03442,
  0.03173, 0.02954, 0.02825, 0.02785, 0.02782, 0.02878, 0.03184, 0.04234, 0.04646, 0.04118, 0.0383,
  0.03563, 0.03187, 0.03033, 0.02964, 0.02888, 0.02888, 0.0298, 0.0322, 0.0377, 0.04399, 0.04076,
  0.0367, 0.03063, 0.02772, 0.0281, 0.02774, 0.02732, 0.02719, 0.02753, 0.03015, 0.03975, 0.04393,
  0.04012, 0.03906, 0.03779, 0.03514, 0.03443, 0.03637, 0.03319, 0.03359, 0.0369, 0.03811, 0.03881,
  0.04199, 0.03907, 0.03411, 0.03041, 0.02819, 0.02763, 0.02704, 0.02629, 0.02624, 0.02636, 0.02849,
  0.03816, 0.04174, 0.04075, 0.04033, 0.03994, 0.03784, 0.03725, 0.03874, 0.03506, 0.03446, 0.03431,
  0.03844, 0.03909, 0.04233, 0.03993, 0.03757, 0.03259, 0.02966, 0.02966, 0.02773, 0.0263, 0.02634,
  0.02625, 0.02654, 0.03051, 0.03183, 0.03986, 0.04205, 0.04015, 0.03946, 0.0375, 0.0346, 0.03256,
  0.0324, 0.03011, 0.03184, 0.03465, 0.03997, 0.03752, 0.03804, 0.0316, 0.02946, 0.02864, 0.02713,
  0.02661, 0.02631, 0.02617, 0.02629, 0.02815, 0.03036, 0.03323, 0.03335, 0.03018, 0.03, 0.02869,
  0.02619, 0.02496, 0.02502, 0.02826, 0.03127, 0.03311, 0.03962, 0.03845, 0.03494, 0.02951, 0.02727,
  0.0278, 0.02609, 0.02572, 0.02577, 0.02643, 0.02893, 0.0402, 0.04366, 0.0426, 0.04117, 0.03994,
  0.03887, 0.03663, 0.03541, 0.03352, 0.03347, 0.03482, 0.03829, 0.03982, 0.04624, 0.04325, 0.03895,
  0.03293, 0.0304, 0.02848, 0.02736, 0.02696, 0.02723, 0.02822, 0.03153, 0.04557, 0.04996, 0.04479,
  0.04135, 0.03875, 0.03596, 0.03293, 0.03246, 0.03029, 0.02988, 0.03091, 0.03466, 0.04015, 0.04671,
  0.04529, 0.03946, 0.0329, 0.03035, 0.02835, 0.02808, 0.02765, 0.02802, 0.02873, 0.03279, 0.04382,
  0.05077, 0.04286, 0.03979, 0.03851, 0.03461, 0.03312, 0.03274, 0.03207, 0.03208, 0.03415, 0.03587,
  0.0389, 0.04337, 0.04049, 0.039, 0.03129, 0.02848, 0.02764, 0.02612, 0.02556, 0.02583, 0.02631,
  0.03032, 0.03843, 0.04369, 0.03933, 0.03816, 0.03416, 0.03088, 0.02924, 0.02968, 0.02916, 0.02946,
  0.03027, 0.03256, 0.03658, 0.03931, 0.03971, 0.03622, 0.02853, 0.02627, 0.02424, 0.02346, 0.02243,
  0.02276, 0.02308, 0.02461, 0.02956, 0.0364, 0.0364, 0.03584, 0.03729, 0.03187, 0.03127, 0.03075,
  0.02972, 0.02975, 0.02985, 0.03124, 0.03207, 0.03502, 0.03284, 0.02921, 0.02607, 0.02512, 0.02494,
  0.02332, 0.02283, 0.02276, 0.02248, 0.0224, 0.02394, 0.02568, 0.02781, 0.02882, 0.02891, 0.02785,
  0.02645, 0.02563, 0.02494, 0.02392, 0.02539, 0.02707, 0.02852, 0.0344, 0.03411, 0.02821, 0.02464,
  0.02364, 0.02494, 0.02307, 0.02229, 0.02208, 0.02157, 0.0216, 0.02195, 0.02271, 0.02381, 0.02675,
  0.02779, 0.02797, 0.02773, 0.0264, 0.0244, 0.02418, 0.02572, 0.02804, 0.03003, 0.03617, 0.03655,
  0.0326, 0.02828, 0.02567, 0.02607, 0.02492, 0.02344, 0.0234, 0.02516, 0.0289, 0.0388, 0.04333,
  0.04162, 0.03856, 0.03959, 0.03725, 0.03462, 0.03625, 0.03255, 0.0333, 0.03361, 0.03777, 0.0396,
  0.0446, 0.04272, 0.03774, 0.03106, 0.02937, 0.02801, 0.02636, 0.02576, 0.02575, 0.02573, 0.02916,
  0.03891, 0.04225, 0.03866, 0.03778, 0.03753, 0.03567, 0.03242, 0.03302, 0.03147, 0.03141, 0.03422,
  0.03636, 0.03657, 0.04258, 0.03951, 0.03556, 0.02821, 0.02705, 0.02568, 0.02415, 0.02282, 0.02281,
  0.02072, 0.02498, 0.02866, 0.03572, 0.03104, 0.02827, 0.02729, 0.02365, 0.02303, 0.02318, 0.02326,
  0.02458, 0.02688, 0.02823, 0.02807, 0.0313, 0.03177, 0.02811, 0.02487, 0.02262, 0.02526, 0.02412,
  0.02294, 0.02312, 0.02415, 0.02578, 0.03298, 0.03797, 0.03503, 0.03454, 0.03312, 0.02972, 0.02803,
  0.02876, 0.02799, 0.0275, 0.02793, 0.0296, 0.03129, 0.03749, 0.03806, 0.03241, 0.02789, 0.0252,
  0.02567, 0.02478, 0.0235, 0.02392, 0.02454, 0.02681, 0.03588, 0.0396, 0.03798, 0.03641, 0.03924,
  0.0392, 0.03709, 0.03864, 0.03623, 0.03597, 0.03821, 0.03823, 0.03806, 0.04307, 0.03976, 0.03598,
  0.02963, 0.02673, 0.03026, 0.02721, 0.02543, 0.02514, 0.02498, 0.02524, 0.02764, 0.02967, 0.03068,
  0.03268, 0.0316, 0.03012, 0.02702, 0.02608, 0.02531, 0.02531, 0.02794, 0.02964, 0.0309, 0.03664,
  0.03655, 0.0325, 0.02829, 0.02711, 0.02405, 0.02259, 0.02113, 0.02034, 0.02018, 0.02011, 0.02143,
  0.02332, 0.02437, 0.02561, 0.02559, 0.0252, 0.02444, 0.02381, 0.02381, 0.02404, 0.02422, 0.02882,
  0.02944, 0.03571, 0.03655, 0.03099, 0.02603, 0.0242, 0.02438, 0.02268, 0.02228, 0.02221, 0.023,
  0.02585, 0.03064, 0.03643, 0.03673, 0.03621, 0.03666, 0.03667, 0.03548, 0.03572, 0.03511, 0.03484,
  0.03553, 0.03558, 0.03716, 0.04008, 0.04129, 0.03468, 0.02844, 0.02594, 0.02588, 0.02438, 0.02415,
  0.02341, 0.02386, 0.02665, 0.03251, 0.03813, 0.03938, 0.0388, 0.03937, 0.03676, 0.03545, 0.03343,
  0.03244, 0.03275, 0.0343, 0.03671, 0.03901, 0.04428, 0.04253, 0.03514, 0.029, 0.02619, 0.0261,
  0.02479, 0.02441, 0.02388, 0.02479, 0.0268, 0.03556, 0.04186, 0.0406, 0.03895, 0.03738, 0.03538,
  0.03374, 0.03401, 0.03246, 0.03287, 0.03371, 0.03547, 0.03527, 0.0391, 0.04293, 0.03546, 0.02925,
  0.02677, 0.02759, 0.02679, 0.02596, 0.02535, 0.02636, 0.02843, 0.03973, 0.04382, 0.04092, 0.03749,
  0.03451, 0.03252, 0.0319, 0.03051, 0.0283, 0.0282, 0.0294, 0.03185, 0.03341, 0.03829, 0.04212,
  0.03425, 0.02813, 0.0263, 0.01985, 0.02278, 0.02352, 0.02377, 0.02397, 0.01786, 0.02791, 0.02964,
  0.02935, 0.02791, 0.02771, 0.02624, 0.0254, 0.02645, 0.02423, 0.02322, 0.02489, 0.0277, 0.02941,
  0.02906, 0.03001, 0.02842, 0.02263, 0.02196, 0.02352, 0.02181, 0.01835, 0.01691, 0.01374, 0.01547,
  0.01684, 0.01897, 0.02707, 0.02769, 0.02703, 0.02628, 0.02556, 0.02447, 0.02241, 0.0242, 0.02477,
  0.02665, 0.02762, 0.03123, 0.03187, 0.0286, 0.02421, 0.02268, 0.01793, 0.01784, 0.0172, 0.01723,
  0.01668, 0.0167, 0.01677, 0.0167, 0.01725, 0.01782, 0.01996, 0.01873, 0.0195, 0.01908, 0.01885,
  0.01903, 0.01896, 0.02075, 0.02087, 0.02314, 0.02397, 0.02007, 0.02015, 0.01673, 0.01668, 0.016,
  0.01596, 0.01657, 0.01665, 0.01721, 0.01849, 0.0246, 0.02659, 0.02617, 0.0275, 0.02597, 0.02344,
  0.02293, 0.02124, 0.02088, 0.02118, 0.02119, 0.02396, 0.03863, 0.03812, 0.0324, 0.02594, 0.02179,
  0.02442, 0.02278, 0.02136, 0.02137, 0.02145, 0.02269, 0.03022, 0.03365, 0.02976, 0.02745, 0.0283,
  0.02506, 0.02324, 0.0221, 0.02198, 0.02208, 0.02346, 0.02763, 0.02867, 0.03283, 0.03752, 0.03192,
  0.02545, 0.02203, 0.01783, 0.01783, 0.0174, 0.01765, 0.01772, 0.02055, 0.02778, 0.03518, 0.02859,
  0.02579, 0.02502, 0.02255, 0.02141, 0.02115, 0.02054, 0.02072, 0.02125, 0.02366, 0.02503, 0.02849,
  0.03503, 0.03127, 0.02231, 0.01774, 0.01986, 0.01707, 0.01501, 0.01717, 0.01739, 0.01961, 0.02391,
  0.03369, 0.02896, 0.02689, 0.0273, 0.02637, 0.02517, 0.0254, 0.02456, 0.02352, 0.02583, 0.02748,
  0.02604, 0.03087, 0.03184, 0.02721, 0.02047, 0.01788, 0.01711, 0.01111, 0.01132, 0.01099, 0.01119,
  0.01244, 0.01946, 0.02545, 0.02794, 0.02762, 0.02886, 0.02462, 0.02548, 0.02458, 0.02304, 0.02318,
  0.02407, 0.02641, 0.02554, 0.02884, 0.03022, 0.02534, 0.0219, 0.01919, 0.01934, 0.01789, 0.01698,
  0.01628, 0.01492, 0.01533, 0.01899, 0.02096, 0.02261, 0.02418, 0.0259, 0.02557, 0.02369, 0.02314,
  0.02124, 0.02092, 0.02102, 0.02324, 0.02303, 0.0263, 0.02873, 0.02302, 0.01983, 0.01773, 0.01976,
  0.0194, 0.01616, 0.01551, 0.01505, 0.01509, 0.0158, 0.0162, 0.01633, 0.01714, 0.01831, 0.01726,
  0.018, 0.01804, 0.0176, 0.01743, 0.01804, 0.02044, 0.02006, 0.02363, 0.02788, 0.02393, 0.02087,
  0.01784, 0.0189, 0.01786, 0.01759, 0.01611, 0.01641, 0.01762, 0.02192, 0.02916, 0.02975, 0.02938,
  0.03102, 0.02936, 0.02889, 0.02719, 0.02546, 0.02521, 0.02701, 0.02981, 0.03057, 0.03474, 0.03519,
  0.03031, 0.02246, 0.02102, 0.01908, 0.01887, 0.01681, 0.01692, 0.01648, 0.01857, 0.02418, 0.02926,
  0.02807, 0.02668, 0.02819, 0.02717, 0.0262, 0.025, 0.02488, 0.02426, 0.02657, 0.02974, 0.02942,
  0.03258, 0.03376, 0.02773, 0.02055, 0.01857, 0.02051, 0.01922, 0.01822, 0.01834, 0.01803, 0.02058,
  0.02567, 0.02929, 0.02668, 0.02657, 0.02764, 0.02591, 0.02532, 0.02379, 0.02356, 0.02356, 0.02557,
  0.02857, 0.02899, 0.03239, 0.03403, 0.02824, 0.02261, 0.0207, 0.02093, 0.02071, 0.01889, 0.0178,
  0.01855, 0.02052, 0.02376, 0.02389, 0.02279, 0.02121, 0.02102, 0.02058, 0.0207, 0.02082, 0.02079,
  0.02044, 0.02014, 0.02183, 0.02256, 0.02605, 0.03163, 0.02506, 0.01996, 0.0186, 0.01983, 0.01957,
  0.01837, 0.01816, 0.0179, 0.0187, 0.02488, 0.0272, 0.02801, 0.0293, 0.02915, 0.02861, 0.02741,
  0.02633, 0.0251, 0.02495, 0.02616, 0.02788, 0.02786, 0.02932, 0.03123, 0.02327, 0.02181, 0.01977,
  0.01717, 0.01697, 0.0163, 0.01637, 0.01526, 0.01723, 0.01863, 0.01888, 0.02431, 0.02625, 0.02633,
  0.0259, 0.02371, 0.02237, 0.02185, 0.02112, 0.02234, 0.02314, 0.02382, 0.02644, 0.02856, 0.02544,
  0.02204, 0.02071, 0.02151, 0.01803, 0.01783, 0.01789, 0.01638, 0.01553, 0.0174, 0.01789, 0.01992,
  0.02292, 0.02555, 0.02538, 0.02486, 0.02393, 0.02147, 0.02181, 0.02398, 0.02488, 0.02692, 0.0295,
  0.03203, 0.0287, 0.02428, 0.02332, 0.02125, 0.01925, 0.01782, 0.01759, 0.01942, 0.02247, 0.02611,
  0.03038, 0.02821, 0.02706, 0.02714, 0.02683, 0.02556, 0.02506, 0.0245, 0.0246, 0.02628, 0.02724,
  0.02723, 0.03092, 0.03389, 0.02827, 0.02083, 0.01927, 0.02298, 0.02017, 0.01806, 0.01909, 0.018,
  0.02076, 0.02525, 0.02884, 0.02912, 0.03005, 0.02965, 0.02913, 0.0278, 0.0271, 0.0266, 0.02706,
  0.02849, 0.03066, 0.03118, 0.03238, 0.03345, 0.02821, 0.02052, 0.01942, 0.02004, 0.01683, 0.01467,
  0.01429, 0.01429, 0.01661, 0.02408, 0.02745, 0.02679, 0.02733, 0.02707, 0.02696, 0.02595, 0.0251,
  0.02451, 0.02446, 0.02621, 0.02844, 0.02772, 0.02863, 0.02957, 0.02546, 0.02062, 0.01993, 0.02058,
  0.01757, 0.01524, 0.01502, 0.01424, 0.01668, 0.02077, 0.02853, 0.02907, 0.03009, 0.03008, 0.02914,
  0.02759, 0.02699, 0.02663, 0.02712, 0.02981, 0.03174, 0.03014, 0.03116, 0.03362, 0.02597, 0.02015,
  0.02111, 0.02097, 0.019, 0.01719, 0.01659, 0.01746, 0.01973, 0.02183, 0.0252, 0.02605, 0.02745,
  0.02746, 0.02817, 0.02713, 0.02719, 0.02569, 0.027, 0.02811, 0.02924, 0.02634, 0.0257, 0.02715,
  0.02293, 0.02087, 0.01969, 0.01922, 0.01639, 0.01355, 0.01228, 0.01162, 0.01873, 0.01993, 0.02051,
  0.02277, 0.02609, 0.02549, 0.02517, 0.02307, 0.0218, 0.02136, 0.02136, 0.02272, 0.02525, 0.02616,
  0.02751, 0.0246, 0.02188, 0.01854, 0.01906, 0.02089, 0.01853, 0.01717, 0.01633, 0.01561, 0.01605,
  0.01566, 0.0156, 0.01774, 0.01939, 0.02022, 0.02071, 0.01999, 0.02002, 0.01875, 0.01864, 0.02003,
  0.02262, 0.02509, 0.02655, 0.02734, 0.02394, 0.02021, 0.02047, 0.02049, 0.01917, 0.01653, 0.01661,
  0.01659, 0.01998, 0.02024, 0.02271, 0.02257, 0.0227, 0.02284, 0.02268, 0.02065, 0.02272, 0.02214,
  0.0243, 0.02636, 0.02998, 0.02851, 0.02857, 0.02835, 0.02292, 0.01909, 0.02034, 0.01884, 0.01701,
  0.01545, 0.01505, 0.01498, 0.01918, 0.02023, 0.02379, 0.02238, 0.02089, 0.02216, 0.02237, 0.02207,
  0.02289, 0.02281, 0.02381, 0.02566, 0.02859, 0.02812, 0.02563, 0.02793, 0.02229, 0.01903, 0.01785,
  0.0171, 0.0154, 0.01435, 0.01112, 0.01154, 0.01497, 0.0179, 0.021, 0.01923, 0.01727, 0.01896,
  0.01856, 0.01797, 0.01781, 0.01777, 0.01863, 0.01863, 0.02043, 0.01877, 0.01999, 0.02285, 0.01912,
  0.01628, 0.01305, 0.01568, 0.01312, 0.01066, 0.01072, 0.01095, 0.01409, 0.01816, 0.02216, 0.01971,
  0.01889, 0.02012, 0.0196, 0.01918, 0.01836, 0.01894, 0.0205, 0.02136, 0.02386, 0.02289, 0.02364,
  0.02606, 0.01828, 0.01737, 0.0155, 0.01329, 0.01231, 0.01006, 0.01005, 0.00568, 0.01324, 0.01721,
  0.02057, 0.02055, 0.02096, 0.02181, 0.02083, 0.02043, 0.02117, 0.01951, 0.02049, 0.02123, 0.02383,
  0.02232, 0.02216, 0.0227, 0.01887, 0.0198, 0.0167, 0.01432, 0.01281, 0.01159, 0.01085, 0.01055,
  0.01573, 0.01652, 0.0192, 0.02004, 0.02162, 0.02127, 0.02045, 0.01873, 0.018, 0.01799, 0.01775,
  0.02036, 0.02096, 0.02416, 0.02577, 0.02328, 0.02095, 0.01813, 0.01427, 0.01958, 0.01726, 0.01387,
  0.01306, 0.01288, 0.01261, 0.01227, 0.01533, 0.01517, 0.01871, 0.0195, 0.01967, 0.01897, 0.0186,
  0.01623, 0.01555, 0.01883, 0.02156, 0.02438, 0.02571, 0.02563, 0.02304, 0.02051, 0.01723, 0.01759,
  0.01539, 0.01408, 0.01469, 0.01487, 0.01741, 0.02028, 0.02118, 0.02286, 0.02298, 0.02368, 0.02299,
  0.02292, 0.02167, 0.02121, 0.02085, 0.02253, 0.02576, 0.02688, 0.02767, 0.02592, 0.02239, 0.02066,
  0.0205, 0.01932, 0.01685, 0.01546, 0.01596, 0.01559, 0.01999, 0.02053, 0.02405, 0.02286, 0.02382,
  0.02406, 0.0229, 0.02278, 0.0215, 0.02005, 0.02033, 0.02202, 0.02277, 0.02302, 0.02313, 0.02547,
  0.02257, 0.02027, 0.02032, 0.01828, 0.01588, 0.01469, 0.01454, 0.0149, 0.01709, 0.0215, 0.0213,
  0.01975, 0.01896, 0.01901, 0.01806, 0.01805, 0.01724, 0.01678, 0.01665, 0.01765, 0.01966, 0.022,
  0.02229, 0.02487, 0.02223, 0.02041, 0.01949, 0.01634, 0.01523, 0.01415, 0.01343, 0.01424, 0.0161,
  0.01984, 0.0234, 0.02039, 0.01981, 0.01888, 0.018, 0.01771, 0.01801, 0.018, 0.01837, 0.01871,
  0.0227, 0.02288, 0.02288, 0.02352, 0.02227, 0.02003, 0.01791, 0.01662, 0.0142, 0.0139, 0.01305,
  0.01259, 0.01477, 0.01992, 0.02109, 0.0191, 0.0209, 0.02294, 0.0209, 0.01765, 0.0175, 0.01697,
  0.01902, 0.01789, 0.01967, 0.01847, 0.01815, 0.02076, 0.02032, 0.01895, 0.01875, 0.01679, 0.01509,
  0.01383, 0.01003, 0.00938, 0.01562, 0.01572, 0.01796, 0.01977, 0.02126, 0.02047, 0.01986, 0.01782,
  0.01798, 0.01761, 0.01904, 0.02452, 0.02664, 0.02593, 0.02519, 0.02225, 0.02042, 0.01655, 0.01576,
  0.01505, 0.01341, 0.01037, 0.01035, 0.00888, 0.00648, 0.00724, 0.01322, 0.01375, 0.0163, 0.01751,
  0.01763, 0.01769, 0.01781, 0.0184, 0.01891, 0.02214, 0.02661, 0.02706, 0.02711, 0.02801, 0.02436,
  0.02036, 0.01807, 0.01785, 0.01687, 0.01473, 0.01597, 0.01547, 0.01779, 0.0203, 0.02383, 0.02611,
  0.02445, 0.02541, 0.0299, 0.02841, 0.03516, 0.03867, 0.04126, 0.04668, 0.04125, 0.03719, 0.03189,
  0.0305, 0.02801, 0.02469, 0.02164, 0.01944, 0.01833, 0.01749, 0.01643, 0.01641, 0.01691, 0.0225,
  0.02335, 0.0232, 0.02223, 0.02159, 0.02157, 0.01972, 0.02161, 0.02121, 0.02221, 0.02591, 0.02864,
  0.02802, 0.02685, 0.02818, 0.02453, 0.02088, 0.01856, 0.01717, 0.01606, 0.0148, 0.01485, 0.01328,
  0.01577, 0.02247, 0.02246, 0.02167, 0.02141, 0.02114, 0.02125, 0.02097, 0.02179, 0.02233, 0.02249,
  0.02424, 0.02693, 0.02431, 0.02369, 0.02507, 0.02326, 0.01974, 0.01911, 0.01725, 0.01546, 0.01454,
  0.01293, 0.01084, 0.01556, 0.02036, 0.02248, 0.02135, 0.02266, 0.02216, 0.02208, 0.02184, 0.02344,
  0.0233, 0.02438, 0.02699, 0.02934, 0.0267, 0.02684, 0.02717, 0.02525, 0.02035, 0.0191, 0.01838,
  0.0167, 0.01525, 0.0153, 0.01575, 0.01605, 0.01823, 0.02058, 0.01995, 0.02125, 0.02018, 0.02007,
  0.01911, 0.02033, 0.02028, 0.0226, 0.02145, 0.02477, 0.02442, 0.0233, 0.0233, 0.02206, 0.01858,
  0.01938, 0.01856, 0.01665, 0.01562, 0.0136, 0.01462, 0.01686, 0.0162, 0.01755, 0.01863, 0.02101,
  0.0212, 0.02152, 0.02136, 0.02159, 0.02162, 0.02222, 0.02414, 0.02678, 0.02631, 0.02585, 0.02408,
  0.02265, 0.01873, 0.0178, 0.01754, 0.01569, 0.01422, 0.01399, 0.01337, 0.01405, 0.01331, 0.01412,
  0.01606, 0.01754, 0.01914, 0.02097, 0.02128, 0.02335, 0.0243, 0.02675, 0.02988, 0.03195, 0.03014,
  0.03116, 0.03318, 0.02799, 0.0245, 0.02182, 0.02099, 0.01765, 0.01617, 0.01571, 0.01495, 0.01535,
  0.01443, 0.01445, 0.01711, 0.02055, 0.02132, 0.02246, 0.02242, 0.02345, 0.02394, 0.02566, 0.02718,
  0.02946, 0.02921, 0.02896, 0.02895, 0.02553, 0.02058, 0.01975, 0.02017, 0.017, 0.01619, 0.01515,
  0.01487, 0.0162, 0.01919, 0.02215, 0.0223, 0.02246, 0.0237, 0.02411, 0.02424, 0.02433, 0.02439,
  0.02486, 0.02794, 0.02956, 0.02659, 0.02645, 0.02617, 0.02358, 0.02027, 0.01913, 0.01934, 0.01698,
  0.01647, 0.01621, 0.01646, 0.01682, 0.01943, 0.02082, 0.02132, 0.02143, 0.02223, 0.0228, 0.02332,
  0.02384, 0.02544, 0.02575, 0.02971, 0.03156, 0.03038, 0.02702, 0.02891, 0.02488, 0.02059, 0.01994,
  0.02121, 0.01818, 0.01768, 0.01681, 0.01672, 0.01849, 0.02018, 0.02252, 0.02292, 0.02464, 0.02509,
  0.02621, 0.02451, 0.0255, 0.02561, 0.02568, 0.02783, 0.02996, 0.02757, 0.0268, 0.02822, 0.02504,
  0.02181, 0.01996, 0.01894, 0.01554, 0.01475, 0.01347, 0.01302, 0.0142, 0.01938, 0.02191, 0.0217,
  0.02217, 0.02275, 0.02367, 0.02348, 0.02349, 0.02321, 0.02373, 0.02537, 0.0255, 0.02453, 0.02361,
  0.02354, 0.0214, 0.02067, 0.02043, 0.01825, 0.0174, 0.01576, 0.01519, 0.01451, 0.01561, 0.01479,
  0.01775, 0.01816, 0.01931, 0.02013, 0.02242, 0.02295, 0.02298, 0.02398, 0.02469, 0.02666, 0.02778,
  0.02597, 0.02431, 0.02449, 0.02411, 0.02101, 0.01748, 0.01861, 0.01639, 0.01505, 0.01458, 0.01351,
  0.01344, 0.01405, 0.01494, 0.01464, 0.01643, 0.0176, 0.01749, 0.01846, 0.02446, 0.0249, 0.02777,
  0.03023, 0.02948, 0.02825, 0.02867, 0.02769, 0.02515, 0.01858, 0.01753, 0.01943, 0.01729, 0.01553,
  0.01511, 0.01526, 0.01619, 0.01886, 0.02185, 0.02256, 0.02362, 0.02379, 0.02362, 0.02431, 0.02476,
  0.02502, 0.02512, 0.02713, 0.02736, 0.02594, 0.02406, 0.02461, 0.02362, 0.02033, 0.01836, 0.01906,
  0.01734, 0.01651, 0.01605, 0.01595, 0.01625, 0.01943, 0.02173, 0.02111, 0.02133, 0.02186, 0.02167,
  0.02166, 0.02226, 0.02397, 0.02404, 0.02626, 0.02707, 0.02501, 0.0237, 0.02496, 0.02359, 0.02047,
  0.0191, 0.01912, 0.01781, 0.01639, 0.01637, 0.01633, 0.01705, 0.01816, 0.02085, 0.02221, 0.02308,
  0.02334, 0.02355, 0.02446, 0.02685, 0.02953, 0.03183, 0.03542, 0.03523, 0.03104, 0.02847, 0.0303,
  0.02765, 0.02388, 0.02243, 0.02124, 0.01978, 0.01831, 0.01806, 0.01781, 0.01916, 0.02172, 0.02402,
  0.02427, 0.02521, 0.02656, 0.0275, 0.02805, 0.03187, 0.0335, 0.03736, 0.03898, 0.03914, 0.03464,
  0.03146, 0.03211, 0.02956, 0.02516, 0.02371, 0.01962, 0.01711, 0.01584, 0.01487, 0.01445, 0.01649,
  0.02021, 0.02229, 0.02328, 0.02327, 0.02538, 0.02557, 0.02592, 0.02853, 0.03039, 0.03172, 0.03348,
  0.0318, 0.02992, 0.02669, 0.02564, 0.02523, 0.02281, 0.02011, 0.01763, 0.01556, 0.01486, 0.01405,
  0.01396, 0.01328, 0.01407, 0.01433, 0.01603, 0.01773, 0.01812, 0.02001, 0.02292, 0.02373, 0.02497,
  0.02659, 0.02815, 0.02896, 0.02717, 0.02551, 0.02474, 0.02435, 0.02047, 0.01853, 0.01731, 0.01486,
  0.0142, 0.01374, 0.01353, 0.01322, 0.01293, 0.01294, 0.0143, 0.0156, 0.01682, 0.0175, 0.01775,
  0.02028, 0.02261, 0.02373, 0.02465, 0.02562, 0.02498, 0.02339, 0.02292, 0.02235, 0.01827, 0.01614,
  0.01812, 0.01529, 0.01542, 0.01482, 0.01522, 0.01456, 0.01813, 0.01869, 0.01909, 0.01993, 0.02143,
  0.02224, 0.02342, 0.02421, 0.02478, 0.0264, 0.02756, 0.02864, 0.02737, 0.02345, 0.02416, 0.02231,
  0.0196, 0.01796, 0.01775, 0.01559, 0.01515, 0.01452, 0.01443, 0.01572, 0.01937, 0.02169, 0.02431,
  0.02451, 0.02652, 0.02543, 0.02573, 0.02795, 0.02976, 0.03079, 0.03187, 0.03146, 0.02966, 0.02696,
  0.02781, 0.02533, 0.02007, 0.01794, 0.01786, 0.01586, 0.01441, 0.0139, 0.01379, 0.01451, 0.01767,
  0.0202, 0.02074, 0.02056, 0.01974, 0.02023, 0.02067, 0.02139, 0.02311, 0.02386, 0.02464, 0.02485,
  0.02328, 0.0216, 0.02178, 0.02148, 0.01837, 0.01765, 0.01662, 0.01544, 0.01441, 0.01423, 0.01396,
  0.01462, 0.01685, 0.01899, 0.01975, 0.02022, 0.02053, 0.02133, 0.0212, 0.02216, 0.0223, 0.02338,
  0.02374, 0.02486, 0.02314, 0.02204, 0.02195, 0.02109, 0.01678, 0.01601, 0.01731, 0.01474, 0.01363,
  0.01341, 0.0134, 0.0146, 0.01744, 0.01885, 0.02257, 0.02348, 0.02471, 0.02516, 0.02533, 0.02578,
  0.02557, 0.02743, 0.02239, 0.02315, 0.02175, 0.02067, 0.02131, 0.02002, 0.01781, 0.01654, 0.01662,
  0.01478, 0.01394, 0.01336, 0.01281, 0.01278, 0.01278, 0.0134, 0.01517, 0.01768, 0.01786, 0.01925,
  0.01941, 0.01951, 0.01981, 0.02239, 0.0243, 0.02606, 0.02576, 0.0249, 0.02518, 0.02326, 0.01911,
  0.01785, 0.01687, 0.01554, 0.01482, 0.01416, 0.01426, 0.01356, 0.01385, 0.01431, 0.01624, 0.01865,
  0.01904, 0.01976, 0.02082, 0.02111, 0.02084, 0.02245, 0.02472, 0.02779, 0.02661, 0.0249, 0.02477,
  0.02295, 0.02018, 0.01945, 0.01931, 0.01643, 0.01593, 0.01555, 0.0144, 0.01682, 0.01867, 0.02112,
  0.02138, 0.02232, 0.02399, 0.02428, 0.02536, 0.0273, 0.02767, 0.02938, 0.03209, 0.03275, 0.02935,
  0.02789, 0.02792, 0.02627, 0.02214, 0.02043, 0.0199, 0.0178, 0.01575, 0.01522, 0.01506, 0.01679,
  0.01898, 0.01956, 0.01962, 0.02134, 0.02296, 0.0237, 0.02449, 0.02684, 0.02726, 0.02749, 0.0283,
  0.02913, 0.02572, 0.02478, 0.02462, 0.02365, 0.019, 0.01964, 0.01704, 0.01446, 0.0129, 0.0124,
  0.01188, 0.01452, 0.01743, 0.01976, 0.01879, 0.02003, 0.02177, 0.02233, 0.02328, 0.02471, 0.02517,
  0.02517, 0.02619, 0.02694, 0.02473, 0.02374, 0.02394, 0.02232, 0.01904, 0.01839, 0.01915, 0.01755,
  0.01622, 0.01506, 0.01533, 0.01733, 0.02005, 0.02088, 0.02085, 0.02117, 0.02223, 0.02286, 0.02351,
  0.02479, 0.02564, 0.02713, 0.02818, 0.02725, 0.02517, 0.02474, 0.02465, 0.02364, 0.01962, 0.01964,
  0.01932, 0.01749, 0.01592, 0.01523, 0.01489, 0.01579, 0.01833, 0.02017, 0.02018, 0.0209, 0.02243,
  0.02333, 0.02369, 0.02467, 0.02531, 0.02549, 0.02621, 0.02575, 0.02365, 0.02226, 0.0224, 0.02149,
  0.01935, 0.01834, 0.01821, 0.01639, 0.01538, 0.01432, 0.01367, 0.01334, 0.01315, 0.01495, 0.01695,
  0.01844, 0.01894, 0.01946, 0.02004, 0.02035, 0.02078, 0.02136, 0.02405, 0.02687, 0.02491, 0.02344,
  0.02254, 0.02159, 0.0192, 0.01817, 0.01791, 0.01552, 0.01463, 0.01372, 0.01298, 0.01321, 0.01193,
  0.01181, 0.01605, 0.01644, 0.01824, 0.01866, 0.02036, 0.02066, 0.02036, 0.02257, 0.02492, 0.02692,
  0.02583, 0.0246, 0.02427, 0.02325, 0.02019, 0.0191, 0.01824, 0.01602, 0.01505, 0.01455, 0.01328,
  0.01461, 0.01703, 0.01862, 0.01982, 0.0206, 0.02204, 0.02417, 0.02624, 0.02794, 0.02842, 0.03184,
  0.03264, 0.03311, 0.02994, 0.0272, 0.02655, 0.02523, 0.0213, 0.01918, 0.02065, 0.01731, 0.01579,
  0.01516, 0.01519, 0.01612, 0.01857, 0.01938, 0.01993, 0.02142, 0.02325, 0.02408, 0.02542, 0.02749,
  0.02799, 0.02846, 0.02908, 0.02908, 0.02772, 0.02556, 0.02553, 0.02389, 0.02082, 0.02, 0.0214,
  0.01936, 0.01721, 0.01586, 0.01553, 0.01788, 0.01899, 0.01909, 0.02003, 0.0216, 0.02341, 0.02525,
  0.02719, 0.02856, 0.0298, 0.03382, 0.03729, 0.0367, 0.03335, 0.02969, 0.02853, 0.02636, 0.02171,
  0.02121, 0.0204, 0.0189, 0.01797, 0.01728, 0.01717, 0.01795, 0.01969, 0.02063, 0.02225, 0.02439,
  0.02639, 0.0288, 0.02994, 0.03214, 0.03424, 0.03789, 0.04103, 0.04116, 0.03546, 0.03145, 0.03005,
  0.02772, 0.02344, 0.02189, 0.02057, 0.01776, 0.01583, 0.01509, 0.01432, 0.01639, 0.01873, 0.01983,
  0.02114, 0.0228, 0.02497, 0.02802, 0.03024, 0.03223, 0.03515, 0.03904, 0.04214, 0.04025, 0.0333,
  0.03122, 0.02922, 0.02732, 0.02425, 0.02191, 0.02374, 0.02062, 0.01869, 0.01709, 0.01684, 0.0173,
  0.018, 0.02022, 0.02245, 0.02404, 0.02546, 0.02893, 0.032, 0.0348, 0.04509, 0.05438, 0.05693,
  0.05275, 0.04427, 0.03368, 0.03212, 0.03522, 0.02968, 0.02414, 0.02555, 0.02203, 0.01755, 0.01668,
  0.01605, 0.01586, 0.01565, 0.01662, 0.02023, 0.02188, 0.02437, 0.0275, 0.03037, 0.03143, 0.03253,
  0.03356, 0.03527, 0.03402, 0.03177, 0.03068, 0.03, 0.02176, 0.01843, 0.01801, 0.01956, 0.0171,
  0.01571, 0.0152, 0.01481, 0.01515, 0.0153, 0.01771, 0.02015, 0.02263, 0.02307, 0.02428, 0.02588,
  0.02868, 0.03215, 0.03381, 0.03722, 0.04058, 0.03307, 0.03112, 0.02886, 0.02403, 0.02048, 0.01928,
  0.02062, 0.01855, 0.01812, 0.01653, 0.01542, 0.01698, 0.01772, 0.01955, 0.02078, 0.02298, 0.02531,
  0.02721, 0.02926, 0.03087, 0.03289, 0.03611, 0.03876, 0.03932, 0.03408, 0.03134, 0.02989, 0.0281,
  0.02399, 0.02188, 0.02255, 0.02026, 0.01868, 0.01759, 0.01736, 0.01856, 0.01991, 0.02089, 0.02274,
  0.02502, 0.02766, 0.02991, 0.03102, 0.03336, 0.03779, 0.04169, 0.04592, 0.04589, 0.03823, 0.03304,
  0.0308, 0.02908, 0.02464, 0.02296, 0.02429, 0.02205, 0.02091, 0.01918, 0.01911, 0.01879, 0.017,
  0.01904, 0.02016, 0.0224, 0.02413, 0.02696, 0.02876, 0.0297, 0.03153, 0.03327, 0.03612, 0.03689,
  0.03339, 0.03093, 0.02869, 0.0267, 0.02332, 0.0222, 0.02284, 0.02135, 0.01879, 0.01821, 0.01856,
  0.01902, 0.01855, 0.01979, 0.0222, 0.02427, 0.02593, 0.02955, 0.03147, 0.03297, 0.03684, 0.03909,
  0.04147, 0.03987, 0.03357, 0.02989, 0.02828, 0.02815, 0.02422, 0.02325, 0.02385, 0.0219, 0.02026,
  0.01875, 0.01861, 0.01797, 0.0183, 0.02021, 0.02026, 0.02287, 0.02578, 0.02755, 0.02974, 0.0309,
  0.03117, 0.03395, 0.03448, 0.03552, 0.03124, 0.02963, 0.02737, 0.02684, 0.02373, 0.02386, 0.02402,
  0.02218, 0.02086, 0.0197, 0.01928, 0.01901, 0.01852, 0.01923, 0.02159, 0.02395, 0.0261, 0.0276,
  0.02957, 0.03081, 0.03144, 0.03361, 0.03089, 0.03104, 0.02897, 0.02783, 0.02761, 0.02631, 0.02127,
  0.02072, 0.02178, 0.01984, 0.01846, 0.01745, 0.01695, 0.01748, 0.01848, 0.01942, 0.01987, 0.02236,
  0.02499, 0.02588, 0.02752, 0.02945, 0.03092, 0.0325, 0.03455, 0.03587, 0.03103, 0.02924, 0.029,
  0.02675, 0.02381, 0.02082, 0.02045, 0.01885, 0.01757, 0.01619, 0.01561, 0.01736, 0.01869, 0.01946,
  0.02005, 0.02227, 0.02428, 0.02599, 0.02766, 0.02994, 0.03178, 0.03451, 0.0369, 0.039, 0.03413,
  0.03021, 0.02888, 0.02751, 0.02361, 0.0219, 0.02195, 0.02094, 0.01869, 0.01816, 0.01804, 0.01877,
  0.02064, 0.02138, 0.02223, 0.02342, 0.0267, 0.0289, 0.03284, 0.03669, 0.03987, 0.04488, 0.04885,
  0.05053, 0.03993, 0.03444, 0.03264, 0.03197, 0.02723, 0.0248, 0.02327, 0.02139, 0.02014, 0.01917,
  0.01912, 0.01998, 0.02167, 0.02326, 0.0266, 0.02874, 0.02998, 0.03229, 0.03508, 0.03749, 0.03909,
  0.04104, 0.04454, 0.04439, 0.03765, 0.0346, 0.03283, 0.03116, 0.02637, 0.02471, 0.02452, 0.02234,
  0.0212, 0.02122, 0.02041, 0.02092, 0.02288, 0.02407, 0.02707, 0.02866, 0.0316, 0.03343, 0.03565,
  0.03775, 0.03834, 0.04073, 0.04213, 0.04124, 0.03551, 0.0318, 0.03112, 0.03073, 0.02674, 0.02475,
  0.0239, 0.02211, 0.02107, 0.02017, 0.01969, 0.01893, 0.01971, 0.02035, 0.02283, 0.02485, 0.02752,
  0.02955, 0.03145, 0.03332, 0.03424, 0.03635, 0.03872, 0.04002, 0.0348, 0.03322, 0.0331, 0.03141,
  0.02989, 0.02685, 0.02442, 0.02263, 0.02145, 0.02077, 0.01992, 0.01931, 0.01928, 0.01976, 0.02172,
  0.02418, 0.02591, 0.02766, 0.02946, 0.03131, 0.03369, 0.03647, 0.04032, 0.03671, 0.03439, 0.03087,
  0.03038, 0.02882, 0.02409, 0.02386, 0.02244, 0.02057, 0.02013, 0.01898, 0.01857, 0.01944, 0.02055,
  0.02187, 0.02387, 0.02498, 0.02719, 0.029, 0.03128, 0.03418, 0.03499, 0.03803, 0.04059, 0.0422,
  0.03649, 0.03317, 0.03153, 0.03066, 0.02633, 0.02383, 0.02326, 0.02205, 0.02097, 0.01991, 0.01971,
  0.02064, 0.02082, 0.02183, 0.02349, 0.02524, 0.02846, 0.03094, 0.03314, 0.03642, 0.03966, 0.04277,
  0.04497, 0.04577, 0.03991, 0.0358, 0.03402, 0.03273, 0.02872, 0.02669, 0.02525, 0.02353, 0.02218,
  0.02177, 0.02154, 0.02258, 0.02371, 0.02441, 0.0273, 0.02903, 0.03116, 0.03308, 0.03675, 0.04021,
  0.04428, 0.04784, 0.05095, 0.04926, 0.04208, 0.03785, 0.03604, 0.03267, 0.0286, 0.02587, 0.02489,
  0.023, 0.02158, 0.02098, 0.02039, 0.02141, 0.023, 0.02441, 0.02616, 0.02893, 0.03089, 0.03372,
  0.03537, 0.0379, 0.03906, 0.04095, 0.04438, 0.04317, 0.03734, 0.03415, 0.03339, 0.0304, 0.02693,
  0.02492, 0.02457, 0.02231, 0.02121, 0.02041, 0.01989, 0.02079, 0.02192, 0.02439, 0.02701, 0.02896,
  0.0333, 0.03649, 0.04054, 0.04674, 0.05405, 0.06019, 0.06475, 0.06485, 0.05689, 0.04441, 0.03979,
  0.03653, 0.03124, 0.0279, 0.027, 0.02521, 0.02441, 0.02329, 0.02282, 0.02294, 0.02373, 0.02389,
  0.02747, 0.03134, 0.03493, 0.03788, 0.04203, 0.04654, 0.05313, 0.06051, 0.06892, 0.06826, 0.05253,
  0.0429, 0.04164, 0.03959, 0.03571, 0.03174, 0.02774, 0.02609, 0.02549, 0.02407, 0.02355, 0.02308,
  0.02319, 0.02355, 0.026, 0.03024, 0.03291, 0.03556, 0.04032, 0.045, 0.05173, 0.06865, 0.07561,
  0.08361, 0.05877, 0.04347, 0.04196, 0.03698, 0.02877, 0.02943, 0.02517, 0.02273, 0.02222, 0.02116,
  0.02101, 0.02117, 0.02315, 0.02358, 0.02686, 0.02993, 0.03151, 0.03307, 0.03481, 0.03754, 0.03935,
  0.04033, 0.04113, 0.03903, 0.03461, 0.02804, 0.02799, 0.02664, 0.0224, 0.02211, 0.02232, 0.0206,
  0.01933, 0.01817, 0.01746, 0.0193, 0.02061, 0.02217, 0.0236, 0.02501, 0.0266, 0.02767, 0.02902,
  0.03035, 0.03054, 0.03128, 0.03198, 0.03155, 0.02933, 0.02857, 0.02778, 0.02559, 0.02256, 0.02134,
  0.02011, 0.01809, 0.01534, 0.01495, 0.01491, 0.01521, 0.01839, 0.01984, 0.02067, 0.0206, 0.02152,
  0.02253, 0.02418, 0.02476, 0.0253, 0.0257, 0.02756, 0.0285, 0.02721, 0.02569, 0.02491, 0.0237,
  0.02094, 0.01999, 0.01893, 0.01726, 0.01479, 0.01441, 0.01319, 0.01433, 0.01715, 0.01916, 0.02024,
  0.02148, 0.02209, 0.02399, 0.02442, 0.02638, 0.02856, 0.02992, 0.03216, 0.03233, 0.02998, 0.02753,
  0.02689, 0.02442, 0.02171, 0.02031, 0.02057, 0.01896, 0.01696, 0.0159, 0.01536, 0.01645, 0.01875,
  0.02107, 0.02179, 0.02218, 0.02428, 0.02626, 0.02763, 0.0301, 0.03327, 0.03456, 0.03803, 0.03751,
  0.03293, 0.03001, 0.0287, 0.02639, 0.02347, 0.02202, 0.02263, 0.02077, 0.0186, 0.01723, 0.01677,
  0.01652, 0.01825, 0.01914, 0.01967, 0.02224, 0.02366, 0.02581, 0.02736, 0.03021, 0.0319, 0.03397,
  0.03735, 0.03796, 0.03364, 0.03079, 0.02926, 0.02762, 0.02425, 0.02268, 0.02259, 0.02026, 0.0186,
  0.01791, 0.01648, 0.01533, 0.0161, 0.01747, 0.01981, 0.02156, 0.02332, 0.02625, 0.02806, 0.031,
  0.03313, 0.0364, 0.03869, 0.04055, 0.03712, 0.03345, 0.03272, 0.03013, 0.02636, 0.02359, 0.02348,
  0.02226, 0.02066, 0.01972, 0.01965, 0.0209, 0.02075, 0.02417, 0.0268, 0.02818, 0.03082, 0.0334,
  0.03663, 0.0404, 0.04473, 0.05169, 0.05664, 0.0572, 0.04281, 0.03803, 0.03577, 0.03332, 0.02884,
  0.027, 0.02485, 0.02312, 0.02067, 0.01957, 0.01962, 0.02087, 0.02165, 0.02473, 0.02737, 0.0291,
  0.02944, 0.03332, 0.03773, 0.04109, 0.0464, 0.05479, 0.0621, 0.06092, 0.04105, 0.03601, 0.03396,
  0.03035, 0.02676, 0.02561, 0.0247, 0.02259, 0.02116, 0.02, 0.01996, 0.02117, 0.02244, 0.0241,
  0.02692, 0.02972, 0.0309, 0.03369, 0.03573, 0.04019, 0.04401, 0.05252, 0.05432, 0.04877, 0.03736,
  0.03332, 0.03116, 0.02928, 0.0254, 0.02349, 0.02255, 0.02061, 0.01912, 0.01848, 0.01861, 0.0192,
  0.0207, 0.02177, 0.0239, 0.02571, 0.02752, 0.03021, 0.03223, 0.03405, 0.03653, 0.03843, 0.04227,
  0.04127, 0.0347, 0.03217, 0.03041, 0.02864, 0.0237, 0.02231, 0.02241, 0.02108, 0.01856, 0.0182,
  0.01821, 0.0186, 0.02039, 0.02158, 0.02461, 0.02588, 0.02734, 0.02975, 0.03188, 0.03484, 0.03684,
  0.0393, 0.04175, 0.0414, 0.0356, 0.03236, 0.03004, 0.02874, 0.02494, 0.02277, 0.02276, 0.02118,
  0.01951, 0.01871, 0.01836, 0.01896, 0.01908, 0.021, 0.02239, 0.02426, 0.02572, 0.02866, 0.0308,
  0.03289, 0.03408, 0.03611, 0.03905, 0.04022, 0.03484, 0.03284, 0.03091, 0.02868, 0.02537, 0.02433,
  0.02365, 0.0222, 0.02064, 0.01923, 0.01839, 0.01807, 0.01831, 0.0185, 0.02017, 0.02214, 0.02369,
  0.02659, 0.02871, 0.0307, 0.03264, 0.03521, 0.03816, 0.04043, 0.03526, 0.03167, 0.03035, 0.02681,
  0.02315, 0.02173, 0.02174, 0.0201, 0.01837, 0.01743, 0.0175, 0.01859, 0.02052, 0.02134, 0.02391,
  0.02502, 0.02683, 0.02952, 0.03126, 0.03337, 0.03662, 0.0396, 0.04309, 0.04391, 0.03762, 0.03397,
  0.03166, 0.02829, 0.02361, 0.02203, 0.02257, 0.0208, 0.01909, 0.01881, 0.01879, 0.02038, 0.02132,
  0.0228, 0.02546, 0.0271, 0.02922, 0.03183, 0.03428, 0.0378, 0.04111, 0.04442, 0.0477, 0.0477,
  0.0403, 0.03512, 0.03409, 0.0313, 0.02554, 0.02388, 0.02245, 0.0213, 0.01979, 0.01864, 0.01886,
  0.01998, 0.02114, 0.02382, 0.02531, 0.02815, 0.0282, 0.03392, 0.03532, 0.038, 0.0431, 0.05597,
  0.05525, 0.05323, 0.0409, 0.03384, 0.03307, 0.02975, 0.02447, 0.02337, 0.02297, 0.02093, 0.01919,
  0.01845, 0.01791, 0.01917, 0.02061, 0.02232, 0.0235, 0.02616, 0.02754, 0.02966, 0.03267, 0.03515,
  0.03772, 0.04101, 0.0441, 0.04309, 0.0354, 0.03226, 0.03093, 0.02723, 0.0229, 0.02286, 0.02058,
  0.01837, 0.0166, 0.01606, 0.0154, 0.01652, 0.01701, 0.01874, 0.02048, 0.02226, 0.02382, 0.02534,
  0.02627, 0.02938, 0.03215, 0.03352, 0.03603, 0.03554, 0.03213, 0.02937, 0.0278, 0.02447, 0.02061,
  0.02057, 0.01948, 0.01803, 0.01655, 0.01553, 0.01503, 0.01557, 0.01525, 0.01638, 0.01684, 0.01806,
  0.01993, 0.02042, 0.02045, 0.0212, 0.02266, 0.02422, 0.02565, 0.02783, 0.02535, 0.02346, 0.02336,
  0.02104, 0.01914, 0.0178, 0.01852, 0.01597, 0.01454, 0.01383, 0.01302, 0.01252, 0.01171, 0.01294,
  0.01414, 0.01505, 0.01581, 0.01727, 0.01876, 0.01957, 0.01988, 0.0216, 0.02455, 0.02722, 0.02607,
  0.02418, 0.0233, 0.02067, 0.01822, 0.01698, 0.01853, 0.0166, 0.01557, 0.01398, 0.01398, 0.01582,
  0.01718, 0.01746, 0.01928, 0.0207, 0.02313, 0.02485, 0.02719, 0.02985, 0.03261, 0.03521, 0.03979,
  0.03977, 0.03639, 0.0321, 0.0302, 0.02794, 0.02244, 0.02064, 0.02102, 0.0195, 0.01737, 0.01659,
  0.01636, 0.01805, 0.01976, 0.022, 0.02317, 0.02479, 0.02732, 0.03058, 0.03235, 0.03433, 0.0345,
  0.03737, 0.03958, 0.03829, 0.03247, 0.03138, 0.0294, 0.02752, 0.02175, 0.02056, 0.02108, 0.01951,
  0.01721, 0.01673, 0.01654, 0.01888, 0.01952, 0.02131, 0.02278, 0.02276, 0.02594, 0.02716, 0.02814,
  0.02952, 0.03007, 0.03015, 0.03176, 0.03201, 0.02916, 0.02649, 0.02521, 0.02226, 0.01941, 0.01873,
  0.02093, 0.01969, 0.01802, 0.01715, 0.01706, 0.01892, 0.01899, 0.01919, 0.01973, 0.02162, 0.02277,
  0.02469, 0.02656, 0.02794, 0.03042, 0.03093, 0.03429, 0.03417, 0.03038, 0.02834, 0.02692, 0.02409,
  0.01962, 0.01921, 0.01978, 0.01897, 0.01672, 0.01597, 0.0164, 0.01836, 0.01813, 0.01943, 0.01986,
  0.02079, 0.02237, 0.02455, 0.02632, 0.02796, 0.02983, 0.03132, 0.03307, 0.03316, 0.02871, 0.02666,
  0.02629, 0.02302, 0.02041, 0.02015, 0.0217, 0.01953, 0.01857, 0.01757, 0.01752, 0.01703, 0.01637,
  0.01882, 0.02049, 0.02181, 0.02373, 0.02685, 0.02858, 0.02999, 0.03147, 0.03393, 0.03595, 0.03634,
  0.03289, 0.02981, 0.02885, 0.02641, 0.02289, 0.02112, 0.02308, 0.02142, 0.02008, 0.01926, 0.01865,
  0.01866, 0.01664, 0.01659, 0.02036, 0.02287, 0.0268, 0.02945, 0.03227, 0.03408, 0.03879, 0.04107,
  0.04549, 0.04708, 0.0413, 0.03733, 0.03614, 0.03277, 0.02752, 0.02385, 0.02408, 0.02174, 0.01991,
  0.01892, 0.01864, 0.02041, 0.02115, 0.0245, 0.0263, 0.0294, 0.03216, 0.03407, 0.03914, 0.04505,
  0.04761, 0.05427, 0.06123, 0.05932, 0.04562, 0.03935, 0.03755, 0.03385, 0.02999, 0.02665, 0.02546,
  0.02324, 0.02247, 0.02146, 0.02131, 0.0227, 0.02323, 0.02682, 0.02895, 0.02969, 0.03215, 0.03418,
  0.03878, 0.04469, 0.04726, 0.05182, 0.0573, 0.05362, 0.04477, 0.03876, 0.03639, 0.03244, 0.02787,
  0.0255, 0.02298, 0.01967, 0.01892, 0.0179, 0.01779, 0.01944, 0.02121, 0.02317, 0.02393, 0.02659,
  0.02738, 0.02944, 0.02976, 0.03373, 0.03983, 0.04934, 0.05763, 0.05363, 0.03893, 0.03439, 0.03168,
  0.02857, 0.02454, 0.02264, 0.0228, 0.02098, 0.01914, 0.01841, 0.01877, 0.02045, 0.02338, 0.02474,
  0.024, 0.02709, 0.02809, 0.03162, 0.03347, 0.03481, 0.03687, 0.04232, 0.05624, 0.05143, 0.03979,
  0.03537, 0.03293, 0.02883, 0.02423, 0.02169, 0.02154, 0.01974, 0.01793, 0.01715, 0.01695, 0.01856,
  0.01951, 0.02014, 0.02105, 0.0223, 0.02323, 0.02456, 0.02582, 0.02657, 0.02729, 0.0275, 0.02842,
  0.02836, 0.02618, 0.02461, 0.02389, 0.02109, 0.01876, 0.01803, 0.01817, 0.01645, 0.01593, 0.0148,
  0.01402, 0.01404, 0.01379, 0.01484, 0.01612, 0.01755, 0.018, 0.01928, 0.02024, 0.02078, 0.02095,
  0.02195, 0.02303, 0.02431, 0.02295, 0.02208, 0.0217, 0.0199, 0.01737, 0.01614, 0.01622, 0.01493,
  0.01409, 0.01359, 0.01278, 0.01347, 0.01295, 0.01345, 0.01476, 0.01524, 0.01539, 0.01619, 0.01721,
  0.01781, 0.01802, 0.01933, 0.02128, 0.02253, 0.02133, 0.02065, 0.02075, 0.01839, 0.01632, 0.01526,
  0.01626, 0.01451, 0.01329, 0.01274, 0.01273, 0.01365, 0.01497, 0.0163, 0.01747, 0.01868, 0.01885,
  0.01972, 0.02034, 0.02113, 0.02284, 0.023, 0.02489, 0.02554, 0.02419, 0.02311, 0.02435, 0.01882,
  0.01707, 0.01599, 0.01621, 0.01366, 0.01302, 0.01271, 0.01277, 0.01342, 0.01637, 0.01689, 0.01706,
  0.01938, 0.01951, 0.02171, 0.0224, 0.02423, 0.02473, 0.02538, 0.02668, 0.02739, 0.02482, 0.02453,
  0.02446, 0.02044, 0.01791, 0.01628, 0.01724, 0.01444, 0.01339, 0.01316, 0.01302, 0.01535, 0.01745,
  0.01795, 0.01916, 0.02065, 0.02181, 0.02346, 0.02475, 0.0257, 0.02617, 0.02734, 0.03076, 0.03111,
  0.02674, 0.02565, 0.02583, 0.02222, 0.01851, 0.01741, 0.01813, 0.01625, 0.01542, 0.01532, 0.01467,
  0.01623, 0.01794, 0.0186, 0.01927, 0.02024, 0.0212, 0.02276, 0.0238, 0.02462, 0.0258, 0.0279,
  0.03019, 0.03006, 0.02588, 0.02415, 0.02483, 0.02199, 0.01792, 0.018, 0.01928, 0.01697, 0.01465,
  0.01459, 0.01493, 0.01634, 0.01706, 0.01794, 0.01859, 0.01982, 0.02123, 0.02308, 0.02472, 0.02607,
  0.0282, 0.03033, 0.0336, 0.03413, 0.0285, 0.0268, 0.02573, 0.02324, 0.01899, 0.01936, 0.01871,
  0.01706, 0.0153, 0.01479, 0.01417, 0.01436, 0.01424, 0.01586, 0.01685, 0.01755, 0.01842, 0.01874,
  0.01876, 0.02005, 0.02063, 0.02201, 0.02419, 0.02473, 0.02366, 0.02221, 0.02218, 0.01995, 0.01797,
  0.01709, 0.0182, 0.01596, 0.0152, 0.0138, 0.01318, 0.01287, 0.01253, 0.01246, 0.01371, 0.01486,
  0.01563, 0.0164, 0.0173, 0.01722, 0.01711, 0.01813, 0.02019, 0.02311, 0.02222, 0.02209, 0.02169,
  0.01855, 0.01619, 0.01697, 0.01767, 0.01579, 0.01485, 0.01404, 0.01383, 0.01379, 0.0136, 0.01363,
  0.01577, 0.01735, 0.01797, 0.01916, 0.01956, 0.02066, 0.02178, 0.02361, 0.02436, 0.02687, 0.02488,
  0.02544, 0.02531, 0.02224, 0.01901, 0.01868, 0.0185, 0.01695, 0.01542, 0.01508, 0.01425, 0.0162,
  0.01843, 0.019, 0.01917, 0.02101, 0.02263, 0.02471, 0.02637, 0.02786, 0.02918, 0.03043, 0.03443,
  0.03512, 0.02862, 0.02765, 0.02761, 0.02298, 0.01845, 0.01853, 0.0173, 0.01604, 0.01428, 0.01314,
  0.01311, 0.01794, 0.01972, 0.02122, 0.02121, 0.02215, 0.02227, 0.0235, 0.02486, 0.02726, 0.02993,
  0.03176, 0.03494, 0.03906, 0.0253, 0.02579, 0.02483, 0.02241, 0.0186, 0.01884, 0.01905, 0.01559,
  0.01465, 0.0132, 0.01303, 0.01379, 0.01774, 0.01871, 0.01788, 0.018, 0.01845, 0.01852, 0.0192,
  0.02021, 0.0218, 0.0218, 0.02372, 0.02387, 0.02195, 0.0221, 0.02215, 0.01918, 0.0174, 0.01757,
  0.01763, 0.01549, 0.01442, 0.0139, 0.01455, 0.01581, 0.01791, 0.0179, 0.01764, 0.01827, 0.01835,
  0.01909, 0.01917, 0.01886, 0.0193, 0.02033, 0.02206, 0.02167, 0.02083, 0.02018, 0.02062, 0.01811,
  0.0174, 0.01794, 0.01797, 0.01551, 0.0146, 0.0144, 0.01395, 0.01467, 0.01441, 0.01462, 0.01458,
  0.01618, 0.01756, 0.01768, 0.01776, 0.01724, 0.01764, 0.01823, 0.0194, 0.02112, 0.02059, 0.02118,
  0.021, 0.01771, 0.01564, 0.01675, 0.01718, 0.01485, 0.01366, 0.01356, 0.01285, 0.01294, 0.01319,
  0.0131, 0.01349, 0.01429, 0.01567, 0.01614, 0.01681, 0.01685, 0.01616, 0.01795, 0.01836, 0.0205,
  0.0217, 0.02226, 0.02196, 0.01794, 0.01509, 0.01398, 0.01553, 0.01365, 0.01163, 0.01136, 0.01157,
  0.014, 0.01736, 0.01802, 0.01794, 0.01881, 0.01826, 0.01885, 0.0194, 0.02086, 0.02148, 0.02237,
  0.02379, 0.0255, 0.0236, 0.0245, 0.0225, 0.02027, 0.01633, 0.01625, 0.01657, 0.01475, 0.01349,
  0.01292, 0.01346, 0.0148, 0.01856, 0.01902, 0.01917, 0.01877, 0.01888, 0.01978, 0.02024, 0.0207,
  0.02239, 0.02315, 0.02584, 0.02633, 0.0251, 0.02576, 0.02354, 0.0207, 0.01681, 0.01643, 0.01919,
  0.01661, 0.01542, 0.01492, 0.01475, 0.0213, 0.02271, 0.02571, 0.02484, 0.0263, 0.0279, 0.02855,
  0.02931, 0.0319, 0.034, 0.04026, 0.04423, 0.04473, 0.03835, 0.03638, 0.03366, 0.03091, 0.02731,
  0.025, 0.02453, 0.0234, 0.02105, 0.02024, 0.02045, 0.02296, 0.02535, 0.02638, 0.02627, 0.02737,
  0.02805, 0.0281, 0.02808, 0.02955, 0.03002, 0.03172, 0.03389, 0.03401, 0.03154, 0.0307, 0.02848,
  0.02548, 0.02185, 0.02218, 0.02038, 0.0181, 0.01646, 0.01686, 0.01678, 0.01755, 0.01978, 0.02108,
  0.02204, 0.02232, 0.02325, 0.02356, 0.0232, 0.02342, 0.02371, 0.02482, 0.02587, 0.02573, 0.02381,
  0.02508, 0.02377, 0.0208, 0.01935, 0.01865, 0.01955, 0.01808, 0.01633, 0.01547, 0.01627, 0.01679,
  0.0177, 0.01832, 0.01958, 0.02034, 0.02094, 0.02187, 0.0219, 0.02228, 0.02192, 0.02366, 0.02485,
  0.02697, 0.0263, 0.02696, 0.02584, 0.023, 0.02135, 0.01974, 0.0198, 0.01732, 0.01645, 0.01586,
  0.01527, 0.01576, 0.01636, 0.01544, 0.01729, 0.01857, 0.01868, 0.01916, 0.01963, 0.02023, 0.02059,
  0.02268, 0.02501, 0.0276, 0.02759, 0.02752, 0.02674, 0.02288, 0.01887, 0.01838, 0.01825, 0.01607,
  0.01513, 0.01459, 0.01476, 0.01749, 0.01993, 0.02073, 0.02136, 0.02176, 0.02278, 0.02314, 0.02335,
  0.02456, 0.02463, 0.02682, 0.03013, 0.03286, 0.03025, 0.03027, 0.02903, 0.02274, 0.0192, 0.01991,
  0.01717, 0.01533, 0.01455, 0.01384, 0.0142, 0.01557, 0.01948, 0.02087, 0.0202, 0.0198, 0.0202,
  0.02037, 0.02052, 0.02184, 0.02239, 0.02326, 0.02593, 0.02827, 0.02683, 0.02577, 0.02522, 0.02072,
  0.01911, 0.0183, 0.01771, 0.01508, 0.01391, 0.01363, 0.01366, 0.01588, 0.01941, 0.0198, 0.01999,
  0.01999, 0.01988, 0.02049, 0.02047, 0.02124, 0.02152, 0.02174, 0.02251, 0.02322, 0.02279, 0.02378,
  0.02158, 0.0208, 0.01897, 0.019, 0.01894, 0.01667, 0.01583, 0.0153, 0.01557, 0.01675, 0.02008,
  0.02034, 0.02096, 0.02022, 0.02049, 0.02118, 0.02085, 0.02139, 0.02257, 0.02277, 0.02423, 0.02558,
  0.02595, 0.02696, 0.02391, 0.02167, 0.01979, 0.01925, 0.01679, 0.01475, 0.01453, 0.01417, 0.01408,
  0.01549, 0.01894, 0.02073, 0.02013, 0.01975, 0.02009, 0.02132, 0.02075, 0.02199, 0.02298, 0.02382,
  0.02628, 0.02794, 0.02625, 0.02566, 0.02465, 0.02058, 0.01951, 0.01906, 0.01828, 0.01695, 0.01619,
  0.01553, 0.01515, 0.01573, 0.01593, 0.01782, 0.01821, 0.01894, 0.01932, 0.02027, 0.02109, 0.02218,
  0.02348, 0.02573, 0.02839, 0.03032, 0.02918, 0.02902, 0.02683, 0.02233, 0.02052, 0.02005, 0.01796,
  0.01644, 0.01484, 0.01437, 0.01382, 0.01422, 0.01479, 0.01559, 0.01607, 0.0179, 0.01897, 0.02014,
  0.02185, 0.02378, 0.02555, 0.02757, 0.03031, 0.03255, 0.03143, 0.03065, 0.02841, 0.02394, 0.02206,
  0.01957, 0.01834, 0.01663, 0.01545, 0.01535, 0.01519, 0.01665, 0.02013, 0.02049, 0.02053, 0.02112,
  0.02298, 0.02386, 0.02509, 0.02759, 0.0298, 0.03582, 0.04081, 0.03951, 0.03243, 0.02901, 0.02641,
  0.02196, 0.01894, 0.0192, 0.02023, 0.01741, 0.0164, 0.01596, 0.01603, 0.01707, 0.02077, 0.0215,
  0.02013, 0.01975, 0.0206, 0.02079, 0.02121, 0.0218, 0.02164, 0.02399, 0.02506, 0.02547, 0.02382,
  0.02422, 0.02293, 0.01915, 0.01751, 0.01713, 0.01743, 0.01518, 0.01323, 0.01255, 0.01263, 0.01511,
  0.01811, 0.01849, 0.01862, 0.0183, 0.0191, 0.01963, 0.01971, 0.02047, 0.02094, 0.02204, 0.02419,
  0.02555, 0.02443, 0.02553, 0.02216, 0.0192, 0.01781, 0.01765, 0.01796, 0.01627, 0.01396, 0.01308,
  0.01367, 0.01509, 0.01889, 0.01963, 0.01908, 0.01903, 0.01993, 0.02026, 0.02095, 0.02217, 0.02607,
  0.02919, 0.03234, 0.03325, 0.02991, 0.02808, 0.02576, 0.0213, 0.01825, 0.01809, 0.01622, 0.01405,
  0.01243, 0.01118, 0.01137, 0.0129, 0.01795, 0.01858, 0.01863, 0.01757, 0.01827, 0.01834, 0.01903,
  0.01914, 0.01882, 0.0206, 0.02206, 0.02383, 0.02322, 0.02378, 0.01989, 0.01782, 0.01608, 0.01622,
  0.01447, 0.01285, 0.01181, 0.01161, 0.01137, 0.01173, 0.01221, 0.01516, 0.01542, 0.01625, 0.01615,
  0.01674, 0.01756, 0.01863, 0.01888, 0.02094, 0.02268, 0.02507, 0.02466, 0.02588, 0.02321, 0.0201,
  0.0185, 0.0177, 0.01652, 0.0152, 0.01381, 0.01333, 0.0127, 0.01291, 0.01363, 0.01418, 0.0151,
  0.01526, 0.0155, 0.01489, 0.01534, 0.01547, 0.01629, 0.01713, 0.01821, 0.02113, 0.02222, 0.02339,
  0.01871, 0.01724, 0.01601, 0.01452, 0.01565, 0.01428, 0.01371, 0.01338, 0.01357, 0.01415, 0.01531,
  0.01666, 0.01685, 0.01647, 0.01665, 0.0166, 0.01639, 0.01638, 0.01661, 0.01822, 0.02012, 0.0201,
  0.02199, 0.0234, 0.02162, 0.01823, 0.01528, 0.0141, 0.01488, 0.01301, 0.01163, 0.01126, 0.01069,
  0.01208, 0.01633, 0.01746, 0.0175, 0.01789, 0.01899, 0.01934, 0.02069, 0.02364, 0.02424, 0.02739,
  0.02943, 0.03098, 0.02928, 0.02911, 0.02789, 0.02339, 0.01871, 0.01707, 0.01919, 0.01698, 0.01573,
  0.01538, 0.0156, 0.01792, 0.02096, 0.02116, 0.02232, 0.02298, 0.02447, 0.02489, 0.02618, 0.02683,
  0.02901, 0.03266, 0.03405, 0.0348, 0.03551, 0.03356, 0.02849, 0.02182, 0.01802, 0.01771, 0.01928,
  0.0159, 0.01473, 0.01239, 0.01369, 0.01575, 0.02018, 0.02191, 0.02176, 0.02066, 0.02152, 0.02027,
  0.02011, 0.02074, 0.02192, 0.02388, 0.02733, 0.0299, 0.02817, 0.028, 0.0248, 0.02008, 0.01663,
  0.01655, 0.01729, 0.01408, 0.01155, 0.01149, 0.01132, 0.01339, 0.01756, 0.01791, 0.01833, 0.01692,
  0.01748, 0.01703, 0.01768, 0.01765, 0.01703, 0.01731, 0.01886, 0.02009, 0.02152, 0.02148, 0.01914,
  0.01779, 0.0163, 0.01561, 0.01267, 0.01136, 0.01069, 0.00962, 0.00861, 0.00963, 0.01266, 0.01478,
  0.01509, 0.01507, 0.01528, 0.01478, 0.01447, 0.01459, 0.01419, 0.01408, 0.01517, 0.01634, 0.01957,
  0.0206, 0.01768, 0.01563, 0.01396, 0.01305, 0.01295, 0.00997, 0.00976, 0.00849, 0.00869, 0.00786,
  0.00944, 0.01013, 0.01167, 0.01226, 0.01223, 0.01256, 0.01298, 0.01322, 0.01322, 0.01364, 0.01435,
  0.01566, 0.01763, 0.01962, 0.01609, 0.01466, 0.01252, 0.01162, 0.01324, 0.01146, 0.01099, 0.01087,
  0.01139, 0.01271, 0.01797, 0.01791, 0.01825, 0.01888, 0.01897, 0.01951, 0.01948, 0.01957, 0.01825,
  0.01921, 0.02039, 0.02181, 0.02283, 0.02252, 0.01945, 0.0168, 0.01425, 0.01265, 0.01432, 0.01244,
  0.01089, 0.01038, 0.01084, 0.01317, 0.01764, 0.01931, 0.01836, 0.01875, 0.01871, 0.01841, 0.01767,
  0.01792, 0.01794, 0.01895, 0.01994, 0.02263, 0.02396, 0.02365, 0.02061, 0.01746, 0.0149, 0.01415,
  0.01525, 0.0136, 0.0128, 0.01207, 0.01207, 0.0166, 0.01944, 0.02171, 0.02238, 0.02255, 0.02017,
  0.02027, 0.01957, 0.01967, 0.0196, 0.02092, 0.02207, 0.02488, 0.02609, 0.02554, 0.02285, 0.01837,
  0.017, 0.01535, 0.01533, 0.01379, 0.0129, 0.01251, 0.01297, 0.01668, 0.02033, 0.02359, 0.02235,
  0.02184, 0.02035, 0.02036, 0.01997, 0.02034, 0.01978, 0.02103, 0.02213, 0.0249, 0.0274, 0.02765,
  0.0225, 0.02003, 0.0175, 0.01609, 0.01462, 0.01271, 0.01234, 0.01138, 0.01195, 0.01353, 0.01847,
  0.02093, 0.02138, 0.02146, 0.02087, 0.02068, 0.0201, 0.01987, 0.01928, 0.01999, 0.02086, 0.02414,
  0.02204, 0.02277, 0.02005, 0.01781, 0.01582, 0.01485, 0.01374, 0.01447, 0.01264, 0.01202, 0.01182,
  0.01317, 0.01481, 0.0165, 0.01723, 0.01768, 0.01692, 0.01701, 0.01684, 0.01655, 0.01665, 0.01697,
  0.01788, 0.01971, 0.02122, 0.02199, 0.01932, 0.01745, 0.01579, 0.01561, 0.0177, 0.01651, 0.01602,
  0.01533, 0.01482, 0.01558, 0.01559, 0.01696, 0.0177, 0.01795, 0.0178, 0.01721, 0.01573, 0.01539,
  0.01614, 0.01633, 0.01816, 0.02061, 0.02415, 0.02524, 0.02285, 0.01838, 0.0163, 0.0154, 0.01638,
  0.01546, 0.01511, 0.01439, 0.01448, 0.01541, 0.01811, 0.02153, 0.02264, 0.02256, 0.02161, 0.02076,
  0.02021, 0.02023, 0.01967, 0.02021, 0.02137, 0.02502, 0.02768, 0.02596, 0.02356, 0.02045, 0.017,
  0.01651, 0.01634, 0.01416, 0.01399, 0.01351, 0.01408, 0.01598, 0.02111, 0.02166, 0.02291, 0.02216,
  0.02136, 0.01951, 0.01826, 0.01805, 0.01716, 0.01829, 0.0197, 0.02343, 0.02759, 0.02843, 0.02255,
  0.01937, 0.01634, 0.01561, 0.01651, 0.01511, 0.01394, 0.01333, 0.01312, 0.01502, 0.02035, 0.02261,
  0.0226, 0.02093, 0.02035, 0.02012, 0.02047, 0.02058, 0.02049, 0.021, 0.02187, 0.02492, 0.02895,
  0.0296, 0.02399, 0.02043, 0.017, 0.01704, 0.01771, 0.0162, 0.01466, 0.01443, 0.01466, 0.0169,
  0.02108, 0.02318, 0.02283, 0.02228, 0.02213, 0.02187, 0.02185, 0.02147, 0.02189, 0.02219, 0.024,
  0.02634, 0.02843, 0.02879, 0.02408, 0.02103, 0.01785, 0.01743, 0.01852, 0.0171, 0.01585, 0.01564,
  0.01595, 0.01828, 0.02285, 0.0244, 0.02224, 0.02137, 0.02124, 0.0206, 0.01958, 0.02005, 0.02028,
  0.02034, 0.02114, 0.02527, 0.02748, 0.02795, 0.02548, 0.02234, 0.01928, 0.0195, 0.01968, 0.01857,
  0.01717, 0.01636, 0.01666, 0.01705, 0.01832, 0.02017, 0.02202, 0.021, 0.01948, 0.01929, 0.01777,
  0.01702, 0.0161, 0.01736, 0.01919, 0.02264, 0.02605, 0.02615, 0.0232, 0.02121, 0.01831, 0.01732,
  0.02029, 0.01873, 0.01827, 0.01733, 0.01761, 0.01773, 0.01909, 0.01959, 0.02055, 0.02046, 0.02052,
  0.02038, 0.02, 0.01981, 0.01982, 0.02014, 0.0218, 0.0254, 0.03053, 0.03092, 0.02606, 0.02254,
  0.01936, 0.01756, 0.01861, 0.01796, 0.01709, 0.01626, 0.01693, 0.01812, 0.0233, 0.02628, 0.02608,
  0.0246, 0.02348, 0.02313, 0.02198, 0.02214, 0.02232, 0.02259, 0.02416, 0.02792, 0.03129, 0.03039,
  0.0257, 0.0215, 0.01802, 0.01719, 0.01657, 0.01549, 0.01528, 0.01483, 0.01465, 0.01664, 0.02094,
  0.02525, 0.02528, 0.02582, 0.0248, 0.02464, 0.02344, 0.02339, 0.02309, 0.02379, 0.02518, 0.03037,
  0.03309, 0.03173, 0.02465, 0.02143, 0.01737, 0.01697, 0.01446, 0.01475, 0.0139, 0.01297, 0.01296,
  0.01386, 0.01843, 0.02294, 0.02076, 0.02001, 0.0188, 0.01692, 0.01586, 0.01525, 0.01397, 0.01497,
  0.01847, 0.02426, 0.03036, 0.02829, 0.02257, 0.01946, 0.0167, 0.01707, 0.01466, 0.01387, 0.01315,
  0.01249, 0.01324, 0.01577, 0.02008, 0.02311, 0.02168, 0.02125, 0.01944, 0.0196, 0.01895, 0.01912,
  0.01944, 0.02004, 0.0209, 0.02525, 0.03023, 0.028, 0.02413, 0.01988, 0.01881, 0.01768, 0.01658,
  0.01504, 0.01401, 0.01392, 0.01444, 0.01674, 0.02203, 0.02365, 0.0234, 0.02183, 0.02065, 0.01996,
  0.0189, 0.01879, 0.01872, 0.01877, 0.02055, 0.0254, 0.02959, 0.02467, 0.02259, 0.02001, 0.01792,
  0.01627, 0.01603, 0.01397, 0.01329, 0.01237, 0.01238, 0.01312, 0.01585, 0.01658, 0.01815, 0.01848,
  0.01732, 0.0171, 0.01684, 0.01544, 0.01424, 0.01449, 0.01661, 0.01996, 0.02327, 0.02197, 0.01884,
  0.01733, 0.016, 0.01608, 0.01484, 0.01314, 0.01259, 0.01207, 0.01188, 0.01177, 0.01495, 0.01592,
  0.01585, 0.0177, 0.0179, 0.0185, 0.01809, 0.01774, 0.01671, 0.01685, 0.01901, 0.02161, 0.0259,
  0.02352, 0.02045, 0.01814, 0.0158, 0.01448, 0.01409, 0.01136, 0.00983, 0.00924, 0.01003, 0.0113,
  0.0186, 0.01993, 0.01975, 0.0198, 0.0208, 0.01987, 0.01862, 0.01825, 0.01741, 0.01833, 0.01923,
  0.02414, 0.02871, 0.0282, 0.02219, 0.01884, 0.01667, 0.0154, 0.01463, 0.01232, 0.01229, 0.01226,
  0.01279, 0.01229, 0.01888, 0.02129, 0.02253, 0.022, 0.02175, 0.02145, 0.01997, 0.01939, 0.01912,
  0.01931, 0.02084, 0.0252, 0.02819, 0.02522, 0.02204, 0.01862, 0.01596, 0.0155, 0.0166, 0.01548,
  0.01488, 0.01456, 0.01453, 0.01517, 0.02257, 0.02321, 0.02258, 0.02241, 0.02321, 0.02298, 0.02137,
  0.02145, 0.02139, 0.02199, 0.02461, 0.0279, 0.03038, 0.02614, 0.02203, 0.02002, 0.01797, 0.01713,
  0.01575, 0.01398, 0.0137, 0.01279, 0.01388, 0.0147, 0.0218, 0.02203, 0.02134, 0.02163, 0.02202,
  0.0217, 0.02249, 0.02222, 0.02201, 0.0219, 0.0232, 0.02615, 0.02724, 0.02513, 0.02178, 0.02019,
  0.0181, 0.01803, 0.01649, 0.01455, 0.01393, 0.01252, 0.01297, 0.01504, 0.02, 0.02367, 0.02173,
  0.02189, 0.02112, 0.02134, 0.02018, 0.01961, 0.01962, 0.01968, 0.02126, 0.02419, 0.02531, 0.0248,
  0.0221, 0.02066, 0.01878, 0.01857, 0.01734, 0.01553, 0.0146, 0.01434, 0.01433, 0.01499, 0.01714,
  0.01944, 0.0187, 0.01877, 0.01783, 0.01702, 0.01545, 0.01444, 0.01367, 0.01344, 0.01528, 0.01869,
  0.02395, 0.02489, 0.02116, 0.01978, 0.01827, 0.01804, 0.01894, 0.01737, 0.01727, 0.01659, 0.01617,
  0.01657, 0.01789, 0.01845, 0.02023, 0.0203, 0.02084, 0.01893, 0.01857, 0.01782, 0.01757, 0.0175,
  0.01782, 0.02281, 0.029, 0.02932, 0.02731, 0.02485, 0.02176, 0.02013, 0.0192, 0.01811, 0.01663,
  0.01577, 0.01669, 0.01661, 0.01915, 0.02477, 0.02602, 0.02586, 0.02363, 0.02268, 0.02089, 0.01989,
  0.0197, 0.01986, 0.02068, 0.02515, 0.0306, 0.03019, 0.02604, 0.02396, 0.0204, 0.01766, 0.01964,
  0.01847, 0.01735, 0.01655, 0.01644, 0.01722, 0.01819, 0.02683, 0.02625, 0.02366, 0.02365, 0.02288,
  0.02103, 0.02056, 0.02138, 0.02051, 0.02119, 0.02417, 0.032, 0.03381, 0.02833, 0.02501, 0.02214,
  0.01918, 0.01879, 0.01719, 0.0161, 0.01483, 0.01456, 0.01573, 0.01857, 0.02518, 0.02733, 0.02561,
  0.02398, 0.02294, 0.02223, 0.02147, 0.02172, 0.02164, 0.02388, 0.02751, 0.03484, 0.03411, 0.03011,
  0.02626, 0.02316, 0.02011, 0.01879, 0.01894, 0.01742, 0.01701, 0.01623, 0.01746, 0.02102, 0.02706,
  0.02961, 0.02792, 0.02714, 0.02591, 0.0257, 0.0251, 0.02519, 0.02493, 0.02656, 0.03035, 0.03831,
  0.03325, 0.03052, 0.02733, 0.02478, 0.02205, 0.0217, 0.02627, 0.02324, 0.02288, 0.02241, 0.02298,
  0.02842, 0.03541, 0.03637, 0.03545, 0.03548, 0.03586, 0.03555, 0.03546, 0.03543, 0.03429, 0.0359,
  0.03865, 0.04516, 0.04305, 0.04034, 0.0375, 0.03577, 0.0337, 0.0315, 0.02969, 0.02785, 0.02641,
  0.02605, 0.02646, 0.02899, 0.03174, 0.0326, 0.03209, 0.0307, 0.03048, 0.02891, 0.02815, 0.02767,
  0.0271, 0.0295, 0.03407, 0.04221, 0.04003, 0.03681, 0.035, 0.03241, 0.02944, 0.0275, 0.02616,
  0.02438, 0.02287, 0.02273, 0.02282, 0.02395, 0.02378, 0.02492, 0.02675, 0.02719, 0.02401, 0.02319,
  0.02287, 0.02172, 0.02234, 0.02356, 0.03075, 0.03878, 0.03676, 0.03192, 0.02838, 0.02565, 0.02194,
  0.02141, 0.02457, 0.02371, 0.02218, 0.02219, 0.02242, 0.02362, 0.02681, 0.03055, 0.03097, 0.02936,
  0.02753, 0.02732, 0.02605, 0.02604, 0.02666, 0.02823, 0.0333, 0.03631, 0.03391, 0.03134, 0.02892,
  0.02546, 0.02173, 0.02208, 0.02415, 0.02337, 0.02186, 0.02166, 0.02203, 0.02475, 0.02943, 0.0303,
  0.03112, 0.03068, 0.03234, 0.03336, 0.03224, 0.03219, 0.03202, 0.03328, 0.03935, 0.04707, 0.04605,
  0.04144, 0.03694, 0.03196, 0.02956, 0.02946, 0.04046, 0.03715, 0.03609, 0.03589, 0.03833, 0.04365,
  0.05322, 0.05545, 0.05201, 0.04861, 0.04927, 0.04902, 0.04777, 0.04728, 0.04763, 0.04804, 0.05844,
  0.07316, 0.06453, 0.05975, 0.0576, 0.04877, 0.04242, 0.03896, 0.04313, 0.04149, 0.03932, 0.04018,
  0.04247, 0.04417, 0.05301, 0.05598, 0.04777, 0.04142, 0.03822, 0.03657, 0.03499, 0.03381, 0.0342,
  0.03569, 0.04058, 0.05589, 0.05313, 0.04732, 0.0445, 0.03746, 0.03257, 0.03207, 0.02889, 0.02779,
  0.02735, 0.02725, 0.02764, 0.0299, 0.03761, 0.03961, 0.03608, 0.03363, 0.03024, 0.02937, 0.02765,
  0.02714, 0.02727, 0.02964, 0.03308, 0.04252, 0.03831, 0.03504, 0.03368, 0.03006, 0.0264, 0.02531,
  0.02702, 0.02494, 0.02439, 0.02409, 0.02388, 0.02526, 0.02659, 0.02941, 0.03165, 0.03107, 0.02924,
  0.02784, 0.02645, 0.02607, 0.02602, 0.02694, 0.0312, 0.03877, 0.03775, 0.03483, 0.03309, 0.0303,
  0.02727, 0.0254, 0.02714, 0.02573, 0.02461, 0.0246, 0.02455, 0.02495, 0.02667, 0.02573, 0.02804,
  0.02783, 0.02668, 0.02535, 0.02491, 0.0248, 0.02458, 0.02489, 0.03007, 0.03696, 0.03607, 0.03411,
  0.03129, 0.02729, 0.02567, 0.02452, 0.02436, 0.02351, 0.02352, 0.02218, 0.02428, 0.02434, 0.02801,
  0.03126, 0.03354, 0.03214, 0.03114, 0.03109, 0.02927, 0.02873, 0.02775, 0.0279, 0.0316, 0.03785,
  0.0365, 0.03254, 0.03025, 0.02482, 0.0228, 0.02379, 0.02459, 0.02408, 0.02151, 0.02143, 0.02326,
  0.02303, 0.0276, 0.0313, 0.03256, 0.03148, 0.0303, 0.02899, 0.02743, 0.02674, 0.02671, 0.02713,
  0.03066, 0.03768, 0.03497, 0.03198, 0.03113, 0.02749, 0.02479, 0.02281, 0.02376, 0.02253, 0.02006,
  0.02028, 0.02189, 0.02319, 0.02691, 0.03238, 0.03101, 0.03098, 0.03006, 0.02861, 0.02747, 0.0272,
  0.02661, 0.02693, 0.03062, 0.03858, 0.03483, 0.03192, 0.02775, 0.02601, 0.0238, 0.02167, 0.02233,
  0.02221, 0.02146, 0.02025, 0.02107, 0.02063, 0.02665, 0.02802, 0.02719, 0.02715, 0.0258, 0.02513,
  0.02381, 0.02328, 0.02204, 0.02396, 0.02764, 0.03282, 0.0288, 0.02579, 0.02498, 0.0223, 0.02114,
  0.02101, 0.02218, 0.02191, 0.01932, 0.01966, 0.0205, 0.02168, 0.02449, 0.02766, 0.02516, 0.02584,
  0.02503, 0.02377, 0.02303, 0.02222, 0.02234, 0.02302, 0.02514, 0.0292, 0.02971, 0.02636, 0.02534,
  0.02421, 0.02323, 0.0218, 0.02414, 0.02188, 0.02159, 0.0204, 0.02037, 0.01667, 0.02189, 0.0228,
  0.02408, 0.02447, 0.02281, 0.02155, 0.02102, 0.02115, 0.02104, 0.02135, 0.02434, 0.02815, 0.02734,
  0.02549, 0.02455, 0.02204, 0.02043, 0.02141, 0.02192, 0.02141, 0.01978, 0.01967, 0.0196, 0.0173,
  0.02039, 0.01981, 0.02039, 0.02047, 0.02309, 0.02282, 0.02212, 0.02089, 0.02071, 0.02069, 0.02327,
  0.02835, 0.02739, 0.02497, 0.02358, 0.02153, 0.01953, 0.0216, 0.01713, 0.01837, 0.01647, 0.01653,
  0.01716, 0.01727, 0.02223, 0.02518, 0.02468, 0.02437, 0.0233, 0.02231, 0.02194, 0.02161, 0.02161,
  0.0222, 0.02607, 0.03048, 0.02896, 0.02639, 0.02408, 0.02238, 0.01775, 0.01981, 0.0215, 0.01816,
  0.01743, 0.01687, 0.0182, 0.01953, 0.02336, 0.02528, 0.02436, 0.02433, 0.02385, 0.02301, 0.02151,
  0.02236, 0.0222, 0.02321, 0.02661, 0.03093, 0.02926, 0.02748, 0.0255, 0.02327, 0.02127, 0.01961,
  0.02044, 0.01715, 0.01197, 0.01092, 0.01641, 0.01638, 0.02024, 0.02158, 0.02346, 0.02345, 0.02257,
  0.02234, 0.02201, 0.02191, 0.02172, 0.02196, 0.02369, 0.02816, 0.02872, 0.02656, 0.02474, 0.02158,
  0.01953, 0.02007, 0.02112, 0.01879, 0.01791, 0.01765, 0.01783, 0.01847, 0.02045, 0.02143, 0.0222,
  0.02376, 0.02503, 0.02503, 0.02373, 0.02288, 0.02251, 0.02248, 0.02439, 0.02645, 0.02499, 0.02415,
  0.02396, 0.02311, 0.02097, 0.01936, 0.02126, 0.01984, 0.01832, 0.01824, 0.01968, 0.02115, 0.02263,
  0.02401, 0.0232, 0.02316, 0.02315, 0.02289, 0.02125, 0.02126, 0.02183, 0.02274, 0.02576, 0.0285,
  0.02755, 0.02653, 0.02555, 0.02446, 0.02213, 0.02142, 0.02264, 0.02118, 0.01987, 0.01934, 0.02031,
  0.02023, 0.02298, 0.0246, 0.02422, 0.02455, 0.02443, 0.02322, 0.02244, 0.02228, 0.02211, 0.02324,
  0.02692, 0.02863, 0.02835, 0.02783, 0.02678, 0.02472, 0.02265, 0.02187, 0.02521, 0.02347, 0.02262,
  0.02248, 0.02274, 0.02315, 0.02535, 0.02513, 0.02668, 0.02763, 0.02834, 0.02855, 0.02681, 0.02816,
  0.02519, 0.02537, 0.02991, 0.03437, 0.03011, 0.02841, 0.02912, 0.0258, 0.02516, 0.02315, 0.02304,
  0.02158, 0.02024, 0.02013, 0.02128, 0.02262, 0.02716, 0.0274, 0.02878, 0.02911, 0.02985, 0.02877,
  0.02915, 0.02849, 0.02771, 0.02772, 0.03032, 0.03788, 0.0351, 0.03293, 0.03048, 0.02826, 0.02553,
  0.0251, 0.02184, 0.02163, 0.02156, 0.02134, 0.02159, 0.02318, 0.02763, 0.03002, 0.03002, 0.02928,
  0.02879, 0.02751, 0.02638, 0.02616, 0.0255, 0.02628, 0.03085, 0.03704, 0.03444, 0.03149, 0.02904,
  0.02641, 0.02372, 0.02402, 0.02221, 0.02217, 0.0216, 0.02141, 0.02213, 0.022, 0.02525, 0.02884,
  0.0298, 0.02758, 0.02736, 0.02644, 0.02554, 0.02436, 0.02419, 0.02482, 0.02842, 0.03358, 0.03183,
  0.02828, 0.02712, 0.02586, 0.02322, 0.02313, 0.0228, 0.02231, 0.02151, 0.02135, 0.02178, 0.02272,
  0.02549, 0.02731, 0.0271, 0.0258, 0.02574, 0.02516, 0.02426, 0.02412, 0.02395, 0.02465, 0.02664,
  0.03242, 0.03077, 0.02785, 0.0267, 0.02546, 0.0229, 0.02238, 0.02191, 0.02167, 0.02061, 0.02108,
  0.02088, 0.02225, 0.02463, 0.02602, 0.02558, 0.02593, 0.02574, 0.02494, 0.02367, 0.02325, 0.02328,
  0.02363, 0.0259, 0.03093, 0.02921, 0.02623, 0.02604, 0.02456, 0.02259, 0.02202, 0.02342, 0.02235,
  0.02159, 0.02039, 0.02156, 0.02224, 0.02143, 0.02377, 0.02511, 0.0252, 0.02527, 0.02414, 0.0229,
  0.02281, 0.02234, 0.02281, 0.02593, 0.03125, 0.02997, 0.02899, 0.02827, 0.02642, 0.02399, 0.02404,
  0.02642, 0.02551, 0.02377, 0.02459, 0.02413, 0.0231, 0.02267, 0.02451, 0.02304, 0.02356, 0.02427,
  0.02318, 0.02275, 0.02241, 0.02192, 0.02399, 0.0263, 0.03045, 0.02928, 0.02899, 0.02802, 0.02549,
  0.02301, 0.02237, 0.01873, 0.01744, 0.01502, 0.01209, 0.01172, 0.01687, 0.02202, 0.02386, 0.02536,
  0.02588, 0.02473, 0.02437, 0.0239, 0.02368, 0.02361, 0.02394, 0.0261, 0.02945, 0.02854, 0.02646,
  0.02517, 0.02346, 0.02123, 0.01964, 0.02011, 0.01929, 0.01868, 0.01868, 0.01841, 0.01874, 0.02042,
  0.02383, 0.02511, 0.02591, 0.0251, 0.02475, 0.02472, 0.02479, 0.02458, 0.0247, 0.02653, 0.03032,
  0.02847, 0.02653, 0.02515, 0.02358, 0.02134, 0.02181, 0.02043, 0.01992, 0.01954, 0.01871, 0.01948,
  0.02063, 0.02472, 0.02713, 0.02832, 0.02837, 0.02896, 0.0285, 0.02839, 0.02722, 0.02694, 0.02775,
  0.03095, 0.03793, 0.03789, 0.0328, 0.0307, 0.02815, 0.02408, 0.02333, 0.02734, 0.02589, 0.02504,
  0.02479, 0.0258, 0.02909, 0.03496, 0.03726, 0.03491, 0.03346, 0.03276, 0.03225, 0.03063, 0.03047,
  0.03068, 0.03189, 0.03245, 0.04185, 0.03914, 0.03517, 0.03422, 0.03106, 0.02845, 0.02848, 0.0259,
  0.02423, 0.0235, 0.02332, 0.02207, 0.02425, 0.02772, 0.03003, 0.03042, 0.02909, 0.02751, 0.026,
  0.0254, 0.02522, 0.02466, 0.02607, 0.02898, 0.03437, 0.03137, 0.02787, 0.02562, 0.0236, 0.02252,
  0.02149, 0.02247, 0.02082, 0.01826, 0.01782, 0.01693, 0.01772, 0.01919, 0.02037, 0.02228, 0.02376,
  0.0239, 0.02339, 0.02258, 0.02236, 0.02172, 0.02174, 0.02491, 0.02809, 0.02658, 0.02564, 0.02356,
  0.02151, 0.02046, 0.01908, 0.01827, 0.01782, 0.01661, 0.01662, 0.01667, 0.01669, 0.01816, 0.01915,
  0.01845, 0.01988, 0.02164, 0.02205, 0.02132, 0.0214, 0.02115, 0.02168, 0.02318, 0.02822, 0.02778,
  0.02705, 0.02484, 0.02407, 0.02189, 0.02084, 0.01999, 0.01975, 0.01928, 0.01797, 0.01861, 0.01993,
  0.02363, 0.02838, 0.02724, 0.0272, 0.0277, 0.02636, 0.02527, 0.0251, 0.02504, 0.0261, 0.02831,
  0.03512, 0.03329, 0.02984, 0.02756, 0.02559, 0.02348, 0.02231, 0.02374, 0.02222, 0.02167, 0.02162,
  0.02224, 0.02329, 0.02656, 0.03025, 0.02902, 0.02908, 0.02903, 0.02861, 0.02722, 0.027, 0.02642,
  0.02661, 0.02928, 0.03676, 0.03431, 0.03197, 0.0292, 0.02689, 0.02413, 0.02323, 0.0258, 0.02524,
  0.02405, 0.02358, 0.02399, 0.0271, 0.03285, 0.03797, 0.033, 0.03106, 0.03336, 0.03269, 0.03031,
  0.0301, 0.03007, 0.03484, 0.03938, 0.05411, 0.05154, 0.04997, 0.04687, 0.04197, 0.03582, 0.03499,
  0.05365, 0.04793, 0.04564, 0.0473, 0.04896, 0.05685, 0.08199, 0.07316, 0.05643, 0.05368, 0.06,
  0.0567, 0.05265, 0.05138, 0.05044, 0.05303, 0.07229, 0.0939, 0.08742, 0.07837, 0.06602, 0.06517,
  0.05098, 0.04958, 0.05414, 0.05168, 0.04859, 0.04764, 0.04854, 0.05594, 0.08416, 0.09355, 0.07801,
  0.06496, 0.05966, 0.05851, 0.05324, 0.05159, 0.05154, 0.05872, 0.06797, 0.0887, 0.08256, 0.06984,
  0.06722, 0.06012, 0.05168, 0.04897, 0.03661, 0.03419, 0.03171, 0.03126, 0.03199, 0.03254, 0.03629,
  0.03397, 0.03449, 0.03461, 0.03157, 0.02992, 0.02828, 0.02769, 0.02686, 0.02774, 0.03191, 0.04047,
  0.03844, 0.03589, 0.03439, 0.02989, 0.028, 0.02614, 0.02823, 0.02596, 0.02662, 0.02456, 0.02444,
  0.02497, 0.02722, 0.02816, 0.02521, 0.02541, 0.02342, 0.02331, 0.02279, 0.02235, 0.02097, 0.02222,
  0.02514, 0.03336, 0.03265, 0.03131, 0.02881, 0.02501, 0.02342, 0.02349, 0.0259, 0.02418, 0.02363,
  0.02322, 0.02371, 0.02459, 0.02855, 0.03659, 0.03111, 0.02965, 0.0279, 0.02646, 0.0234, 0.02376,
  0.02367, 0.02331, 0.0284, 0.03723, 0.03217, 0.03092, 0.0297, 0.02554, 0.02309, 0.02312, 0.02568,
  0.0244, 0.02409, 0.02376, 0.02333, 0.02489, 0.02705, 0.03102, 0.0283, 0.02857, 0.02566, 0.02483,
  0.02345, 0.02323, 0.02248, 0.02322, 0.02744, 0.03506, 0.03097, 0.02884, 0.02737, 0.02566, 0.02329,
  0.02298, 0.02291, 0.0221, 0.02131, 0.02116, 0.02095, 0.02126, 0.02252, 0.02386, 0.02273, 0.0224,
  0.02089, 0.02066, 0.02019, 0.02014, 0.02013, 0.02027, 0.02271, 0.02605, 0.02472, 0.02359, 0.02248,
  0.0208, 0.02041, 0.02045, 0.02011, 0.01945, 0.01847, 0.01866, 0.01943, 0.02062, 0.02166, 0.02483,
  0.02439, 0.02451, 0.02345, 0.02284, 0.02246, 0.02234, 0.02212, 0.0226, 0.0246, 0.03122, 0.02702,
  0.02558, 0.02369, 0.0224, 0.02105, 0.01997, 0.01812, 0.01662, 0.01476, 0.01439, 0.01558, 0.01666,
  0.02134, 0.02395, 0.02389, 0.02417, 0.0233, 0.02271, 0.02224, 0.02158, 0.02107, 0.02159, 0.02472,
  0.02795, 0.02602, 0.02375, 0.02277, 0.02111, 0.01992, 0.01944, 0.01891, 0.01682, 0.01576, 0.01558,
  0.01567, 0.01685, 0.01792, 0.01907, 0.01848, 0.01997, 0.01986, 0.01943, 0.0188, 0.01822, 0.01709,
  0.01704, 0.01994, 0.02432, 0.02364, 0.02267, 0.02198, 0.02055, 0.01913, 0.01839, 0.01997, 0.01748,
  0.01624, 0.01592, 0.01611, 0.01659, 0.01725, 0.01809, 0.01701, 0.0173, 0.01725, 0.01744, 0.01747,
  0.0167, 0.01594, 0.01716, 0.02014, 0.02516, 0.02354, 0.02259, 0.02106, 0.01968, 0.01846, 0.01738,
  0.01785, 0.017, 0.01527, 0.01528, 0.01563, 0.01762, 0.01969, 0.02182, 0.02107, 0.02183, 0.02156,
  0.02158, 0.02171, 0.02148, 0.02128, 0.02147, 0.0232, 0.03102, 0.02763, 0.02481, 0.02261, 0.0205,
  0.01917, 0.01759, 0.01634, 0.01436, 0.0156, 0.01346, 0.0138, 0.01432, 0.01716, 0.0195, 0.01948,
  0.0206, 0.02091, 0.02038, 0.02036, 0.01994, 0.01954, 0.02077, 0.02269, 0.0276, 0.02603, 0.02338,
  0.02101, 0.01935, 0.01866, 0.01778,
];

export default hourlyMarketPricesZoneH;
