import React from 'react';
import {Box, useTheme} from '@material-ui/core';
import {LoadProfile} from '@bellawatt/electric-rate-engine';
import {useInputs} from '@bellawatt/use-inputs';
import {Bar} from 'react-chartjs-2';
import {formatTime, formatAsThousands, formatVehicleSetTitle} from '@generic/functions/formatters';
import calcBatterySchedule from '@generic/functions/vehicleSet/calcBatterySchedule';
import {YEAR} from '@generic/data/assumptions';
import {useIntl} from 'react-intl';
import mean from 'lodash/mean';
import times from 'lodash/times';
import chunk from 'lodash/chunk';
import sum from 'lodash/sum';

export default function ChargingSiteLoadProfileChart({timeRange}) {
  const theme = useTheme();
  const {formatMessage} = useIntl();

  const {vehicleSets} = useInputs();

  const getTooltipTitle = (tick) => {
    if (timeRange.value === 'month') {
      return formatMessage({id: `timePeriods.months.${tick}.abbreviated`});
    }
    if (timeRange.value === 'day') {
      return formatMessage({
        id: `timePeriods.days.${tick}.abbreviated`,
      });
    }
    return formatTime(tick);
  };

  const getXAxisLabel = (tick) => {
    if (timeRange.value === 'month') {
      return formatMessage({id: `timePeriods.months.${tick}.abbreviated`});
    }
    if (timeRange.value === 'day') {
      return formatMessage({
        id: `timePeriods.days.${tick}.abbreviated`,
      });
    }
    return formatTime(+tick % 24);
  };

  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.light,
    theme.palette.primary.light,
    theme.palette.primary.dark,
    theme.palette.success.light,
  ];

  let labels;
  if (timeRange.value === 'month') {
    labels = times(12, (i) => i);
  } else if (timeRange.value === 'day') {
    labels = times(7, (i) => i);
  } else {
    labels = times(24, (i) => i);
  }

  const datasets = vehicleSets.map((vehicleSet, idx) => {
    const loadProfileData = calcBatterySchedule(vehicleSet).map((hour) => hour.chargedKwh);
    const loadProfile = new LoadProfile(loadProfileData, {year: YEAR});

    let chartData;
    switch (timeRange.value) {
      case 'month':
        // total by month
        chartData = loadProfile.sumByMonth();
        break;
      case 'day':
        // average by day of week throughout entire year
        chartData = times(7, (day) => {
          const filteredByDay = loadProfile.expanded().filter((entry) => entry.dayOfWeek === day);
          const groupedIntoFullDays = chunk(filteredByDay, 24);
          const fullDayLoads = groupedIntoFullDays.map((fullDay) =>
            sum(fullDay.map((entry) => entry.load))
          );
          return mean(fullDayLoads);
        });
        break;
      default:
        // average by hour throughout entire year
        chartData = times(24, (hour) =>
          mean(
            loadProfile
              .expanded()
              .filter((entry) => entry.hourStart === hour)
              .map((entry) => entry.load)
          )
        );
        break;
    }

    return {
      idx,
      label: formatVehicleSetTitle(vehicleSet),
      data: chartData,
      backgroundColor: COLORS[idx],
      categoryPercentage: 1,
      barPercentage: 0.8,
    };
  });

  const data = {
    labels,
    datasets,
  };

  const options = {
    tooltips: {
      callbacks: {
        title: (data) => getTooltipTitle(data[0].xLabel),
        label: (data, context) =>
          `${context.datasets[data.datasetIndex].label}: ${formatAsThousands(+data.value)} kWh`,
      },
    },
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        color: theme.palette.common.black,
        font: {
          size: 12,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.common.black,
            fontSize: 14,
            callback: (value) => `${formatAsThousands(value)} kWh`,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: getXAxisLabel,
            fontColor: theme.palette.common.black,
            fontSize: 14,
            maxRotation: 45,
            minRotation: 45,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <Box height={300}>
      <Bar data={data} options={options} datasetKeyProvider={(d) => d.idx} />
    </Box>
  );
}
