import React from 'react';
import calcHasConflictingChargingWindows from '@generic/functions/vehicleSet/calcHasConflictingChargingWindows';
import Alert from '@material-ui/lab/Alert';
import {useIntl} from 'react-intl';

export default function ChargingWindowsAlerts({chargingWindows}) {
  const {formatMessage} = useIntl();

  const alert = calcHasConflictingChargingWindows(chargingWindows) && {
    severity: 'error',
    msg: formatMessage({id: 'vehicleSetDialog.alerts.conflictingChargingWindows'}),
  };

  return alert && <Alert severity={alert.severity}>{alert.msg}</Alert>;
}
