import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  error: {
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
}));

export default function ChargingErrors({errors}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const {conflictingChargingWindowVehicles, depletedBatteryVehicles} = errors;

  return (
    <Box className={classes.root}>
      {!!conflictingChargingWindowVehicles.length && (
        <Alert severity="error" className={classes.error}>
          <Typography>
            {formatMessage(
              {id: 'charging.errors.conflictingChargingWindows'},
              {vehicles: <b>{conflictingChargingWindowVehicles.join(', ')}</b>}
            )}
          </Typography>
        </Alert>
      )}

      {!!depletedBatteryVehicles.length && (
        <Alert severity="error" className={classes.error}>
          <Typography>
            {formatMessage(
              {id: 'charging.errors.depletedBatteries'},
              {vehicles: <b>{depletedBatteryVehicles.join(', ')}</b>}
            )}
          </Typography>
          <Typography>
            {formatMessage({id: 'charging.errors.depletedBatteriesSubheader'})}
          </Typography>
        </Alert>
      )}
    </Box>
  );
}
