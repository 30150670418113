import React from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import MaintenanceCostsChart from '@generic/pages/ChargingCalculator/MaintenanceCostsChart';

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: '700',
    marginBottom: '1rem',
  },
  subheader: {fontSize: '20px'},
  chartWrapper: {
    padding: '1.5rem 0',
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem',
    },
  },
}));

export default function MaintenanceCosts() {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  return (
    <Box>
      <Typography variant="h6" className={classes.header}>
        {formatMessage({id: 'charging.content.costs.maintenance.header'})}
      </Typography>
      <Typography variant="body2" className={classes.subheader}>
        {formatMessage({id: 'charging.content.costs.maintenance.subheader'})}
      </Typography>

      <Box className={classes.chartWrapper}>
        <MaintenanceCostsChart />
      </Box>
    </Box>
  );
}
