import React from 'react';
import {Grid, Typography, Box, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import ChargingTimeRangeBreakdown from '@generic/pages/ChargingCalculator/ChargingTimeRangeBreakdown';
import ChargingBreakdownLabels from '@generic/pages/ChargingCalculator/ChargingBreakdownLabels';
import ChargingBreakdownDivider from '@generic/pages/ChargingCalculator/ChargingBreakdownDivider';
import groupVehicleSetsByChargers from '@generic/functions/groupVehicleSetsByChargers';
import {formatTimeRange, formatVehicleSetTitle} from '@generic/functions/formatters';

const useStyles = makeStyles((theme) => ({
  labelsWrapper: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.gray[400],
  },
  row: {
    marginBottom: '3rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '2rem',
    },
  },
  chargerLabel: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1rem',
    color: theme.palette.gray[500],
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  vehicleInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  vehicleLabel: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1rem',
    marginRight: '1rem',
    color: theme.palette.gray[500],
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  vehicleTitle: {
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  indexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.light}`,
  },
  vehicleImg: {
    marginRight: 16,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
    },
  },
  bold: {
    fontWeight: 700,
  },
}));

export default function ChargerIndexedChargingBreakdown() {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const {vehicleSets} = useInputs();

  const chargingSets = groupVehicleSetsByChargers(vehicleSets);

  return (
    <Grid container spacing={1}>
      <Box className={classes.labelsWrapper}>
        <ChargingBreakdownLabels
          labels={[
            formatMessage({id: 'charging.content.chargers.labels.chargerType'}),
            formatMessage({id: 'charging.content.chargers.labels.vehicle'}),
            formatMessage({id: 'charging.content.chargers.labels.timeRange'}),
          ]}
        />
      </Box>

      {chargingSets.map((chargingSet, idx) => (
        <Grid key={idx} item container xs={12} className={classes.row}>
          {/* Left Box */}
          <Grid item xs={12} md={4}>
            <Box
              p={3}
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              className={classes.indexBox}
            >
              <Box className={classes.chargerLabel}>
                {formatMessage({id: 'charging.content.chargers.labels.chargerType'})}
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="body1">{chargingSet.type}</Typography>
                <Typography variant="body1">
                  {formatMessage({id: `chargers.ports.${chargingSet.ports}`})}
                </Typography>
                <Typography variant="body1" className={classes.bold}>
                  {formatMessage(
                    {id: 'chargingBreakdown.minimumRequired'},
                    {
                      quantity: chargingSet.minimumRequired,
                    }
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Divider */}
          <Grid item xs={12} md={1}>
            <ChargingBreakdownDivider />
          </Grid>

          {/* Right Box */}
          <Grid item xs={12} md={7}>
            {chargingSet.vehicles.map(({vehicle, vehicleCount, chargingWindow}, idx) => (
              <ChargingTimeRangeBreakdown
                key={idx}
                timeRange={formatTimeRange(chargingWindow.start, chargingWindow.finish)}
              >
                <Box className={classes.vehicleInfo}>
                  <Box className={classes.vehicleLabel}>
                    {formatMessage({id: 'charging.content.chargers.labels.vehicle'})}
                  </Box>
                  <Box display="flex" alignItems="center">
                    <img
                      src={vehicle.imageUrl}
                      alt={formatVehicleSetTitle({vehicle, vehicleCount})}
                      height="auto"
                      width={63}
                      className={classes.vehicleImg}
                    />
                    <Typography variant="body1" className={classes.vehicleTitle}>
                      {formatVehicleSetTitle({vehicle, vehicleCount})}
                    </Typography>
                  </Box>
                </Box>
              </ChargingTimeRangeBreakdown>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
