import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import SelectCharger from '@generic/components/ChargerSetDialog/SelectCharger';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1.125rem',
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  subheader: {
    fontSize: '1rem',
  },
}));

export default function Chargers({inputs, handleInputChange}) {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const {chargerSets} = inputs;
  // remove computed charger so it isn't included when we set the charger sets input
  const {charger, ...restOfChargerSet} = chargerSets[0];

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h2" className={classes.header}>
          {formatMessage({id: 'wizard.planning.chargers.header'})}
        </Typography>
        <Typography className={classes.subheader}>
          {formatMessage({id: 'wizard.planning.chargers.subheader'})}
        </Typography>
      </Box>
      <SelectCharger
        chargerSet={restOfChargerSet}
        onChangeChargerSet={(newData) =>
          handleInputChange('chargerSets', [{...restOfChargerSet, ...newData}])
        }
        variant="gray"
      />
    </Box>
  );
}
