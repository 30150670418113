import {Box, makeStyles, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import calcAnnualFossilFuelCosts from '@generic/functions/calcAnnualFossilFuelCosts';
import calcAnnualVehicleElectricityCosts from '@generic/functions/calcAnnualVehicleElectricityCosts';
import {HorizontalBar} from 'react-chartjs-2';
import {formatAsThousands} from '@generic/functions/formatters';
import {useInputs} from '@bellawatt/use-inputs';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  graphHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 2,
  },
  graphSubheader: {
    fontWeight: 'bold',
  },
  costDifference: {
    color: theme.palette.primary.main,
  },
  smallScreenChart: {
    border: `1px solid ${theme.palette.gray[300]}`,
  },
  chartBottomInfo: {
    borderTop: `1px solid ${theme.palette.gray[300]}`,
  },
  chartInfoTitle: {
    fontSize: '0.875rem',
    fontWeight: 700,
  },
}));

export default function YearlyFuelCostChart() {
  const theme = useTheme();
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const inputs = useInputs();
  const {separatelyMetered} = inputs;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });

  const gasolineCost = calcAnnualFossilFuelCosts(inputs);
  const {total: electricityCost, facility, ev} = calcAnnualVehicleElectricityCosts(inputs);

  const gasolineCostWithFacility = gasolineCost + facility;

  const data = {
    labels: [
      `${formatMessage({id: 'charging.content.overview.chart.electricFleet'})} ${formatMessage({
        id: 'charging.content.overview.chart.yourSelection',
      })}`,
      `${formatMessage({id: 'charging.content.overview.chart.fossilFuelFleet'})} ${formatMessage({
        id: 'charging.content.overview.chart.similarVehicle',
      })}`,
    ],
    datasets: [
      {
        label: formatMessage({id: 'charging.content.overview.chart.facility'}),
        data: [facility, facility],
        barPercentage: 0.6,
        backgroundColor: '#FDEEBA',
      },
      {
        label: formatMessage({id: 'charging.content.overview.chart.vehicles'}),
        data: [ev, gasolineCost],
        barPercentage: 0.6,
        backgroundColor: [theme.palette.primary.main, theme.palette.common.black],
      },
    ],
  };

  const options = {
    tooltips: {
      enabled: !separatelyMetered,
      callbacks: {
        label: (dataItem, context) =>
          `${context.datasets[dataItem.datasetIndex].label}: $${formatAsThousands(
            context.datasets[dataItem.datasetIndex].data[dataItem.index]
          )}`,
      },
    },
    legend: false,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontStyle: 'bold',
            fontColor: '#2B2B2B',
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            max: (Math.max(electricityCost, gasolineCost) + facility) * 1.3,
            display: false,
          },
        },
      ],
    },
    hover: {
      animationDuration: 1,
    },
    animation: {
      duration: 1,
      // label each bar with its total value
      onComplete: function onAnimationComplete() {
        /* eslint-disable react/no-this-in-sfc */
        const chartInstance = this.chart;
        const {ctx} = chartInstance;
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.font = ctx.font.replace(/\d+px/, '14px');
        ctx.textBaseline = 'center';

        const lastDatasetIdx = this.data.datasets.length - 1;

        const meta = chartInstance.controller.getDatasetMeta(lastDatasetIdx);
        meta.data.forEach(({_model: {x, y}}, index) => {
          const data = `$${formatAsThousands(
            this.data.datasets.reduce((sum, curr) => sum + curr.data[index], 0)
          )}`;
          ctx.fillText(data, x + 35, y + 3);
        });
        /* eslint-enable react/no-this-in-sfc */
      },
    },
  };

  const isElectricSavings = electricityCost < gasolineCostWithFacility;
  const costDifference = isElectricSavings
    ? gasolineCostWithFacility - electricityCost
    : electricityCost - gasolineCostWithFacility;

  return (
    <Box mt={8}>
      <Typography variant="body2" className={classes.graphHeader}>
        {formatMessage({id: 'charging.content.overview.yearlyCostsHeader'})}
      </Typography>
      <Typography variant="body2" className={classes.graphSubheader}>
        {formatMessage(
          {id: 'charging.content.overview.yearlyCosts'},
          {
            costDifference: (
              <span className={classes.costDifference}>{`$${formatAsThousands(
                costDifference
              )}`}</span>
            ),
            moreOrLess: isElectricSavings ? 'less' : 'more',
          }
        )}
      </Typography>

      {/* No need to render actual ChartJS chart on small screens, just the data as text */}
      {isSmallScreen ? (
        <Box mt={3} className={classes.smallScreenChart}>
          <Box display="flex">
            <Box width="60%" p={2} display="flex" flexDirection="column" justifyContent="center">
              <Typography className={classes.chartInfoTitle}>
                {formatMessage({id: 'charging.content.overview.chart.electricFleet'})}
              </Typography>
              <Typography className={classes.chartInfoTitle}>
                {formatMessage({id: 'charging.content.overview.chart.yourSelection'})}
              </Typography>
            </Box>
            <Box flexGrow={1} p={2} display="flex" flexDirection="column" justifyContent="center">
              <Typography>${formatAsThousands(electricityCost)}</Typography>
            </Box>
          </Box>
          <Box display="flex" className={classes.chartBottomInfo}>
            <Box width="60%" p={2} display="flex" flexDirection="column" justifyContent="center">
              <Typography className={classes.chartInfoTitle}>
                {formatMessage({id: 'charging.content.overview.chart.fossilFuelFleet'})}
              </Typography>
              <Typography className={classes.chartInfoTitle}>
                {formatMessage({id: 'charging.content.overview.chart.similarVehicle'})}
              </Typography>
            </Box>
            <Box flexGrow={1} p={2} display="flex" flexDirection="column" justifyContent="center">
              <Typography>${formatAsThousands(gasolineCostWithFacility)}</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box mt={3}>
          <HorizontalBar data={data} options={options} />
        </Box>
      )}
    </Box>
  );
}
