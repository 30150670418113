import React, {useState} from 'react';
import {makeStyles, Box, Typography, TextField, Tooltip} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import IconCircleQuestion from '@generic/assets/images/IconCircleQuestion';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  inlineBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: (props) => ({
    fontWeight: props.variant === 'gray' ? 600 : 700,
    textTransform: props.variant === 'gray' ? 'none' : 'uppercase',
    color: props.variant === 'gray' ? theme.palette.gray[600] : theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  }),
  textField: {
    '& .MuiInput-root': {
      fontSize: '22px',
      fontWeight: 600,
      width: 90,
    },
  },
  textFieldFullWidth: {
    width: '100%',
    '& .MuiInput-root': {
      width: '100%',
    },
  },
  tooltipTrigger: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  autocomplete: {
    '& .MuiInputBase-root': {
      minWidth: '125px',
    },
  },
}));

const WrappedAutocomplete = ({
  className,
  fullWidth,
  onChange,
  options,
  value,
  variant,
  ...rest
}) => {
  // The autocomplete component needs its value to either be null or a string that exists within its list of options.
  // But the _input value_ expects a blank string instead of null. Thus we cast null to a blank string.
  const valueAsString = value ? value.toString() : '';
  // This separate component exists so that an external change to the value can be used as a key to this component's
  // parent, which will remount this component, resetting its working internal state.
  const [workingValue, setWorkingValue] = useState(valueAsString);
  const classes = useStyles({variant});

  const handleChange = (e, newValue, reason) => {
    if (onChange) onChange(e, newValue, reason);
  };

  return (
    <Autocomplete
      className={`${classes.autocomplete} ${className}`}
      options={options}
      onChange={handleChange}
      variant="outlined"
      inputValue={workingValue}
      onInputChange={(e, newValue) => {
        setWorkingValue(newValue);
      }}
      value={value}
      renderInput={(params) => (
        <TextField
          className={`${classes.textField} ${fullWidth ? classes.textFieldFullWidth : ''}`}
          {...params}
        />
      )}
      {...rest}
    />
  );
};

export default function AutocompleteInput(props) {
  const {fullWidth = false, title, tooltip, value, variant = ''} = props;
  // When a URL query param drives this value, useInputs casts a numeric looking string to a number.
  // But the autocomplete component expects a string. So we want to cast is back to a string.
  // But if the value doesn't exist, the autocomplete component expects null.
  const valueAsStringOrNull = value ? value.toString() : null;

  const classes = useStyles({variant});

  const propsWithStringifiedValue = {
    ...props,
    value: valueAsStringOrNull,
  };

  return (
    <Box className={fullWidth ? '' : classes.inlineBox}>
      <Typography variant="body2" className={classes.title}>
        {title}
        {tooltip && (
          <Tooltip title={tooltip} arrow placement="right">
            <span className={classes.tooltipTrigger}>
              <IconCircleQuestion />
            </span>
          </Tooltip>
        )}
      </Typography>
      <WrappedAutocomplete key={valueAsStringOrNull} {...propsWithStringifiedValue} />
    </Box>
  );
}
