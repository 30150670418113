import React from 'react';
import {Box} from '@material-ui/core';
import LineItemBill from '@generic/pages/ChargingCalculator/LineItemBill';
import {useIntl} from 'react-intl';

export default function TotalCharges({total}) {
  const {formatMessage} = useIntl();

  const taxes = [
    {
      label: formatMessage({id: 'charging.content.costs.electricity.bill.taxesLabel'}),
      charge: formatMessage({id: 'charging.content.costs.electricity.bill.taxesNotIncluded'}),
      isText: true,
    },
    {
      label: formatMessage({id: 'charging.content.costs.electricity.bill.totalTaxes'}),
      charge: formatMessage({id: 'charging.content.costs.electricity.bill.taxesNotIncluded'}),
      isText: true,
      emphasized: true,
    },
  ];

  return (
    <Box>
      <LineItemBill charges={taxes} total={total} largeTotal />
    </Box>
  );
}
