import React from 'react';
import {Box, makeStyles, FormControl, Select, MenuItem, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import {getRates} from '@generic/data/rates';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bold',
    marginRight: '1rem',
    lineHeight: '1.5rem',
  },
  select: {
    fontSize: '1.25rem',
    marginRight: '8px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

export default function RateBreakdownSelector({rateName, handleChange, maxDemand}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const inputs = useInputs();

  return (
    <Box mb={5} display="flex" alignItems="center" justifyContent="center">
      <Typography variant="h6" className={classes.label}>
        <label htmlFor="rate-breakdown">
          {formatMessage({id: 'charging.content.costs.electricity.rateBreakdown.title'})}
        </label>
      </Typography>
      <FormControl>
        <Select
          className={classes.select}
          value={rateName}
          onChange={(e) => handleChange(e.target.value)}
          inputProps={{id: 'rate-breakdown'}}
        >
          {getRates(inputs, maxDemand).map(({name, title}) => (
            <MenuItem key={name} value={name}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
