import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '28px 20px',
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up('md')]: {
      padding: '28px 100px',
    },
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkText: {
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  text: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: theme.palette.common.white,
  },
}));

export default function SecondaryFooter() {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={2}>
          <a
            href={formatMessage({id: 'footer.secondary.links.privacyPolicyLink'})}
            className={classes.link}
          >
            <Typography className={classes.linkText}>
              {formatMessage({id: 'footer.secondary.links.privacyPolicy'})}
            </Typography>
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a
            href={formatMessage({id: 'footer.secondary.links.accessibilityPolicyLink'})}
            className={classes.link}
          >
            <Typography className={classes.linkText}>
              {formatMessage({id: 'footer.secondary.links.accessibilityPolicy'})}
            </Typography>
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a
            href={formatMessage({id: 'footer.secondary.links.termsOfServiceLink'})}
            className={classes.link}
          >
            <Typography className={classes.linkText}>
              {formatMessage({id: 'footer.secondary.links.termsOfService'})}
            </Typography>
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <a
            href={formatMessage({id: 'footer.secondary.links.homeNetLink'})}
            className={classes.link}
          >
            <Typography className={classes.linkText}>
              {formatMessage({id: 'footer.secondary.links.homeNet'})}
            </Typography>
          </a>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography className={classes.text}>
            {formatMessage(
              {id: 'footer.secondary.poweredByLink'},
              {
                linkTag: (
                  <a href="https://zappyride.com" className={classes.link}>
                    {formatMessage({id: 'footer.secondary.zappyRide'})}
                  </a>
                ),
              }
            )}
          </Typography>
        </Grid>
      </Grid>

      <Box flexGrow={1} mt={2}>
        <Typography className={classes.text}>
          {formatMessage({id: 'footer.secondary.copyright'})}
        </Typography>
      </Box>
    </Box>
  );
}
