import React from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';
import {formatAsDollars} from '@generic/functions/formatters';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
  },
  lineItem: {
    padding: '1px 4px',
  },
  text: {
    fontSize: 10,
  },
  total: {
    backgroundColor: theme.palette.primary.lighter,
    borderTop: `1px solid ${theme.palette.common.black}`,
  },
  totalText: {
    fontSize: 10,
    fontWeight: 700,
    padding: 4,
  },
  emphasized: {
    fontWeight: 700,
  },
  largeTotal: {
    fontSize: 14,
  },
}));

export default function LineItemBill({charges, total, largeTotal = false}) {
  const classes = useStyles();

  return (
    <Box mt={2}>
      {charges.map(({label, charge, emphasized, isText}) => (
        <Box key={label} display="flex" className={classes.lineItem}>
          <Box flexGrow={1}>
            <Typography className={`${classes.text} ${emphasized ? classes.emphasized : ''}`}>
              {label}
            </Typography>
          </Box>
          <Box>
            <Typography className={`${classes.text} ${emphasized ? classes.emphasized : ''}`}>
              {isText ? charge : formatAsDollars(charge)}
            </Typography>
          </Box>
        </Box>
      ))}
      <Box display="flex" className={classes.total}>
        <Box flexGrow={1}>
          <Typography className={`${classes.totalText} ${largeTotal ? classes.largeTotal : ''}`}>
            {total.label}
          </Typography>
        </Box>
        <Box>
          <Typography className={`${classes.totalText} ${largeTotal ? classes.largeTotal : ''}`}>
            {total.isText ? total.charge : formatAsDollars(total.charge)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
