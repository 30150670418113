import React from 'react';
import Header from '@generic/components/Header';
import {Box, Container} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Footer from '@generic/components/Footer';
import Favicons from '@generic/components/Favicons';
import Seo from '@generic/components/Seo';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.palette.common.white,
  },
  main: {
    flexGrow: 1,
    padding: ({noPadding}) => (noPadding ? '0' : 'auto'),
  },
}));

export default function Layout({children, noPadding = false}) {
  const classes = useStyles({noPadding});

  return (
    <Box className={classes.root}>
      <Favicons />
      <Header className={classes.header} />
      <Container maxWidth="xl" component="main" className={classes.main}>
        {children}
      </Container>
      <Footer />

      <Seo />
    </Box>
  );
}
