import React from 'react';
import {Grid, Typography, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bold',
    color: theme.palette.gray[400],
  },
}));

export default function ChargingBreakdownLabels({labels: [label1, label2, label3]}) {
  const classes = useStyles();
  return (
    <Grid item container xs={12}>
      <Grid item container xs={5}>
        <Typography variant="body2" className={classes.label}>
          {label1}
        </Typography>
      </Grid>
      <Grid item container xs={7}>
        <Grid item xs={7}>
          <Typography variant="body2" className={classes.label}>
            {label2}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" className={classes.label}>
            {label3}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
