import React, {useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ChevronIcon from '@generic/assets/images/icon-chevron.svg';

const Accordion = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.gray[300]}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gray[100],
    borderBottom: `1px solid ${theme.palette.gray[300]}`,
    marginBottom: -1,
    minHeight: 56,
    padding: '0 24px',
    '&$expanded': {
      minHeight: 56,
    },

    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '16px 24px',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
  summaryText: {
    flexGrow: 1,
  },
  chevronIcon: {
    transition: 'transform 100ms ease',
  },
  chevronExpanded: {
    transform: 'rotate(180deg)',
  },
}));

export default function AccordionPanel({summary, details}) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Accordion square expanded={expanded} onChange={(e, newExpanded) => setExpanded(newExpanded)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography className={classes.summaryText}>{summary}</Typography>
          <img
            className={`${classes.chevronIcon} ${expanded ? classes.chevronExpanded : ''}`}
            src={ChevronIcon}
            alt={expanded ? 'Collapse' : 'Expand'}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{details}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
