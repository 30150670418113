import calcHasConflictingChargingWindows from '@generic/functions/vehicleSet/calcHasConflictingChargingWindows';
import calcMinimumStateOfCharge from '@generic/functions/vehicleSet/calcMinimumStateOfCharge';
import {formatVehicleName} from '@generic/functions/formatters';

const calcVehicleSetErrors = (vehicleSets) => {
  const conflictingChargingWindowVehicles = [];
  const depletedBatteryVehicles = [];

  vehicleSets.forEach((vehicleSet) => {
    const {chargingWindows, vehicle} = vehicleSet;

    if (calcHasConflictingChargingWindows(chargingWindows)) {
      conflictingChargingWindowVehicles.push(formatVehicleName(vehicle));
    }

    if (calcMinimumStateOfCharge(vehicleSet) < 0) {
      depletedBatteryVehicles.push(formatVehicleName(vehicle));
    }
  });

  return {
    conflictingChargingWindowVehicles,
    depletedBatteryVehicles,
  };
};

export default calcVehicleSetErrors;
