import {Box, Button, Grid, IconButton, makeStyles, Tooltip} from '@material-ui/core';
import {useIntl} from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import DropdownInput from '@generic/components/inputs/DropdownInput';
import ChargingWindowsAlerts from '@generic/components/VehicleSetDialog/ChargingWindowsAlerts';
import IconCircleQuestion from '@generic/assets/images/IconCircleQuestion';
import {chargingTimes} from '@generic/data/chargingWindows';
import chargers from '@generic/data/chargers';
import chargingApproaches from '@generic/data/chargingApproaches';
import {
  DEFAULT_CHARGING_WINDOW,
  SECONDARY_DEFAULT_CHARGING_WINDOW,
} from '@generic/functions/getDefaults';

const MAX_CHARGING_WINDOWS = 4;

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    fontSize: '4rem',
    height: '2rem',
    width: '2rem',

    '&:hover': {
      color: theme.palette.gray[500],
      backgroundColor: 'transparent',
    },
  },
  singleRowHeadings: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  singleRowHeading: {
    paddingBottom: '0 !important',
    fontWeight: 600,
    color: theme.palette.gray[500],
  },
  heading: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  chargingWindow: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.gray[300]}`,

    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
      paddingTop: theme.spacing(1),
    },

    '&:first-child': {
      borderTop: 0,
    },
    '& .MuiSelect-select': {
      paddingRight: theme.spacing(2),
    },
  },
  chargingWindows: {
    '& .MuiGrid-root:first-child': {
      '& .MuiGrid-item': {
        paddingTop: '0',
      },
    },
  },
  addChargingTime: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    textDecoration: 'underline',
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
    '&:active, &:focus': {
      color: theme.palette.primary.dark,
    },
  },
  tooltipTrigger: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

function SingleRowHeading({id, label, tooltip, ...rest}) {
  const classes = useStyles();

  return (
    <Grid className={classes.singleRowHeading} item {...rest}>
      <Box display="flex">
        <label htmlFor={id}>{label}</label>
        {tooltip && (
          <Tooltip title={tooltip} arrow placement="right">
            <span className={classes.tooltipTrigger}>
              <IconCircleQuestion />
            </span>
          </Tooltip>
        )}
      </Box>
    </Grid>
  );
}

function Heading({id, label, tooltip}) {
  const classes = useStyles();

  return (
    <Box className={classes.heading}>
      <label htmlFor={id}>{label}</label>
      {tooltip && (
        <Tooltip title={tooltip} arrow placement="right">
          <span className={classes.tooltipTrigger}>
            <IconCircleQuestion />
          </span>
        </Tooltip>
      )}
    </Box>
  );
}

function ChargingWindow({start, finish, type, chargingApproach, onChange, onRemove, canDelete}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const handleChange = (e, key) => {
    onChange({
      start,
      finish,
      type,
      chargingApproach,
      [key]: e.target.value,
    });
  };

  return (
    <Grid className={classes.chargingWindow} container spacing={2} alignItems="center" pb={4}>
      <Grid item xs={6} md={2}>
        <Heading
          id="charging-time-start"
          label={formatMessage({id: 'vehicleSetDialog.chargingWindows.start.label'})}
        />
        <DropdownInput
          id="charging-time-start"
          fullWidth
          onChange={(e) => handleChange(e, 'start')}
          options={chargingTimes.map(({id, translationKey}) => ({
            value: id,
            children: formatMessage({id: translationKey}),
          }))}
          value={start}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <Heading
          id="charging-time-finish"
          label={formatMessage({id: 'vehicleSetDialog.chargingWindows.finish.label'})}
        />
        <DropdownInput
          id="charging-time-finish"
          fullWidth
          onChange={(e) => handleChange(e, 'finish')}
          options={chargingTimes.map(({id, translationKey}) => ({
            value: id,
            children: formatMessage({id: translationKey}),
          }))}
          value={finish}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Heading
          id="charger-type"
          label={formatMessage({id: 'vehicleSetDialog.chargingWindows.charger.label'})}
          tooltip={formatMessage({
            id: 'vehicleSetDialog.chargingWindows.charger.tooltip',
          })}
        />
        <DropdownInput
          id="charger-type"
          fullWidth
          onChange={(e) => handleChange(e, 'type')}
          options={chargers.map(({type}) => ({
            value: type,
            children: formatMessage({id: `chargers.types.${type}`}),
          }))}
          value={type}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <Heading
          id="charging-approach"
          label={formatMessage({id: 'vehicleSetDialog.chargingWindows.approach.label'})}
          tooltip={formatMessage({
            id: 'vehicleSetDialog.chargingWindows.approach.tooltip',
          })}
        />
        <DropdownInput
          id="charging-approach"
          fullWidth
          onChange={(e) => handleChange(e, 'chargingApproach')}
          options={chargingApproaches.map(({id, translationKey}) => ({
            value: id,
            children: formatMessage({id: translationKey}),
          }))}
          value={chargingApproach}
        />
      </Grid>
      <Grid item xs={1}>
        {canDelete && (
          <IconButton className={`${classes.closeIcon} icon-close`} onClick={onRemove} />
        )}
      </Grid>
    </Grid>
  );
}

export default function ChargingWindows({vehicleSet, onChangeVehicleSet}) {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const {chargingWindows} = vehicleSet;

  const handleChange = (idx, data) => {
    const newChargingWindows = cloneDeep(chargingWindows);
    newChargingWindows[idx] = data;
    onChangeVehicleSet({chargingWindows: newChargingWindows});
  };

  const handleAddChargingWindow = () =>
    onChangeVehicleSet({
      chargingWindows: [
        ...vehicleSet.chargingWindows,
        chargingWindows.length === 0 ? DEFAULT_CHARGING_WINDOW : SECONDARY_DEFAULT_CHARGING_WINDOW,
      ],
    });

  const handleRemoveChargingWindow = (idx) => {
    onChangeVehicleSet({
      chargingWindows: vehicleSet.chargingWindows.filter((_, i) => i !== idx),
    });
  };

  return (
    <>
      <Grid container spacing={2} className={classes.singleRowHeadings}>
        <SingleRowHeading
          id="charging-time-start-single-row"
          label={formatMessage({id: 'vehicleSetDialog.chargingWindows.start.label'})}
          xs={2}
          p={0}
        />
        <SingleRowHeading
          id="charging-time-finish-single-row"
          label={formatMessage({id: 'vehicleSetDialog.chargingWindows.finish.label'})}
          xs={2}
        />
        <SingleRowHeading
          id="charger-type-single-row"
          label={formatMessage({id: 'vehicleSetDialog.chargingWindows.charger.label'})}
          tooltip={formatMessage({
            id: 'vehicleSetDialog.chargingWindows.charger.tooltip',
          })}
          xs={5}
        />
        <SingleRowHeading
          id="charging-approach-single-row"
          label={formatMessage({id: 'vehicleSetDialog.chargingWindows.approach.label'})}
          tooltip={formatMessage({
            id: 'vehicleSetDialog.chargingWindows.approach.tooltip',
          })}
          xs={2}
        />
      </Grid>
      <Box className={classes.chargingWindows} mb={4}>
        {chargingWindows.map((chargingWindow, idx) => (
          <ChargingWindow
            key={idx}
            onChange={(data) => handleChange(idx, data)}
            onRemove={() => handleRemoveChargingWindow(idx)}
            canDelete={chargingWindows.length > 1}
            {...chargingWindow}
          />
        ))}
      </Box>
      {chargingWindows.length < MAX_CHARGING_WINDOWS && (
        <Button className={classes.addChargingTime} onClick={handleAddChargingWindow}>
          {formatMessage({id: 'vehicleSetDialog.chargingWindows.addChargingTime'})}
        </Button>
      )}

      <Box mt={2}>
        <ChargingWindowsAlerts chargingWindows={chargingWindows} />
      </Box>
    </>
  );
}
