import isEqual from 'lodash/isEqual';

export const formatAsThousands = (val) =>
  Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatTime = (hours24) => {
  const hours = ((hours24 + 11) % 12) + 1;
  const amPm = hours24 > 11 ? 'pm' : 'am';
  return hours + amPm;
};

export const formatTimeRange = (start, finish) => `${formatTime(start)} - ${formatTime(finish)}`;

export const formatAsDollars = (val) =>
  `$${Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const formatAsHundredsOfDollars = (val) =>
  `~$${(Math.round(val / 100) * 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const formatAsThousandsOfDollars = (val) =>
  `$${Math.round(val / 1000)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}k`;

export const formatAsDollarsRounded = (val) => {
  const numZeros = 3;

  const output =
    Math.round(val).toString().length > numZeros
      ? Number(val.toPrecision(Math.round(val).toString().length - 3))
      : Math.round(Number(val));

  return `$${output.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatAsDollarsPerGal = (val) => `$${parseFloat(val).toFixed(2).toString()}/gal`;

export const formatAsCents = (val) => `$${val.toFixed(2)}`;

export const titleCase = (string) => {
  if (string === null || string === '') return '';
  const str = string.toString();

  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const formatVehicleName = ({make, model, type, subtype}) => {
  const name = make ? `${make} ${model}` : `${type} (${subtype})`;
  return titleCase(name).replace(/_/g, ' ');
};

export const formatVehicleSetTitle = ({vehicleCount, vehicle}) =>
  `${vehicleCount}x ${formatVehicleName(vehicle)}`;

export const formatChargerSetTitle = ({chargerCount, type}) => `${chargerCount}x ${type}`;

export const formatChargerTitle = ({category, power}) => `${category} - ${power}kW`;

export const formatWorkdays = ({workdays, formatMessage}) => {
  let workdaysKeys = [];
  if (isEqual([1, 2, 3, 4, 5], workdays.sort())) {
    workdaysKeys = ['weekdays'];
  } else if (isEqual([0, 6], workdays.sort())) {
    workdaysKeys = ['weekends'];
  } else {
    workdaysKeys = workdays;
  }

  return workdaysKeys.map((key) => formatMessage({id: `timePeriods.days.${key}.long`})).join(', ');
};
