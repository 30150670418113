import calcMilesPerYear from '@generic/functions/calcMilesPerYear';
import sum from 'lodash/sum';
import {ELECTRIC_MAINTENANCE_COST_FACTOR} from '@generic/data/assumptions';

const calcAnnualElectricityMaintenanceCosts = ({vehicleSets}) =>
  sum(
    vehicleSets.map(
      (set) =>
        calcMilesPerYear(set) *
        set.vehicle.maintenanceCostPerMile *
        ELECTRIC_MAINTENANCE_COST_FACTOR
    )
  );

export default calcAnnualElectricityMaintenanceCosts;
