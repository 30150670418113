import {IntlProvider} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import {messages} from '@generic/utils/reactIntlConfig';

export default function ReactIntlProvider({children, defaultLocale}) {
  const {lng} = useInputs();
  const currentMessages =
    messages[lng] || messages[defaultLocale] || messages[Object.keys(messages)[0]];

  return (
    <IntlProvider locale={lng} key={lng} messages={currentMessages}>
      {children}
    </IntlProvider>
  );
}
