import React from 'react';
import YearlyFuelCostChart from '@generic/pages/ChargingCalculator/YearlyFuelCostChart';
import FleetCostsSummary from '@generic/pages/ChargingCalculator/FleetCostsSummary';

export default function ChargingOverview() {
  return (
    <>
      <YearlyFuelCostChart />
      <FleetCostsSummary />
    </>
  );
}
