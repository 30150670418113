import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import ElectricityBillHeader from '@generic/pages/ChargingCalculator/ElectricityBillHeader';
import SupplyCharges from '@generic/pages/ChargingCalculator/SupplyCharges';
import DeliveryCharges from '@generic/pages/ChargingCalculator/DeliveryCharges';
import TotalCharges from '@generic/pages/ChargingCalculator/TotalCharges';
import {useIntl} from 'react-intl';
import sum from 'lodash/sum';

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    fontSize: '0.75rem',
    color: theme.palette.gray[500],
  },
}));

export default function ElectricityBill({rateCalculator, loadProfile, rateTitle}) {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const totalKwh = loadProfile.sum() / 12;
  const totalKw = loadProfile.max();

  const deliveryElements = rateCalculator.rateElements({billingCategories: ['delivery']});
  const supplyElements = rateCalculator.rateElements({billingCategories: ['supply']});

  const [supplyDemand, supplyEnergy] = ['demand', 'energy'].map(
    (classification) =>
      sum(
        supplyElements
          .filter((el) => el.classification === classification)
          .map((el) => el.annualCost())
      ) / 12
  );

  const [deliveryDemand, deliveryEnergy, deliveryFixed] = ['demand', 'energy', 'fixed'].map(
    (classification) =>
      sum(
        deliveryElements
          .filter((el) => el.classification === classification)
          .map((el) => el.annualCost())
      ) / 12
  );

  const deliveryTotal = sum(deliveryElements.map((el) => el.annualCost())) / 12;
  const supplyTotal = sum(supplyElements.map((el) => el.annualCost())) / 12;

  const total = deliveryTotal + supplyTotal;
  const rateCalculatorTotal = rateCalculator.annualCost() / 12;

  if (Math.abs(total - rateCalculatorTotal) > 1) {
    // eslint-disable-next-line no-console
    console.error(
      "WARNING: Calculated Delivery + Supply costs were not equivalent to the rate calculator's total cost"
    );
  }

  const supplyCharges = [
    {
      label: `Energy Supply ${totalKwh.toFixed(3)} kWh @${(supplyEnergy / totalKwh).toFixed(
        3
      )}/kWh`,
      charge: supplyEnergy,
    },
    {
      label: `Demand Supply ${totalKw.toFixed(3)} kW @${(supplyDemand / totalKw).toFixed(3)}/kW`,
      charge: supplyDemand,
    },
  ];

  const deliveryCharges = [
    {
      label: formatMessage({
        id: 'charging.content.costs.electricity.bill.deliveryCharges.fixedLabel',
      }),
      charge: deliveryFixed,
    },
    {
      label: `Energy Delivery ${totalKwh.toFixed(3)} kWh @${(deliveryEnergy / totalKwh).toFixed(
        3
      )}/kWh`,
      charge: deliveryEnergy,
    },
    {
      label: `Demand Delivery ${totalKw.toFixed(3)} kW @${(deliveryDemand / totalKw).toFixed(
        3
      )}/kW`,
      charge: deliveryDemand,
    },
  ];

  const totalCharge = {
    label: formatMessage({id: 'charging.content.costs.electricity.bill.total'}),
    charge: total,
  };

  const supplyCostPerKwh = supplyTotal / totalKwh;

  return (
    <Box mt={12}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ElectricityBillHeader rateTitle={rateTitle} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SupplyCharges charges={supplyCharges} costPerKwh={supplyCostPerKwh} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DeliveryCharges charges={deliveryCharges} />
          <TotalCharges total={totalCharge} />
        </Grid>
      </Grid>
      <Box mt={4}>
        <Typography variant="body2" className={classes.disclaimer}>
          {formatMessage({id: 'charging.content.costs.electricity.bill.disclaimer'})}
        </Typography>
      </Box>
    </Box>
  );
}
