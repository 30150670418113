import {useIntl} from 'react-intl';
import {Box, makeStyles, Typography, useTheme} from '@material-ui/core';
import {HorizontalBar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {formatAsThousands, formatAsDollars} from '@generic/functions/formatters';
import calcAnnualFossilFuelCosts from '@generic/functions/calcAnnualFossilFuelCosts';
import {useInputs} from '@bellawatt/use-inputs';
import {RateCalculator} from '@bellawatt/electric-rate-engine';
import calcScaledFacilityLoadProfile from '@generic/functions/calcScaledFacilityLoadProfile';

const useStyles = makeStyles(() => ({
  chartHeader: {
    fontWeight: '600',
  },
}));

export default function ChargingRatesOverviewChart({compareToFossil, annualCosts}) {
  const classes = useStyles();
  const theme = useTheme();
  const {formatMessage} = useIntl();
  const inputs = useInputs();

  const {separatelyMetered, currentRate, includeSCCRebate} = inputs;
  RateCalculator.shouldValidate = false;

  const fossilFuelCosts = calcAnnualFossilFuelCosts(inputs);

  let fossilFuelFacilityCost = 0;
  if (!separatelyMetered) {
    const scaledFacilityLoadProfile = calcScaledFacilityLoadProfile(inputs);

    fossilFuelFacilityCost = new RateCalculator({
      ...currentRate,
      loadProfile: scaledFacilityLoadProfile,
    }).annualCost();
  }

  const data = {
    labels: compareToFossil
      ? ['Fossil Fuel', ...annualCosts.map(({title}) => title)]
      : [...annualCosts.map(({title}) => title)],
    datasets: [
      {
        label: formatMessage({id: 'charging.content.costs.electricity.rateBreakdown.facility'}),
        data: compareToFossil
          ? [fossilFuelFacilityCost, ...annualCosts.map(({facility}) => facility)]
          : annualCosts.map(({facility}) => facility),
        barPercentage: 0.7,
        backgroundColor: theme.palette.secondary.lighter,
      },
      {
        label: formatMessage({
          id: 'charging.content.costs.electricity.rateBreakdown.fossilFuelVehicles',
        }),
        data: compareToFossil
          ? [fossilFuelCosts, ...annualCosts.map(() => 0)]
          : annualCosts.map(({ev, fleetSceRebate: {total}}) => ev - total),
        barPercentage: 0.7,
        backgroundColor: theme.palette.grey[800],
      },
      {
        label: formatMessage({id: 'charging.content.costs.electricity.rateBreakdown.ev'}),
        data: compareToFossil
          ? [0, ...annualCosts.map(({ev, fleetSccRebate: {total}}) => ev - total)]
          : annualCosts.map(({ev, fleetSceRebate: {total}}) => ev - total),
        barPercentage: 0.7,
        backgroundColor: theme.palette.primary.main,
      },
      {
        label: formatMessage({id: 'smartChargeCommercial.graphLabel'}),
        data: compareToFossil
          ? [0, ...annualCosts.map(({fleetSccRebate: {total}}) => total)]
          : annualCosts.map(({fleetSccRebate: {total}}) => total),
        barPercentage: 0.7,
        borderColor: theme.palette.success.main,
        borderWidth: 1,
        backgroundColor: theme.palette.neutral.light,
      },
    ],
  };

  const options = {
    legend: {
      display: true,
      labels: {
        filter: (legendItem) => {
          const isSccRebate =
            legendItem.text === formatMessage({id: 'smartChargeCommercial.graphLabel'});
          const isFacility =
            legendItem.text ===
            formatMessage({id: 'charging.content.costs.electricity.rateBreakdown.facility'});
          const isFossilFuel =
            legendItem.text ===
            formatMessage({
              id: 'charging.content.costs.electricity.rateBreakdown.fossilFuelVehicles',
            });

          if (isSccRebate && !includeSCCRebate) {
            return false;
          }
          if (isFacility && separatelyMetered) {
            return false;
          }
          if (isFossilFuel && !compareToFossil) {
            return false;
          }
          return true;
        },
      },
    },
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (dataItem, context) => {
          const {datasetIndex, index} = dataItem;
          const value = context.datasets[datasetIndex].data[index];
          // don't show zero values
          return value > 0
            ? `${context.datasets[dataItem.datasetIndex].label}: $${formatAsThousands(value)}`
            : null;
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 8,
            callback: (value) => `$${formatAsThousands(value)}`,
          },
        },
      ],
    },
    layout: {
      padding: {
        right: 100,
      },
    },
    plugins: {
      datalabels: {
        formatter: (_value, ctx) => {
          const {
            chart: {
              data: {datasets},
            },
            dataIndex,
            datasetIndex,
          } = ctx;

          const {
            fleetSccRebate: {total},
          } = annualCosts[dataIndex - 1] || {fleetSccRebate: {total: 0}};

          if (dataIndex > 0 && datasetIndex === datasets.length - 1 && total > 0) {
            return `${formatAsDollars(total)} incentive`;
          }
          return '';
        },
        align: 'end',
        anchor: 'end',
        color: theme.palette.success.main,
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const plugins = [ChartDataLabels];

  return (
    <Box mt={6}>
      <Typography align="center" variant="h6" className={classes.chartHeader}>
        {formatMessage(
          {id: 'charging.content.costs.electricity.rateBreakdown.cheapestRateTitle'},
          {rateTitle: annualCosts[0].title}
        )}
      </Typography>
      <Typography align="center">
        {formatMessage(
          {
            id: 'smartChargeCommercial.chartSubtitle.text',
          },
          {
            link: (
              <a
                href={formatMessage({id: 'smartChargeCommercial.chartSubtitle.href'})}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({id: 'smartChargeCommercial.chartSubtitle.linkTrigger'})}
              </a>
            ),
          }
        )}
      </Typography>
      <Box height={300} mt={3}>
        <HorizontalBar data={data} options={options} plugins={plugins} />
      </Box>
    </Box>
  );
}
