import React, {useState} from 'react';
import Layout from '@generic/components/Layout';
import {Typography, Box, Grid, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {useIntl} from 'react-intl';
import PlanningInsights from '@generic/pages/SitePlanner/PlanningInsights';
import PlanningTabs from '@generic/pages/SitePlanner/PlanningTabs';
import PlanningInputs from '@generic/pages/SitePlanner/PlanningInputs';
import PlanningWizard from '@generic/pages/SitePlanner/wizard/PlanningWizard';
import {useInputs} from '@bellawatt/use-inputs';
import InputsDialog from '@generic/components/inputs/InputsDialog';
import CtaButton from '@generic/components/CtaButton';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    paddingBottom: 20,
    fontWeight: '800',
    textAlign: 'left',

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      height: 4,
      width: 85,
      backgroundColor: theme.palette.primary.light,
    },

    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  inputs: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  smallScreenFilterTrigger: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  smallScreenFilterTriggerButton: {
    width: 300,
  },
}));
export default function SitePlanner() {
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const {planningWizardShown} = useInputs();
  const theme = useTheme();

  const [isSmallScreenFiltersOpen, setIsSmallScreenFiltersOpen] = useState(false);

  const isMediumOrSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <Layout>
      <Box mb={6} mt={3}>
        <Typography align="center" variant="h1" className={classes.header}>
          {formatMessage({id: 'planning.header'})}
        </Typography>
      </Box>

      <Box mb={6}>
        <PlanningInsights />
      </Box>

      <Box mb={6} className={classes.smallScreenFilterTrigger}>
        <CtaButton
          className={classes.smallScreenFilterTriggerButton}
          onClick={() => setIsSmallScreenFiltersOpen(true)}
        >
          {formatMessage({id: 'charging.inputs.adjustFilters'})}
        </CtaButton>
      </Box>

      <Grid container spacing={isMediumOrSmallScreen ? 2 : 6}>
        <Grid item md={3} className={classes.inputs}>
          <PlanningInputs />
        </Grid>
        <Grid item xs={12} md={9}>
          <PlanningTabs />
        </Grid>
      </Grid>

      {!planningWizardShown && <PlanningWizard />}

      <InputsDialog
        isOpen={isSmallScreenFiltersOpen}
        onClose={() => setIsSmallScreenFiltersOpen(false)}
      >
        <PlanningInputs />
      </InputsDialog>
    </Layout>
  );
}
