import React from 'react';
import {Box, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const SUPPORTING_COPY = {
  sc9Rate1: [
    'SC 9 Rate I applies to customers with demand over 10kW and under 1,500kW.',
    'This rate is intended for general use by large commercial and industrial customers. Most EV charging sites take service under this rate.',
    'For Demand Charges, the first 5 kW are included as part of the monthly charge. Additional demand is charged on a $/kW basis.',
    'Example customers include large offices, grocery stores, and hospitals.',
  ],
  sc9Rate2: [
    'SC 9 Rate II applies to customers with demand over 1,500kW.',
    'This rate benefits customers that can shift demand to off-peak hours, and provides a good opportunity to moderate demand through co-location of EV charging load and other facility load.',
    'Demand Charges are based upon the maximum demand for each time period, including Monday to Friday from 8 a.m. to 10 p.m., and Monday to Friday 8 a.m. to 6 p.m. during summer months.',
    'Example customers include manufacturing facilities with robust evening shifts, and delivery businesses with an EV fleet charging overnight.',
  ],
  sc9Rate3: [
    'SC 9 Rate III applies to customers with demand over 10kW and under 1,500kW.',
    'This rate benefits customers that can shift demand to off-peak hours and provides a good opportunity to moderate demand through co-location of EV charging load and other facility load.',
    'Demand Charges are based upon the maximum demand for Monday to Friday from 8 a.m. to 10 p.m., and Monday to Friday 8 a.m. to 6 p.m. during summer months.',
    'Example customers include manufacturing facilities with robust evening shifts, and delivery businesses with an EV fleet charging overnight.',
  ],
  sc9Rate4: [
    'SC 9 Rate IV applies to customers with demand over 10kW and under 1,500kW.',
    'This rate is designed for customers that use the Con Edison system to serve part or none of their load, but is open to eligible customers with no distributed generation. This rate most benefits customers with steady load.',
    'Customers pay a fixed contract demand delivery charge based on the maximum demand of the facility. As-used daily demand delivery charges are based upon maximum demand for Monday to Friday from 8 a.m. to 10 p.m., and Monday to Friday 8 a.m. to 6 p.m. during summer months.',
    'Example customers include private generation customers.',
  ],
  sc9Rate5: [
    'SC 9 Rate V applies to customers with demand over 1,500kW.',
    'This rate is designed for customers that use the Con Edison system to serve part or none of their load, but is open to eligible customers with no distributed generation. This rate most benefits customers with steady load.',
    'Customers pay a fixed contract demand delivery charge based on the maximum demand of the facility. As-used daily demand delivery charges are based upon maximum demand for Monday to Friday from 8 a.m. to 10 p.m., and Monday to Friday 8 a.m. to 6 p.m. during summer months.',
    'Example customers include private generation customers.',
  ],
};

export default function SupportingCopyPrintout({rateName}) {
  const supportCopy = SUPPORTING_COPY[rateName];
  return (
    <Box>
      <Alert icon={false} severity="success" variant="outlined">
        {supportCopy.map((paragraph, idx) => (
          <Typography key={idx}>{paragraph}</Typography>
        ))}
      </Alert>
    </Box>
  );
}
