const peakWindowsByZipcode = {
  10001: [14, 15, 16, 17],
  10002: [14, 15, 16, 17],
  10003: [14, 15, 16, 17],
  10004: [11, 12, 13, 14],
  10005: [11, 12, 13, 14],
  10006: [11, 12, 13, 14],
  10007: [14, 15, 16, 17],
  10008: [14, 15, 16, 17],
  10009: [14, 15, 16, 17],
  10010: [14, 15, 16, 17],
  10011: [14, 15, 16, 17],
  10012: [14, 15, 16, 17],
  10013: [14, 15, 16, 17],
  10014: [14, 15, 16, 17],
  10016: [14, 15, 16, 17],
  10017: [14, 15, 16, 17],
  10018: [11, 12, 13, 14],
  10019: [14, 15, 16, 17],
  10020: [14, 15, 16, 17],
  10021: [14, 15, 16, 17],
  10022: [14, 15, 16, 17],
  10023: [16, 17, 18, 19],
  10024: [16, 17, 18, 19],
  10025: [16, 17, 18, 19],
  10026: [14, 15, 16, 17],
  10027: [14, 15, 16, 17],
  10028: [14, 15, 16, 17],
  10029: [14, 15, 16, 17],
  10030: [14, 15, 16, 17],
  10031: [14, 15, 16, 17],
  10032: [16, 17, 18, 19],
  10033: [16, 17, 18, 19],
  10034: [16, 17, 18, 19],
  10035: [19, 20, 21, 22],
  10036: [14, 15, 16, 17],
  10037: [14, 15, 16, 17],
  10038: [14, 15, 16, 17],
  10039: [14, 15, 16, 17],
  10040: [16, 17, 18, 19],
  10041: [11, 12, 13, 14],
  10043: [11, 12, 13, 14],
  10044: [14, 15, 16, 17],
  10045: [14, 15, 16, 17],
  10055: [11, 12, 13, 14],
  10060: [11, 12, 13, 14],
  10065: [14, 15, 16, 17],
  10069: [14, 15, 16, 17],
  10075: [14, 15, 16, 17],
  10081: [14, 15, 16, 17],
  10087: [14, 15, 16, 17],
  10090: [11, 12, 13, 14],
  10101: [14, 15, 16, 17],
  10102: [11, 12, 13, 14],
  10103: [11, 12, 13, 14],
  10104: [11, 12, 13, 14],
  10105: [11, 12, 13, 14],
  10106: [11, 12, 13, 14],
  10107: [14, 15, 16, 17],
  10108: [11, 12, 13, 14],
  10109: [11, 12, 13, 14],
  10110: [11, 12, 13, 14],
  10111: [14, 15, 16, 17],
  10112: [14, 15, 16, 17],
  10113: [14, 15, 16, 17],
  10114: [11, 12, 13, 14],
  10115: [14, 15, 16, 17],
  10116: [14, 15, 16, 17],
  10117: [14, 15, 16, 17],
  10118: [14, 15, 16, 17],
  10119: [11, 12, 13, 14],
  10120: [11, 12, 13, 14],
  10121: [11, 12, 13, 14],
  10122: [11, 12, 13, 14],
  10123: [11, 12, 13, 14],
  10124: [11, 12, 13, 14],
  10125: [14, 15, 16, 17],
  10126: [14, 15, 16, 17],
  10128: [14, 15, 16, 17],
  10129: [11, 12, 13, 14],
  10130: [14, 15, 16, 17],
  10131: [14, 15, 16, 17],
  10132: [14, 15, 16, 17],
  10133: [14, 15, 16, 17],
  10138: [11, 12, 13, 14],
  10150: [11, 12, 13, 14],
  10151: [11, 12, 13, 14],
  10152: [11, 12, 13, 14],
  10153: [11, 12, 13, 14],
  10154: [11, 12, 13, 14],
  10155: [14, 15, 16, 17],
  10156: [11, 12, 13, 14],
  10157: [14, 15, 16, 17],
  10158: [11, 12, 13, 14],
  10159: [14, 15, 16, 17],
  10160: [14, 15, 16, 17],
  10161: [11, 12, 13, 14],
  10162: [14, 15, 16, 17],
  10163: [11, 12, 13, 14],
  10164: [11, 12, 13, 14],
  10165: [11, 12, 13, 14],
  10166: [11, 12, 13, 14],
  10167: [14, 15, 16, 17],
  10168: [11, 12, 13, 14],
  10169: [11, 12, 13, 14],
  10170: [11, 12, 13, 14],
  10171: [14, 15, 16, 17],
  10172: [14, 15, 16, 17],
  10173: [11, 12, 13, 14],
  10174: [11, 12, 13, 14],
  10175: [11, 12, 13, 14],
  10176: [11, 12, 13, 14],
  10177: [11, 12, 13, 14],
  10178: [11, 12, 13, 14],
  10179: [11, 12, 13, 14],
  10185: [11, 12, 13, 14],
  10199: [14, 15, 16, 17],
  10211: [14, 15, 16, 17],
  10212: [11, 12, 13, 14],
  10213: [14, 15, 16, 17],
  10242: [14, 15, 16, 17],
  10249: [14, 15, 16, 17],
  10256: [11, 12, 13, 14],
  10257: [14, 15, 16, 17],
  10258: [14, 15, 16, 17],
  10260: [11, 12, 13, 14],
  10265: [11, 12, 13, 14],
  10268: [11, 12, 13, 14],
  10269: [11, 12, 13, 14],
  10270: [11, 12, 13, 14],
  10271: [14, 15, 16, 17],
  10272: [14, 15, 16, 17],
  10273: [14, 15, 16, 17],
  10274: [11, 12, 13, 14],
  10275: [14, 15, 16, 17],
  10276: [14, 15, 16, 17],
  10277: [11, 12, 13, 14],
  10278: [14, 15, 16, 17],
  10279: [11, 12, 13, 14],
  10280: [14, 15, 16, 17],
  10281: [14, 15, 16, 17],
  10282: [14, 15, 16, 17],
  10285: [14, 15, 16, 17],
  10286: [11, 12, 13, 14],
  10292: [14, 15, 16, 17],
  10301: [16, 17, 18, 19],
  10302: [16, 17, 18, 19],
  10303: [16, 17, 18, 19],
  10304: [16, 17, 18, 19],
  10305: [16, 17, 18, 19],
  10306: [16, 17, 18, 19],
  10307: [16, 17, 18, 19],
  10308: [16, 17, 18, 19],
  10309: [16, 17, 18, 19],
  10310: [16, 17, 18, 19],
  10311: [16, 17, 18, 19],
  10312: [16, 17, 18, 19],
  10313: [16, 17, 18, 19],
  10314: [16, 17, 18, 19],
  10451: [16, 17, 18, 19],
  10452: [16, 17, 18, 19],
  10453: [16, 17, 18, 19],
  10454: [16, 17, 18, 19],
  10455: [16, 17, 18, 19],
  10456: [16, 17, 18, 19],
  10457: [16, 17, 18, 19],
  10458: [16, 17, 18, 19],
  10459: [16, 17, 18, 19],
  10460: [16, 17, 18, 19],
  10461: [16, 17, 18, 19],
  10462: [16, 17, 18, 19],
  10463: [16, 17, 18, 19],
  10464: [16, 17, 18, 19],
  10465: [16, 17, 18, 19],
  10466: [16, 17, 18, 19],
  10467: [16, 17, 18, 19],
  10468: [16, 17, 18, 19],
  10469: [16, 17, 18, 19],
  10470: [16, 17, 18, 19],
  10471: [16, 17, 18, 19],
  10472: [16, 17, 18, 19],
  10473: [16, 17, 18, 19],
  10474: [16, 17, 18, 19],
  10475: [16, 17, 18, 19],
  10499: [16, 17, 18, 19],
  10502: [16, 17, 18, 19],
  10503: [16, 17, 18, 19],
  10504: [14, 15, 16, 17],
  10506: [14, 15, 16, 17],
  10507: [14, 15, 16, 17],
  10510: [16, 17, 18, 19],
  10511: [16, 17, 18, 19],
  10514: [14, 15, 16, 17],
  10517: [16, 17, 18, 19],
  10520: [16, 17, 18, 19],
  10522: [16, 17, 18, 19],
  10523: [16, 17, 18, 19],
  10528: [14, 15, 16, 17],
  10530: [16, 17, 18, 19],
  10532: [14, 15, 16, 17],
  10533: [16, 17, 18, 19],
  10538: [16, 17, 18, 19],
  10543: [14, 15, 16, 17],
  10545: [16, 17, 18, 19],
  10546: [14, 15, 16, 17],
  10547: [16, 17, 18, 19],
  10548: [16, 17, 18, 19],
  10549: [14, 15, 16, 17],
  10550: [16, 17, 18, 19],
  10551: [16, 17, 18, 19],
  10552: [16, 17, 18, 19],
  10553: [16, 17, 18, 19],
  10562: [16, 17, 18, 19],
  10566: [16, 17, 18, 19],
  10567: [16, 17, 18, 19],
  10570: [14, 15, 16, 17],
  10571: [14, 15, 16, 17],
  10572: [14, 15, 16, 17],
  10573: [14, 15, 16, 17],
  10577: [14, 15, 16, 17],
  10580: [14, 15, 16, 17],
  10583: [16, 17, 18, 19],
  10588: [16, 17, 18, 19],
  10591: [16, 17, 18, 19],
  10594: [14, 15, 16, 17],
  10595: [14, 15, 16, 17],
  10596: [16, 17, 18, 19],
  10598: [16, 17, 18, 19],
  10601: [14, 15, 16, 17],
  10602: [14, 15, 16, 17],
  10603: [14, 15, 16, 17],
  10604: [14, 15, 16, 17],
  10605: [14, 15, 16, 17],
  10606: [14, 15, 16, 17],
  10607: [16, 17, 18, 19],
  10610: [14, 15, 16, 17],
  10701: [16, 17, 18, 19],
  10702: [16, 17, 18, 19],
  10703: [16, 17, 18, 19],
  10704: [16, 17, 18, 19],
  10705: [16, 17, 18, 19],
  10706: [16, 17, 18, 19],
  10707: [16, 17, 18, 19],
  10708: [16, 17, 18, 19],
  10709: [16, 17, 18, 19],
  10710: [16, 17, 18, 19],
  10801: [16, 17, 18, 19],
  10802: [16, 17, 18, 19],
  10803: [16, 17, 18, 19],
  10804: [16, 17, 18, 19],
  10805: [16, 17, 18, 19],
  11004: [16, 17, 18, 19],
  11005: [16, 17, 18, 19],
  11101: [14, 15, 16, 17],
  11102: [14, 15, 16, 17],
  11103: [14, 15, 16, 17],
  11104: [16, 17, 18, 19],
  11105: [14, 15, 16, 17],
  11106: [14, 15, 16, 17],
  11109: [14, 15, 16, 17],
  11120: [14, 15, 16, 17],
  11201: [14, 15, 16, 17],
  11202: [14, 15, 16, 17],
  11203: [19, 20, 21, 22],
  11204: [14, 15, 16, 17],
  11205: [14, 15, 16, 17],
  11206: [19, 20, 21, 22],
  11207: [19, 20, 21, 22],
  11208: [19, 20, 21, 22],
  11209: [14, 15, 16, 17],
  11210: [16, 17, 18, 19],
  11211: [19, 20, 21, 22],
  11212: [19, 20, 21, 22],
  11213: [19, 20, 21, 22],
  11214: [14, 15, 16, 17],
  11215: [14, 15, 16, 17],
  11216: [16, 17, 18, 19],
  11217: [14, 15, 16, 17],
  11218: [16, 17, 18, 19],
  11219: [14, 15, 16, 17],
  11220: [14, 15, 16, 17],
  11221: [19, 20, 21, 22],
  11222: [19, 20, 21, 22],
  11223: [14, 15, 16, 17],
  11224: [14, 15, 16, 17],
  11225: [16, 17, 18, 19],
  11226: [16, 17, 18, 19],
  11228: [16, 17, 18, 19],
  11229: [16, 17, 18, 19],
  11230: [14, 15, 16, 17],
  11231: [14, 15, 16, 17],
  11232: [14, 15, 16, 17],
  11233: [19, 20, 21, 22],
  11234: [16, 17, 18, 19],
  11235: [14, 15, 16, 17],
  11236: [16, 17, 18, 19],
  11237: [19, 20, 21, 22],
  11238: [16, 17, 18, 19],
  11239: [19, 20, 21, 22],
  11240: [14, 15, 16, 17],
  11241: [14, 15, 16, 17],
  11242: [14, 15, 16, 17],
  11243: [14, 15, 16, 17],
  11245: [14, 15, 16, 17],
  11247: [19, 20, 21, 22],
  11249: [14, 15, 16, 17],
  11251: [14, 15, 16, 17],
  11252: [16, 17, 18, 19],
  11256: [19, 20, 21, 22],
  11351: [16, 17, 18, 19],
  11352: [16, 17, 18, 19],
  11354: [16, 17, 18, 19],
  11355: [16, 17, 18, 19],
  11356: [16, 17, 18, 19],
  11357: [16, 17, 18, 19],
  11358: [16, 17, 18, 19],
  11359: [16, 17, 18, 19],
  11360: [16, 17, 18, 19],
  11361: [16, 17, 18, 19],
  11362: [16, 17, 18, 19],
  11363: [16, 17, 18, 19],
  11364: [16, 17, 18, 19],
  11365: [16, 17, 18, 19],
  11366: [16, 17, 18, 19],
  11367: [16, 17, 18, 19],
  11368: [16, 17, 18, 19],
  11369: [16, 17, 18, 19],
  11370: [16, 17, 18, 19],
  11371: [16, 17, 18, 19],
  11372: [16, 17, 18, 19],
  11373: [16, 17, 18, 19],
  11374: [16, 17, 18, 19],
  11375: [16, 17, 18, 19],
  11377: [14, 15, 16, 17],
  11378: [16, 17, 18, 19],
  11379: [16, 17, 18, 19],
  11380: [16, 17, 18, 19],
  11381: [16, 17, 18, 19],
  11385: [16, 17, 18, 19],
  11386: [16, 17, 18, 19],
  11390: [16, 17, 18, 19],
  11405: [16, 17, 18, 19],
  11411: [16, 17, 18, 19],
  11412: [16, 17, 18, 19],
  11413: [16, 17, 18, 19],
  11414: [19, 20, 21, 22],
  11415: [19, 20, 21, 22],
  11416: [19, 20, 21, 22],
  11417: [19, 20, 21, 22],
  11418: [19, 20, 21, 22],
  11419: [19, 20, 21, 22],
  11420: [19, 20, 21, 22],
  11421: [19, 20, 21, 22],
  11422: [16, 17, 18, 19],
  11423: [16, 17, 18, 19],
  11424: [16, 17, 18, 19],
  11425: [16, 17, 18, 19],
  11426: [16, 17, 18, 19],
  11427: [16, 17, 18, 19],
  11428: [16, 17, 18, 19],
  11429: [16, 17, 18, 19],
  11430: [16, 17, 18, 19],
  11431: [16, 17, 18, 19],
  11432: [16, 17, 18, 19],
  11433: [16, 17, 18, 19],
  11434: [16, 17, 18, 19],
  11435: [16, 17, 18, 19],
  11436: [16, 17, 18, 19],
  11439: [16, 17, 18, 19],
  11451: [16, 17, 18, 19],
  11499: [16, 17, 18, 19],
  11690: [19, 20, 21, 22],
  11691: [19, 20, 21, 22],
  11693: [19, 20, 21, 22],
  11694: [19, 20, 21, 22],
  11695: [19, 20, 21, 22],
  11697: [19, 20, 21, 22],
};

export const DEFAULT_PEAK_DEMAND_WINDOWS = [16, 17, 18, 19];
export default peakWindowsByZipcode;
