import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {useInputs} from '@bellawatt/use-inputs';
import AccordionPanel from '@generic/components/AccordionPanel';
import ChargingRatesOverviewChart from '@generic/pages/ChargingCalculator/ChargingRatesOverviewChart';
import ChargingRateBreakdown from '@generic/pages/ChargingCalculator/ChargingRateBreakdown';
import {useIntl} from 'react-intl';
import calcAnnualVehicleElectricityCostsAllRates from '@generic/functions/calcAnnualVehicleElectricityCostsAllRates';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0',
    [theme.breakpoints.up('md')]: {
      padding: '0 2rem',
    },
  },
}));

const MAX_RATES_SHOWN = 3;

export default function ElectricityCosts() {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const inputs = useInputs();

  const rawAnnualCosts = calcAnnualVehicleElectricityCostsAllRates(inputs);
  const annualCosts = rawAnnualCosts.sort((a, b) => a.total - b.total).slice(0, MAX_RATES_SHOWN);

  return (
    <Box>
      <AccordionPanel
        summary={formatMessage({id: 'electricRateExplainer.summary'})}
        details={formatMessage({id: 'electricRateExplainer.details'})}
      />

      <Box className={classes.content}>
        <ChargingRatesOverviewChart compareToFossil annualCosts={annualCosts} />

        <ChargingRateBreakdown annualCosts={annualCosts} />
      </Box>
    </Box>
  );
}
