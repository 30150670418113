import {Box, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import {useIntl} from 'react-intl';
import {
  formatTime,
  formatVehicleSetTitle,
  formatWorkdays,
  formatAsThousands,
} from '@generic/functions/formatters';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.gray[300]}`,
    borderLeft: `1px solid ${theme.palette.gray[300]}`,
    borderTop: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      borderLeft: 'none',
      borderTop: `1px solid ${theme.palette.gray[300]}`,
    },
  },
  border: {
    // we need a div acting as a left border so the corners don't look 'mitered'
    // as a result of having differently styled borders on all other sides
    backgroundColor: theme.palette.primary.main,
    height: 5,
    width: '100%',
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: 5,
      height: 'auto',
    },
  },
  titleAndImage: {
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'auto',
      width: 290,
    },
  },
  vehicleImage: {
    width: 255,
    maxWidth: '100%',
    height: 'auto',
  },
  vehicleTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  specsTitle: {
    textTransform: 'uppercase',
  },
  specsContainer: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  specsColumn: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
}));

const VehicleSpec = ({children, label, value}) => (
  <Box mb={3}>
    <Typography variant="body2" style={{fontWeight: 'bold'}}>
      {label}
    </Typography>
    {children || <Typography variant="body2">{value}</Typography>}
  </Box>
);

const ChargingWindow = ({chargingWindow: {start, finish, type, chargingApproach}}) => {
  const {formatMessage} = useIntl();
  return (
    <Box mb={2}>
      <Typography variant="body2">
        {formatMessage(
          {
            id: 'charging.content.vehicles.specs.chargingWindows.time',
          },
          {
            start: formatTime(start),
            finish: formatTime(finish),
          }
        )}
      </Typography>
      <Typography variant="body2">{formatMessage({id: `chargers.types.${type}`})}</Typography>
      <Typography variant="body2">
        {formatMessage(
          {
            id: 'charging.content.vehicles.specs.chargingWindows.approach',
          },
          {
            chargingApproach,
          }
        )}
      </Typography>
    </Box>
  );
};

export default function VehicleSetCard({vehicleSet}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  const {vehicle} = vehicleSet;
  const {
    imageUrl,
    rangeInMiles,
    batteryCapacityInKwh,
    efficiencyInKwhPerHundredMiles,
    fossilMilesPerGallon,
    fossilFuelType,
    gvwr,
  } = vehicle;
  const {workdays, milesPerWorkday, chargingWindows, vehicleCount} = vehicleSet;

  const vehicleSetTitle = formatVehicleSetTitle({vehicle, vehicleCount});

  return (
    <Box display="flex" mb={3} className={classes.root}>
      <Box className={classes.border} />
      <Box className={classes.content} p={3}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.titleAndImage}
        >
          <Typography variant="h6" className={classes.vehicleTitle}>
            {vehicleSetTitle}
          </Typography>
          <img src={imageUrl} alt={vehicleSetTitle} className={classes.vehicleImage} />
        </Box>

        <Box ml={3} flexGrow={1}>
          <Typography variant="body2" className={classes.specsTitle}>
            {formatMessage({id: 'charging.content.vehicles.vehicleSpecsTitle'})}
          </Typography>
          <Box display="flex" mt={3} className={classes.specsContainer}>
            <Box className={classes.specsColumn}>
              <VehicleSpec
                label={formatMessage({id: 'charging.content.vehicles.specs.range.label'})}
                value={formatMessage(
                  {
                    id: 'charging.content.vehicles.specs.range.value',
                  },
                  {
                    rangeInMiles,
                  }
                )}
              />
              <VehicleSpec
                label={formatMessage({
                  id: 'charging.content.vehicles.specs.batteryCapacity.label',
                })}
                value={formatMessage(
                  {
                    id: 'charging.content.vehicles.specs.batteryCapacity.value',
                  },
                  {
                    batteryCapacityInKwh,
                  }
                )}
              />
              <VehicleSpec
                label={formatMessage({
                  id: 'charging.content.vehicles.specs.efficiency.label',
                })}
                value={formatMessage(
                  {
                    id: 'charging.content.vehicles.specs.efficiency.value',
                  },
                  {
                    efficiencyInKwhPerHundredMiles,
                  }
                )}
              />
              <VehicleSpec
                label={formatMessage({
                  id: 'charging.content.vehicles.specs.weight.label',
                })}
                value={formatMessage(
                  {
                    id: 'charging.content.vehicles.specs.weight.value',
                  },
                  {
                    gvwr: formatAsThousands(gvwr),
                  }
                )}
              />
              <VehicleSpec
                label={formatMessage({id: 'charging.content.vehicles.specs.equivGasMpg.label'})}
                value={formatMessage(
                  {
                    id: 'charging.content.vehicles.specs.equivGasMpg.value',
                  },
                  {
                    fossilMilesPerGallon,
                    fossilFuelType,
                  }
                )}
              />
            </Box>

            <Box className={classes.specsColumn}>
              <VehicleSpec
                label={formatMessage({id: 'charging.content.vehicles.specs.workdays.label'})}
                value={formatWorkdays({workdays, formatMessage})}
              />
              <VehicleSpec
                label={formatMessage({
                  id: 'charging.content.vehicles.specs.typicalWorkdays.label',
                })}
                value={formatMessage(
                  {
                    id: 'charging.content.vehicles.specs.typicalWorkdays.value',
                  },
                  {
                    milesPerWorkday,
                  }
                )}
              />
              <VehicleSpec
                label={formatMessage({
                  id: 'charging.content.vehicles.specs.chargingWindows.label',
                })}
              >
                {chargingWindows.map((window, idx) => (
                  <ChargingWindow key={idx} chargingWindow={window} />
                ))}
              </VehicleSpec>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
