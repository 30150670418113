import React from 'react';
import TabExplainer from '@generic/components/TabExplainer';
import ChargerIndexedChargingBreakdown from '@generic/pages/ChargingCalculator/ChargerIndexedChargingBreakdown';
import VehicleIndexedChargingBreakdown from '@generic/pages/ChargingCalculator/VehicleIndexedChargingBreakdown';
import {Box, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import HourlyChargerUseChart from '@generic/pages/ChargingCalculator/HourlyChargerUseChart';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '2rem 0',
    [theme.breakpoints.up('lg')]: {
      padding: '2rem',
    },
  },
}));

export default function ChargingChargers() {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  return (
    <Box>
      <TabExplainer
        title={formatMessage({id: 'charging.content.chargers.header'})}
        description={formatMessage({id: 'charging.content.chargers.subheader'})}
      />

      <Box className={classes.section}>
        <VehicleIndexedChargingBreakdown />
      </Box>
      <Box className={classes.section}>
        <ChargerIndexedChargingBreakdown />
      </Box>
      <Box className={classes.section}>
        <HourlyChargerUseChart />
      </Box>
    </Box>
  );
}
