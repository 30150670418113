import sum from 'lodash/sum';
import calcFossilFuelCostForVehicleSet from '@generic/functions/calcFossilFuelCostForVehicleSet';

const calcAnnualFossilFuelCosts = ({vehicleSets, dieselPrice, gasolinePrice, lpgPrice}) =>
  sum(
    vehicleSets.map((vehicleSet) =>
      calcFossilFuelCostForVehicleSet({vehicleSet, dieselPrice, gasolinePrice, lpgPrice})
    )
  );

export default calcAnnualFossilFuelCosts;
