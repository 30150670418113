import React from 'react';
import {Box, Grid, useTheme} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import FleetSummary from '@generic/pages/ChargingCalculator/FleetSummary';
import evIcon from '@generic/assets/images/icon-electric-car.svg';
import barrelIcon from '@generic/assets/images/icon-barrel.svg';
import {formatAsDollars, formatAsCents} from '@generic/functions/formatters';
import calcAnnualFossilFuelCosts from '@generic/functions/calcAnnualFossilFuelCosts';
import calcAnnualVehicleElectricityCosts from '@generic/functions/calcAnnualVehicleElectricityCosts';
import calcPerMileFossilFuelCosts from '@generic/functions/calcPerMileFossilFuelCosts';
import calcPerMileElectricityCosts from '@generic/functions/calcPerMileElectricityCosts';
import calcAnnualFossilFuelMaintenanceCosts from '@generic/functions/calcAnnualFossilFuelMaintenanceCosts';
import calcAnnualElectricityMaintenanceCosts from '@generic/functions/calcAnnualElectricityMaintenanceCosts';
import calcPerMileFossilFuelMaintenanceCosts from '@generic/functions/calcPerMileFossilFuelMaintenanceCosts';
import calcPerMileElectricityMaintenanceCosts from '@generic/functions/calcPerMileElectricityMaintenanceCosts';

export default function FleetCostsSummary() {
  const theme = useTheme();
  const {formatMessage} = useIntl();
  const inputs = useInputs();
  return (
    <Box mt={5}>
      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} md={6}>
          <FleetSummary
            color={theme.palette.primary.main}
            title={formatMessage({id: 'charging.content.overview.fleetSummary.electricHeader'})}
            icon={evIcon}
            annualCostLabel={formatMessage({
              id: 'charging.content.overview.fleetSummary.annualElectricCost',
            })}
            annualCostValue={formatAsDollars(calcAnnualVehicleElectricityCosts(inputs).ev)}
            perMileLabel={formatMessage({
              id: 'charging.content.overview.fleetSummary.electricPerMile',
            })}
            perMileValue={formatAsCents(calcPerMileElectricityCosts(inputs))}
            annualMaintenanceCostValue={formatAsDollars(
              calcAnnualElectricityMaintenanceCosts(inputs)
            )}
            perMileMaintenanceCostValue={formatAsCents(
              calcPerMileElectricityMaintenanceCosts(inputs)
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FleetSummary
            color={theme.palette.common.black}
            title={formatMessage({id: 'charging.content.overview.fleetSummary.fossilFuelHeader'})}
            icon={barrelIcon}
            annualCostLabel={formatMessage({
              id: 'charging.content.overview.fleetSummary.annualFossilFuelCost',
            })}
            annualCostValue={formatAsDollars(calcAnnualFossilFuelCosts(inputs))}
            perMileLabel={formatMessage({
              id: 'charging.content.overview.fleetSummary.fossilFuelPerMile',
            })}
            perMileValue={formatAsCents(calcPerMileFossilFuelCosts(inputs))}
            annualMaintenanceCostValue={formatAsDollars(
              calcAnnualFossilFuelMaintenanceCosts(inputs)
            )}
            perMileMaintenanceCostValue={formatAsCents(
              calcPerMileFossilFuelMaintenanceCosts(inputs)
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
