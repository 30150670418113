import {SEASONS} from '@generic/data/assumptions';
import getMarketRateElements from './shared/getMarketRateElements';
import sharedConEdStaticRateElements from './shared/sharedConEdStaticRateElements';

// Service Classification ("SC") 9
// Rate 1
const RATE_NAME = 'sc9Rate1';

// The prices are the same at both low and high tension, but this format matches the lookup sheet here:
// https://www.coned.com/en/accounts-billing/your-bill/rate-calculators/market-supply-charge
//
// and thus will avoid confusion on updates.
const MSC_ENERGY_CHARGES = {
  low: {
    H: 0.081748,
    I: 0.083699,
    J: 0.084389,
  },
  high: {
    H: 0.081748,
    I: 0.083699,
    J: 0.084389,
  },
};

const getMscCapacityCharge = (isLocatedInWestchester) => (isLocatedInWestchester ? 5.6 : 15.26);

const sc9Rate1 = ({zone, isHighTension, isOnMarketBasedRate, isLocatedInWestchester}) => ({
  name: RATE_NAME,
  title: `SC 9 Rate I`,
  maxKw: 1500,
  rateElements: [
    ...sharedConEdStaticRateElements,
    ...getMarketRateElements(zone, isOnMarketBasedRate),
    {
      rateElementType: 'MonthlyDemand',
      name: 'MSC Capacity Charge',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: getMscCapacityCharge(isOnMarketBasedRate, isLocatedInWestchester),
          name: 'MSC Capacity Charge',
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'MSC Energy Charge',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: isOnMarketBasedRate
            ? 0
            : MSC_ENERGY_CHARGES[isHighTension ? 'high' : 'low'][zone],
          name: 'MSC Energy Charge',
        },
      ],
    },
    {
      rateElementType: 'FixedPerMonth',
      name: 'Customer Charge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: 66.0,
          name: 'Customer Charge',
        },
      ],
    },
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Energy Delivery Charge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: isHighTension ? 0.0168 : 0.0181,
          name: 'Energy Delivery Charge',
        },
      ],
    },
    {
      id: 'demandChargeRebate',
      rateElementType: 'MonthlyDemand',
      name: 'Dynamic Load Management ("DLM") Surcharge',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: 0.32,
          name: 'CSRP & Term-/Auto-DLM Component',
        },
        {
          charge: 0.2,
          name: 'DLC & DLRP Component',
        },
      ],
    },
    {
      id: 'demandChargeRebate',
      rateElementType: 'DemandTiersInMonths',
      name: 'Demand Delivery Charge - over 5kW',
      billingCategory: 'delivery',
      rateComponents: [
        {
          charge: isHighTension
            ? SEASONS.map((season) => (season === 'WINTER' ? 18.83 : 27.08))
            : SEASONS.map((season) => (season === 'WINTER' ? 30.73 : 39.27)),
          min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          max: [
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
            'Infinity',
          ],
          name: 'Demand Delivery Charge',
        },
      ],
    },
  ],
});

export default sc9Rate1;
