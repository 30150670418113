import React from 'react';
import {Box, Grid, Typography, makeStyles} from '@material-ui/core';
import ChargingBreakdownDivider from '@generic/pages/ChargingCalculator/ChargingBreakdownDivider';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  grid: {
    fontStyle: 'italic',
    height: '100%',
  },
  divider: {
    display: 'none',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  timeRangeInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1.5rem',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: 0,
    },
  },
  timeRangeLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    color: theme.palette.gray[500],
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function ChargingTimeRangeBreakdown({timeRange, children}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  return (
    <Box p={4} className={classes.root}>
      <Grid container className={classes.grid}>
        {/* Inner left col */}
        <Grid item xs={12} md={7}>
          {children}
        </Grid>

        {/* Inner divider */}
        <Grid item xs={1}>
          <Box width="100%" className={classes.divider}>
            <ChargingBreakdownDivider />
          </Box>
        </Grid>

        {/* Inner right col */}
        <Grid item xs={12} md={4}>
          <Box className={classes.timeRangeInfo} display="flex" height="100%">
            <Typography className={classes.timeRangeLabel}>
              {formatMessage({id: 'charging.content.chargers.labels.timeRange'})}
            </Typography>
            <Typography variant="body1">{timeRange}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
