import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '500',
    position: 'relative',
    marginBottom: '1rem',
    paddingBottom: '0.5rem',

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      height: 4,
      width: 85,
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default function TabExplainer({title, description}) {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      {description && <Typography>{description}</Typography>}
    </Box>
  );
}
