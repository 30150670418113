import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dialogPaper: {
    margin: 0,
    width: '100%',
    maxWidth: 'none !important',
    borderRadius: '0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 800,
  },
  closeIcon: {
    height: 35,
    width: 35,
  },
}));

export default function InputsDialog({isOpen, onClose, children}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="body"
      className={classes.root}
      PaperProps={{className: classes.dialogPaper}}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className={classes.header} disableTypography>
        <Typography className={classes.title}>
          {formatMessage({id: 'charging.inputs.adjustFilters'})}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
