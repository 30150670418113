import calcBatterySchedule from '@generic/functions/vehicleSet/calcBatterySchedule';

const calcVehicleSetFacilityLoadProfile = ({vehicleSets}) => {
  // hourly charged kWh for every vehicle
  const schedules = vehicleSets.map((set) =>
    calcBatterySchedule(set).map((hour) => hour.chargedKwh)
  );

  return schedules.reduce((sums, current) => sums.map((value, i) => value + current[i]));
};

export default calcVehicleSetFacilityLoadProfile;
