import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import LineItemBill from './LineItemBill';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      marginBottom: '33px',
    },
  },
  rateDisclaimer: {
    padding: '7px 10px',
    border: `1px dashed ${theme.palette.common.black}`,
  },
  rateDisclaimerText: {
    fontSize: 10,
    fontStyle: 'italic',
  },
}));

export default function SupplyCharges({charges, costPerKwh}) {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const total = {
    label: formatMessage({id: 'charging.content.costs.electricity.bill.supplyCharges.total'}),
    charge: charges.reduce((acc, curr) => acc + curr.charge, 0),
  };

  return (
    <Box>
      <Typography variant="body2" className={classes.title}>
        {formatMessage({id: 'charging.content.costs.electricity.bill.supplyCharges.title'})}
      </Typography>
      <LineItemBill charges={charges} total={total} />
      <Box mt={2} className={classes.rateDisclaimer}>
        <Typography className={classes.rateDisclaimerText}>
          {formatMessage(
            {
              id: 'charging.content.costs.electricity.bill.supplyChargesRateDisclaimer',
            },
            {cost: (costPerKwh * 100).toFixed(2)}
          )}
        </Typography>
      </Box>
    </Box>
  );
}
