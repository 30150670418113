const smallHotelLoadProfile = [
  6.35, 6.38, 6.38, 6.28, 6.28, 6.29, 6.45, 6.12, 6.21, 6.29, 6.24, 5.95, 6.51, 6.73, 5.71, 4.15,
  3.58, 4.22, 4.29, 4.26, 4.92, 6.12, 6.4, 6.44, 6.46, 6.5, 6.5, 6.5, 6.53, 6.43, 6.6, 6.26, 6.23,
  6.31, 6.26, 5.98, 6.53, 6.76, 5.73, 4.18, 3.58, 4.22, 4.29, 4.26, 4.92, 6.11, 6.39, 6.42, 6.33,
  6.35, 6.35, 6.34, 6.38, 6.43, 6.57, 6.1, 6.06, 6.15, 6.09, 5.84, 6.49, 6.73, 5.71, 4.18, 3.63,
  4.22, 4.29, 4.26, 4.92, 6.2, 6.32, 6.33, 6.34, 6.37, 6.37, 6.38, 6.42, 6.43, 6.6, 6.28, 6.24, 6.4,
  6.44, 6.16, 6.59, 6.78, 5.74, 4.19, 3.63, 4.22, 4.29, 4.25, 4.91, 6.02, 6.32, 6.37, 7.55, 7.56,
  7.55, 7.55, 7.58, 7.59, 7.6, 7.2, 7.12, 7.22, 7.15, 6.88, 6.79, 6.83, 5.94, 4.36, 3.67, 4.24,
  4.56, 4.42, 6.32, 6.82, 7.53, 7.55, 7.53, 7.53, 7.53, 7.53, 7.53, 7.54, 7.54, 7.14, 7.06, 7.18,
  7.12, 6.85, 6.79, 6.82, 5.91, 4.36, 3.64, 4.26, 4.57, 4.45, 6.31, 6.82, 7.47, 7.48, 6.77, 6.79,
  6.81, 6.82, 6.84, 6.88, 7.09, 6.73, 6.72, 6.66, 6.6, 6.31, 6.62, 6.78, 5.76, 4.18, 3.58, 4.22,
  4.29, 4.26, 4.92, 6.12, 6.4, 6.35, 6.35, 6.38, 6.38, 6.28, 6.28, 6.29, 6.45, 6.12, 6.21, 6.29,
  6.24, 5.95, 6.51, 6.73, 5.71, 4.15, 3.58, 4.22, 4.29, 4.26, 4.92, 6.12, 6.4, 6.44, 6.46, 6.5, 6.5,
  6.5, 6.53, 6.43, 6.6, 6.26, 6.23, 6.31, 6.26, 5.98, 6.53, 6.76, 5.73, 4.18, 3.58, 4.22, 4.29,
  4.26, 4.92, 6.11, 6.39, 6.42, 6.33, 6.35, 6.35, 6.34, 6.38, 6.43, 6.57, 6.1, 6.06, 6.15, 6.09,
  5.84, 6.49, 6.73, 5.71, 4.18, 3.63, 4.22, 4.29, 4.26, 4.92, 6.2, 6.32, 6.33, 6.34, 6.37, 6.37,
  6.38, 6.42, 6.43, 6.6, 6.28, 6.24, 6.4, 6.44, 6.16, 6.59, 6.78, 5.74, 4.19, 3.63, 4.22, 4.29,
  4.25, 4.91, 6.02, 6.32, 6.37, 7.55, 7.56, 7.55, 7.55, 7.58, 7.59, 7.6, 7.2, 7.12, 7.22, 7.15,
  6.88, 6.79, 6.83, 5.94, 4.36, 3.67, 4.24, 4.56, 4.42, 6.32, 6.82, 7.53, 7.55, 7.53, 7.53, 7.53,
  7.53, 7.53, 7.54, 7.54, 7.14, 7.06, 7.18, 7.12, 6.85, 6.79, 6.82, 5.91, 4.36, 3.64, 4.26, 4.57,
  4.45, 6.31, 6.82, 7.47, 7.48, 6.77, 6.79, 6.81, 6.82, 6.84, 6.88, 7.09, 6.73, 6.72, 6.66, 6.6,
  6.31, 6.62, 6.78, 5.76, 4.18, 3.58, 4.22, 4.29, 4.26, 4.92, 6.12, 6.4, 6.35, 6.35, 6.38, 6.38,
  6.28, 6.28, 6.29, 6.45, 6.12, 6.21, 6.29, 6.24, 5.95, 6.51, 6.73, 5.71, 4.15, 3.58, 4.22, 4.29,
  4.26, 4.92, 6.12, 6.4, 6.44, 6.46, 6.5, 6.5, 6.5, 6.53, 6.43, 6.6, 6.26, 6.23, 6.31, 6.26, 5.98,
  6.53, 6.76, 5.73, 4.18, 3.58, 4.22, 4.29, 4.26, 4.92, 6.11, 6.39, 6.42, 6.33, 6.35, 6.35, 6.34,
  6.38, 6.43, 6.57, 6.1, 6.06, 6.15, 6.09, 5.84, 6.49, 6.73, 5.71, 4.18, 3.63, 4.22, 4.29, 4.26,
  4.92, 6.2, 6.32, 6.33, 6.34, 6.37, 6.37, 6.38, 6.42, 6.43, 6.6, 6.28, 6.24, 6.4, 6.44, 6.16, 6.59,
  6.78, 5.74, 4.19, 3.63, 4.22, 4.29, 4.25, 4.91, 6.02, 6.32, 6.37, 7.55, 7.56, 7.55, 7.55, 7.58,
  7.59, 7.6, 7.2, 7.12, 7.22, 7.15, 6.88, 6.79, 6.83, 5.94, 4.36, 3.67, 4.24, 4.56, 4.42, 6.32,
  6.82, 7.53, 7.55, 7.53, 7.53, 7.53, 7.53, 7.53, 7.54, 7.54, 7.14, 7.06, 7.18, 7.12, 6.85, 6.79,
  6.82, 5.91, 4.36, 3.64, 4.26, 4.57, 4.45, 6.31, 6.82, 7.47, 7.48, 6.77, 6.79, 6.81, 6.82, 6.84,
  6.88, 7.09, 6.73, 6.72, 6.66, 6.6, 6.31, 6.62, 6.78, 5.76, 4.18, 3.58, 4.22, 4.29, 4.26, 4.92,
  6.12, 6.4, 6.35, 6.35, 6.38, 6.38, 6.28, 6.28, 6.29, 6.45, 6.12, 6.21, 6.29, 6.24, 5.95, 6.51,
  6.73, 5.71, 4.15, 3.58, 4.22, 4.29, 4.26, 4.92, 6.12, 6.4, 6.44, 6.46, 6.5, 6.5, 6.5, 6.53, 6.43,
  6.6, 6.26, 6.23, 6.31, 6.26, 5.98, 6.53, 6.76, 5.73, 4.18, 3.58, 4.22, 4.29, 4.26, 4.92, 6.11,
  6.39, 6.42, 6.33, 6.35, 6.35, 6.34, 6.38, 6.43, 6.57, 6.1, 6.06, 6.15, 6.09, 5.84, 6.49, 6.73,
  5.71, 4.18, 3.63, 4.22, 4.29, 4.26, 4.92, 6.2, 6.32, 6.33, 6.34, 6.37, 6.37, 6.38, 6.42, 6.43,
  6.6, 6.28, 6.24, 6.4, 6.44, 6.16, 6.59, 6.78, 5.74, 4.19, 3.63, 4.22, 4.29, 4.25, 4.91, 6.02,
  6.32, 6.37, 7.55, 7.56, 7.55, 7.55, 7.58, 7.59, 7.6, 7.2, 7.12, 7.22, 7.15, 6.88, 6.79, 6.83,
  5.94, 4.36, 3.67, 4.24, 4.56, 4.42, 6.32, 6.82, 7.53, 7.55, 7.53, 7.53, 7.53, 7.53, 7.53, 7.54,
  7.54, 7.14, 7.06, 7.18, 7.12, 6.85, 6.79, 6.82, 5.91, 4.36, 3.64, 4.26, 4.57, 4.45, 6.31, 6.82,
  7.47, 7.48, 6.77, 6.79, 6.81, 6.82, 6.84, 6.88, 7.09, 6.73, 6.72, 6.66, 6.6, 6.31, 6.62, 6.78,
  5.76, 4.18, 3.58, 4.22, 4.29, 4.26, 4.92, 6.12, 6.4, 6.35, 6.35, 6.38, 6.38, 6.28, 6.28, 6.29,
  6.45, 6.12, 6.21, 6.29, 6.24, 5.95, 6.51, 6.73, 5.71, 4.15, 3.58, 4.22, 4.29, 4.26, 4.92, 6.12,
  6.4, 6.44, 6.46, 6.5, 6.5, 6.5, 6.53, 6.43, 6.6, 6.26, 6.23, 6.31, 6.26, 5.98, 6.53, 6.76, 5.73,
  4.18, 3.58, 4.22, 4.29, 4.26, 4.92, 6.11, 6.39, 6.42, 6.33, 6.35, 6.35, 6.34, 6.38, 6.43, 6.57,
  6.1, 6.06, 6.15, 6.09, 5.84, 6.49, 6.73, 5.71, 4.18, 3.63, 4.22, 4.29, 4.26, 4.92, 6.2, 6.32,
  6.33, 6.78, 6.81, 6.81, 6.81, 6.83, 6.85, 6.99, 6.66, 6.78, 6.72, 6.59, 6.32, 6.73, 7.01, 5.87,
  4.19, 3.4, 4.06, 4.28, 4.22, 4.85, 6.56, 6.87, 6.92, 7.55, 7.56, 7.56, 7.55, 7.56, 7.58, 7.48,
  7.05, 7.12, 7.22, 7.15, 6.88, 6.88, 6.89, 5.96, 4.36, 3.48, 4.09, 4.56, 4.42, 6.32, 6.83, 7.53,
  7.54, 7.51, 7.53, 7.51, 7.5, 7.53, 7.54, 7.44, 7.01, 7.07, 7.21, 7.14, 6.87, 6.89, 6.96, 6.01,
  4.37, 3.51, 4.13, 4.57, 4.45, 6.32, 6.83, 7.49, 7.49, 6.59, 6.61, 6.61, 6.61, 6.64, 6.66, 6.7,
  6.39, 6.5, 6.59, 6.53, 6.2, 6.61, 6.79, 5.74, 4.15, 3.4, 4.06, 4.28, 4.24, 4.89, 6.39, 6.67, 6.59,
  6.57, 6.59, 6.57, 6.59, 6.6, 6.62, 6.68, 6.37, 6.46, 6.54, 6.4, 6.1, 6.59, 6.79, 5.76, 4.19, 3.41,
  4.03, 4.29, 4.25, 4.91, 6.2, 6.48, 6.53, 6.55, 6.44, 6.42, 6.43, 6.45, 6.49, 6.53, 6.13, 6.28,
  6.45, 6.33, 6.0, 6.5, 6.73, 5.72, 4.17, 3.4, 4.03, 4.29, 4.25, 4.91, 6.22, 6.5, 6.54, 6.57, 6.61,
  6.61, 6.62, 6.65, 6.68, 6.83, 6.46, 6.61, 6.93, 6.76, 6.39, 6.71, 6.9, 5.87, 4.2, 3.4, 4.06, 4.28,
  4.2, 4.84, 6.46, 6.72, 6.76, 6.78, 6.81, 6.81, 6.81, 6.83, 6.85, 6.99, 6.66, 6.78, 6.72, 6.59,
  6.32, 6.73, 7.01, 5.87, 4.19, 3.4, 4.06, 4.28, 4.22, 4.85, 6.56, 6.87, 6.92, 7.55, 7.56, 7.56,
  7.55, 7.56, 7.58, 7.48, 7.05, 7.12, 7.22, 7.15, 6.88, 6.88, 6.89, 5.96, 4.36, 3.48, 4.09, 4.56,
  4.42, 6.32, 6.83, 7.53, 7.54, 7.51, 7.53, 7.51, 7.5, 7.53, 7.54, 7.44, 7.01, 7.07, 7.21, 7.14,
  6.87, 6.89, 6.96, 6.01, 4.37, 3.51, 4.13, 4.57, 4.45, 6.32, 6.83, 7.49, 7.49, 6.59, 6.61, 6.61,
  6.61, 6.64, 6.66, 6.7, 6.39, 6.5, 6.59, 6.53, 6.2, 6.61, 6.79, 5.74, 4.15, 3.4, 4.06, 4.28, 4.24,
  4.89, 6.39, 6.67, 6.59, 6.57, 6.59, 6.57, 6.59, 6.6, 6.62, 6.68, 6.37, 6.46, 6.54, 6.4, 6.1, 6.59,
  6.79, 5.76, 4.19, 3.41, 4.03, 4.29, 4.25, 4.91, 6.2, 6.48, 6.53, 6.55, 6.44, 6.42, 6.43, 6.45,
  6.49, 6.53, 6.13, 6.28, 6.45, 6.33, 6.0, 6.5, 6.73, 5.72, 4.17, 3.4, 4.03, 4.29, 4.25, 4.91, 6.22,
  6.5, 6.54, 6.57, 6.61, 6.61, 6.62, 6.65, 6.68, 6.83, 6.46, 6.61, 6.93, 6.76, 6.39, 6.71, 6.9,
  5.87, 4.2, 3.4, 4.06, 4.28, 4.2, 4.84, 6.46, 6.72, 6.76, 6.78, 6.81, 6.81, 6.81, 6.83, 6.85, 6.99,
  6.66, 6.78, 6.72, 6.59, 6.32, 6.73, 7.01, 5.87, 4.19, 3.4, 4.06, 4.28, 4.22, 4.85, 6.56, 6.87,
  6.92, 7.55, 7.56, 7.56, 7.55, 7.56, 7.58, 7.48, 7.05, 7.12, 7.22, 7.15, 6.88, 6.88, 6.89, 5.96,
  4.36, 3.48, 4.09, 4.56, 4.42, 6.32, 6.83, 7.53, 7.54, 7.51, 7.53, 7.51, 7.5, 7.53, 7.54, 7.44,
  7.01, 7.07, 7.21, 7.14, 6.87, 6.89, 6.96, 6.01, 4.37, 3.51, 4.13, 4.57, 4.45, 6.32, 6.83, 7.49,
  7.49, 6.59, 6.61, 6.61, 6.61, 6.64, 6.66, 6.7, 6.39, 6.5, 6.59, 6.53, 6.2, 6.61, 6.79, 5.74, 4.15,
  3.4, 4.06, 4.28, 4.24, 4.89, 6.39, 6.67, 6.59, 6.57, 6.59, 6.57, 6.59, 6.6, 6.62, 6.68, 6.37,
  6.46, 6.54, 6.4, 6.1, 6.59, 6.79, 5.76, 4.19, 3.41, 4.03, 4.29, 4.25, 4.91, 6.2, 6.48, 6.53, 6.55,
  6.44, 6.42, 6.43, 6.45, 6.49, 6.53, 6.13, 6.28, 6.45, 6.33, 6.0, 6.5, 6.73, 5.72, 4.17, 3.4, 4.03,
  4.29, 4.25, 4.91, 6.22, 6.5, 6.54, 6.57, 6.61, 6.61, 6.62, 6.65, 6.68, 6.83, 6.46, 6.61, 6.93,
  6.76, 6.39, 6.71, 6.9, 5.87, 4.2, 3.4, 4.06, 4.28, 4.2, 4.84, 6.46, 6.72, 6.76, 6.78, 6.81, 6.81,
  6.81, 6.83, 6.85, 6.99, 6.66, 6.78, 6.72, 6.59, 6.32, 6.73, 7.01, 5.87, 4.19, 3.4, 4.06, 4.28,
  4.22, 4.85, 6.56, 6.87, 6.92, 7.55, 7.56, 7.56, 7.55, 7.56, 7.58, 7.48, 7.05, 7.12, 7.22, 7.15,
  6.88, 6.88, 6.89, 5.96, 4.36, 3.48, 4.09, 4.56, 4.42, 6.32, 6.83, 7.53, 7.54, 7.51, 7.53, 7.51,
  7.5, 7.53, 7.54, 7.44, 7.01, 7.07, 7.21, 7.14, 6.87, 6.89, 6.96, 6.01, 4.37, 3.51, 4.13, 4.57,
  4.45, 6.32, 6.83, 7.49, 7.49, 6.59, 6.61, 6.61, 6.61, 6.64, 6.66, 6.7, 6.39, 6.5, 6.59, 6.53, 6.2,
  6.61, 6.79, 5.74, 4.15, 3.4, 4.06, 4.28, 4.24, 4.89, 6.39, 6.67, 6.59, 6.57, 6.59, 6.57, 6.59,
  6.6, 6.62, 6.68, 6.37, 6.46, 6.54, 6.4, 6.1, 6.59, 6.79, 5.76, 4.19, 3.41, 4.03, 4.29, 4.25, 4.91,
  6.2, 6.48, 6.53, 6.55, 6.44, 6.42, 6.43, 6.45, 6.49, 6.53, 6.13, 6.28, 6.45, 6.33, 6.0, 6.5, 6.73,
  5.72, 4.17, 3.4, 4.03, 4.29, 4.25, 4.91, 6.22, 6.5, 6.54, 6.57, 6.61, 6.61, 6.62, 6.65, 6.68,
  6.83, 6.46, 6.61, 6.93, 6.76, 6.39, 6.71, 6.9, 5.87, 4.2, 3.4, 4.06, 4.28, 4.2, 4.84, 6.46, 6.72,
  6.76, 6.88, 6.99, 7.0, 7.03, 7.05, 7.2, 6.9, 6.82, 6.68, 6.84, 6.83, 7.09, 7.59, 6.98, 5.41, 4.13,
  3.81, 3.93, 4.28, 4.59, 5.73, 6.62, 6.77, 6.81, 7.66, 7.67, 7.66, 7.66, 7.67, 7.62, 7.22, 7.2,
  7.28, 7.29, 7.16, 7.05, 7.07, 6.6, 5.11, 3.85, 3.58, 3.98, 4.5, 5.63, 6.72, 7.37, 7.66, 7.67,
  7.64, 7.61, 7.6, 7.61, 7.62, 7.59, 7.18, 7.14, 7.26, 7.23, 7.04, 7.05, 7.23, 6.66, 5.24, 4.06,
  3.81, 4.11, 4.47, 5.94, 6.79, 7.47, 7.62, 7.62, 6.83, 6.84, 6.84, 6.87, 6.88, 7.06, 6.73, 6.77,
  6.77, 6.43, 6.02, 6.51, 6.94, 6.23, 4.66, 3.57, 3.54, 3.75, 4.22, 4.67, 6.17, 6.82, 6.92, 6.95,
  6.96, 6.98, 6.87, 6.85, 6.89, 7.04, 6.72, 6.76, 6.76, 6.66, 6.54, 7.06, 7.44, 6.72, 5.19, 4.13,
  4.06, 4.08, 4.39, 4.78, 6.0, 6.48, 6.56, 6.61, 6.76, 6.79, 6.81, 6.82, 6.84, 6.99, 6.66, 6.7,
  6.72, 6.4, 6.07, 6.49, 6.93, 6.16, 4.61, 3.57, 3.54, 3.74, 4.2, 4.67, 6.18, 6.71, 6.77, 6.79,
  6.83, 6.73, 6.73, 6.76, 6.78, 6.9, 6.6, 6.62, 6.53, 6.32, 6.22, 6.84, 7.36, 6.83, 5.34, 3.96,
  3.62, 3.84, 4.22, 4.57, 5.74, 6.62, 6.77, 6.81, 6.88, 6.99, 7.0, 7.03, 7.05, 7.2, 6.9, 6.82, 6.68,
  6.84, 6.83, 7.09, 7.59, 6.98, 5.41, 4.13, 3.81, 3.93, 4.28, 4.59, 5.73, 6.62, 6.77, 6.81, 7.66,
  7.67, 7.66, 7.66, 7.67, 7.62, 7.22, 7.2, 7.28, 7.29, 7.16, 7.05, 7.07, 6.6, 5.11, 3.85, 3.58,
  3.98, 4.5, 5.63, 6.72, 7.37, 7.66, 7.67, 7.64, 7.61, 7.61, 7.62, 7.59, 7.18, 7.14, 7.26, 7.23,
  7.04, 7.05, 7.23, 6.66, 5.24, 4.06, 3.81, 4.11, 4.47, 5.94, 6.79, 7.47, 7.62, 7.62, 6.83, 6.84,
  6.84, 6.87, 6.88, 7.06, 6.73, 6.77, 6.77, 6.43, 6.02, 6.51, 6.94, 6.23, 4.66, 3.57, 3.54, 3.75,
  4.22, 4.67, 6.17, 6.82, 6.92, 6.95, 6.96, 6.98, 6.87, 6.85, 6.89, 7.04, 6.72, 6.76, 6.76, 6.66,
  6.54, 7.06, 7.44, 6.72, 5.19, 4.13, 4.06, 4.08, 4.39, 4.78, 6.0, 6.48, 6.56, 6.61, 6.76, 6.79,
  6.81, 6.82, 6.84, 6.99, 6.66, 6.7, 6.72, 6.4, 6.07, 6.49, 6.93, 6.16, 4.61, 3.57, 3.54, 3.74, 4.2,
  4.67, 6.18, 6.71, 6.77, 6.79, 6.83, 6.73, 6.73, 6.76, 6.78, 6.9, 6.6, 6.62, 6.53, 6.32, 6.22,
  6.84, 7.36, 6.83, 5.34, 3.96, 3.62, 3.84, 4.22, 4.57, 5.74, 6.62, 6.77, 6.81, 6.88, 6.99, 7.0,
  7.03, 7.05, 7.2, 6.9, 6.82, 6.68, 6.84, 6.83, 7.09, 7.59, 6.98, 5.41, 4.13, 3.81, 3.93, 4.28,
  4.59, 5.73, 6.62, 6.77, 6.81, 7.66, 7.67, 7.66, 7.66, 7.67, 7.62, 7.22, 7.2, 7.28, 7.29, 7.16,
  7.05, 7.07, 6.6, 5.11, 3.85, 3.58, 3.98, 4.5, 5.63, 6.72, 7.37, 7.66, 7.67, 7.64, 7.61, 7.6, 7.61,
  7.62, 7.59, 7.18, 7.14, 7.26, 7.23, 7.04, 7.05, 7.23, 6.66, 5.24, 4.06, 3.81, 4.11, 4.47, 5.94,
  6.79, 7.47, 7.62, 7.62, 6.83, 6.84, 6.84, 6.87, 6.88, 7.06, 6.73, 6.77, 6.77, 6.43, 6.02, 6.51,
  6.94, 6.23, 4.66, 3.57, 3.54, 3.75, 4.22, 4.67, 6.17, 6.82, 6.92, 6.95, 6.96, 6.98, 6.87, 6.85,
  6.89, 7.04, 6.72, 6.76, 6.76, 6.66, 6.54, 7.06, 7.44, 6.72, 5.19, 4.13, 4.06, 4.08, 4.39, 4.78,
  6.0, 6.48, 6.56, 6.61, 6.76, 6.79, 6.81, 6.82, 6.84, 6.99, 6.66, 6.7, 6.72, 6.4, 6.07, 6.49, 6.93,
  6.16, 4.61, 3.57, 3.54, 3.74, 4.2, 4.67, 6.18, 6.71, 6.77, 6.79, 6.83, 6.73, 6.73, 6.76, 6.78,
  6.9, 6.6, 6.62, 6.53, 6.32, 6.22, 6.84, 7.36, 6.83, 5.34, 3.96, 3.62, 3.84, 4.22, 4.57, 5.74,
  6.62, 6.77, 6.81, 6.88, 6.99, 7.0, 7.03, 7.05, 7.2, 6.9, 6.82, 6.68, 6.84, 6.83, 7.09, 7.59, 6.98,
  5.41, 4.13, 3.81, 3.93, 4.28, 4.59, 5.73, 6.62, 6.77, 6.81, 7.66, 7.67, 7.66, 7.66, 7.67, 7.62,
  7.22, 7.2, 7.28, 7.29, 7.16, 7.05, 7.07, 6.6, 5.11, 3.85, 3.58, 3.98, 4.5, 5.63, 6.72, 7.37, 7.66,
  7.67, 7.64, 7.61, 7.6, 7.61, 7.62, 7.59, 7.18, 7.14, 7.26, 7.23, 7.04, 7.05, 7.23, 6.66, 5.24,
  4.06, 3.81, 4.11, 4.47, 5.94, 6.79, 7.47, 7.62, 7.62, 6.83, 6.84, 6.84, 6.87, 6.88, 7.06, 6.73,
  6.77, 6.77, 6.43, 6.02, 6.51, 6.94, 6.23, 4.66, 3.57, 3.54, 3.75, 4.22, 4.67, 6.17, 6.82, 6.92,
  6.95, 6.96, 6.98, 6.87, 6.85, 6.89, 7.04, 6.72, 6.76, 6.76, 6.66, 6.54, 7.06, 7.44, 6.72, 5.19,
  4.13, 4.06, 4.08, 4.39, 4.78, 6.0, 6.48, 6.56, 6.61, 6.76, 6.79, 6.81, 6.82, 6.84, 6.99, 6.66,
  6.7, 6.72, 6.4, 6.07, 6.49, 6.93, 6.16, 4.61, 3.57, 3.54, 3.74, 4.2, 4.67, 6.18, 6.71, 6.77, 6.79,
  6.83, 6.73, 6.73, 6.76, 6.78, 6.9, 6.6, 6.62, 6.53, 6.32, 6.22, 6.84, 7.36, 6.83, 5.34, 3.96,
  3.62, 3.84, 4.22, 4.57, 5.74, 6.62, 6.77, 6.81, 6.88, 6.99, 7.0, 7.03, 7.05, 7.2, 6.9, 6.82, 6.68,
  6.84, 6.83, 7.09, 7.59, 6.98, 5.41, 4.13, 3.81, 3.93, 4.28, 4.59, 5.73, 6.62, 6.77, 6.81, 7.66,
  7.67, 7.66, 7.66, 7.67, 7.62, 7.22, 7.2, 7.28, 7.29, 7.16, 7.05, 7.07, 6.6, 5.11, 3.85, 3.58,
  3.98, 4.5, 5.63, 6.72, 7.37, 7.66, 7.67, 7.64, 7.61, 7.6, 7.61, 7.62, 7.59, 7.18, 7.14, 7.26,
  7.23, 7.04, 7.05, 7.23, 6.66, 5.24, 4.06, 3.81, 4.11, 4.47, 5.94, 6.79, 7.47, 7.62, 7.62, 6.98,
  7.0, 7.01, 7.06, 7.09, 6.93, 6.81, 6.7, 6.56, 6.59, 6.6, 7.72, 8.28, 7.25, 5.54, 4.52, 4.46, 4.18,
  4.19, 4.96, 6.43, 6.77, 6.82, 6.92, 7.0, 7.01, 7.01, 7.05, 7.07, 6.95, 6.89, 6.78, 6.57, 6.54,
  6.43, 7.34, 7.76, 6.64, 4.83, 3.8, 3.81, 3.7, 3.97, 4.85, 6.59, 6.93, 6.94, 6.94, 6.89, 6.87, 6.9,
  7.05, 7.07, 6.92, 6.83, 6.78, 6.65, 6.61, 6.46, 6.99, 7.28, 6.28, 4.56, 3.65, 3.76, 3.75, 3.97,
  4.84, 6.62, 6.93, 6.98, 6.99, 7.0, 7.0, 7.01, 7.05, 7.07, 6.9, 6.89, 6.68, 6.73, 6.92, 6.82, 7.84,
  8.17, 7.05, 5.18, 4.04, 4.02, 3.91, 4.02, 4.83, 6.43, 6.78, 6.93, 7.0, 7.01, 7.01, 7.03, 7.05,
  7.09, 6.92, 6.83, 6.81, 6.82, 6.92, 7.11, 8.25, 8.86, 7.81, 5.95, 4.63, 4.5, 4.28, 4.4, 5.19,
  6.51, 6.78, 6.77, 6.83, 7.99, 7.94, 7.86, 7.82, 7.81, 7.38, 7.43, 7.72, 8.13, 8.53, 8.48, 8.53,
  8.87, 7.93, 5.85, 4.59, 4.5, 4.59, 4.62, 6.82, 7.21, 7.93, 7.93, 7.91, 7.76, 7.69, 7.67, 7.69,
  7.7, 7.31, 7.17, 7.23, 7.4, 7.48, 7.4, 7.47, 7.69, 6.85, 5.14, 4.17, 4.24, 4.28, 4.3, 6.44, 6.96,
  7.7, 7.71, 7.72, 6.98, 7.0, 7.01, 7.06, 7.09, 6.93, 6.81, 6.7, 6.56, 6.59, 6.6, 7.72, 8.28, 7.25,
  5.54, 4.52, 4.46, 4.18, 4.19, 4.96, 6.43, 6.77, 6.82, 6.92, 7.0, 7.01, 7.01, 7.05, 7.07, 6.95,
  6.89, 6.78, 6.57, 6.54, 6.43, 7.34, 7.76, 6.64, 4.83, 3.8, 3.81, 3.7, 3.97, 4.85, 6.59, 6.93,
  6.94, 6.94, 6.89, 6.87, 6.9, 7.05, 7.07, 6.92, 6.83, 6.78, 6.65, 6.61, 6.46, 6.99, 7.28, 6.28,
  4.56, 3.65, 3.76, 3.75, 3.97, 4.84, 6.62, 6.93, 6.98, 6.99, 7.0, 7.0, 7.01, 7.05, 7.07, 6.9, 6.89,
  6.68, 6.73, 6.92, 6.82, 7.84, 8.17, 7.05, 5.18, 4.04, 4.02, 3.91, 4.02, 4.83, 6.43, 6.78, 6.93,
  7.0, 7.01, 7.01, 7.03, 7.05, 7.09, 6.92, 6.83, 6.81, 6.82, 6.92, 7.11, 8.25, 8.86, 7.81, 5.95,
  4.63, 4.5, 4.28, 4.4, 5.19, 6.51, 6.78, 6.77, 6.83, 7.99, 7.94, 7.86, 7.82, 7.81, 7.38, 7.43,
  7.72, 8.13, 8.53, 8.48, 8.53, 8.87, 7.93, 5.85, 4.59, 4.5, 4.59, 4.62, 6.82, 7.21, 7.93, 7.93,
  7.91, 7.76, 7.69, 7.67, 7.69, 7.7, 7.31, 7.17, 7.23, 7.4, 7.48, 7.4, 7.47, 7.69, 6.85, 5.14, 4.17,
  4.24, 4.28, 4.3, 6.44, 6.96, 7.7, 7.71, 7.72, 6.98, 7.0, 7.01, 7.06, 7.09, 6.93, 6.81, 6.7, 6.56,
  6.59, 6.6, 7.72, 8.28, 7.25, 5.54, 4.52, 4.46, 4.18, 4.19, 4.96, 6.43, 6.77, 6.82, 6.92, 7.0,
  7.01, 7.01, 7.05, 7.07, 6.95, 6.89, 6.78, 6.57, 6.54, 6.43, 7.34, 7.76, 6.64, 4.83, 3.8, 3.81,
  3.7, 3.97, 4.85, 6.59, 6.93, 6.94, 6.94, 6.89, 6.87, 6.9, 7.05, 7.07, 6.92, 6.83, 6.78, 6.65,
  6.61, 6.46, 6.99, 7.28, 6.28, 4.56, 3.65, 3.76, 3.75, 3.97, 4.84, 6.62, 6.93, 6.98, 6.99, 7.0,
  7.0, 7.01, 7.05, 7.07, 6.9, 6.89, 6.68, 6.73, 6.92, 6.82, 7.84, 8.17, 7.05, 5.18, 4.04, 4.02,
  3.91, 4.02, 4.83, 6.43, 6.78, 6.93, 7.0, 7.01, 7.01, 7.03, 7.05, 7.09, 6.92, 6.83, 6.81, 6.82,
  6.92, 7.11, 8.25, 8.86, 7.81, 5.95, 4.63, 4.5, 4.28, 4.4, 5.19, 6.51, 6.78, 6.77, 6.83, 7.99,
  7.94, 7.86, 7.82, 7.81, 7.38, 7.43, 7.72, 8.13, 8.53, 8.48, 8.53, 8.87, 7.93, 5.85, 4.59, 4.5,
  4.59, 4.62, 6.82, 7.21, 7.93, 7.93, 7.91, 7.76, 7.69, 7.67, 7.69, 7.7, 7.31, 7.17, 7.23, 7.4,
  7.48, 7.4, 7.47, 7.69, 6.85, 5.14, 4.17, 4.24, 4.28, 4.3, 6.44, 6.96, 7.7, 7.71, 7.72, 6.98, 7.0,
  7.01, 7.06, 7.09, 6.93, 6.81, 6.7, 6.56, 6.59, 6.6, 7.72, 8.28, 7.25, 5.54, 4.52, 4.46, 4.18,
  4.19, 4.96, 6.43, 6.77, 6.82, 6.92, 7.0, 7.01, 7.01, 7.05, 7.07, 6.95, 6.89, 6.78, 6.57, 6.54,
  6.43, 7.34, 7.76, 6.64, 4.83, 3.8, 3.81, 3.7, 3.97, 4.85, 6.59, 6.93, 6.94, 6.94, 6.89, 6.87, 6.9,
  7.05, 7.07, 6.92, 6.83, 6.78, 6.65, 6.61, 6.46, 6.99, 7.28, 6.28, 4.56, 3.65, 3.76, 3.75, 3.97,
  4.84, 6.62, 6.93, 6.98, 6.99, 7.0, 7.0, 7.01, 7.05, 7.07, 6.9, 6.89, 6.68, 6.73, 6.92, 6.82, 7.84,
  8.17, 7.05, 5.18, 4.04, 4.02, 3.91, 4.02, 4.83, 6.43, 6.78, 6.93, 7.0, 7.01, 7.01, 7.03, 7.05,
  7.09, 6.92, 6.83, 6.81, 6.82, 6.92, 7.11, 8.25, 8.86, 7.81, 5.95, 4.63, 4.5, 4.28, 4.4, 5.19,
  6.51, 6.78, 6.77, 6.83, 7.99, 7.94, 7.86, 7.82, 7.81, 7.38, 7.43, 7.72, 8.13, 8.53, 8.48, 8.53,
  8.87, 7.93, 5.85, 4.59, 4.5, 4.59, 4.62, 6.82, 7.21, 7.93, 7.93, 7.91, 7.76, 7.69, 7.67, 7.69,
  7.7, 7.31, 7.17, 7.23, 7.4, 7.48, 7.4, 7.47, 7.69, 6.85, 5.14, 4.17, 4.24, 4.28, 4.3, 6.44, 6.96,
  7.7, 7.71, 7.72, 6.98, 7.0, 7.01, 7.06, 7.09, 6.93, 6.81, 6.7, 6.56, 6.59, 6.6, 7.72, 8.28, 7.25,
  5.54, 4.52, 4.46, 4.18, 4.19, 4.96, 6.43, 6.77, 6.82, 6.92, 7.0, 7.01, 7.01, 7.05, 7.07, 6.95,
  6.89, 6.78, 6.57, 6.54, 6.43, 7.34, 7.76, 6.64, 4.83, 3.8, 3.81, 3.7, 3.97, 4.85, 6.59, 6.93,
  6.94, 6.94, 6.89, 6.84, 6.83, 6.85, 6.67, 6.75, 7.0, 7.1, 7.56, 8.37, 8.85, 9.91, 10.46, 9.48,
  7.6, 6.5, 6.28, 5.72, 4.99, 5.68, 6.55, 6.84, 6.89, 6.89, 6.88, 6.89, 6.93, 6.99, 6.81, 6.75,
  6.82, 7.2, 7.81, 8.6, 9.23, 10.41, 10.91, 9.81, 7.75, 6.46, 6.27, 5.88, 5.35, 6.1, 6.92, 7.0,
  6.88, 6.78, 6.71, 6.71, 6.7, 6.72, 6.55, 6.45, 6.56, 6.92, 7.4, 7.88, 8.03, 8.72, 9.02, 7.93,
  6.15, 4.85, 4.47, 4.2, 3.95, 4.91, 6.1, 6.46, 6.59, 6.78, 7.82, 7.81, 7.81, 7.84, 7.65, 7.31, 7.4,
  7.62, 8.04, 8.36, 8.42, 8.53, 8.58, 7.51, 5.58, 4.29, 4.19, 4.31, 4.11, 6.61, 7.06, 7.81, 7.81,
  7.8, 7.76, 7.76, 7.75, 7.76, 7.55, 7.2, 7.22, 7.27, 7.48, 7.51, 7.39, 7.58, 7.83, 6.93, 5.21,
  4.22, 4.26, 4.33, 3.98, 6.49, 7.03, 7.76, 7.76, 7.77, 6.89, 6.93, 6.94, 6.96, 6.76, 6.65, 6.67,
  6.42, 6.84, 7.61, 8.14, 9.4, 9.98, 9.08, 7.42, 6.16, 5.79, 5.25, 4.67, 5.49, 6.37, 6.75, 6.81,
  6.84, 6.81, 6.77, 6.78, 6.83, 6.71, 6.61, 6.7, 6.99, 7.32, 7.89, 8.28, 9.68, 10.41, 9.65, 7.77,
  6.44, 6.0, 5.39, 4.74, 5.52, 6.39, 6.7, 6.84, 6.93, 6.89, 6.84, 6.83, 6.85, 6.67, 6.75, 7.0, 7.1,
  7.56, 8.37, 8.85, 9.91, 10.46, 9.48, 7.6, 6.5, 6.28, 5.72, 4.99, 5.68, 6.55, 6.84, 6.89, 6.89,
  6.88, 6.89, 6.93, 6.99, 6.81, 6.75, 6.82, 7.2, 7.81, 8.6, 9.23, 10.41, 10.91, 9.81, 7.75, 6.46,
  6.27, 5.88, 5.35, 6.1, 6.92, 7.0, 6.88, 6.78, 6.71, 6.71, 6.7, 6.72, 6.55, 6.45, 6.56, 6.92, 7.4,
  7.88, 8.03, 8.72, 9.02, 7.93, 6.15, 4.85, 4.47, 4.2, 3.95, 4.91, 6.1, 6.46, 6.59, 6.78, 7.82,
  7.81, 7.81, 7.84, 7.65, 7.31, 7.4, 7.62, 8.04, 8.36, 8.42, 8.53, 8.58, 7.51, 5.58, 4.29, 4.19,
  4.31, 4.11, 6.61, 7.06, 7.81, 7.81, 7.8, 7.76, 7.76, 7.75, 7.76, 7.55, 7.2, 7.22, 7.27, 7.48,
  7.51, 7.39, 7.58, 7.83, 6.93, 5.21, 4.22, 4.26, 4.33, 3.98, 6.49, 7.03, 7.76, 7.76, 7.77, 6.89,
  6.93, 6.94, 6.96, 6.76, 6.65, 6.67, 6.42, 6.84, 7.61, 8.14, 9.4, 9.98, 9.08, 7.42, 6.16, 5.79,
  5.25, 4.67, 5.49, 6.37, 6.75, 6.81, 6.84, 6.81, 6.77, 6.78, 6.83, 6.71, 6.61, 6.7, 6.99, 7.32,
  7.89, 8.28, 9.68, 10.41, 9.65, 7.77, 6.44, 6.0, 5.39, 4.74, 5.52, 6.39, 6.7, 6.84, 6.93, 6.89,
  6.84, 6.83, 6.85, 6.67, 6.75, 7.0, 7.1, 7.56, 8.37, 8.85, 9.91, 10.46, 9.48, 7.6, 6.5, 6.28, 5.72,
  4.99, 5.68, 6.55, 6.84, 6.89, 6.89, 6.88, 6.89, 6.93, 6.99, 6.81, 6.75, 6.82, 7.2, 7.81, 8.6,
  9.23, 10.41, 10.91, 9.81, 7.75, 6.46, 6.27, 5.88, 5.35, 6.1, 6.92, 7.0, 6.88, 6.78, 6.71, 6.71,
  6.7, 6.72, 6.55, 6.45, 6.56, 6.92, 7.4, 7.88, 8.03, 8.72, 9.02, 7.93, 6.15, 4.85, 4.47, 4.2, 3.95,
  4.91, 6.1, 6.46, 6.59, 6.78, 7.82, 7.81, 7.81, 7.84, 7.65, 7.31, 7.4, 7.62, 8.04, 8.36, 8.42,
  8.53, 8.58, 7.51, 5.58, 4.29, 4.19, 4.31, 4.11, 6.61, 7.06, 7.81, 7.81, 7.8, 7.76, 7.76, 7.75,
  7.76, 7.55, 7.2, 7.22, 7.27, 7.48, 7.51, 7.39, 7.58, 7.83, 6.93, 5.21, 4.22, 4.26, 4.33, 3.98,
  6.49, 7.03, 7.76, 7.76, 7.77, 6.89, 6.93, 6.94, 6.96, 6.76, 6.65, 6.67, 6.42, 6.84, 7.61, 8.14,
  9.4, 9.98, 9.08, 7.42, 6.16, 5.79, 5.25, 4.67, 5.49, 6.37, 6.75, 6.81, 6.84, 6.81, 6.77, 6.78,
  6.83, 6.71, 6.61, 6.7, 6.99, 7.32, 7.89, 8.28, 9.68, 10.41, 9.65, 7.77, 6.44, 6.0, 5.39, 4.74,
  5.52, 6.39, 6.7, 6.84, 6.93, 6.89, 6.84, 6.83, 6.85, 6.67, 6.75, 7.0, 7.1, 7.56, 8.37, 8.85, 9.91,
  10.46, 9.48, 7.6, 6.5, 6.28, 5.72, 4.99, 5.68, 6.55, 6.84, 6.89, 6.89, 6.88, 6.89, 6.93, 6.99,
  6.81, 6.75, 6.82, 7.2, 7.81, 8.6, 9.23, 10.41, 10.91, 9.81, 7.75, 6.46, 6.27, 5.88, 5.35, 6.1,
  6.92, 7.0, 6.88, 6.78, 6.71, 6.71, 6.7, 6.72, 6.55, 6.45, 6.56, 6.92, 7.4, 7.88, 8.03, 8.72, 9.02,
  7.93, 6.15, 4.85, 4.47, 4.2, 3.95, 4.91, 6.1, 6.46, 6.59, 6.78, 7.82, 7.81, 7.81, 7.84, 7.65,
  7.31, 7.4, 7.62, 8.04, 8.36, 8.42, 8.53, 8.58, 7.51, 5.58, 4.29, 4.19, 4.31, 4.11, 6.61, 7.06,
  7.81, 7.81, 7.8, 7.76, 7.76, 7.75, 7.76, 7.55, 7.2, 7.22, 7.27, 7.48, 7.51, 7.39, 7.58, 7.83,
  6.93, 5.21, 4.22, 4.26, 4.33, 3.98, 6.49, 7.03, 7.76, 7.76, 7.77, 6.89, 6.93, 6.94, 6.96, 6.76,
  6.65, 6.67, 6.42, 6.84, 7.61, 8.14, 9.4, 9.98, 9.08, 7.42, 6.16, 5.79, 5.25, 4.67, 5.49, 6.37,
  6.75, 6.81, 6.84, 6.81, 6.77, 6.78, 6.83, 6.71, 6.61, 6.7, 6.99, 7.32, 7.89, 8.28, 9.68, 10.41,
  9.65, 7.77, 6.44, 6.0, 5.39, 4.74, 5.52, 6.39, 6.7, 6.84, 6.93, 6.89, 6.84, 6.83, 6.85, 6.67,
  6.75, 7.0, 7.1, 7.56, 8.37, 8.85, 9.91, 10.46, 9.48, 7.6, 6.5, 6.28, 5.72, 4.99, 5.68, 6.55, 6.84,
  6.89, 6.89, 6.88, 6.89, 6.93, 6.99, 6.81, 6.75, 6.82, 7.2, 7.81, 8.6, 9.23, 10.41, 10.91, 9.81,
  7.75, 6.46, 6.27, 5.88, 5.35, 6.1, 6.92, 7.0, 6.88, 6.78, 6.71, 6.71, 6.7, 6.72, 6.55, 6.45, 6.56,
  6.92, 7.4, 7.88, 8.03, 8.72, 9.02, 7.93, 6.15, 4.85, 4.47, 4.2, 3.95, 4.91, 6.1, 6.46, 6.59, 6.78,
  8.52, 8.49, 8.48, 8.5, 8.16, 8.09, 8.38, 8.94, 9.64, 10.26, 10.62, 11.21, 11.64, 10.89, 8.93,
  7.69, 7.48, 7.2, 6.34, 8.41, 8.66, 9.3, 9.18, 9.09, 8.81, 8.7, 8.59, 8.54, 8.17, 8.1, 8.47, 9.1,
  9.95, 10.55, 10.91, 11.06, 10.75, 9.93, 8.37, 7.06, 6.67, 6.33, 5.6, 7.77, 8.08, 8.69, 8.55, 8.46,
  6.49, 6.44, 6.42, 6.42, 6.07, 6.26, 6.76, 7.42, 8.15, 8.81, 8.76, 9.52, 9.87, 8.77, 6.92, 5.54,
  5.08, 4.68, 4.33, 5.23, 6.2, 6.44, 6.44, 6.59, 6.7, 6.75, 6.77, 6.78, 6.46, 6.54, 6.65, 7.06,
  7.86, 8.76, 9.35, 10.5, 11.17, 10.04, 7.99, 6.32, 5.96, 5.56, 5.0, 5.58, 6.33, 6.55, 6.49, 6.45,
  6.46, 6.53, 6.61, 6.72, 6.39, 6.29, 6.56, 7.32, 8.43, 9.23, 9.49, 10.61, 11.08, 9.95, 7.87, 6.33,
  6.07, 5.67, 5.1, 5.83, 6.71, 6.93, 6.87, 6.81, 6.71, 6.65, 6.59, 6.55, 6.15, 6.43, 6.87, 7.54,
  8.33, 8.75, 8.96, 10.04, 10.53, 9.57, 7.58, 6.37, 6.31, 5.93, 5.35, 6.0, 6.84, 7.01, 6.95, 6.82,
  6.66, 6.53, 6.49, 6.49, 6.16, 6.28, 6.75, 7.62, 8.63, 9.51, 10.03, 10.78, 11.18, 10.18, 8.25,
  6.83, 6.48, 6.0, 5.4, 6.1, 6.87, 6.94, 6.81, 6.73, 8.52, 8.49, 8.48, 8.5, 8.16, 8.09, 8.38, 8.94,
  9.64, 10.26, 10.62, 11.21, 11.64, 10.89, 8.93, 7.69, 7.48, 7.2, 6.34, 8.41, 8.66, 9.3, 9.18, 9.09,
  8.81, 8.7, 8.59, 8.54, 8.17, 8.1, 8.47, 9.1, 9.95, 10.55, 10.91, 11.06, 10.75, 9.93, 8.37, 7.06,
  6.67, 6.33, 5.6, 7.77, 8.08, 8.69, 8.55, 8.46, 6.49, 6.44, 6.42, 6.42, 6.07, 6.26, 6.76, 7.42,
  8.15, 8.81, 8.76, 9.52, 9.87, 8.77, 6.92, 5.54, 5.08, 4.68, 4.33, 5.23, 6.2, 6.44, 6.44, 6.59,
  6.7, 6.75, 6.77, 6.78, 6.46, 6.54, 6.65, 7.06, 7.86, 8.76, 9.35, 10.5, 11.17, 10.04, 7.99, 6.32,
  5.96, 5.56, 5.0, 5.58, 6.33, 6.55, 6.49, 6.45, 6.46, 6.53, 6.61, 6.72, 6.39, 6.29, 6.56, 7.32,
  8.43, 9.23, 9.49, 10.61, 11.08, 9.95, 7.87, 6.33, 6.07, 5.67, 5.1, 5.83, 6.71, 6.93, 6.87, 6.81,
  6.71, 6.65, 6.59, 6.55, 6.15, 6.43, 6.87, 7.54, 8.33, 8.75, 8.96, 10.04, 10.53, 9.57, 7.58, 6.37,
  6.31, 5.93, 5.35, 6.0, 6.84, 7.01, 6.95, 6.82, 6.66, 6.53, 6.49, 6.49, 6.16, 6.28, 6.75, 7.62,
  8.63, 9.51, 10.03, 10.78, 11.18, 10.18, 8.25, 6.83, 6.48, 6.0, 5.4, 6.1, 6.87, 6.94, 6.81, 6.73,
  8.52, 8.49, 8.48, 8.5, 8.16, 8.09, 8.38, 8.94, 9.64, 10.26, 10.62, 11.21, 11.64, 10.89, 8.93,
  7.69, 7.48, 7.2, 6.34, 8.41, 8.66, 9.3, 9.18, 9.09, 8.81, 8.7, 8.59, 8.54, 8.17, 8.1, 8.47, 9.1,
  9.95, 10.55, 10.91, 11.06, 10.75, 9.93, 8.37, 7.06, 6.67, 6.33, 5.6, 7.77, 8.08, 8.69, 8.55, 8.46,
  6.49, 6.44, 6.42, 6.42, 6.07, 6.26, 6.76, 7.42, 8.15, 8.81, 8.76, 9.52, 9.87, 8.77, 6.92, 5.54,
  5.08, 4.68, 4.33, 5.23, 6.2, 6.44, 6.44, 6.59, 6.7, 6.75, 6.77, 6.78, 6.46, 6.54, 6.65, 7.06,
  7.86, 8.76, 9.35, 10.5, 11.17, 10.04, 7.99, 6.32, 5.96, 5.56, 5.0, 5.58, 6.33, 6.55, 6.49, 6.45,
  6.46, 6.53, 6.61, 6.72, 6.39, 6.29, 6.56, 7.32, 8.43, 9.23, 9.49, 10.61, 11.08, 9.95, 7.87, 6.33,
  6.07, 5.67, 5.1, 5.83, 6.71, 6.93, 6.87, 6.81, 6.71, 6.65, 6.59, 6.55, 6.15, 6.43, 6.87, 7.54,
  8.33, 8.75, 8.96, 10.04, 10.53, 9.57, 7.58, 6.37, 6.31, 5.93, 5.35, 6.0, 6.84, 7.01, 6.95, 6.82,
  6.66, 6.53, 6.49, 6.49, 6.16, 6.28, 6.75, 7.62, 8.63, 9.51, 10.03, 10.78, 11.18, 10.18, 8.25,
  6.83, 6.48, 6.0, 5.4, 6.1, 6.87, 6.94, 6.81, 6.73, 8.52, 8.49, 8.48, 8.5, 8.16, 8.09, 8.38, 8.94,
  9.64, 10.26, 10.62, 11.21, 11.64, 10.89, 8.93, 7.69, 7.48, 7.2, 6.34, 8.41, 8.66, 9.3, 9.18, 9.09,
  8.81, 8.7, 8.59, 8.54, 8.17, 8.1, 8.47, 9.1, 9.95, 10.55, 10.91, 11.06, 10.75, 9.93, 8.37, 7.06,
  6.67, 6.33, 5.6, 7.77, 8.08, 8.69, 8.55, 8.46, 6.49, 6.44, 6.42, 6.42, 6.07, 6.26, 6.76, 7.42,
  8.15, 8.81, 8.76, 9.52, 9.87, 8.77, 6.92, 5.54, 5.08, 4.68, 4.33, 5.23, 6.2, 6.44, 6.44, 6.59,
  6.7, 6.75, 6.77, 6.78, 6.46, 6.54, 6.65, 7.06, 7.86, 8.76, 9.35, 10.5, 11.17, 10.04, 7.99, 6.32,
  5.96, 5.56, 5.0, 5.58, 6.33, 6.55, 6.49, 6.45, 6.46, 6.53, 6.61, 6.72, 6.39, 6.29, 6.56, 7.32,
  8.43, 9.23, 9.49, 10.61, 11.08, 9.95, 7.87, 6.33, 6.07, 5.67, 5.1, 5.83, 6.71, 6.93, 6.87, 6.81,
  6.71, 6.65, 6.59, 6.55, 6.15, 6.43, 6.87, 7.54, 8.33, 8.75, 8.96, 10.04, 10.53, 9.57, 7.58, 6.37,
  6.31, 5.93, 5.35, 6.0, 6.84, 7.01, 6.95, 6.82, 6.66, 6.53, 6.49, 6.49, 6.16, 6.28, 6.75, 7.62,
  8.63, 9.51, 10.03, 10.78, 11.18, 10.18, 8.25, 6.83, 6.48, 6.0, 5.4, 6.1, 6.87, 6.94, 6.81, 6.73,
  8.52, 8.49, 8.48, 8.5, 8.16, 8.09, 8.38, 8.94, 9.64, 10.26, 10.62, 11.21, 11.64, 10.89, 8.93,
  7.69, 7.48, 7.2, 6.34, 8.41, 8.66, 9.3, 9.18, 9.09, 8.81, 8.7, 8.59, 8.54, 8.17, 8.1, 8.47, 9.1,
  9.95, 10.55, 10.91, 11.06, 10.75, 9.93, 8.37, 7.06, 6.67, 6.33, 5.6, 7.77, 8.08, 8.69, 8.55, 8.46,
  6.85, 6.72, 6.66, 6.64, 6.4, 6.51, 6.99, 7.66, 8.46, 9.43, 10.03, 11.07, 11.57, 9.87, 7.55, 6.34,
  6.49, 6.28, 5.63, 6.31, 7.03, 7.17, 7.1, 6.98, 6.85, 6.78, 6.75, 6.75, 6.54, 6.67, 7.2, 8.06,
  9.07, 10.07, 10.73, 11.96, 12.7, 11.8, 9.81, 8.36, 8.0, 7.32, 6.55, 7.11, 7.73, 7.73, 7.55, 7.39,
  7.37, 7.23, 7.14, 7.11, 6.83, 7.05, 7.66, 8.55, 9.8, 11.13, 11.96, 13.14, 13.56, 12.24, 10.24,
  8.92, 8.44, 7.75, 7.0, 7.54, 8.33, 8.46, 8.2, 8.02, 7.81, 7.54, 7.36, 7.27, 6.95, 7.12, 7.75,
  8.83, 9.98, 10.72, 10.69, 11.42, 12.39, 11.68, 9.03, 7.6, 7.39, 6.84, 6.15, 6.71, 7.31, 7.36, 7.2,
  7.03, 6.93, 6.84, 6.78, 6.75, 6.5, 6.64, 7.2, 8.02, 8.97, 9.97, 10.17, 10.9, 11.4, 10.64, 9.03,
  7.73, 7.42, 6.82, 6.1, 6.66, 7.31, 7.34, 7.18, 7.11, 9.05, 9.03, 8.94, 8.92, 8.72, 8.54, 8.94,
  9.6, 10.41, 11.06, 11.29, 11.88, 12.35, 11.34, 8.83, 7.1, 6.79, 6.66, 6.17, 8.5, 8.74, 9.36, 9.19,
  9.05, 8.97, 8.82, 8.66, 8.57, 8.27, 8.05, 8.38, 8.98, 9.89, 10.61, 10.92, 11.27, 11.57, 10.78,
  9.08, 7.71, 7.33, 6.94, 6.11, 8.27, 8.58, 9.18, 9.08, 8.94, 6.85, 6.72, 6.66, 6.64, 6.4, 6.51,
  6.99, 7.66, 8.46, 9.43, 10.03, 11.07, 11.57, 9.87, 7.55, 6.34, 6.49, 6.28, 5.63, 6.31, 7.03, 7.17,
  7.1, 6.98, 6.85, 6.78, 6.75, 6.75, 6.54, 6.67, 7.2, 8.06, 9.07, 10.07, 10.73, 11.96, 12.7, 11.8,
  9.81, 8.36, 8.0, 7.32, 6.55, 7.11, 7.73, 7.73, 7.55, 7.39, 7.37, 7.23, 7.14, 7.11, 6.83, 7.05,
  7.66, 8.55, 9.8, 11.13, 11.96, 13.14, 13.56, 12.24, 10.24, 8.92, 8.44, 7.75, 7.0, 7.54, 8.33,
  8.46, 8.2, 8.02, 7.81, 7.54, 7.36, 7.27, 6.95, 7.12, 7.75, 8.83, 9.98, 10.72, 10.69, 11.42, 12.39,
  11.68, 9.03, 7.6, 7.39, 6.84, 6.15, 6.71, 7.31, 7.36, 7.2, 7.03, 6.93, 6.84, 6.78, 6.75, 6.5,
  6.64, 7.2, 8.02, 8.97, 9.97, 10.17, 10.9, 11.4, 10.64, 9.03, 7.73, 7.42, 6.82, 6.1, 6.66, 7.31,
  7.34, 7.18, 7.11, 9.05, 9.03, 8.94, 8.92, 8.72, 8.54, 8.94, 9.6, 10.41, 11.06, 11.29, 11.88,
  12.35, 11.34, 8.83, 7.1, 6.79, 6.66, 6.17, 8.5, 8.74, 9.36, 9.19, 9.05, 8.97, 8.82, 8.66, 8.57,
  8.27, 8.05, 8.38, 8.98, 9.89, 10.61, 10.92, 11.27, 11.57, 10.78, 9.08, 7.71, 7.33, 6.94, 6.11,
  8.27, 8.58, 9.18, 9.08, 8.94, 6.85, 6.72, 6.66, 6.64, 6.4, 6.51, 6.99, 7.66, 8.46, 9.43, 10.03,
  11.07, 11.57, 9.87, 7.55, 6.34, 6.49, 6.28, 5.63, 6.31, 7.03, 7.17, 7.1, 6.98, 6.85, 6.78, 6.75,
  6.75, 6.54, 6.67, 7.2, 8.06, 9.07, 10.07, 10.73, 11.96, 12.7, 11.8, 9.81, 8.36, 8.0, 7.32, 6.55,
  7.11, 7.73, 7.73, 7.55, 7.39, 7.37, 7.23, 7.14, 7.11, 6.83, 7.05, 7.66, 8.55, 9.8, 11.13, 11.96,
  13.14, 13.56, 12.24, 10.24, 8.92, 8.44, 7.75, 7.0, 7.54, 8.33, 8.46, 8.2, 8.02, 7.81, 7.54, 7.36,
  7.27, 6.95, 7.12, 7.75, 8.83, 9.98, 10.72, 10.69, 11.42, 12.39, 11.68, 9.03, 7.6, 7.39, 6.84,
  6.15, 6.71, 7.31, 7.36, 7.2, 7.03, 6.93, 6.84, 6.78, 6.75, 6.5, 6.64, 7.2, 8.02, 8.97, 9.97,
  10.17, 10.9, 11.4, 10.64, 9.03, 7.73, 7.42, 6.82, 6.1, 6.66, 7.31, 7.34, 7.18, 7.11, 9.05, 9.03,
  8.94, 8.92, 8.72, 8.54, 8.94, 9.6, 10.41, 11.06, 11.29, 11.88, 12.35, 11.34, 8.83, 7.1, 6.79,
  6.66, 6.17, 8.5, 8.74, 9.36, 9.19, 9.05, 8.97, 8.82, 8.66, 8.57, 8.27, 8.05, 8.38, 8.98, 9.89,
  10.61, 10.92, 11.27, 11.57, 10.78, 9.08, 7.71, 7.33, 6.94, 6.11, 8.27, 8.58, 9.18, 9.08, 8.94,
  6.85, 6.72, 6.66, 6.64, 6.4, 6.51, 6.99, 7.66, 8.46, 9.43, 10.03, 11.07, 11.57, 9.87, 7.55, 6.34,
  6.49, 6.28, 5.63, 6.31, 7.03, 7.17, 7.1, 6.98, 6.85, 6.78, 6.75, 6.75, 6.54, 6.67, 7.2, 8.06,
  9.07, 10.07, 10.73, 11.96, 12.7, 11.8, 9.81, 8.36, 8.0, 7.32, 6.55, 7.11, 7.73, 7.73, 7.55, 7.39,
  7.37, 7.23, 7.14, 7.11, 6.83, 7.05, 7.66, 8.55, 9.8, 11.13, 11.96, 13.14, 13.56, 12.24, 10.24,
  8.92, 8.44, 7.75, 7.0, 7.54, 8.33, 8.46, 8.2, 8.02, 7.81, 7.54, 7.36, 7.27, 6.95, 7.12, 7.75,
  8.83, 9.98, 10.72, 10.69, 11.42, 12.39, 11.68, 9.03, 7.6, 7.39, 6.84, 6.15, 6.71, 7.31, 7.36, 7.2,
  7.03, 6.93, 6.84, 6.78, 6.75, 6.5, 6.64, 7.2, 8.02, 8.97, 9.97, 10.17, 10.9, 11.4, 10.64, 9.03,
  7.73, 7.42, 6.82, 6.1, 6.66, 7.31, 7.34, 7.18, 7.11, 9.05, 9.03, 8.94, 8.92, 8.72, 8.54, 8.94,
  9.6, 10.41, 11.06, 11.29, 11.88, 12.35, 11.34, 8.83, 7.1, 6.79, 6.66, 6.17, 8.5, 8.74, 9.36, 9.19,
  9.05, 8.97, 8.82, 8.66, 8.57, 8.27, 8.05, 8.38, 8.98, 9.89, 10.61, 10.92, 11.27, 11.57, 10.78,
  9.08, 7.71, 7.33, 6.94, 6.11, 8.27, 8.58, 9.18, 9.08, 8.94, 6.85, 6.72, 6.66, 6.64, 6.4, 6.51,
  6.99, 7.66, 8.46, 9.43, 10.03, 11.07, 11.57, 9.87, 7.55, 6.34, 6.49, 6.28, 5.63, 6.31, 7.03, 7.17,
  7.1, 6.98, 6.85, 6.78, 6.75, 6.75, 6.54, 6.67, 7.2, 8.06, 9.07, 10.07, 10.73, 11.96, 12.7, 11.8,
  9.81, 8.36, 8.0, 7.32, 6.55, 7.11, 7.73, 7.73, 7.55, 7.39, 7.37, 7.23, 7.14, 7.11, 6.83, 7.05,
  7.66, 8.55, 9.8, 11.13, 11.96, 13.14, 13.56, 12.24, 10.24, 8.92, 8.44, 7.75, 7.0, 7.54, 8.33,
  8.46, 8.2, 8.02, 7.07, 6.92, 6.81, 6.78, 6.73, 6.66, 6.84, 7.38, 8.15, 8.81, 9.05, 10.19, 10.77,
  9.64, 7.62, 6.35, 6.15, 5.69, 5.36, 6.06, 6.78, 7.01, 6.96, 6.9, 6.87, 6.84, 6.85, 6.95, 6.99,
  6.9, 7.03, 7.44, 8.17, 9.05, 9.54, 10.86, 11.52, 10.61, 8.48, 7.06, 6.77, 6.29, 5.87, 6.45, 7.1,
  7.27, 7.18, 7.07, 9.02, 9.02, 8.97, 8.94, 8.87, 8.42, 8.69, 9.46, 10.55, 11.4, 11.84, 12.26,
  12.49, 11.3, 9.35, 8.03, 7.64, 7.18, 6.5, 8.69, 8.92, 9.56, 9.35, 9.12, 8.85, 8.69, 8.6, 8.54,
  8.48, 8.03, 8.35, 8.97, 9.84, 10.45, 10.88, 11.23, 11.6, 10.57, 8.31, 6.75, 6.39, 6.18, 5.68,
  8.04, 8.39, 9.13, 9.01, 8.81, 6.7, 6.59, 6.53, 6.5, 6.51, 6.34, 6.61, 7.16, 7.93, 8.48, 8.59,
  9.68, 10.33, 9.25, 7.1, 5.89, 5.71, 5.3, 5.02, 5.74, 6.4, 6.54, 6.48, 6.44, 6.4, 6.38, 6.38, 6.42,
  6.46, 6.29, 6.48, 7.01, 7.93, 8.59, 8.91, 10.01, 10.56, 9.29, 7.34, 6.28, 6.07, 5.61, 5.19, 5.9,
  6.65, 6.88, 6.83, 6.76, 6.7, 6.64, 6.6, 6.61, 6.62, 6.56, 6.92, 7.47, 8.33, 8.92, 9.38, 10.69,
  11.49, 10.72, 8.74, 7.27, 6.87, 6.33, 5.9, 6.61, 7.26, 7.42, 7.33, 7.22, 7.07, 6.92, 6.81, 6.78,
  6.73, 6.66, 6.84, 7.38, 8.15, 8.81, 9.05, 10.19, 10.77, 9.64, 7.62, 6.35, 6.15, 5.69, 5.36, 6.06,
  6.78, 7.01, 6.96, 6.9, 6.87, 6.84, 6.85, 6.95, 6.99, 6.9, 7.03, 7.44, 8.17, 9.05, 9.54, 10.86,
  11.52, 10.61, 8.48, 7.06, 6.77, 6.29, 5.87, 6.45, 7.1, 7.27, 7.18, 7.07, 9.02, 9.02, 8.97, 8.94,
  8.87, 8.42, 8.69, 9.46, 10.55, 11.4, 11.84, 12.26, 12.49, 11.3, 9.35, 8.03, 7.64, 7.18, 6.5, 8.69,
  8.92, 9.56, 9.35, 9.12, 8.85, 8.69, 8.6, 8.54, 8.48, 8.03, 8.35, 8.97, 9.84, 10.45, 10.88, 11.23,
  11.6, 10.57, 8.31, 6.75, 6.39, 6.18, 5.68, 8.04, 8.39, 9.13, 9.01, 8.81, 6.7, 6.59, 6.53, 6.5,
  6.51, 6.34, 6.61, 7.16, 7.93, 8.48, 8.59, 9.68, 10.33, 9.25, 7.1, 5.89, 5.71, 5.3, 5.02, 5.74,
  6.4, 6.54, 6.48, 6.44, 6.4, 6.38, 6.38, 6.42, 6.46, 6.29, 6.48, 7.01, 7.93, 8.59, 8.91, 10.01,
  10.56, 9.29, 7.34, 6.28, 6.07, 5.61, 5.19, 5.9, 6.65, 6.88, 6.83, 6.76, 6.7, 6.64, 6.6, 6.61,
  6.62, 6.56, 6.92, 7.47, 8.33, 8.92, 9.38, 10.69, 11.49, 10.72, 8.74, 7.27, 6.87, 6.33, 5.9, 6.61,
  7.26, 7.42, 7.33, 7.22, 7.07, 6.92, 6.81, 6.78, 6.73, 6.66, 6.84, 7.38, 8.15, 8.81, 9.05, 10.19,
  10.77, 9.64, 7.62, 6.35, 6.15, 5.69, 5.36, 6.06, 6.78, 7.01, 6.96, 6.9, 6.87, 6.84, 6.85, 6.95,
  6.99, 6.9, 7.03, 7.44, 8.17, 9.05, 9.54, 10.86, 11.52, 10.61, 8.48, 7.06, 6.77, 6.29, 5.87, 6.45,
  7.1, 7.27, 7.18, 7.07, 9.02, 9.02, 8.97, 8.94, 8.87, 8.42, 8.69, 9.46, 10.55, 11.4, 11.84, 12.26,
  12.49, 11.3, 9.35, 8.03, 7.64, 7.18, 6.5, 8.69, 8.92, 9.56, 9.35, 9.12, 8.85, 8.69, 8.6, 8.54,
  8.48, 8.03, 8.35, 8.97, 9.84, 10.45, 10.88, 11.23, 11.6, 10.57, 8.31, 6.75, 6.39, 6.18, 5.68,
  8.04, 8.39, 9.13, 9.01, 8.81, 6.7, 6.59, 6.53, 6.5, 6.51, 6.34, 6.61, 7.16, 7.93, 8.48, 8.59,
  9.68, 10.33, 9.25, 7.1, 5.89, 5.71, 5.3, 5.02, 5.74, 6.4, 6.54, 6.48, 6.44, 6.4, 6.38, 6.38, 6.42,
  6.46, 6.29, 6.48, 7.01, 7.93, 8.59, 8.91, 10.01, 10.56, 9.29, 7.34, 6.28, 6.07, 5.61, 5.19, 5.9,
  6.65, 6.88, 6.83, 6.76, 6.7, 6.64, 6.6, 6.61, 6.62, 6.56, 6.92, 7.47, 8.33, 8.92, 9.38, 10.69,
  11.49, 10.72, 8.74, 7.27, 6.87, 6.33, 5.9, 6.61, 7.26, 7.42, 7.33, 7.22, 7.07, 6.92, 6.81, 6.78,
  6.73, 6.66, 6.84, 7.38, 8.15, 8.81, 9.05, 10.19, 10.77, 9.64, 7.62, 6.35, 6.15, 5.69, 5.36, 6.06,
  6.78, 7.01, 6.96, 6.9, 6.87, 6.84, 6.85, 6.95, 6.99, 6.9, 7.03, 7.44, 8.17, 9.05, 9.54, 10.86,
  11.52, 10.61, 8.48, 7.06, 6.77, 6.29, 5.87, 6.45, 7.1, 7.27, 7.18, 7.07, 9.02, 9.02, 8.97, 8.94,
  8.87, 8.42, 8.69, 9.46, 10.55, 11.4, 11.84, 12.26, 12.49, 11.3, 9.35, 8.03, 7.64, 7.18, 6.5, 8.69,
  8.92, 9.56, 9.35, 9.12, 8.85, 8.69, 8.6, 8.54, 8.48, 8.03, 8.35, 8.97, 9.84, 10.45, 10.88, 11.23,
  11.6, 10.57, 8.31, 6.75, 6.39, 6.18, 5.68, 8.04, 8.39, 9.13, 9.01, 8.81, 6.7, 6.59, 6.53, 6.5,
  6.51, 6.34, 6.61, 7.16, 7.93, 8.48, 8.59, 9.68, 10.33, 9.25, 7.1, 5.89, 5.71, 5.3, 5.02, 5.74,
  6.4, 6.54, 6.48, 6.44, 6.4, 6.38, 6.38, 6.42, 6.46, 6.29, 6.48, 7.01, 7.93, 8.59, 8.91, 10.01,
  10.56, 9.29, 7.34, 6.28, 6.07, 5.61, 5.19, 5.9, 6.65, 6.88, 6.83, 6.76, 6.7, 6.64, 6.6, 6.61,
  6.62, 6.56, 6.92, 7.47, 8.33, 8.92, 9.38, 10.69, 11.49, 10.72, 8.74, 7.27, 6.87, 6.33, 5.9, 6.61,
  7.26, 7.42, 7.33, 7.22, 7.07, 6.92, 6.81, 6.78, 6.73, 6.66, 6.84, 7.38, 8.15, 8.81, 9.05, 10.19,
  10.77, 9.64, 7.62, 6.35, 6.15, 5.69, 5.36, 6.06, 6.78, 7.01, 6.96, 6.9, 6.87, 6.84, 6.85, 6.95,
  6.99, 6.9, 7.03, 7.44, 8.17, 9.05, 9.54, 10.86, 11.52, 10.61, 8.48, 7.06, 6.77, 6.29, 5.87, 6.45,
  7.1, 7.27, 7.18, 7.07, 9.02, 9.02, 8.97, 8.94, 8.87, 8.42, 8.69, 9.46, 10.55, 11.4, 11.84, 12.26,
  12.49, 11.3, 9.35, 8.03, 7.64, 7.18, 6.5, 8.69, 8.92, 9.56, 9.35, 9.12, 8.1, 8.05, 8.02, 8.02,
  8.02, 7.76, 7.49, 7.58, 8.04, 8.55, 8.72, 9.14, 9.62, 8.72, 6.76, 5.34, 5.08, 5.05, 5.03, 7.09,
  7.53, 8.25, 8.21, 8.19, 6.77, 6.76, 6.85, 7.04, 7.16, 7.17, 6.79, 6.95, 7.34, 7.95, 8.42, 9.58,
  10.11, 8.94, 7.05, 5.79, 5.47, 5.08, 5.07, 5.58, 6.43, 6.77, 6.81, 6.79, 6.77, 6.75, 6.72, 6.72,
  6.75, 6.79, 6.67, 6.96, 7.5, 8.17, 8.48, 9.68, 10.31, 9.24, 7.17, 5.9, 5.58, 5.12, 5.05, 5.5,
  6.27, 6.51, 6.51, 6.56, 6.64, 6.71, 6.81, 6.9, 6.95, 7.05, 6.73, 6.79, 7.37, 8.06, 8.44, 9.53,
  10.04, 8.99, 6.92, 5.57, 5.3, 4.99, 4.99, 5.52, 6.48, 6.84, 6.85, 6.85, 6.87, 6.88, 6.95, 7.09,
  7.18, 7.23, 6.89, 6.94, 7.16, 7.6, 7.92, 9.03, 9.54, 8.52, 6.59, 5.38, 5.18, 4.88, 4.9, 5.46,
  6.37, 6.7, 6.73, 6.77, 6.83, 6.84, 6.83, 6.81, 6.81, 6.96, 6.75, 6.92, 7.34, 7.76, 8.03, 9.08,
  9.52, 8.47, 6.65, 5.4, 5.28, 5.1, 5.14, 5.66, 6.42, 6.6, 6.54, 6.5, 8.48, 8.43, 8.38, 8.37, 8.38,
  8.17, 7.99, 8.33, 8.85, 9.26, 9.36, 9.52, 9.69, 8.76, 6.84, 5.46, 5.19, 5.17, 5.11, 7.15, 7.51,
  8.24, 8.21, 8.19, 8.1, 8.05, 8.02, 8.02, 8.02, 7.76, 7.49, 7.58, 8.04, 8.55, 8.72, 9.14, 9.62,
  8.72, 6.76, 5.34, 5.08, 5.05, 5.03, 7.09, 7.53, 8.25, 8.21, 8.19, 6.77, 6.76, 6.85, 7.04, 7.16,
  7.17, 6.79, 6.95, 7.34, 7.95, 8.42, 9.58, 10.11, 8.94, 7.05, 5.79, 5.47, 5.08, 5.07, 5.58, 6.43,
  6.77, 6.81, 6.79, 6.77, 6.75, 6.72, 6.72, 6.75, 6.79, 6.67, 6.96, 7.5, 8.17, 8.48, 9.68, 10.31,
  9.24, 7.17, 5.9, 5.58, 5.12, 5.05, 5.5, 6.27, 6.51, 6.51, 6.56, 6.64, 6.71, 6.81, 6.9, 6.95, 7.05,
  6.73, 6.79, 7.37, 8.06, 8.44, 9.53, 10.04, 8.99, 6.92, 5.57, 5.3, 4.99, 4.99, 5.52, 6.48, 6.84,
  6.85, 6.85, 6.87, 6.88, 6.95, 7.09, 7.18, 7.23, 6.89, 6.94, 7.16, 7.6, 7.92, 9.03, 9.54, 8.52,
  6.59, 5.38, 5.18, 4.88, 4.9, 5.46, 6.37, 6.7, 6.73, 6.77, 6.83, 6.84, 6.83, 6.81, 6.81, 6.96,
  6.75, 6.92, 7.34, 7.76, 8.03, 9.08, 9.52, 8.47, 6.65, 5.4, 5.28, 5.1, 5.14, 5.66, 6.42, 6.6, 6.54,
  6.5, 8.48, 8.43, 8.38, 8.37, 8.38, 8.17, 7.99, 8.33, 8.85, 9.26, 9.36, 9.52, 9.69, 8.76, 6.84,
  5.46, 5.19, 5.17, 5.11, 7.15, 7.51, 8.24, 8.21, 8.19, 8.1, 8.05, 8.02, 8.02, 8.02, 7.76, 7.49,
  7.58, 8.04, 8.55, 8.72, 9.14, 9.62, 8.72, 6.76, 5.34, 5.08, 5.05, 5.03, 7.09, 7.53, 8.25, 8.21,
  8.19, 6.77, 6.76, 6.85, 7.04, 7.16, 7.17, 6.79, 6.95, 7.34, 7.95, 8.42, 9.58, 10.11, 8.94, 7.05,
  5.79, 5.47, 5.08, 5.07, 5.58, 6.43, 6.77, 6.81, 6.79, 6.77, 6.75, 6.72, 6.72, 6.75, 6.79, 6.67,
  6.96, 7.5, 8.17, 8.48, 9.68, 10.31, 9.24, 7.17, 5.9, 5.58, 5.12, 5.05, 5.5, 6.27, 6.51, 6.51,
  6.56, 6.64, 6.71, 6.81, 6.9, 6.95, 7.05, 6.73, 6.79, 7.37, 8.06, 8.44, 9.53, 10.04, 8.99, 6.92,
  5.57, 5.3, 4.99, 4.99, 5.52, 6.48, 6.84, 6.85, 6.85, 6.87, 6.88, 6.95, 7.09, 7.18, 7.23, 6.89,
  6.94, 7.16, 7.6, 7.92, 9.03, 9.54, 8.52, 6.59, 5.38, 5.18, 4.88, 4.9, 5.46, 6.37, 6.7, 6.73, 6.77,
  6.83, 6.84, 6.83, 6.81, 6.81, 6.96, 6.75, 6.92, 7.34, 7.76, 8.03, 9.08, 9.52, 8.47, 6.65, 5.4,
  5.28, 5.1, 5.14, 5.66, 6.42, 6.6, 6.54, 6.5, 8.48, 8.43, 8.38, 8.37, 8.38, 8.17, 7.99, 8.33, 8.85,
  9.26, 9.36, 9.52, 9.69, 8.76, 6.84, 5.46, 5.19, 5.17, 5.11, 7.15, 7.51, 8.24, 8.21, 8.19, 8.1,
  8.05, 8.02, 8.02, 8.02, 7.76, 7.49, 7.58, 8.04, 8.55, 8.72, 9.14, 9.62, 8.72, 6.76, 5.34, 5.08,
  5.05, 5.03, 7.09, 7.53, 8.25, 8.21, 8.19, 6.77, 6.76, 6.85, 7.04, 7.16, 7.17, 6.79, 6.95, 7.34,
  7.95, 8.42, 9.58, 10.11, 8.94, 7.05, 5.79, 5.47, 5.08, 5.07, 5.58, 6.43, 6.77, 6.81, 6.79, 6.77,
  6.75, 6.72, 6.72, 6.75, 6.79, 6.67, 6.96, 7.5, 8.17, 8.48, 9.68, 10.31, 9.24, 7.17, 5.9, 5.58,
  5.12, 5.05, 5.5, 6.27, 6.51, 6.51, 6.56, 6.64, 6.71, 6.81, 6.9, 6.95, 7.05, 6.73, 6.79, 7.37,
  8.06, 8.44, 9.53, 10.04, 8.99, 6.92, 5.57, 5.3, 4.99, 4.99, 5.52, 6.48, 6.84, 6.85, 6.85, 6.87,
  6.88, 6.95, 7.09, 7.18, 7.23, 6.89, 6.94, 7.16, 7.6, 7.92, 9.03, 9.54, 8.52, 6.59, 5.38, 5.18,
  4.88, 4.9, 5.46, 6.37, 6.7, 6.73, 6.77, 6.83, 6.84, 6.83, 6.81, 6.81, 6.96, 6.75, 6.92, 7.34,
  7.76, 8.03, 9.08, 9.52, 8.47, 6.65, 5.4, 5.28, 5.1, 5.14, 5.66, 6.42, 6.6, 6.54, 6.5, 8.48, 8.43,
  8.38, 8.37, 8.38, 8.17, 7.99, 8.33, 8.85, 9.26, 9.36, 9.52, 9.69, 8.76, 6.84, 5.46, 5.19, 5.17,
  5.11, 7.15, 7.51, 8.24, 8.21, 8.19, 8.1, 8.05, 8.02, 8.02, 8.02, 7.76, 7.49, 7.58, 8.04, 8.55,
  8.72, 9.14, 9.62, 8.72, 6.76, 5.34, 5.08, 5.05, 5.03, 7.09, 7.53, 8.25, 8.21, 8.19, 6.77, 6.76,
  6.85, 7.04, 7.16, 7.17, 6.79, 6.95, 7.34, 7.95, 8.42, 9.58, 10.11, 8.94, 7.05, 5.79, 5.47, 5.08,
  5.07, 5.58, 6.43, 6.77, 6.81, 6.79, 6.77, 6.82, 6.87, 6.94, 6.96, 7.22, 6.84, 6.75, 6.67, 6.71,
  6.53, 7.4, 7.92, 6.87, 5.05, 4.04, 4.07, 4.37, 4.25, 4.88, 6.26, 6.65, 6.7, 6.72, 6.75, 6.78,
  6.82, 6.88, 6.93, 7.22, 6.87, 6.75, 6.75, 6.68, 6.45, 7.45, 7.93, 6.82, 4.92, 3.84, 3.92, 4.3,
  4.25, 4.89, 6.44, 6.73, 6.81, 6.84, 6.83, 6.87, 6.85, 6.84, 6.83, 7.07, 6.67, 6.57, 6.66, 6.84,
  6.7, 7.49, 7.92, 6.84, 5.01, 3.89, 3.95, 4.25, 4.23, 4.9, 6.32, 6.79, 6.83, 6.84, 6.84, 6.84,
  6.85, 6.89, 6.94, 7.21, 6.88, 6.77, 6.53, 6.51, 6.54, 7.43, 7.77, 6.65, 4.85, 3.82, 3.89, 4.25,
  4.2, 4.86, 6.39, 6.75, 6.81, 6.88, 7.76, 7.73, 7.72, 7.73, 7.75, 7.75, 7.27, 7.28, 7.39, 7.32,
  7.21, 7.26, 7.39, 6.54, 4.79, 3.74, 3.84, 4.52, 4.45, 6.46, 6.95, 7.7, 7.71, 7.72, 7.69, 7.67,
  7.67, 7.69, 7.7, 7.71, 7.23, 7.23, 7.37, 7.36, 7.23, 7.28, 7.44, 6.61, 4.89, 3.74, 3.82, 4.59,
  4.47, 6.49, 7.0, 7.72, 7.72, 7.72, 6.88, 6.89, 6.89, 6.93, 6.95, 7.21, 6.84, 6.84, 6.83, 6.65,
  6.53, 7.4, 7.86, 6.81, 5.0, 3.97, 4.04, 4.41, 4.3, 4.9, 6.34, 6.65, 6.68, 6.72, 6.77, 6.82, 6.87,
  6.94, 6.96, 7.22, 6.84, 6.75, 6.67, 6.71, 6.53, 7.4, 7.92, 6.87, 5.05, 4.04, 4.07, 4.37, 4.25,
  4.88, 6.26, 6.65, 6.7, 6.72, 6.75, 6.78, 6.82, 6.88, 6.93, 7.22, 6.87, 6.75, 6.75, 6.68, 6.45,
  7.45, 7.93, 6.82, 4.92, 3.84, 3.92, 4.3, 4.25, 4.89, 6.44, 6.73, 6.81, 6.84, 6.83, 6.87, 6.85,
  6.84, 6.83, 7.07, 6.67, 6.57, 6.66, 6.84, 6.7, 7.49, 7.92, 6.84, 5.01, 3.89, 3.95, 4.25, 4.23,
  4.9, 6.32, 6.79, 6.83, 6.84, 6.84, 6.84, 6.85, 6.89, 6.94, 7.21, 6.88, 6.77, 6.53, 6.51, 6.54,
  7.43, 7.77, 6.65, 4.85, 3.82, 3.89, 4.25, 4.2, 4.86, 6.39, 6.75, 6.81, 6.88, 7.76, 7.73, 7.72,
  7.73, 7.75, 7.75, 7.27, 7.28, 7.39, 7.32, 7.21, 7.26, 7.39, 6.54, 4.79, 3.74, 3.84, 4.52, 4.45,
  6.46, 6.95, 7.7, 7.71, 7.72, 7.69, 7.67, 7.67, 7.69, 7.7, 7.71, 7.23, 7.23, 7.37, 7.36, 7.23,
  7.28, 7.44, 6.61, 4.89, 3.74, 3.82, 4.59, 4.47, 6.49, 7.0, 7.72, 7.72, 7.72, 6.88, 6.89, 6.89,
  6.93, 6.95, 7.21, 6.84, 6.84, 6.83, 6.65, 6.53, 7.4, 7.86, 6.81, 5.0, 3.97, 4.04, 4.41, 4.3, 4.9,
  6.34, 6.65, 6.68, 6.72, 6.77, 6.82, 6.87, 6.94, 6.96, 7.22, 6.84, 6.75, 6.67, 6.71, 6.53, 7.4,
  7.92, 6.87, 5.05, 4.04, 4.07, 4.37, 4.25, 4.88, 6.26, 6.65, 6.7, 6.72, 6.75, 6.78, 6.82, 6.88,
  6.93, 7.22, 6.87, 6.75, 6.75, 6.68, 6.45, 7.45, 7.93, 6.82, 4.92, 3.84, 3.92, 4.3, 4.25, 4.89,
  6.44, 6.73, 6.81, 6.84, 6.83, 6.87, 6.85, 6.84, 6.83, 7.07, 6.67, 6.57, 6.66, 6.84, 6.7, 7.49,
  7.92, 6.84, 5.01, 3.89, 3.95, 4.25, 4.23, 4.9, 6.32, 6.79, 6.83, 6.84, 6.84, 6.84, 6.85, 6.89,
  6.94, 7.21, 6.88, 6.77, 6.53, 6.51, 6.54, 7.43, 7.77, 6.65, 4.85, 3.82, 3.89, 4.25, 4.2, 4.86,
  6.39, 6.75, 6.81, 6.88, 7.76, 7.73, 7.72, 7.73, 7.75, 7.75, 7.27, 7.28, 7.39, 7.32, 7.21, 7.26,
  7.39, 6.54, 4.79, 3.74, 3.84, 4.52, 4.45, 6.46, 6.95, 7.7, 7.71, 7.72, 7.69, 7.67, 7.67, 7.69,
  7.7, 7.71, 7.23, 7.23, 7.37, 7.36, 7.23, 7.28, 7.44, 6.61, 4.89, 3.74, 3.82, 4.59, 4.47, 6.49,
  7.0, 7.72, 7.72, 7.72, 6.88, 6.89, 6.89, 6.93, 6.95, 7.21, 6.84, 6.84, 6.83, 6.65, 6.53, 7.4,
  7.86, 6.81, 5.0, 3.97, 4.04, 4.41, 4.3, 4.9, 6.34, 6.65, 6.68, 6.72, 6.77, 6.82, 6.87, 6.94, 6.96,
  7.22, 6.84, 6.75, 6.67, 6.71, 6.53, 7.4, 7.92, 6.87, 5.05, 4.04, 4.07, 4.37, 4.25, 4.88, 6.26,
  6.65, 6.7, 6.72, 6.75, 6.78, 6.82, 6.88, 6.93, 7.22, 6.87, 6.75, 6.75, 6.68, 6.45, 7.45, 7.93,
  6.82, 4.92, 3.84, 3.92, 4.3, 4.25, 4.89, 6.44, 6.73, 6.81, 6.84, 6.83, 6.87, 6.85, 6.84, 6.83,
  7.07, 6.67, 6.57, 6.66, 6.84, 6.7, 7.49, 7.92, 6.84, 5.01, 3.89, 3.95, 4.25, 4.23, 4.9, 6.32,
  6.79, 6.83, 6.84, 6.84, 6.84, 6.85, 6.89, 6.94, 7.21, 6.88, 6.77, 6.53, 6.51, 6.54, 7.43, 7.77,
  6.65, 4.85, 3.82, 3.89, 4.25, 4.2, 4.86, 6.39, 6.75, 6.81, 6.88, 7.76, 7.73, 7.72, 7.73, 7.75,
  7.75, 7.27, 7.28, 7.39, 7.32, 7.21, 7.26, 7.39, 6.54, 4.79, 3.74, 3.84, 4.52, 4.45, 6.46, 6.95,
  7.7, 7.71, 7.72, 7.69, 7.67, 7.67, 7.69, 7.7, 7.71, 7.23, 7.23, 7.37, 7.36, 7.23, 7.28, 7.44,
  6.61, 4.89, 3.74, 3.82, 4.59, 4.47, 6.49, 7.0, 7.72, 7.72, 7.72, 6.88, 6.89, 6.89, 6.93, 6.95,
  7.21, 6.84, 6.84, 6.83, 6.65, 6.53, 7.4, 7.86, 6.81, 5.0, 3.97, 4.04, 4.41, 4.3, 4.9, 6.34, 6.65,
  6.68, 6.72, 6.77, 6.82, 6.87, 6.94, 6.96, 7.22, 6.84, 6.75, 6.67, 6.71, 6.53, 7.4, 7.92, 6.87,
  5.05, 4.04, 4.07, 4.37, 4.25, 4.88, 6.26, 6.65, 6.7, 6.72, 6.75, 6.78, 6.82, 6.88, 6.93, 7.22,
  6.87, 6.75, 6.75, 6.68, 6.45, 7.45, 7.93, 6.82, 4.92, 3.84, 3.92, 4.3, 4.25, 4.89, 6.44, 6.73,
  6.81, 6.84, 6.83, 6.87, 6.85, 6.84, 6.83, 7.07, 6.67, 6.57, 6.66, 6.84, 6.7, 7.49, 7.92, 6.84,
  5.01, 3.89, 3.95, 4.25, 4.23, 4.9, 6.32, 6.79, 6.83, 6.84, 6.98, 6.99, 6.99, 7.0, 7.03, 7.1, 7.15,
  6.88, 6.92, 6.75, 6.64, 6.56, 7.05, 7.03, 5.79, 4.18, 3.79, 4.24, 4.28, 4.35, 5.21, 6.68, 6.93,
  6.96, 7.72, 7.72, 7.71, 7.71, 7.71, 7.72, 7.73, 7.53, 7.22, 7.32, 7.38, 7.45, 7.38, 7.38, 7.18,
  6.01, 4.35, 3.85, 4.37, 4.57, 4.97, 6.61, 7.17, 7.75, 7.77, 7.72, 7.72, 7.71, 7.72, 7.73, 7.76,
  7.56, 7.25, 7.31, 7.43, 7.4, 7.21, 7.18, 7.01, 5.9, 4.31, 3.9, 4.39, 4.57, 4.97, 6.55, 7.12, 7.65,
  7.66, 7.0, 7.03, 7.01, 6.9, 6.89, 6.92, 6.95, 6.72, 6.76, 6.55, 6.46, 6.22, 6.87, 7.12, 6.01,
  4.33, 3.75, 4.23, 4.3, 4.23, 4.88, 6.49, 6.77, 6.81, 6.83, 6.84, 6.84, 6.85, 6.88, 6.9, 6.96, 6.7,
  6.68, 6.67, 6.57, 6.32, 6.99, 7.25, 6.09, 4.36, 3.76, 4.23, 4.3, 4.23, 4.86, 6.62, 6.92, 6.95,
  6.98, 6.99, 6.99, 7.0, 7.03, 7.05, 7.17, 6.88, 6.84, 6.65, 6.57, 6.35, 6.93, 7.12, 6.01, 4.28,
  3.78, 4.23, 4.3, 4.23, 4.86, 6.4, 6.72, 6.78, 6.85, 6.88, 6.9, 6.94, 7.01, 7.11, 7.14, 6.88, 6.93,
  6.75, 6.66, 6.68, 7.07, 7.0, 5.73, 4.15, 3.79, 4.23, 4.26, 4.34, 5.21, 6.67, 6.92, 6.96, 6.98,
  6.99, 6.99, 7.0, 7.03, 7.1, 7.15, 6.88, 6.92, 6.75, 6.64, 6.56, 7.05, 7.03, 5.79, 4.18, 3.79,
  4.24, 4.28, 4.35, 5.21, 6.68, 6.93, 6.96, 7.72, 7.72, 7.71, 7.71, 7.72, 7.73, 7.53, 7.22, 7.32,
  7.38, 7.45, 7.38, 7.38, 7.18, 6.01, 4.35, 3.85, 4.37, 4.57, 4.97, 6.61, 7.17, 7.75, 7.77, 7.72,
  7.72, 7.71, 7.72, 7.73, 7.76, 7.56, 7.25, 7.31, 7.43, 7.4, 7.21, 7.18, 7.01, 5.9, 4.31, 3.9, 4.39,
  4.57, 4.97, 6.55, 7.12, 7.65, 7.66, 7.0, 7.03, 7.01, 6.9, 6.89, 6.92, 6.95, 6.72, 6.76, 6.55,
  6.46, 6.22, 6.87, 7.12, 6.01, 4.33, 3.75, 4.23, 4.3, 4.23, 4.88, 6.49, 6.77, 6.81, 6.83, 6.84,
  6.84, 6.85, 6.88, 6.9, 6.96, 6.7, 6.68, 6.67, 6.57, 6.32, 6.99, 7.25, 6.09, 4.36, 3.76, 4.23, 4.3,
  4.23, 4.86, 6.62, 6.92, 6.95, 6.98, 6.99, 6.99, 7.0, 7.03, 7.05, 7.17, 6.88, 6.84, 6.65, 6.57,
  6.35, 6.93, 7.12, 6.01, 4.28, 3.78, 4.23, 4.3, 4.23, 4.86, 6.4, 6.72, 6.78, 6.85, 6.88, 6.9, 6.94,
  7.01, 7.11, 7.14, 6.88, 6.93, 6.75, 6.66, 6.68, 7.07, 7.0, 5.73, 4.15, 3.79, 4.23, 4.26, 4.34,
  5.21, 6.67, 6.92, 6.96, 6.98, 6.99, 6.99, 7.0, 7.03, 7.1, 7.15, 6.88, 6.92, 6.75, 6.64, 6.56,
  7.05, 7.03, 5.79, 4.18, 3.79, 4.24, 4.28, 4.35, 5.21, 6.68, 6.93, 6.96, 7.72, 7.72, 7.71, 7.71,
  7.72, 7.73, 7.53, 7.22, 7.32, 7.38, 7.45, 7.38, 7.38, 7.18, 6.01, 4.35, 3.85, 4.37, 4.57, 4.97,
  6.61, 7.17, 7.75, 7.77, 7.72, 7.72, 7.71, 7.72, 7.73, 7.76, 7.56, 7.25, 7.31, 7.43, 7.4, 7.21,
  7.18, 7.01, 5.9, 4.31, 3.9, 4.39, 4.57, 4.97, 6.55, 7.12, 7.65, 7.66, 7.0, 7.03, 7.01, 6.9, 6.89,
  6.92, 6.95, 6.72, 6.76, 6.55, 6.46, 6.22, 6.87, 7.12, 6.01, 4.33, 3.75, 4.23, 4.3, 4.23, 4.88,
  6.49, 6.77, 6.81, 6.83, 6.84, 6.84, 6.85, 6.88, 6.9, 6.96, 6.7, 6.68, 6.67, 6.57, 6.32, 6.99,
  7.25, 6.09, 4.36, 3.76, 4.23, 4.3, 4.23, 4.86, 6.62, 6.92, 6.95, 6.98, 6.99, 6.99, 7.0, 7.03,
  7.05, 7.17, 6.88, 6.84, 6.65, 6.57, 6.35, 6.93, 7.12, 6.01, 4.28, 3.78, 4.23, 4.3, 4.23, 4.86,
  6.4, 6.72, 6.78, 6.85, 6.88, 6.9, 6.94, 7.01, 7.11, 7.14, 6.88, 6.93, 6.75, 6.66, 6.68, 7.07, 7.0,
  5.73, 4.15, 3.79, 4.23, 4.26, 4.34, 5.21, 6.67, 6.92, 6.96, 6.98, 6.99, 6.99, 7.0, 7.03, 7.1,
  7.15, 6.88, 6.92, 6.75, 6.64, 6.56, 7.05, 7.03, 5.79, 4.18, 3.79, 4.24, 4.28, 4.35, 5.21, 6.68,
  6.93, 6.96, 7.72, 7.72, 7.71, 7.71, 7.72, 7.73, 7.53, 7.22, 7.32, 7.38, 7.45, 7.38, 7.38, 7.18,
  6.01, 4.35, 3.85, 4.37, 4.57, 4.97, 6.61, 7.17, 7.75, 7.77, 7.72, 7.72, 7.71, 7.72, 7.73, 7.76,
  7.56, 7.25, 7.31, 7.43, 7.4, 7.21, 7.18, 7.01, 5.9, 4.31, 3.9, 4.39, 4.57, 4.97, 6.55, 7.12, 7.65,
  7.66, 7.0, 7.03, 7.01, 6.9, 6.89, 6.92, 6.95, 6.72, 6.76, 6.55, 6.46, 6.22, 6.87, 7.12, 6.01,
  4.33, 3.75, 4.23, 4.3, 4.23, 4.88, 6.49, 6.77, 6.81, 6.83, 6.84, 6.84, 6.85, 6.88, 6.9, 6.96, 6.7,
  6.68, 6.67, 6.57, 6.32, 6.99, 7.25, 6.09, 4.36, 3.76, 4.23, 4.3, 4.23, 4.86, 6.62, 6.92, 6.95,
  6.98, 6.99, 6.99, 7.0, 7.03, 7.05, 7.17, 6.88, 6.84, 6.65, 6.57, 6.35, 6.93, 7.12, 6.01, 4.28,
  3.78, 4.23, 4.3, 4.23, 4.86, 6.4, 6.72, 6.78, 6.85, 6.88, 6.9, 6.94, 7.01, 7.11, 7.14, 6.88, 6.93,
  6.75, 6.66, 6.68, 7.07, 7.0, 5.73, 4.15, 3.79, 4.23, 4.26, 4.34, 5.21, 6.67, 6.92, 6.96, 6.98,
  6.99, 6.99, 7.0, 7.03, 7.1, 7.15, 6.88, 6.92, 6.75, 6.64, 6.56, 7.05, 7.03, 5.79, 4.18, 3.79,
  4.24, 4.28, 4.35, 5.21, 6.68, 6.93, 6.96, 7.72, 7.72, 7.71, 7.71, 7.72, 7.73, 7.53, 7.22, 7.32,
  7.38, 7.45, 7.38, 7.38, 7.18, 6.01, 4.35, 3.85, 4.37, 4.57, 4.97, 6.61, 7.17, 7.75, 7.77, 7.53,
  7.54, 7.53, 7.53, 7.54, 7.55, 7.56, 7.11, 7.09, 7.22, 7.16, 6.89, 6.84, 6.88, 5.99, 4.41, 3.91,
  4.26, 4.57, 4.44, 6.33, 6.84, 7.51, 7.54, 6.78, 6.81, 6.81, 6.82, 6.84, 6.88, 7.09, 6.72, 6.75,
  6.82, 6.7, 6.23, 6.64, 6.87, 5.83, 4.22, 3.8, 4.2, 4.28, 4.23, 4.89, 6.43, 6.72, 6.76, 6.75, 6.76,
  6.77, 6.77, 6.81, 6.83, 7.05, 6.66, 6.7, 6.77, 6.71, 6.4, 6.7, 6.83, 5.78, 4.19, 3.84, 4.2, 4.29,
  4.24, 4.9, 6.38, 6.66, 6.71, 6.73, 6.76, 6.76, 6.6, 6.64, 6.65, 6.84, 6.46, 6.46, 6.55, 6.5, 6.22,
  6.61, 6.77, 5.74, 4.18, 3.84, 4.22, 4.29, 4.25, 4.9, 6.34, 6.65, 6.68, 6.71, 6.75, 6.75, 6.76,
  6.78, 6.81, 7.03, 6.68, 6.67, 6.75, 6.7, 6.42, 6.66, 6.78, 5.74, 4.18, 3.84, 4.2, 4.29, 4.24, 4.9,
  6.26, 6.51, 6.55, 6.57, 6.59, 6.59, 6.59, 6.62, 6.65, 6.85, 6.51, 6.51, 6.57, 6.54, 6.26, 6.65,
  6.82, 5.78, 4.2, 3.84, 4.2, 4.28, 4.23, 4.89, 6.22, 6.51, 6.55, 7.59, 7.6, 7.59, 7.59, 7.6, 7.61,
  7.61, 7.16, 7.14, 7.23, 7.16, 6.89, 6.82, 6.84, 5.96, 4.39, 3.92, 4.24, 4.56, 4.42, 6.33, 6.84,
  7.54, 7.56, 7.53, 7.54, 7.53, 7.53, 7.54, 7.55, 7.56, 7.11, 7.09, 7.22, 7.16, 6.89, 6.84, 6.88,
  5.99, 4.41, 3.91, 4.26, 4.57, 4.44, 6.33, 6.84, 7.51, 7.54, 6.78, 6.81, 6.81, 6.82, 6.84, 6.88,
  7.09, 6.72, 6.75, 6.82, 6.7, 6.23, 6.64, 6.87, 5.83, 4.22, 3.8, 4.2, 4.28, 4.23, 4.89, 6.43, 6.72,
  6.76, 6.75, 6.76, 6.77, 6.77, 6.81, 6.83, 7.05, 6.66, 6.7, 6.77, 6.71, 6.4, 6.7, 6.83, 5.78, 4.19,
  3.84, 4.2, 4.29, 4.24, 4.9, 6.38, 6.66, 6.71, 6.73, 6.76, 6.76, 6.6, 6.64, 6.65, 6.84, 6.46, 6.46,
  6.55, 6.5, 6.22, 6.61, 6.77, 5.74, 4.18, 3.84, 4.22, 4.29, 4.25, 4.9, 6.34, 6.65, 6.68, 6.71,
  6.75, 6.75, 6.76, 6.78, 6.81, 7.03, 6.68, 6.67, 6.75, 6.7, 6.42, 6.66, 6.78, 5.74, 4.18, 3.84,
  4.2, 4.29, 4.24, 4.9, 6.26, 6.51, 6.55, 6.57, 6.59, 6.59, 6.59, 6.62, 6.65, 6.85, 6.51, 6.51,
  6.57, 6.54, 6.26, 6.65, 6.82, 5.78, 4.2, 3.84, 4.2, 4.28, 4.23, 4.89, 6.22, 6.51, 6.55, 7.59, 7.6,
  7.59, 7.59, 7.6, 7.61, 7.61, 7.16, 7.14, 7.23, 7.16, 6.89, 6.82, 6.84, 5.96, 4.39, 3.92, 4.24,
  4.56, 4.42, 6.33, 6.84, 7.54, 7.56, 7.53, 7.54, 7.53, 7.53, 7.54, 7.55, 7.56, 7.11, 7.09, 7.22,
  7.16, 6.89, 6.84, 6.88, 5.99, 4.41, 3.91, 4.26, 4.57, 4.44, 6.33, 6.84, 7.51, 7.54, 6.78, 6.81,
  6.81, 6.82, 6.84, 6.88, 7.09, 6.72, 6.75, 6.82, 6.7, 6.23, 6.64, 6.87, 5.83, 4.22, 3.8, 4.2, 4.28,
  4.23, 4.89, 6.43, 6.72, 6.76, 6.75, 6.76, 6.77, 6.77, 6.81, 6.83, 7.05, 6.66, 6.7, 6.77, 6.71,
  6.4, 6.7, 6.83, 5.78, 4.19, 3.84, 4.2, 4.29, 4.24, 4.9, 6.38, 6.66, 6.71, 6.73, 6.76, 6.76, 6.6,
  6.64, 6.65, 6.84, 6.46, 6.46, 6.55, 6.5, 6.22, 6.61, 6.77, 5.74, 4.18, 3.84, 4.22, 4.29, 4.25,
  4.9, 6.34, 6.65, 6.68, 6.71, 6.75, 6.75, 6.76, 6.78, 6.81, 7.03, 6.68, 6.67, 6.75, 6.7, 6.42,
  6.66, 6.78, 5.74, 4.18, 3.84, 4.2, 4.29, 4.24, 4.9, 6.26, 6.51, 6.55, 6.57, 6.59, 6.59, 6.59,
  6.62, 6.65, 6.85, 6.51, 6.51, 6.57, 6.54, 6.26, 6.65, 6.82, 5.78, 4.2, 3.84, 4.2, 4.28, 4.23,
  4.89, 6.22, 6.51, 6.55, 7.59, 7.6, 7.59, 7.59, 7.6, 7.61, 7.61, 7.16, 7.14, 7.23, 7.16, 6.89,
  6.82, 6.84, 5.96, 4.39, 3.92, 4.24, 4.56, 4.42, 6.33, 6.84, 7.54, 7.56, 7.53, 7.54, 7.53, 7.53,
  7.54, 7.55, 7.56, 7.11, 7.09, 7.22, 7.16, 6.89, 6.84, 6.88, 5.99, 4.41, 3.91, 4.26, 4.57, 4.44,
  6.33, 6.84, 7.51, 7.54, 6.78, 6.81, 6.81, 6.82, 6.84, 6.88, 7.09, 6.72, 6.75, 6.82, 6.7, 6.23,
  6.64, 6.87, 5.83, 4.22, 3.8, 4.2, 4.28, 4.23, 4.89, 6.43, 6.72, 6.76, 6.75, 6.76, 6.77, 6.77,
  6.81, 6.83, 7.05, 6.66, 6.7, 6.77, 6.71, 6.4, 6.7, 6.83, 5.78, 4.19, 3.84, 4.2, 4.29, 4.24, 4.9,
  6.38, 6.66, 6.71, 6.73, 6.76, 6.76, 6.6, 6.64, 6.65, 6.84, 6.46, 6.46, 6.55, 6.5, 6.22, 6.61,
  6.77, 5.74, 4.18, 3.84, 4.22, 4.29, 4.25, 4.9, 6.34, 6.65, 6.68, 6.71, 6.75, 6.75, 6.76, 6.78,
  6.81, 7.03, 6.68, 6.67, 6.75, 6.7, 6.42, 6.66, 6.78, 5.74, 4.18, 3.84, 4.2, 4.29, 4.24, 4.9, 6.26,
  6.51, 6.55, 6.57, 6.59, 6.59, 6.59, 6.62, 6.65, 6.85, 6.51, 6.51, 6.57, 6.54, 6.26, 6.65, 6.82,
  5.78, 4.2, 3.84, 4.2, 4.28, 4.23, 4.89, 6.22, 6.51, 6.55, 7.59, 7.6, 7.59, 7.59, 7.6, 7.61, 7.61,
  7.16, 7.14, 7.23, 7.16, 6.89, 6.82, 6.84, 5.96, 4.39, 3.92, 4.24, 4.56, 4.42, 6.33, 6.84, 7.54,
  7.56, 7.53, 7.54, 7.53, 7.53, 7.54, 7.55, 7.56, 7.11, 7.09, 7.22, 7.16, 6.89, 6.84, 6.88, 5.99,
  4.41, 3.91, 4.26, 4.57, 4.44, 6.33, 6.84, 7.51, 7.54, 6.78, 6.81, 6.81, 6.82, 6.84, 6.88, 7.09,
  6.72, 6.75, 6.82, 6.7, 6.23, 6.64, 6.87, 5.83, 4.22, 3.8, 4.2, 4.28, 4.23, 4.89, 6.43, 6.72, 6.76,
  6.75, 6.76, 6.77, 6.77, 6.81, 6.83, 7.05, 6.66, 6.7, 6.77, 6.71, 6.4, 6.7, 6.83, 5.78, 4.19, 3.84,
  4.2, 4.29, 4.24, 4.9, 6.38, 6.66, 6.71,
];

export default smallHotelLoadProfile;
