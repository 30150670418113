import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import VehicleDialogDetails from '@generic/components/VehicleSetDialog/VehicleDetails';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1.125rem',
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
}));

export default function VehicleDetails({inputs, handleInputChange}) {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const {vehicleSets} = inputs;
  // remove computed vehicle so it isn't included when we set the vehicle sets input
  const {vehicle, ...restOfVehicleSet} = vehicleSets[0];

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h2" className={classes.header}>
          {formatMessage({id: 'wizard.charging.vehicleDetails.header'})}
        </Typography>
        <Typography className={classes.header}>
          {formatMessage({id: 'wizard.charging.vehicleDetails.subheader'})}
        </Typography>
      </Box>
      <VehicleDialogDetails
        vehicleSet={restOfVehicleSet}
        onChangeVehicleSet={(newData) =>
          handleInputChange('vehicleSets', [{...restOfVehicleSet, ...newData}])
        }
        variant="gray"
      />
    </Box>
  );
}
