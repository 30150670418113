import i18n from 'i18next';
import merge from 'lodash/merge';
import {initReactI18next} from 'react-i18next';
/* eslint-disable import/no-duplicates */
import localTranslations from '@generic/config/translations/translations';
import baseTranslations from '../config/translations/translations';
/* eslint-enable import/no-duplicates */

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = merge({}, baseTranslations, localTranslations);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    keySeparator: '.',
    fallbackLng: 'en',
  });

export default i18n;
