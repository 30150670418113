const largeHotelLoadProfile = [
  5.63, 5.47, 4.68, 4.19, 4.29, 4.74, 5.69, 6.57, 7.73, 7.99, 8.04, 7.32, 6.6, 6.11, 5.74, 5.79,
  5.19, 5.87, 6.02, 6.18, 6.24, 6.48, 6.87, 6.89, 5.62, 5.45, 4.67, 4.19, 4.27, 4.72, 5.68, 6.51,
  7.72, 8.0, 8.03, 7.32, 6.59, 6.1, 5.73, 5.79, 5.18, 5.89, 6.02, 6.19, 6.25, 6.49, 6.87, 6.89,
  5.62, 5.44, 4.67, 4.2, 4.27, 4.72, 5.69, 6.54, 7.72, 7.98, 8.02, 7.32, 6.59, 6.09, 5.74, 5.78,
  5.22, 5.89, 6.02, 6.18, 6.26, 6.49, 6.88, 6.89, 5.62, 5.45, 4.67, 4.18, 4.27, 4.72, 5.69, 6.54,
  7.72, 7.99, 8.02, 7.31, 6.58, 6.06, 5.72, 5.76, 5.19, 5.89, 6.01, 6.2, 6.25, 6.49, 6.87, 6.88,
  4.93, 4.93, 4.52, 4.47, 4.69, 4.94, 5.8, 6.48, 7.12, 7.44, 7.95, 7.93, 7.53, 7.35, 7.15, 7.21,
  6.39, 7.26, 7.53, 7.52, 7.39, 6.83, 6.46, 5.71, 5.0, 5.18, 4.76, 4.63, 4.72, 4.93, 5.5, 5.97,
  6.69, 7.17, 7.38, 7.33, 7.13, 7.26, 7.24, 7.34, 6.33, 6.9, 7.13, 7.1, 7.04, 6.72, 6.31, 5.59,
  5.62, 5.42, 4.66, 4.16, 4.25, 4.7, 5.68, 6.46, 7.74, 8.0, 8.04, 7.33, 6.61, 6.1, 5.73, 5.79, 5.17,
  5.89, 6.01, 6.19, 6.25, 6.49, 6.88, 6.9, 5.63, 5.47, 4.68, 4.19, 4.29, 4.74, 5.69, 6.57, 7.73,
  7.99, 8.04, 7.32, 6.6, 6.11, 5.74, 5.79, 5.19, 5.87, 6.02, 6.18, 6.24, 6.48, 6.87, 6.89, 5.62,
  5.45, 4.67, 4.19, 4.27, 4.72, 5.68, 6.51, 7.72, 8.0, 8.03, 7.32, 6.59, 6.1, 5.73, 5.79, 5.18,
  5.89, 6.02, 6.19, 6.25, 6.49, 6.87, 6.89, 5.62, 5.44, 4.67, 4.2, 4.27, 4.72, 5.69, 6.54, 7.72,
  7.98, 8.02, 7.32, 6.59, 6.09, 5.74, 5.78, 5.22, 5.89, 6.02, 6.18, 6.26, 6.49, 6.88, 6.89, 5.62,
  5.45, 4.67, 4.18, 4.27, 4.72, 5.69, 6.54, 7.72, 7.99, 8.02, 7.31, 6.58, 6.06, 5.72, 5.76, 5.19,
  5.89, 6.01, 6.2, 6.25, 6.49, 6.87, 6.88, 4.93, 4.93, 4.52, 4.47, 4.69, 4.94, 5.8, 6.48, 7.12,
  7.44, 7.95, 7.93, 7.53, 7.35, 7.15, 7.21, 6.39, 7.26, 7.53, 7.52, 7.39, 6.83, 6.46, 5.71, 5.0,
  5.18, 4.76, 4.63, 4.72, 4.93, 5.5, 5.97, 6.69, 7.17, 7.38, 7.33, 7.13, 7.26, 7.24, 7.34, 6.33,
  6.9, 7.13, 7.1, 7.04, 6.72, 6.31, 5.59, 5.62, 5.42, 4.66, 4.16, 4.25, 4.7, 5.68, 6.46, 7.74, 8.0,
  8.04, 7.33, 6.61, 6.1, 5.73, 5.79, 5.17, 5.89, 6.01, 6.19, 6.25, 6.49, 6.88, 6.9, 5.63, 5.47,
  4.68, 4.19, 4.29, 4.74, 5.69, 6.57, 7.73, 7.99, 8.04, 7.32, 6.6, 6.11, 5.74, 5.79, 5.19, 5.87,
  6.02, 6.18, 6.24, 6.48, 6.87, 6.89, 5.62, 5.45, 4.67, 4.19, 4.27, 4.72, 5.68, 6.51, 7.72, 8.0,
  8.03, 7.32, 6.59, 6.1, 5.73, 5.79, 5.18, 5.89, 6.02, 6.19, 6.25, 6.49, 6.87, 6.89, 5.62, 5.44,
  4.67, 4.2, 4.27, 4.72, 5.69, 6.54, 7.72, 7.98, 8.02, 7.32, 6.59, 6.09, 5.74, 5.78, 5.22, 5.89,
  6.02, 6.18, 6.26, 6.49, 6.88, 6.89, 5.62, 5.45, 4.67, 4.18, 4.27, 4.72, 5.69, 6.54, 7.72, 7.99,
  8.02, 7.31, 6.58, 6.06, 5.72, 5.76, 5.19, 5.89, 6.01, 6.2, 6.25, 6.49, 6.87, 6.88, 4.93, 4.93,
  4.52, 4.47, 4.69, 4.94, 5.8, 6.48, 7.12, 7.44, 7.95, 7.93, 7.53, 7.35, 7.15, 7.21, 6.39, 7.26,
  7.53, 7.52, 7.39, 6.83, 6.46, 5.71, 5.0, 5.18, 4.76, 4.63, 4.72, 4.93, 5.5, 5.97, 6.69, 7.17,
  7.38, 7.33, 7.13, 7.26, 7.24, 7.34, 6.33, 6.9, 7.13, 7.1, 7.04, 6.72, 6.31, 5.59, 5.62, 5.42,
  4.66, 4.16, 4.25, 4.7, 5.68, 6.46, 7.74, 8.0, 8.04, 7.33, 6.61, 6.1, 5.73, 5.79, 5.17, 5.89, 6.01,
  6.19, 6.25, 6.49, 6.88, 6.9, 5.63, 5.47, 4.68, 4.19, 4.29, 4.74, 5.69, 6.57, 7.73, 7.99, 8.04,
  7.32, 6.6, 6.11, 5.74, 5.79, 5.19, 5.87, 6.02, 6.18, 6.24, 6.48, 6.87, 6.89, 5.62, 5.45, 4.67,
  4.19, 4.27, 4.72, 5.68, 6.51, 7.72, 8.0, 8.03, 7.32, 6.59, 6.1, 5.73, 5.79, 5.18, 5.89, 6.02,
  6.19, 6.25, 6.49, 6.87, 6.89, 5.62, 5.44, 4.67, 4.2, 4.27, 4.72, 5.69, 6.54, 7.72, 7.98, 8.02,
  7.32, 6.59, 6.09, 5.74, 5.78, 5.22, 5.89, 6.02, 6.18, 6.26, 6.49, 6.88, 6.89, 5.62, 5.45, 4.67,
  4.18, 4.27, 4.72, 5.69, 6.54, 7.72, 7.99, 8.02, 7.31, 6.58, 6.06, 5.72, 5.76, 5.19, 5.89, 6.01,
  6.2, 6.25, 6.49, 6.87, 6.88, 4.93, 4.93, 4.52, 4.47, 4.69, 4.94, 5.8, 6.48, 7.12, 7.44, 7.95,
  7.93, 7.53, 7.35, 7.15, 7.21, 6.39, 7.26, 7.53, 7.52, 7.39, 6.83, 6.46, 5.71, 5.0, 5.18, 4.76,
  4.63, 4.72, 4.93, 5.5, 5.97, 6.69, 7.17, 7.38, 7.33, 7.13, 7.26, 7.24, 7.34, 6.33, 6.9, 7.13, 7.1,
  7.04, 6.72, 6.31, 5.59, 5.62, 5.42, 4.66, 4.16, 4.25, 4.7, 5.68, 6.46, 7.74, 8.0, 8.04, 7.33,
  6.61, 6.1, 5.73, 5.79, 5.17, 5.89, 6.01, 6.19, 6.25, 6.49, 6.88, 6.9, 5.63, 5.47, 4.68, 4.19,
  4.29, 4.74, 5.69, 6.57, 7.73, 7.99, 8.04, 7.32, 6.6, 6.11, 5.74, 5.79, 5.19, 5.87, 6.02, 6.18,
  6.24, 6.48, 6.87, 6.89, 5.62, 5.45, 4.67, 4.19, 4.27, 4.72, 5.68, 6.51, 7.72, 8.0, 8.03, 7.32,
  6.59, 6.1, 5.73, 5.79, 5.18, 5.89, 6.02, 6.19, 6.25, 6.49, 6.87, 6.89, 5.62, 5.44, 4.67, 4.2,
  4.27, 4.72, 5.69, 6.54, 7.72, 7.98, 8.02, 7.32, 6.59, 6.09, 5.74, 5.78, 5.22, 5.89, 6.02, 6.18,
  6.26, 6.49, 6.88, 6.89, 5.62, 5.44, 4.66, 4.16, 4.26, 4.72, 5.61, 6.4, 7.77, 8.07, 8.12, 7.38,
  6.62, 6.09, 5.69, 5.74, 5.06, 5.79, 6.02, 6.21, 6.24, 6.5, 6.88, 6.89, 4.93, 4.95, 4.53, 4.47,
  4.67, 4.94, 5.72, 6.38, 7.12, 7.44, 7.95, 7.92, 7.55, 7.36, 7.17, 7.23, 6.3, 7.18, 7.54, 7.52,
  7.39, 6.83, 6.47, 5.69, 5.02, 5.22, 4.75, 4.63, 4.73, 4.94, 5.45, 5.88, 6.69, 7.16, 7.38, 7.33,
  7.17, 7.27, 7.27, 7.36, 6.26, 6.83, 7.14, 7.1, 7.04, 6.75, 6.32, 5.59, 5.63, 5.46, 4.67, 4.17,
  4.26, 4.72, 5.59, 6.42, 7.75, 8.01, 8.05, 7.33, 6.58, 6.07, 5.69, 5.75, 5.05, 5.78, 6.02, 6.19,
  6.26, 6.49, 6.88, 6.91, 5.63, 5.46, 4.68, 4.17, 4.29, 4.72, 5.6, 6.42, 7.74, 8.02, 8.06, 7.33,
  6.6, 6.09, 5.74, 5.77, 5.07, 5.8, 6.03, 6.2, 6.26, 6.5, 6.89, 6.91, 5.63, 5.46, 4.67, 4.19, 4.28,
  4.73, 5.6, 6.43, 7.74, 8.0, 8.03, 7.32, 6.58, 6.07, 5.7, 5.72, 5.03, 5.75, 6.01, 6.18, 6.24, 6.49,
  6.87, 6.9, 5.63, 5.45, 4.67, 4.17, 4.26, 4.72, 5.62, 6.41, 7.75, 8.03, 8.08, 7.36, 6.6, 6.06, 5.7,
  5.75, 5.07, 5.79, 6.04, 6.21, 6.26, 6.5, 6.89, 6.9, 5.62, 5.44, 4.66, 4.16, 4.26, 4.72, 5.61, 6.4,
  7.77, 8.07, 8.12, 7.38, 6.62, 6.09, 5.69, 5.74, 5.06, 5.79, 6.02, 6.21, 6.24, 6.5, 6.88, 6.89,
  4.93, 4.95, 4.53, 4.47, 4.67, 4.94, 5.72, 6.38, 7.12, 7.44, 7.95, 7.92, 7.55, 7.36, 7.17, 7.23,
  6.3, 7.18, 7.54, 7.52, 7.39, 6.83, 6.47, 5.69, 5.02, 5.22, 4.75, 4.63, 4.73, 4.94, 5.45, 5.88,
  6.69, 7.16, 7.38, 7.33, 7.17, 7.27, 7.27, 7.36, 6.26, 6.83, 7.14, 7.1, 7.04, 6.75, 6.32, 5.59,
  5.63, 5.46, 4.67, 4.17, 4.26, 4.72, 5.59, 6.42, 7.75, 8.01, 8.05, 7.33, 6.58, 6.07, 5.69, 5.75,
  5.05, 5.78, 6.02, 6.19, 6.26, 6.49, 6.88, 6.91, 5.63, 5.46, 4.68, 4.17, 4.29, 4.72, 5.6, 6.42,
  7.74, 8.02, 8.06, 7.33, 6.6, 6.09, 5.74, 5.77, 5.07, 5.8, 6.03, 6.2, 6.26, 6.5, 6.89, 6.91, 5.63,
  5.46, 4.67, 4.19, 4.28, 4.73, 5.6, 6.43, 7.74, 8.0, 8.03, 7.32, 6.58, 6.07, 5.7, 5.72, 5.03, 5.75,
  6.01, 6.18, 6.24, 6.49, 6.87, 6.9, 5.63, 5.45, 4.67, 4.17, 4.26, 4.72, 5.62, 6.41, 7.75, 8.03,
  8.08, 7.36, 6.6, 6.06, 5.7, 5.75, 5.07, 5.79, 6.04, 6.21, 6.26, 6.5, 6.89, 6.9, 5.62, 5.44, 4.66,
  4.16, 4.26, 4.72, 5.61, 6.4, 7.77, 8.07, 8.12, 7.38, 6.62, 6.09, 5.69, 5.74, 5.06, 5.79, 6.02,
  6.21, 6.24, 6.5, 6.88, 6.89, 4.93, 4.95, 4.53, 4.47, 4.67, 4.94, 5.72, 6.38, 7.12, 7.44, 7.95,
  7.92, 7.55, 7.36, 7.17, 7.23, 6.3, 7.18, 7.54, 7.52, 7.39, 6.83, 6.47, 5.69, 5.02, 5.22, 4.75,
  4.63, 4.73, 4.94, 5.45, 5.88, 6.69, 7.16, 7.38, 7.33, 7.17, 7.27, 7.27, 7.36, 6.26, 6.83, 7.14,
  7.1, 7.04, 6.75, 6.32, 5.59, 5.63, 5.46, 4.67, 4.17, 4.26, 4.72, 5.59, 6.42, 7.75, 8.01, 8.05,
  7.33, 6.58, 6.07, 5.69, 5.75, 5.05, 5.78, 6.02, 6.19, 6.26, 6.49, 6.88, 6.91, 5.63, 5.46, 4.68,
  4.17, 4.29, 4.72, 5.6, 6.42, 7.74, 8.02, 8.06, 7.33, 6.6, 6.09, 5.74, 5.77, 5.07, 5.8, 6.03, 6.2,
  6.26, 6.5, 6.89, 6.91, 5.63, 5.46, 4.67, 4.19, 4.28, 4.73, 5.6, 6.43, 7.74, 8.0, 8.03, 7.32, 6.58,
  6.07, 5.7, 5.72, 5.03, 5.75, 6.01, 6.18, 6.24, 6.49, 6.87, 6.9, 5.63, 5.45, 4.67, 4.17, 4.26,
  4.72, 5.62, 6.41, 7.75, 8.03, 8.08, 7.36, 6.6, 6.06, 5.7, 5.75, 5.07, 5.79, 6.04, 6.21, 6.26, 6.5,
  6.89, 6.9, 5.62, 5.44, 4.66, 4.16, 4.26, 4.72, 5.61, 6.4, 7.77, 8.07, 8.12, 7.38, 6.62, 6.09,
  5.69, 5.74, 5.06, 5.79, 6.02, 6.21, 6.24, 6.5, 6.88, 6.89, 4.93, 4.95, 4.53, 4.47, 4.67, 4.94,
  5.72, 6.38, 7.12, 7.44, 7.95, 7.92, 7.55, 7.36, 7.17, 7.23, 6.3, 7.18, 7.54, 7.52, 7.39, 6.83,
  6.47, 5.69, 5.02, 5.22, 4.75, 4.63, 4.73, 4.94, 5.45, 5.88, 6.69, 7.16, 7.38, 7.33, 7.17, 7.27,
  7.27, 7.36, 6.26, 6.83, 7.14, 7.1, 7.04, 6.75, 6.32, 5.59, 5.63, 5.46, 4.67, 4.17, 4.26, 4.72,
  5.59, 6.42, 7.75, 8.01, 8.05, 7.33, 6.58, 6.07, 5.69, 5.75, 5.05, 5.78, 6.02, 6.19, 6.26, 6.49,
  6.88, 6.91, 5.63, 5.46, 4.68, 4.17, 4.29, 4.72, 5.6, 6.42, 7.74, 8.02, 8.06, 7.33, 6.6, 6.09,
  5.74, 5.77, 5.07, 5.8, 6.03, 6.2, 6.26, 6.5, 6.89, 6.91, 5.63, 5.46, 4.67, 4.19, 4.28, 4.73, 5.6,
  6.43, 7.74, 8.0, 8.03, 7.32, 6.58, 6.07, 5.7, 5.72, 5.03, 5.75, 6.01, 6.18, 6.24, 6.49, 6.87, 6.9,
  5.63, 5.45, 4.67, 4.17, 4.26, 4.72, 5.62, 6.41, 7.75, 8.03, 8.08, 7.36, 6.6, 6.06, 5.7, 5.75,
  5.07, 5.79, 6.04, 6.21, 6.26, 6.5, 6.89, 6.9, 5.51, 4.96, 4.35, 4.21, 4.51, 5.26, 6.01, 7.24, 8.0,
  8.19, 7.84, 7.13, 6.47, 6.03, 5.94, 5.58, 5.5, 5.78, 6.2, 6.31, 6.45, 6.76, 6.92, 6.14, 4.94,
  4.71, 4.48, 4.58, 4.84, 5.41, 6.05, 6.8, 7.36, 7.79, 8.01, 7.81, 7.56, 7.38, 7.28, 6.74, 6.73,
  7.19, 7.57, 7.49, 7.12, 6.64, 6.0, 5.23, 5.12, 4.89, 4.68, 4.73, 4.89, 5.36, 5.7, 6.46, 7.05,
  7.32, 7.35, 7.22, 7.23, 7.31, 7.41, 6.61, 6.55, 6.84, 7.12, 7.07, 6.81, 6.4, 5.77, 5.15, 5.47,
  4.83, 4.26, 4.21, 4.58, 5.4, 6.11, 7.39, 7.95, 8.03, 7.48, 6.73, 6.14, 5.72, 5.66, 5.19, 5.35,
  5.67, 6.14, 6.24, 6.42, 6.75, 6.86, 5.91, 5.48, 4.84, 4.27, 4.22, 4.58, 5.4, 6.13, 7.4, 7.95,
  8.07, 7.61, 6.9, 6.37, 5.99, 5.93, 5.55, 5.73, 5.89, 6.29, 6.35, 6.5, 6.84, 6.92, 5.93, 5.48,
  4.85, 4.28, 4.24, 4.57, 5.42, 6.13, 7.41, 7.93, 8.0, 7.49, 6.71, 6.14, 5.69, 5.65, 5.16, 5.37,
  5.64, 6.15, 6.22, 6.41, 6.77, 6.85, 5.9, 5.5, 4.95, 4.34, 4.21, 4.51, 5.25, 6.0, 7.19, 7.9, 8.03,
  7.61, 6.88, 6.27, 5.85, 5.75, 5.33, 5.33, 5.7, 6.14, 6.25, 6.41, 6.76, 6.89, 6.12, 5.51, 4.96,
  4.35, 4.21, 4.51, 5.26, 6.01, 7.24, 8.0, 8.19, 7.84, 7.13, 6.47, 6.03, 5.94, 5.58, 5.5, 5.78, 6.2,
  6.31, 6.45, 6.76, 6.92, 6.14, 4.94, 4.71, 4.48, 4.58, 4.84, 5.41, 6.05, 6.8, 7.36, 7.79, 8.01,
  7.81, 7.56, 7.38, 7.28, 6.74, 6.73, 7.19, 7.57, 7.49, 7.12, 6.64, 6.0, 5.23, 5.12, 4.89, 4.73,
  4.89, 5.36, 5.7, 6.46, 7.05, 7.32, 7.35, 7.22, 7.23, 7.31, 7.41, 6.61, 6.55, 6.84, 7.12, 7.07,
  6.81, 6.4, 5.77, 5.15, 5.47, 4.83, 4.26, 4.21, 4.58, 5.4, 6.11, 7.39, 7.95, 8.03, 7.48, 6.73,
  6.14, 5.72, 5.66, 5.19, 5.35, 5.67, 6.14, 6.24, 6.42, 6.75, 6.86, 5.91, 5.48, 4.84, 4.27, 4.22,
  4.58, 5.4, 6.13, 7.4, 7.95, 8.07, 7.61, 6.9, 6.37, 5.99, 5.93, 5.55, 5.73, 5.89, 6.29, 6.35, 6.5,
  6.84, 6.92, 5.93, 5.48, 4.85, 4.28, 4.24, 4.57, 5.42, 6.13, 7.41, 7.93, 8.0, 7.49, 6.71, 6.14,
  5.69, 5.65, 5.16, 5.37, 5.64, 6.15, 6.22, 6.41, 6.77, 6.85, 5.9, 5.5, 4.95, 4.34, 4.21, 4.51,
  5.25, 6.0, 7.19, 7.9, 8.03, 7.61, 6.88, 6.27, 5.85, 5.75, 5.33, 5.33, 5.7, 6.14, 6.25, 6.41, 6.76,
  6.89, 6.12, 5.51, 4.96, 4.35, 4.21, 4.51, 5.26, 6.01, 7.24, 8.0, 8.19, 7.84, 7.13, 6.47, 6.03,
  5.94, 5.58, 5.5, 5.78, 6.2, 6.31, 6.45, 6.76, 6.92, 6.14, 4.94, 4.71, 4.48, 4.58, 4.84, 5.41,
  6.05, 6.8, 7.36, 7.79, 8.01, 7.81, 7.56, 7.38, 7.28, 6.74, 6.73, 7.19, 7.57, 7.49, 7.12, 6.64,
  6.0, 5.23, 5.12, 4.89, 4.68, 4.73, 4.89, 5.36, 5.7, 6.46, 7.05, 7.32, 7.35, 7.22, 7.23, 7.31,
  7.41, 6.61, 6.55, 6.84, 7.12, 7.07, 6.81, 6.4, 5.77, 5.15, 5.47, 4.83, 4.26, 4.21, 4.58, 5.4,
  6.11, 7.39, 7.95, 8.03, 7.48, 6.73, 6.14, 5.72, 5.66, 5.19, 5.35, 5.67, 6.14, 6.24, 6.42, 6.75,
  6.86, 5.91, 5.48, 4.84, 4.27, 4.22, 4.58, 5.4, 6.13, 7.4, 7.95, 8.07, 7.61, 6.9, 6.37, 5.99, 5.93,
  5.55, 5.73, 5.89, 6.29, 6.35, 6.5, 6.84, 6.92, 5.93, 5.48, 4.85, 4.28, 4.24, 4.57, 5.42, 6.13,
  7.41, 7.93, 8.0, 7.49, 6.71, 6.14, 5.69, 5.65, 5.16, 5.37, 5.64, 6.15, 6.22, 6.41, 6.77, 6.85,
  5.9, 5.5, 4.95, 4.34, 4.21, 4.51, 5.25, 6.0, 7.19, 7.9, 8.03, 7.61, 6.88, 6.27, 5.85, 5.75, 5.33,
  5.33, 5.7, 6.14, 6.25, 6.41, 6.76, 6.89, 6.12, 5.51, 4.96, 4.35, 4.21, 4.51, 5.26, 6.01, 7.24,
  8.0, 8.19, 7.84, 7.13, 6.47, 6.03, 5.94, 5.58, 5.5, 5.78, 6.2, 6.31, 6.45, 6.76, 6.92, 6.14, 4.94,
  4.71, 4.48, 4.58, 4.84, 5.41, 6.05, 6.8, 7.36, 7.79, 8.01, 7.81, 7.56, 7.38, 7.28, 6.74, 6.73,
  7.19, 7.57, 7.49, 7.12, 6.64, 6.0, 5.23, 5.12, 4.89, 4.68, 4.73, 4.89, 5.36, 5.7, 6.46, 7.05,
  7.32, 7.35, 7.22, 7.23, 7.31, 7.41, 6.61, 6.55, 6.84, 7.12, 7.07, 6.81, 6.4, 5.77, 5.15, 5.47,
  4.83, 4.26, 4.21, 4.58, 5.4, 6.11, 7.39, 7.95, 8.03, 7.48, 6.73, 6.14, 5.72, 5.66, 5.19, 5.35,
  5.67, 6.14, 6.24, 6.42, 6.75, 6.86, 5.91, 5.48, 4.84, 4.27, 4.22, 4.58, 5.4, 6.13, 7.4, 7.95,
  8.07, 7.61, 6.9, 6.37, 5.99, 5.93, 5.55, 5.73, 5.89, 6.29, 6.35, 6.5, 6.84, 6.92, 5.93, 5.48,
  4.85, 4.28, 4.24, 4.57, 5.42, 6.13, 7.41, 7.93, 8.0, 7.49, 6.71, 6.14, 5.69, 5.65, 5.16, 5.37,
  5.64, 6.15, 6.22, 6.41, 6.77, 6.85, 5.9, 5.5, 4.95, 4.34, 4.21, 4.51, 5.25, 6.0, 7.19, 7.9, 8.03,
  7.61, 6.88, 6.27, 5.85, 5.75, 5.33, 5.33, 5.7, 6.14, 6.25, 6.41, 6.76, 6.89, 6.12, 5.51, 4.96,
  4.35, 4.21, 4.51, 5.26, 6.01, 7.24, 8.0, 8.19, 7.84, 7.13, 6.47, 6.03, 5.94, 5.58, 5.5, 5.78, 6.2,
  6.31, 6.45, 6.76, 6.92, 6.14, 4.94, 4.71, 4.48, 4.58, 4.84, 5.41, 6.05, 6.8, 7.36, 7.79, 8.01,
  7.81, 7.56, 7.38, 7.28, 6.74, 6.73, 7.19, 7.57, 7.49, 7.12, 6.64, 6.0, 5.23, 5.12, 4.89, 4.68,
  4.73, 4.89, 5.36, 5.7, 6.46, 7.05, 7.32, 7.35, 7.22, 7.23, 7.31, 7.41, 6.61, 6.55, 6.84, 7.12,
  7.07, 6.81, 6.4, 5.77, 5.15, 5.43, 4.65, 4.13, 4.22, 4.67, 5.39, 6.33, 7.77, 8.09, 8.17, 7.45,
  6.7, 6.18, 5.85, 5.99, 5.43, 5.89, 5.97, 6.31, 6.45, 6.64, 6.89, 6.85, 5.6, 5.42, 4.62, 4.13,
  4.22, 4.66, 5.43, 6.31, 7.75, 8.06, 8.13, 7.45, 6.7, 6.14, 5.75, 5.78, 5.13, 5.54, 5.66, 6.07,
  6.25, 6.46, 6.85, 6.85, 5.6, 5.4, 4.62, 4.13, 4.24, 4.66, 5.4, 6.35, 7.71, 7.97, 8.0, 7.24, 6.52,
  5.94, 5.59, 5.66, 5.07, 5.54, 5.68, 6.07, 6.23, 6.46, 6.83, 6.83, 5.59, 5.4, 4.61, 4.12, 4.21,
  4.65, 5.37, 6.32, 7.72, 8.02, 8.11, 7.4, 6.71, 6.16, 5.79, 5.81, 5.23, 5.64, 5.75, 6.12, 6.26,
  6.5, 6.86, 6.84, 5.61, 5.42, 4.63, 4.14, 4.22, 4.68, 5.39, 6.34, 7.8, 8.13, 8.32, 7.74, 7.16, 6.7,
  6.38, 6.45, 5.8, 6.12, 6.2, 6.61, 6.8, 7.01, 7.35, 7.29, 5.86, 5.16, 4.73, 4.61, 4.85, 5.08, 5.7,
  6.68, 7.52, 8.0, 8.87, 8.99, 8.67, 8.48, 8.22, 8.2, 7.29, 7.83, 7.9, 7.93, 7.83, 7.21, 6.77, 6.0,
  5.15, 5.28, 4.81, 4.67, 4.74, 4.94, 5.26, 5.79, 6.63, 7.13, 7.38, 7.37, 7.19, 7.31, 7.34, 7.47,
  6.47, 6.78, 6.88, 6.99, 7.01, 6.71, 6.28, 5.56, 5.02, 5.43, 4.65, 4.13, 4.22, 4.67, 5.39, 6.33,
  7.77, 8.09, 8.17, 7.45, 6.7, 6.18, 5.85, 5.99, 5.43, 5.89, 5.97, 6.31, 6.45, 6.64, 6.89, 6.85,
  5.6, 5.42, 4.62, 4.13, 4.22, 4.66, 5.43, 6.31, 7.75, 8.06, 8.13, 7.45, 6.7, 6.14, 5.75, 5.78,
  5.13, 5.54, 5.66, 6.07, 6.25, 6.46, 6.85, 6.85, 5.6, 5.4, 4.62, 4.13, 4.24, 4.66, 5.4, 6.35, 7.71,
  7.97, 8.0, 7.24, 6.52, 5.94, 5.59, 5.66, 5.07, 5.54, 5.68, 6.07, 6.23, 6.46, 6.83, 6.83, 5.59,
  5.4, 4.61, 4.12, 4.21, 4.65, 5.37, 6.32, 7.72, 8.02, 8.11, 7.4, 6.71, 6.16, 5.79, 5.81, 5.23,
  5.64, 5.75, 6.12, 6.26, 6.5, 6.86, 6.84, 5.61, 5.42, 4.63, 4.14, 4.22, 4.68, 5.39, 6.34, 7.8,
  8.13, 8.32, 7.74, 7.16, 6.7, 6.38, 6.45, 5.8, 6.12, 6.2, 6.61, 6.8, 7.01, 7.35, 7.29, 5.86, 5.16,
  4.73, 4.61, 4.85, 5.08, 5.7, 6.68, 7.52, 8.0, 8.87, 8.99, 8.67, 8.48, 8.22, 8.2, 7.29, 7.83, 7.9,
  7.93, 7.83, 7.21, 6.77, 6.0, 5.15, 5.28, 4.81, 4.67, 4.74, 4.94, 5.26, 5.79, 6.63, 7.13, 7.38,
  7.37, 7.19, 7.31, 7.34, 7.47, 6.47, 6.78, 6.88, 6.99, 7.01, 6.71, 6.28, 5.56, 5.02, 5.43, 4.65,
  4.13, 4.22, 4.67, 5.39, 6.33, 7.77, 8.09, 8.17, 7.45, 6.7, 6.18, 5.85, 5.99, 5.43, 5.89, 5.97,
  6.31, 6.45, 6.64, 6.89, 6.85, 5.6, 5.42, 4.62, 4.13, 4.22, 4.66, 5.43, 6.31, 7.75, 8.06, 8.13,
  7.45, 6.7, 6.14, 5.75, 5.78, 5.13, 5.54, 5.66, 6.07, 6.25, 6.46, 6.85, 6.85, 5.6, 5.4, 4.62, 4.13,
  4.24, 4.66, 5.4, 6.35, 7.71, 7.97, 8.0, 7.24, 6.52, 5.94, 5.59, 5.66, 5.07, 5.54, 5.68, 6.07,
  6.23, 6.46, 6.83, 6.83, 5.59, 5.4, 4.61, 4.12, 4.21, 4.65, 5.37, 6.32, 7.72, 8.02, 8.11, 7.4,
  6.71, 6.16, 5.79, 5.81, 5.23, 5.64, 5.75, 6.12, 6.26, 6.5, 6.86, 6.84, 5.61, 5.42, 4.63, 4.14,
  4.22, 4.68, 5.39, 6.34, 7.8, 8.13, 8.32, 7.74, 7.16, 6.7, 6.38, 6.45, 5.8, 6.12, 6.2, 6.61, 6.8,
  7.01, 7.35, 7.29, 5.86, 5.16, 4.73, 4.61, 4.85, 5.08, 5.7, 6.68, 7.52, 8.0, 8.87, 8.99, 8.67,
  8.48, 8.22, 8.2, 7.29, 7.83, 7.9, 7.93, 7.83, 7.21, 6.77, 6.0, 5.15, 5.28, 4.81, 4.67, 4.74, 4.94,
  5.26, 5.79, 6.63, 7.13, 7.38, 7.37, 7.19, 7.31, 7.34, 7.47, 6.47, 6.78, 6.88, 6.99, 7.01, 6.71,
  6.28, 5.56, 5.02, 5.43, 4.65, 4.13, 4.22, 4.67, 5.39, 6.33, 7.77, 8.09, 8.17, 7.45, 6.7, 6.18,
  5.85, 5.99, 5.43, 5.89, 5.97, 6.31, 6.45, 6.64, 6.89, 6.85, 5.6, 5.42, 4.62, 4.13, 4.22, 4.66,
  5.43, 6.31, 7.75, 8.06, 8.13, 7.45, 6.7, 6.14, 5.75, 5.78, 5.13, 5.54, 5.66, 6.07, 6.25, 6.46,
  6.85, 6.85, 5.6, 5.4, 4.62, 4.13, 4.24, 4.66, 5.4, 6.35, 7.71, 7.97, 8.0, 7.24, 6.52, 5.94, 5.59,
  5.66, 5.07, 5.54, 5.68, 6.07, 6.23, 6.46, 6.83, 6.83, 5.59, 5.4, 4.61, 4.12, 4.21, 4.65, 5.37,
  6.32, 7.72, 8.02, 8.11, 7.4, 6.71, 6.16, 5.79, 5.81, 5.23, 5.64, 5.75, 6.12, 6.26, 6.5, 6.86,
  6.84, 5.61, 5.42, 4.63, 4.14, 4.22, 4.68, 5.39, 6.34, 7.8, 8.13, 8.32, 7.74, 7.16, 6.7, 6.38,
  6.45, 5.8, 6.12, 6.2, 6.61, 6.8, 7.01, 7.35, 7.29, 5.86, 5.16, 4.73, 4.61, 4.85, 5.08, 5.7, 6.68,
  7.52, 8.0, 8.87, 8.99, 8.67, 8.48, 8.22, 8.2, 7.29, 7.83, 7.9, 7.93, 7.83, 7.21, 6.77, 6.0, 5.15,
  5.28, 4.81, 4.67, 4.74, 4.94, 5.26, 5.79, 6.63, 7.13, 7.38, 7.37, 7.19, 7.31, 7.34, 7.47, 6.47,
  6.78, 6.88, 6.99, 7.01, 6.71, 6.28, 5.56, 5.02, 5.43, 4.65, 4.13, 4.22, 4.67, 5.39, 6.33, 7.77,
  8.09, 8.17, 7.45, 6.7, 6.18, 5.85, 5.99, 5.43, 5.89, 5.97, 6.31, 6.45, 6.64, 6.89, 6.85, 5.6,
  5.42, 4.62, 4.13, 4.22, 4.66, 5.43, 6.31, 7.75, 8.06, 8.13, 7.45, 6.7, 6.14, 5.75, 5.78, 5.13,
  5.54, 5.66, 6.07, 6.25, 6.46, 6.85, 6.85, 5.6, 5.87, 5.03, 4.51, 4.58, 4.91, 5.81, 7.38, 8.95,
  9.53, 9.76, 9.11, 8.38, 7.94, 7.65, 7.75, 7.42, 7.74, 7.7, 7.44, 7.44, 7.55, 7.95, 7.95, 6.27,
  6.01, 5.18, 4.63, 4.7, 4.98, 5.81, 7.34, 8.95, 9.59, 9.94, 9.58, 9.04, 8.66, 8.5, 8.5, 7.89, 8.11,
  8.12, 8.02, 8.08, 8.14, 8.31, 8.05, 6.32, 5.87, 5.03, 4.43, 4.49, 4.77, 5.6, 6.89, 8.43, 8.92,
  8.99, 8.21, 7.39, 6.84, 6.54, 6.71, 6.05, 6.2, 6.19, 6.28, 6.55, 6.71, 7.05, 6.99, 5.74, 5.03,
  4.59, 4.52, 4.78, 4.95, 5.61, 6.67, 7.47, 8.01, 8.76, 8.89, 8.56, 8.4, 8.11, 8.0, 6.98, 7.39,
  7.57, 7.53, 7.65, 7.03, 6.59, 5.78, 5.02, 5.23, 4.8, 4.65, 4.74, 4.82, 5.15, 5.82, 6.57, 7.14,
  7.39, 7.37, 7.24, 7.39, 7.39, 7.52, 6.57, 6.81, 6.96, 6.86, 7.04, 6.72, 6.25, 5.53, 5.03, 5.4,
  4.61, 4.11, 4.2, 4.51, 5.33, 6.41, 7.85, 8.35, 8.63, 8.09, 7.44, 7.02, 6.84, 7.11, 6.67, 6.96,
  6.93, 6.96, 7.16, 7.31, 7.69, 7.59, 6.07, 5.7, 4.92, 4.41, 4.47, 4.81, 5.65, 7.03, 8.57, 9.09,
  9.36, 8.75, 8.04, 7.56, 7.4, 7.57, 7.08, 7.25, 7.17, 7.13, 7.28, 7.35, 7.73, 7.74, 6.14, 5.87,
  5.03, 4.51, 4.58, 4.91, 5.81, 7.38, 8.95, 9.53, 9.76, 9.11, 8.38, 7.94, 7.65, 7.75, 7.42, 7.74,
  7.7, 7.44, 7.44, 7.55, 7.95, 7.95, 6.27, 6.01, 5.18, 4.63, 4.7, 4.98, 5.81, 7.34, 8.95, 9.59,
  9.94, 9.58, 9.04, 8.66, 8.5, 8.5, 7.89, 8.11, 8.12, 8.02, 8.08, 8.14, 8.31, 8.05, 6.32, 5.87,
  5.03, 4.43, 4.49, 4.77, 5.6, 6.89, 8.43, 8.92, 8.99, 8.21, 7.39, 6.84, 6.54, 6.71, 6.05, 6.2,
  6.19, 6.28, 6.55, 6.71, 7.05, 6.99, 5.74, 5.03, 4.59, 4.52, 4.78, 4.95, 5.61, 6.67, 7.47, 8.01,
  8.76, 8.89, 8.56, 8.4, 8.11, 8.0, 6.98, 7.39, 7.57, 7.53, 7.65, 7.03, 6.59, 5.78, 5.02, 5.23, 4.8,
  4.65, 4.74, 4.82, 5.15, 5.82, 6.57, 7.14, 7.39, 7.37, 7.24, 7.39, 7.39, 7.52, 6.57, 6.81, 6.96,
  6.86, 7.04, 6.72, 6.25, 5.53, 5.03, 5.4, 4.61, 4.11, 4.2, 4.51, 5.33, 6.41, 7.85, 8.35, 8.63,
  8.09, 7.44, 7.02, 6.84, 7.11, 6.67, 6.96, 6.93, 6.96, 7.16, 7.31, 7.69, 7.59, 6.07, 5.7, 4.92,
  4.41, 4.47, 4.81, 5.65, 7.03, 8.57, 9.09, 9.36, 8.75, 8.04, 7.56, 7.4, 7.57, 7.08, 7.25, 7.17,
  7.13, 7.28, 7.35, 7.73, 7.74, 6.14, 5.87, 5.03, 4.51, 4.58, 4.91, 5.81, 7.38, 8.95, 9.53, 9.76,
  9.11, 8.38, 7.94, 7.65, 7.75, 7.42, 7.74, 7.7, 7.44, 7.44, 7.55, 7.95, 7.95, 6.27, 6.01, 5.18,
  4.63, 4.7, 4.98, 5.81, 7.34, 8.95, 9.59, 9.94, 9.58, 9.04, 8.66, 8.5, 8.5, 7.89, 8.11, 8.12, 8.02,
  8.08, 8.14, 8.31, 8.05, 6.32, 5.87, 5.03, 4.43, 4.49, 4.77, 5.6, 6.89, 8.43, 8.92, 8.99, 8.21,
  7.39, 6.84, 6.54, 6.71, 6.05, 6.2, 6.19, 6.28, 6.55, 6.71, 7.05, 6.99, 5.74, 5.03, 4.59, 4.52,
  4.78, 4.95, 5.61, 6.67, 7.47, 8.01, 8.76, 8.89, 8.56, 8.4, 8.11, 8.0, 6.98, 7.39, 7.57, 7.53,
  7.65, 7.03, 6.59, 5.78, 5.02, 5.23, 4.8, 4.65, 4.74, 4.82, 5.15, 5.82, 6.57, 7.14, 7.39, 7.37,
  7.24, 7.39, 7.39, 7.52, 6.57, 6.81, 6.96, 6.86, 7.04, 6.72, 6.25, 5.53, 5.03, 5.4, 4.61, 4.11,
  4.2, 4.51, 5.33, 6.41, 7.85, 8.35, 8.63, 8.09, 7.44, 7.02, 6.84, 7.11, 6.67, 6.96, 6.93, 6.96,
  7.16, 7.31, 7.69, 7.59, 6.07, 5.7, 4.92, 4.41, 4.47, 4.81, 5.65, 7.03, 8.57, 9.09, 9.36, 8.75,
  8.04, 7.56, 7.4, 7.57, 7.08, 7.25, 7.17, 7.13, 7.28, 7.35, 7.73, 7.74, 6.14, 5.87, 5.03, 4.51,
  4.58, 4.91, 5.81, 7.38, 8.95, 9.53, 9.76, 9.11, 8.38, 7.94, 7.65, 7.75, 7.42, 7.74, 7.7, 7.44,
  7.44, 7.55, 7.95, 7.95, 6.27, 6.01, 5.18, 4.63, 4.7, 4.98, 5.81, 7.34, 8.95, 9.59, 9.94, 9.58,
  9.04, 8.66, 8.5, 8.5, 7.89, 8.11, 8.12, 8.02, 8.08, 8.14, 8.31, 8.05, 6.32, 5.87, 5.03, 4.43,
  4.49, 4.77, 5.6, 6.89, 8.43, 8.92, 8.99, 8.21, 7.39, 6.84, 6.54, 6.71, 6.05, 6.2, 6.19, 6.28,
  6.55, 6.71, 7.05, 6.99, 5.74, 5.03, 4.59, 4.52, 4.78, 4.95, 5.61, 6.67, 7.47, 8.01, 8.76, 8.89,
  8.56, 8.4, 8.11, 8.0, 6.98, 7.39, 7.57, 7.53, 7.65, 7.03, 6.59, 5.78, 5.02, 5.23, 4.8, 4.65, 4.74,
  4.82, 5.15, 5.82, 6.57, 7.14, 7.39, 7.37, 7.24, 7.39, 7.39, 7.52, 6.57, 6.81, 6.96, 6.86, 7.04,
  6.72, 6.25, 5.53, 5.03, 5.4, 4.61, 4.11, 4.2, 4.51, 5.33, 6.41, 7.85, 8.35, 8.63, 8.09, 7.44,
  7.02, 6.84, 7.11, 6.67, 6.96, 6.93, 6.96, 7.16, 7.31, 7.69, 7.59, 6.07, 5.7, 4.92, 4.41, 4.47,
  4.81, 5.65, 7.03, 8.57, 9.09, 9.36, 8.75, 8.04, 7.56, 7.4, 7.57, 7.08, 7.25, 7.17, 7.13, 7.28,
  7.35, 7.73, 7.74, 6.14, 5.87, 5.03, 4.51, 4.58, 4.91, 5.81, 7.38, 8.95, 9.53, 9.76, 9.11, 8.38,
  7.94, 7.65, 7.75, 7.42, 7.74, 7.7, 7.44, 7.44, 7.55, 7.95, 7.95, 6.27, 6.01, 5.18, 4.63, 4.7,
  4.98, 5.81, 7.34, 8.95, 9.59, 9.94, 9.58, 9.04, 8.66, 8.5, 8.5, 7.89, 8.11, 8.12, 8.02, 8.08,
  8.14, 8.31, 8.05, 6.32, 5.87, 5.03, 4.43, 4.49, 4.77, 5.6, 6.89, 8.43, 8.92, 8.99, 8.21, 7.39,
  6.84, 6.54, 6.71, 6.05, 6.2, 6.19, 6.28, 6.55, 6.71, 7.05, 6.99, 5.74, 5.9, 5.39, 5.26, 5.52,
  5.58, 6.35, 8.02, 8.84, 9.56, 10.38, 10.79, 10.89, 10.98, 10.75, 10.79, 10.09, 10.49, 10.52, 9.99,
  9.69, 8.93, 8.37, 7.43, 6.18, 6.28, 5.75, 5.53, 5.6, 5.59, 6.08, 7.47, 8.27, 9.2, 9.66, 9.89,
  9.87, 9.98, 9.9, 10.03, 9.15, 9.19, 9.09, 8.75, 8.62, 8.16, 7.58, 6.75, 5.88, 6.29, 5.45, 4.88,
  4.91, 5.15, 6.15, 7.9, 9.43, 9.98, 10.13, 9.34, 8.34, 7.63, 7.25, 7.26, 6.7, 6.85, 6.83, 6.95,
  7.16, 7.42, 7.74, 7.66, 6.18, 5.94, 5.11, 4.56, 4.64, 4.9, 5.84, 7.38, 8.92, 9.48, 9.68, 9.07,
  8.36, 7.91, 7.76, 7.88, 7.32, 7.48, 7.51, 7.42, 7.49, 7.74, 8.03, 7.9, 6.31, 6.07, 5.24, 4.68,
  4.74, 4.98, 5.93, 7.5, 9.12, 9.83, 10.02, 9.47, 8.8, 8.29, 8.0, 8.08, 7.46, 7.65, 7.69, 7.65,
  7.87, 8.23, 8.53, 8.46, 6.68, 6.4, 5.52, 4.95, 5.02, 5.23, 6.17, 7.93, 9.44, 10.04, 10.14, 9.47,
  8.72, 8.14, 7.9, 7.94, 7.5, 7.88, 8.03, 7.97, 8.05, 8.26, 8.62, 8.53, 6.69, 6.42, 5.5, 4.92, 4.95,
  5.16, 6.1, 7.87, 9.42, 9.98, 10.25, 9.66, 8.85, 8.26, 8.09, 8.39, 7.92, 8.05, 8.02, 8.04, 8.19,
  8.41, 8.57, 8.39, 6.64, 5.9, 5.39, 5.26, 5.52, 5.58, 6.35, 8.02, 8.84, 9.56, 10.38, 10.79, 10.89,
  10.98, 10.75, 10.79, 10.09, 10.49, 10.52, 9.99, 9.69, 8.93, 8.37, 7.43, 6.18, 6.28, 5.75, 5.53,
  5.6, 5.59, 6.08, 7.47, 8.27, 9.2, 9.66, 9.89, 9.87, 9.98, 9.9, 10.03, 9.15, 9.19, 9.09, 8.75,
  8.62, 8.16, 7.58, 6.75, 5.88, 6.29, 5.45, 4.88, 4.91, 5.15, 6.15, 7.9, 9.43, 9.98, 10.13, 9.34,
  8.34, 7.63, 7.25, 7.26, 6.7, 6.85, 6.83, 6.95, 7.16, 7.42, 7.74, 7.66, 6.18, 5.94, 5.11, 4.56,
  4.64, 4.9, 5.84, 7.38, 8.92, 9.48, 9.68, 9.07, 8.36, 7.91, 7.76, 7.88, 7.32, 7.48, 7.51, 7.42,
  7.49, 7.74, 8.03, 7.9, 6.31, 6.07, 5.24, 4.68, 4.74, 4.98, 5.93, 7.5, 9.12, 9.83, 10.02, 9.47,
  8.8, 8.29, 8.0, 8.08, 7.46, 7.65, 7.69, 7.65, 7.87, 8.23, 8.53, 8.46, 6.68, 6.4, 5.52, 4.95, 5.02,
  5.23, 6.17, 7.93, 9.44, 10.04, 10.14, 9.47, 8.72, 8.14, 7.9, 7.94, 7.5, 7.88, 8.03, 7.97, 8.05,
  8.26, 8.62, 8.53, 6.69, 6.42, 5.5, 4.92, 4.95, 5.16, 6.1, 7.87, 9.42, 9.98, 10.25, 9.66, 8.85,
  8.26, 8.09, 8.39, 7.92, 8.05, 8.02, 8.04, 8.19, 8.41, 8.57, 8.39, 6.64, 5.9, 5.39, 5.26, 5.52,
  5.58, 6.35, 8.02, 8.84, 9.56, 10.38, 10.79, 10.89, 10.98, 10.75, 10.79, 10.09, 10.49, 10.52, 9.99,
  9.69, 8.93, 8.37, 7.43, 6.18, 6.28, 5.75, 5.53, 5.6, 5.59, 6.08, 7.47, 8.27, 9.2, 9.66, 9.89,
  9.87, 9.98, 9.9, 10.03, 9.15, 9.19, 9.09, 8.75, 8.62, 8.16, 7.58, 6.75, 5.88, 6.29, 5.45, 4.88,
  4.91, 5.15, 6.15, 7.9, 9.43, 9.98, 10.13, 9.34, 8.34, 7.63, 7.25, 7.26, 6.7, 6.85, 6.83, 6.95,
  7.16, 7.42, 7.74, 7.66, 6.18, 5.94, 5.11, 4.56, 4.64, 4.9, 5.84, 7.38, 8.92, 9.48, 9.68, 9.07,
  8.36, 7.91, 7.76, 7.88, 7.32, 7.48, 7.51, 7.42, 7.49, 7.74, 8.03, 7.9, 6.31, 6.07, 5.24, 4.68,
  4.74, 4.98, 5.93, 7.5, 9.12, 9.83, 10.02, 9.47, 8.8, 8.29, 8.0, 8.08, 7.46, 7.65, 7.69, 7.65,
  7.87, 8.23, 8.53, 8.46, 6.68, 6.4, 5.52, 4.95, 5.02, 5.23, 6.17, 7.93, 9.44, 10.04, 10.14, 9.47,
  8.72, 8.14, 7.9, 7.94, 7.5, 7.88, 8.03, 7.97, 8.05, 8.26, 8.62, 8.53, 6.69, 6.42, 5.5, 4.92, 4.95,
  5.16, 6.1, 7.87, 9.42, 9.98, 10.25, 9.66, 8.85, 8.26, 8.09, 8.39, 7.92, 8.05, 8.02, 8.04, 8.19,
  8.41, 8.57, 8.39, 6.64, 5.9, 5.39, 5.26, 5.52, 5.58, 6.35, 8.02, 8.84, 9.56, 10.38, 10.79, 10.89,
  10.98, 10.75, 10.79, 10.09, 10.49, 10.52, 9.99, 9.69, 8.93, 8.37, 7.43, 6.18, 6.28, 5.75, 5.53,
  5.6, 5.59, 6.08, 7.47, 8.27, 9.2, 9.66, 9.89, 9.87, 9.98, 9.9, 10.03, 9.15, 9.19, 9.09, 8.75,
  8.62, 8.16, 7.58, 6.75, 5.88, 6.29, 5.45, 4.88, 4.91, 5.15, 6.15, 7.9, 9.43, 9.98, 10.13, 9.34,
  8.34, 7.63, 7.25, 7.26, 6.7, 6.85, 6.83, 6.95, 7.16, 7.42, 7.74, 7.66, 6.18, 5.94, 5.11, 4.56,
  4.64, 4.9, 5.84, 7.38, 8.92, 9.48, 9.68, 9.07, 8.36, 7.91, 7.76, 7.88, 7.32, 7.48, 7.51, 7.42,
  7.49, 7.74, 8.03, 7.9, 6.31, 6.07, 5.24, 4.68, 4.74, 4.98, 5.93, 7.5, 9.12, 9.83, 10.02, 9.47,
  8.8, 8.29, 8.0, 8.08, 7.46, 7.65, 7.69, 7.65, 7.87, 8.23, 8.53, 8.46, 6.68, 6.4, 5.52, 4.95, 5.02,
  5.23, 6.17, 7.93, 9.44, 10.04, 10.14, 9.47, 8.72, 8.14, 7.9, 7.94, 7.5, 7.88, 8.03, 7.97, 8.05,
  8.26, 8.62, 8.53, 6.69, 6.42, 5.5, 4.92, 4.95, 5.16, 6.1, 7.87, 9.42, 9.98, 10.25, 9.66, 8.85,
  8.26, 8.09, 8.39, 7.92, 8.05, 8.02, 8.04, 8.19, 8.41, 8.57, 8.39, 6.64, 5.9, 5.39, 5.26, 5.52,
  5.58, 6.35, 8.02, 8.84, 9.56, 10.38, 10.79, 10.89, 10.98, 10.75, 10.79, 10.09, 10.49, 10.52, 9.99,
  9.69, 8.93, 8.37, 7.43, 6.18, 6.28, 5.75, 5.53, 5.6, 5.59, 6.08, 7.47, 8.27, 9.2, 9.66, 9.89,
  9.87, 9.98, 9.9, 10.03, 9.15, 9.19, 9.09, 8.75, 8.62, 8.16, 7.58, 6.75, 5.88, 6.6, 5.71, 5.16,
  5.22, 5.52, 6.37, 8.4, 9.93, 10.57, 11.0, 10.47, 9.68, 9.17, 8.6, 8.45, 7.97, 8.5, 8.69, 8.64,
  8.66, 8.75, 9.1, 8.94, 6.9, 6.64, 5.76, 5.21, 5.29, 5.6, 6.5, 8.84, 10.45, 11.14, 11.39, 10.81,
  10.16, 9.79, 9.62, 9.9, 9.47, 9.71, 9.53, 9.36, 9.43, 9.51, 9.74, 9.49, 7.2, 7.03, 6.12, 5.57,
  5.64, 5.92, 6.83, 9.64, 11.21, 12.16, 12.75, 12.3, 11.5, 10.91, 10.56, 10.78, 10.41, 10.54, 10.4,
  10.15, 10.13, 10.43, 10.7, 10.27, 7.65, 7.36, 6.34, 5.72, 5.77, 6.04, 6.92, 9.75, 11.48, 12.38,
  12.63, 11.84, 10.59, 9.96, 9.78, 9.79, 9.31, 9.47, 9.37, 9.25, 9.2, 9.2, 9.47, 9.25, 7.12, 6.87,
  5.98, 5.4, 5.44, 5.69, 6.57, 8.83, 10.42, 11.14, 11.46, 10.76, 9.82, 9.37, 9.27, 9.64, 9.18, 9.36,
  9.27, 9.12, 9.14, 9.25, 9.45, 9.25, 7.15, 6.38, 5.88, 5.65, 5.88, 6.02, 6.75, 8.97, 9.74, 10.48,
  11.39, 11.68, 11.55, 11.59, 11.16, 10.92, 9.91, 10.21, 10.27, 10.06, 9.89, 9.02, 8.41, 7.36, 6.12,
  6.39, 5.82, 5.56, 5.63, 5.67, 6.06, 7.62, 8.47, 9.41, 10.02, 10.29, 10.24, 10.67, 10.59, 10.74,
  9.85, 9.85, 9.75, 9.27, 9.18, 8.72, 8.17, 7.27, 6.2, 6.6, 5.71, 5.16, 5.22, 5.52, 6.37, 8.4, 9.93,
  10.57, 11.0, 10.47, 9.68, 9.17, 8.6, 8.45, 7.97, 8.5, 8.69, 8.64, 8.66, 8.75, 9.1, 8.94, 6.9,
  6.64, 5.76, 5.21, 5.29, 5.6, 6.5, 8.84, 10.45, 11.14, 11.39, 10.81, 10.16, 9.79, 9.62, 9.9, 9.47,
  9.71, 9.53, 9.36, 9.43, 9.51, 9.74, 9.49, 7.2, 7.03, 6.12, 5.57, 5.64, 5.92, 6.83, 9.64, 11.21,
  12.16, 12.75, 12.3, 11.5, 10.91, 10.56, 10.78, 10.41, 10.54, 10.4, 10.15, 10.13, 10.43, 10.7,
  10.27, 7.65, 7.36, 6.34, 5.72, 5.77, 6.04, 6.92, 9.75, 11.48, 12.38, 12.63, 11.84, 10.59, 9.96,
  9.78, 9.79, 9.31, 9.47, 9.37, 9.25, 9.2, 9.2, 9.47, 9.25, 7.12, 6.87, 5.98, 5.4, 5.44, 5.69, 6.57,
  8.83, 10.42, 11.14, 11.46, 10.76, 9.82, 9.37, 9.27, 9.64, 9.18, 9.36, 9.27, 9.12, 9.14, 9.25,
  9.45, 9.25, 7.15, 6.38, 5.88, 5.65, 5.88, 6.02, 6.75, 8.97, 9.74, 10.48, 11.39, 11.68, 11.55,
  11.59, 11.16, 10.92, 9.91, 10.21, 10.27, 10.06, 9.89, 9.02, 8.41, 7.36, 6.12, 6.39, 5.82, 5.56,
  5.63, 5.67, 6.06, 7.62, 8.47, 9.41, 10.02, 10.29, 10.24, 10.67, 10.59, 10.74, 9.85, 9.85, 9.75,
  9.27, 9.18, 8.72, 8.17, 7.27, 6.2, 6.6, 5.71, 5.16, 5.22, 5.52, 6.37, 8.4, 9.93, 10.57, 11.0,
  10.47, 9.68, 9.17, 8.6, 8.45, 7.97, 8.5, 8.69, 8.64, 8.66, 8.75, 9.1, 8.94, 6.9, 6.64, 5.76, 5.21,
  5.29, 5.6, 6.5, 8.84, 10.45, 11.14, 11.39, 10.81, 10.16, 9.79, 9.62, 9.9, 9.47, 9.71, 9.53, 9.36,
  9.43, 9.51, 9.74, 9.49, 7.2, 7.03, 6.12, 5.57, 5.64, 5.92, 6.83, 9.64, 11.21, 12.16, 12.75, 12.3,
  11.5, 10.91, 10.56, 10.78, 10.41, 10.54, 10.4, 10.15, 10.13, 10.43, 10.7, 10.27, 7.65, 7.36, 6.34,
  5.72, 5.77, 6.04, 6.92, 9.75, 11.48, 12.38, 12.63, 11.84, 10.59, 9.96, 9.78, 9.79, 9.31, 9.47,
  9.37, 9.25, 9.2, 9.2, 9.47, 9.25, 7.12, 6.87, 5.98, 5.4, 5.44, 5.69, 6.57, 8.83, 10.42, 11.14,
  11.46, 10.76, 9.82, 9.37, 9.27, 9.64, 9.18, 9.36, 9.27, 9.12, 9.14, 9.25, 9.45, 9.25, 7.15, 6.38,
  5.88, 5.65, 5.88, 6.02, 6.75, 8.97, 9.74, 10.48, 11.39, 11.68, 11.55, 11.59, 11.16, 10.92, 9.91,
  10.21, 10.27, 10.06, 9.89, 9.02, 8.41, 7.36, 6.12, 6.39, 5.82, 5.56, 5.63, 5.67, 6.06, 7.62, 8.47,
  9.41, 10.02, 10.29, 10.24, 10.67, 10.59, 10.74, 9.85, 9.85, 9.75, 9.27, 9.18, 8.72, 8.17, 7.27,
  6.2, 6.6, 5.71, 5.16, 5.22, 5.52, 6.37, 8.4, 9.93, 10.57, 11.0, 10.47, 9.68, 9.17, 8.6, 8.45,
  7.97, 8.5, 8.69, 8.64, 8.66, 8.75, 9.1, 8.94, 6.9, 6.64, 5.76, 5.21, 5.29, 5.6, 6.5, 8.84, 10.45,
  11.14, 11.39, 10.81, 10.16, 9.79, 9.62, 9.9, 9.47, 9.71, 9.53, 9.36, 9.43, 9.51, 9.74, 9.49, 7.2,
  7.03, 6.12, 5.57, 5.64, 5.92, 6.83, 9.64, 11.21, 12.16, 12.75, 12.3, 11.5, 10.91, 10.56, 10.78,
  10.41, 10.54, 10.4, 10.15, 10.13, 10.43, 10.7, 10.27, 7.65, 7.36, 6.34, 5.72, 5.77, 6.04, 6.92,
  9.75, 11.48, 12.38, 12.63, 11.84, 10.59, 9.96, 9.78, 9.79, 9.31, 9.47, 9.37, 9.25, 9.2, 9.2, 9.47,
  9.25, 7.12, 6.87, 5.98, 5.4, 5.44, 5.69, 6.57, 8.83, 10.42, 11.14, 11.46, 10.76, 9.82, 9.37, 9.27,
  9.64, 9.18, 9.36, 9.27, 9.12, 9.14, 9.25, 9.45, 9.25, 7.15, 6.38, 5.88, 5.65, 5.88, 6.02, 6.75,
  8.97, 9.74, 10.48, 11.39, 11.68, 11.55, 11.59, 11.16, 10.92, 9.91, 10.21, 10.27, 10.06, 9.89,
  9.02, 8.41, 7.36, 6.12, 6.39, 5.82, 5.56, 5.63, 5.67, 6.06, 7.62, 8.47, 9.41, 10.02, 10.29, 10.24,
  10.67, 10.59, 10.74, 9.85, 9.85, 9.75, 9.27, 9.18, 8.72, 8.17, 7.27, 6.2, 6.6, 5.71, 5.16, 5.22,
  5.52, 6.37, 8.4, 9.93, 10.57, 11.0, 10.47, 9.68, 9.17, 8.6, 8.45, 7.97, 8.5, 8.69, 8.64, 8.66,
  8.75, 9.1, 8.94, 6.9, 6.64, 5.76, 5.21, 5.29, 5.6, 6.5, 8.84, 10.45, 11.14, 11.39, 10.81, 10.16,
  9.79, 9.62, 9.9, 9.47, 9.71, 9.53, 9.36, 9.43, 9.51, 9.74, 9.49, 7.2, 7.03, 6.12, 5.57, 5.64,
  5.92, 6.83, 9.64, 11.21, 12.16, 12.75, 12.3, 11.5, 10.91, 10.56, 10.78, 10.41, 10.54, 10.4, 10.15,
  10.13, 10.43, 10.7, 10.27, 7.65, 6.71, 5.76, 5.13, 5.16, 5.53, 6.24, 8.15, 9.75, 10.32, 10.59,
  9.98, 9.2, 8.69, 8.23, 8.33, 7.9, 8.16, 8.11, 8.22, 8.36, 8.53, 8.88, 8.72, 6.81, 6.57, 5.69,
  5.15, 5.25, 5.63, 6.36, 8.54, 10.03, 10.7, 11.05, 10.44, 9.69, 9.28, 9.12, 9.26, 8.69, 8.91, 8.85,
  8.83, 8.83, 8.96, 9.28, 9.09, 6.96, 6.29, 5.77, 5.61, 5.82, 6.06, 6.65, 8.79, 9.64, 10.44, 11.4,
  11.62, 11.34, 11.47, 11.11, 11.14, 10.39, 10.76, 10.64, 10.26, 10.08, 9.3, 8.72, 7.59, 6.21, 6.37,
  5.76, 5.55, 5.65, 5.8, 6.08, 7.69, 8.54, 9.5, 10.08, 10.41, 10.44, 10.78, 10.45, 10.39, 9.42,
  9.43, 9.28, 8.98, 9.0, 8.62, 8.09, 7.18, 6.1, 6.48, 5.6, 5.02, 5.05, 5.46, 6.13, 7.84, 9.36, 9.95,
  10.06, 9.34, 8.55, 8.11, 7.72, 7.79, 7.3, 7.5, 7.48, 7.64, 7.74, 7.78, 8.04, 7.93, 6.37, 6.16,
  5.3, 4.74, 4.81, 5.21, 5.94, 7.49, 9.08, 9.76, 10.01, 9.34, 8.49, 7.97, 7.61, 7.82, 7.51, 7.8,
  7.8, 7.82, 7.9, 8.07, 8.46, 8.36, 6.65, 6.46, 5.58, 5.01, 5.11, 5.51, 6.26, 8.19, 9.75, 10.51,
  10.82, 10.27, 9.52, 9.11, 9.05, 9.28, 8.81, 9.05, 8.89, 8.87, 9.0, 9.1, 9.36, 9.11, 7.0, 6.71,
  5.76, 5.13, 5.16, 5.53, 6.24, 8.15, 9.75, 10.32, 10.59, 9.98, 9.2, 8.69, 8.23, 8.33, 7.9, 8.16,
  8.11, 8.22, 8.36, 8.53, 8.88, 8.72, 6.81, 6.57, 5.69, 5.15, 5.25, 5.63, 6.36, 8.54, 10.03, 10.7,
  11.05, 10.44, 9.69, 9.28, 9.12, 9.26, 8.69, 8.91, 8.85, 8.83, 8.83, 8.96, 9.28, 9.09, 6.96, 6.29,
  5.77, 5.61, 5.82, 6.06, 6.65, 8.79, 9.64, 10.44, 11.4, 11.62, 11.34, 11.47, 11.11, 11.14, 10.39,
  10.76, 10.64, 10.26, 10.08, 9.3, 8.72, 7.59, 6.21, 6.37, 5.76, 5.55, 5.65, 5.8, 6.08, 7.69, 8.54,
  9.5, 10.08, 10.41, 10.44, 10.78, 10.45, 10.39, 9.42, 9.43, 9.28, 8.98, 9.0, 8.62, 8.09, 7.18, 6.1,
  6.48, 5.6, 5.02, 5.05, 5.46, 6.13, 7.84, 9.36, 9.95, 10.06, 9.34, 8.55, 8.11, 7.72, 7.79, 7.3,
  7.5, 7.48, 7.64, 7.74, 7.78, 8.04, 7.93, 6.37, 6.16, 5.3, 4.74, 4.81, 5.21, 5.94, 7.49, 9.08,
  9.76, 10.01, 9.34, 8.49, 7.97, 7.61, 7.82, 7.51, 7.8, 7.8, 7.82, 7.9, 8.07, 8.46, 8.36, 6.65,
  6.46, 5.58, 5.01, 5.11, 5.51, 6.26, 8.19, 9.75, 10.51, 10.82, 10.27, 9.52, 9.11, 9.05, 9.28, 8.81,
  9.05, 8.89, 8.87, 9.0, 9.1, 9.36, 9.11, 7.0, 6.71, 5.76, 5.13, 5.16, 5.53, 6.24, 8.15, 9.75,
  10.32, 10.59, 9.98, 9.2, 8.69, 8.23, 8.33, 7.9, 8.16, 8.11, 8.22, 8.36, 8.53, 8.88, 8.72, 6.81,
  6.57, 5.69, 5.15, 5.25, 5.63, 6.36, 8.54, 10.03, 10.7, 11.05, 10.44, 9.69, 9.28, 9.12, 9.26, 8.69,
  8.91, 8.85, 8.83, 8.83, 8.96, 9.28, 9.09, 6.96, 6.29, 5.77, 5.61, 5.82, 6.06, 6.65, 8.79, 9.64,
  10.44, 11.4, 11.62, 11.34, 11.47, 11.11, 11.14, 10.39, 10.76, 10.64, 10.26, 10.08, 9.3, 8.72,
  7.59, 6.21, 6.37, 5.76, 5.55, 5.65, 5.8, 6.08, 7.69, 8.54, 9.5, 10.08, 10.41, 10.44, 10.78, 10.45,
  10.39, 9.42, 9.43, 9.28, 8.98, 9.0, 8.62, 8.09, 7.18, 6.1, 6.48, 5.6, 5.02, 5.05, 5.46, 6.13,
  7.84, 9.36, 9.95, 10.06, 9.34, 8.55, 8.11, 7.72, 7.79, 7.3, 7.5, 7.48, 7.64, 7.74, 7.78, 8.04,
  7.93, 6.37, 6.16, 5.3, 4.74, 4.81, 5.21, 5.94, 7.49, 9.08, 9.76, 10.01, 9.34, 8.49, 7.97, 7.61,
  7.82, 7.51, 7.8, 7.8, 7.82, 7.9, 8.07, 8.46, 8.36, 6.65, 6.46, 5.58, 5.01, 5.11, 5.51, 6.26, 8.19,
  9.75, 10.51, 10.82, 10.27, 9.52, 9.11, 9.05, 9.28, 8.81, 9.05, 8.89, 8.87, 9.0, 9.1, 9.36, 9.11,
  7.0, 6.71, 5.76, 5.13, 5.16, 5.53, 6.24, 8.15, 9.75, 10.32, 10.59, 9.98, 9.2, 8.69, 8.23, 8.33,
  7.9, 8.16, 8.11, 8.22, 8.36, 8.53, 8.88, 8.72, 6.81, 6.57, 5.69, 5.15, 5.25, 5.63, 6.36, 8.54,
  10.03, 10.7, 11.05, 10.44, 9.69, 9.28, 9.12, 9.26, 8.69, 8.91, 8.85, 8.83, 8.83, 8.96, 9.28, 9.09,
  6.96, 6.29, 5.77, 5.61, 5.82, 6.06, 6.65, 8.79, 9.64, 10.44, 11.4, 11.62, 11.34, 11.47, 11.11,
  11.14, 10.39, 10.76, 10.64, 10.26, 10.08, 9.3, 8.72, 7.59, 6.21, 6.37, 5.76, 5.55, 5.65, 5.8,
  6.08, 7.69, 8.54, 9.5, 10.08, 10.41, 10.44, 10.78, 10.45, 10.39, 9.42, 9.43, 9.28, 8.98, 9.0,
  8.62, 8.09, 7.18, 6.1, 6.48, 5.6, 5.02, 5.05, 5.46, 6.13, 7.84, 9.36, 9.95, 10.06, 9.34, 8.55,
  8.11, 7.72, 7.79, 7.3, 7.5, 7.48, 7.64, 7.74, 7.78, 8.04, 7.93, 6.37, 6.16, 5.3, 4.74, 4.81, 5.21,
  5.94, 7.49, 9.08, 9.76, 10.01, 9.34, 8.49, 7.97, 7.61, 7.82, 7.51, 7.8, 7.8, 7.82, 7.9, 8.07,
  8.46, 8.36, 6.65, 6.46, 5.58, 5.01, 5.11, 5.51, 6.26, 8.19, 9.75, 10.51, 10.82, 10.27, 9.52, 9.11,
  9.05, 9.28, 8.81, 9.05, 8.89, 8.87, 9.0, 9.1, 9.36, 9.11, 7.0, 6.71, 5.76, 5.13, 5.16, 5.53, 6.24,
  8.15, 9.75, 10.32, 10.59, 9.98, 9.2, 8.69, 8.23, 8.33, 7.9, 8.16, 8.11, 8.22, 8.36, 8.53, 8.88,
  8.72, 6.81, 6.57, 5.69, 5.15, 5.25, 5.63, 6.36, 8.54, 10.03, 10.7, 11.05, 10.44, 9.69, 9.28, 9.12,
  9.26, 8.69, 8.91, 8.85, 8.83, 8.83, 8.96, 9.28, 9.09, 6.96, 6.29, 5.77, 5.61, 5.82, 6.06, 6.65,
  8.79, 9.64, 10.44, 11.4, 11.62, 11.34, 11.47, 11.11, 11.14, 10.39, 10.76, 10.64, 10.26, 10.08,
  9.3, 8.72, 7.59, 6.21, 5.61, 5.09, 4.9, 5.02, 5.2, 5.57, 6.23, 7.0, 7.8, 8.21, 8.38, 8.32, 8.63,
  8.64, 8.7, 7.77, 7.9, 7.98, 8.04, 7.93, 7.55, 7.03, 6.25, 5.53, 6.02, 5.17, 4.63, 4.72, 5.17, 6.0,
  7.39, 8.86, 9.42, 9.7, 9.12, 8.31, 7.84, 7.45, 7.52, 7.07, 7.26, 7.27, 7.55, 7.54, 7.7, 8.06,
  7.96, 6.35, 6.12, 5.25, 4.68, 4.74, 5.19, 6.03, 7.43, 8.9, 9.5, 9.77, 9.16, 8.38, 7.93, 7.61,
  7.63, 7.19, 7.38, 7.31, 7.58, 7.54, 7.66, 7.97, 7.84, 6.28, 6.04, 5.19, 4.66, 4.74, 5.19, 6.05,
  7.37, 8.86, 9.49, 9.82, 9.23, 8.38, 7.86, 7.52, 7.49, 7.01, 7.18, 7.22, 7.5, 7.51, 7.68, 8.03,
  7.95, 6.33, 6.12, 5.29, 4.75, 4.85, 5.29, 6.13, 7.54, 8.92, 9.4, 9.5, 8.81, 7.91, 7.36, 7.07,
  7.22, 6.87, 7.08, 7.09, 7.37, 7.4, 7.61, 7.99, 7.94, 6.39, 6.22, 5.38, 4.83, 4.91, 5.35, 6.24,
  7.7, 9.13, 9.69, 9.88, 9.25, 8.34, 7.75, 7.43, 7.5, 7.05, 7.34, 7.44, 7.79, 7.78, 7.87, 8.14,
  8.02, 6.4, 5.72, 5.23, 5.05, 5.31, 5.59, 6.29, 7.51, 8.19, 8.75, 9.41, 9.58, 9.28, 9.26, 8.96,
  8.93, 8.17, 8.51, 8.57, 8.56, 8.33, 7.65, 7.18, 6.34, 5.41, 5.61, 5.09, 4.9, 5.02, 5.2, 5.57,
  6.23, 7.0, 7.8, 8.21, 8.38, 8.32, 8.63, 8.64, 8.7, 7.77, 7.9, 7.98, 8.04, 7.93, 7.55, 7.03, 6.25,
  5.53, 6.02, 5.17, 4.63, 4.72, 5.17, 6.0, 7.39, 8.86, 9.42, 9.7, 9.12, 8.31, 7.84, 7.45, 7.52,
  7.07, 7.26, 7.27, 7.55, 7.54, 7.7, 8.06, 7.96, 6.35, 6.12, 5.25, 4.68, 4.74, 5.19, 6.03, 7.43,
  8.9, 9.5, 9.77, 9.16, 8.38, 7.93, 7.61, 7.63, 7.19, 7.38, 7.31, 7.58, 7.54, 7.66, 7.97, 7.84,
  6.28, 6.04, 5.19, 4.66, 4.74, 5.19, 6.05, 7.37, 8.86, 9.49, 9.82, 9.23, 8.38, 7.86, 7.52, 7.49,
  7.01, 7.18, 7.22, 7.5, 7.51, 7.68, 8.03, 7.95, 6.33, 6.12, 5.29, 4.75, 4.85, 5.29, 6.13, 7.54,
  8.92, 9.4, 9.5, 8.81, 7.91, 7.36, 7.07, 7.22, 6.87, 7.08, 7.09, 7.37, 7.4, 7.61, 7.99, 7.94, 6.39,
  6.22, 5.38, 4.83, 4.91, 5.35, 6.24, 7.7, 9.13, 9.69, 9.88, 9.25, 8.34, 7.75, 7.43, 7.5, 7.05,
  7.34, 7.44, 7.79, 7.78, 7.87, 8.14, 8.02, 6.4, 5.72, 5.23, 5.05, 5.31, 5.59, 6.29, 7.51, 8.19,
  8.75, 9.41, 9.58, 9.28, 9.26, 8.96, 8.93, 8.17, 8.51, 8.57, 8.56, 8.33, 7.65, 7.18, 6.34, 5.41,
  5.61, 5.09, 4.9, 5.02, 5.2, 5.57, 6.23, 7.0, 7.8, 8.21, 8.38, 8.32, 8.63, 8.64, 8.7, 7.77, 7.9,
  7.98, 8.04, 7.93, 7.55, 7.03, 6.25, 5.53, 6.02, 5.17, 4.63, 4.72, 5.17, 6.0, 7.39, 8.86, 9.42,
  9.7, 9.12, 8.31, 7.84, 7.45, 7.52, 7.07, 7.26, 7.27, 7.55, 7.54, 7.7, 8.06, 7.96, 6.35, 6.12,
  5.25, 4.68, 4.74, 5.19, 6.03, 7.43, 8.9, 9.5, 9.77, 9.16, 8.38, 7.93, 7.61, 7.63, 7.19, 7.38,
  7.31, 7.58, 7.54, 7.66, 7.97, 7.84, 6.28, 6.04, 5.19, 4.66, 4.74, 5.19, 6.05, 7.37, 8.86, 9.49,
  9.82, 9.23, 8.38, 7.86, 7.52, 7.49, 7.01, 7.18, 7.22, 7.5, 7.51, 7.68, 8.03, 7.95, 6.33, 6.12,
  5.29, 4.75, 4.85, 5.29, 6.13, 7.54, 8.92, 9.4, 9.5, 8.81, 7.91, 7.36, 7.07, 7.22, 6.87, 7.08,
  7.09, 7.37, 7.4, 7.61, 7.99, 7.94, 6.39, 6.22, 5.38, 4.83, 4.91, 5.35, 6.24, 7.7, 9.13, 9.69,
  9.88, 9.25, 8.34, 7.75, 7.43, 7.5, 7.05, 7.34, 7.44, 7.79, 7.78, 7.87, 8.14, 8.02, 6.4, 5.72,
  5.23, 5.05, 5.31, 5.59, 6.29, 7.51, 8.19, 8.75, 9.41, 9.58, 9.28, 9.26, 8.96, 8.93, 8.17, 8.51,
  8.57, 8.56, 8.33, 7.65, 7.18, 6.34, 5.41, 5.61, 5.09, 4.9, 5.02, 5.2, 5.57, 6.23, 7.0, 7.8, 8.21,
  8.38, 8.32, 8.63, 8.64, 8.7, 7.77, 7.9, 7.98, 8.04, 7.93, 7.55, 7.03, 6.25, 5.53, 6.02, 5.17,
  4.63, 4.72, 5.17, 6.0, 7.39, 8.86, 9.42, 9.7, 9.12, 8.31, 7.84, 7.45, 7.52, 7.07, 7.26, 7.27,
  7.55, 7.54, 7.7, 8.06, 7.96, 6.35, 6.12, 5.25, 4.68, 4.74, 5.19, 6.03, 7.43, 8.9, 9.5, 9.77, 9.16,
  8.38, 7.93, 7.61, 7.63, 7.19, 7.38, 7.31, 7.58, 7.54, 7.66, 7.97, 7.84, 6.28, 6.04, 5.19, 4.66,
  4.74, 5.19, 6.05, 7.37, 8.86, 9.49, 9.82, 9.23, 8.38, 7.86, 7.52, 7.49, 7.01, 7.18, 7.22, 7.5,
  7.51, 7.68, 8.03, 7.95, 6.33, 6.12, 5.29, 4.75, 4.85, 5.29, 6.13, 7.54, 8.92, 9.4, 9.5, 8.81,
  7.91, 7.36, 7.07, 7.22, 6.87, 7.08, 7.09, 7.37, 7.4, 7.61, 7.99, 7.94, 6.39, 6.22, 5.38, 4.83,
  4.91, 5.35, 6.24, 7.7, 9.13, 9.69, 9.88, 9.25, 8.34, 7.75, 7.43, 7.5, 7.05, 7.34, 7.44, 7.79,
  7.78, 7.87, 8.14, 8.02, 6.4, 5.72, 5.23, 5.05, 5.31, 5.59, 6.29, 7.51, 8.19, 8.75, 9.41, 9.58,
  9.28, 9.26, 8.96, 8.93, 8.17, 8.51, 8.57, 8.56, 8.33, 7.65, 7.18, 6.34, 5.41, 5.61, 5.09, 4.9,
  5.02, 5.2, 5.57, 6.23, 7.0, 7.8, 8.21, 8.38, 8.32, 8.63, 8.64, 8.7, 7.77, 7.9, 7.98, 8.04, 7.93,
  7.55, 7.03, 6.25, 5.53, 6.02, 5.17, 4.63, 4.72, 5.17, 6.0, 7.39, 8.86, 9.42, 9.7, 9.12, 8.31,
  7.84, 7.45, 7.52, 7.07, 7.26, 7.27, 7.55, 7.54, 7.7, 8.06, 7.96, 6.35, 5.46, 4.67, 4.17, 4.28,
  4.71, 5.67, 6.45, 7.75, 8.14, 8.21, 7.48, 6.74, 6.2, 5.86, 5.94, 5.48, 5.78, 6.16, 6.35, 6.34,
  6.53, 6.86, 6.83, 5.61, 5.4, 4.62, 4.13, 4.21, 4.65, 5.61, 6.36, 7.62, 8.04, 8.16, 7.47, 6.78,
  6.25, 5.87, 5.87, 5.32, 5.66, 6.05, 6.26, 6.29, 6.5, 6.88, 6.87, 5.65, 5.48, 4.68, 4.18, 4.28,
  4.75, 5.74, 6.6, 7.97, 8.38, 8.47, 7.7, 6.92, 6.34, 5.94, 5.96, 5.42, 5.75, 6.1, 6.32, 6.31, 6.51,
  6.86, 6.84, 5.61, 5.42, 4.63, 4.12, 4.22, 4.65, 5.61, 6.35, 7.61, 7.98, 8.06, 7.35, 6.63, 6.06,
  5.71, 5.78, 5.27, 5.6, 6.0, 6.2, 6.24, 6.44, 6.81, 6.77, 5.58, 4.91, 4.47, 4.38, 4.61, 4.88, 5.72,
  6.26, 6.89, 7.28, 7.82, 7.86, 7.52, 7.3, 7.16, 7.2, 6.44, 6.97, 7.47, 7.44, 7.27, 6.73, 6.31,
  5.56, 4.89, 5.12, 4.71, 4.6, 4.7, 4.9, 5.45, 5.75, 6.41, 6.96, 7.2, 7.21, 7.05, 7.17, 7.23, 7.31,
  6.35, 6.57, 7.07, 7.01, 6.94, 6.65, 6.19, 5.48, 4.98, 5.42, 4.64, 4.13, 4.21, 4.66, 5.63, 6.34,
  7.63, 8.06, 8.17, 7.48, 6.78, 6.24, 5.89, 5.97, 5.46, 5.78, 6.16, 6.35, 6.38, 6.6, 6.96, 6.94,
  5.68, 5.46, 4.67, 4.17, 4.28, 4.71, 5.67, 6.45, 7.75, 8.14, 8.21, 7.48, 6.74, 6.2, 5.86, 5.94,
  5.48, 5.78, 6.16, 6.35, 6.34, 6.53, 6.86, 6.83, 5.61, 5.4, 4.62, 4.13, 4.21, 4.65, 5.61, 6.36,
  7.62, 8.04, 8.16, 7.47, 6.78, 6.25, 5.87, 5.87, 5.32, 5.66, 6.05, 6.26, 6.29, 6.5, 6.88, 6.87,
  5.65, 5.48, 4.68, 4.18, 4.28, 4.75, 5.74, 6.6, 7.97, 8.38, 8.47, 7.7, 6.92, 6.34, 5.94, 5.96,
  5.42, 5.75, 6.1, 6.32, 6.31, 6.51, 6.86, 6.84, 5.61, 5.42, 4.63, 4.12, 4.22, 4.65, 5.61, 6.35,
  7.61, 7.98, 8.06, 7.35, 6.63, 6.06, 5.71, 5.78, 5.27, 5.6, 6.0, 6.2, 6.24, 6.44, 6.81, 6.77, 5.58,
  4.91, 4.47, 4.38, 4.61, 4.88, 5.72, 6.26, 6.89, 7.28, 7.82, 7.86, 7.52, 7.3, 7.16, 7.2, 6.44,
  6.97, 7.47, 7.44, 7.27, 6.73, 6.31, 5.56, 4.89, 5.12, 4.71, 4.6, 4.7, 4.9, 5.45, 5.75, 6.41, 6.96,
  7.2, 7.21, 7.05, 7.17, 7.23, 7.31, 6.35, 6.57, 7.07, 7.01, 6.94, 6.65, 6.19, 5.48, 4.98, 5.42,
  4.64, 4.13, 4.21, 4.66, 5.63, 6.34, 7.63, 8.06, 8.17, 7.48, 6.78, 6.24, 5.89, 5.97, 5.46, 5.78,
  6.16, 6.35, 6.38, 6.6, 6.96, 6.94, 5.68, 5.46, 4.67, 4.17, 4.28, 4.71, 5.67, 6.45, 7.75, 8.14,
  8.21, 7.48, 6.74, 6.2, 5.86, 5.94, 5.48, 5.78, 6.16, 6.35, 6.34, 6.53, 6.86, 6.83, 5.61, 5.4,
  4.62, 4.13, 4.21, 4.65, 5.61, 6.36, 7.62, 8.04, 8.16, 7.47, 6.78, 6.25, 5.87, 5.87, 5.32, 5.66,
  6.05, 6.26, 6.29, 6.5, 6.88, 6.87, 5.65, 5.48, 4.68, 4.18, 4.28, 4.75, 5.74, 6.6, 7.97, 8.38,
  8.47, 7.7, 6.92, 6.34, 5.94, 5.96, 5.42, 5.75, 6.1, 6.32, 6.31, 6.51, 6.86, 6.84, 5.61, 5.42,
  4.63, 4.12, 4.22, 4.65, 5.61, 6.35, 7.61, 7.98, 8.06, 7.35, 6.63, 6.06, 5.71, 5.78, 5.27, 5.6,
  6.0, 6.2, 6.24, 6.44, 6.81, 6.77, 5.58, 4.91, 4.47, 4.38, 4.61, 4.88, 5.72, 6.26, 6.89, 7.28,
  7.82, 7.86, 7.52, 7.3, 7.16, 7.2, 6.44, 6.97, 7.47, 7.44, 7.27, 6.73, 6.31, 5.56, 4.89, 5.12,
  4.71, 4.6, 4.7, 4.9, 5.45, 5.75, 6.41, 6.96, 7.2, 7.21, 7.05, 7.17, 7.23, 7.31, 6.35, 6.57, 7.07,
  7.01, 6.94, 6.65, 6.19, 5.48, 4.98, 5.42, 4.64, 4.13, 4.21, 4.66, 5.63, 6.34, 7.63, 8.06, 8.17,
  7.48, 6.78, 6.24, 5.89, 5.97, 5.46, 5.78, 6.16, 6.35, 6.38, 6.6, 6.96, 6.94, 5.68, 5.46, 4.67,
  4.17, 4.28, 4.71, 5.67, 6.45, 7.75, 8.14, 8.21, 7.48, 6.74, 6.2, 5.86, 5.94, 5.48, 5.78, 6.16,
  6.35, 6.34, 6.53, 6.86, 6.83, 5.61, 5.4, 4.62, 4.13, 4.21, 4.65, 5.61, 6.36, 7.62, 8.04, 8.16,
  7.47, 6.78, 6.25, 5.87, 5.87, 5.32, 5.66, 6.05, 6.26, 6.29, 6.5, 6.88, 6.87, 5.65, 5.48, 4.68,
  4.18, 4.28, 4.75, 5.74, 6.6, 7.97, 8.38, 8.47, 7.7, 6.92, 6.34, 5.94, 5.96, 5.42, 5.75, 6.1, 6.32,
  6.31, 6.51, 6.86, 6.84, 5.61, 5.42, 4.63, 4.12, 4.22, 4.65, 5.61, 6.35, 7.61, 7.98, 8.06, 7.35,
  6.63, 6.06, 5.71, 5.78, 5.27, 5.6, 6.0, 6.2, 6.24, 6.44, 6.81, 6.77, 5.58, 4.91, 4.47, 4.38, 4.61,
  4.88, 5.72, 6.26, 6.89, 7.28, 7.82, 7.86, 7.52, 7.3, 7.16, 7.2, 6.44, 6.97, 7.47, 7.44, 7.27,
  6.73, 6.31, 5.56, 4.89, 5.12, 4.71, 4.6, 4.7, 4.9, 5.45, 5.75, 6.41, 6.96, 7.2, 7.21, 7.05, 7.17,
  7.23, 7.31, 6.35, 6.57, 7.07, 7.01, 6.94, 6.65, 6.19, 5.48, 4.98, 5.42, 4.64, 4.13, 4.21, 4.66,
  5.63, 6.34, 7.63, 8.06, 8.17, 7.48, 6.78, 6.24, 5.89, 5.97, 5.46, 5.78, 6.16, 6.35, 6.38, 6.6,
  6.96, 6.94, 5.68, 5.46, 4.67, 4.17, 4.28, 4.71, 5.67, 6.45, 7.75, 8.14, 8.21, 7.48, 6.74, 6.2,
  5.86, 5.94, 5.48, 5.78, 6.16, 6.35, 6.34, 6.53, 6.86, 6.83, 5.61, 5.4, 4.62, 4.13, 4.21, 4.65,
  5.61, 6.36, 7.62, 8.04, 8.16, 7.47, 6.78, 6.25, 5.87, 5.87, 5.32, 5.66, 6.05, 6.26, 6.29, 6.5,
  6.88, 6.87, 5.65, 5.48, 4.68, 4.18, 4.28, 4.75, 5.74, 6.6, 7.97, 8.38, 8.47, 7.7, 6.92, 6.34,
  5.94, 5.96, 5.42, 5.75, 6.1, 6.32, 6.31, 6.51, 6.86, 6.84, 5.61, 5.52, 5.21, 4.49, 4.12, 4.28,
  4.83, 5.7, 6.55, 7.66, 7.9, 7.8, 7.07, 6.39, 5.9, 5.61, 5.54, 5.33, 5.84, 6.0, 6.15, 6.24, 6.48,
  6.79, 6.53, 4.87, 4.79, 4.46, 4.46, 4.45, 4.68, 5.1, 5.81, 6.44, 7.09, 7.52, 7.97, 7.89, 7.57,
  7.38, 7.23, 7.01, 6.66, 7.33, 7.51, 7.46, 7.19, 6.68, 6.19, 5.44, 5.02, 5.08, 4.72, 4.66, 4.77,
  5.07, 5.5, 5.94, 6.69, 7.15, 7.32, 7.26, 7.14, 7.24, 7.25, 7.05, 6.5, 6.91, 7.05, 6.97, 6.86,
  6.54, 6.03, 5.37, 5.56, 5.37, 4.59, 4.11, 4.2, 4.63, 5.47, 6.26, 7.61, 7.9, 7.94, 7.2, 6.47, 5.93,
  5.55, 5.58, 5.22, 5.78, 5.94, 6.14, 6.18, 6.41, 6.79, 6.76, 5.55, 5.37, 4.6, 4.1, 4.19, 4.63,
  5.51, 6.3, 7.62, 7.93, 7.98, 7.27, 6.56, 6.02, 5.61, 5.64, 5.24, 5.81, 5.96, 6.16, 6.19, 6.43,
  6.8, 6.77, 5.56, 5.39, 4.59, 4.12, 4.21, 4.63, 5.53, 6.3, 7.65, 7.93, 8.0, 7.27, 6.53, 5.97, 5.59,
  5.65, 5.27, 5.82, 5.97, 6.17, 6.2, 6.44, 6.8, 6.79, 5.53, 5.23, 4.51, 4.13, 4.28, 4.83, 5.69,
  6.53, 7.66, 7.89, 7.77, 7.05, 6.37, 5.86, 5.55, 5.49, 5.27, 5.81, 5.97, 6.13, 6.22, 6.47, 6.76,
  6.51, 5.52, 5.21, 4.49, 4.12, 4.28, 4.83, 5.7, 6.55, 7.66, 7.9, 7.8, 7.07, 6.39, 5.9, 5.61, 5.54,
  5.33, 5.84, 6.0, 6.15, 6.24, 6.48, 6.79, 6.53, 4.87, 4.79, 4.46, 4.45, 4.68, 5.1, 5.81, 6.44,
  7.09, 7.52, 7.97, 7.89, 7.57, 7.38, 7.23, 7.01, 6.66, 7.33, 7.51, 7.46, 7.19, 6.68, 6.19, 5.44,
  5.02, 5.08, 4.72, 4.66, 4.77, 5.07, 5.5, 5.94, 6.69, 7.15, 7.32, 7.26, 7.14, 7.24, 7.25, 7.05,
  6.5, 6.91, 7.05, 6.97, 6.86, 6.54, 6.03, 5.37, 5.56, 5.37, 4.59, 4.11, 4.2, 4.63, 5.47, 6.26,
  7.61, 7.9, 7.94, 7.2, 6.47, 5.93, 5.55, 5.58, 5.22, 5.78, 5.94, 6.14, 6.18, 6.41, 6.79, 6.76,
  5.55, 5.37, 4.6, 4.1, 4.19, 4.63, 5.51, 6.3, 7.62, 7.93, 7.98, 7.27, 6.56, 6.02, 5.61, 5.64, 5.24,
  5.81, 5.96, 6.16, 6.19, 6.43, 6.8, 6.77, 5.56, 5.39, 4.59, 4.12, 4.21, 4.63, 5.53, 6.3, 7.65,
  7.93, 8.0, 7.27, 6.53, 5.97, 5.59, 5.65, 5.27, 5.82, 5.97, 6.17, 6.2, 6.44, 6.8, 6.79, 5.53, 5.23,
  4.51, 4.13, 4.28, 4.83, 5.69, 6.53, 7.66, 7.89, 7.77, 7.05, 6.37, 5.86, 5.55, 5.49, 5.27, 5.81,
  5.97, 6.13, 6.22, 6.47, 6.76, 6.51, 5.52, 5.21, 4.49, 4.12, 4.28, 4.83, 5.7, 6.55, 7.66, 7.9, 7.8,
  7.07, 6.39, 5.9, 5.61, 5.54, 5.33, 5.84, 6.0, 6.15, 6.24, 6.48, 6.79, 6.53, 4.87, 4.79, 4.46,
  4.45, 4.68, 5.1, 5.81, 6.44, 7.09, 7.52, 7.97, 7.89, 7.57, 7.38, 7.23, 7.01, 6.66, 7.33, 7.51,
  7.46, 7.19, 6.68, 6.19, 5.44, 5.02, 5.08, 4.72, 4.66, 4.77, 5.07, 5.5, 5.94, 6.69, 7.15, 7.32,
  7.26, 7.14, 7.24, 7.25, 7.05, 6.5, 6.91, 7.05, 6.97, 6.86, 6.54, 6.03, 5.37, 5.56, 5.37, 4.59,
  4.11, 4.2, 4.63, 5.47, 6.26, 7.61, 7.9, 7.94, 7.2, 6.47, 5.93, 5.55, 5.58, 5.22, 5.78, 5.94, 6.14,
  6.18, 6.41, 6.79, 6.76, 5.55, 5.37, 4.6, 4.1, 4.19, 4.63, 5.51, 6.3, 7.62, 7.93, 7.98, 7.27, 6.56,
  6.02, 5.61, 5.64, 5.24, 5.81, 5.96, 6.16, 6.19, 6.43, 6.8, 6.77, 5.56, 5.39, 4.59, 4.12, 4.21,
  4.63, 5.53, 6.3, 7.65, 7.93, 8.0, 7.27, 6.53, 5.97, 5.59, 5.65, 5.27, 5.82, 5.97, 6.17, 6.2, 6.44,
  6.8, 6.79, 5.53, 5.23, 4.51, 4.13, 4.28, 4.83, 5.69, 6.53, 7.66, 7.89, 7.77, 7.05, 6.37, 5.86,
  5.55, 5.49, 5.27, 5.81, 5.97, 6.13, 6.22, 6.47, 6.76, 6.51, 5.52, 5.21, 4.49, 4.12, 4.28, 4.83,
  5.7, 6.55, 7.66, 7.9, 7.8, 7.07, 6.39, 5.9, 5.61, 5.54, 5.33, 5.84, 6.0, 6.15, 6.24, 6.48, 6.79,
  6.53, 4.87, 4.79, 4.46, 4.45, 4.68, 5.1, 5.81, 6.44, 7.09, 7.52, 7.97, 7.89, 7.57, 7.38, 7.23,
  7.01, 6.66, 7.33, 7.51, 7.46, 7.19, 6.68, 6.19, 5.44, 5.02, 5.08, 4.72, 4.66, 4.77, 5.07, 5.5,
  5.94, 6.69, 7.15, 7.32, 7.26, 7.14, 7.24, 7.25, 7.05, 6.5, 6.91, 7.05, 6.97, 6.86, 6.54, 6.03,
  5.37, 5.56, 5.37, 4.59, 4.11, 4.2, 4.63, 5.47, 6.26, 7.61, 7.9, 7.94, 7.2, 6.47, 5.93, 5.55, 5.58,
  5.22, 5.78, 5.94, 6.14, 6.18, 6.41, 6.79, 6.76, 5.55, 5.37, 4.6, 4.1, 4.19, 4.63, 5.51, 6.3, 7.62,
  7.93, 7.98, 7.27, 6.56, 6.02, 5.61, 5.64, 5.24, 5.81, 5.96, 6.16, 6.19, 6.43, 6.8, 6.77, 5.56,
  5.39, 4.59, 4.12, 4.21, 4.63, 5.53, 6.3, 7.65, 7.93, 8.0, 7.27, 6.53, 5.97, 5.59, 5.65, 5.27,
  5.82, 5.97, 6.17, 6.2, 6.44, 6.8, 6.79, 5.53, 5.23, 4.51, 4.13, 4.28, 4.83, 5.69, 6.53, 7.66,
  7.89, 7.77, 7.05, 6.37, 5.86, 5.55, 5.49, 5.27, 5.81, 5.97, 6.13, 6.22, 6.47, 6.76, 6.51, 5.52,
  5.21, 4.49, 4.12, 4.28, 4.83, 5.7, 6.55, 7.66, 7.9, 7.8, 7.07, 6.39, 5.9, 5.61, 5.54, 5.33, 5.84,
  6.0, 6.15, 6.24, 6.48, 6.79, 6.53, 4.87, 4.79, 4.46, 4.45, 4.68, 5.1, 5.81, 6.44, 7.09, 7.52,
  7.97, 7.89, 7.57, 7.38, 7.23, 7.01, 6.66, 7.33, 7.51, 7.46, 7.19, 6.68, 6.19, 5.44, 4.99, 5.17,
  4.74, 4.62, 4.72, 4.92, 5.49, 5.89, 6.62, 7.08, 7.29, 7.27, 7.08, 7.19, 7.19, 7.28, 6.46, 6.88,
  7.09, 7.06, 6.99, 6.68, 6.26, 5.55, 5.59, 5.42, 4.64, 4.15, 4.24, 4.67, 5.65, 6.41, 7.67, 7.93,
  7.97, 7.24, 6.51, 5.98, 5.61, 5.67, 5.28, 5.84, 5.97, 6.16, 6.22, 6.44, 6.83, 6.84, 5.59, 5.41,
  4.64, 4.14, 4.25, 4.67, 5.66, 6.4, 7.68, 7.92, 7.96, 7.26, 6.52, 6.01, 5.65, 5.71, 5.3, 5.86,
  5.97, 6.15, 6.22, 6.45, 6.82, 6.85, 5.59, 5.41, 4.64, 4.15, 4.24, 4.69, 5.66, 6.46, 7.66, 7.92,
  7.94, 7.27, 6.52, 6.04, 5.67, 5.74, 5.32, 5.85, 5.99, 6.15, 6.22, 6.45, 6.82, 6.84, 5.59, 5.42,
  4.64, 4.15, 4.24, 4.67, 5.66, 6.43, 7.67, 7.91, 7.95, 7.25, 6.53, 6.02, 5.66, 5.74, 5.3, 5.87,
  5.96, 6.15, 6.22, 6.44, 6.82, 6.85, 5.6, 5.42, 4.64, 4.17, 4.25, 4.69, 5.66, 6.44, 7.69, 7.93,
  7.97, 7.27, 6.55, 6.02, 5.65, 5.7, 5.32, 5.86, 5.98, 6.17, 6.23, 6.46, 6.84, 6.85, 4.91, 4.93,
  4.51, 4.45, 4.67, 4.93, 5.77, 6.43, 7.06, 7.35, 7.88, 7.84, 7.48, 7.26, 7.08, 7.13, 6.51, 7.21,
  7.49, 7.47, 7.33, 6.78, 6.4, 5.65, 4.99, 5.17, 4.74, 4.62, 4.72, 4.92, 5.49, 5.89, 6.62, 7.08,
  7.29, 7.27, 7.08, 7.19, 7.19, 7.28, 6.46, 6.88, 7.09, 7.06, 6.99, 6.68, 6.26, 5.55, 5.59, 5.42,
  4.64, 4.15, 4.24, 4.67, 5.65, 6.41, 7.67, 7.93, 7.97, 7.24, 6.51, 5.98, 5.61, 5.67, 5.28, 5.84,
  5.97, 6.16, 6.22, 6.44, 6.83, 6.84, 5.59, 5.41, 4.64, 4.14, 4.25, 4.67, 5.66, 6.4, 7.68, 7.92,
  7.96, 7.26, 6.52, 6.01, 5.65, 5.71, 5.3, 5.86, 5.97, 6.15, 6.22, 6.45, 6.82, 6.85, 5.59, 5.41,
  4.64, 4.15, 4.24, 4.69, 5.66, 6.46, 7.66, 7.92, 7.94, 7.27, 6.52, 6.04, 5.67, 5.74, 5.32, 5.85,
  5.99, 6.15, 6.22, 6.45, 6.82, 6.84, 5.59, 5.42, 4.64, 4.15, 4.24, 4.67, 5.66, 6.43, 7.67, 7.91,
  7.95, 7.25, 6.53, 6.02, 5.66, 5.74, 5.3, 5.87, 5.96, 6.15, 6.22, 6.44, 6.82, 6.85, 5.6, 5.42,
  4.64, 4.17, 4.25, 4.69, 5.66, 6.44, 7.69, 7.93, 7.97, 7.27, 6.55, 6.02, 5.65, 5.7, 5.32, 5.86,
  5.98, 6.17, 6.23, 6.46, 6.84, 6.85, 4.91, 4.93, 4.51, 4.45, 4.67, 4.93, 5.77, 6.43, 7.06, 7.35,
  7.88, 7.84, 7.48, 7.26, 7.08, 7.13, 6.51, 7.21, 7.49, 7.47, 7.33, 6.78, 6.4, 5.65, 4.99, 5.17,
  4.74, 4.62, 4.72, 4.92, 5.49, 5.89, 6.62, 7.08, 7.29, 7.27, 7.08, 7.19, 7.19, 7.28, 6.46, 6.88,
  7.09, 7.06, 6.99, 6.68, 6.26, 5.55, 5.59, 5.42, 4.64, 4.15, 4.24, 4.67, 5.65, 6.41, 7.67, 7.93,
  7.97, 7.24, 6.51, 5.98, 5.61, 5.67, 5.28, 5.84, 5.97, 6.16, 6.22, 6.44, 6.83, 6.84, 5.59, 5.41,
  4.64, 4.14, 4.25, 4.67, 5.66, 6.4, 7.68, 7.92, 7.96, 7.26, 6.52, 6.01, 5.65, 5.71, 5.3, 5.86,
  5.97, 6.15, 6.22, 6.45, 6.82, 6.85, 5.59, 5.41, 4.64, 4.15, 4.24, 4.69, 5.66, 6.46, 7.66, 7.92,
  7.94, 7.27, 6.52, 6.04, 5.67, 5.74, 5.32, 5.85, 5.99, 6.15, 6.22, 6.45, 6.82, 6.84, 5.59, 5.42,
  4.64, 4.15, 4.24, 4.67, 5.66, 6.43, 7.67, 7.91, 7.95, 7.25, 6.53, 6.02, 5.66, 5.74, 5.3, 5.87,
  5.96, 6.15, 6.22, 6.44, 6.82, 6.85, 5.6, 5.42, 4.64, 4.17, 4.25, 4.69, 5.66, 6.44, 7.69, 7.93,
  7.97, 7.27, 6.55, 6.02, 5.65, 5.7, 5.32, 5.86, 5.98, 6.17, 6.23, 6.46, 6.84, 6.85, 4.91, 4.93,
  4.51, 4.45, 4.67, 4.93, 5.77, 6.43, 7.06, 7.35, 7.88, 7.84, 7.48, 7.26, 7.08, 7.13, 6.51, 7.21,
  7.49, 7.47, 7.33, 6.78, 6.4, 5.65, 4.99, 5.17, 4.74, 4.62, 4.72, 4.92, 5.49, 5.89, 6.62, 7.08,
  7.29, 7.27, 7.08, 7.19, 7.19, 7.28, 6.46, 6.88, 7.09, 7.06, 6.99, 6.68, 6.26, 5.55, 5.59, 5.42,
  4.64, 4.15, 4.24, 4.67, 5.65, 6.41, 7.67, 7.93, 7.97, 7.24, 6.51, 5.98, 5.61, 5.67, 5.28, 5.84,
  5.97, 6.16, 6.22, 6.44, 6.83, 6.84, 5.59, 5.41, 4.64, 4.14, 4.25, 4.67, 5.66, 6.4, 7.68, 7.92,
  7.96, 7.26, 6.52, 6.01, 5.65, 5.71, 5.3, 5.86, 5.97, 6.15, 6.22, 6.45, 6.82, 6.85, 5.59, 5.41,
  4.64, 4.15, 4.24, 4.69, 5.66, 6.46, 7.66, 7.92, 7.94, 7.27, 6.52, 6.04, 5.67, 5.74, 5.32, 5.85,
  5.99, 6.15, 6.22, 6.45, 6.82, 6.84, 5.59, 5.42, 4.64, 4.15, 4.24, 4.67, 5.66, 6.43, 7.67, 7.91,
  7.95, 7.25, 6.53, 6.02, 5.66, 5.74, 5.3, 5.87, 5.96, 6.15, 6.22, 6.44, 6.82, 6.85, 5.6, 5.42,
  4.64, 4.17, 4.25, 4.69, 5.66, 6.44, 7.69, 7.93, 7.97, 7.27, 6.55, 6.02, 5.65, 5.7, 5.32, 5.86,
  5.98, 6.17, 6.23, 6.46, 6.84, 6.85, 4.91, 4.93, 4.51, 4.45, 4.67, 4.93, 5.77, 6.43, 7.06, 7.35,
  7.88, 7.84, 7.48, 7.26, 7.08, 7.13, 6.51, 7.21, 7.49, 7.47, 7.33, 6.78, 6.4, 5.65, 4.99, 5.17,
  4.74, 4.62, 4.72, 4.92, 5.49, 5.89, 6.62, 7.08, 7.29, 7.27, 7.08, 7.19, 7.19, 7.28, 6.46, 6.88,
  7.09, 7.06, 6.99, 6.68, 6.26, 5.55, 5.59, 5.42, 4.64, 4.15, 4.24, 4.67, 5.65, 6.41, 7.67, 7.93,
  7.97, 7.24, 6.51, 5.98, 5.61, 5.67, 5.28, 5.84, 5.97, 6.16, 6.22, 6.44, 6.83, 6.84, 5.59, 5.41,
  4.64, 4.14, 4.25, 4.67, 5.66, 6.4, 7.68, 7.92, 7.96, 7.26, 6.52, 6.01, 5.65, 5.71, 5.3, 5.86,
  5.97, 6.15, 6.22, 6.45, 6.82, 6.85,
];

export default largeHotelLoadProfile;
