import {Box, Link, Typography, makeStyles} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import CtaButton from '@generic/components/CtaButton';
import SwitchInput from '@generic/components/inputs/SwitchInput';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 'bold',
    marginRight: '1rem',
    lineHeight: '1.5rem',
  },
  alert: {
    flexGrow: 1,
  },
  flexedGridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function SmartChargeCommercialBreakdown() {
  const inputs = useInputs();
  const {formatMessage} = useIntl();
  const classes = useStyles();

  const {includeSCCRebate, setInput} = inputs;

  return (
    <>
      <Box mb={5} display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6" component="span" className={classes.label}>
          {formatMessage({
            id: 'smartChargeCommercial.title',
          })}
        </Typography>
        <SwitchInput
          checked={includeSCCRebate}
          onChange={(e) => {
            setInput({includeSCCRebate: e.target.checked});
          }}
        />
      </Box>
      <Alert icon={false} severity="success" variant="outlined" className={classes.alert}>
        <Box mb={4}>
          <Typography>
            {formatMessage({
              id: 'smartChargeCommercial.description',
            })}
          </Typography>
        </Box>

        <Box textAlign="center">
          <CtaButton
            component={Link}
            target="_blank"
            rel="noopener noreferrer"
            href={formatMessage({
              id: 'smartChargeCommercial.enrollUrl',
            })}
          >
            {formatMessage({
              id: 'smartChargeCommercial.enroll',
            })}
          </CtaButton>
        </Box>
      </Alert>
    </>
  );
}
