import React from 'react';
import {useTheme, useMediaQuery} from '@material-ui/core';
import Tabs from '@generic/components/Tabs';
import DropdownViews from '@generic/components/DropdownViews';
import ChargingOverview from '@generic/pages/ChargingCalculator/ChargingOverview';
import ChargingVehicles from '@generic/pages/ChargingCalculator/ChargingVehicles';
import ChargingChargers from '@generic/pages/ChargingCalculator/ChargingChargers';
import ChargingCosts from '@generic/pages/ChargingCalculator/ChargingCosts';
import ElectricityCosts from '@generic/pages/ChargingCalculator/ElectricityCosts';
import FuelCosts from '@generic/pages/ChargingCalculator/FuelCosts';
import MaintenanceCosts from '@generic/pages/ChargingCalculator/MaintenanceCosts';
import {useIntl} from 'react-intl';

export default function ChargingTabs() {
  const {formatMessage} = useIntl();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const views = [
    {
      id: 'overview',
      label: formatMessage({id: 'charging.content.tabs.overviewLabel'}),
      ContentComponent: ChargingOverview,
      isSmallScreen: true,
      isLargeScreen: true,
    },
    {
      id: 'vehicles',
      label: formatMessage({id: 'charging.content.tabs.vehiclesLabel'}),
      ContentComponent: ChargingVehicles,
      isSmallScreen: true,
      isLargeScreen: true,
    },
    {
      id: 'chargers',
      label: formatMessage({id: 'charging.content.tabs.chargersLabel'}),
      ContentComponent: ChargingChargers,
      isSmallScreen: true,
      isLargeScreen: true,
    },
    {
      id: 'costs',
      label: formatMessage({id: 'charging.content.tabs.costsLabel'}),
      ContentComponent: ChargingCosts,
      isSmallScreen: false,
      isLargeScreen: true,
    },
    {
      id: 'electricityCosts',
      label: formatMessage({id: 'charging.content.tabs.electricityCostsLabel'}),
      ContentComponent: ElectricityCosts,
      isSmallScreen: true,
      isLargeScreen: false,
    },
    {
      id: 'fuelCosts',
      label: formatMessage({id: 'charging.content.tabs.fuelCostsLabel'}),
      ContentComponent: FuelCosts,
      isSmallScreen: true,
      isLargeScreen: false,
    },
    {
      id: 'maintenanceCosts',
      label: formatMessage({id: 'charging.content.tabs.maintenanceCostsLabel'}),
      ContentComponent: MaintenanceCosts,
      isSmallScreen: true,
      isLargeScreen: false,
    },
  ];

  // small screens display a simplified dropdown view switcher, as opposed
  // to (sometimes) nested tabs
  return isSmallScreen ? (
    <DropdownViews views={views.filter((view) => view.isSmallScreen)} />
  ) : (
    <Tabs tabs={views.filter((view) => view.isLargeScreen)} />
  );
}
