import {Box, Grid, Typography, makeStyles} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {formatChargerSetTitle} from '@generic/functions/formatters';
import CHARGERS from '@generic/data/chargers';

const useStyles = makeStyles((theme) => {
  const {
    vehicleSetDialog: {overview},
  } = theme;

  return {
    img: {
      maxHeight: 100,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        height: 'auto',
        maxHeight: 'none',
      },
    },
    imgContainer: {
      textAlign: 'center',
    },
    title: {...overview.title},
    categoryLabel: {
      fontWeight: '700',
      fontSize: '0.875rem',
    },
    categoryValue: {
      fontSize: '0.875rem',
    },
  };
});

export default function Overview({chargerSet: {chargerCount, type}}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const charger = CHARGERS.find((c) => c.type === type);
  const {imageUrl} = charger;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
      <Grid item xs={12} sm={4}>
        <Box className={classes.imgContainer}>
          <img className={classes.img} src={imageUrl} alt="" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography className={classes.title}>
          {formatChargerSetTitle({chargerCount, type})}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <Typography className={classes.categoryLabel}>
              {formatMessage({id: 'chargerSetDialog.overview.type.label'})}
            </Typography>
            <Typography className={classes.categoryValue}>
              {formatMessage({id: 'chargerSetDialog.overview.type.value'}, {type})}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.categoryLabel}>
              {formatMessage({id: 'chargerSetDialog.overview.count.label'})}
            </Typography>
            <Typography className={classes.categoryValue}>{chargerCount}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
