import React, {useState} from 'react';
import {
  Box,
  makeStyles,
  Typography,
  useTheme,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import {Bar} from 'react-chartjs-2';
import {formatTime, formatVehicleSetTitle} from '@generic/functions/formatters';
import {useIntl} from 'react-intl';
import {useInputs} from '@bellawatt/use-inputs';
import groupVehicleSetsByChargers from '@generic/functions/groupVehicleSetsByChargers';

const useStyles = makeStyles((theme) => ({
  chartTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  chartWrapper: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      padding: '0 2rem',
    },
  },
}));

export default function HourlyChargerUseChart() {
  const classes = useStyles();
  const theme = useTheme();
  const {formatMessage} = useIntl();
  const {vehicleSets} = useInputs();

  const chargingSets = groupVehicleSetsByChargers(vehicleSets);

  const [selectedCharger, setSelectedCharger] = useState(chargingSets[0].charger.type);

  const selectedChargingSet =
    chargingSets.find((set) => set.charger.type === selectedCharger) || chargingSets[0];

  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.light,
    theme.palette.primary.light,
    theme.palette.primary.dark,
    theme.palette.success.light,
  ];

  const datasets = selectedChargingSet.vehicles.map((vehicleSet, idx) => ({
    idx,
    label: formatVehicleSetTitle(vehicleSet),
    data: vehicleSet.usage,
    categoryPercentage: 1,
    barPercentage: 0.9,
    backgroundColor: COLORS[idx],
  }));

  const data = {
    labels: [...Array(24).keys()].map((label) => `${label}`),
    datasets,
  };

  const options = {
    tooltips: {
      callbacks: {
        title: (data) => `${formatTime(+data[0].label)}`,
        label: (data, context) => `${context.datasets[data.datasetIndex].label}: ${data.value}`,
      },
    },
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        color: theme.palette.common.black,
        font: {
          size: 12,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontStyle: 'bold',
            fontColor: '#2B2B2B',
            maxTicksLimit:
              Math.max(...datasets.map((dataset) => dataset.data).flatMap((val) => val)) + 1,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 12,
            callback: (value) => formatTime(+value),
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <Box mt={3} display="flex" flexDirection="column">
      <Typography variant="body2" className={classes.chartTitle}>
        {formatMessage({id: 'charging.content.chargers.chart.title'})}
      </Typography>
      <Box alignSelf="center" my={3}>
        <Typography variant="body1">
          <label htmlFor="select-charger">
            {formatMessage({id: 'charging.content.chargers.chart.selectLabel'})}
          </label>
        </Typography>
        <FormControl variant="outlined">
          <Select
            id="select"
            value={selectedCharger}
            onChange={(e) => setSelectedCharger(e.target.value)}
            inputProps={{id: 'select-charger'}}
          >
            {chargingSets.map(({charger: {type}}) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="body2">
        {formatMessage({id: 'charging.content.chargers.chart.label'})}
      </Typography>
      <Box className={classes.chartWrapper} height={300}>
        <Bar data={data} options={options} datasetKeyProvider={(d) => d.idx} />
      </Box>
    </Box>
  );
}
