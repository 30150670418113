const siteAdditionalQualificationOptions = [
  {
    id: 'none',
    translationKey: 'inputs.siteAdditionalQualificationOptions.none',
  },
  {
    id: 'transitAgency',
    translationKey: 'inputs.siteAdditionalQualificationOptions.transitAgency',
  },
  {
    id: 'publiclyAvailable',
    translationKey: 'inputs.siteAdditionalQualificationOptions.publiclyAvailable',
  },
];

export default siteAdditionalQualificationOptions;
