import React from 'react';
import {Box} from '@material-ui/core';
import {useInputs} from '@bellawatt/use-inputs';
import AccordionPanel from '@generic/components/AccordionPanel';
import PlanningRatesOverviewChart from '@generic/pages/SitePlanner/PlanningRatesOverviewChart';
import PlanningRateBreakdown from '@generic/pages/SitePlanner/PlanningRateBreakdown';
import {useIntl} from 'react-intl';
import calcAnnualChargerElectricityCostsAllRates from '@generic/functions/calcAnnualChargerElectricityCostsAllRates';

const MAX_RATES_SHOWN = 4;

export default function PlanningElectricity() {
  const {formatMessage} = useIntl();
  const inputs = useInputs();

  const rawAnnualCosts = calcAnnualChargerElectricityCostsAllRates(inputs);
  const annualCosts = rawAnnualCosts.sort((a, b) => a.total - b.total).slice(0, MAX_RATES_SHOWN);

  return (
    <Box mt={4}>
      <AccordionPanel
        summary={formatMessage({id: 'electricRateExplainer.summary'})}
        details={formatMessage({id: 'electricRateExplainer.details'})}
      />

      <Box px={4}>
        <PlanningRatesOverviewChart annualCosts={annualCosts} />

        <PlanningRateBreakdown annualCosts={annualCosts} />
      </Box>
    </Box>
  );
}
