import React from 'react';
import Tabs from '@generic/components/Tabs';
import {useIntl} from 'react-intl';
import PlanningChargers from '@generic/pages/SitePlanner/PlanningChargers';
import PlanningElectricity from '@generic/pages/SitePlanner/PlanningElectricity';
import DropdownViews from '@generic/components/DropdownViews';
import {useTheme, useMediaQuery} from '@material-ui/core';

export default function PlanningTabs() {
  const {formatMessage} = useIntl();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const views = [
    {
      id: 'electricity',
      label: formatMessage({id: 'planning.content.tabs.electricityLabel'}),
      ContentComponent: PlanningElectricity,
      isSmallScreen: true,
      isLargeScreen: true,
    },
    {
      id: 'chargers',
      label: formatMessage({id: 'planning.content.tabs.chargersLabel'}),
      ContentComponent: PlanningChargers,
      isSmallScreen: true,
      isLargeScreen: true,
    },
  ];

  // small screens display a simplified dropdown view switcher, as opposed
  // to (sometimes) nested tabs
  return isSmallScreen ? (
    <DropdownViews views={views.filter((view) => view.isSmallScreen)} />
  ) : (
    <Tabs tabs={views.filter((view) => view.isLargeScreen)} />
  );
}
