const largeOfficeLoadProfile = [
  5.82, 5.76, 5.71, 5.78, 6.06, 6.46, 6.92, 7.22, 7.61, 8.01, 8.21, 8.2, 8.1, 7.89, 7.4, 7.08, 7.0,
  6.84, 6.72, 6.42, 6.26, 6.18, 5.93, 5.81, 5.77, 5.72, 5.7, 5.77, 6.04, 6.42, 6.91, 7.19, 7.6,
  8.01, 8.18, 8.17, 8.08, 7.87, 7.39, 7.09, 7.01, 6.87, 6.74, 6.44, 6.29, 6.2, 5.96, 5.84, 5.8,
  5.73, 5.68, 5.76, 6.04, 6.42, 6.9, 7.19, 7.57, 7.98, 8.16, 8.15, 8.06, 7.85, 7.38, 7.08, 7.02,
  6.87, 6.75, 6.47, 6.32, 6.24, 5.97, 5.84, 5.81, 5.74, 5.7, 5.77, 6.03, 6.42, 6.89, 7.19, 7.58,
  7.98, 8.16, 8.15, 8.11, 7.93, 7.44, 7.13, 7.08, 6.91, 6.77, 6.46, 6.3, 6.18, 5.93, 5.81, 5.63,
  5.52, 5.49, 5.65, 5.72, 5.82, 5.97, 5.87, 5.7, 5.7, 5.63, 5.64, 5.59, 5.61, 5.69, 5.77, 5.71,
  5.78, 5.86, 5.83, 5.64, 5.61, 5.48, 5.53, 5.0, 5.25, 5.28, 5.35, 5.44, 5.54, 5.37, 5.2, 5.19,
  5.14, 5.05, 4.97, 4.97, 4.95, 4.91, 4.87, 4.85, 4.94, 4.94, 4.97, 5.0, 5.03, 5.06, 5.08, 5.79,
  5.76, 5.69, 5.74, 6.04, 6.4, 6.9, 7.19, 7.58, 8.01, 8.21, 8.21, 8.11, 7.91, 7.43, 7.11, 7.03,
  6.89, 6.76, 6.45, 6.28, 6.23, 6.0, 5.87, 5.82, 5.76, 5.71, 5.78, 6.06, 6.46, 6.92, 7.22, 7.61,
  8.01, 8.21, 8.2, 8.1, 7.89, 7.4, 7.08, 7.0, 6.84, 6.72, 6.42, 6.26, 6.18, 5.93, 5.81, 5.77, 5.72,
  5.7, 5.77, 6.04, 6.42, 6.91, 7.19, 7.6, 8.01, 8.18, 8.17, 8.08, 7.87, 7.39, 7.09, 7.01, 6.87,
  6.74, 6.44, 6.29, 6.2, 5.96, 5.84, 5.8, 5.73, 5.68, 5.76, 6.04, 6.42, 6.9, 7.19, 7.57, 7.98, 8.16,
  8.15, 8.06, 7.85, 7.38, 7.08, 7.02, 6.87, 6.75, 6.47, 6.32, 6.24, 5.97, 5.84, 5.81, 5.74, 5.7,
  5.77, 6.03, 6.42, 6.89, 7.19, 7.58, 7.98, 8.16, 8.15, 8.11, 7.93, 7.44, 7.13, 7.08, 6.91, 6.77,
  6.46, 6.3, 6.18, 5.93, 5.81, 5.63, 5.52, 5.49, 5.65, 5.72, 5.82, 5.97, 5.87, 5.7, 5.7, 5.63, 5.64,
  5.59, 5.61, 5.69, 5.77, 5.71, 5.78, 5.86, 5.83, 5.64, 5.61, 5.48, 5.53, 5.0, 5.25, 5.28, 5.35,
  5.44, 5.54, 5.37, 5.2, 5.19, 5.14, 5.05, 4.97, 4.97, 4.95, 4.91, 4.87, 4.85, 4.94, 4.94, 4.97,
  5.0, 5.03, 5.06, 5.08, 5.79, 5.76, 5.69, 5.74, 6.04, 6.4, 6.9, 7.19, 7.58, 8.01, 8.21, 8.21, 8.11,
  7.91, 7.43, 7.11, 7.03, 6.89, 6.76, 6.45, 6.28, 6.23, 6.0, 5.87, 5.82, 5.76, 5.71, 5.78, 6.06,
  6.46, 6.92, 7.22, 7.61, 8.01, 8.21, 8.2, 8.1, 7.89, 7.4, 7.08, 7.0, 6.84, 6.72, 6.42, 6.26, 6.18,
  5.93, 5.81, 5.77, 5.72, 5.7, 5.77, 6.04, 6.42, 6.91, 7.19, 7.6, 8.01, 8.18, 8.17, 8.08, 7.87,
  7.39, 7.09, 7.01, 6.87, 6.74, 6.44, 6.29, 6.2, 5.96, 5.84, 5.8, 5.73, 5.68, 5.76, 6.04, 6.42, 6.9,
  7.19, 7.57, 7.98, 8.16, 8.15, 8.06, 7.85, 7.38, 7.08, 7.02, 6.87, 6.75, 6.47, 6.32, 6.24, 5.97,
  5.84, 5.81, 5.74, 5.7, 5.77, 6.03, 6.42, 6.89, 7.19, 7.58, 7.98, 8.16, 8.15, 8.11, 7.93, 7.44,
  7.13, 7.08, 6.91, 6.77, 6.46, 6.3, 6.18, 5.93, 5.81, 5.63, 5.52, 5.49, 5.65, 5.72, 5.82, 5.97,
  5.87, 5.7, 5.7, 5.63, 5.64, 5.59, 5.61, 5.69, 5.77, 5.71, 5.78, 5.86, 5.83, 5.64, 5.61, 5.48,
  5.53, 5.0, 5.25, 5.28, 5.35, 5.44, 5.54, 5.37, 5.2, 5.19, 5.14, 5.05, 4.97, 4.97, 4.95, 4.91,
  4.87, 4.85, 4.94, 4.94, 4.97, 5.0, 5.03, 5.06, 5.08, 5.79, 5.76, 5.69, 5.74, 6.04, 6.4, 6.9, 7.19,
  7.58, 8.01, 8.21, 8.21, 8.11, 7.91, 7.43, 7.11, 7.03, 6.89, 6.76, 6.45, 6.28, 6.23, 6.0, 5.87,
  5.82, 5.76, 5.71, 5.78, 6.06, 6.46, 6.92, 7.22, 7.61, 8.01, 8.21, 8.2, 8.1, 7.89, 7.4, 7.08, 7.0,
  6.84, 6.72, 6.42, 6.26, 6.18, 5.93, 5.81, 5.77, 5.72, 5.7, 5.77, 6.04, 6.42, 6.91, 7.19, 7.6,
  8.01, 8.18, 8.17, 8.08, 7.87, 7.39, 7.09, 7.01, 6.87, 6.74, 6.44, 6.29, 6.2, 5.96, 5.84, 5.8,
  5.73, 5.68, 5.76, 6.04, 6.42, 6.9, 7.19, 7.57, 7.98, 8.16, 8.15, 8.06, 7.85, 7.38, 7.08, 7.02,
  6.87, 6.75, 6.47, 6.32, 6.24, 5.97, 5.84, 5.81, 5.74, 5.7, 5.77, 6.03, 6.42, 6.89, 7.19, 7.58,
  7.98, 8.16, 8.15, 8.11, 7.93, 7.44, 7.13, 7.08, 6.91, 6.77, 6.46, 6.3, 6.18, 5.93, 5.81, 5.63,
  5.52, 5.49, 5.65, 5.72, 5.82, 5.97, 5.87, 5.7, 5.7, 5.63, 5.64, 5.59, 5.61, 5.69, 5.77, 5.71,
  5.78, 5.86, 5.83, 5.64, 5.61, 5.48, 5.53, 5.0, 5.25, 5.28, 5.35, 5.44, 5.54, 5.37, 5.2, 5.19,
  5.14, 5.05, 4.97, 4.97, 4.95, 4.91, 4.87, 4.85, 4.94, 4.94, 4.97, 5.0, 5.03, 5.06, 5.08, 5.79,
  5.76, 5.69, 5.74, 6.04, 6.4, 6.9, 7.19, 7.58, 8.01, 8.21, 8.21, 8.11, 7.91, 7.43, 7.11, 7.03,
  6.89, 6.76, 6.45, 6.28, 6.23, 6.0, 5.87, 5.82, 5.76, 5.71, 5.78, 6.06, 6.46, 6.92, 7.22, 7.61,
  8.01, 8.21, 8.2, 8.1, 7.89, 7.4, 7.08, 7.0, 6.84, 6.72, 6.42, 6.26, 6.18, 5.93, 5.81, 5.77, 5.72,
  5.7, 5.77, 6.04, 6.42, 6.91, 7.19, 7.6, 8.01, 8.18, 8.17, 8.08, 7.87, 7.39, 7.09, 7.01, 6.87,
  6.74, 6.44, 6.29, 6.2, 5.96, 5.84, 5.8, 5.73, 5.68, 5.76, 6.04, 6.42, 6.9, 7.19, 7.57, 7.98, 8.16,
  8.15, 8.06, 7.85, 7.38, 7.08, 7.02, 6.87, 6.75, 6.47, 6.32, 6.24, 5.97, 5.84, 5.64, 5.56, 5.53,
  5.6, 5.97, 6.41, 6.98, 7.28, 7.75, 8.16, 8.35, 8.38, 8.32, 8.11, 7.57, 7.21, 7.05, 6.86, 6.69,
  6.32, 6.12, 6.06, 5.81, 5.73, 5.57, 5.45, 5.46, 5.6, 5.67, 5.76, 5.89, 5.81, 5.69, 5.66, 5.6,
  5.61, 5.57, 5.59, 5.68, 5.77, 5.62, 5.67, 5.79, 5.76, 5.6, 5.56, 5.45, 5.5, 4.96, 5.19, 5.23,
  5.29, 5.36, 5.45, 5.23, 5.07, 5.1, 5.03, 4.94, 4.85, 4.84, 4.85, 4.84, 4.8, 4.75, 4.82, 4.86, 4.9,
  4.93, 4.96, 5.0, 5.04, 5.74, 5.72, 5.69, 5.71, 6.02, 6.39, 6.84, 7.15, 7.58, 8.0, 8.2, 8.19, 8.09,
  7.88, 7.41, 7.11, 6.97, 6.82, 6.72, 6.39, 6.23, 6.19, 5.93, 5.82, 5.75, 5.68, 5.64, 5.71, 6.03,
  6.44, 6.9, 7.17, 7.64, 8.06, 8.24, 8.21, 8.11, 7.91, 7.42, 7.11, 6.98, 6.83, 6.75, 6.46, 6.28,
  6.17, 5.96, 5.82, 5.75, 5.69, 5.68, 5.77, 6.05, 6.42, 6.86, 7.16, 7.59, 7.99, 8.17, 8.18, 8.1,
  7.88, 7.38, 7.07, 6.93, 6.77, 6.69, 6.38, 6.21, 6.14, 5.87, 5.78, 5.77, 5.7, 5.66, 5.73, 6.01,
  6.44, 6.92, 7.21, 7.69, 8.11, 8.32, 8.33, 8.25, 8.05, 7.57, 7.25, 7.1, 6.91, 6.75, 6.39, 6.16,
  6.07, 5.81, 5.71, 5.64, 5.56, 5.53, 5.6, 5.97, 6.41, 6.98, 7.28, 7.75, 8.16, 8.35, 8.38, 8.32,
  8.11, 7.57, 7.21, 7.05, 6.86, 6.69, 6.32, 6.12, 6.06, 5.81, 5.73, 5.57, 5.45, 5.46, 5.6, 5.67,
  5.76, 5.89, 5.81, 5.69, 5.66, 5.6, 5.61, 5.57, 5.59, 5.68, 5.77, 5.62, 5.67, 5.79, 5.76, 5.6,
  5.56, 5.45, 5.5, 4.96, 5.19, 5.23, 5.29, 5.36, 5.45, 5.23, 5.07, 5.1, 5.03, 4.94, 4.85, 4.84,
  4.85, 4.84, 4.8, 4.75, 4.82, 4.86, 4.9, 4.93, 4.96, 5.0, 5.04, 5.74, 5.72, 5.69, 5.71, 6.02, 6.39,
  6.84, 7.15, 7.58, 8.0, 8.2, 8.19, 8.09, 7.88, 7.41, 7.11, 6.97, 6.82, 6.72, 6.39, 6.23, 6.19,
  5.93, 5.82, 5.75, 5.68, 5.64, 5.71, 6.03, 6.44, 6.9, 7.17, 7.64, 8.06, 8.24, 8.21, 8.11, 7.91,
  7.42, 7.11, 6.98, 6.83, 6.75, 6.46, 6.28, 6.17, 5.96, 5.82, 5.75, 5.69, 5.68, 5.77, 6.05, 6.42,
  6.86, 7.16, 7.59, 7.99, 8.17, 8.18, 8.1, 7.88, 7.38, 7.07, 6.93, 6.77, 6.69, 6.38, 6.21, 6.14,
  5.87, 5.78, 5.77, 5.7, 5.66, 5.73, 6.01, 6.44, 6.92, 7.21, 7.69, 8.11, 8.32, 8.33, 8.25, 8.05,
  7.57, 7.25, 7.1, 6.91, 6.75, 6.39, 6.16, 6.07, 5.81, 5.71, 5.64, 5.56, 5.53, 5.6, 5.97, 6.41,
  6.98, 7.28, 7.75, 8.16, 8.35, 8.38, 8.32, 8.11, 7.57, 7.21, 7.05, 6.86, 6.69, 6.32, 6.12, 6.06,
  5.81, 5.73, 5.57, 5.45, 5.46, 5.6, 5.67, 5.76, 5.89, 5.81, 5.69, 5.66, 5.6, 5.61, 5.57, 5.59,
  5.68, 5.77, 5.62, 5.67, 5.79, 5.76, 5.6, 5.56, 5.45, 5.5, 4.96, 5.19, 5.23, 5.29, 5.36, 5.45,
  5.23, 5.07, 5.1, 5.03, 4.94, 4.85, 4.84, 4.85, 4.84, 4.8, 4.75, 4.82, 4.86, 4.9, 4.93, 4.96, 5.0,
  5.04, 5.74, 5.72, 5.69, 5.71, 6.02, 6.39, 6.84, 7.15, 7.58, 8.0, 8.2, 8.19, 8.09, 7.88, 7.41,
  7.11, 6.97, 6.82, 6.72, 6.39, 6.23, 6.19, 5.93, 5.82, 5.75, 5.68, 5.64, 5.71, 6.03, 6.44, 6.9,
  7.17, 7.64, 8.06, 8.24, 8.21, 8.11, 7.91, 7.42, 7.11, 6.98, 6.83, 6.75, 6.46, 6.28, 6.17, 5.96,
  5.82, 5.75, 5.69, 5.68, 5.77, 6.05, 6.42, 6.86, 7.16, 7.59, 7.99, 8.17, 8.18, 8.1, 7.88, 7.38,
  7.07, 6.93, 6.77, 6.69, 6.38, 6.21, 6.14, 5.87, 5.78, 5.77, 5.7, 5.66, 5.73, 6.01, 6.44, 6.92,
  7.21, 7.69, 8.11, 8.32, 8.33, 8.25, 8.05, 7.57, 7.25, 7.1, 6.91, 6.75, 6.39, 6.16, 6.07, 5.81,
  5.71, 5.64, 5.56, 5.53, 5.6, 5.97, 6.41, 6.98, 7.28, 7.75, 8.16, 8.35, 8.38, 8.32, 8.11, 7.57,
  7.21, 7.05, 6.86, 6.69, 6.32, 6.12, 6.06, 5.81, 5.73, 5.57, 5.45, 5.46, 5.6, 5.67, 5.76, 5.89,
  5.81, 5.69, 5.66, 5.6, 5.61, 5.57, 5.59, 5.68, 5.77, 5.62, 5.67, 5.79, 5.76, 5.6, 5.56, 5.45, 5.5,
  4.96, 5.19, 5.23, 5.29, 5.36, 5.45, 5.23, 5.07, 5.1, 5.03, 4.94, 4.85, 4.84, 4.85, 4.84, 4.8,
  4.75, 4.82, 4.86, 4.9, 4.93, 4.96, 5.0, 5.04, 5.74, 5.72, 5.69, 5.71, 6.02, 6.39, 6.84, 7.15,
  7.58, 8.0, 8.2, 8.19, 8.09, 7.88, 7.41, 7.11, 6.97, 6.82, 6.72, 6.39, 6.23, 6.19, 5.93, 5.82,
  5.75, 5.68, 5.64, 5.71, 6.03, 6.44, 6.9, 7.17, 7.64, 8.06, 8.24, 8.21, 8.11, 7.91, 7.42, 7.11,
  6.98, 6.83, 6.75, 6.46, 6.28, 6.17, 5.96, 5.82, 5.75, 5.69, 5.68, 5.77, 6.05, 6.42, 6.86, 7.16,
  7.59, 7.99, 8.17, 8.18, 8.1, 7.88, 7.38, 7.07, 6.93, 6.77, 6.69, 6.38, 6.21, 6.14, 5.87, 5.78,
  5.77, 5.7, 5.66, 5.73, 6.01, 6.44, 6.92, 7.21, 7.69, 8.11, 8.32, 8.33, 8.25, 8.05, 7.57, 7.25,
  7.1, 6.91, 6.75, 6.39, 6.16, 6.07, 5.81, 5.71, 5.55, 5.51, 5.54, 5.81, 6.22, 6.79, 7.16, 7.62,
  8.13, 8.5, 8.64, 8.65, 8.51, 8.12, 7.71, 7.47, 7.14, 6.81, 6.61, 6.25, 6.11, 5.85, 5.69, 5.63,
  5.5, 5.36, 5.45, 5.52, 5.62, 5.8, 5.83, 5.79, 5.77, 5.74, 5.73, 5.72, 5.74, 5.81, 5.89, 5.82,
  5.67, 5.7, 5.85, 5.75, 5.62, 5.48, 5.46, 5.52, 4.98, 5.07, 5.15, 5.24, 5.31, 5.19, 4.92, 4.94,
  4.92, 4.81, 4.68, 4.67, 4.7, 4.71, 4.72, 4.66, 4.57, 4.57, 4.75, 4.79, 4.8, 4.81, 4.84, 4.89,
  5.55, 5.5, 5.54, 5.86, 6.27, 6.84, 7.17, 7.61, 8.04, 8.3, 8.35, 8.28, 8.12, 7.71, 7.33, 7.13,
  6.82, 6.57, 6.43, 6.14, 6.0, 5.82, 5.7, 5.61, 5.56, 5.52, 5.56, 5.87, 6.29, 6.82, 7.13, 7.61,
  8.06, 8.33, 8.44, 8.42, 8.27, 7.86, 7.5, 7.35, 7.04, 6.72, 6.52, 6.21, 6.11, 5.87, 5.74, 5.62,
  5.58, 5.58, 5.58, 5.88, 6.29, 6.83, 7.13, 7.57, 8.01, 8.27, 8.35, 8.29, 8.12, 7.72, 7.35, 7.16,
  6.86, 6.58, 6.43, 6.14, 5.99, 5.78, 5.65, 5.54, 5.51, 5.48, 5.54, 5.79, 6.19, 6.73, 7.05, 7.5,
  7.98, 8.28, 8.39, 8.39, 8.28, 7.94, 7.56, 7.31, 6.98, 6.69, 6.53, 6.21, 6.05, 5.87, 5.7, 5.6,
  5.55, 5.51, 5.54, 5.81, 6.22, 6.79, 7.16, 7.62, 8.13, 8.5, 8.64, 8.65, 8.51, 8.12, 7.71, 7.47,
  7.14, 6.81, 6.61, 6.25, 6.11, 5.85, 5.69, 5.63, 5.5, 5.36, 5.45, 5.52, 5.62, 5.8, 5.83, 5.79,
  5.77, 5.74, 5.73, 5.72, 5.74, 5.81, 5.89, 5.82, 5.67, 5.7, 5.85, 5.75, 5.62, 5.48, 5.46, 5.52,
  4.98, 5.07, 5.24, 5.31, 5.19, 4.92, 4.94, 4.92, 4.81, 4.68, 4.67, 4.7, 4.71, 4.72, 4.66, 4.57,
  4.57, 4.75, 4.79, 4.8, 4.81, 4.84, 4.89, 5.55, 5.5, 5.54, 5.86, 6.27, 6.84, 7.17, 7.61, 8.04, 8.3,
  8.35, 8.28, 8.12, 7.71, 7.33, 7.13, 6.82, 6.57, 6.43, 6.14, 6.0, 5.82, 5.7, 5.61, 5.56, 5.52,
  5.56, 5.87, 6.29, 6.82, 7.13, 7.61, 8.06, 8.33, 8.44, 8.42, 8.27, 7.86, 7.5, 7.35, 7.04, 6.72,
  6.52, 6.21, 6.11, 5.87, 5.74, 5.62, 5.58, 5.58, 5.58, 5.88, 6.29, 6.83, 7.13, 7.57, 8.01, 8.27,
  8.35, 8.29, 8.12, 7.72, 7.35, 7.16, 6.86, 6.58, 6.43, 6.14, 5.99, 5.78, 5.65, 5.54, 5.51, 5.48,
  5.54, 5.79, 6.19, 6.73, 7.05, 7.5, 7.98, 8.28, 8.39, 8.39, 8.28, 7.94, 7.56, 7.31, 6.98, 6.69,
  6.53, 6.21, 6.05, 5.87, 5.7, 5.6, 5.55, 5.51, 5.54, 5.81, 6.22, 6.79, 7.16, 7.62, 8.13, 8.5, 8.64,
  8.65, 8.51, 8.12, 7.71, 7.47, 7.14, 6.81, 6.61, 6.25, 6.11, 5.85, 5.69, 5.63, 5.5, 5.36, 5.45,
  5.52, 5.62, 5.8, 5.83, 5.79, 5.77, 5.74, 5.73, 5.72, 5.74, 5.81, 5.89, 5.82, 5.67, 5.7, 5.85,
  5.75, 5.62, 5.48, 5.46, 5.52, 4.98, 5.07, 5.15, 5.24, 5.31, 5.19, 4.92, 4.94, 4.92, 4.81, 4.68,
  4.67, 4.7, 4.71, 4.72, 4.66, 4.57, 4.57, 4.75, 4.79, 4.8, 4.81, 4.84, 4.89, 5.55, 5.5, 5.54, 5.86,
  6.27, 6.84, 7.17, 7.61, 8.04, 8.3, 8.35, 8.28, 8.12, 7.71, 7.33, 7.13, 6.82, 6.57, 6.43, 6.14,
  6.0, 5.82, 5.7, 5.61, 5.56, 5.52, 5.56, 5.87, 6.29, 6.82, 7.13, 7.61, 8.06, 8.33, 8.44, 8.42,
  8.27, 7.86, 7.5, 7.35, 7.04, 6.72, 6.52, 6.21, 6.11, 5.87, 5.74, 5.62, 5.58, 5.58, 5.58, 5.88,
  6.29, 6.83, 7.13, 7.57, 8.01, 8.27, 8.35, 8.29, 8.12, 7.72, 7.35, 7.16, 6.86, 6.58, 6.43, 6.14,
  5.99, 5.78, 5.65, 5.54, 5.51, 5.48, 5.54, 5.79, 6.19, 6.73, 7.05, 7.5, 7.98, 8.28, 8.39, 8.39,
  8.28, 7.94, 7.56, 7.31, 6.98, 6.69, 6.53, 6.21, 6.05, 5.87, 5.7, 5.6, 5.55, 5.51, 5.54, 5.81,
  6.22, 6.79, 7.16, 7.62, 8.13, 8.5, 8.64, 8.65, 8.51, 8.12, 7.71, 7.47, 7.14, 6.81, 6.61, 6.25,
  6.11, 5.85, 5.69, 5.63, 5.5, 5.36, 5.45, 5.52, 5.62, 5.8, 5.83, 5.79, 5.77, 5.74, 5.73, 5.72,
  5.74, 5.81, 5.89, 5.82, 5.67, 5.7, 5.85, 5.75, 5.62, 5.48, 5.46, 5.52, 4.98, 5.07, 5.15, 5.24,
  5.31, 5.19, 4.92, 4.94, 4.92, 4.81, 4.68, 4.67, 4.7, 4.71, 4.72, 4.66, 4.57, 4.57, 4.75, 4.79,
  4.8, 4.81, 4.84, 4.89, 5.55, 5.5, 5.54, 5.86, 6.27, 6.84, 7.17, 7.61, 8.04, 8.3, 8.35, 8.28, 8.12,
  7.71, 7.33, 7.13, 6.82, 6.57, 6.43, 6.14, 6.0, 5.82, 5.7, 5.61, 5.56, 5.52, 5.56, 5.87, 6.29,
  6.82, 7.13, 7.61, 8.06, 8.33, 8.44, 8.42, 8.27, 7.86, 7.5, 7.35, 7.04, 6.72, 6.52, 6.21, 6.11,
  5.87, 5.74, 5.62, 5.58, 5.58, 5.58, 5.88, 6.29, 6.83, 7.13, 7.57, 8.01, 8.27, 8.35, 8.29, 8.12,
  7.72, 7.35, 7.16, 6.86, 6.58, 6.43, 6.14, 5.99, 5.78, 5.65, 5.54, 5.51, 5.48, 5.54, 5.79, 6.19,
  6.73, 7.05, 7.5, 7.98, 8.28, 8.39, 8.39, 8.28, 7.94, 7.56, 7.31, 6.98, 6.69, 6.53, 6.21, 6.05,
  5.87, 5.7, 5.6, 5.55, 5.51, 5.54, 5.81, 6.22, 6.79, 7.16, 7.62, 8.13, 8.5, 8.64, 8.65, 8.51, 8.12,
  7.71, 7.47, 7.14, 6.81, 6.61, 6.25, 6.11, 5.85, 5.69, 5.63, 5.5, 5.36, 5.45, 5.52, 5.62, 5.8,
  5.83, 5.79, 5.77, 5.74, 5.73, 5.72, 5.74, 5.81, 5.89, 5.82, 5.67, 5.7, 5.85, 5.75, 5.62, 5.48,
  5.46, 5.52, 4.98, 5.07, 5.15, 5.24, 5.31, 5.19, 4.92, 4.94, 4.92, 4.81, 4.68, 4.67, 4.7, 4.71,
  4.72, 4.66, 4.57, 4.57, 4.75, 4.79, 4.8, 4.81, 4.84, 4.89, 5.51, 5.46, 5.52, 5.97, 6.42, 6.95,
  7.41, 7.94, 8.43, 8.66, 8.68, 8.62, 8.46, 8.0, 7.73, 7.63, 7.25, 6.86, 6.5, 6.23, 6.07, 5.71,
  5.59, 5.49, 5.44, 5.4, 5.48, 5.92, 6.39, 6.93, 7.4, 7.9, 8.4, 8.65, 8.7, 8.66, 8.48, 7.97, 7.59,
  7.4, 6.98, 6.65, 6.39, 6.17, 6.02, 5.73, 5.62, 5.53, 5.48, 5.47, 5.52, 5.92, 6.42, 6.93, 7.35,
  7.82, 8.28, 8.49, 8.49, 8.4, 8.22, 7.76, 7.46, 7.36, 7.01, 6.67, 6.37, 6.14, 6.0, 5.73, 5.62,
  5.51, 5.44, 5.43, 5.52, 5.93, 6.39, 6.91, 7.33, 7.88, 8.37, 8.6, 8.65, 8.62, 8.45, 7.96, 7.64,
  7.52, 7.13, 6.76, 6.43, 6.16, 6.02, 5.71, 5.63, 5.52, 5.46, 5.43, 5.5, 5.91, 6.41, 6.91, 7.37,
  7.94, 8.44, 8.72, 8.83, 8.86, 8.75, 8.28, 7.98, 7.79, 7.34, 6.93, 6.58, 6.32, 6.13, 5.79, 5.67,
  5.56, 5.42, 5.37, 5.55, 5.62, 5.77, 5.94, 6.16, 6.17, 6.25, 6.28, 6.31, 6.28, 6.32, 6.43, 6.51,
  6.32, 6.17, 6.12, 6.09, 5.89, 5.68, 5.45, 5.51, 5.57, 5.04, 5.06, 5.13, 5.2, 5.29, 4.99, 4.82,
  4.93, 4.9, 4.79, 4.69, 4.68, 4.7, 4.67, 4.66, 4.64, 4.64, 4.6, 4.68, 4.74, 4.76, 4.79, 4.82, 4.85,
  5.51, 5.46, 5.52, 5.97, 6.42, 6.95, 7.41, 7.94, 8.43, 8.66, 8.68, 8.62, 8.46, 8.0, 7.73, 7.63,
  7.25, 6.86, 6.5, 6.23, 6.07, 5.71, 5.59, 5.49, 5.44, 5.4, 5.48, 5.92, 6.39, 6.93, 7.4, 7.9, 8.4,
  8.65, 8.7, 8.66, 8.48, 7.97, 7.59, 7.4, 6.98, 6.65, 6.39, 6.17, 6.02, 5.73, 5.62, 5.53, 5.48,
  5.47, 5.52, 5.92, 6.42, 6.93, 7.35, 7.82, 8.28, 8.49, 8.49, 8.4, 8.22, 7.76, 7.46, 7.36, 7.01,
  6.67, 6.37, 6.14, 6.0, 5.73, 5.62, 5.51, 5.44, 5.43, 5.52, 5.93, 6.39, 6.91, 7.33, 7.88, 8.37,
  8.6, 8.65, 8.62, 8.45, 7.96, 7.64, 7.52, 7.13, 6.76, 6.43, 6.16, 6.02, 5.71, 5.63, 5.52, 5.46,
  5.43, 5.5, 5.91, 6.41, 6.91, 7.37, 7.94, 8.44, 8.72, 8.83, 8.86, 8.75, 8.28, 7.98, 7.79, 7.34,
  6.93, 6.58, 6.32, 6.13, 5.79, 5.67, 5.56, 5.42, 5.37, 5.55, 5.62, 5.77, 5.94, 6.16, 6.17, 6.25,
  6.28, 6.31, 6.28, 6.32, 6.43, 6.51, 6.32, 6.17, 6.12, 6.09, 5.89, 5.68, 5.45, 5.51, 5.57, 5.04,
  5.06, 5.13, 5.2, 5.29, 4.99, 4.82, 4.93, 4.9, 4.79, 4.69, 4.68, 4.7, 4.67, 4.66, 4.64, 4.64, 4.6,
  4.68, 4.74, 4.76, 4.79, 4.82, 4.85, 5.51, 5.46, 5.52, 5.97, 6.42, 6.95, 7.41, 7.94, 8.43, 8.66,
  8.68, 8.62, 8.46, 8.0, 7.73, 7.63, 7.25, 6.86, 6.5, 6.23, 6.07, 5.71, 5.59, 5.49, 5.44, 5.4, 5.48,
  5.92, 6.39, 6.93, 7.4, 7.9, 8.4, 8.65, 8.7, 8.66, 8.48, 7.97, 7.59, 7.4, 6.98, 6.65, 6.39, 6.17,
  6.02, 5.73, 5.62, 5.53, 5.48, 5.47, 5.52, 5.92, 6.42, 6.93, 7.35, 7.82, 8.28, 8.49, 8.49, 8.4,
  8.22, 7.76, 7.46, 7.36, 7.01, 6.67, 6.37, 6.14, 6.0, 5.73, 5.62, 5.51, 5.44, 5.43, 5.52, 5.93,
  6.39, 6.91, 7.33, 7.88, 8.37, 8.6, 8.65, 8.62, 8.45, 7.96, 7.64, 7.52, 7.13, 6.76, 6.43, 6.16,
  6.02, 5.71, 5.63, 5.52, 5.46, 5.43, 5.5, 5.91, 6.41, 6.91, 7.37, 7.94, 8.44, 8.72, 8.83, 8.86,
  8.75, 8.28, 7.98, 7.79, 7.34, 6.93, 6.58, 6.32, 6.13, 5.79, 5.67, 5.56, 5.42, 5.37, 5.55, 5.62,
  5.77, 5.94, 6.16, 6.17, 6.25, 6.28, 6.31, 6.28, 6.32, 6.43, 6.51, 6.32, 6.17, 6.12, 6.09, 5.89,
  5.68, 5.45, 5.51, 5.57, 5.04, 5.06, 5.13, 5.2, 5.29, 4.99, 4.82, 4.93, 4.9, 4.79, 4.69, 4.68, 4.7,
  4.67, 4.66, 4.64, 4.64, 4.6, 4.68, 4.74, 4.76, 4.79, 4.82, 4.85, 5.51, 5.46, 5.52, 5.97, 6.42,
  6.95, 7.41, 7.94, 8.43, 8.66, 8.68, 8.62, 8.46, 8.0, 7.73, 7.63, 7.25, 6.86, 6.5, 6.23, 6.07,
  5.71, 5.59, 5.49, 5.44, 5.4, 5.48, 5.92, 6.39, 6.93, 7.4, 7.9, 8.4, 8.65, 8.7, 8.66, 8.48, 7.97,
  7.59, 7.4, 6.98, 6.65, 6.39, 6.17, 6.02, 5.73, 5.62, 5.53, 5.48, 5.47, 5.52, 5.92, 6.42, 6.93,
  7.35, 7.82, 8.28, 8.49, 8.49, 8.4, 8.22, 7.76, 7.46, 7.36, 7.01, 6.67, 6.37, 6.14, 6.0, 5.73,
  5.62, 5.51, 5.44, 5.43, 5.52, 5.93, 6.39, 6.91, 7.33, 7.88, 8.37, 8.6, 8.65, 8.62, 8.45, 7.96,
  7.64, 7.52, 7.13, 6.76, 6.43, 6.16, 6.02, 5.71, 5.63, 5.52, 5.46, 5.43, 5.5, 5.91, 6.41, 6.91,
  7.37, 7.94, 8.44, 8.72, 8.83, 8.86, 8.75, 8.28, 7.98, 7.79, 7.34, 6.93, 6.58, 6.32, 6.13, 5.79,
  5.67, 5.56, 5.42, 5.37, 5.55, 5.62, 5.77, 5.94, 6.16, 6.17, 6.25, 6.28, 6.31, 6.28, 6.32, 6.43,
  6.51, 6.32, 6.17, 6.12, 6.09, 5.89, 5.68, 5.45, 5.51, 5.57, 5.04, 5.06, 5.13, 5.2, 5.29, 4.99,
  4.82, 4.93, 4.9, 4.79, 4.69, 4.68, 4.7, 4.67, 4.66, 4.64, 4.64, 4.6, 4.68, 4.74, 4.76, 4.79, 4.82,
  4.85, 5.51, 5.46, 5.52, 5.97, 6.42, 6.95, 7.41, 7.94, 8.43, 8.66, 8.68, 8.62, 8.46, 8.0, 7.73,
  7.63, 7.25, 6.86, 6.5, 6.23, 6.07, 5.71, 5.59, 5.49, 5.44, 5.4, 5.48, 5.92, 6.39, 6.93, 7.4, 7.9,
  8.4, 8.65, 8.7, 8.66, 8.48, 7.97, 7.59, 7.4, 6.98, 6.65, 6.39, 6.17, 6.02, 5.73, 5.62, 5.53, 5.57,
  5.52, 5.61, 6.15, 6.67, 7.43, 8.13, 8.84, 9.49, 9.86, 9.99, 10.01, 9.94, 9.44, 9.12, 9.05, 8.54,
  7.92, 7.09, 6.7, 6.47, 6.05, 5.92, 5.76, 5.66, 5.6, 5.65, 6.2, 6.74, 7.5, 8.09, 8.82, 9.47, 9.89,
  10.15, 10.29, 10.28, 9.86, 9.47, 9.22, 8.64, 8.03, 7.21, 6.87, 6.56, 6.09, 5.89, 5.68, 5.56, 5.52,
  5.61, 6.1, 6.56, 7.22, 7.92, 8.52, 9.09, 9.33, 9.35, 9.27, 9.1, 8.64, 8.36, 8.1, 7.53, 7.08, 6.58,
  6.35, 6.2, 5.81, 5.72, 5.56, 5.37, 5.32, 5.52, 5.61, 5.69, 5.98, 6.23, 6.23, 6.34, 6.33, 6.37,
  6.33, 6.36, 6.44, 6.48, 6.18, 5.95, 5.94, 5.85, 5.88, 5.64, 5.37, 5.42, 5.48, 4.98, 5.02, 5.07,
  5.13, 5.15, 4.85, 4.78, 4.9, 4.87, 4.77, 4.66, 4.66, 4.67, 4.67, 4.68, 4.65, 4.59, 4.54, 4.54,
  4.7, 4.7, 4.72, 4.75, 4.77, 5.42, 5.4, 5.49, 5.98, 6.4, 6.98, 7.55, 8.1, 8.72, 9.08, 9.23, 9.27,
  9.2, 8.82, 8.66, 8.54, 8.04, 7.49, 6.88, 6.54, 6.38, 5.94, 5.77, 5.63, 5.52, 5.49, 5.58, 6.1,
  6.59, 7.29, 7.95, 8.59, 9.19, 9.58, 9.71, 9.75, 9.66, 9.26, 9.02, 8.9, 8.27, 7.65, 6.96, 6.66,
  6.41, 5.95, 5.79, 5.64, 5.57, 5.52, 5.61, 6.15, 6.67, 7.43, 8.13, 8.84, 9.49, 9.86, 9.99, 10.01,
  9.94, 9.44, 9.12, 9.05, 8.54, 7.92, 7.09, 6.7, 6.47, 6.05, 5.92, 5.76, 5.66, 5.6, 5.65, 6.2, 6.74,
  7.5, 8.09, 8.82, 9.47, 9.89, 10.15, 10.29, 10.28, 9.86, 9.47, 9.22, 8.64, 8.03, 7.21, 6.87, 6.56,
  6.09, 5.89, 5.68, 5.56, 5.52, 5.61, 6.1, 6.56, 7.22, 7.92, 8.52, 9.09, 9.33, 9.35, 9.27, 9.1,
  8.64, 8.36, 8.1, 7.53, 7.08, 6.58, 6.35, 6.2, 5.81, 5.72, 5.56, 5.37, 5.32, 5.52, 5.61, 5.69,
  5.98, 6.23, 6.23, 6.34, 6.33, 6.37, 6.33, 6.36, 6.44, 6.48, 6.18, 5.95, 5.94, 5.85, 5.88, 5.64,
  5.37, 5.42, 5.48, 4.98, 5.02, 5.07, 5.13, 5.15, 4.85, 4.78, 4.9, 4.87, 4.77, 4.66, 4.66, 4.67,
  4.67, 4.68, 4.65, 4.59, 4.54, 4.54, 4.7, 4.7, 4.72, 4.75, 4.77, 5.42, 5.4, 5.49, 5.98, 6.4, 6.98,
  7.55, 8.1, 8.72, 9.08, 9.23, 9.27, 9.2, 8.82, 8.66, 8.54, 8.04, 7.49, 6.88, 6.54, 6.38, 5.94,
  5.77, 5.63, 5.52, 5.49, 5.58, 6.1, 6.59, 7.29, 7.95, 8.59, 9.19, 9.58, 9.71, 9.75, 9.66, 9.26,
  9.02, 8.9, 8.27, 7.65, 6.96, 6.66, 6.41, 5.95, 5.79, 5.64, 5.57, 5.52, 5.61, 6.15, 6.67, 7.43,
  8.13, 8.84, 9.49, 9.86, 9.99, 10.01, 9.94, 9.44, 9.12, 9.05, 8.54, 7.92, 7.09, 6.7, 6.47, 6.05,
  5.92, 5.76, 5.66, 5.6, 5.65, 6.2, 6.74, 7.5, 8.09, 8.82, 9.47, 9.89, 10.15, 10.29, 10.28, 9.86,
  9.47, 9.22, 8.64, 8.03, 7.21, 6.87, 6.56, 6.09, 5.89, 5.68, 5.56, 5.52, 5.61, 6.1, 6.56, 7.22,
  7.92, 8.52, 9.09, 9.33, 9.35, 9.27, 9.1, 8.64, 8.36, 8.1, 7.53, 7.08, 6.58, 6.35, 6.2, 5.81, 5.72,
  5.56, 5.37, 5.32, 5.52, 5.61, 5.69, 5.98, 6.23, 6.23, 6.34, 6.33, 6.37, 6.33, 6.36, 6.44, 6.48,
  6.18, 5.95, 5.94, 5.85, 5.88, 5.64, 5.37, 5.42, 5.48, 4.98, 5.02, 5.07, 5.13, 5.15, 4.85, 4.78,
  4.9, 4.87, 4.77, 4.66, 4.66, 4.67, 4.67, 4.68, 4.65, 4.59, 4.54, 4.54, 4.7, 4.7, 4.72, 4.75, 4.77,
  5.42, 5.4, 5.49, 5.98, 6.4, 6.98, 7.55, 8.1, 8.72, 9.08, 9.23, 9.27, 9.2, 8.82, 8.66, 8.54, 8.04,
  7.49, 6.88, 6.54, 6.38, 5.94, 5.77, 5.63, 5.52, 5.49, 5.58, 6.1, 6.59, 7.29, 7.95, 8.59, 9.19,
  9.58, 9.71, 9.75, 9.66, 9.26, 9.02, 8.9, 8.27, 7.65, 6.96, 6.66, 6.41, 5.95, 5.79, 5.64, 5.57,
  5.52, 5.61, 6.15, 6.67, 7.43, 8.13, 8.84, 9.49, 9.86, 9.99, 10.01, 9.94, 9.44, 9.12, 9.05, 8.54,
  7.92, 7.09, 6.7, 6.47, 6.05, 5.92, 5.76, 5.66, 5.6, 5.65, 6.2, 6.74, 7.5, 8.09, 8.82, 9.47, 9.89,
  10.15, 10.29, 10.28, 9.86, 9.47, 9.22, 8.64, 8.03, 7.21, 6.87, 6.56, 6.09, 5.89, 5.68, 5.56, 5.52,
  5.61, 6.1, 6.56, 7.22, 7.92, 8.52, 9.09, 9.33, 9.35, 9.27, 9.1, 8.64, 8.36, 8.1, 7.53, 7.08, 6.58,
  6.35, 6.2, 5.81, 5.72, 5.56, 5.37, 5.32, 5.52, 5.61, 5.69, 5.98, 6.23, 6.23, 6.34, 6.33, 6.37,
  6.33, 6.36, 6.44, 6.48, 6.18, 5.95, 5.94, 5.85, 5.88, 5.64, 5.37, 5.42, 5.48, 4.98, 5.02, 5.07,
  5.13, 5.15, 4.85, 4.78, 4.9, 4.87, 4.77, 4.66, 4.66, 4.67, 4.67, 4.68, 4.65, 4.59, 4.54, 4.54,
  4.7, 4.7, 4.72, 4.75, 4.77, 5.42, 5.4, 5.49, 5.98, 6.4, 6.98, 7.55, 8.1, 8.72, 9.08, 9.23, 9.27,
  9.2, 8.82, 8.66, 8.54, 8.04, 7.49, 6.88, 6.54, 6.38, 5.94, 5.77, 5.63, 5.52, 5.49, 5.58, 6.1,
  6.59, 7.29, 7.95, 8.59, 9.19, 9.58, 9.71, 9.75, 9.66, 9.26, 9.02, 8.9, 8.27, 7.65, 6.96, 6.66,
  6.41, 5.95, 5.79, 5.64, 5.57, 5.52, 5.61, 6.15, 6.67, 7.43, 8.13, 8.84, 9.49, 9.86, 9.99, 10.01,
  9.94, 9.44, 9.12, 9.05, 8.54, 7.92, 7.09, 6.7, 6.47, 6.05, 5.92, 5.76, 5.66, 5.6, 5.65, 6.2, 6.74,
  7.5, 8.09, 8.82, 9.47, 9.89, 10.15, 10.29, 10.28, 9.86, 9.47, 9.22, 8.64, 8.03, 7.21, 6.87, 6.56,
  6.09, 5.89, 5.68, 5.56, 5.52, 5.61, 6.1, 6.56, 7.22, 7.92, 8.52, 9.09, 9.33, 9.35, 9.27, 9.1,
  8.64, 8.36, 8.1, 7.53, 7.08, 6.58, 6.35, 6.2, 5.81, 5.72, 5.56, 5.64, 5.62, 5.89, 6.03, 6.15, 6.7,
  7.07, 7.14, 7.29, 7.31, 7.42, 7.56, 7.67, 7.88, 8.08, 7.93, 7.74, 7.6, 7.15, 6.58, 6.33, 5.94,
  5.94, 5.87, 5.22, 5.26, 5.32, 5.37, 5.33, 5.12, 5.16, 5.54, 5.59, 5.5, 5.42, 5.46, 5.44, 5.49,
  5.61, 5.59, 5.46, 5.37, 5.21, 5.12, 5.1, 5.03, 5.05, 5.09, 5.74, 5.69, 5.85, 6.47, 7.03, 7.94,
  8.67, 9.32, 9.91, 10.22, 10.23, 10.08, 9.86, 9.33, 8.97, 8.67, 8.03, 7.46, 6.93, 6.66, 6.44, 6.02,
  5.86, 5.65, 5.59, 5.55, 5.67, 6.21, 6.7, 7.52, 8.34, 8.99, 9.59, 9.92, 10.0, 9.98, 9.89, 9.48,
  9.25, 9.0, 8.4, 7.81, 7.09, 6.76, 6.54, 6.06, 5.91, 5.7, 5.63, 5.59, 5.7, 6.24, 6.72, 7.53, 8.39,
  9.11, 9.82, 10.11, 10.2, 10.23, 10.13, 9.67, 9.36, 9.03, 8.44, 7.85, 7.15, 6.87, 6.62, 6.15, 6.01,
  5.77, 5.7, 5.67, 5.8, 6.41, 6.91, 7.79, 8.48, 9.13, 9.8, 10.14, 10.22, 10.22, 10.07, 9.59, 9.25,
  9.1, 8.65, 8.09, 7.31, 6.93, 6.66, 6.2, 6.03, 5.81, 5.71, 5.66, 5.79, 6.37, 6.87, 7.7, 8.52, 9.25,
  9.86, 10.2, 10.3, 10.25, 10.07, 9.67, 9.5, 9.34, 8.72, 8.04, 7.27, 7.0, 6.69, 6.23, 6.03, 5.79,
  5.64, 5.62, 5.89, 6.03, 6.15, 6.7, 7.07, 7.14, 7.29, 7.31, 7.42, 7.56, 7.67, 7.88, 8.08, 7.93,
  7.74, 7.6, 7.15, 6.58, 6.33, 5.94, 5.94, 5.87, 5.22, 5.26, 5.32, 5.37, 5.33, 5.12, 5.16, 5.54,
  5.59, 5.5, 5.42, 5.46, 5.44, 5.49, 5.61, 5.59, 5.46, 5.37, 5.21, 5.12, 5.1, 5.03, 5.05, 5.09,
  5.74, 5.69, 5.85, 6.47, 7.03, 7.94, 8.67, 9.32, 9.91, 10.22, 10.23, 10.08, 9.86, 9.33, 8.97, 8.67,
  8.03, 7.46, 6.93, 6.66, 6.44, 6.02, 5.86, 5.65, 5.59, 5.55, 5.67, 6.21, 6.7, 7.52, 8.34, 8.99,
  9.59, 9.92, 10.0, 9.98, 9.89, 9.48, 9.25, 9.0, 8.4, 7.81, 7.09, 6.76, 6.54, 6.06, 5.91, 5.7, 5.63,
  5.59, 5.7, 6.24, 6.72, 7.53, 8.39, 9.11, 9.82, 10.11, 10.2, 10.23, 10.13, 9.67, 9.36, 9.03, 8.44,
  7.85, 7.15, 6.87, 6.62, 6.15, 6.01, 5.77, 5.7, 5.67, 5.8, 6.41, 6.91, 7.79, 8.48, 9.13, 9.8,
  10.14, 10.22, 10.22, 10.07, 9.59, 9.25, 9.1, 8.65, 8.09, 7.31, 6.93, 6.66, 6.2, 6.03, 5.81, 5.71,
  5.66, 5.79, 6.37, 6.87, 7.7, 8.52, 9.25, 9.86, 10.2, 10.3, 10.25, 10.07, 9.67, 9.5, 9.34, 8.72,
  8.04, 7.27, 7.0, 6.69, 6.23, 6.03, 5.79, 5.64, 5.62, 5.89, 6.03, 6.15, 6.7, 7.07, 7.14, 7.29,
  7.31, 7.42, 7.56, 7.67, 7.88, 8.08, 7.93, 7.74, 7.6, 7.15, 6.58, 6.33, 5.94, 5.94, 5.87, 5.22,
  5.26, 5.32, 5.37, 5.33, 5.12, 5.16, 5.54, 5.59, 5.5, 5.42, 5.46, 5.44, 5.49, 5.61, 5.59, 5.46,
  5.37, 5.21, 5.12, 5.1, 5.03, 5.05, 5.09, 5.74, 5.69, 5.85, 6.47, 7.03, 7.94, 8.67, 9.32, 9.91,
  10.22, 10.23, 10.08, 9.86, 9.33, 8.97, 8.67, 8.03, 7.46, 6.93, 6.66, 6.44, 6.02, 5.86, 5.65, 5.59,
  5.55, 5.67, 6.21, 6.7, 7.52, 8.34, 8.99, 9.59, 9.92, 10.0, 9.98, 9.89, 9.48, 9.25, 9.0, 8.4, 7.81,
  7.09, 6.76, 6.54, 6.06, 5.91, 5.7, 5.63, 5.59, 5.7, 6.24, 6.72, 7.53, 8.39, 9.11, 9.82, 10.11,
  10.2, 10.23, 10.13, 9.67, 9.36, 9.03, 8.44, 7.85, 7.15, 6.87, 6.62, 6.15, 6.01, 5.77, 5.7, 5.67,
  5.8, 6.41, 6.91, 7.79, 8.48, 9.13, 9.8, 10.14, 10.22, 10.22, 10.07, 9.59, 9.25, 9.1, 8.65, 8.09,
  7.31, 6.93, 6.66, 6.2, 6.03, 5.81, 5.71, 5.66, 5.79, 6.37, 6.87, 7.7, 8.52, 9.25, 9.86, 10.2,
  10.3, 10.25, 10.07, 9.67, 9.5, 9.34, 8.72, 8.04, 7.27, 7.0, 6.69, 6.23, 6.03, 5.79, 5.64, 5.62,
  5.89, 6.03, 6.15, 6.7, 7.07, 7.14, 7.29, 7.31, 7.42, 7.56, 7.67, 7.88, 8.08, 7.93, 7.74, 7.6,
  7.15, 6.58, 6.33, 5.94, 5.94, 5.87, 5.22, 5.26, 5.32, 5.37, 5.33, 5.12, 5.16, 5.54, 5.59, 5.5,
  5.42, 5.46, 5.44, 5.49, 5.61, 5.59, 5.46, 5.37, 5.21, 5.12, 5.1, 5.03, 5.05, 5.09, 5.74, 5.69,
  5.85, 6.47, 7.03, 7.94, 8.67, 9.32, 9.91, 10.22, 10.23, 10.08, 9.86, 9.33, 8.97, 8.67, 8.03, 7.46,
  6.93, 6.66, 6.44, 6.02, 5.86, 5.65, 5.59, 5.55, 5.67, 6.21, 6.7, 7.52, 8.34, 8.99, 9.59, 9.92,
  10.0, 9.98, 9.89, 9.48, 9.25, 9.0, 8.4, 7.81, 7.09, 6.76, 6.54, 6.06, 5.91, 5.7, 5.63, 5.59, 5.7,
  6.24, 6.72, 7.53, 8.39, 9.11, 9.82, 10.11, 10.2, 10.23, 10.13, 9.67, 9.36, 9.03, 8.44, 7.85, 7.15,
  6.87, 6.62, 6.15, 6.01, 5.77, 5.7, 5.67, 5.8, 6.41, 6.91, 7.79, 8.48, 9.13, 9.8, 10.14, 10.22,
  10.22, 10.07, 9.59, 9.25, 9.1, 8.65, 8.09, 7.31, 6.93, 6.66, 6.2, 6.03, 5.81, 5.71, 5.66, 5.79,
  6.37, 6.87, 7.7, 8.52, 9.25, 9.86, 10.2, 10.3, 10.25, 10.07, 9.67, 9.5, 9.34, 8.72, 8.04, 7.27,
  7.0, 6.69, 6.23, 6.03, 5.79, 5.64, 5.62, 5.89, 6.03, 6.15, 6.7, 7.07, 7.14, 7.29, 7.31, 7.42,
  7.56, 7.67, 7.88, 8.08, 7.93, 7.74, 7.6, 7.15, 6.58, 6.33, 5.94, 5.94, 5.87, 5.22, 5.26, 5.32,
  5.37, 5.33, 5.12, 5.16, 5.54, 5.59, 5.5, 5.42, 5.46, 5.44, 5.49, 5.61, 5.59, 5.46, 5.37, 5.21,
  5.12, 5.1, 5.03, 5.05, 5.09, 5.86, 5.77, 5.97, 6.71, 7.36, 8.22, 8.89, 9.66, 10.35, 10.83, 11.0,
  11.04, 10.91, 10.23, 9.76, 9.55, 9.16, 8.57, 7.66, 7.3, 6.87, 6.45, 6.13, 5.87, 5.82, 5.78, 5.95,
  6.68, 7.34, 8.26, 9.12, 9.97, 10.7, 11.07, 11.2, 11.28, 11.21, 10.81, 10.55, 10.32, 9.7, 8.81,
  7.8, 7.48, 7.03, 6.54, 6.22, 5.99, 5.92, 5.91, 6.03, 6.82, 7.56, 8.55, 9.41, 10.32, 11.26, 11.83,
  12.08, 12.13, 11.95, 11.37, 11.1, 10.96, 10.19, 9.23, 8.1, 7.71, 7.26, 6.9, 6.45, 6.18, 6.08,
  5.97, 6.09, 6.94, 7.68, 8.64, 9.57, 10.61, 11.49, 11.82, 11.86, 11.61, 11.39, 10.98, 10.53, 10.31,
  9.71, 8.85, 7.86, 7.48, 6.97, 6.45, 6.2, 5.92, 5.86, 5.83, 6.01, 6.71, 7.32, 8.17, 8.99, 9.86,
  10.63, 11.03, 11.07, 11.03, 10.97, 10.66, 10.51, 10.33, 9.64, 8.75, 7.74, 7.43, 6.99, 6.46, 6.19,
  5.93, 5.82, 5.82, 6.09, 6.25, 6.58, 7.14, 7.59, 7.67, 7.88, 7.9, 7.95, 8.02, 8.09, 8.25, 8.3,
  7.96, 7.67, 7.53, 7.26, 6.74, 6.41, 6.01, 5.98, 5.9, 5.26, 5.29, 5.33, 5.4, 5.41, 5.17, 5.17,
  5.55, 5.66, 5.6, 5.52, 5.61, 5.66, 5.69, 5.86, 5.86, 5.73, 5.63, 5.44, 5.34, 5.33, 5.25, 5.26,
  5.26, 5.86, 5.77, 5.97, 6.71, 7.36, 8.22, 8.89, 9.66, 10.35, 10.83, 11.0, 11.04, 10.91, 10.23,
  9.76, 9.55, 9.16, 8.57, 7.66, 7.3, 6.87, 6.45, 6.13, 5.87, 5.82, 5.78, 5.95, 6.68, 7.34, 8.26,
  9.12, 9.97, 10.7, 11.07, 11.2, 11.28, 11.21, 10.81, 10.55, 10.32, 9.7, 8.81, 7.8, 7.48, 7.03,
  6.54, 6.22, 5.99, 5.92, 5.91, 6.03, 6.82, 7.56, 8.55, 9.41, 10.32, 11.26, 11.83, 12.08, 12.13,
  11.95, 11.37, 11.1, 10.96, 10.19, 9.23, 8.1, 7.71, 7.26, 6.9, 6.45, 6.18, 6.08, 5.97, 6.09, 6.94,
  7.68, 8.64, 9.57, 10.61, 11.49, 11.82, 11.86, 11.61, 11.39, 10.98, 10.53, 10.31, 9.71, 8.85, 7.86,
  7.48, 6.97, 6.45, 6.2, 5.92, 5.86, 5.83, 6.01, 6.71, 7.32, 8.17, 8.99, 9.86, 10.63, 11.03, 11.07,
  11.03, 10.97, 10.66, 10.51, 10.33, 9.64, 8.75, 7.74, 7.43, 6.99, 6.46, 6.19, 5.93, 5.82, 5.82,
  6.09, 6.25, 6.58, 7.14, 7.59, 7.67, 7.88, 7.9, 7.95, 8.02, 8.09, 8.25, 8.3, 7.96, 7.67, 7.53,
  7.26, 6.74, 6.41, 6.01, 5.98, 5.9, 5.26, 5.29, 5.33, 5.4, 5.41, 5.17, 5.17, 5.55, 5.66, 5.6, 5.52,
  5.61, 5.66, 5.69, 5.86, 5.86, 5.73, 5.63, 5.44, 5.34, 5.33, 5.25, 5.26, 5.26, 5.86, 5.77, 5.97,
  6.71, 7.36, 8.22, 8.89, 9.66, 10.35, 10.83, 11.0, 11.04, 10.91, 10.23, 9.76, 9.55, 9.16, 8.57,
  7.66, 7.3, 6.87, 6.45, 6.13, 5.87, 5.82, 5.78, 5.95, 6.68, 7.34, 8.26, 9.12, 9.97, 10.7, 11.07,
  11.2, 11.28, 11.21, 10.81, 10.55, 10.32, 9.7, 8.81, 7.8, 7.48, 7.03, 6.54, 6.22, 5.99, 5.92, 5.91,
  6.03, 6.82, 7.56, 8.55, 9.41, 10.32, 11.26, 11.83, 12.08, 12.13, 11.95, 11.37, 11.1, 10.96, 10.19,
  9.23, 8.1, 7.71, 7.26, 6.9, 6.45, 6.18, 6.08, 5.97, 6.09, 6.94, 7.68, 8.64, 9.57, 10.61, 11.49,
  11.82, 11.86, 11.61, 11.39, 10.98, 10.53, 10.31, 9.71, 8.85, 7.86, 7.48, 6.97, 6.45, 6.2, 5.92,
  5.86, 5.83, 6.01, 6.71, 7.32, 8.17, 8.99, 9.86, 10.63, 11.03, 11.07, 11.03, 10.97, 10.66, 10.51,
  10.33, 9.64, 8.75, 7.74, 7.43, 6.99, 6.46, 6.19, 5.93, 5.82, 5.82, 6.09, 6.25, 6.58, 7.14, 7.59,
  7.67, 7.88, 7.9, 7.95, 8.02, 8.09, 8.25, 8.3, 7.96, 7.67, 7.53, 7.26, 6.74, 6.41, 6.01, 5.98, 5.9,
  5.26, 5.29, 5.33, 5.4, 5.41, 5.17, 5.17, 5.55, 5.66, 5.6, 5.52, 5.61, 5.66, 5.69, 5.86, 5.86,
  5.73, 5.63, 5.44, 5.34, 5.33, 5.25, 5.26, 5.26, 5.86, 5.77, 5.97, 6.71, 7.36, 8.22, 8.89, 9.66,
  10.35, 10.83, 11.0, 11.04, 10.91, 10.23, 9.76, 9.55, 9.16, 8.57, 7.66, 7.3, 6.87, 6.45, 6.13,
  5.87, 5.82, 5.78, 5.95, 6.68, 7.34, 8.26, 9.12, 9.97, 10.7, 11.07, 11.2, 11.28, 11.21, 10.81,
  10.55, 10.32, 9.7, 8.81, 7.8, 7.48, 7.03, 6.54, 6.22, 5.99, 5.92, 5.91, 6.03, 6.82, 7.56, 8.55,
  9.41, 10.32, 11.26, 11.83, 12.08, 12.13, 11.95, 11.37, 11.1, 10.96, 10.19, 9.23, 8.1, 7.71, 7.26,
  6.9, 6.45, 6.18, 6.08, 5.97, 6.09, 6.94, 7.68, 8.64, 9.57, 10.61, 11.49, 11.82, 11.86, 11.61,
  11.39, 10.98, 10.53, 10.31, 9.71, 8.85, 7.86, 7.48, 6.97, 6.45, 6.2, 5.92, 5.86, 5.83, 6.01, 6.71,
  7.32, 8.17, 8.99, 9.86, 10.63, 11.03, 11.07, 11.03, 10.97, 10.66, 10.51, 10.33, 9.64, 8.75, 7.74,
  7.43, 6.99, 6.46, 6.19, 5.93, 5.82, 5.82, 6.09, 6.25, 6.58, 7.14, 7.59, 7.67, 7.88, 7.9, 7.95,
  8.02, 8.09, 8.25, 8.3, 7.96, 7.67, 7.53, 7.26, 6.74, 6.41, 6.01, 5.98, 5.9, 5.26, 5.29, 5.33, 5.4,
  5.41, 5.17, 5.17, 5.55, 5.66, 5.6, 5.52, 5.61, 5.66, 5.69, 5.86, 5.86, 5.73, 5.63, 5.44, 5.34,
  5.33, 5.25, 5.26, 5.26, 5.86, 5.77, 5.97, 6.71, 7.36, 8.22, 8.89, 9.66, 10.35, 10.83, 11.0, 11.04,
  10.91, 10.23, 9.76, 9.55, 9.16, 8.57, 7.66, 7.3, 6.87, 6.45, 6.13, 5.87, 5.82, 5.78, 5.95, 6.68,
  7.34, 8.26, 9.12, 9.97, 10.7, 11.07, 11.2, 11.28, 11.21, 10.81, 10.55, 10.32, 9.7, 8.81, 7.8,
  7.48, 7.03, 6.54, 6.22, 5.99, 5.92, 5.91, 6.03, 6.82, 7.56, 8.55, 9.41, 10.32, 11.26, 11.83,
  12.08, 12.13, 11.95, 11.37, 11.1, 10.96, 10.19, 9.23, 8.1, 7.71, 7.26, 6.9, 6.45, 6.18, 5.8, 5.75,
  5.89, 6.52, 7.17, 7.88, 8.58, 9.41, 10.08, 10.45, 10.6, 10.63, 10.48, 9.94, 9.6, 9.41, 8.81, 8.1,
  7.42, 7.13, 6.82, 6.36, 6.14, 5.88, 5.82, 5.79, 5.95, 6.68, 7.32, 8.08, 8.86, 9.65, 10.42, 10.84,
  11.01, 11.09, 11.01, 10.57, 10.24, 10.02, 9.4, 8.61, 7.74, 7.34, 6.98, 6.5, 6.25, 6.01, 5.91,
  5.92, 6.23, 6.41, 6.64, 7.09, 7.5, 7.67, 7.95, 7.95, 7.93, 7.91, 8.04, 8.22, 8.38, 8.18, 7.93,
  7.71, 7.33, 6.78, 6.49, 6.08, 6.06, 5.88, 5.25, 5.29, 5.34, 5.4, 5.48, 5.18, 5.22, 5.56, 5.65,
  5.61, 5.57, 5.67, 5.72, 5.7, 5.68, 5.59, 5.45, 5.3, 5.22, 5.26, 5.28, 5.18, 5.23, 5.19, 5.82,
  5.75, 5.93, 6.64, 7.33, 7.99, 8.66, 9.38, 10.06, 10.33, 10.37, 10.36, 10.24, 9.73, 9.32, 9.09,
  8.51, 7.87, 7.22, 6.89, 6.58, 6.12, 5.95, 5.74, 5.68, 5.65, 5.78, 6.36, 6.97, 7.64, 8.4, 9.14,
  9.83, 10.2, 10.26, 10.21, 10.05, 9.54, 9.26, 9.21, 8.71, 8.1, 7.34, 6.99, 6.69, 6.23, 6.05, 5.83,
  5.77, 5.73, 5.88, 6.51, 7.16, 7.89, 8.69, 9.41, 10.25, 10.63, 10.84, 10.89, 10.82, 10.51, 10.22,
  9.98, 9.39, 8.57, 7.71, 7.37, 6.97, 6.42, 6.16, 5.89, 5.8, 5.75, 5.89, 6.52, 7.17, 7.88, 8.58,
  9.41, 10.08, 10.45, 10.6, 10.63, 10.48, 9.94, 9.6, 9.41, 8.81, 8.1, 7.42, 7.13, 6.82, 6.36, 6.14,
  5.88, 5.82, 5.79, 5.95, 6.68, 7.32, 8.08, 8.86, 9.65, 10.42, 10.84, 11.01, 11.09, 11.01, 10.57,
  10.24, 10.02, 9.4, 8.61, 7.74, 7.34, 6.98, 6.5, 6.25, 6.01, 5.91, 5.92, 6.23, 6.41, 6.64, 7.09,
  7.5, 7.67, 7.95, 7.95, 7.93, 7.91, 8.04, 8.22, 8.38, 8.18, 7.93, 7.71, 7.33, 6.78, 6.49, 6.08,
  6.06, 5.88, 5.25, 5.29, 5.34, 5.4, 5.48, 5.18, 5.22, 5.56, 5.65, 5.61, 5.57, 5.67, 5.72, 5.7,
  5.68, 5.59, 5.45, 5.3, 5.22, 5.26, 5.28, 5.18, 5.23, 5.19, 5.82, 5.75, 5.93, 6.64, 7.33, 7.99,
  8.66, 9.38, 10.06, 10.33, 10.37, 10.36, 10.24, 9.73, 9.32, 9.09, 8.51, 7.87, 7.22, 6.89, 6.58,
  6.12, 5.95, 5.74, 5.68, 5.65, 5.78, 6.36, 6.97, 7.64, 8.4, 9.14, 9.83, 10.2, 10.26, 10.21, 10.05,
  9.54, 9.26, 9.21, 8.71, 8.1, 7.34, 6.99, 6.69, 6.23, 6.05, 5.83, 5.77, 5.73, 5.88, 6.51, 7.16,
  7.89, 8.69, 9.41, 10.25, 10.63, 10.84, 10.89, 10.82, 10.51, 10.22, 9.98, 9.39, 8.57, 7.71, 7.37,
  6.97, 6.42, 6.16, 5.89, 5.8, 5.75, 5.89, 6.52, 7.17, 7.88, 8.58, 9.41, 10.08, 10.45, 10.6, 10.63,
  10.48, 9.94, 9.6, 9.41, 8.81, 8.1, 7.42, 7.13, 6.82, 6.36, 6.14, 5.88, 5.82, 5.79, 5.95, 6.68,
  7.32, 8.08, 8.86, 9.65, 10.42, 10.84, 11.01, 11.09, 11.01, 10.57, 10.24, 10.02, 9.4, 8.61, 7.74,
  7.34, 6.98, 6.5, 6.25, 6.01, 5.91, 5.92, 6.23, 6.41, 6.64, 7.09, 7.5, 7.67, 7.95, 7.95, 7.93,
  7.91, 8.04, 8.22, 8.38, 8.18, 7.93, 7.71, 7.33, 6.78, 6.49, 6.08, 6.06, 5.88, 5.25, 5.29, 5.34,
  5.4, 5.48, 5.18, 5.22, 5.56, 5.65, 5.61, 5.57, 5.67, 5.72, 5.7, 5.68, 5.59, 5.45, 5.3, 5.22, 5.26,
  5.28, 5.18, 5.23, 5.19, 5.82, 5.75, 5.93, 6.64, 7.33, 7.99, 8.66, 9.38, 10.06, 10.33, 10.37,
  10.36, 10.24, 9.73, 9.32, 9.09, 8.51, 7.87, 7.22, 6.89, 6.58, 6.12, 5.95, 5.74, 5.68, 5.65, 5.78,
  6.36, 6.97, 7.64, 8.4, 9.14, 9.83, 10.2, 10.26, 10.21, 10.05, 9.54, 9.26, 9.21, 8.71, 8.1, 7.34,
  6.99, 6.69, 6.23, 6.05, 5.83, 5.77, 5.73, 5.88, 6.51, 7.16, 7.89, 8.69, 9.41, 10.25, 10.63, 10.84,
  10.89, 10.82, 10.51, 10.22, 9.98, 9.39, 8.57, 7.71, 7.37, 6.97, 6.42, 6.16, 5.89, 5.8, 5.75, 5.89,
  6.52, 7.17, 7.88, 8.58, 9.41, 10.08, 10.45, 10.6, 10.63, 10.48, 9.94, 9.6, 9.41, 8.81, 8.1, 7.42,
  7.13, 6.82, 6.36, 6.14, 5.88, 5.82, 5.79, 5.95, 6.68, 7.32, 8.08, 8.86, 9.65, 10.42, 10.84, 11.01,
  11.09, 11.01, 10.57, 10.24, 10.02, 9.4, 8.61, 7.74, 7.34, 6.98, 6.5, 6.25, 6.01, 5.91, 5.92, 6.23,
  6.41, 6.64, 7.09, 7.5, 7.67, 7.95, 7.95, 7.93, 7.91, 8.04, 8.22, 8.38, 8.18, 7.93, 7.71, 7.33,
  6.78, 6.49, 6.08, 6.06, 5.88, 5.25, 5.29, 5.34, 5.4, 5.48, 5.18, 5.22, 5.56, 5.65, 5.61, 5.57,
  5.67, 5.72, 5.7, 5.68, 5.59, 5.45, 5.3, 5.22, 5.26, 5.28, 5.18, 5.23, 5.19, 5.82, 5.75, 5.93,
  6.64, 7.33, 7.99, 8.66, 9.38, 10.06, 10.33, 10.37, 10.36, 10.24, 9.73, 9.32, 9.09, 8.51, 7.87,
  7.22, 6.89, 6.58, 6.12, 5.95, 5.74, 5.68, 5.65, 5.78, 6.36, 6.97, 7.64, 8.4, 9.14, 9.83, 10.2,
  10.26, 10.21, 10.05, 9.54, 9.26, 9.21, 8.71, 8.1, 7.34, 6.99, 6.69, 6.23, 6.05, 5.83, 5.77, 5.73,
  5.88, 6.51, 7.16, 7.89, 8.69, 9.41, 10.25, 10.63, 10.84, 10.89, 10.82, 10.51, 10.22, 9.98, 9.39,
  8.57, 7.71, 7.37, 6.97, 6.42, 6.16, 5.89, 5.8, 5.75, 5.89, 6.52, 7.17, 7.88, 8.58, 9.41, 10.08,
  10.45, 10.6, 10.63, 10.48, 9.94, 9.6, 9.41, 8.81, 8.1, 7.42, 7.13, 6.82, 6.36, 6.14, 5.88, 5.82,
  5.79, 5.95, 6.68, 7.32, 8.08, 8.86, 9.65, 10.42, 10.84, 11.01, 11.09, 11.01, 10.57, 10.24, 10.02,
  9.4, 8.61, 7.74, 7.34, 6.98, 6.5, 6.25, 6.01, 5.91, 5.92, 6.23, 6.41, 6.64, 7.09, 7.5, 7.67, 7.95,
  7.95, 7.93, 7.91, 8.04, 8.22, 8.38, 8.18, 7.93, 7.71, 7.33, 6.78, 6.49, 6.08, 6.06, 5.88, 5.09,
  5.11, 5.17, 5.23, 5.3, 5.02, 4.84, 5.0, 5.08, 5.03, 5.0, 5.1, 5.17, 5.18, 5.17, 5.1, 4.98, 4.87,
  4.89, 4.89, 4.9, 4.92, 4.94, 4.97, 5.64, 5.58, 5.72, 6.33, 6.97, 7.69, 8.22, 8.95, 9.6, 9.93,
  10.06, 10.06, 9.93, 9.41, 9.09, 8.9, 8.3, 7.67, 7.17, 6.76, 6.51, 6.07, 5.91, 5.7, 5.65, 5.61,
  5.73, 6.3, 6.9, 7.61, 8.17, 8.86, 9.55, 9.93, 10.05, 10.07, 9.96, 9.48, 9.12, 8.93, 8.32, 7.67,
  7.17, 6.76, 6.51, 6.04, 5.9, 5.7, 5.62, 5.59, 5.7, 6.28, 6.89, 7.59, 8.09, 8.87, 9.53, 9.97,
  10.12, 10.1, 9.97, 9.46, 9.04, 8.79, 8.21, 7.63, 7.16, 6.73, 6.54, 6.06, 5.92, 5.73, 5.65, 5.6,
  5.74, 6.36, 6.98, 7.72, 8.25, 8.89, 9.52, 9.83, 9.91, 9.87, 9.71, 9.23, 8.91, 8.72, 8.14, 7.56,
  7.09, 6.66, 6.48, 6.04, 5.91, 5.73, 5.68, 5.65, 5.78, 6.37, 6.99, 7.75, 8.27, 8.95, 9.6, 10.01,
  10.14, 10.12, 9.95, 9.45, 9.1, 8.87, 8.33, 7.78, 7.31, 6.79, 6.61, 6.1, 5.96, 5.77, 5.62, 5.57,
  5.82, 5.93, 6.15, 6.57, 6.77, 6.75, 6.84, 6.82, 6.86, 6.85, 6.9, 7.01, 7.1, 6.85, 6.56, 6.46,
  6.35, 6.15, 5.85, 5.56, 5.56, 5.59, 5.09, 5.11, 5.17, 5.23, 5.3, 5.02, 4.84, 5.0, 5.08, 5.03, 5.0,
  5.1, 5.17, 5.18, 5.17, 5.1, 4.98, 4.87, 4.89, 4.89, 4.9, 4.92, 4.94, 4.97, 5.64, 5.58, 5.72, 6.33,
  6.97, 7.69, 8.22, 8.95, 9.6, 9.93, 10.06, 10.06, 9.93, 9.41, 9.09, 8.9, 8.3, 7.67, 7.17, 6.76,
  6.51, 6.07, 5.91, 5.7, 5.65, 5.61, 5.73, 6.3, 6.9, 7.61, 8.17, 8.86, 9.55, 9.93, 10.05, 10.07,
  9.96, 9.48, 9.12, 8.93, 8.32, 7.67, 7.17, 6.76, 6.51, 6.04, 5.9, 5.7, 5.62, 5.59, 5.7, 6.28, 6.89,
  7.59, 8.09, 8.87, 9.53, 9.97, 10.12, 10.1, 9.97, 9.46, 9.04, 8.79, 8.21, 7.63, 7.16, 6.73, 6.54,
  6.06, 5.92, 5.73, 5.65, 5.6, 5.74, 6.36, 6.98, 7.72, 8.25, 8.89, 9.52, 9.83, 9.91, 9.87, 9.71,
  9.23, 8.91, 8.72, 8.14, 7.56, 7.09, 6.66, 6.48, 6.04, 5.91, 5.73, 5.68, 5.65, 5.78, 6.37, 6.99,
  7.75, 8.27, 8.95, 9.6, 10.01, 10.14, 10.12, 9.95, 9.45, 9.1, 8.87, 8.33, 7.78, 7.31, 6.79, 6.61,
  6.1, 5.96, 5.77, 5.62, 5.57, 5.82, 5.93, 6.15, 6.57, 6.77, 6.75, 6.84, 6.82, 6.86, 6.85, 6.9,
  7.01, 7.1, 6.85, 6.56, 6.46, 6.35, 6.15, 5.85, 5.56, 5.56, 5.59, 5.09, 5.11, 5.17, 5.23, 5.3,
  5.02, 4.84, 5.0, 5.08, 5.03, 5.0, 5.1, 5.17, 5.18, 5.17, 5.1, 4.98, 4.87, 4.89, 4.89, 4.9, 4.92,
  4.94, 4.97, 5.64, 5.58, 5.72, 6.33, 6.97, 7.69, 8.22, 8.95, 9.6, 9.93, 10.06, 10.06, 9.93, 9.41,
  9.09, 8.9, 8.3, 7.67, 7.17, 6.76, 6.51, 6.07, 5.91, 5.7, 5.65, 5.61, 5.73, 6.3, 6.9, 7.61, 8.17,
  8.86, 9.55, 9.93, 10.05, 10.07, 9.96, 9.48, 9.12, 8.93, 8.32, 7.67, 7.17, 6.76, 6.51, 6.04, 5.9,
  5.7, 5.62, 5.59, 5.7, 6.28, 6.89, 7.59, 8.09, 8.87, 9.53, 9.97, 10.12, 10.1, 9.97, 9.46, 9.04,
  8.79, 8.21, 7.63, 7.16, 6.73, 6.54, 6.06, 5.92, 5.73, 5.65, 5.6, 5.74, 6.36, 6.98, 7.72, 8.25,
  8.89, 9.52, 9.83, 9.91, 9.87, 9.71, 9.23, 8.91, 8.72, 8.14, 7.56, 7.09, 6.66, 6.48, 6.04, 5.91,
  5.73, 5.68, 5.65, 5.78, 6.37, 6.99, 7.75, 8.27, 8.95, 9.6, 10.01, 10.14, 10.12, 9.95, 9.45, 9.1,
  8.87, 8.33, 7.78, 7.31, 6.79, 6.61, 6.1, 5.96, 5.77, 5.62, 5.57, 5.82, 5.93, 6.15, 6.57, 6.77,
  6.75, 6.84, 6.82, 6.86, 6.85, 6.9, 7.01, 7.1, 6.85, 6.56, 6.46, 6.35, 6.15, 5.85, 5.56, 5.56,
  5.59, 5.09, 5.11, 5.17, 5.23, 5.3, 5.02, 4.84, 5.0, 5.08, 5.03, 5.0, 5.1, 5.17, 5.18, 5.17, 5.1,
  4.98, 4.87, 4.89, 4.89, 4.9, 4.92, 4.94, 4.97, 5.64, 5.58, 5.72, 6.33, 6.97, 7.69, 8.22, 8.95,
  9.6, 9.93, 10.06, 10.06, 9.93, 9.41, 9.09, 8.9, 8.3, 7.67, 7.17, 6.76, 6.51, 6.07, 5.91, 5.7,
  5.65, 5.61, 5.73, 6.3, 6.9, 7.61, 8.17, 8.86, 9.55, 9.93, 10.05, 10.07, 9.96, 9.48, 9.12, 8.93,
  8.32, 7.67, 7.17, 6.76, 6.51, 6.04, 5.9, 5.7, 5.62, 5.59, 5.7, 6.28, 6.89, 7.59, 8.09, 8.87, 9.53,
  9.97, 10.12, 10.1, 9.97, 9.46, 9.04, 8.79, 8.21, 7.63, 7.16, 6.73, 6.54, 6.06, 5.92, 5.73, 5.65,
  5.6, 5.74, 6.36, 6.98, 7.72, 8.25, 8.89, 9.52, 9.83, 9.91, 9.87, 9.71, 9.23, 8.91, 8.72, 8.14,
  7.56, 7.09, 6.66, 6.48, 6.04, 5.91, 5.73, 5.68, 5.65, 5.78, 6.37, 6.99, 7.75, 8.27, 8.95, 9.6,
  10.01, 10.14, 10.12, 9.95, 9.45, 9.1, 8.87, 8.33, 7.78, 7.31, 6.79, 6.61, 6.1, 5.96, 5.77, 5.62,
  5.57, 5.82, 5.93, 6.15, 6.57, 6.77, 6.75, 6.84, 6.82, 6.86, 6.85, 6.9, 7.01, 7.1, 6.85, 6.56,
  6.46, 6.35, 6.15, 5.85, 5.56, 5.56, 5.59, 5.09, 5.11, 5.17, 5.23, 5.3, 5.02, 4.84, 5.0, 5.08,
  5.03, 5.0, 5.1, 5.17, 5.18, 5.17, 5.1, 4.98, 4.87, 4.89, 4.89, 4.9, 4.92, 4.94, 4.97, 5.64, 5.58,
  5.72, 6.33, 6.97, 7.69, 8.22, 8.95, 9.6, 9.93, 10.06, 10.06, 9.93, 9.41, 9.09, 8.9, 8.3, 7.67,
  7.17, 6.76, 6.51, 6.07, 5.91, 5.7, 5.49, 5.47, 5.56, 6.01, 6.5, 7.17, 7.53, 8.04, 8.54, 8.84,
  8.89, 8.86, 8.73, 8.25, 7.93, 7.76, 7.28, 7.02, 6.64, 6.24, 6.11, 5.76, 5.64, 5.52, 5.46, 5.43,
  5.52, 5.97, 6.44, 7.11, 7.46, 7.96, 8.48, 8.8, 8.88, 8.88, 8.75, 8.25, 7.88, 7.66, 7.21, 6.99,
  6.62, 6.26, 6.11, 5.79, 5.67, 5.56, 5.52, 5.49, 5.55, 6.08, 6.62, 7.33, 7.73, 8.26, 8.77, 9.1,
  9.13, 9.09, 8.93, 8.42, 8.06, 7.84, 7.38, 7.11, 6.74, 6.33, 6.17, 5.8, 5.64, 5.55, 5.51, 5.47,
  5.53, 5.99, 6.51, 7.18, 7.54, 8.01, 8.51, 8.79, 8.85, 8.8, 8.61, 8.12, 7.8, 7.64, 7.2, 6.97, 6.59,
  6.26, 6.12, 5.77, 5.63, 5.54, 5.4, 5.35, 5.53, 5.56, 5.68, 5.97, 5.95, 5.87, 5.92, 5.88, 5.92,
  5.89, 5.93, 6.03, 6.12, 5.94, 5.81, 5.96, 5.93, 5.76, 5.59, 5.4, 5.45, 5.52, 4.99, 5.01, 5.1,
  5.17, 5.25, 5.09, 4.82, 4.89, 4.87, 4.77, 4.67, 4.72, 4.75, 4.74, 4.74, 4.66, 4.54, 4.68, 4.72,
  4.74, 4.77, 4.8, 4.83, 4.87, 5.54, 5.48, 5.55, 6.0, 6.49, 7.15, 7.48, 7.95, 8.46, 8.74, 8.81,
  8.78, 8.63, 8.15, 7.82, 7.65, 7.2, 6.98, 6.61, 6.23, 6.13, 5.79, 5.68, 5.55, 5.49, 5.47, 5.56,
  6.01, 6.5, 7.17, 7.53, 8.04, 8.54, 8.84, 8.89, 8.86, 8.73, 8.25, 7.93, 7.76, 7.28, 7.02, 6.64,
  6.24, 6.11, 5.76, 5.64, 5.52, 5.46, 5.43, 5.52, 5.97, 6.44, 7.11, 7.46, 7.96, 8.48, 8.8, 8.88,
  8.88, 8.75, 8.25, 7.88, 7.66, 7.21, 6.99, 6.62, 6.26, 6.11, 5.79, 5.67, 5.56, 5.52, 5.49, 5.55,
  6.08, 6.62, 7.33, 7.73, 8.26, 8.77, 9.1, 9.13, 9.09, 8.93, 8.42, 8.06, 7.84, 7.38, 7.11, 6.74,
  6.33, 6.17, 5.8, 5.64, 5.55, 5.51, 5.47, 5.53, 5.99, 6.51, 7.18, 7.54, 8.01, 8.51, 8.79, 8.85,
  8.8, 8.61, 8.12, 7.8, 7.64, 7.2, 6.97, 6.59, 6.26, 6.12, 5.77, 5.63, 5.54, 5.4, 5.35, 5.53, 5.56,
  5.68, 5.97, 5.95, 5.87, 5.92, 5.88, 5.92, 5.89, 5.93, 6.03, 6.12, 5.94, 5.81, 5.96, 5.93, 5.76,
  5.59, 5.4, 5.45, 5.52, 4.99, 5.01, 5.1, 5.17, 5.25, 5.09, 4.82, 4.89, 4.87, 4.77, 4.67, 4.72,
  4.75, 4.74, 4.74, 4.66, 4.54, 4.68, 4.72, 4.74, 4.77, 4.8, 4.83, 4.87, 5.54, 5.48, 5.55, 6.0,
  6.49, 7.15, 7.48, 7.95, 8.46, 8.74, 8.81, 8.78, 8.63, 8.15, 7.82, 7.65, 7.2, 6.98, 6.61, 6.23,
  6.13, 5.79, 5.68, 5.55, 5.49, 5.47, 5.56, 6.01, 6.5, 7.17, 7.53, 8.04, 8.54, 8.84, 8.89, 8.86,
  8.73, 8.25, 7.93, 7.76, 7.28, 7.02, 6.64, 6.24, 6.11, 5.76, 5.64, 5.52, 5.46, 5.43, 5.52, 5.97,
  6.44, 7.11, 7.46, 7.96, 8.48, 8.8, 8.88, 8.88, 8.75, 8.25, 7.88, 7.66, 7.21, 6.99, 6.62, 6.26,
  6.11, 5.79, 5.67, 5.56, 5.52, 5.49, 5.55, 6.08, 6.62, 7.33, 7.73, 8.26, 8.77, 9.1, 9.13, 9.09,
  8.93, 8.42, 8.06, 7.84, 7.38, 7.11, 6.74, 6.33, 6.17, 5.8, 5.64, 5.55, 5.51, 5.47, 5.53, 5.99,
  6.51, 7.18, 7.54, 8.01, 8.51, 8.79, 8.85, 8.8, 8.61, 8.12, 7.8, 7.64, 7.2, 6.97, 6.59, 6.26, 6.12,
  5.77, 5.63, 5.54, 5.4, 5.35, 5.53, 5.56, 5.68, 5.97, 5.95, 5.87, 5.92, 5.88, 5.92, 5.89, 5.93,
  6.03, 6.12, 5.94, 5.81, 5.96, 5.93, 5.76, 5.59, 5.4, 5.45, 5.52, 4.99, 5.01, 5.1, 5.17, 5.25,
  5.09, 4.82, 4.89, 4.87, 4.77, 4.67, 4.72, 4.75, 4.74, 4.74, 4.66, 4.54, 4.68, 4.72, 4.74, 4.77,
  4.8, 4.83, 4.87, 5.54, 5.48, 5.55, 6.0, 6.49, 7.15, 7.48, 7.95, 8.46, 8.74, 8.81, 8.78, 8.63,
  8.15, 7.82, 7.65, 7.2, 6.98, 6.61, 6.23, 6.13, 5.79, 5.68, 5.55, 5.49, 5.47, 5.56, 6.01, 6.5,
  7.17, 7.53, 8.04, 8.54, 8.84, 8.89, 8.86, 8.73, 8.25, 7.93, 7.76, 7.28, 7.02, 6.64, 6.24, 6.11,
  5.76, 5.64, 5.52, 5.46, 5.43, 5.52, 5.97, 6.44, 7.11, 7.46, 7.96, 8.48, 8.8, 8.88, 8.88, 8.75,
  8.25, 7.88, 7.66, 7.21, 6.99, 6.62, 6.26, 6.11, 5.79, 5.67, 5.56, 5.52, 5.49, 5.55, 6.08, 6.62,
  7.33, 7.73, 8.26, 8.77, 9.1, 9.13, 9.09, 8.93, 8.42, 8.06, 7.84, 7.38, 7.11, 6.74, 6.33, 6.17,
  5.8, 5.64, 5.55, 5.51, 5.47, 5.53, 5.99, 6.51, 7.18, 7.54, 8.01, 8.51, 8.79, 8.85, 8.8, 8.61,
  8.12, 7.8, 7.64, 7.2, 6.97, 6.59, 6.26, 6.12, 5.77, 5.63, 5.54, 5.4, 5.35, 5.53, 5.56, 5.68, 5.97,
  5.95, 5.87, 5.92, 5.88, 5.92, 5.89, 5.93, 6.03, 6.12, 5.94, 5.81, 5.96, 5.93, 5.76, 5.59, 5.4,
  5.45, 5.52, 4.99, 5.01, 5.1, 5.17, 5.25, 5.09, 4.82, 4.89, 4.87, 4.77, 4.67, 4.72, 4.75, 4.74,
  4.74, 4.66, 4.54, 4.68, 4.72, 4.74, 4.77, 4.8, 4.83, 4.87, 5.54, 5.48, 5.55, 6.0, 6.49, 7.15,
  7.48, 7.95, 8.46, 8.74, 8.81, 8.78, 8.63, 8.15, 7.82, 7.65, 7.2, 6.98, 6.61, 6.23, 6.13, 5.79,
  5.68, 5.55, 5.49, 5.47, 5.56, 6.01, 6.5, 7.17, 7.53, 8.04, 8.54, 8.84, 8.89, 8.86, 8.73, 8.25,
  7.93, 7.76, 7.28, 7.02, 6.64, 6.24, 6.11, 5.76, 5.64, 5.52, 5.46, 5.43, 5.52, 5.97, 6.44, 7.11,
  7.46, 7.96, 8.48, 8.8, 8.88, 8.88, 8.75, 8.25, 7.88, 7.66, 7.21, 6.99, 6.62, 6.26, 6.11, 5.79,
  5.67, 5.56, 5.52, 5.49, 5.55, 6.08, 6.62, 7.33, 7.73, 8.26, 8.77, 9.1, 9.13, 9.09, 8.93, 8.42,
  8.06, 7.84, 7.38, 7.11, 6.74, 6.33, 6.17, 5.8, 5.64, 5.55, 5.53, 5.47, 5.46, 5.61, 6.0, 6.52,
  7.05, 7.4, 7.91, 8.32, 8.52, 8.56, 8.48, 8.24, 7.8, 7.49, 7.33, 7.04, 6.75, 6.37, 6.13, 5.96,
  5.72, 5.63, 5.53, 5.33, 5.37, 5.37, 5.51, 5.53, 5.71, 5.87, 5.86, 5.89, 5.95, 5.97, 5.99, 5.97,
  6.01, 6.09, 6.05, 5.92, 5.93, 5.96, 5.9, 5.72, 5.55, 5.41, 5.47, 4.89, 5.04, 5.09, 5.17, 5.25,
  5.27, 5.04, 4.88, 4.93, 4.87, 4.79, 4.73, 4.76, 4.77, 4.74, 4.72, 4.7, 4.74, 4.76, 4.79, 4.81,
  4.84, 4.86, 4.89, 5.58, 5.53, 5.49, 5.57, 5.97, 6.44, 6.98, 7.34, 7.8, 8.24, 8.43, 8.47, 8.4, 8.2,
  7.69, 7.35, 7.27, 6.99, 6.75, 6.4, 6.14, 6.0, 5.74, 5.63, 5.54, 5.5, 5.47, 5.54, 5.93, 6.38, 6.93,
  7.3, 7.81, 8.28, 8.51, 8.55, 8.5, 8.3, 7.79, 7.42, 7.32, 7.02, 6.79, 6.43, 6.18, 6.06, 5.77, 5.66,
  5.58, 5.52, 5.49, 5.57, 5.92, 6.37, 6.98, 7.35, 7.84, 8.27, 8.51, 8.54, 8.46, 8.23, 7.74, 7.42,
  7.36, 7.07, 6.8, 6.46, 6.19, 6.09, 5.77, 5.67, 5.59, 5.54, 5.52, 5.66, 6.05, 6.57, 7.06, 7.42,
  7.89, 8.28, 8.47, 8.5, 8.41, 8.14, 7.67, 7.37, 7.22, 6.95, 6.68, 6.32, 6.09, 5.92, 5.71, 5.61,
  5.53, 5.47, 5.46, 5.61, 6.0, 6.52, 7.05, 7.4, 7.91, 8.32, 8.52, 8.56, 8.48, 8.24, 7.8, 7.49, 7.33,
  7.04, 6.75, 6.37, 6.13, 5.96, 5.72, 5.63, 5.53, 5.33, 5.37, 5.51, 5.53, 5.71, 5.87, 5.86, 5.89,
  5.95, 5.97, 5.99, 5.97, 6.01, 6.09, 6.05, 5.92, 5.93, 5.96, 5.9, 5.72, 5.55, 5.41, 5.47, 4.89,
  5.04, 5.09, 5.17, 5.25, 5.27, 5.04, 4.88, 4.93, 4.87, 4.79, 4.73, 4.76, 4.77, 4.74, 4.72, 4.7,
  4.74, 4.76, 4.79, 4.81, 4.84, 4.86, 4.89, 5.58, 5.53, 5.49, 5.57, 5.97, 6.44, 6.98, 7.34, 7.8,
  8.24, 8.43, 8.47, 8.4, 8.2, 7.69, 7.35, 7.27, 6.99, 6.75, 6.4, 6.14, 6.0, 5.74, 5.63, 5.54, 5.5,
  5.47, 5.54, 5.93, 6.38, 6.93, 7.3, 7.81, 8.28, 8.51, 8.55, 8.5, 8.3, 7.79, 7.42, 7.32, 7.02, 6.79,
  6.43, 6.18, 6.06, 5.77, 5.66, 5.58, 5.52, 5.49, 5.57, 5.92, 6.37, 6.98, 7.35, 7.84, 8.27, 8.51,
  8.54, 8.46, 8.23, 7.74, 7.42, 7.36, 7.07, 6.8, 6.46, 6.19, 6.09, 5.77, 5.67, 5.59, 5.54, 5.52,
  5.66, 6.05, 6.57, 7.06, 7.42, 7.89, 8.28, 8.47, 8.5, 8.41, 8.14, 7.67, 7.37, 7.22, 6.95, 6.68,
  6.32, 6.09, 5.92, 5.71, 5.61, 5.53, 5.47, 5.46, 5.61, 6.0, 6.52, 7.05, 7.4, 7.91, 8.32, 8.52,
  8.56, 8.48, 8.24, 7.8, 7.49, 7.33, 7.04, 6.75, 6.37, 6.13, 5.96, 5.72, 5.63, 5.53, 5.33, 5.37,
  5.51, 5.53, 5.71, 5.87, 5.86, 5.89, 5.95, 5.97, 5.99, 5.97, 6.01, 6.09, 6.05, 5.92, 5.93, 5.96,
  5.9, 5.72, 5.55, 5.41, 5.47, 4.89, 5.04, 5.09, 5.17, 5.25, 5.27, 5.04, 4.88, 4.93, 4.87, 4.79,
  4.73, 4.76, 4.77, 4.74, 4.72, 4.7, 4.74, 4.76, 4.79, 4.81, 4.84, 4.86, 4.89, 5.58, 5.53, 5.49,
  5.57, 5.97, 6.44, 6.98, 7.34, 7.8, 8.24, 8.43, 8.47, 8.4, 8.2, 7.69, 7.35, 7.27, 6.99, 6.75, 6.4,
  6.14, 6.0, 5.74, 5.63, 5.54, 5.5, 5.47, 5.54, 5.93, 6.38, 6.93, 7.3, 7.81, 8.28, 8.51, 8.55, 8.5,
  8.3, 7.79, 7.42, 7.32, 7.02, 6.79, 6.43, 6.18, 6.06, 5.77, 5.66, 5.58, 5.52, 5.49, 5.57, 5.92,
  6.37, 6.98, 7.35, 7.84, 8.27, 8.51, 8.54, 8.46, 8.23, 7.74, 7.42, 7.36, 7.07, 6.8, 6.46, 6.19,
  6.09, 5.77, 5.67, 5.59, 5.54, 5.52, 5.66, 6.05, 6.57, 7.06, 7.42, 7.89, 8.28, 8.47, 8.5, 8.41,
  8.14, 7.67, 7.37, 7.22, 6.95, 6.68, 6.32, 6.09, 5.92, 5.71, 5.61, 5.53, 5.47, 5.46, 5.61, 6.0,
  6.52, 7.05, 7.4, 7.91, 8.32, 8.52, 8.56, 8.48, 8.24, 7.8, 7.49, 7.33, 7.04, 6.75, 6.37, 6.13,
  5.96, 5.72, 5.63, 5.53, 5.33, 5.37, 5.51, 5.53, 5.71, 5.87, 5.86, 5.89, 5.95, 5.97, 5.99, 5.97,
  6.01, 6.09, 6.05, 5.92, 5.93, 5.96, 5.9, 5.72, 5.55, 5.41, 5.47, 4.89, 5.04, 5.09, 5.17, 5.25,
  5.27, 5.04, 4.88, 4.93, 4.87, 4.79, 4.73, 4.76, 4.77, 4.74, 4.72, 4.7, 4.74, 4.76, 4.79, 4.81,
  4.84, 4.86, 4.89, 5.58, 5.53, 5.49, 5.57, 5.97, 6.44, 6.98, 7.34, 7.8, 8.24, 8.43, 8.47, 8.4, 8.2,
  7.69, 7.35, 7.27, 6.99, 6.75, 6.4, 6.14, 6.0, 5.74, 5.63, 5.54, 5.5, 5.47, 5.54, 5.93, 6.38, 6.93,
  7.3, 7.81, 8.28, 8.51, 8.55, 8.5, 8.3, 7.79, 7.42, 7.32, 7.02, 6.79, 6.43, 6.18, 6.06, 5.77, 5.66,
  5.58, 5.52, 5.49, 5.57, 5.92, 6.37, 6.98, 7.35, 7.84, 8.27, 8.51, 8.54, 8.46, 8.23, 7.74, 7.42,
  7.36, 7.07, 6.8, 6.46, 6.19, 6.09, 5.77, 5.67, 5.59, 5.54, 5.52, 5.66, 6.05, 6.57, 7.06, 7.42,
  7.89, 8.28, 8.47, 8.5, 8.41, 8.14, 7.67, 7.37, 7.22, 6.95, 6.68, 6.32, 6.09, 5.92, 5.71, 5.61,
  5.53, 5.47, 5.46, 5.61, 6.0, 6.52, 7.05, 7.4, 7.91, 8.32, 8.52, 8.56, 8.48, 8.24, 7.8, 7.49, 7.33,
  7.04, 6.75, 6.37, 6.13, 5.96, 5.72, 5.63, 5.53, 5.33, 5.37, 5.51, 5.53, 5.71, 5.87, 5.86, 5.89,
  5.95, 5.97, 5.99, 5.97, 6.01, 6.09, 6.05, 5.92, 5.93, 5.96, 5.9, 5.72, 5.55, 5.41, 5.47, 4.97,
  5.2, 5.23, 5.31, 5.39, 5.47, 5.31, 5.1, 5.09, 5.04, 4.95, 4.87, 4.87, 4.86, 4.83, 4.81, 4.9, 4.89,
  4.9, 4.94, 4.95, 4.98, 5.02, 5.05, 5.74, 5.71, 5.65, 5.7, 6.04, 6.37, 6.92, 7.22, 7.6, 8.04, 8.26,
  8.28, 8.2, 7.99, 7.49, 7.16, 7.15, 6.9, 6.69, 6.36, 6.18, 6.11, 5.85, 5.75, 5.67, 5.65, 5.64,
  5.68, 5.99, 6.42, 6.95, 7.23, 7.67, 8.06, 8.24, 8.23, 8.15, 7.94, 7.45, 7.13, 7.14, 6.89, 6.7,
  6.38, 6.21, 6.14, 5.89, 5.8, 5.76, 5.69, 5.66, 5.73, 6.02, 6.41, 6.93, 7.21, 7.63, 8.05, 8.22,
  8.22, 8.13, 7.93, 7.44, 7.13, 7.15, 6.91, 6.75, 6.44, 6.28, 6.21, 5.93, 5.84, 5.8, 5.71, 5.66,
  5.72, 6.0, 6.39, 6.91, 7.2, 7.62, 8.03, 8.21, 8.22, 8.13, 7.93, 7.44, 7.13, 7.13, 6.89, 6.74,
  6.41, 6.26, 6.21, 5.93, 5.82, 5.78, 5.72, 5.68, 5.76, 6.04, 6.42, 6.94, 7.24, 7.68, 8.11, 8.28,
  8.25, 8.17, 7.95, 7.46, 7.18, 7.21, 6.95, 6.77, 6.43, 6.23, 6.14, 5.88, 5.79, 5.62, 5.5, 5.49,
  5.65, 5.71, 5.8, 5.99, 5.9, 5.72, 5.7, 5.64, 5.64, 5.59, 5.6, 5.68, 5.77, 5.79, 5.77, 5.85, 5.8,
  5.63, 5.59, 5.47, 5.52, 4.97, 5.2, 5.23, 5.31, 5.39, 5.47, 5.31, 5.1, 5.09, 5.04, 4.95, 4.87,
  4.87, 4.86, 4.83, 4.81, 4.9, 4.89, 4.9, 4.94, 4.95, 4.98, 5.02, 5.05, 5.74, 5.71, 5.65, 5.7, 6.04,
  6.37, 6.92, 7.22, 7.6, 8.04, 8.26, 8.28, 8.2, 7.99, 7.49, 7.16, 7.15, 6.9, 6.69, 6.36, 6.18, 6.11,
  5.85, 5.75, 5.67, 5.65, 5.64, 5.68, 5.99, 6.42, 6.95, 7.23, 7.67, 8.06, 8.24, 8.23, 8.15, 7.94,
  7.45, 7.13, 7.14, 6.89, 6.7, 6.38, 6.21, 6.14, 5.89, 5.8, 5.76, 5.69, 5.66, 5.73, 6.02, 6.41,
  6.93, 7.21, 7.63, 8.05, 8.22, 8.22, 8.13, 7.93, 7.44, 7.13, 7.15, 6.91, 6.75, 6.44, 6.28, 6.21,
  5.93, 5.84, 5.8, 5.71, 5.66, 5.72, 6.0, 6.39, 6.91, 7.2, 7.62, 8.03, 8.21, 8.22, 8.13, 7.93, 7.44,
  7.13, 7.13, 6.89, 6.74, 6.41, 6.26, 6.21, 5.93, 5.82, 5.78, 5.72, 5.68, 5.76, 6.04, 6.42, 6.94,
  7.24, 7.68, 8.11, 8.28, 8.25, 8.17, 7.95, 7.46, 7.18, 7.21, 6.95, 6.77, 6.43, 6.23, 6.14, 5.88,
  5.79, 5.62, 5.5, 5.49, 5.65, 5.71, 5.8, 5.99, 5.9, 5.72, 5.7, 5.64, 5.64, 5.59, 5.6, 5.68, 5.77,
  5.79, 5.77, 5.85, 5.8, 5.63, 5.59, 5.47, 5.52, 4.97, 5.2, 5.23, 5.31, 5.39, 5.47, 5.31, 5.1, 5.09,
  5.04, 4.95, 4.87, 4.87, 4.86, 4.83, 4.81, 4.9, 4.89, 4.9, 4.94, 4.95, 4.98, 5.02, 5.05, 5.74,
  5.71, 5.65, 5.7, 6.04, 6.37, 6.92, 7.22, 7.6, 8.04, 8.26, 8.28, 8.2, 7.99, 7.49, 7.16, 7.15, 6.9,
  6.69, 6.36, 6.18, 6.11, 5.85, 5.75, 5.67, 5.65, 5.64, 5.68, 5.99, 6.42, 6.95, 7.23, 7.67, 8.06,
  8.24, 8.23, 8.15, 7.94, 7.45, 7.13, 7.14, 6.89, 6.7, 6.38, 6.21, 6.14, 5.89, 5.8, 5.76, 5.69,
  5.66, 5.73, 6.02, 6.41, 6.93, 7.21, 7.63, 8.05, 8.22, 8.22, 8.13, 7.93, 7.44, 7.13, 7.15, 6.91,
  6.75, 6.44, 6.28, 6.21, 5.93, 5.84, 5.8, 5.71, 5.66, 5.72, 6.0, 6.39, 6.91, 7.2, 7.62, 8.03, 8.21,
  8.22, 8.13, 7.93, 7.44, 7.13, 7.13, 6.89, 6.74, 6.41, 6.26, 6.21, 5.93, 5.82, 5.78, 5.72, 5.68,
  5.76, 6.04, 6.42, 6.94, 7.24, 7.68, 8.11, 8.28, 8.25, 8.17, 7.95, 7.46, 7.18, 7.21, 6.95, 6.77,
  6.43, 6.23, 6.14, 5.88, 5.79, 5.62, 5.5, 5.49, 5.65, 5.71, 5.8, 5.99, 5.9, 5.72, 5.7, 5.64, 5.64,
  5.59, 5.6, 5.68, 5.77, 5.79, 5.77, 5.85, 5.8, 5.63, 5.59, 5.47, 5.52, 4.97, 5.2, 5.23, 5.31, 5.39,
  5.47, 5.31, 5.1, 5.09, 5.04, 4.95, 4.87, 4.87, 4.86, 4.83, 4.81, 4.9, 4.89, 4.9, 4.94, 4.95, 4.98,
  5.02, 5.05, 5.74, 5.71, 5.65, 5.7, 6.04, 6.37, 6.92, 7.22, 7.6, 8.04, 8.26, 8.28, 8.2, 7.99, 7.49,
  7.16, 7.15, 6.9, 6.69, 6.36, 6.18, 6.11, 5.85, 5.75, 5.67, 5.65, 5.64, 5.68, 5.99, 6.42, 6.95,
  7.23, 7.67, 8.06, 8.24, 8.23, 8.15, 7.94, 7.45, 7.13, 7.14, 6.89, 6.7, 6.38, 6.21, 6.14, 5.89,
  5.8, 5.76, 5.69, 5.66, 5.73, 6.02, 6.41, 6.93, 7.21, 7.63, 8.05, 8.22, 8.22, 8.13, 7.93, 7.44,
  7.13, 7.15, 6.91, 6.75, 6.44, 6.28, 6.21, 5.93, 5.84, 5.8, 5.71, 5.66, 5.72, 6.0, 6.39, 6.91, 7.2,
  7.62, 8.03, 8.21, 8.22, 8.13, 7.93, 7.44, 7.13, 7.13, 6.89, 6.74, 6.41, 6.26, 6.21, 5.93, 5.82,
  5.78, 5.72, 5.68, 5.76, 6.04, 6.42, 6.94, 7.24, 7.68, 8.11, 8.28, 8.25, 8.17, 7.95, 7.46, 7.18,
  7.21, 6.95, 6.77, 6.43, 6.23, 6.14, 5.88, 5.79, 5.62, 5.5, 5.49, 5.65, 5.71, 5.8, 5.99, 5.9, 5.72,
  5.7, 5.64, 5.64, 5.59, 5.6, 5.68, 5.77, 5.79, 5.77, 5.85, 5.8, 5.63, 5.59, 5.47, 5.52, 4.97, 5.2,
  5.23, 5.31, 5.39, 5.47, 5.31, 5.1, 5.09, 5.04, 4.95, 4.87, 4.87, 4.86, 4.83, 4.81, 4.9, 4.89, 4.9,
  4.94, 4.95, 4.98, 5.02, 5.05, 5.74, 5.71, 5.65, 5.7, 6.04, 6.37, 6.92, 7.22, 7.6, 8.04, 8.26,
  8.28, 8.2, 7.99, 7.49, 7.16, 7.15, 6.9, 6.69, 6.36, 6.18, 6.11, 5.85, 5.75, 5.67, 5.65, 5.64,
  5.68, 5.99, 6.42, 6.95, 7.23, 7.67, 8.06, 8.24, 8.23, 8.15, 7.94, 7.45, 7.13, 7.14, 6.89, 6.7,
  6.38, 6.21, 6.14, 5.89, 5.8,
];

export default largeOfficeLoadProfile;
