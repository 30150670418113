import {getHoursOfYear} from '@generic/functions/time';
import {YEAR} from '@generic/data/assumptions';
import TIME_PERIODS from '@generic/config/charger';

const isWorkday = ({chargerSet: {workdays, workmonths}, date}) =>
  workdays.includes(date.dayOfWeek) && workmonths.includes(date.month);

const calcSitePlannerLoadProfile = (chargerSet) => {
  const {chargerCount, charger, chargersPerTimePeriod, maxTimePeriod} = chargerSet;
  const hoursOfYear = getHoursOfYear(YEAR);

  const sitePlannerLoadProfile = [];

  // The intent of this load profile is to have the total energy calculated based on the average,
  // typical usage of the chargers. Typical usage won't have all chargers on at the same time.
  // The result of that is that the demand charge will be quite low. The demand charge should be
  // calculated based on the maximum demand of the chargers.
  //
  // In order to achieve accurate energy and demand charges, we ask the user to select a time period
  // during which the chargers will be used at their maximum. This is the maxTimePeriod.
  // We set one hour within that time period to have the max demand. The end result is a funny looking
  // load profile that more accurately balances the energy and demand charges.
  //
  // This is prototyped here:
  // https://docs.google.com/spreadsheets/d/1xvAE5f-CdVl8DwU5fP_aogmHuvqOWyx5KAyuCVDtQ0M/edit#gid=987505034
  hoursOfYear.forEach((date, currIdx) => {
    const timePeriod = TIME_PERIODS.find((period) => period.hours[date.hourStart] === 1);
    const hour = date.hourStart;
    if (!timePeriod || !isWorkday({chargerSet, date})) {
      sitePlannerLoadProfile[currIdx] = {chargedKwh: 0};
    } else if (timePeriod.key === maxTimePeriod && timePeriod.maxTimePeriod === hour) {
      sitePlannerLoadProfile[currIdx] = {chargedKwh: charger.portKw * chargerCount};
    } else {
      const count = (timePeriod && chargersPerTimePeriod?.[timePeriod.key]) || 0;
      const chargedKwh = charger.portKw * count;

      sitePlannerLoadProfile[currIdx] = {chargedKwh};
    }
  });

  return sitePlannerLoadProfile;
};

export default calcSitePlannerLoadProfile;
