import smallOfficeLoadProfile from '@generic/data/loadProfiles/smallOffice';
import mediumOfficeLoadProfile from '@generic/data/loadProfiles/mediumOffice';
import largeOfficeLoadProfile from '@generic/data/loadProfiles/largeOffice';
import smallHotelLoadProfile from '@generic/data/loadProfiles/smallHotel';
import largeHotelLoadProfile from '@generic/data/loadProfiles/largeHotel';
import primarySchoolLoadProfile from '@generic/data/loadProfiles/primarySchool';
import secondarySchoolLoadProfile from '@generic/data/loadProfiles/secondarySchool';
import standaloneRetailLoadProfile from '@generic/data/loadProfiles/standaloneRetail';
import stripMallLoadProfile from '@generic/data/loadProfiles/stripMall';
import warehouseLoadProfile from '@generic/data/loadProfiles/warehouse';
import fullServiceRestaurantLoadProfile from '@generic/data/loadProfiles/fullServiceRestaurant';
import quickServiceRestaurantLoadProfile from '@generic/data/loadProfiles/quickServiceRestaurant';
import outpatientLoadProfile from '@generic/data/loadProfiles/outpatient';

const representativeLoadProfiles = {
  smallOffice: smallOfficeLoadProfile,
  mediumOffice: mediumOfficeLoadProfile,
  largeOffice: largeOfficeLoadProfile,
  smallHotel: smallHotelLoadProfile,
  largeHotel: largeHotelLoadProfile,
  primarySchool: primarySchoolLoadProfile,
  secondarySchool: secondarySchoolLoadProfile,
  standaloneRetail: standaloneRetailLoadProfile,
  stripMall: stripMallLoadProfile,
  warehouse: warehouseLoadProfile,
  fullServiceRestaurant: fullServiceRestaurantLoadProfile,
  quickServiceRestaurant: quickServiceRestaurantLoadProfile,
  outpatient: outpatientLoadProfile,
};

const getRepresentativeLoadProfile = (buildingType) => representativeLoadProfiles[buildingType];

export default getRepresentativeLoadProfile;
