import {Box, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import {useIntl} from 'react-intl';
import FuelCostsTable from '@generic/pages/ChargingCalculator/FuelCostsTable';

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: '700',
    marginBottom: '1rem',
  },
  subheader: {
    fontSize: '20px',
  },
  tableWrapper: {
    padding: '1.5rem 0',
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem',
    },
  },
}));

export default function FuelCosts() {
  const {formatMessage} = useIntl();
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h6" className={classes.header}>
        {formatMessage({id: 'charging.content.costs.fuel.header'})}
      </Typography>
      <Typography variant="body2" className={classes.subheader}>
        {formatMessage({id: 'charging.content.costs.fuel.subheader'})}
      </Typography>

      <Box className={classes.tableWrapper}>
        <FuelCostsTable />
      </Box>
    </Box>
  );
}
