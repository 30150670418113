import startCase from 'lodash/startCase';
import {
  formatAsDollarsPerGal,
  formatAsDollars,
  formatAsThousands,
} from '@generic/functions/formatters';

const NEW_LINE = escape('\n');
const SPACE = ' ';
const PERCENT = escape('%');

const IGNORED_INPUTS_KEYS = [
  'id',
  'lng',
  'loading',
  'setInput',
  'chargingWizardShown',
  'planningWizardShown',
  'imageUrl',
  'idx',
  'vehicle_id',
];

const spaces = (depth) =>
  Array(depth * 2)
    .fill(SPACE)
    .join('');

const printCurrentRate = ({title}) => title;

const WORKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const printWorkdays = (workdays) => workdays.map((workday) => WORKDAYS[workday]).join(', ');

const WORKMONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
const printWorkmonths = (workmonths) =>
  workmonths.map((workmonth) => WORKMONTHS[workmonth]).join(', ');

/* eslint-disable no-use-before-define */
const printChargerSets = (chargerSets, depth) =>
  chargerSets
    .map(
      (chargerSet, idx) =>
        `${spaces(depth)}Charger Set ${idx + 1}: ${NEW_LINE}${printArbitraryThings(
          chargerSet,
          depth + 1
        )}`
    )
    .join(NEW_LINE);

const printVehicleSets = (vehicleSets, depth) =>
  vehicleSets
    .map(
      (vehicleSet, idx) =>
        `${spaces(depth)}Vehicle Set ${idx + 1}: ${NEW_LINE}${printArbitraryThings(
          vehicleSet,
          depth + 1
        )}`
    )
    .join(NEW_LINE);

const HOURS = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

const printChargingWindows = (chargingWindows, depth) =>
  chargingWindows
    .map((chargingWindow, idx) => {
      const {start, finish, ...restOfChargingWindow} = chargingWindow;
      const timePeriod = `${HOURS[start]} - ${HOURS[finish]}`;
      return `${spaces(depth)}Charging Window ${idx + 1}:${NEW_LINE}${spaces(
        depth + 1
      )}Time Period: ${timePeriod}${NEW_LINE}${printArbitraryThings(
        restOfChargingWindow,
        depth + 1
      )}`;
    })
    .join(NEW_LINE);
/* eslint-enable no-use-before-define */

const printChargersPerTimePeriod = (chargersPerTimePeriod, depth) =>
  Object.entries(chargersPerTimePeriod)
    .map(
      ([timePeriod, chargerQty]) =>
        `${spaces(depth + 1)}${timePeriod.replace('_', ' - ')}: ${chargerQty}`
    )
    .join(NEW_LINE);

const printArbitraryThings = (thing, depth = 0) => {
  if (thing === null) return '';
  if (['number', 'string', 'boolean'].includes(typeof thing)) return `${thing.toString()}`;

  return Object.entries(thing)
    .map(([key, value]) => {
      if (IGNORED_INPUTS_KEYS.includes(key)) return null;

      const defaultPrintedKey = () => `${spaces(depth)}${startCase(key)}: `;
      const customPrintedKeys = {
        chargerSets: () => `${spaces(depth)}Site Planner Charger Sets:${NEW_LINE}`,
        vehicleSets: () => `${spaces(depth)}Fleet Planner Vehicle Sets:${NEW_LINE}`,
        chargingWindows: () => `${spaces(depth)}Charging Windows:${NEW_LINE}`,
        maxTimePeriod: () => `${spaces(depth)}${startCase(key)}: `,
        chargersPerTimePeriod: () => `${spaces(depth)}${startCase(key)}:${NEW_LINE}`,
        charger: () => `${spaces(depth)}${startCase(key)}:${NEW_LINE}`,
        vehicle: () => `${spaces(depth)}${startCase(key)}:${NEW_LINE}`,
        gvwr: () => `${spaces(depth)}Gross Vehicle Weight Rating: `,
      };

      const defaultPrintedValue = () => `${printArbitraryThings(value, depth + 1)}`;
      const customPrintedValue = {
        currentRate: () => printCurrentRate(value),
        workdays: () => printWorkdays(value),
        workmonths: () => printWorkmonths(value),
        buildingType: () => startCase(value),
        chargerType: () => startCase(value),
        formFactor: () => startCase(value),
        chargerSets: () => printChargerSets(value, depth + 1),
        vehicleSets: () => printVehicleSets(value, depth + 1),
        chargingWindows: () => printChargingWindows(value, depth + 1),
        maxTimePeriod: () => `${value.replace('_', ' - ')}`,
        chargersPerTimePeriod: () => printChargersPerTimePeriod(value, depth),
        utilization: () => `${(value * 100).toFixed(0)}${PERCENT}`,
        dieselPrice: () => `${formatAsDollarsPerGal(value)}`,
        gasolinePrice: () => `${formatAsDollarsPerGal(value)}`,
        monthlyElectricBill: () => `${formatAsDollars(value)}`,
        msrp: () => `${formatAsDollars(value)}`,
        fossilFuelMsrp: () => `${formatAsDollars(value)}`,
        gvwr: () => `${formatAsThousands(value)}`,
      };

      const printedKey = customPrintedKeys[key]?.() || defaultPrintedKey();
      const printedValue = customPrintedValue[key]?.() || defaultPrintedValue();
      return `${printedKey}${printedValue}`;
    })
    .filter((line) => line !== null)
    .join(NEW_LINE);
};
const printInputs = (inputs) => printArbitraryThings(inputs);

export default printInputs;
