import {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  makeStyles,
} from '@material-ui/core';
import {useIntl} from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Overview from '@generic/components/VehicleSetDialog/Overview';
import SidebarInput from '@generic/components/inputs/SidebarInput';
import SelectVehicle from '@generic/components/VehicleSetDialog/SelectVehicle';
import VehicleDetails from '@generic/components/VehicleSetDialog/VehicleDetails';
import ChargingWindows from '@generic/components/VehicleSetDialog/ChargingWindows';
import VehicleSetAlerts from '@generic/components/VehicleSetDialog/VehicleSetAlerts';
import CtaButton from '@generic/components/CtaButton';
import {useInputs} from '@bellawatt/use-inputs';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: '755px',
  },
  dialogTitle: {
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      padding: '16px 24px',
    },
  },
  dialogContent: {
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      padding: '16px 24px',
    },
  },
  alerts: {
    borderTop: `1px solid ${theme.palette.gray[300]}`,
    paddingTop: theme.spacing(2),
  },
  dialogActions: {
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },

    '& > button': {
      width: '100%',
      marginTop: 8,
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        marginTop: 0,
      },
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
    fontWeight: 600,
    textTransform: 'none',
  },
  cancelButton: {
    color: theme.palette.gray[500],
    fontWeight: 600,
    padding: '0.75rem 1.5rem',
    borderRadius: '10em',
    textTransform: 'none',
    border: `1px solid ${theme.palette.gray[500]}`,
  },
}));

export default function VehicleSetDialog({
  isOpen,
  onChange,
  onClose,
  onRemove,
  vehicleSet: {vehicle, ...restOfVehicleSet},
}) {
  const classes = useStyles();
  const {formatMessage} = useIntl();

  // needed to make sure we aren't deleting the one and only vehicle set
  const {vehicleSets} = useInputs();

  const [workingVehicleSet, setWorkingVehicleSet] = useState(cloneDeep(restOfVehicleSet));

  // if the vehicle set changes via the wizard dialog, the working state here needs to reflect that
  useDeepCompareEffect(() => {
    setWorkingVehicleSet(restOfVehicleSet);
  }, [restOfVehicleSet]);

  const handleChangeVehicleSet = (newData) =>
    setWorkingVehicleSet((current) => ({...current, ...newData}));

  const handleSaveAndClose = () => {
    onChange(workingVehicleSet);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="body"
      PaperProps={{className: classes.dialogPaper}}
      fullWidth
    >
      <Box p={{xs: 1, md: 3}}>
        <DialogTitle className={classes.dialogTitle}>
          <Overview vehicleSet={workingVehicleSet} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <SidebarInput title={formatMessage({id: 'vehicleSetDialog.selectVehicleType'})}>
            <Box py={{xs: 2, md: 3}} px={{xs: 2, md: 5}}>
              <SelectVehicle
                vehicleSet={workingVehicleSet}
                onChangeVehicleSet={handleChangeVehicleSet}
              />
            </Box>
          </SidebarInput>

          <SidebarInput title={formatMessage({id: 'vehicleSetDialog.enterVehicleDetails'})}>
            <Box py={{xs: 2, md: 3}} px={{xs: 2, md: 5}}>
              <VehicleDetails
                vehicleSet={workingVehicleSet}
                onChangeVehicleSet={handleChangeVehicleSet}
              />
            </Box>
          </SidebarInput>

          <SidebarInput title={formatMessage({id: 'vehicleSetDialog.selectChargingBehaviors'})}>
            <Box py={{xs: 2, md: 3}} px={{xs: 2, md: 5}}>
              <ChargingWindows
                vehicleSet={workingVehicleSet}
                onChangeVehicleSet={handleChangeVehicleSet}
              />
            </Box>
          </SidebarInput>

          <Box className={classes.alerts}>
            <VehicleSetAlerts vehicleSet={workingVehicleSet} />
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {vehicleSets.length > 1 && (
            <Button className={classes.deleteButton} onClick={onRemove}>
              <Icon className="icon-close" />
              {formatMessage({id: 'vehicleSetDialog.deleteVehicleSet'})}
            </Button>
          )}
          <Button className={classes.cancelButton} onClick={onClose}>
            {formatMessage({id: 'vehicleSetDialog.cancelChanges'})}
          </Button>
          <CtaButton onClick={handleSaveAndClose}>
            {formatMessage({id: 'vehicleSetDialog.saveChanges'})}
          </CtaButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
